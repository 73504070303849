import React, { useRef, useEffect, useState, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import LoadingSpinner from "./LoadingSpinner";

// Ensure Mapbox token is available via environment variables
const MAPBOX_TOKEN =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
  process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;

// Set the access token for mapboxgl
if (MAPBOX_TOKEN) {
  mapboxgl.accessToken = MAPBOX_TOKEN;
} else {
  console.error(
    "Mapbox Access Token is missing. Please set REACT_APP_MAPBOX_ACCESS_TOKEN or NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN environment variable."
  );
}

// Helper to create popup HTML
const createPopupHTML = (marker) => {
  return `
    <div class="p-3 max-w-xs bg-white rounded-md shadow-md">
      <h4 class="font-semibold text-sm text-gray-800 mb-1">${
        marker.name || "Location"
      }</h4>
      ${
        marker.tooltip
          ? `<p class="text-xs text-gray-600 mt-1">${marker.tooltip}</p>`
          : ""
      }
    </div>
  `;
};

const ShipmentRouteMap = ({ markers, fullscreen = false }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const markerRefs = useRef({});

  // Create a stable identifier for markers to prevent unnecessary re-renders
  const markersKey = useMemo(() => {
    return markers.map((m) => `${m.id}-${m.lat}-${m.lng}`).join("|");
  }, [markers]);

  useEffect(() => {
    if (!MAPBOX_TOKEN) return;
    if (map.current) return;
    if (!mapContainer.current) return;

    // Initialize map with a more professional style
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/navigation-day-v1", // Better style for routes
      center: [-98.5795, 39.8283], // Default US center
      zoom: 3.5,
      attributionControl: false,
    });

    // Add navigation controls
    map.current.addControl(
      new mapboxgl.NavigationControl({
        showCompass: true,
        visualizePitch: true,
      }),
      "top-right"
    );

    map.current.addControl(
      new mapboxgl.ScaleControl({
        maxWidth: 100,
        unit: "imperial",
      }),
      "bottom-left"
    );

    // Add attribution in a nicer way
    map.current.addControl(
      new mapboxgl.AttributionControl({
        compact: true,
      })
    );

    // Set map loaded state and add markers and route
    map.current.on("load", () => {
      setMapLoaded(true);
      addMarkersToMap();

      if (markers.length >= 2) {
        addRouteLine();
      }
    });

    return () => {
      if (map.current) {
        // Clean up any registered animation frames
        if (map.current._cleanupFunctions) {
          map.current._cleanupFunctions.forEach((cleanup) => cleanup());
        }

        map.current.remove();
        map.current = null;
        markerRefs.current = {};
      }
    };
  }, []);

  // Update markers and route when markers prop changes
  useEffect(() => {
    if (!map.current || !mapLoaded) return;

    // Clear existing markers
    Object.values(markerRefs.current).forEach((marker) => marker.remove());
    markerRefs.current = {};

    // Add new markers
    addMarkersToMap();

    // Update route
    if (map.current.getSource("route")) {
      if (markers.length >= 2) {
        updateRouteLine();
      } else {
        // Remove route if not enough markers
        if (map.current.getLayer("route-dots"))
          map.current.removeLayer("route-dots");
        if (map.current.getLayer("route-line"))
          map.current.removeLayer("route-line");
        if (map.current.getLayer("route-bg"))
          map.current.removeLayer("route-bg");
        if (map.current.getLayer("route-outline"))
          map.current.removeLayer("route-outline");
        map.current.removeSource("route");
      }
    } else if (markers.length >= 2) {
      addRouteLine();
    }
  }, [markersKey, mapLoaded]);

  const addMarkersToMap = () => {
    if (!map.current) return;

    markers.forEach((marker) => {
      // Create a custom marker element
      const el = document.createElement("div");
      el.className = "marker-container";

      // Use custom HTML for better looking markers
      const isOrigin = marker.id === "origin";
      const color = isOrigin ? "#3b82f6" : "#10b981";
      const textColor = isOrigin ? "#1d4ed8" : "#047857";
      const label = isOrigin ? "Origin" : "Destination";

      el.innerHTML = `
        <div class="relative">
          <div class="absolute -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
            <div class="px-2 py-1 bg-white rounded-md shadow-sm text-xs font-semibold" style="color: ${textColor}">
              ${label}
            </div>
          </div>
          <div class="w-7 h-7 rounded-full border-2 border-white shadow-lg" style="background-color: ${color}"></div>
          <div class="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-1 h-3 bg-white shadow-sm"></div>
        </div>
      `;

      el.style.width = "28px";
      el.style.height = "28px";

      const markerId = `marker-${marker.id}`;
      markerRefs.current[markerId] = new mapboxgl.Marker({
        element: el,
        anchor: "bottom",
      })
        .setLngLat([marker.lng, marker.lat])
        .setPopup(
          new mapboxgl.Popup({
            offset: 25,
            closeButton: false,
            className: "custom-popup",
          }).setHTML(createPopupHTML(marker))
        )
        .addTo(map.current);
    });

    // Fit bounds to show all markers
    if (markers.length > 0) {
      const bounds = new mapboxgl.LngLatBounds();
      markers.forEach((marker) => {
        bounds.extend([marker.lng, marker.lat]);
      });

      // Add padding to bounds
      map.current.fitBounds(bounds, {
        padding: { top: 100, bottom: 100, left: 100, right: 100 },
        maxZoom: 12,
        duration: 1500,
        essential: true,
      });
    }
  };

  const addRouteLine = () => {
    if (!map.current || markers.length < 2) return;

    // Find origin and destination markers
    const origin = markers.find((m) => m.id === "origin");
    const destination = markers.find((m) => m.id === "destination");

    if (!origin || !destination) return;

    // Create a simple straight line between points
    const coordinates = [
      [origin.lng, origin.lat],
      [destination.lng, destination.lat],
    ];

    // Add the route source
    map.current.addSource("route", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coordinates,
        },
      },
    });

    // Add an outline for the route (stoke)
    map.current.addLayer({
      id: "route-outline",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#000",
        "line-width": 7,
        "line-opacity": 0.1,
        "line-blur": 3,
      },
    });

    // Add a background line (shadow)
    map.current.addLayer({
      id: "route-bg",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#4338ca", // Indigo base color
        "line-width": 6,
        "line-opacity": 0.3,
        "line-blur": 1,
      },
    });

    // Add the main route line with gradient
    map.current.addLayer({
      id: "route-line",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#6366f1", // Indigo color
        "line-width": 4,
        "line-opacity": 0.8,
        "line-dasharray": [0, 1.5, 0.5],
      },
    });

    // Add animated dots along the route (truck effect instead of airplane)
    map.current.addLayer({
      id: "route-dots",
      type: "symbol",
      source: "route",
      layout: {
        "symbol-placement": "line",
        "symbol-spacing": 80,
        "icon-image": "car-15", // Changed from airport-15 to car-15 (truck icon)
        "icon-size": 0.85, // Slightly larger size for the truck
        "icon-rotate": ["get", "bearing"],
        "icon-rotation-alignment": "map",
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
      },
      paint: {
        "icon-color": "#ffffff",
        "icon-opacity": 0.9,
      },
    });

    // Add animated truck effect
    animateRoute();
  };

  const animateRoute = () => {
    if (!map.current) return;

    // Use a ref to track animation frame ID
    const animationFrameRef = { current: null };
    let step = 0;

    function animate() {
      // Update line-dasharray to create moving effect
      if (map.current && map.current.getLayer("route-line")) {
        // Move the dash pattern to create animation
        map.current.setPaintProperty("route-line", "line-dasharray", [
          0,
          4,
          3,
          step % 8,
        ]);
      }

      // Update step and request next frame
      step = (step + 1) % 8;
      animationFrameRef.current = requestAnimationFrame(animate);
    }

    // Start the animation
    animate();

    // Add cleanup function to the map instance for later cleanup
    map.current._cleanupFunctions = map.current._cleanupFunctions || [];
    map.current._cleanupFunctions.push(() => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    });
  };

  const updateRouteLine = () => {
    if (!map.current || markers.length < 2) return;

    // Find origin and destination markers
    const origin = markers.find((m) => m.id === "origin");
    const destination = markers.find((m) => m.id === "destination");

    if (!origin || !destination) return;

    // Update the source data with a simple straight line
    map.current.getSource("route").setData({
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: [
          [origin.lng, origin.lat],
          [destination.lng, destination.lat],
        ],
      },
    });
  };

  if (!MAPBOX_TOKEN) {
    return (
      <div className="flex items-center justify-center h-full bg-red-100 text-red-700 p-4 rounded-md">
        Mapbox Access Token is missing.
      </div>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: fullscreen ? "calc(100vh - 220px)" : "400px",
        minHeight: "400px",
        borderRadius: "0.375rem",
        overflow: "hidden",
      }}
    >
      {!mapLoaded && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 z-10">
          <LoadingSpinner />
        </div>
      )}

      <div
        ref={mapContainer}
        className="map-container"
        style={{ position: "absolute", top: 0, bottom: 0, width: "100%" }}
      />

      {/* Optional: Map legend */}
      <div className="absolute bottom-2 left-2 z-10 bg-white bg-opacity-75 p-2 rounded-md text-xs font-medium">
        <div className="flex items-center gap-1.5 mb-1">
          <div className="w-3 h-3 rounded-full bg-blue-500"></div>
          <span>Origin</span>
        </div>
        <div className="flex items-center gap-1.5">
          <div className="w-3 h-3 rounded-full bg-emerald-500"></div>
          <span>Destination</span>
        </div>
      </div>
    </div>
  );
};

export default ShipmentRouteMap;
