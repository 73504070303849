/**
 * PersonaReportService.js
 *
 * This service handles Persona report-related operations
 */

import PersonaProvider, {
  personaProvider,
} from "../../../providers/PersonaProvider";
import { VerificationRepository } from "../../../repositories/VerificationRepository";
import { supabase } from "../../../../lib/supabase";
import { REPORT_TYPES } from "../constants/ReportTypes";

// UUID generator function to replace crypto dependency
function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

class PersonaReportService {
  constructor(dependencies = {}) {
    this.personaProvider = dependencies.personaProvider || personaProvider;
    this.verificationRepository =
      dependencies.verificationRepository ||
      new VerificationRepository(supabase);
  }

  /**
   * Creates a new Persona verification report
   *
   * @param {Object} data - Report data
   * @returns {Promise<Object>} Report creation result
   */
  async createPersonaReport(data) {
    try {
      console.log("🔍 [PersonaReportService] Creating Persona report:", data);

      if (!data) {
        throw new Error("Report data is required");
      }

      // Create a report in the Persona platform using the provider
      const reportResult = await this.personaProvider.createReport(data);

      // Store the report result in our database using the repository
      const verificationRecord = {
        type: "persona_verification",
        state: "pending",
        persona_verification_complete: false,
        persona_report_id: reportResult.reportId,
        reference_id: reportResult.referenceId || data.referenceId,
        metadata: {
          ...data,
          report_id: reportResult.reportId,
          reference_id: reportResult.referenceId || data.referenceId,
        },
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      const savedRecord = await this.verificationRepository.create(
        verificationRecord
      );

      console.log(
        "✅ [PersonaReportService] Report created successfully:",
        savedRecord.id
      );

      return {
        id: savedRecord.id,
        reportId: reportResult.reportId,
        referenceId: reportResult.referenceId || data.referenceId,
        type: savedRecord.type,
        state: savedRecord.state,
        created_at: savedRecord.created_at,
      };
    } catch (error) {
      console.error("❌ [PersonaReportService] Error creating report:", error);
      throw error;
    }
  }

  /**
   * Gets all Persona reports
   *
   * @returns {Promise<Array>} List of all reports
   */
  async getAllPersonaReports() {
    try {
      console.log("🔍 [PersonaReportService] Getting all reports");

      // Use the repository to get all persona verification records
      const reports = await this.verificationRepository.findAll({
        type: "persona_verification",
      });

      console.log(
        `✅ [PersonaReportService] Retrieved ${reports.length} reports`
      );
      return reports;
    } catch (error) {
      console.error("❌ [PersonaReportService] Error getting reports:", error);
      throw error;
    }
  }

  /**
   * Gets a Persona report by reference ID
   *
   * @param {string} referenceId - Reference ID to search for
   * @returns {Promise<Object>} Report details
   */
  async getPersonaReportByReferenceId(referenceId) {
    try {
      console.log(
        "🔍 [PersonaReportService] Getting report by reference ID:",
        referenceId
      );

      if (!referenceId) {
        throw new Error("Reference ID is required");
      }

      // Use the repository to find records by reference ID
      const reports = await this.verificationRepository.findByReferenceId(
        referenceId
      );

      console.log(
        `✅ [PersonaReportService] Retrieved report for reference ID: ${referenceId}`
      );
      return reports;
    } catch (error) {
      console.error("❌ [PersonaReportService] Error getting report:", error);
      throw error;
    }
  }

  /**
   * Gets verification details by carrier ID
   *
   * @param {string} carrierId - Carrier ID to search for
   * @returns {Promise<Array>} List of verification resources
   */
  async getVerificationsByCarrierId(carrierId) {
    try {
      console.log(
        "🔍 [PersonaReportService] Getting verifications by carrier ID:",
        carrierId
      );

      if (!carrierId) {
        throw new Error("Carrier ID is required");
      }

      // Use the repository to find records by carrier ID
      const verifications = await this.verificationRepository.findByCarrierId(
        carrierId
      );

      console.log(
        `✅ [PersonaReportService] Retrieved ${verifications.length} verifications for carrier: ${carrierId}`
      );
      return verifications;
    } catch (error) {
      console.error(
        "❌ [PersonaReportService] Error getting carrier verifications:",
        error
      );
      throw error;
    }
  }

  /**
   * Updates a Persona report
   *
   * @param {Object} reportData - Report data to update
   * @returns {Promise<Object>} Update result
   */
  async updatePersonaReport(reportData) {
    try {
      console.log("🔍 [PersonaReportService] Updating report:", reportData);

      if (!reportData || !reportData.id) {
        throw new Error("Report ID is required for update");
      }

      // If we have a reportId and want to update status in Persona platform
      if (reportData.reportId && reportData.updatePersona) {
        await this.personaProvider.updateReport(reportData.reportId, {
          status: reportData.status,
          metadata: reportData.metadata,
        });
      }

      // Update the verification record in our database
      const updatedRecord = await this.verificationRepository.update(
        reportData.id,
        {
          ...reportData,
          updated_at: new Date().toISOString(),
        }
      );

      console.log(
        "✅ [PersonaReportService] Report updated successfully:",
        updatedRecord.id
      );
      return updatedRecord;
    } catch (error) {
      console.error("❌ [PersonaReportService] Error updating report:", error);
      throw error;
    }
  }

  /**
   * Get a list of available report types based on the form data
   * @param {Object} formData - The form data to check
   * @returns {Array<string>} List of available report types
   */
  getAvailableReportTypes(formData) {
    if (!formData) {
      return [];
    }

    // Log important values for debugging
    console.log("[PersonaReportService] Checking available reports for:", {
      businessName:
        formData?.business_name || formData?.business_fields?.business_name,
      phone:
        formData?.phone_number ||
        formData?.phoneNumber ||
        formData?.business_fields?.phone_number,
      email: formData?.email || formData?.business_fields?.email,
    });

    // Log the complete business_fields object for debugging
    console.log(
      "[PersonaReportService] Complete business_fields:",
      formData?.business_fields
    );

    const reports = Object.entries(REPORT_TYPES)
      .filter(([_, config]) => config.shouldRun(formData))
      .map(([reportType]) => reportType);

    console.log("[PersonaReportService] Available reports:", reports);
    return reports;
  }

  /**
   * Format report data for storage
   * @param {Object} options - Options for formatting
   * @param {Object} options.result - The report result
   * @param {Object} options.config - The report configuration
   * @param {string} options.entityId - The entity ID
   * @param {string} options.entityType - The entity type
   * @param {string} options.customValue - Custom value for reference
   * @returns {Object} Formatted report data
   */
  formatReportResult({ result, config, entityId, entityType, customValue }) {
    if (!result || !config) {
      throw new Error(
        "Result and config are required for formatting report data"
      );
    }

    return {
      ...result.reportData,
      referenceId: result.referenceId,
      success: true,
      verificationMethod: config.verificationMethod,
      resourceType: "report",
      entityType: entityType || "carrier",
      entityId,
      customValue,
    };
  }

  /**
   * Format transaction data to match verification result format
   * @param {Object} options - Options for formatting
   * @param {Object} options.transactionData - The transaction data
   * @param {string} options.referenceId - The reference ID
   * @param {Object} options.config - The report configuration
   * @param {string} options.entityId - The entity ID
   * @param {string} options.entityType - The entity type
   * @param {string} options.customValue - Custom value for reference
   * @returns {Object} Formatted transaction data
   */
  formatTransactionResult({
    transactionData,
    referenceId,
    config,
    entityId,
    entityType = "carrier",
    customValue,
  }) {
    if (!transactionData || !config) {
      throw new Error(
        "Transaction data and config are required for formatting"
      );
    }

    const timestamp = new Date().toISOString();

    return {
      id: generateUUID(), // Generate unique ID for this verification result
      resource_id: entityId,
      verification_method: config.verificationMethod,
      status: "completed",
      result: {
        id: transactionData.id,
        type: "transaction",
        score: 0,
        success:
          transactionData.attributes.status === "created" ||
          transactionData.attributes.status === "completed" ||
          transactionData.attributes.status === "submitted",
        attributes: transactionData.attributes,
        relationships: transactionData.relationships || {},
      },
      error_message: null,
      retry_count: 0,
      version: 1,
      processed_at: timestamp,
      created_at: timestamp,
      updated_at: timestamp,
      referenceId: referenceId,
      customValue,
    };
  }
}

// Export as singleton instance
export default new PersonaReportService();
