import React from "react";

/**
 * EmailRiskReportDetails - Renders details specific to email risk reports.
 * TODO: Implement email risk report snapshot view.
 *
 * @param {Object} props - Component props
 * @param {Object} props.verification - The verification data object (assuming it contains email risk data)
 */
export default function EmailRiskReportDetails({ verification }) {
  // Assuming risk data might be nested or need processing
  const riskData = verification.email_risk_data || {}; // Example structure

  return (
    <div className="p-4 bg-green-50 space-y-2 text-sm border border-green-200 rounded">
      <h4 className="font-semibold text-green-700">
        Email Risk Report Details
      </h4>
      <p className="text-slate-500">Verification ID: {verification.id}</p>
      <p className="text-slate-500">Email: {verification.email || "N/A"}</p>
      <p className="text-slate-500">State: {verification.state || "N/A"}</p>
      <p className="text-orange-600 italic">
        -- Email risk report snapshot placeholder --
      </p>
      {/* Add email risk specific fields and layout here */}
      <pre className="text-xs bg-slate-100 p-2 rounded overflow-auto">
        {JSON.stringify(riskData, null, 2)}
      </pre>
    </div>
  );
}
