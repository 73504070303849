import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl"; // Use mapbox-gl-js directly
import "mapbox-gl/dist/mapbox-gl.css"; // Import Mapbox CSS
import LoadingSpinner from "./LoadingSpinner";

// Ensure Mapbox token is available via environment variables
const MAPBOX_TOKEN =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
  process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;

// Set the access token for mapboxgl
if (MAPBOX_TOKEN) {
  mapboxgl.accessToken = MAPBOX_TOKEN;
} else {
  console.error(
    "Mapbox Access Token is missing. Please set REACT_APP_MAPBOX_ACCESS_TOKEN or NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN environment variable."
  );
}

// Helper to create popup HTML
const createPopupHTML = (marker) => {
  return `
    <div class="p-1 max-w-xs">
      <h4 class="font-semibold text-sm mb-1">${marker.name || "Marker"}</h4>
      ${
        marker.status
          ? `<p class="text-xs text-gray-600">Status: ${marker.status}</p>`
          : ""
      }
      ${
        marker.riskScore !== undefined
          ? `<p class="text-xs text-gray-600">Risk: ${marker.riskScore}</p>`
          : ""
      }
      ${
        marker.tooltip
          ? `<p class="text-xs text-gray-500 mt-1">${marker.tooltip}</p>`
          : ""
      }
    </div>
  `;
};

function MapboxMapComponent({
  markers = [],
  hotspots = [],
  fullscreen = false,
  initialViewState = null,
}) {
  const mapContainer = useRef(null); // Ref for the map container div
  const map = useRef(null); // Ref for the map instance
  const [lng, setLng] = useState(initialViewState?.longitude || -98.5795); // Default centerlng
  const [lat, setLat] = useState(initialViewState?.latitude || 39.8283); // Default center lat
  const [zoom, setZoom] = useState(initialViewState?.zoom || 3.5); // Default zoom
  const [mapLoaded, setMapLoaded] = useState(false);
  const markerRefs = useRef({}); // Keep track of added markers

  useEffect(() => {
    if (!MAPBOX_TOKEN) return; // Don't initialize if token is missing
    if (map.current) return; // Initialize map only once
    if (!mapContainer.current) return; // Ensure container is available

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11", // Default style
      center: [lng, lat],
      zoom: zoom,
    });

    // Add navigation controls
    map.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    map.current.addControl(new mapboxgl.ScaleControl());

    // Update state on map move
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });

    // Set map loaded state and add layers/markers
    map.current.on("load", () => {
      setMapLoaded(true);
      // Initial add of markers and hotspots
      updateMarkers(map.current, markers, markerRefs);
      updateHotspots(map.current, hotspots);
    });

    // Clean up on unmount
    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null; // Nullify the ref
        markerRefs.current = {}; // Clear marker refs
      }
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  // Effect to update markers when the markers prop changes
  useEffect(() => {
    if (map.current && mapLoaded) {
      // Ensure map is loaded
      updateMarkers(map.current, markers, markerRefs);
    }
  }, [markers, mapLoaded]);

  // Effect to update hotspots when the hotspots prop changes
  useEffect(() => {
    if (map.current && mapLoaded) {
      // Ensure map is loaded
      updateHotspots(map.current, hotspots);
    }
  }, [hotspots, mapLoaded]);

  // Function to add/update/remove markers
  const updateMarkers = (mapInstance, currentMarkers, refs) => {
    const currentMarkerIds = new Set(
      currentMarkers.map((m) => `marker-${m.id}`)
    );
    const existingMarkerIds = new Set(Object.keys(refs.current));

    // Remove markers that are no longer in the props
    existingMarkerIds.forEach((id) => {
      if (!currentMarkerIds.has(id)) {
        refs.current[id].remove();
        delete refs.current[id];
      }
    });

    // Add or update markers
    currentMarkers.forEach((marker) => {
      const markerId = `marker-${marker.id}`;
      const color = marker.hasAlerts ? "#ef4444" : "#3b82f6";

      if (refs.current[markerId]) {
        // Optional: Update existing marker if needed (e.g., position, popup content)
        refs.current[markerId].setLngLat([marker.lng, marker.lat]);
        // Update popup content if it can change
        const popup = refs.current[markerId].getPopup();
        if (popup) {
          popup.setHTML(createPopupHTML(marker));
        }
      } else {
        // Create new marker
        const el = document.createElement("div");
        el.className = "mapbox-marker"; // Add a class for potential styling
        el.style.backgroundImage = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${color}" width="24px" height="24px"><path fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041A16.975 16.975 0 0012 21.75a16.975 16.975 0 00-6.592-3.041l-.071.041-.028.015a.76.76 0 00-.723 0l-.028-.015-.07-.04A16.975 16.975 0 005.25 21.75c0 .68.103 1.33.294 1.941l.071.214c.042.128.103.25.18.367.077.117.165.225.263.323.098.098.206.186.323.263.117.077.24.138.368.18.229.074.468.12.718.151l.13.011.011.002.013.001.013.001c.013 0 .026 0 .04 0 .013 0 .026 0 .04 0l.014-.001.013-.001.01-.002.131-.011c.25-.03.49-.077.718-.15.128-.042.25-.103.368-.18.098-.08.186-.168.263-.263.098-.098.186-.206.263-.323.077-.117.138-.24.18-.368l.07-.214a17.005 17.005 0 00.294-1.941 16.975 16.975 0 00-6.592 3.04zM12 12.75a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" /></svg>')`;
        el.style.width = `24px`;
        el.style.height = `24px`;
        el.style.backgroundSize = "100%";
        el.style.cursor = "pointer";

        refs.current[markerId] = new mapboxgl.Marker(el)
          .setLngLat([marker.lng, marker.lat])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(createPopupHTML(marker))
          )
          .addTo(mapInstance);
      }
    });
  };

  // Function to add/update hotspot layer
  const updateHotspots = (mapInstance, currentHotspots) => {
    const sourceId = "hotspots-source";
    const layerId = "hotspots-layer";
    const source = mapInstance.getSource(sourceId);

    const geojsonData = {
      type: "FeatureCollection",
      features: currentHotspots.map((hp) => ({
        type: "Feature",
        geometry: { type: "Point", coordinates: [hp.lng, hp.lat] },
        properties: {
          intensity: hp.intensity,
          radius: hp.radius || 10, // Default radius if not provided
          description: hp.description,
        },
      })),
    };

    if (source) {
      // Update existing source data
      source.setData(geojsonData);
    } else {
      // Add new source and layer
      mapInstance.addSource(sourceId, {
        type: "geojson",
        data: geojsonData,
      });

      mapInstance.addLayer({
        id: layerId,
        type: "circle",
        source: sourceId,
        paint: {
          "circle-radius": ["get", "radius"],
          "circle-color": [
            "interpolate",
            ["linear"],
            ["get", "intensity"],
            0,
            "#ffffb2", // Adjusted colors slightly
            50,
            "#fecc5c",
            100,
            "#e31a1c",
          ],
          "circle-opacity": 0.5,
          "circle-stroke-width": 0.5,
          "circle-stroke-color": "#888",
        },
      });
    }
    // Ensure layer visibility based on props if MapControls modifies this
    // Example: mapInstance.setLayoutProperty(layerId, 'visibility', props.showHotspots ? 'visible' : 'none');
  };

  if (!MAPBOX_TOKEN) {
    return (
      <div className="flex items-center justify-center h-full bg-red-100 text-red-700 p-4 rounded-md">
        Mapbox Access Token is missing.
      </div>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: fullscreen ? "calc(100vh - 220px)" : "400px",
        minHeight: fullscreen ? "600px" : "400px",
      }}
    >
      {/* Add loading state overlay */}
      {!mapLoaded && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 z-10">
          <LoadingSpinner />
        </div>
      )}
      {/* Map container div */}
      <div
        ref={mapContainer}
        className="map-container"
        style={{ position: "absolute", top: 0, bottom: 0, width: "100%" }}
      />
    </div>
  );
}

export default MapboxMapComponent;
