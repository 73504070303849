// @ts-check
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DriverService } from "../api/services/verification/DriversService";

/**
 * Hook to fetch all drivers
 * @returns {Object} The query result containing driver list
 */
export const useDrivers = () => {
  return useQuery({
    queryKey: ["drivers"],
    queryFn: () => DriverService.getAllDrivers(),
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to fetch a specific driver by ID
 * @param {string} id - The driver ID to fetch
 * @returns {Object} The query result containing driver details
 */
export const useDriver = (id) => {
  return useQuery({
    queryKey: ["driver", id],
    queryFn: () => DriverService.getDriver(id),
    enabled: !!id,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook providing all driver-related mutations
 * @returns {Object} Mutation functions for driver operations
 */
export const useDriverMutations = () => {
  const queryClient = useQueryClient();

  /**
   * Helper to invalidate driver queries
   * @param {string} id - Optional driver ID to invalidate specific queries
   */
  const invalidateDrivers = async (id) => {
    await queryClient.invalidateQueries({ queryKey: ["drivers"] });
    if (id) await queryClient.invalidateQueries({ queryKey: ["driver", id] });
  };

  const createDriver = useMutation({
    mutationFn: DriverService.createDriver,
    onSuccess: () => invalidateDrivers(),
    onError: (error) => {
      console.error("Error creating driver:", error);
    },
  });

  const updateDriver = useMutation({
    mutationFn: ({ id, driverData }) =>
      DriverService.updateDriver(id, driverData),
    onSuccess: (_, { id }) => invalidateDrivers(id),
    onError: (error) => {
      console.error("Error updating driver:", error);
    },
  });

  const deleteDriver = useMutation({
    mutationFn: DriverService.deleteDriver,
    onSuccess: (_, id) => invalidateDrivers(id),
    onError: (error) => {
      console.error("Error deleting driver:", error);
    },
  });

  return {
    createDriver,
    updateDriver,
    deleteDriver,
  };
};
