// useFMSCAQueries.js
import { useQuery } from "@tanstack/react-query";
import FMSCAService from "../api/services/carrier/FMSCA";

/**
 * Best Practice: Keep a consistent pattern for your query keys.
 * Usually, an array of strings (and/or params) is recommended.
 * This helps with caching, invalidation, and concurrency.
 */

/**
 * Hook to fetch carrier data by name
 * @param {string} name - The carrier name to search for
 * @param {Object} options - Additional options for the query
 * @returns {Object} The query result
 */
export function useCarrierByName(name, options = {}) {
  return useQuery({
    queryKey: ["carrierByName", name],
    queryFn: () => FMSCAService.getCarrierByName(name),
    enabled: Boolean(name),
    ...options,
  });
}

/**
 * Hook to fetch carrier data by DOT number
 * @param {string|number} dotNumber - The DOT number to search for
 * @param {Object} options - Additional options for the query
 * @returns {Object} The query result
 */
export function useCarrierByDOT(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierByDOT", dotNumber],
    queryFn: () => FMSCAService.getCarrierByDOT(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

/**
 * Hook to fetch carrier data by docket number
 * @param {string|number} docketNumber - The docket number to search for
 * @param {Object} options - Additional options for the query
 * @returns {Object} The query result
 */
export function useCarrierByDocket(docketNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierByDocket", docketNumber],
    queryFn: () => FMSCAService.getCarrierByDocket(docketNumber),
    enabled: Boolean(docketNumber),
    ...options,
  });
}

/**
 * Hook to fetch carrier basics data
 * @param {string|number} dotNumber - The DOT number to search for
 * @param {Object} options - Additional options for the query
 * @returns {Object} The query result
 */
export function useCarrierBasics(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierBasics", dotNumber],
    queryFn: () => FMSCAService.getCarrierBasics(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

/**
 * Hook to fetch carrier cargo carried data
 * @param {string|number} dotNumber - The DOT number to search for
 * @param {Object} options - Additional options for the query
 * @returns {Object} The query result
 */
export function useCarrierCargoCarried(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierCargoCarried", dotNumber],
    queryFn: () => FMSCAService.getCarrierCargoCarried(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

/**
 * Hook to fetch carrier operation classification data
 * @param {string|number} dotNumber - The DOT number to search for
 * @param {Object} options - Additional options for the query
 * @returns {Object} The query result
 */
export function useCarrierOperationClassification(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierOperationClassification", dotNumber],
    queryFn: () => FMSCAService.getCarrierOperationClassification(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

/**
 * Hook to fetch carrier out-of-service data
 * @param {string|number} dotNumber - The DOT number to search for
 * @param {Object} options - Additional options for the query
 * @returns {Object} The query result
 */
export function useCarrierOOS(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierOOS", dotNumber],
    queryFn: () => FMSCAService.getCarrierOOS(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

/**
 * Hook to fetch carrier docket numbers
 * @param {string|number} dotNumber - The DOT number to search for
 * @param {Object} options - Additional options for the query
 * @returns {Object} The query result
 */
export function useCarrierDocketNumbers(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierDocketNumbers", dotNumber],
    queryFn: () => FMSCAService.getCarrierDocketNumbers(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}

/**
 * Hook to fetch carrier authority data
 * @param {string|number} dotNumber - The DOT number to search for
 * @param {Object} options - Additional options for the query
 * @returns {Object} The query result
 */
export function useCarrierAuthority(dotNumber, options = {}) {
  return useQuery({
    queryKey: ["carrierAuthority", dotNumber],
    queryFn: () => FMSCAService.getCarrierAuthority(dotNumber),
    enabled: Boolean(dotNumber),
    ...options,
  });
}
