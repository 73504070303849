// Create an instance with Supabase client from your app
import { supabase } from "../../lib/supabase";

class DockRepository {
  constructor(dbClient) {
    if (!dbClient) {
      throw new Error("DockRepository requires a database client.");
    }
    this.db = dbClient; // e.g., Supabase client, Knex instance, Prisma client
    this.tableName = "docks";
  }

  /**
   * Creates a new dock.
   * @param {object} dockData - Data for the new dock.
   * @param {string} dockData.name - Dock name.
   * @param {string} dockData.type - Dock type ('standard', 'refrigerated', etc.).
   * @param {number} dockData.company_id - The ID of the company owning the dock.
   * @param {string} [dockData.status='available'] - Initial status.
   * @param {string} [dockData.location_description] - Optional location details.
   * @returns {Promise<object>} The newly created dock object.
   */
  async create(dockData) {
    // Add default status if not provided
    const dataToInsert = {
      status: "available",
      ...dockData,
      // Ensure timestamps are handled by DB defaults or set here if needed
      // created_at: new Date(), // Usually handled by DB default
      updated_at: new Date(),
    };

    // Example using Supabase-like client:
    const { data, error } = await this.db
      .from(this.tableName)
      .insert(dataToInsert)
      .select()
      .single(); // Return the created row

    if (error) {
      console.error("Error creating dock:", error);
      throw new Error(`Failed to create dock: ${error.message}`);
    }
    if (!data) {
      throw new Error("Dock creation did not return data.");
    }
    return data;

    // Example using Knex.js:
    // const [newDock] = await this.db(this.tableName).insert(dataToInsert).returning('*');
    // return newDock;
  }

  /**
   * Finds a dock by its ID.
   * @param {number} id - The ID of the dock.
   * @returns {Promise<object|null>} The dock object or null if not found.
   */
  async findById(id) {
    // Example using Supabase-like client:
    const { data, error } = await this.db
      .from(this.tableName)
      .select("*")
      .eq("id", id)
      .maybeSingle(); // Returns single row or null

    if (error) {
      console.error(`Error finding dock by id ${id}:`, error);
      throw new Error(`Failed to find dock: ${error.message}`);
    }
    return data;
  }

  /**
   * Finds docks belonging to a specific company, with optional filters.
   * @param {number} companyId - The ID of the company.
   * @param {object} [filters={}] - Optional filters.
   * @param {string} [filters.status] - Filter by status (e.g., 'available', 'occupied'). 'all' or undefined means no status filter.
   * @param {string} [filters.type] - Filter by type (e.g., 'standard', 'refrigerated'). 'all' or undefined means no type filter.
   * @param {string} [filters.search] - Search term for dock name (case-insensitive partial match).
   * @returns {Promise<object[]>} An array of dock objects.
   */
  async findByCompanyId(companyId, filters = {}) {
    let query = this.db
      .from(this.tableName)
      .select("*")
      .eq("company_id", companyId);

    // Apply filters
    if (filters.status && filters.status !== "all") {
      query = query.eq("status", filters.status);
    }
    if (filters.type && filters.type !== "all") {
      query = query.eq("type", filters.type);
    }
    if (filters.search) {
      // Use 'ilike' for case-insensitive partial match (PostgreSQL)
      query = query.ilike("name", `%${filters.search}%`);
    }

    // Add ordering, e.g., by name
    query = query.order("name", { ascending: true });

    // Execute query
    const { data, error } = await query;

    if (error) {
      console.error(`Error finding docks for company ${companyId}:`, error);
      throw new Error(`Failed to find docks: ${error.message}`);
    }
    return data || [];
  }

  /**
   * Updates a dock.
   * @param {number} id - The ID of the dock to update.
   * @param {object} updateData - Fields to update.
   * @param {string} [updateData.status] - New status.
   * @param {string} [updateData.name] - New name.
   * @param {string} [updateData.type] - New type.
   * @param {string} [updateData.location_description] - New location description.
   * // Add other updatable fields as needed
   * @returns {Promise<object|null>} The updated dock object or null if not found.
   */
  async update(id, updateData) {
    const dataToUpdate = {
      ...updateData,
      updated_at: new Date(), // Always update the timestamp
    };

    // Example using Supabase-like client:
    const { data, error } = await this.db
      .from(this.tableName)
      .update(dataToUpdate)
      .eq("id", id)
      .select()
      .single(); // Return the updated row

    if (error) {
      console.error(`Error updating dock ${id}:`, error);
      // Handle specific errors like not found if possible
      throw new Error(`Failed to update dock: ${error.message}`);
    }
    if (!data) {
      // Handle case where the update affects 0 rows (record not found)
      // Depending on dbClient behavior, error might already be thrown
      console.warn(`Dock with ID ${id} not found for update.`);
      return null;
    }
    return data;
  }

  /**
   * Updates only the status of a dock.
   * @param {number} id - The ID of the dock to update.
   * @param {string} status - The new status ('available', 'occupied', 'maintenance').
   * @returns {Promise<object|null>} The updated dock object or null if not found.
   */
  async updateStatus(id, status) {
    // Validate status if needed, though DB constraint should handle it
    const validStatuses = ["available", "occupied", "maintenance"];
    if (!validStatuses.includes(status)) {
      throw new Error(`Invalid status provided: ${status}`);
    }
    return this.update(id, { status });
  }

  /**
   * Deletes a dock by its ID.
   * @param {number} id - The ID of the dock to delete.
   * @returns {Promise<boolean>} True if deletion was successful, false otherwise.
   */
  async delete(id) {
    // Example using Supabase-like client:
    const { error, count } = await this.db
      .from(this.tableName)
      .delete()
      .eq("id", id);

    if (error) {
      console.error(`Error deleting dock ${id}:`, error);
      // Consider foreign key constraints - deletion might fail if dock is referenced
      throw new Error(`Failed to delete dock: ${error.message}`);
    }
    // Check if any row was actually deleted
    return count > 0;
  }

  // Potential methods for managing current_shipment_id if added to docks table:
  // async assignShipment(dockId, shipmentId) { ... }
  // async clearShipment(dockId) { ... }
}

// Export the repository instance (singleton)
const dockRepository = new DockRepository(supabase);
export default dockRepository;
