// @ts-check
import React, { useState, useMemo, useCallback } from "react";
import { useShipmentByCargoOwnerId } from "../../hooks/shipments";
import { Card, CardContent } from "../Components/Card";
import { Input } from "../Components/Input";
import { Button } from "../Components/Button";
import { Select } from "../Components/Select";
import { Loader2 } from "lucide-react";
import {
  FiSearch,
  FiFilter,
  FiChevronLeft,
  FiChevronRight,
  FiExternalLink,
  FiClock,
  FiCheckCircle,
} from "react-icons/fi";
import Toast from "../Components/Toast";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase";

/**
 * @constant {number} ITEMS_PER_PAGE - Number of items to display per page
 */
const ITEMS_PER_PAGE = 10;

/**
 * ShipmentList component to display a paginated list of shipments with filtering and sorting capabilities
 *
 * @returns {JSX.Element} Rendered component
 */
const ShipmentList = () => {
  const { id: cargoOwnerId } = useParams();
  const navigate = useNavigate();

  // State management
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    search: "",
    cargoOwnerId: cargoOwnerId,
    orderBy: "created_at",
    orderDirection: "desc",
    status: "", // Added status filter
  });

  // Fetch shipment data with React Query
  const { data, isLoading, error, refetch } = useShipmentByCargoOwnerId(
    currentPage,
    ITEMS_PER_PAGE,
    filters,
    cargoOwnerId
  );

  const { shipments = [], total = 0 } = data || {};
  const totalPages = Math.ceil((total || 0) / ITEMS_PER_PAGE);

  // Memoized filter options
  const statusOptions = useMemo(
    () => [
      { value: "", label: "All Statuses" },
      { value: "Verified", label: "Verified" },
      { value: "In Transit", label: "In Transit" },
      { value: "Pending", label: "Pending" },
      { value: "Unverified", label: "Unverified" },
    ],
    []
  );

  const sortOptions = useMemo(
    () => [
      { value: "created_at", label: "Date Created" },
      { value: "load_id", label: "Load ID" },
      { value: "status", label: "Status" },
    ],
    []
  );

  // Handle filter changes (memoized callback)
  const handleFilterChange = useCallback((key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
    setCurrentPage(1); // Reset to first page when filter changes
  }, []);

  // Handle pagination (memoized callback)
  const handlePageChange = useCallback(
    (newPage) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    },
    [totalPages]
  );

  // Generate report URL (memoized callback)
  const handleViewDetails = useCallback(async (shipment) => {
    try {
      // Check for existing token
      const { data: tokenData, error: tokenError } = await supabase
        .from("shipments")
        .select("public_token")
        .eq("id", shipment.id)
        .single();

      if (tokenError) throw tokenError;

      let token = tokenData?.public_token;

      // Only generate a new token if one doesn't exist
      if (!token) {
        token = crypto.randomUUID();

        // Update shipment with new token
        const { error: updateError } = await supabase
          .from("shipments")
          .update({ public_token: token })
          .eq("id", shipment.id);

        if (updateError) throw updateError;
      }

      // Open in a new tab
      window.open(`/public-report/${token}`, "_blank");
    } catch (error) {
      console.error("Error handling report view:", error);
      Toast.error("Failed to generate report link");
    }
  }, []);

  // Reset all filters to default values
  const resetFilters = useCallback(() => {
    setFilters({
      search: "",
      cargoOwnerId: cargoOwnerId,
      orderBy: "created_at",
      orderDirection: "desc",
      status: "",
    });
    setCurrentPage(1);
  }, [cargoOwnerId]);

  // Handle sort direction toggle
  const toggleSortDirection = useCallback(() => {
    setFilters((prev) => ({
      ...prev,
      orderDirection: prev.orderDirection === "asc" ? "desc" : "asc",
    }));
  }, []);

  // Render status badge with appropriate color
  const renderStatusBadge = useCallback((status) => {
    const statusConfig = {
      Verified: {
        bg: "bg-green-100",
        text: "text-green-800",
        icon: <FiCheckCircle className="mr-1" />,
      },
      "In Transit": {
        bg: "bg-yellow-100",
        text: "text-yellow-800",
        icon: <FiClock className="mr-1" />,
      },
      Pending: {
        bg: "bg-gray-100",
        text: "text-gray-800",
        icon: <FiClock className="mr-1" />,
      },
      Unverified: {
        bg: "bg-red-100",
        text: "text-red-800",
        icon: <FiClock className="mr-1" />,
      },
    };

    const config = statusConfig[status] || statusConfig.Pending;

    return (
      <span
        className={`px-2 py-1 inline-flex items-center text-xs leading-5 font-semibold rounded-full ${config.bg} ${config.text}`}
      >
        {config.icon}
        {status}
      </span>
    );
  }, []);

  // Error handling
  if (error) {
    return (
      <Card className="bg-white dark:bg-slate-800 rounded-xl overflow-hidden shadow-lg">
        <CardContent className="p-6">
          <div className="flex flex-col items-center justify-center py-8">
            <div className="text-red-500 mb-4">
              <svg
                className="w-12 h-12"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h3 className="text-lg font-medium text-slate-900 dark:text-white mb-2">
              Error Loading Shipments
            </h3>
            <p className="text-sm text-slate-500 dark:text-slate-400 mb-4">
              {error.message}
            </p>
            <Button onClick={() => refetch()}>Retry</Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      {/* Filter and Search Section */}
      <Card className="bg-white dark:bg-slate-800 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-200 border-none">
        <CardContent className="p-6">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
            <h2 className="text-xl font-bold text-slate-800 dark:text-white mb-4 md:mb-0">
              Shipment Dashboard
            </h2>
            <Button
              variant="outline"
              className="bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 font-medium border border-slate-200 dark:border-slate-600 hover:bg-slate-50 dark:hover:bg-slate-600 transition-colors"
              onClick={resetFilters}
            >
              Reset Filters
            </Button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Search Input */}
            <div className="w-full md:col-span-3">
              {/* Search input and sort direction button */}
              <div className="flex items-center gap-2 mb-4">
                <div className="flex-grow">
                  <Input
                    placeholder="Search by Load ID..."
                    value={filters.search}
                    onChange={(e) =>
                      handleFilterChange("search", e.target.value)
                    }
                    icon={<FiSearch className="w-4 h-4" />}
                    className="w-full bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 border border-slate-200 dark:border-slate-600 rounded-lg"
                  />
                </div>
                <Button
                  variant="outline"
                  onClick={toggleSortDirection}
                  className="h-10 w-10 p-0 flex-shrink-0 flex items-center justify-center bg-white dark:bg-slate-700 border border-slate-200 dark:border-slate-600"
                  title={
                    filters.orderDirection === "asc"
                      ? "Ascending"
                      : "Descending"
                  }
                >
                  {filters.orderDirection === "asc" ? "↑" : "↓"}
                </Button>
              </div>

              {/* Status filter and sort options */}
              <div className="flex items-center gap-4">
                <div className="w-1/2">
                  <Select
                    options={statusOptions}
                    value={filters.status}
                    onChange={(value) => handleFilterChange("status", value)}
                    placeholder="Filter by status"
                    icon={<FiFilter className="w-4 h-4" />}
                    className="w-full bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 border border-slate-200 dark:border-slate-600 rounded-lg"
                  />
                </div>
                <div className="w-1/2">
                  <Select
                    options={sortOptions}
                    value={filters.orderBy}
                    onChange={(value) => handleFilterChange("orderBy", value)}
                    placeholder="Sort by"
                    className="w-full bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 border border-slate-200 dark:border-slate-600 rounded-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Shipments Table Card */}
      <Card className="bg-white dark:bg-slate-800 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-200 border-none">
        <CardContent className="p-6">
          {isLoading ? (
            <div className="flex justify-center py-12">
              <div className="flex flex-col items-center">
                <Loader2 className="w-10 h-10 animate-spin text-slate-600 dark:text-slate-400 mb-4" />
                <p className="text-slate-600 dark:text-slate-400">
                  Loading shipments...
                </p>
              </div>
            </div>
          ) : (
            <>
              {/* Table Header */}
              <div className="rounded-lg overflow-hidden border border-slate-200 dark:border-slate-700">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-slate-700">
                    <thead className="bg-slate-50 dark:bg-slate-700">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-slate-600 dark:text-slate-300 uppercase tracking-wider">
                          Load ID
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-slate-600 dark:text-slate-300 uppercase tracking-wider">
                          Status
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-slate-600 dark:text-slate-300 uppercase tracking-wider">
                          Created
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-slate-600 dark:text-slate-300 uppercase tracking-wider">
                          Condition
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-slate-600 dark:text-slate-300 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>

                    {/* Table Body */}
                    <tbody className="bg-white dark:bg-slate-800 divide-y divide-gray-200 dark:divide-slate-700">
                      {shipments.length > 0 ? (
                        shipments.map((shipment) => (
                          <tr
                            key={shipment.id}
                            className={`hover:bg-slate-50 dark:hover:bg-slate-700 transition-colors
                              ${
                                shipment.status !== "Verified"
                                  ? "opacity-80"
                                  : ""
                              }
                            `}
                          >
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="font-medium text-slate-900 dark:text-white">
                                {shipment.load_id}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {renderStatusBadge(shipment.status)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-slate-500 dark:text-slate-400">
                                {new Date(
                                  shipment.created_at
                                ).toLocaleDateString(undefined, {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-slate-500 dark:text-slate-400">
                                {shipment.condition || "N/A"}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <Button
                                variant={
                                  shipment.status === "Verified"
                                    ? "default"
                                    : "outline"
                                }
                                onClick={() => handleViewDetails(shipment)}
                                disabled={shipment.status !== "Verified"}
                                className={`
                                  w-auto h-9 flex items-center justify-center rounded-md px-4
                                  ${
                                    shipment.status === "Verified"
                                      ? "bg-[#143560] text-white hover:bg-[#0F8A90] transition-colors"
                                      : "bg-slate-50 dark:bg-slate-700 border border-slate-200 dark:border-slate-600 text-slate-500"
                                  }
                                `}
                              >
                                {shipment.status === "Verified" ? (
                                  <span className="flex items-center gap-2">
                                    <span className="text-sm font-medium">
                                      View Report
                                    </span>
                                    <FiExternalLink className="w-4 h-4" />
                                  </span>
                                ) : (
                                  <span className="flex items-center gap-2">
                                    <FiClock className="w-4 h-4 text-slate-500" />
                                    <span className="text-sm font-medium">
                                      Pending
                                    </span>
                                  </span>
                                )}
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="5"
                            className="px-6 py-12 text-center text-slate-500 dark:text-slate-400"
                          >
                            <div className="flex flex-col items-center">
                              <svg
                                className="w-12 h-12 text-slate-300 dark:text-slate-600 mb-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                />
                              </svg>
                              <span className="text-lg font-medium mb-2">
                                No shipments found
                              </span>
                              <p className="text-sm text-slate-500 dark:text-slate-400 mb-4">
                                Try adjusting your filters or search criteria
                              </p>
                              <Button onClick={resetFilters} variant="outline">
                                Reset Filters
                              </Button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Pagination Controls */}
              {total > 0 && (
                <div className="flex items-center justify-between mt-6 px-2">
                  <div className="hidden sm:flex text-sm text-slate-500 dark:text-slate-400">
                    <span>
                      Showing{" "}
                      <span className="font-medium">
                        {Math.min(
                          (currentPage - 1) * ITEMS_PER_PAGE + 1,
                          total
                        )}
                      </span>{" "}
                      -{" "}
                      <span className="font-medium">
                        {Math.min(currentPage * ITEMS_PER_PAGE, total)}
                      </span>{" "}
                      of <span className="font-medium">{total}</span> shipments
                    </span>
                  </div>

                  <div className="flex flex-1 justify-center sm:justify-end space-x-1">
                    <Button
                      variant="outline"
                      size="sm"
                      className="h-9 w-9 p-0 flex items-center justify-center bg-white dark:bg-slate-700"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <FiChevronLeft className="w-4 h-4" />
                    </Button>

                    {/* Page number buttons - show at most 5 pages */}
                    {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                      // Calculate page numbers to show centered around current page
                      const pageRange = 2; // Show 2 pages on each side when possible
                      let startPage = Math.max(1, currentPage - pageRange);
                      let endPage = Math.min(totalPages, startPage + 4);

                      if (endPage - startPage < 4) {
                        startPage = Math.max(1, endPage - 4);
                      }

                      const pageNum = startPage + i;
                      if (pageNum > endPage) return null;

                      return (
                        <Button
                          key={pageNum}
                          variant={
                            currentPage === pageNum ? "default" : "outline"
                          }
                          size="sm"
                          className={`h-9 w-9 p-0 flex items-center justify-center ${
                            currentPage === pageNum
                              ? "bg-[#143560] text-white"
                              : "bg-white dark:bg-slate-700"
                          }`}
                          onClick={() => handlePageChange(pageNum)}
                        >
                          {pageNum}
                        </Button>
                      );
                    }).filter(Boolean)}

                    <Button
                      variant="outline"
                      size="sm"
                      className="h-9 w-9 p-0 flex items-center justify-center bg-white dark:bg-slate-700"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      <FiChevronRight className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ShipmentList;
