import React, { useEffect, useState } from "react";
import { MapPin, Package, Truck } from "lucide-react";

/**
 * The third step of the shipment form for reviewing the shipment details before submission
 */
export function ShipmentReviewStep({ form, cargoOwnerOptions }) {
  const [cargoOwnerName, setCargoOwnerName] = useState("");

  // Find and set the cargo owner name whenever form values or options change
  useEffect(() => {
    const cargoOwnerId = form.getValues("cargo_owner_id");
    console.log("Review step cargo owner ID:", cargoOwnerId);
    console.log("Available cargo owner options:", cargoOwnerOptions);

    if (cargoOwnerId && cargoOwnerOptions?.length > 0) {
      // Convert cargo owner ID to the same type as in the options (usually a number)
      // Since cargoOwnerOptions use numeric IDs and the form might store it as string
      const normalizedId =
        typeof cargoOwnerId === "string"
          ? parseInt(cargoOwnerId, 10)
          : cargoOwnerId;
      console.log("Normalized ID for comparison:", normalizedId);

      // Try matching with both the original and normalized ID
      const owner = cargoOwnerOptions.find(
        (owner) => owner.value === cargoOwnerId || owner.value === normalizedId
      );

      setCargoOwnerName(owner?.label || "");
      console.log("Found cargo owner:", owner);
    }
  }, [form, cargoOwnerOptions]);

  return (
    <div className="space-y-6">
      <div className="bg-muted/50 p-4 rounded-lg">
        <div className="flex items-center space-x-2 mb-4">
          <Package className="h-5 w-5 text-primary" />
          <h3 className="font-medium">Shipment Details</h3>
        </div>
        <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3 text-sm">
          <div>
            <dt className="font-medium text-muted-foreground">Load ID</dt>
            <dd>{form.getValues("load_id") || "(Will be generated)"}</dd>
          </div>
          <div>
            <dt className="font-medium text-muted-foreground">Cargo Owner</dt>
            <dd>{cargoOwnerName || "Not specified"}</dd>
          </div>
          <div>
            <dt className="font-medium text-muted-foreground">
              Carrier Number
            </dt>
            <dd>{form.getValues("carrier_number") || "Not specified"}</dd>
          </div>
          <div>
            <dt className="font-medium text-muted-foreground">
              Container Number
            </dt>
            <dd>{form.getValues("container_number") || "Not specified"}</dd>
          </div>
          <div>
            <dt className="font-medium text-muted-foreground">Pallet Number</dt>
            <dd>{form.getValues("pallet_number") || "Not specified"}</dd>
          </div>
          <div>
            <dt className="font-medium text-muted-foreground">Direction</dt>
            <dd>{form.getValues("direction") || "Not specified"}</dd>
          </div>
          <div>
            <dt className="font-medium text-muted-foreground">Tags</dt>
            <dd>{form.getValues("tags") || "None"}</dd>
          </div>
          <div>
            <dt className="font-medium text-muted-foreground">Status</dt>
            <dd>{form.getValues("status")}</dd>
          </div>
          <div>
            <dt className="font-medium text-muted-foreground">Pickup Time</dt>
            <dd>
              {form.getValues("pickup_time")
                ? new Date(form.getValues("pickup_time")).toLocaleString()
                : "Not scheduled"}
            </dd>
          </div>
        </dl>
        {form.getValues("notes") && (
          <div className="mt-4">
            <dt className="font-medium text-muted-foreground mb-1">Notes</dt>
            <dd className="bg-background/50 p-2 rounded text-sm">
              {form.getValues("notes")}
            </dd>
          </div>
        )}
      </div>

      <div className="bg-muted/50 p-4 rounded-lg">
        <div className="flex items-center space-x-2 mb-4">
          <Truck className="h-5 w-5 text-primary" />
          <h3 className="font-medium">Route Information</h3>
        </div>
        <dl className="grid grid-cols-1 gap-y-3 text-sm">
          <div>
            <dt className="font-medium text-muted-foreground">
              Origin Address
            </dt>
            <dd className="flex items-start">
              <MapPin className="h-4 w-4 text-green-600 mr-2 mt-0.5 flex-shrink-0" />
              <span>{form.getValues("origin_address")}</span>
            </dd>
          </div>
          <div>
            <dt className="font-medium text-muted-foreground">
              Destination Address
            </dt>
            <dd className="flex items-start">
              <MapPin className="h-4 w-4 text-red-600 mr-2 mt-0.5 flex-shrink-0" />
              <span>{form.getValues("destination_address")}</span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
