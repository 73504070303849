// @ts-nocheck
import { supabase } from "../../../lib/supabase";

const OCRService = {
  uploadImage: async (imageFile, shipmentId) => {
    try {
      console.log(
        "[OCRService] Starting image upload for shipment:",
        shipmentId
      );

      // File type validation
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!allowedTypes.includes(imageFile.type)) {
        throw new Error(
          "Invalid file type. Only JPEG, PNG, and GIF are allowed."
        );
      }

      // File size validation
      const MAX_FILE_SIZE = 4 * 1024 * 1024; // 4MB instead of 5MB
      if (imageFile.size > MAX_FILE_SIZE) {
        throw new Error("File size exceeds the limit of 5MB.");
      }

      const fileExt = imageFile.name.split(".").pop();
      const fileName = `${shipmentId}_${Date.now()}.${fileExt}`;

      // Check if bucket exists
      const { data: buckets, error: bucketsError } =
        await supabase.storage.listBuckets();

      if (bucketsError) {
        console.error("[OCRService] Error listing buckets:", bucketsError);
        throw new Error(
          `Failed to list storage buckets: ${bucketsError.message}`
        );
      }

      console.log("[OCRService] Available buckets:", buckets);

      const bucketName = "documentImages";
      const bucketExists = buckets.some((bucket) => bucket.name === bucketName);

      if (!bucketExists) {
        console.error(`[OCRService] Bucket '${bucketName}' does not exist`);
        throw new Error(
          `Storage bucket '${bucketName}' does not exist. Please create it in the Supabase dashboard.`
        );
      }

      console.log(
        `[OCRService] Uploading file to '${bucketName}' bucket:`,
        fileName
      );

      const { data: uploadData, error: uploadError } = await supabase.storage
        .from(bucketName)
        .upload(fileName, imageFile);

      if (uploadError) {
        console.error("[OCRService] Error uploading file:", uploadError);
        throw new Error(`Failed to upload image: ${uploadError.message}`);
      }

      console.log("[OCRService] File uploaded successfully:", uploadData);

      // Get the signed URL
      const { data: urlData, error: urlError } = await supabase.storage
        .from(bucketName)
        .createSignedUrl(fileName, 60 * 60); // URL expires in 1 hour (3600 seconds)

      if (urlError) {
        console.error("[OCRService] Error creating signed URL:", urlError);
        throw new Error(`Failed to create signed URL: ${urlError.message}`);
      }

      const signedUrl = urlData.signedUrl;

      if (!signedUrl) {
        throw new Error("Failed to get signed URL for uploaded image");
      }

      console.log("[OCRService] Generated signed URL:", signedUrl);

      // Update the shipments table with the document_photo_url
      const { error: updateError } = await supabase
        .from("shipments")
        .update({ document_photo_url: signedUrl })
        .eq("load_id", shipmentId);

      if (updateError) {
        console.error(
          "[OCRService] Error updating shipment record:",
          updateError
        );
        // Don't throw here, as we still want to return the signed URL
        console.warn(
          `Failed to update shipment record with photo URL: ${updateError.message}`
        );
      }

      return {
        signedUrl,
        fileName,
        filePath: uploadData.path,
        fileSize: imageFile.size,
      };
    } catch (error) {
      console.error("[OCRService] Error uploading image:", error);
      throw error;
    }
  },

  processImage: async (imageUrl, shipmentId) => {
    try {
      // Extract the URL from the object if needed
      const url = typeof imageUrl === "object" ? imageUrl.signedUrl : imageUrl;

      console.log("[OCRService] Processing image with URL:", url);
      console.log("[OCRService] Shipment ID:", shipmentId);

      // Validate URL format
      try {
        new URL(url);
      } catch (e) {
        throw new Error(`Invalid URL format: ${url}`);
      }

      console.log(
        "[OCRService] Invoking OCR edge function with body:",
        JSON.stringify({ imageUrl: url, shipmentId })
      );

      const { data, error } = await supabase.functions.invoke("ocr", {
        body: JSON.stringify({ imageUrl: url, shipmentId }),
      });

      if (error) {
        console.error("[OCRService] Error invoking OCR function:", error);
        throw new Error(`Error invoking OCR function: ${error.message}`);
      }

      console.log(
        "[OCRService] OCR function raw response:",
        JSON.stringify(data, null, 2)
      );

      // Check for Eden AI specific errors
      if (data?.microsoft?.error) {
        const msError = data.microsoft.error;
        if (msError.type === "ProviderInvalidInputFileSizeError") {
          throw new Error(
            "The image file is too large for OCR processing. Please try with a smaller image (try compressing the image or reducing its dimensions)."
          );
        }
        throw new Error(`OCR processing failed: ${msError.message}`);
      }

      return data;
    } catch (error) {
      console.error("Error processing OCR:", error);
      throw error;
    }
  },
};

export default OCRService;
