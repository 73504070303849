import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Merge class names with tailwind classes
 * This is used by shadcn UI components
 */
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

/**
 * Validate email format
 * @param {string} email - Email to validate
 * @returns {boolean} Whether the email is valid
 */
export const validateEmail = (email) => {
  if (!email) return true; // Optional field
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

/**
 * Validate phone number format
 * @param {string} phone - Phone number to validate
 * @returns {boolean} Whether the phone number is valid
 */
export const validatePhone = (phone) => {
  if (!phone) return true; // Optional field
  // Accepts formats: (123) 456-7890, 123-456-7890, 1234567890
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return phoneRegex.test(phone);
};

/**
 * Validate DOT number format
 * @param {string} dot - DOT number to validate
 * @returns {boolean} Whether the DOT number is valid
 */
export const validateDOT = (dot) => {
  if (!dot) return false; // Required field
  const dotRegex = /^\d{1,8}$/;
  return dotRegex.test(dot);
};

/**
 * Extract and parse verification details data from carrier record
 * @param {Object} supabase - Supabase client instance
 * @param {string} carrierId - ID of the carrier to fetch verification details for
 * @returns {Promise<Object | null>} Object containing parsed verification data or null
 */
export const extractVerificationDetails = async (supabase, carrierId) => {
  if (!carrierId) return null;

  try {
    console.log(
      "🔍 Extracting report data from verification_details for carrier:",
      carrierId
    );

    // Get the carrier details with verification_details
    const { data: carrierWithDetails, error: carrierError } = await supabase
      .from("carriers")
      .select("id, verification_details")
      .eq("id", carrierId)
      .single();

    if (carrierError) {
      console.error(
        "❌ Error fetching carrier verification details:",
        carrierError
      );
      return {
        error: `Error fetching carrier details: ${carrierError.message}`,
      };
    }

    if (!carrierWithDetails || !carrierWithDetails.verification_details) {
      console.log("⚠️ No verification_details found for carrier:", carrierId);
      return {
        verificationDetails: null,
        /** @type {{ phoneRiskReport: any, emailRiskReport: any, businessReport: any }} */
        displayData: {
          phoneRiskReport: null,
          emailRiskReport: null,
          businessReport: null,
        },
      };
    }

    const verificationDetails = carrierWithDetails.verification_details;
    console.log(
      "📊 Verification details structure type:",
      typeof verificationDetails
    );

    // If it's a string, try to parse it
    let parsedDetails = verificationDetails;
    if (typeof verificationDetails === "string") {
      try {
        parsedDetails = JSON.parse(verificationDetails);
        console.log("📊 Parsed verification details:", parsedDetails);
      } catch (parseError) {
        console.error(
          "❌ Error parsing verification_details JSON:",
          parseError
        );
        return {
          error: `Error parsing verification details: ${parseError.message}`,
        };
      }
    }

    // Prepare display data for UI with proper typing
    /** @type {{ phoneRiskReport: any, emailRiskReport: any, businessReport: any }} */
    const displayData = {
      phoneRiskReport: null,
      emailRiskReport: null,
      businessReport: null,
    };

    // Handle phone risk report data
    if (parsedDetails.phone_risk_report) {
      console.log("📱 Found phone risk report in verification_details");

      try {
        // Extract phone data from the specific structure we now know
        const phoneReport = parsedDetails.phone_risk_report;
        let phoneData = {};

        // Extract from the known structure based on the provided example
        if (
          phoneReport.results &&
          phoneReport.results.rawData &&
          phoneReport.results.rawData.data &&
          phoneReport.results.rawData.data.attributes
        ) {
          // Structure path: results.rawData.data.attributes
          const attributes = phoneReport.results.rawData.data.attributes;
          phoneData = {
            phoneNumber: attributes["phone-number"],
            phoneType: attributes["phone-type"],
            phoneCarrier: attributes["phone-carrier"],
            phoneRiskLevel: attributes["phone-risk-level"],
            phoneRiskScore: attributes["phone-risk-score"],
            phoneRiskRecommendation:
              attributes["phone-risk-recommendation"],
          };
        }
        // Try the flattened structure that's directly in results
        else if (phoneReport.results) {
          phoneData = {
            phoneNumber: phoneReport.results.phoneNumber,
            phoneType: phoneReport.results.phoneType,
            phoneCarrier: phoneReport.results.phoneCarrier,
            phoneRiskLevel: phoneReport.results.phoneRiskLevel,
            phoneRiskScore: phoneReport.results.phoneRiskScore,
            phoneRiskRecommendation:
              phoneReport.results.phoneRiskRecommendation,
          };
        }

        console.log("📱 Extracted phone data:", phoneData);

        displayData.phoneRiskReport = {
          data: phoneData,
          event_type: phoneReport.event_type,
          resource_id: phoneReport.resource_id,
          reference_id: phoneReport.reference_id,
          last_update: phoneReport.last_update,
          status: phoneReport.status,
        };
      } catch (phoneError) {
        console.error("❌ Error processing phone risk report:", phoneError);
      }
    }

    // Handle email risk report data
    if (parsedDetails.email_risk_report) {
      console.log("📧 Found email risk report in verification_details");

      try {
        // Extract email data from the specific structure we now know
        const emailReport = parsedDetails.email_risk_report;
        let emailData = {};

        // Extract from the known structure based on the provided example
        if (
          emailReport.results &&
          emailReport.results.rawData &&
          emailReport.results.rawData.data &&
          emailReport.results.rawData.data.attributes
        ) {
          // Structure path: results.rawData.data.attributes
          const attributes = emailReport.results.rawData.data.attributes;
          emailData = {
            emailAddress: attributes["email-address"],
            emailDomain: attributes["email-domain"],
            emailReputation: attributes["email-reputation"],
            emailIsDisposable: attributes["email-is-disposable"],
            emailIsDeliverable: attributes["email-is-deliverable"],
            emailDomainAgeDays: attributes["email-domain-age-days"],
          };
        }
        // Try direct access to attributes in case the structure is flattened
        else if (
          emailReport.results &&
          emailReport.results.rawData &&
          emailReport.results.rawData.data
        ) {
          const data = emailReport.results.rawData.data;
          emailData = {
            emailAddress: data.attributes?.["email-address"],
            emailDomain: data.attributes?.["email-domain"],
            emailReputation: data.attributes?.["email-reputation"],
            emailIsDisposable: data.attributes?.["email-is-disposable"],
            emailIsDeliverable: data.attributes?.["email-is-deliverable"],
            emailDomainAgeDays: data.attributes?.["email-domain-age-days"],
          };
        }

        console.log("📧 Extracted email data:", emailData);

        displayData.emailRiskReport = {
          data: emailData,
          event_type: emailReport.event_type,
          resource_id: emailReport.resource_id,
          reference_id: emailReport.reference_id,
          last_update: emailReport.last_update,
          status: emailReport.status,
        };
      } catch (emailError) {
        console.error("❌ Error processing email risk report:", emailError);
      }
    }

    // Extract business report data for display (the full report is passed to the hook)
    if (parsedDetails.business_report) {
      console.log("🏢 Found business report in verification_details");

      try {
        const businessReport = parsedDetails.business_report;

        // Extract useful business data to display
        const businessData = {
          businessName:
            businessReport.results?.rawData?.data?.attributes?.[
              "business-name"
            ] ||
            businessReport.results?.rawData?.data?.attributes
              ?.businessName ||
            "N/A",
          status: businessReport.status || "N/A",
          registrationNumber:
            businessReport.results?.rawData?.data?.attributes?.[
              "registration-number"
            ] ||
            businessReport.results?.rawData?.data?.attributes
              ?.registrationNumber ||
            "N/A",
          addressCity:
            businessReport.results?.rawData?.data?.attributes?.[
              "address-city"
            ] ||
            businessReport.results?.rawData?.data?.attributes
              ?.addressCity ||
            "N/A",
          addressStreet:
            businessReport.results?.rawData?.data?.attributes?.[
              "address-street-1"
            ] ||
            businessReport.results?.rawData?.data?.attributes
              ?.addressStreet1 ||
            "N/A",
          addressSubdivision:
            businessReport.results?.rawData?.data?.attributes?.[
              "address-subdivision"
            ] ||
            businessReport.results?.rawData?.data?.attributes
              ?.addressSubdivision ||
            "N/A",
          addressPostalCode:
            businessReport.results?.rawData?.data?.attributes?.[
              "address-postal-code"
            ] ||
            businessReport.results?.rawData?.data?.attributes
              ?.addressPostalCode ||
            "N/A",
        };

        console.log("🏢 Extracted business data:", businessData);

        displayData.businessReport = {
          data: businessData,
          event_type: businessReport.event_type,
          resource_id: businessReport.resource_id,
          reference_id: businessReport.reference_id,
          last_update: businessReport.last_update,
          status: businessReport.status,
        };
      } catch (businessError) {
        console.error(
          "❌ Error processing business report:",
          businessError
        );
      }
    }

    return {
      verificationDetails: parsedDetails,
      /** @type {{ phoneRiskReport: any, emailRiskReport: any, businessReport: any }} */
      displayData: displayData,
    };
  } catch (error) {
    console.error("❌ Error in extractVerificationDetails:", error);
    return { 
      error: `Error processing verification details: ${error.message}`,
      /** @type {{ phoneRiskReport: any, emailRiskReport: any, businessReport: any }} */
      displayData: {
        phoneRiskReport: null,
        emailRiskReport: null,
        businessReport: null,
      }
    };
  }
};
