// @ts-nocheck
import { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../lib/supabase"; // Add this import
import OCRService from "../api/services/core/OCRService";

// Convert ESM import to CommonJS require for VerificationService
const {
  checkVerificationStatus,
  performVerificationOCR,
  getVerificationStatus,
  updateVerificationRecord,
  getVerificationStrategy,
  getAllVerifications,
  getVerificationById,
  updateVerification: updateVerificationService, // <-- RENAMED IMPORT
} = require("../api/services/verification/VerificationService");

/**
 * @typedef {Object} VerificationState
 * @property {boolean} personaComplete - Whether the Persona verification is complete
 * @property {boolean} personaSuccess - Whether the Persona verification was successful
 * @property {boolean} ocrComplete - Whether the OCR verification is complete
 * @property {boolean} ocrSuccess - Whether the OCR verification was successful
 * @property {number} progress - Progress percentage (0-100)
 * @property {boolean} isComplete - Whether the entire verification process is complete
 * @property {string|null} personaVerificationURL - URL for Persona verification
 */

/**
 * Hook to manage verification state for a specific shipment
 * @param {string} shipmentId - The ID of the shipment
 * @returns {Object} The verification state and functions to manipulate it
 */
export const useVerificationState = (shipmentId) => {
  const [verificationState, setVerificationState] = useState({
    personaComplete: false,
    personaSuccess: false,
    ocrComplete: false,
    ocrSuccess: false,
    progress: 0,
    isComplete: false,
    personaVerificationURL: null,
  });
  const [error, setError] = useState(null);

  const fetchState = useCallback(async () => {
    if (!shipmentId) return;

    try {
      // Use the service instead of direct Supabase access
      const data = await getVerificationStatus(shipmentId);

      if (!data) {
        throw new Error(`No verification found for shipment ID: ${shipmentId}`);
      }

      setVerificationState({
        personaComplete: data.persona_verification_complete,
        personaSuccess: data.state === "persona_verified",
        ocrComplete: data.ocr_verification_complete,
        ocrSuccess: data.state === "ocr_verified",
        progress: calculateProgress(data),
        isComplete: data.state === "completed",
        personaVerificationURL: data.persona_verification_url,
      });
    } catch (error) {
      console.error("Error fetching verification state:", error);
      setError(error);
    }
  }, [shipmentId]);

  useEffect(() => {
    if (shipmentId) {
      fetchState();
    }
  }, [fetchState, shipmentId]);

  const refresh = useCallback(() => {
    fetchState();
  }, [fetchState]);

  const updateVerificationState = useCallback(
    async (newState) => {
      if (!shipmentId) return;

      try {
        // Use the service instead of direct Supabase access
        await updateVerificationService(shipmentId, newState);

        // Update local state
        setVerificationState((prevState) => ({ ...prevState, ...newState }));

        // Refresh to get latest data
        fetchState();
      } catch (error) {
        console.error("Error updating verification state:", error);
        setError(error);
      }
    },
    [fetchState, shipmentId]
  );

  return { verificationState, updateVerificationState, refresh, error };
};

/**
 * Hook for handling image capture for verification
 * @returns {Object} Functions and state for image capture
 */
export const useImageCapture = () => {
  const [capturedImage, setCapturedImage] = useState(null); // Data URL
  const [capturedFile, setCapturedFile] = useState(null); // File object
  const [error, setError] = useState(null);

  const captureImage = useCallback((file) => {
    setError(null); // Reset error state

    if (!(file instanceof File)) {
      setError("Invalid file type. Please select an image file.");
      return;
    }

    // Store the original File object
    setCapturedFile(file);

    // Read the file as a Data URL for display
    const reader = new FileReader();
    reader.onload = (e) => {
      setCapturedImage(e.target.result);
    };
    reader.onerror = () => {
      setError("Failed to read the file. Please try again.");
    };
    reader.readAsDataURL(file);
  }, []);

  const resetImage = useCallback(() => {
    setCapturedImage(null);
    setCapturedFile(null);
    setError(null);
  }, []);

  return { capturedImage, capturedFile, captureImage, resetImage, error };
};

/**
 * Start Persona verification using the provided URL
 * @param {Object} verificationState - The current verification state
 * @returns {Promise<void>}
 */
export const startPersonaVerification = async (verificationState) => {
  try {
    console.log("Verification state:", verificationState);
    const oneTimeLink = verificationState.personaVerificationURL;

    if (!oneTimeLink) {
      throw new Error("Persona verification URL is not available");
    }

    console.log("Redirecting to:", oneTimeLink);
    window.open(oneTimeLink);
  } catch (error) {
    console.error("Error starting Persona verification:", error);
  }
};

/**
 * Hook to fetch verification data for a specific shipment
 * @param {string} shipmentId - The ID of the shipment
 * @returns {Object} The query result
 */
export const useVerification = (shipmentId) => {
  console.log(`[useVerification] Hook called with shipmentId: "${shipmentId}"`);

  return useQuery({
    queryKey: ["verification", shipmentId],
    queryFn: async () => {
      console.log(
        `[useVerification] queryFn executing with shipmentId: "${shipmentId}"`
      );

      if (!shipmentId) {
        console.log(`[useVerification] No shipmentId provided, returning null`);
        return null;
      }

      // Use the service instead of direct Supabase access
      console.log(
        `[useVerification] Calling getVerificationStatus with shipmentId: "${shipmentId}"`
      );
      const data = await getVerificationStatus(shipmentId);
      console.log(`[useVerification] getVerificationStatus returned:`, data);

      return data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
    enabled: !!shipmentId,
  });
};

/**
 * Hook to fetch verification data by Persona inquiry ID
 * @param {string} inquiry_id - The Persona inquiry ID
 * @returns {Object} The query result
 */
export const useVerificationByInquiryId = (inquiry_id) => {
  return useQuery({
    queryKey: ["verification-by-inquiry", inquiry_id],
    queryFn: async () => {
      if (!inquiry_id) {
        console.warn("No inquiry_id provided to useVerificationByInquiryId");
        return null;
      }

      try {
        console.log("Fetching verification for inquiry ID:", inquiry_id);

        // Use the strategy pattern to get the appropriate service
        const verificationStrategy = getVerificationStrategy("driver_id"); // This should be determined based on context
        const verification =
          await verificationStrategy.getVerificationByInquiryId(inquiry_id);

        if (!verification) {
          console.warn(`No verification found for inquiry ID: ${inquiry_id}`);
          return null;
        }

        console.log(
          "Successfully fetched verification by inquiry ID:",
          verification
        );
        return verification;
      } catch (error) {
        console.error("Error in useVerificationByInquiryId:", error);
        throw error;
      }
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
    enabled: !!inquiry_id, // Only run the query if inquiry_id exists
    retry: 1, // Only retry once to avoid excessive retries for non-existent records
  });
};

/**
 * Hook for inquiry-based verification mutations
 * @returns {Object} Mutation functions
 */
export const useInquiryVerificationMutations = () => {
  const queryClient = useQueryClient();

  const updateVerification = useMutation({
    mutationFn: async ({ inquiry_id, data }) => {
      console.log("Triggering updateVerification hook", inquiry_id, data);

      if (!inquiry_id) {
        throw new Error("Missing inquiry_id in updateVerification mutation");
      }

      // Validate and sanitize the update data
      const validStates = [
        "created",
        "Started",
        "pending",
        "Complete",
        "Approved",
        "Declined",
        "Expired",
        "persona_complete",
        "ocr_complete",
        "error",
      ];

      // Sanitize the input data to prevent schema validation errors
      const sanitizedData = {
        // Only include defined fields
        ...(data.state && {
          state: validStates.includes(data.state) ? data.state : "pending",
        }),
        ...(typeof data.persona_verification_complete === "boolean" && {
          persona_verification_complete: data.persona_verification_complete,
        }),
        ...(data.ocr_verification_complete !== undefined && {
          ocr_verification_complete: Boolean(data.ocr_verification_complete),
        }),
        updated_at: new Date().toISOString(),
      };

      console.log("Sanitized update data:", sanitizedData);

      try {
        // Use the service method directly
        // --- MODIFIED START ---
        // 1. Get the appropriate verification strategy
        //    (Assuming 'all' strategy works for finding by inquiry ID initially)
        //    We might need a better way to determine the strategy contextually.
        const verificationStrategy = getVerificationStrategy("all");

        // 2. Fetch the verification record using the inquiry_id
        const existingVerification =
          await verificationStrategy.getVerificationByInquiryId(inquiry_id);
        // --- MODIFIED END ---

        if (!existingVerification) {
          throw new Error(
            `Verification record not found for inquiry ID: ${inquiry_id}`
          );
        }

        // Update the verification using the generic update function
        // --- MODIFIED --- Use the actual UUID (existingVerification.id)
        // --- MODIFIED --- Use renamed service function
        const updatedData = await updateVerificationService(
          existingVerification.id, // Use the UUID
          sanitizedData
        );

        console.log("Successfully updated verification:", updatedData);
        return updatedData;
      } catch (error) {
        console.error("Unexpected error in updateVerification:", error);
        throw error;
      }
    },
    onSuccess: (data, variables) => {
      console.log("Verification update successful, invalidating queries");
      queryClient.invalidateQueries([
        "verification-by-inquiry",
        variables.inquiry_id,
      ]);
    },
    onError: (error, variables) => {
      console.error("Mutation error in updateVerification:", error);
      console.log(
        "Failed update attempt for inquiry ID:",
        variables.inquiry_id
      );
    },
  });

  const checkStatus = useMutation({
    mutationFn: async ({ inquiryId }) => {
      console.log(`Starting checkStatus mutation for inquiry ID: ${inquiryId}`);

      if (!inquiryId) {
        console.error("Missing inquiryId in checkStatus mutation");
        throw new Error("Missing inquiryId in checkStatus mutation");
      }

      try {
        // --- MODIFIED START ---
        // 1. Use the verification service to get status from Persona
        const result = await checkVerificationStatus(inquiryId);
        console.log("Check status result:", result);

        // Add DETAILED debugging
        console.log("DEBUG - Check result structure:", {
          hasResult: !!result,
          resultType: typeof result,
          status: result?.status,
          statusType: typeof result?.status,
          success: result?.success,
          message: result?.message,
        });

        // 2. Fetch the local DB record corresponding to the inquiryId
        const verificationStrategy = getVerificationStrategy("all");
        const existingVerification =
          await verificationStrategy.getVerificationByInquiryId(inquiryId);

        // Add DETAILED debugging
        console.log("DEBUG - Verification lookup:", {
          inquiryId,
          foundVerification: !!existingVerification,
          verificationId: existingVerification?.id,
          currentState: existingVerification?.state,
        });

        if (existingVerification && result?.status) {
          // 3. Update the local DB record with the fetched status
          console.log(
            `Updating local verification ${existingVerification.id} with status: ${result.status}`
          );
          await updateVerificationService(existingVerification.id, {
            state: result.status, // The checkVerificationStatus returns a top-level status field
            persona_verification_complete: result.success, // Use success flag directly from result
            updated_at: new Date().toISOString(),
          });

          // 4. If this is a business owner verification, update the business owner record too
          if (
            existingVerification.verification_type ===
              "business_owner_verification" &&
            existingVerification.carrier_id
          ) {
            try {
              console.log(
                `This is a business owner verification. Updating carrier_business_owners record...`
              );

              // Find business owner(s) that might have verification records for this carrier
              const { data: businessOwners, error: boError } = await supabase
                .from("carrier_business_owners")
                .select("*")
                .eq("carrier_id", existingVerification.carrier_id);

              if (boError) {
                console.error("Error fetching business owners:", boError);
              } else if (businessOwners && businessOwners.length > 0) {
                console.log(
                  `Found ${businessOwners.length} business owners for carrier ${existingVerification.carrier_id}`
                );

                // For each business owner, update their verification_result if needed
                for (const owner of businessOwners) {
                  // Check if this owner's verification_result needs to be updated
                  if (
                    owner.verification_result?.inquiry_id !==
                    existingVerification.persona_inquiry_id
                  ) {
                    console.log(
                      `Updating business owner ${owner.id} verification result`
                    );

                    // Create new verification_result
                    const newVerificationResult = {
                      passed: result.success,
                      status: result.status,
                      inquiry_id: existingVerification.persona_inquiry_id,
                      verified_at: new Date().toISOString(),
                      reference_id: owner.id, // Use the owner ID as reference
                    };

                    // Update the business owner record
                    const { error: updateError } = await supabase
                      .from("carrier_business_owners")
                      .update({ verification_result: newVerificationResult })
                      .eq("id", owner.id);

                    if (updateError) {
                      console.error(
                        `Error updating business owner verification result:`,
                        updateError
                      );
                    } else {
                      console.log(
                        `Successfully updated business owner ${owner.id} verification result`
                      );
                    }
                  }
                }
              } else {
                console.log(
                  `No business owners found for carrier ${existingVerification.carrier_id}`
                );
              }
            } catch (boUpdateError) {
              console.error(
                "Error handling business owner verification update:",
                boUpdateError
              );
            }
          }
        } else if (!existingVerification) {
          console.warn(
            `Local verification record not found for inquiry ID: ${inquiryId}. Cannot update status.`
          );
        } else {
          console.warn(
            `No status found in checkVerificationStatus result for inquiry ID: ${inquiryId}. Cannot update status.`
          );
        }

        // 4. Return the original status check result
        return result;
        // --- MODIFIED END ---
      } catch (error) {
        console.error("Error in checkStatus mutation:", error);
        throw error;
      }
    },
    onSuccess: (data, variables) => {
      console.log("Status check successful, invalidating queries");
      queryClient.invalidateQueries([
        "verification-by-inquiry",
        variables.inquiryId,
      ]);
    },
    onError: (error, variables) => {
      console.error("Mutation error in checkStatus:", error);
      console.log("Failed status check for inquiry ID:", variables.inquiryId);
    },
  });

  return { updateVerification, checkStatus };
};

/**
 * Hook for shipment-based verification mutations
 * @returns {Object} Mutation functions
 */
export const useVerificationMutations = () => {
  const queryClient = useQueryClient();

  const updateVerification = useMutation({
    mutationFn: async ({ shipmentId, data }) => {
      console.log("Triggering updateVerification hook", { shipmentId, data });
      try {
        // Use the specific service function `updateVerificationRecord`
        // which correctly handles updates based on shipmentId
        const updatedData = await updateVerificationRecord(shipmentId, data);

        console.log("Successfully updated verification:", updatedData);
        return updatedData;
      } catch (error) {
        console.error("Unexpected error in updateVerification:", error);
        throw error;
      }
    },
    onSuccess: (data, variables) => {
      console.log("Verification update successful, invalidating queries");
      queryClient.invalidateQueries({
        queryKey: ["verification", variables.shipmentId],
      });
    },
    onError: (error, variables) => {
      console.error("Mutation error in updateVerification:", error);
      console.log(
        "Failed update attempt for shipment ID:",
        variables.shipmentId
      );
    },
  });

  const processOCR = useMutation({
    mutationFn: async ({ shipmentId, file }) => {
      if (!shipmentId || !file) {
        throw new Error("Missing required parameters for OCR processing");
      }

      console.log(
        "[useVerifications] Starting OCR processing for shipment:",
        shipmentId
      );
      console.log("[useVerifications] File:", file);

      try {
        // First upload the image
        const uploadResult = await OCRService.uploadImage(file, shipmentId);
        console.log(
          "[useVerifications] Image uploaded successfully:",
          uploadResult
        );

        // Then process the OCR with the upload result
        const result = await performVerificationOCR(
          shipmentId,
          file,
          uploadResult.signedUrl
        );
        console.log("[useVerifications] OCR processing completed:", result);

        return result;
      } catch (error) {
        console.error("[useVerifications] Error in OCR processing:", error);
        throw error;
      }
    },
    onSuccess: (data, variables) => {
      console.log(
        "[useVerifications] OCR processing successful, invalidating queries"
      );
      queryClient.invalidateQueries(["verification", variables.shipmentId]);
    },
    onError: (error) => {
      console.error("[useVerifications] Error processing OCR:", error);
    },
  });

  const checkStatus = useMutation({
    mutationFn: async ({ shipmentId }) => {
      if (!shipmentId) {
        throw new Error("Missing shipmentId parameter");
      }

      // Use the service instead of direct Supabase access
      const result = await checkVerificationStatus(shipmentId);
      console.log("Check status result", result);

      return result;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["verification", variables.shipmentId]);
    },
    onError: (error) => {
      console.error("Error checking verification status:", error);
    },
  });

  return { updateVerification, processOCR, checkStatus };
};

/**
 * Helper function to calculate verification progress
 * @param {Object} data - The verification data
 * @returns {number} Progress percentage (0-100)
 */
function calculateProgress(data) {
  let progress = 0;
  if (data.persona_verification_complete) progress += 50;
  if (data.ocr_verification_complete) progress += 50;
  return progress;
}

/**
 * Hook to fetch all verifications
 * @returns {Object} The query result
 */
export const useGetAllVerifications = () => {
  return useQuery({
    queryKey: ["verifications"],
    queryFn: async () => {
      // Use the service method directly
      const data = await getAllVerifications();
      return data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
  });
};

/**
 * Hook to fetch verification data for business and insurance by carrier ID and inquiry ID
 * @param {string} carrier_id - The carrier ID
 * @param {string} inquiry_id - The Persona inquiry ID
 * @returns {Object} The query result
 */
export const useVerificationBusinessAndInsurance = (carrier_id, inquiry_id) => {
  return useQuery({
    queryKey: ["verification", inquiry_id],
    queryFn: async () => {
      if (!inquiry_id) return null;

      // Use the business verification strategy
      const verificationStrategy = getVerificationStrategy(
        "business_verification"
      );
      const data = await verificationStrategy.getVerificationByInquiryId(
        inquiry_id
      );

      return data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
    enabled: !!inquiry_id && !!carrier_id,
  });
};
