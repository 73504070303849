import VetInsuranceDocumentation from "./VetInsuranceDocumentation";

const VetInsuranceDocumentationForm = () => {
  return (
    <div className="min-h-screen bg-white flex flex-row">
      <VetInsuranceDocumentation />
    </div>
  );
};

export default VetInsuranceDocumentationForm;
