// @ts-nocheck
import * as React from "react";
import { Card } from "@/components/ui/card";
import { UserCheck, UserX, UserCog, User } from "lucide-react";
import { Clock } from "lucide-react";

/**
 * StatCard - A compact card displaying a single statistic with an icon
 */
function StatCard({ title, value, icon: Icon, description, className = "" }) {
  return (
    <Card className={`p-4 relative ${className}`}>
      <div className="mb-2 flex justify-between items-center">
        <div className="text-sm font-medium">{title}</div>
        {Icon && <Icon className="h-5 w-5 text-gray-500" aria-hidden="true" />}
      </div>
      <div className="text-3xl font-bold mb-1">{value}</div>
      {description && <p className="text-xs text-gray-500">{description}</p>}
    </Card>
  );
}

/**
 * DriversStats - Displays summary statistics for drivers
 *
 * @param {Object} props - Component props
 * @param {Array} props.data - Array of driver data
 */
const DriversStats = ({ data = [] }) => {
  // Calculate statistics based on driver data
  const stats = React.useMemo(() => {
    const total = data.length;

    const activeDrivers = data.filter(
      (driver) => driver.employment_status === "Active"
    ).length;

    const inactiveDrivers = data.filter(
      (driver) => driver.employment_status === "Inactive"
    ).length;

    const blockedDrivers = data.filter(
      (driver) => driver.employment_status === "Blocked"
    ).length;

    const verifiedDrivers = data.filter(
      (driver) => driver.is_verified === true
    ).length;

    return {
      total,
      activeDrivers,
      inactiveDrivers,
      blockedDrivers,
      verifiedDrivers,
    };
  }, [data]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
      <StatCard
        title="Total Drivers"
        value={stats.total}
        icon={User}
        description="All drivers"
        className="border-l-2 border-l-slate-400"
      />
      <StatCard
        title="Active Drivers"
        value={stats.activeDrivers}
        icon={UserCheck}
        description="Currently employed"
        className="border-l-2 border-l-emerald-200"
      />
      <StatCard
        title="Inactive Drivers"
        value={stats.inactiveDrivers}
        icon={Clock}
        description="Temporarily inactive"
        className="border-l-2 border-l-amber-200"
      />
      <StatCard
        title="Verified Drivers"
        value={stats.verifiedDrivers}
        icon={UserCog}
        description="Identity verified"
        className="border-l-2 border-l-[#119DA4]"
      />
      <StatCard
        title="Blocked Drivers"
        value={stats.blockedDrivers}
        icon={UserX}
        description="Access blocked"
        className="border-l-2 border-l-rose-200"
      />
    </div>
  );
};

export default DriversStats;
