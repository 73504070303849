import axios from "axios";

const FMSCA_WEBKEY = process.env.REACT_APP_FMSCA_WEBKEY;
const API_URL = "https://mobile.fmcsa.dot.gov/qc/services";

/**
 * Create a dedicated axios instance
 * with a base URL and default params.
 */
const fmscaApi = axios.create({
  baseURL: API_URL,
  params: {
    webKey: FMSCA_WEBKEY,
  },
});

/**
 * (Optional) Interceptor example:
 *
 * fmscaApi.interceptors.response.use(
 *   response => response,
 *   error => {
 *     // Handle the error globally, log, or transform
 *     console.error("FMSCA API error:", error);
 *     return Promise.reject(error);
 *   }
 * );
 */

/**
 * Helper to handle GET requests with standardized error handling.
 * @param {string} endpoint - The API endpoint (relative to baseURL).
 * @param {string} errorMessage - A custom error message for logging.
 * @returns {Promise<any>} The response data.
 */
const getRequest = async (endpoint, errorMessage) => {
  try {
    const response = await fmscaApi.get(endpoint);
    return response.data;
  } catch (error) {
    console.error(errorMessage, error);
    throw error; // Re-throw so calling code can handle it
  }
};

const FMSCAService = {
  /**
   * Fetch carrier details by name.
   * @param {string} name - Carrier name.
   * @returns {Promise<Object>}
   */
  getCarrierByName(name) {
    const endpoint = `/carriers/name/${encodeURIComponent(name)}`;
    return getRequest(endpoint, "Error fetching carrier by name:");
  },

  /**
   * Fetch carrier details by DOT number.
   * @param {string} dotNumber - DOT Number.
   * @returns {Promise<Object>}
   */
  getCarrierByDOT(dotNumber) {
    const endpoint = `/carriers/${dotNumber}`;
    return getRequest(endpoint, "Error fetching carrier by DOT number:");
  },

  /**
   * Fetch carrier details by Docket Number.
   * @param {string} docketNumber - Docket Number.
   * @returns {Promise<Object>}
   */
  getCarrierByDocket(docketNumber) {
    const endpoint = `/carriers/docket-number/${docketNumber}`;
    return getRequest(endpoint, "Error fetching carrier by docket number:");
  },

  /**
   * Fetch carrier BASIC safety information.
   * @param {string} dotNumber - DOT Number.
   * @returns {Promise<Object>}
   */
  getCarrierBasics(dotNumber) {
    const endpoint = `/carriers/${dotNumber}/basics`;
    return getRequest(endpoint, "Error fetching carrier BASICs:");
  },

  /**
   * Fetch cargo carried by a carrier.
   * @param {string} dotNumber - DOT Number.
   * @returns {Promise<Object>}
   */
  getCarrierCargoCarried(dotNumber) {
    const endpoint = `/carriers/${dotNumber}/cargo-carried`;
    return getRequest(endpoint, "Error fetching carrier cargo carried:");
  },

  /**
   * Fetch carrier operation classification.
   * @param {string} dotNumber - DOT Number.
   * @returns {Promise<Object>}
   */
  getCarrierOperationClassification(dotNumber) {
    const endpoint = `/carriers/${dotNumber}/operation-classification`;
    return getRequest(
      endpoint,
      "Error fetching carrier operation classification:"
    );
  },

  /**
   * Fetch carrier out-of-service information.
   * @param {string} dotNumber - DOT Number.
   * @returns {Promise<Object>}
   */
  getCarrierOOS(dotNumber) {
    const endpoint = `/carriers/${dotNumber}/oos`;
    return getRequest(endpoint, "Error fetching carrier OOS:");
  },

  /**
   * Fetch all docket numbers for a carrier.
   * @param {string} dotNumber - DOT Number.
   * @returns {Promise<Object>}
   */
  getCarrierDocketNumbers(dotNumber) {
    const endpoint = `/carriers/${dotNumber}/docket-numbers`;
    return getRequest(endpoint, "Error fetching carrier docket numbers:");
  },

  /**
   * Fetch carrier authority details.
   * @param {string} dotNumber - DOT Number.
   * @returns {Promise<Object>}
   */
  getCarrierAuthority(dotNumber) {
    const endpoint = `/carriers/${dotNumber}/authority`;
    return getRequest(endpoint, "Error fetching carrier authority details:");
  },
};

export default FMSCAService;
