import React, { useState, useMemo, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { Input } from "../../components/ui/input";
import {
  Clock,
  TruckIcon,
  ArrowDownToLine,
  ArrowUpFromLine,
  CheckCircle,
  XCircle,
  AlertTriangle,
  Calendar,
  UserCheck,
  Package,
  RefreshCw,
  Filter,
  Search,
  MoreHorizontal,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Label } from "../../components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../components/ui/command";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../lib/utils";

// Import custom hooks
import { useFetchDocks, useUpdateDockStatus } from "../../hooks/useDocks";
// Import the shipments and drivers hooks
import { useShipments, useShipmentMutations } from "../../hooks/useShipments";
import { useDrivers } from "../../hooks/useDrivers";
import { useGetAllVerifications } from "../../hooks/useVerifications";

// Remove modal imports
// import { AssignDriverModal } from "./AssignDriverModal";
// import { ScheduleShipmentModal } from "./ScheduleShipmentModal";

// Status color mapping - Updated for muted appearance
const getStatusColor = (status) => {
  const colors = {
    available:
      "bg-emerald-100 text-emerald-800 dark:bg-emerald-900 dark:text-emerald-200",
    occupied:
      "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200",
    scheduled: "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200",
    maintenance:
      "bg-amber-100 text-amber-800 dark:bg-amber-900 dark:text-amber-200",
    Unverified:
      "bg-slate-100 text-slate-800 dark:bg-slate-700 dark:text-slate-200",
    Arrived:
      "bg-amber-100 text-amber-800 dark:bg-amber-900 dark:text-amber-200",
    "Check In":
      "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200",
    Loading:
      "bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200",
    Unloading:
      "bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200",
    "Check Out":
      "bg-teal-100 text-teal-800 dark:bg-teal-900 dark:text-teal-200",
    Departed:
      "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200",
    Completed:
      "bg-emerald-100 text-emerald-800 dark:bg-emerald-900 dark:text-emerald-200",
    Cancelled: "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200",
  };
  // Return border class if needed or keep as is
  return (
    colors[status] ||
    "bg-slate-100 text-slate-800 dark:bg-slate-700 dark:text-slate-200"
  );
};

// Status icon mapping
const getStatusIcon = (status) => {
  const icons = {
    available: <CheckCircle className="h-5 w-5 text-emerald-500" />,
    occupied: <Clock className="h-5 w-5 text-purple-500" />,
    scheduled: <Calendar className="h-5 w-5 text-blue-500" />,
    maintenance: <AlertTriangle className="h-5 w-5 text-amber-500" />,
  };
  return icons[status] || <Clock className="h-5 w-5" />;
};

// Type icon mapping
const getTypeIcon = (type) => {
  const icons = {
    standard: <TruckIcon className="h-4 w-4" />,
    refrigerated: <TruckIcon className="h-4 w-4 text-blue-500" />,
    oversized: <TruckIcon className="h-4 w-4 text-amber-500" />,
    hazmat: <AlertTriangle className="h-4 w-4 text-red-500" />,
  };
  return icons[type] || <TruckIcon className="h-4 w-4" />;
};

// Format time difference to show how long ago
const timeAgo = (date) => {
  const diff = Math.floor((new Date() - date) / 1000);
  if (diff < 60) return `${diff} sec ago`;
  if (diff < 3600) return `${Math.floor(diff / 60)} min ago`;
  if (diff < 86400) return `${Math.floor(diff / 3600)} hr ago`;
  return `${Math.floor(diff / 86400)} days ago`;
};

// Truncate text with ellipsis
const truncateText = (text, maxLength) => {
  if (!text) return "";
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

// Component for showing detailed information about a selected dock
const DockDetail = React.memo(
  ({ dock, shipments, onClose, onUpdateStatus }) => {
    const currentShipment = useMemo(() => {
      if (!shipments || shipments.length === 0) return null;

      // Look for a shipment assigned to this dock with an active status
      return shipments.find(
        (s) =>
          // Ensure consistent type comparison by converting both to strings
          String(s.dock_id) === String(dock.id) &&
          ["Arrived", "Check In", "Loading", "Unloading"].includes(s.status)
      );
    }, [dock.id, shipments]);

    // Find upcoming shipments for this dock
    const upcomingShipments = useMemo(() => {
      if (!shipments || shipments.length === 0) return [];

      return shipments
        .filter(
          (s) =>
            // Ensure consistent type comparison by converting both to strings
            String(s.dock_id) === String(dock.id) && s.status === "Scheduled"
        )
        .sort((a, b) => new Date(a.pickup_time) - new Date(b.pickup_time));
    }, [dock.id, shipments]);

    return (
      <Card className="w-full">
        <CardHeader className="pb-2 border-b">
          <div className="flex justify-between items-center">
            <CardTitle className="text-xl flex items-center gap-2">
              {dock.name}
              <Badge className={getStatusColor(dock.status)}>
                {dock.status.charAt(0).toUpperCase() + dock.status.slice(1)}
              </Badge>
              <Badge variant="outline">
                {dock.type.charAt(0).toUpperCase() + dock.type.slice(1)}
              </Badge>
            </CardTitle>
            <Button variant="ghost" size="sm" onClick={onClose}>
              <XCircle className="h-5 w-5" />
            </Button>
          </div>
        </CardHeader>

        <CardContent className="p-6">
          {/* Dock Information */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
            <div>
              <h3 className="text-lg font-semibold mb-3">Dock Information</h3>
              <div className="space-y-2 bg-muted/30 p-4 rounded-md">
                <div className="grid grid-cols-2 gap-1">
                  <div className="text-sm font-medium">ID</div>
                  <div className="text-sm">{dock.id}</div>
                </div>
                <div className="grid grid-cols-2 gap-1">
                  <div className="text-sm font-medium">Type</div>
                  <div className="text-sm flex items-center gap-1">
                    {getTypeIcon(dock.type)}
                    {dock.type.charAt(0).toUpperCase() + dock.type.slice(1)}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-1">
                  <div className="text-sm font-medium">Status</div>
                  <div className="text-sm">
                    <Badge className={getStatusColor(dock.status)}>
                      {dock.status.charAt(0).toUpperCase() +
                        dock.status.slice(1)}
                    </Badge>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-1">
                  <div className="text-sm font-medium">Last Updated</div>
                  <div className="text-sm">
                    {dock.updated_at
                      ? timeAgo(new Date(dock.updated_at))
                      : "Unknown"}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-1">
                  <div className="text-sm font-medium">Location</div>
                  <div className="text-sm">
                    {dock.location || "Warehouse main area"}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-1">
                  <div className="text-sm font-medium">Capacity</div>
                  <div className="text-sm">{dock.capacity || "Standard"}</div>
                </div>
              </div>
            </div>

            {/* Current Activity */}
            <div>
              <h3 className="text-lg font-semibold mb-3">Current Activity</h3>
              {currentShipment ? (
                <div className="space-y-3 bg-muted/30 p-4 rounded-md">
                  <div className="flex justify-between items-center">
                    <div className="text-base font-medium">
                      {currentShipment.reference ||
                        currentShipment.load_id ||
                        "Unknown"}
                    </div>
                    <Badge className={getStatusColor(currentShipment.status)}>
                      {currentShipment.status}
                    </Badge>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="text-sm font-medium">Carrier</div>
                    <div className="text-sm">
                      {currentShipment.carrier || "Unknown"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="text-sm font-medium">Direction</div>
                    <div className="text-sm">
                      {currentShipment.direction || "Unknown"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="text-sm font-medium">Driver</div>
                    <div className="text-sm">
                      {currentShipment.driver || "Not assigned"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="text-sm font-medium">Arrived</div>
                    <div className="text-sm">
                      {currentShipment.arrival_time
                        ? new Date(
                            currentShipment.arrival_time
                          ).toLocaleString()
                        : "Not recorded"}
                    </div>
                  </div>
                  <div className="mt-3 flex justify-end">
                    <Button variant="outline" size="sm">
                      Update Status
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="py-8 text-center text-muted-foreground bg-muted/30 rounded-md h-full flex flex-col items-center justify-center">
                  <Package className="h-12 w-12 mb-4 opacity-30" />
                  <h3 className="text-lg font-medium mb-2">
                    No Active Shipment
                  </h3>
                  {dock.status === "available" ? (
                    <p>This dock is available for assignment</p>
                  ) : dock.status === "maintenance" ? (
                    <p>This dock is currently under maintenance</p>
                  ) : dock.status === "occupied" ? (
                    <p>
                      This dock is marked as occupied but no active shipment
                      data is available
                    </p>
                  ) : (
                    <p>No current activity</p>
                  )}
                  {dock.status === "available" && (
                    <Button variant="outline" size="sm" className="mt-4">
                      Schedule Shipment
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Upcoming Shipments */}
          {upcomingShipments.length > 0 && (
            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-4">Upcoming Shipments</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {upcomingShipments.slice(0, 4).map((shipment) => (
                  <Card
                    key={shipment.id}
                    className="overflow-hidden border-l-4"
                    style={{
                      borderLeftColor:
                        getStatusColor(shipment.status).replace(
                          "bg-",
                          "rgb(var(--"
                        ) + "))",
                    }}
                  >
                    <CardContent className="p-4">
                      <div className="flex justify-between items-start">
                        <div>
                          <div className="font-medium">
                            {shipment.reference || shipment.load_id}
                          </div>
                          <div className="text-sm text-muted-foreground">
                            {shipment.carrier || "Unknown carrier"} •{" "}
                            {shipment.direction}
                          </div>
                        </div>
                        <div className="text-right">
                          <div className="text-sm font-medium">
                            {new Date(shipment.pickup_time).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </div>
                          <div className="text-xs text-muted-foreground">
                            {new Date(
                              shipment.pickup_time
                            ).toLocaleDateString()}
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 pt-2 border-t flex justify-between items-center">
                        <div className="text-xs text-muted-foreground">
                          {shipment.driver
                            ? `Driver: ${shipment.driver}`
                            : "No driver assigned"}
                        </div>
                        <Button variant="ghost" size="sm" className="h-7 px-2">
                          Details
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
              {upcomingShipments.length > 4 && (
                <div className="mt-3 text-center">
                  <Button variant="ghost" size="sm">
                    View All ({upcomingShipments.length}) Shipments
                  </Button>
                </div>
              )}
            </div>
          )}

          {/* Dock History (would be populated with real data in production) */}
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-4">Recent Activity</h3>
            <Card>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Time</TableHead>
                    <TableHead>Event</TableHead>
                    <TableHead>Details</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell className="text-xs">
                      {new Date().toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Badge variant="outline">Status Change</Badge>
                    </TableCell>
                    <TableCell>Status updated to {dock.status}</TableCell>
                  </TableRow>
                  {/* Additional mock history rows would be here */}
                </TableBody>
              </Table>
            </Card>
          </div>
        </CardContent>
      </Card>
    );
  }
);

// Main component
export function DockView() {
  const queryClient = useQueryClient();
  const [selectedDock, setSelectedDock] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [typeFilter, setTypeFilter] = useState("all");

  // State for tracking assigned drivers and shipments
  const [dockAssignments, setDockAssignments] = useState({});
  // Add state for tracking scheduled shipments
  const [scheduledShipments, setScheduledShipments] = useState({});

  // Modal State
  const [isAssignDriverModalOpen, setIsAssignDriverModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [selectedDockForAction, setSelectedDockForAction] = useState(null);

  // Assume companyId is obtained from context or props
  const companyId = 1;

  // Performance Note: Changed from enablePolling: true to refetchInterval: 30000 (30s)
  // Continuous polling can heavily impact performance. Consider WebSocket for real-time or adjust interval.
  // Use the hook to fetch docks
  const {
    data: docks,
    isLoading: isLoadingDocks,
    error: docksError,
    refetch: refetchDocks,
  } = useFetchDocks(
    companyId,
    { search: searchTerm, status: statusFilter, type: typeFilter },
    { refetchInterval: 30000 } // Refetch every 30 seconds
  );

  // Performance TODO: Fetching up to 1000 shipments is a major performance bottleneck.
  // Implement server-side filtering/pagination for the useShipments hook and this component.
  // Fetch shipments using the hook
  const {
    data: shipmentsData,
    isLoading: isLoadingShipments,
    refetch: refetchShipments,
  } = useShipments(1, 1000);

  // Get drivers using the drivers hook
  const { data: driversData, isLoading: isLoadingDrivers } = useDrivers();
  const { data: verificationsData, isLoading: isLoadingVerifications } =
    useGetAllVerifications({ companyId }, { enabled: !!companyId });

  // Extract the real data from the API responses
  const shipments = useMemo(() => {
    return shipmentsData?.shipments || [];
  }, [shipmentsData]);

  const drivers = useMemo(() => {
    return driversData?.drivers || [];
  }, [driversData]);

  const verifications = useMemo(() => {
    return verificationsData || [];
  }, [verificationsData]);

  // Additional debugging for scheduled shipments
  useEffect(() => {
    if (shipments?.length > 0) {
      const allScheduled = shipments.filter((s) => s.status === "Scheduled");
      console.log(
        `Found ${allScheduled.length} shipments with 'Scheduled' status in entire dataset`
      );

      // Log all shipments with verifications
      const shipmentsWithVerif = shipments.filter((s) =>
        verifications.some((v) => v.shipment_id === s.load_id)
      );

      console.log(
        `Found ${shipmentsWithVerif.length} shipments with verifications`
      );

      if (shipmentsWithVerif.length > 0) {
        console.log(
          "Shipments with verifications:",
          shipmentsWithVerif.map((s) => {
            const verification = verifications.find(
              (v) => v.shipment_id === s.load_id
            );
            return {
              id: s.id,
              load_id: s.load_id,
              dock_id: s.dock_id,
              status: s.status,
              verification_state: verification?.state,
              verification_id: verification?.id,
              is_complete:
                verification?.state === "Complete" ||
                verification?.state === "complete",
            };
          })
        );
      }

      if (allScheduled.length > 0) {
        console.log(
          "All scheduled shipments:",
          allScheduled.map((s) => ({
            id: s.id,
            load_id: s.load_id,
            status: s.status,
            dock_id: s.dock_id,
            has_verification: verifications?.some(
              (v) => v.shipment_id === s.load_id
            ),
          }))
        );
      }
    }
  }, [shipments, verifications]);

  // Mutation hook for updating status
  const updateDockStatusMutation = useUpdateDockStatus();

  const { updateShipment } = useShipmentMutations(); // Get mutation function

  // Memoize filtered docks to prevent unnecessary recalculations if only search/filters change but raw data doesn't
  const filteredDocks = useMemo(() => docks || [], [docks]);

  // Pre-calculate dock details for the table to avoid expensive computations inside the map function
  const docksWithDetails = useMemo(() => {
    if (!filteredDocks.length || !shipments || !verifications) {
      return [];
    }

    // Create lookup maps for faster access
    const verificationMap = new Map(
      verifications.map((v) => [v.shipment_id, v]) // Assuming v.shipment_id corresponds to shipment.load_id based on usage below
    );

    // Log for debugging
    console.log("All shipments in state:", shipments.length, "items");
    console.log("Verification records:", verifications.length, "items");

    // Log shipments with verification to see the relationship
    const shipmentsWithVerification = shipments.filter((s) =>
      verifications.some((v) => v.shipment_id === s.load_id)
    );
    console.log(
      "Shipments with verification:",
      shipmentsWithVerification.length
    );

    // Log a sample of the shipments for debugging
    console.log(
      "Sample shipments data:",
      shipments.slice(0, 3).map((s) => ({
        id: s.id,
        load_id: s.load_id,
        dock_id: s.dock_id,
        status: s.status,
        verification: verifications.find((v) => v.shipment_id === s.load_id)
          ? "Has verification"
          : "No verification",
      }))
    );

    // Check for dock assignments
    const shipmentsWithDocks = shipments.filter((s) => s.dock_id);
    console.log("Shipments with dock assignments:", shipmentsWithDocks.length);

    // Log detailed info about shipments with dock assignments
    if (shipmentsWithDocks.length > 0) {
      console.log(
        "Shipments with dock assignments details:",
        shipmentsWithDocks.map((s) => ({
          id: s.id,
          load_id: s.load_id,
          dock_id: s.dock_id,
          status: s.status,
          verification: verifications.find((v) => v.shipment_id === s.load_id)
            ? "Has verification"
            : "No verification",
        }))
      );
    }

    // Log scheduled shipments from local state
    if (Object.keys(scheduledShipments).length > 0) {
      console.log("Local scheduled shipments:", scheduledShipments);
    }

    // Create a direct lookup for shipments by dock ID for easier access
    // This ensures we don't miss any shipments if dock_id is not a string or number
    const directShipmentsByDock = {};
    shipments.forEach((shipment) => {
      if (shipment.dock_id) {
        // Handle various dock_id formats (number, string with spaces, etc.)
        const dockId = String(shipment.dock_id).trim();

        // Skip empty strings after trimming
        if (dockId === "") return;

        if (!directShipmentsByDock[dockId]) {
          directShipmentsByDock[dockId] = [];
        }

        // Create a normalized shipment object with consistent dock_id type
        const normalizedShipment = {
          ...shipment,
          // Store the numeric dock_id if it's a valid number, otherwise keep the string
          dock_id: !isNaN(parseInt(dockId, 10)) ? parseInt(dockId, 10) : dockId,
        };

        directShipmentsByDock[dockId].push(normalizedShipment);
      }
    });

    // Add specific debug for dock 1 to check if shipments are properly assigned
    if (directShipmentsByDock["1"]) {
      console.log(
        "FOUND DOCK 1 SHIPMENTS:",
        directShipmentsByDock["1"].map((s) => ({
          id: s.id,
          load_id: s.load_id,
          status: s.status,
          dock_id: s.dock_id,
          dock_id_type: typeof s.dock_id,
        }))
      );

      // Specific debug for scheduled shipments on dock 1
      const dock1Scheduled = directShipmentsByDock["1"].filter(
        (s) => s.status === "Scheduled"
      );
      if (dock1Scheduled.length > 0) {
        console.log("DOCK 1 SCHEDULED SHIPMENTS:", dock1Scheduled);
      } else {
        console.log("NO SCHEDULED SHIPMENTS FOR DOCK 1");
      }
    } else {
      console.log("NO SHIPMENTS FOUND FOR DOCK 1");
    }

    return filteredDocks.map((dock) => {
      // Get all shipments for this dock using our direct lookup
      const dockIdStr = String(dock.id);
      const dockShipments = directShipmentsByDock[dockIdStr] || [];

      // Log for debugging
      if (dockShipments.length > 0) {
        console.log(
          `Dock ${dock.id} has ${dockShipments.length} shipments assigned:`,
          dockShipments.map((s) => `${s.id} (${s.status})`).join(", ")
        );
      }

      // Log all shipments (regardless of status) assigned to this dock
      console.log(
        `Dock ${dock.id} - all assigned shipments:`,
        dockShipments.map((s) => ({
          id: s.id,
          load_id: s.load_id,
          status: s.status,
          reference: s.reference,
        }))
      );

      // Find active shipment (status is one of the active states)
      const _currentShipment = dockShipments.find((s) =>
        ["Arrived", "Check In", "Loading", "Unloading"].includes(s.status)
      );

      // Find scheduled shipment from backend data
      const _backendScheduledShipment = dockShipments.find(
        (s) => s.status === "Scheduled"
      );

      // Add detailed debug info about each dock's shipments
      console.log(
        `Dock ${dock.id} - processing dockShipments:`,
        dockShipments.map((s) => ({
          id: s.id,
          load_id: s.load_id,
          status: s.status,
          type: typeof s.dock_id,
          dock_id_value: s.dock_id,
          comparison: s.dock_id === dock.id ? "MATCH" : "NO MATCH",
          data_types: `${typeof s.dock_id} vs ${typeof dock.id}`,
        }))
      );

      // Log specific info about scheduled shipments
      const allScheduled = dockShipments.filter(
        (s) => s.status === "Scheduled"
      );
      if (allScheduled.length > 0) {
        console.log(
          `Dock ${dock.id} has ${allScheduled.length} SCHEDULED shipments:`,
          allScheduled.map(
            (s) => `${s.id || s.load_id} (dock_id: ${s.dock_id})`
          )
        );
      } else {
        console.log(`Dock ${dock.id} has NO scheduled shipments`);
      }

      // Check if we have a locally scheduled shipment for this dock
      const _localScheduledShipment = scheduledShipments[dock.id];

      // Prioritize backend data over local state
      const _scheduledShipment =
        _backendScheduledShipment || _localScheduledShipment;

      if (_scheduledShipment) {
        console.log(
          `Dock ${dock.id} has scheduled shipment:`,
          _scheduledShipment.id || _scheduledShipment.load_id,
          _backendScheduledShipment ? "(from backend)" : "(from local state)"
        );
      }

      const shipmentToCheck = _currentShipment || _scheduledShipment;
      const _verification = shipmentToCheck
        ? verificationMap.get(shipmentToCheck.load_id) // Use .get() for Map
        : null;

      // Get assigned driver from state
      const _assignedDriver = dockAssignments[dock.id];

      // Update dock status to "scheduled" if it's available but has a scheduled shipment
      let updatedStatus = dock.status;
      if (dock.status === "available" && _scheduledShipment) {
        updatedStatus = "scheduled";
      }

      return {
        ...dock,
        status: updatedStatus, // Apply updated status
        _currentShipment,
        _scheduledShipment,
        _verification,
        _assignedDriver,
      };
    });
  }, [
    filteredDocks,
    shipments,
    verifications,
    dockAssignments,
    scheduledShipments,
  ]);

  // Memoize quick stats calculations
  const quickStats = useMemo(() => {
    const totalDocks = filteredDocks.length;
    const availableDocks = filteredDocks.filter(
      (d) => d.status === "available"
    ).length;
    const occupiedDocks = filteredDocks.filter(
      (d) => d.status === "occupied"
    ).length;
    const maintenanceDocks = filteredDocks.filter(
      (d) => d.status === "maintenance"
    ).length;
    // Calculate active shipments count based on the potentially large shipments array
    const activeShipments = shipments.filter((s) =>
      ["Arrived", "Check In", "Loading", "Unloading"].includes(s.status)
    ).length;

    return {
      totalDocks,
      availableDocks,
      occupiedDocks,
      maintenanceDocks,
      activeShipments,
    };
  }, [filteredDocks, shipments]);

  // Handler for scheduling a shipment at a dock
  const handleShipmentScheduling = (dockId, shipmentLoadId, scheduledTime) => {
    console.log(
      `Scheduling shipment ${shipmentLoadId} for dock ${dockId} at ${scheduledTime}`
    );
    const shipmentToSchedule = shipments.find(
      (s) => String(s.load_id) === String(shipmentLoadId)
    );

    if (!shipmentToSchedule) {
      console.error("Shipment not found for scheduling:", shipmentLoadId);
      return;
    }

    if (!shipmentToSchedule.id) {
      console.error(
        "Shipment found, but missing primary ID:",
        shipmentToSchedule
      );
      return;
    }

    // Ensure dockId is a number
    const numericDockId = parseInt(dockId, 10);

    if (isNaN(numericDockId)) {
      console.error("Invalid dock ID (not a number):", dockId);
      return;
    }

    // Create shipment data to update with proper types
    const shipmentData = {
      dock_id: numericDockId,
      pickup_time: scheduledTime,
      status: "Scheduled",
    };

    console.log("Sending update with data:", shipmentData);
    console.log("Shipment data types: dock_id is", typeof shipmentData.dock_id);

    // Create updated shipment for local state
    const updatedShipment = {
      ...shipmentToSchedule,
      ...shipmentData,
    };

    // Update our local state immediately so the UI reflects the change
    setScheduledShipments((prev) => ({
      ...prev,
      [dockId]: updatedShipment,
    }));

    // Make the API call
    updateShipment.mutate(
      {
        id: shipmentToSchedule.id,
        shipmentData: shipmentData,
      },
      {
        onSuccess: (data) => {
          console.log(
            `Shipment ${shipmentLoadId} scheduled successfully for dock ${dockId}`
          );
          console.log("Response from API:", data);

          // Verify the API response has the correct dock_id
          if (!data.dock_id || data.dock_id !== numericDockId) {
            console.error(
              "API response is missing dock_id or has incorrect value:",
              data
            );
            console.log("Will retry the update...");

            // If API response doesn't have dock_id, try the update again
            // But first wait a moment to avoid potential rate limiting
            setTimeout(() => {
              console.log("Retrying update with explicit dock_id...");
              updateShipment.mutate(
                {
                  id: shipmentToSchedule.id,
                  shipmentData: shipmentData,
                },
                {
                  onSuccess: (retryData) => {
                    console.log("Retry response:", retryData);
                    // Refresh data
                    refetchShipments();
                    refetchDocks();
                  },
                  onError: (error) => console.error("Retry error:", error),
                }
              );
            }, 500);
          }

          // Keep the local state in sync with the backend
          refetchShipments();
          refetchDocks();

          // Keep the optimistic update longer to ensure the UI stays consistent
          setTimeout(() => {
            console.log("Checking if shipment is now in backend data:");
            const currentShipments = queryClient.getQueryData([
              "shipments",
              1,
              1000,
              {},
            ]);
            const shipmentWithDock = currentShipments?.shipments?.find(
              (s) =>
                s.id === shipmentToSchedule.id && s.dock_id === numericDockId
            );

            console.log("Found in backend data?", !!shipmentWithDock);

            // Only remove from local state if the backend now has the correct data
            if (shipmentWithDock) {
              setScheduledShipments((prev) => {
                const newState = { ...prev };
                delete newState[dockId];
                return newState;
              });
              console.log(
                "Removed from local state as backend data is now correct"
              );
            } else {
              console.log(
                "Keeping in local state as backend data is not yet correct"
              );
            }
          }, 10000); // Longer timeout to ensure backend data has time to load
        },
        onError: (error) => {
          console.error("Error scheduling shipment:", error);

          // Remove the optimistic update on error
          setScheduledShipments((prev) => {
            const newState = { ...prev };
            delete newState[dockId];
            return newState;
          });
        },
      }
    );
  };

  // Handler for assigning a driver to a dock
  const handleDriverAssignment = (dockId, driverId) => {
    // In a real app, this would make an API call
    setDockAssignments((prev) => ({
      ...prev,
      [dockId]: driverId
        ? drivers.find((d) => d.id.toString() === driverId)
        : null,
    }));
  };

  // Refresh data - uses the refetch function from the hook
  const handleRefresh = () => {
    refetchDocks();
    refetchShipments();
  };

  // Handle dock selection
  const handleSelectDock = (dock) => {
    setSelectedDock(dock);
  };

  // TODO: Replace with actual mutation when implemented
  // Handle status update
  const handleUpdateStatus = (shipmentId, newStatus) => {
    console.log("Would update shipment status:", shipmentId, newStatus);
    // In a real app, this would call an API via a useMutation hook
  };

  // Action Handlers
  const handleAssignDriver = (dock) => {
    console.log("Opening Assign Driver modal for Dock:", dock.id);
    setSelectedDockForAction(dock);
    setIsAssignDriverModalOpen(true);
  };

  const handleMarkOutOfOrder = (dockId) => {
    console.log("Mark Dock Out of Order:", dockId);
    updateDockStatusMutation.mutate({ dockId, status: "maintenance" });
  };

  const handleAddShipment = (dock) => {
    console.log("Opening Add Shipment modal for Dock:", dock.id);
    setSelectedDockForAction(dock);
    setIsScheduleModalOpen(true);
  };

  // Loading and Error States
  if (
    isLoadingDocks ||
    isLoadingShipments ||
    isLoadingDrivers ||
    isLoadingVerifications
  ) {
    return <div className="p-4 text-center">Loading data...</div>;
  }

  if (docksError) {
    return (
      <div className="p-4 text-center text-red-500">
        Error loading docks: {docksError.message}
      </div>
    );
  }

  // Render Logic
  return (
    <div className="container mx-auto p-4">
      {/* Header with cleaner layout */}
      <div className="flex flex-col md:flex-row justify-between border-b pb-4 mb-6">
        <div>
          <h1 className="text-3xl font-bold">Dock Command Center</h1>
          <p className="text-muted-foreground mt-1">
            Real-time warehouse dock monitoring and management
          </p>
        </div>

        <div className="flex mt-4 md:mt-0 gap-2 items-center">
          <Button
            variant="outline"
            size="sm"
            onClick={handleRefresh}
            className="gap-1"
          >
            <RefreshCw className="h-4 w-4" /> Refresh
          </Button>

          <Tabs defaultValue="live" className="w-[180px]">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="live">Live</TabsTrigger>
              <TabsTrigger value="analytics">Analytics</TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>

      {/* Main Content Layout - Moved filters to top */}
      <div className="space-y-6">
        {/* Main Content - Dock View */}
        <Tabs defaultValue="dock-view">
          <TabsContent value="dock-view" className="mt-0 pt-0">
            {selectedDock ? (
              <DockDetail
                dock={selectedDock}
                shipments={shipments}
                onClose={() => setSelectedDock(null)}
                onUpdateStatus={handleUpdateStatus}
              />
            ) : (
              <>
                <div className="space-y-4">
                  <div className="flex flex-col lg:flex-row gap-4 justify-between items-center">
                    <div>
                      <p className="text-sm text-muted-foreground mt-1">
                        {filteredDocks.length} docks matching filters • Last
                        updated: {new Date().toLocaleTimeString()}
                      </p>
                    </div>

                    {/* Filter controls in horizontal layout */}
                    <div className="flex flex-wrap md:flex-nowrap gap-3 items-center w-full lg:w-auto">
                      {/* Search */}
                      <div className="relative w-full md:w-64">
                        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Input
                          placeholder="Search docks..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="pl-8"
                        />
                      </div>

                      {/* Status Filter */}
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            variant="outline"
                            size="sm"
                            className="w-full md:w-auto justify-between min-w-[140px]"
                          >
                            <span>
                              {statusFilter === "all"
                                ? "All Statuses"
                                : statusFilter.charAt(0).toUpperCase() +
                                  statusFilter.slice(1)}
                            </span>
                            <Filter className="h-3.5 w-3.5 ml-2 opacity-70" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            onClick={() => setStatusFilter("all")}
                          >
                            All Statuses
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => setStatusFilter("available")}
                          >
                            Available
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => setStatusFilter("occupied")}
                          >
                            Occupied
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => setStatusFilter("maintenance")}
                          >
                            Maintenance
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>

                      {/* Type Filter */}
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            variant="outline"
                            size="sm"
                            className="w-full md:w-auto justify-between min-w-[140px]"
                          >
                            <span>
                              {typeFilter === "all"
                                ? "All Types"
                                : typeFilter.charAt(0).toUpperCase() +
                                  typeFilter.slice(1)}
                            </span>
                            <Filter className="h-3.5 w-3.5 ml-2 opacity-70" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            onClick={() => setTypeFilter("all")}
                          >
                            All Types
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => setTypeFilter("standard")}
                          >
                            Standard
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => setTypeFilter("refrigerated")}
                          >
                            Refrigerated
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => setTypeFilter("oversized")}
                          >
                            Oversized
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => setTypeFilter("hazmat")}
                          >
                            Hazmat
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>

                  {/* Quick Stats in small cards */}
                  <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                    <Card className="bg-slate-50 dark:bg-slate-800 p-3">
                      <div className="text-xl font-semibold">
                        {quickStats.totalDocks}
                      </div>
                      <div className="text-xs text-muted-foreground">
                        Total Docks
                      </div>
                    </Card>
                    <Card className="bg-slate-50 dark:bg-slate-800 p-3 border-l-4 border-emerald-500">
                      <div className="text-xl font-semibold">
                        {quickStats.availableDocks}
                      </div>
                      <div className="text-xs text-muted-foreground">
                        Available
                      </div>
                    </Card>
                    <Card className="bg-slate-50 dark:bg-slate-800 p-3 border-l-4 border-purple-500">
                      <div className="text-xl font-semibold">
                        {quickStats.occupiedDocks}
                      </div>
                      <div className="text-xs text-muted-foreground">
                        Occupied
                      </div>
                    </Card>
                    <Card className="bg-slate-50 dark:bg-slate-800 p-3 border-l-4 border-amber-500">
                      <div className="text-xl font-semibold">
                        {quickStats.maintenanceDocks}
                      </div>
                      <div className="text-xs text-muted-foreground">
                        In Maintenance
                      </div>
                    </Card>
                    <Card className="hidden md:block bg-slate-50 dark:bg-slate-800 p-3">
                      <div className="text-xl font-semibold">
                        {quickStats.activeShipments}
                      </div>
                      <div className="text-xs text-muted-foreground">
                        Active Shipments
                      </div>
                    </Card>
                  </div>

                  <Card>
                    <Table>
                      <TableHeader>
                        <TableRow className="hover:bg-transparent">
                          <TableHead>Dock</TableHead>
                          <TableHead>Status</TableHead>
                          <TableHead>Current Activity</TableHead>
                          <TableHead>Assigned Driver</TableHead>
                          <TableHead>Assigned Shipment</TableHead>
                          <TableHead>Verified</TableHead>
                          <TableHead>Last Activity</TableHead>
                          <TableHead className="text-right">Actions</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {docksWithDetails.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={8} className="h-24 text-center">
                              No docks match the current filters.
                            </TableCell>
                          </TableRow>
                        ) : (
                          docksWithDetails.map((dock) => {
                            // Use pre-calculated values
                            const currentShipment = dock._currentShipment;
                            const scheduledShipment = dock._scheduledShipment;
                            const assignedDriver = dock._assignedDriver;
                            const verification = dock._verification;

                            return (
                              <TableRow
                                key={dock.id}
                                onClick={() => handleSelectDock(dock)}
                                className="cursor-pointer hover:bg-muted/50"
                              >
                                <TableCell className="py-3">
                                  <div className="font-medium">{dock.name}</div>
                                  <div className="text-xs text-muted-foreground flex items-center gap-1 mt-1">
                                    {getTypeIcon(dock.type)}
                                    {dock.type.charAt(0).toUpperCase() +
                                      dock.type.slice(1)}
                                  </div>
                                </TableCell>
                                <TableCell className="py-3">
                                  <Badge
                                    className={`${getStatusColor(
                                      dock.status
                                    )} px-2 py-1`}
                                  >
                                    {dock.status.charAt(0).toUpperCase() +
                                      dock.status.slice(1)}
                                  </Badge>
                                </TableCell>
                                <TableCell className="py-3">
                                  {dock._currentShipment ? (
                                    <div>
                                      <div className="flex items-center gap-2 mb-1">
                                        <Badge
                                          className={getStatusColor(
                                            dock._currentShipment.status
                                          )}
                                          variant="outline"
                                        >
                                          {dock._currentShipment.status}
                                        </Badge>
                                        <span className="font-medium">
                                          {dock._currentShipment.reference ||
                                            dock._currentShipment.load_id ||
                                            "Shipment #" +
                                              dock._currentShipment.id}
                                        </span>
                                      </div>
                                      <div className="text-xs text-muted-foreground flex items-center justify-between">
                                        <span>
                                          {dock._currentShipment.carrier ||
                                            "Unknown carrier"}
                                          {dock._currentShipment.direction &&
                                            ` (${dock._currentShipment.direction})`}
                                        </span>
                                      </div>
                                    </div>
                                  ) : dock.status === "maintenance" ? (
                                    <div className="text-xs text-amber-600 flex items-center gap-1">
                                      <AlertTriangle className="h-3.5 w-3.5" />
                                      Under maintenance since{" "}
                                      {dock.updated_at
                                        ? timeAgo(new Date(dock.updated_at))
                                        : "unknown time"}
                                    </div>
                                  ) : (
                                    <div
                                      className={`text-xs ${
                                        dock.status === "available"
                                          ? "text-emerald-600"
                                          : "text-slate-500"
                                      } flex items-center gap-1`}
                                    >
                                      {dock.status === "available" ? (
                                        <CheckCircle className="h-3.5 w-3.5" />
                                      ) : (
                                        <Clock className="h-3.5 w-3.5" />
                                      )}
                                      {dock.status === "available"
                                        ? "Available for shipments"
                                        : dock.status.charAt(0).toUpperCase() +
                                          dock.status.slice(1)}
                                    </div>
                                  )}
                                </TableCell>

                                {/* Assigned Driver Column */}
                                <TableCell className="py-3">
                                  {dock._assignedDriver ? (
                                    <div className="flex items-center gap-2">
                                      <UserCheck className="h-4 w-4 text-green-500" />
                                      <div>
                                        <div className="font-medium text-sm">
                                          {/* Ensure driver object structure matches */}
                                          {dock._assignedDriver.name ||
                                            `${
                                              dock._assignedDriver.first_name ||
                                              ""
                                            } ${
                                              dock._assignedDriver.last_name ||
                                              ""
                                            }`.trim()}
                                        </div>
                                        <div className="text-xs text-muted-foreground">
                                          {dock._assignedDriver.phone}
                                        </div>
                                      </div>
                                    </div>
                                  ) : dock.status !== "maintenance" ? (
                                    <Button
                                      variant="outline"
                                      size="sm"
                                      className="text-xs text-muted-foreground hover:text-foreground"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleAssignDriver(dock);
                                      }}
                                    >
                                      Assign driver
                                    </Button>
                                  ) : (
                                    <span className="text-xs text-muted-foreground">
                                      Not applicable
                                    </span>
                                  )}
                                </TableCell>

                                {/* Assigned Shipment Column */}
                                <TableCell className="py-3">
                                  {(() => {
                                    // Find any shipment assigned to this dock
                                    const assignedShipment = shipments.find(
                                      (s) =>
                                        // Type-safe comparison of dock_id
                                        String(s.dock_id) === String(dock.id)
                                    );

                                    if (assignedShipment) {
                                      return (
                                        <div>
                                          <div className="font-medium text-sm">
                                            {assignedShipment.reference ||
                                              (assignedShipment.load_id
                                                ? truncateText(
                                                    assignedShipment.load_id,
                                                    12
                                                  )
                                                : `Shipment #${assignedShipment.id}` ||
                                                  "Unknown ID")}
                                          </div>
                                          <div className="flex justify-between items-center">
                                            <span className="text-xs text-muted-foreground">
                                              {assignedShipment.carrier ||
                                                "Unknown carrier"}
                                              {assignedShipment.status && (
                                                <Badge
                                                  className="ml-1"
                                                  variant="secondary"
                                                  size="sm"
                                                >
                                                  {assignedShipment.status}
                                                </Badge>
                                              )}
                                            </span>
                                            {assignedShipment.pickup_time && (
                                              <Badge
                                                variant="outline"
                                                className="text-xs"
                                              >
                                                {new Date(
                                                  assignedShipment.pickup_time
                                                ).toLocaleTimeString([], {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })}
                                              </Badge>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    } else if (dock.status === "available") {
                                      return (
                                        <Button
                                          variant="outline"
                                          size="sm"
                                          className="text-xs text-muted-foreground hover:text-foreground"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddShipment(dock);
                                          }}
                                        >
                                          Assign shipment
                                        </Button>
                                      );
                                    } else {
                                      return (
                                        <span className="text-xs text-muted-foreground">
                                          {dock.status === "maintenance"
                                            ? "Not applicable"
                                            : "No shipment assigned"}
                                        </span>
                                      );
                                    }
                                  })()}
                                </TableCell>

                                {/* Verified Column Cell */}
                                <TableCell className="py-3">
                                  {(() => {
                                    // Find shipment assigned to this dock
                                    const assignedShipment = shipments.find(
                                      (s) =>
                                        String(s.dock_id) === String(dock.id)
                                    );

                                    if (!assignedShipment) {
                                      return (
                                        <span className="text-xs text-muted-foreground">
                                          -
                                        </span>
                                      );
                                    }

                                    // Find verification for this shipment
                                    const shipmentVerification =
                                      verifications.find(
                                        (v) =>
                                          v.shipment_id ===
                                          assignedShipment.load_id
                                      );

                                    if (!shipmentVerification) {
                                      return (
                                        <span className="text-xs text-muted-foreground">
                                          No verification
                                        </span>
                                      );
                                    }

                                    // Check if verification state is "Complete"
                                    const isComplete =
                                      shipmentVerification.state ===
                                        "Complete" ||
                                      shipmentVerification.state === "complete";

                                    if (isComplete) {
                                      return (
                                        <TooltipProvider>
                                          <Tooltip>
                                            <TooltipTrigger asChild>
                                              <div className="flex items-center">
                                                <CheckCircle className="h-4 w-4 text-green-500 mr-1" />
                                                <Badge
                                                  variant="success"
                                                  className="bg-green-100 text-green-800 text-xs"
                                                >
                                                  Verified
                                                </Badge>
                                              </div>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                              <p>Verification Completed</p>
                                            </TooltipContent>
                                          </Tooltip>
                                        </TooltipProvider>
                                      );
                                    } else {
                                      // Show status if not complete
                                      return (
                                        <TooltipProvider>
                                          <Tooltip>
                                            <TooltipTrigger asChild>
                                              <div className="flex items-center">
                                                <XCircle className="h-4 w-4 text-amber-500 mr-1" />
                                                <Badge
                                                  variant="outline"
                                                  className="text-xs"
                                                >
                                                  {shipmentVerification.state ||
                                                    "Unknown"}
                                                </Badge>
                                              </div>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                              <p>
                                                Verification in progress:{" "}
                                                {shipmentVerification.state}
                                              </p>
                                            </TooltipContent>
                                          </Tooltip>
                                        </TooltipProvider>
                                      );
                                    }
                                  })()}
                                </TableCell>

                                <TableCell className="py-3">
                                  <div className="text-xs text-muted-foreground">
                                    {dock.updated_at
                                      ? timeAgo(new Date(dock.updated_at))
                                      : "Unknown"}
                                  </div>
                                </TableCell>
                                <TableCell className="text-right py-3">
                                  <div className="flex justify-end">
                                    <DropdownMenu>
                                      <DropdownMenuTrigger asChild>
                                        <Button
                                          variant="ghost"
                                          size="icon"
                                          className="h-8 w-8"
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <MoreHorizontal className="h-4 w-4" />
                                          <span className="sr-only">
                                            Dock Actions
                                          </span>
                                        </Button>
                                      </DropdownMenuTrigger>
                                      <DropdownMenuContent
                                        align="end"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <DropdownMenuItem
                                          onSelect={() =>
                                            handleAssignDriver(dock)
                                          }
                                          disabled={
                                            dock.status === "maintenance"
                                          }
                                        >
                                          Assign/Update Driver
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                          onSelect={() =>
                                            handleAddShipment(dock)
                                          }
                                          disabled={dock.status !== "available"}
                                        >
                                          Schedule Shipment
                                        </DropdownMenuItem>
                                        {dock.status !== "maintenance" ? (
                                          <DropdownMenuItem
                                            onSelect={() =>
                                              handleMarkOutOfOrder(dock.id)
                                            }
                                            disabled={
                                              dock.status === "maintenance"
                                            }
                                          >
                                            Mark Out of Order
                                          </DropdownMenuItem>
                                        ) : (
                                          <DropdownMenuItem
                                            onSelect={() =>
                                              updateDockStatusMutation.mutate({
                                                dockId: dock.id,
                                                status: "available",
                                              })
                                            }
                                            disabled={
                                              dock.status !== "maintenance"
                                            }
                                          >
                                            Mark as Available
                                          </DropdownMenuItem>
                                        )}
                                      </DropdownMenuContent>
                                    </DropdownMenu>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </Card>
                </div>
              </>
            )}
          </TabsContent>
        </Tabs>
      </div>

      {/* Modal Components Rendered Here */}
      {selectedDockForAction && (
        <AssignDriverModal
          open={isAssignDriverModalOpen}
          onOpenChange={setIsAssignDriverModalOpen}
          dockId={selectedDockForAction.id}
          currentShipment={shipments.find(
            (s) =>
              // Ensure consistent type comparison by converting to strings
              String(s.dock_id) === String(selectedDockForAction.id) &&
              ["Arrived", "Check In", "Loading", "Unloading"].includes(s.status)
          )}
          availableDrivers={drivers}
          onAssignDriver={handleDriverAssignment}
        />
      )}

      {selectedDockForAction && (
        <ScheduleShipmentModal
          open={isScheduleModalOpen}
          onOpenChange={setIsScheduleModalOpen}
          dockId={selectedDockForAction.id}
          availableShipments={shipments.filter(
            (s) =>
              !s.dock_id && s.status !== "Cancelled" && s.status !== "Completed"
          )}
          onScheduleShipment={handleShipmentScheduling}
        />
      )}
    </div>
  );
}

// --- Moved Modal Components ---

// AssignDriverModal component
// (Ensure imports needed only by this modal are either here or globally available)
function AssignDriverModal({
  open,
  onOpenChange,
  dockId,
  currentShipment,
  availableDrivers = [], // Default to empty array
  onAssignDriver,
}) {
  // Ensure currentShipment info is used safely
  const shipmentRef =
    currentShipment?.reference || currentShipment?.load_id || "Unknown";

  // Find the ID of the driver currently assigned *to the shipment*, if any
  // Note: This logic assumes the parent state `dockAssignments` reflects the *target* state,
  // while `currentShipment.driver_id` might reflect the *actual* current state from data.
  // Using currentShipment.driver_id seems more accurate for pre-selecting.
  const currentlyAssignedDriverId = currentShipment?.driver_id
    ? String(currentShipment.driver_id)
    : "";

  const [selectedDriver, setSelectedDriver] = useState(
    currentlyAssignedDriverId
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Update selectedDriver if the currentShipment changes while modal is open
  React.useEffect(() => {
    setSelectedDriver(currentlyAssignedDriverId);
  }, [currentlyAssignedDriverId]);

  const handleSubmit = () => {
    setIsSubmitting(true);
    // Call the parent's handler
    // TODO: This still calls the handleDriverAssignment which updates local state `dockAssignments`.
    // Ideally, this should trigger a mutation to update the *shipment's* driver_id
    // and potentially the dock status, then rely on data refetching.
    // For now, keep the existing local state update.
    onAssignDriver(dockId, selectedDriver);

    // Simulate API call delay - Replace with actual mutation later
    setTimeout(() => {
      setIsSubmitting(false);
      onOpenChange(false);
    }, 1000);
  };

  // Filter out invalid drivers before mapping
  const validDrivers = availableDrivers.filter(
    (driver) => driver && driver.id != null && String(driver.id) !== ""
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Assign Driver to Dock {dockId}</DialogTitle>
          <DialogDescription>
            {currentShipment
              ? `Assign or update the driver for shipment ${shipmentRef}.`
              : `Assign a driver to manage activity at dock ${dockId}.`}
            {/* Add info about current driver if exists */}
            {currentlyAssignedDriverId &&
              validDrivers.find(
                (d) => d.id.toString() === currentlyAssignedDriverId
              ) && (
                <span className="block text-sm mt-1 text-muted-foreground">
                  Currently assigned:{" "}
                  {
                    validDrivers.find(
                      (d) => d.id.toString() === currentlyAssignedDriverId
                    )?.first_name
                  }{" "}
                  {
                    validDrivers.find(
                      (d) => d.id.toString() === currentlyAssignedDriverId
                    )?.last_name
                  }
                </span>
              )}
          </DialogDescription>
        </DialogHeader>

        <div className="py-4">
          <Label htmlFor="driver-select" className="mb-2 block">
            Select Driver
          </Label>
          <Select
            value={selectedDriver}
            onValueChange={(value) =>
              setSelectedDriver(value === "__UNASSIGN__" ? "" : value)
            }
          >
            <SelectTrigger id="driver-select">
              <SelectValue placeholder="Select a driver" />
            </SelectTrigger>
            <SelectContent className="max-h-[300px] overflow-y-auto">
              {/* Option to unassign - Use a special non-empty value */}
              <SelectItem value="__UNASSIGN__">
                <em>Unassign Driver</em>
              </SelectItem>
              {validDrivers.map((driver) => (
                <SelectItem key={driver.id} value={driver.id.toString()}>
                  {driver.first_name} {driver.last_name} (
                  {driver.status || "Status Unknown"}) -{" "}
                  {driver.phone || "No Phone"}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={
              isSubmitting || selectedDriver === currentlyAssignedDriverId
            }
          >
            {isSubmitting ? (
              <>
                <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                Saving...
              </>
            ) : selectedDriver === "" ? (
              "Unassign Driver"
            ) : (
              "Assign Driver"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

// ScheduleShipmentModal component
// (Ensure imports needed only by this modal are globally available or added here if specific)
function ScheduleShipmentModal({
  open,
  onOpenChange,
  dockId,
  availableShipments = [], // Default to empty array
  onScheduleShipment,
}) {
  const [selectedShipment, setSelectedShipment] = useState("");
  const [scheduledTime, setScheduledTime] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShipmentPopoverOpen, setIsShipmentPopoverOpen] = useState(false);

  // Initialize with a default date/time (now + 1 hour)
  // Also reset state when dockId changes
  React.useEffect(() => {
    if (open) {
      const date = new Date();
      date.setHours(date.getHours() + 1);
      date.setMinutes(0);
      setScheduledTime(date.toISOString().slice(0, 16)); // Format for datetime-local

      // Reset selected shipment when dock changes
      setSelectedShipment("");
    } else {
      // Reset state on close
      setSelectedShipment("");
      setScheduledTime("");
      setIsSubmitting(false);
    }
  }, [open, dockId]);

  const handleSubmit = () => {
    if (!selectedShipment || !scheduledTime) return; // Basic validation

    setIsSubmitting(true);

    // Convert local datetime string back to ISO string or Date object for API
    const scheduledISOTime = new Date(scheduledTime).toISOString();

    // Close the modal immediately
    onOpenChange(false);

    // Call the parent's handler
    onScheduleShipment(dockId, selectedShipment, scheduledISOTime); // Pass ISO time
  };

  // Find the details of the selected shipment for display
  const selectedShipmentDetails = selectedShipment
    ? availableShipments.find(
        (s) => String(s.load_id) === String(selectedShipment)
      )
    : null;

  const displayShipmentLabel = selectedShipmentDetails
    ? `${selectedShipmentDetails.load_id.substring(0, 8)}... - ${
        selectedShipmentDetails.cargo_owners?.company_name || "Owner Unknown"
      } (${selectedShipmentDetails.direction || "N/A"})`
    : "Select shipment...";

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Schedule Shipment</DialogTitle>
          <DialogDescription>
            Assign an available shipment to dock {dockId}. Only shipments
            without a dock assignment are shown.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4 py-2">
          <div>
            <Label htmlFor="shipment-select" className="mb-2 block">
              Select Shipment
            </Label>
            <Popover
              open={isShipmentPopoverOpen}
              onOpenChange={setIsShipmentPopoverOpen}
            >
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={isShipmentPopoverOpen}
                  className="w-full justify-between"
                >
                  {displayShipmentLabel}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0">
                <Command>
                  <CommandInput placeholder="Search by Load ID, Owner, Direction..." />
                  <CommandList>
                    <CommandEmpty>No available shipments found.</CommandEmpty>
                    <CommandGroup>
                      {availableShipments.map((shipment) => (
                        <CommandItem
                          key={shipment.load_id}
                          value={String(shipment.load_id)} // Value should be the ID
                          onSelect={(currentValue) => {
                            // currentValue is the load_id string here
                            setSelectedShipment(
                              currentValue === selectedShipment
                                ? ""
                                : currentValue
                            );
                            setIsShipmentPopoverOpen(false);
                          }}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              selectedShipment === String(shipment.load_id)
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          {/* Display format in the list */}
                          {`${shipment.load_id.substring(0, 8)}... - ${
                            shipment.cargo_owners?.company_name ||
                            "Owner Unknown"
                          } (${shipment.direction || "N/A"})`}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>

          <div>
            <Label htmlFor="scheduled-time" className="mb-2 block">
              Scheduled Arrival/Pickup Time
            </Label>
            <Input
              id="scheduled-time"
              type="datetime-local" // Use datetime-local for easy user input
              value={scheduledTime}
              onChange={(e) => setScheduledTime(e.target.value)}
              // Add min attribute to prevent scheduling in the past?
              min={new Date().toISOString().slice(0, 16)}
            />
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isSubmitting || !selectedShipment || !scheduledTime}
          >
            {isSubmitting ? (
              <>
                <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                Scheduling...
              </>
            ) : (
              "Schedule Shipment"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
