/**
 * ReportTypes.js
 *
 * This file defines the configuration for different types of Persona reports.
 * Each configuration includes:
 * - shouldRun: Function to determine if the report should be run based on input data
 * - getQuery: Function to generate the query parameters for the report
 * - getBusinessFields: Function to extract business fields from input data (for transaction reports)
 * - prefix: Used for generating reference IDs
 * - customReferenceField: Field used for referencing
 * - verificationMethod: Type of verification
 */

export const REPORT_TYPES = {
  "phone-risk-report": {
    shouldRun: (data) =>
      Boolean(
        data?.phone_number ||
          data?.phoneNumber ||
          data?.business_fields?.phone_number
      ),
    getQuery: (data) => ({
      "phone-number":
        data?.phone_number ||
        data?.phoneNumber ||
        data?.business_fields?.phone_number,
    }),
    prefix: "phone",
    customReferenceField: "phone_number",
    verificationMethod: "phone_verification",
    description: "Phone risk assessment report",
  },

  "email-risk-report": {
    shouldRun: (data) => Boolean(data?.email || data?.business_fields?.email),
    getQuery: (data) => ({
      "email-address": data?.email || data?.business_fields?.email,
    }),
    prefix: "email",
    customReferenceField: "email",
    verificationMethod: "email_verification",
    description: "Email risk assessment report",
  },

  "business-verification-transaction": {
    shouldRun: (data) =>
      Boolean(data?.business_fields?.business_name || data?.business_name),
    getQuery: (data) => null,
    getBusinessFields: (data) => {
      // Start with original business fields
      const fields = data.business_fields || {};

      // Ensure business_name is set
      if (!fields.business_name && data.business_name) {
        fields.business_name = data.business_name;
      }

      // Ensure carrier_id is included (if available at the top level)
      if (!fields.carrier_id && data.carrier_id) {
        fields.carrier_id = data.carrier_id;
      }

      // Ensure phone_number is included
      if (!fields.phone_number && (data.phone_number || data.phoneNumber)) {
        fields.phone_number = data.phone_number || data.phoneNumber;
      }

      // Ensure email is included
      if (!fields.email && data.email) {
        fields.email = data.email;
      }

      // Ensure website is included
      if (!fields.website && data.website) {
        fields.website = data.website;
      }

      // Ensure ein is included
      if (!fields.ein && data.ein) {
        fields.ein = data.ein;
      }

      // Ensure business_registered_address is included
      if (
        !fields.business_registered_address &&
        data.business_registered_address
      ) {
        fields.business_registered_address = data.business_registered_address;
      }

      // Log the complete business fields for debugging
      console.log("[ReportTypes] Complete business fields:", fields);

      return fields;
    },
    prefix: "business_tx",
    customReferenceField: "business_name",
    verificationMethod: "business_verification_transaction",
    description: "Business verification transaction",
  },
};

/**
 * Check if a report is considered "ready" based on its status
 * @param {Object} report - The report object to check
 * @returns {boolean} - Whether the report is ready
 */
export const isReportReady = (report) => {
  // Handle transaction results (they have a different structure)
  if (report && report.result && report.result.type === "transaction") {
    const status = report.result.attributes?.status;
    return (
      status === "created" || status === "completed" || status === "submitted"
    );
  }

  // Handle regular reports
  return (
    report &&
    report.result &&
    report.result.attributes &&
    report.result.attributes.status === "ready"
  );
};
