import React from "react";
import AttributeGroup from "../AttributeGroup";
import { formatAttributeName } from "../utils";

/**
 * Renders a comparison result with visual indicators
 */
const ComparisonResult = ({ comparison }) => {
  const getMatchResultColor = (result) => {
    switch (result) {
      case "full":
        return "bg-green-100 text-green-800 border-green-200";
      case "partial":
        return "bg-yellow-100 text-yellow-800 border-yellow-200";
      case "none":
        return "bg-red-100 text-red-800 border-red-200";
      default:
        return "bg-gray-100 text-gray-800 border-gray-200";
    }
  };

  return (
    <div className="flex items-center space-x-2 mb-1 text-xs">
      <div className="w-32 font-medium text-gray-600">
        {formatAttributeName(comparison.type)}
      </div>
      <div
        className={`px-2 py-1 rounded-md border ${getMatchResultColor(
          comparison["match-result"]
        )}`}
      >
        {formatAttributeName(comparison["match-result"])} Match
      </div>
      <div className="text-gray-500">
        (Required: {formatAttributeName(comparison["match-level-minimum"])})
      </div>
    </div>
  );
};

/**
 * Renders a field comparison with claimed and extracted values
 */
const FieldComparison = ({ result }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "passed":
        return "bg-green-50 border-green-100";
      case "failed":
        return "bg-red-50 border-red-100";
      default:
        return "bg-gray-50 border-gray-100";
    }
  };

  const getMatchResultBadge = (result) => {
    switch (result) {
      case "full":
        return (
          <span className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
            Full Match
          </span>
        );
      case "partial":
        return (
          <span className="bg-yellow-100 text-yellow-800 text-xs px-2 py-1 rounded-full">
            Partial Match
          </span>
        );
      case "none":
        return (
          <span className="bg-red-100 text-red-800 text-xs px-2 py-1 rounded-full">
            No Match
          </span>
        );
      default:
        return (
          <span className="bg-gray-100 text-gray-800 text-xs px-2 py-1 rounded-full">
            Unknown
          </span>
        );
    }
  };

  return (
    <div
      className={`mb-4 p-3 rounded-lg border ${getStatusColor(result.status)}`}
    >
      <div className="flex justify-between items-center mb-2">
        <h4 className="font-medium text-gray-800">
          {formatAttributeName(result.name)}
        </h4>
        <div className="flex items-center space-x-2">
          <span
            className={`px-2 py-1 text-xs rounded-full ${
              result.status === "passed"
                ? "bg-green-100 text-green-800"
                : "bg-red-100 text-red-800"
            }`}
          >
            {formatAttributeName(result.status)}
          </span>
          {result["match-result"] &&
            getMatchResultBadge(result["match-result"])}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-3">
        <div className="bg-white p-2 rounded border border-gray-200">
          <div className="text-xs text-gray-500 mb-1">Claimed Value</div>
          <div className="font-medium text-gray-800">
            {result["claimed-value"] || "N/A"}
          </div>
          {result["normalized-claimed-value"] &&
            result["normalized-claimed-value"] !== result["claimed-value"] && (
              <div className="text-xs text-gray-500 mt-1">
                Normalized:{" "}
                <span className="text-gray-700">
                  {result["normalized-claimed-value"]}
                </span>
              </div>
            )}
        </div>
        <div className="bg-white p-2 rounded border border-gray-200">
          <div className="text-xs text-gray-500 mb-1">Extracted Value</div>
          <div className="font-medium text-gray-800">
            {result["extracted-value"] || "N/A"}
          </div>
          {result["normalized-extracted-value"] &&
            result["normalized-extracted-value"] !==
              result["extracted-value"] && (
              <div className="text-xs text-gray-500 mt-1">
                Normalized:{" "}
                <span className="text-gray-700">
                  {result["normalized-extracted-value"]}
                </span>
              </div>
            )}
        </div>
      </div>

      {result.comparisons && result.comparisons.length > 0 && (
        <div className="mt-2">
          <div className="text-xs font-medium text-gray-600 mb-1">
            Comparison Methods
          </div>
          <div className="bg-white p-2 rounded border border-gray-200">
            {result.comparisons.map((comparison, idx) => (
              <ComparisonResult key={idx} comparison={comparison} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * Renders a database business verification report
 * @param {Object} props
 * @param {Object} props.attributes - The report attributes
 */
const DatabaseBusinessVerification = ({ attributes }) => {
  console.log("attributes", attributes);
  if (!attributes) {
    return <div className="text-slate-500">No verification data available</div>;
  }

  // Extract nested attributes if needed
  const reportAttributes =
    attributes.data?.data?.data?.attributes ||
    attributes.raw?.data?.data?.data?.attributes ||
    attributes;

  // Group verification fields
  const basicInfo = [
    "business-name",
    "status",
    "created-at",
    "completed-at",
    "submitted-at",
    "country-code",
    "ein", // Added EIN field
    "registration-number", // Moved from separate section to basic info
  ];

  const addressInfo = [
    "address-street-1",
    "address-street-2",
    "address-city",
    "address-subdivision",
    "address-postal-code",
    "address-country-code",
  ];

  // Function to check if any keys from a group exist in the attributes
  const hasAnyKeys = (keys) => {
    return keys.some((key) => reportAttributes[key] !== undefined);
  };

  // Find the identity comparison check
  const identityComparisonCheck = reportAttributes.checks?.find(
    (check) => check.name === "database_business_identity_comparison"
  );

  // Extract match requirement results if they exist
  const matchRequirementResults =
    identityComparisonCheck?.metadata?.["match-requirement-results"] || [];

  return (
    <div className="space-y-6">
      {hasAnyKeys(basicInfo) && (
        <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
          <h3 className="text-blue-800 font-medium mb-3 text-sm flex items-center">
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            Business Information
          </h3>
          <div className="bg-white rounded-lg divide-y divide-slate-100 shadow-sm">
            <AttributeGroup attributes={reportAttributes} keys={basicInfo} />
          </div>
        </div>
      )}

      {hasAnyKeys(addressInfo) && (
        <div className="bg-indigo-50 p-4 rounded-lg border border-indigo-100">
          <h3 className="text-indigo-800 font-medium mb-3 text-sm flex items-center">
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              ></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
            </svg>
            Address Information
          </h3>
          <div className="bg-white rounded-lg divide-y divide-slate-100 shadow-sm">
            <AttributeGroup attributes={reportAttributes} keys={addressInfo} />
          </div>
        </div>
      )}

      {/* Enhanced Identity Comparison Section */}
      {identityComparisonCheck && matchRequirementResults.length > 0 && (
        <div className="bg-teal-50 p-4 rounded-lg border border-teal-100">
          <h3 className="text-teal-800 font-medium mb-3 text-sm flex items-center">
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
              ></path>
            </svg>
            Business Identity Comparison
          </h3>

          <div className="bg-white rounded-lg p-4 shadow-sm mb-4">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h4 className="font-medium text-gray-800">
                  {identityComparisonCheck.metadata?.name &&
                    formatAttributeName(identityComparisonCheck.metadata.name)}
                </h4>
                {identityComparisonCheck.metadata?.description && (
                  <p className="text-sm text-gray-600">
                    {identityComparisonCheck.metadata.description}
                  </p>
                )}
              </div>
              <span
                className={`px-3 py-1 text-sm rounded-full font-medium ${
                  identityComparisonCheck.status === "passed"
                    ? "bg-green-100 text-green-800"
                    : identityComparisonCheck.status === "failed"
                    ? "bg-red-100 text-red-800"
                    : "bg-gray-100 text-gray-600"
                }`}
              >
                {identityComparisonCheck.status === "passed"
                  ? "Passed"
                  : identityComparisonCheck.status === "failed"
                  ? "Failed"
                  : formatAttributeName(identityComparisonCheck.status)}
              </span>
            </div>

            <div className="space-y-4">
              {matchRequirementResults.map((result, idx) => (
                <FieldComparison key={idx} result={result} />
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Other Verification Checks */}
      {reportAttributes.checks && reportAttributes.checks.length > 0 && (
        <div className="bg-purple-50 p-4 rounded-lg border border-purple-100">
          <h3 className="text-purple-800 font-medium mb-3 text-sm flex items-center">
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            Other Verification Checks
          </h3>
          <div className="space-y-3">
            {reportAttributes.checks
              .filter(
                (check) =>
                  check.name !== "database_business_identity_comparison"
              )
              .map((check, index) => (
                <div
                  key={index}
                  className="bg-white p-3 rounded-lg border border-slate-200"
                >
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-medium text-slate-700">
                      {formatAttributeName(check.name)}
                    </span>
                    <span
                      className={`px-2 py-1 text-xs rounded-full ${
                        check.status === "passed"
                          ? "bg-green-100 text-green-800"
                          : check.status === "failed"
                          ? "bg-red-100 text-red-800"
                          : check.status === "not_applicable"
                          ? "bg-gray-100 text-gray-600"
                          : "bg-gray-100 text-gray-800"
                      }`}
                    >
                      {check.status === "passed"
                        ? "Passed"
                        : check.status === "failed"
                        ? "Failed"
                        : check.status === "not_applicable"
                        ? "Not Applicable"
                        : formatAttributeName(check.status)}
                    </span>
                  </div>
                  {check.reasons && check.reasons.length > 0 && (
                    <div className="mt-2">
                      <span className="text-xs text-gray-500">Reasons:</span>
                      <ul className="list-disc list-inside text-sm text-gray-700 ml-2">
                        {check.reasons.map((reason, idx) => (
                          <li key={idx}>{formatAttributeName(reason)}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {check.metadata && Object.keys(check.metadata).length > 0 && (
                    <div className="mt-2 text-xs text-gray-500">
                      <details className="cursor-pointer">
                        <summary className="font-medium">
                          Additional Details
                        </summary>
                        <div className="mt-1 pl-2 border-l-2 border-gray-200">
                          {Object.entries(check.metadata).map(
                            ([key, value]) => (
                              <div key={key} className="mb-1">
                                <span className="font-medium">
                                  {formatAttributeName(key)}:
                                </span>{" "}
                                {typeof value === "string"
                                  ? value
                                  : JSON.stringify(value)}
                              </div>
                            )
                          )}
                        </div>
                      </details>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}

      {/* Display summary information if available */}
      {(attributes.summary || attributes.success !== undefined) && (
        <div className="bg-green-50 p-4 rounded-lg border border-green-100">
          <h3 className="text-green-800 font-medium mb-3 text-sm flex items-center">
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              ></path>
            </svg>
            Summary
          </h3>
          <div className="bg-white rounded-lg p-3 shadow-sm">
            {attributes.success !== undefined && (
              <div className="mb-2">
                <span className="font-medium">Status:</span>{" "}
                <span
                  className={
                    attributes.success ? "text-green-600" : "text-red-600"
                  }
                >
                  {attributes.success ? "Success" : "Failed"}
                </span>
              </div>
            )}
            {attributes.summary && (
              <div>
                <span className="font-medium">Summary:</span>{" "}
                {attributes.summary}
              </div>
            )}
            {attributes.timestamp && (
              <div className="text-sm text-gray-500 mt-2">
                Timestamp: {new Date(attributes.timestamp).toLocaleString()}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatabaseBusinessVerification;
