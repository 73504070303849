// @ts-nocheck
import React from "react";
import { motion } from "framer-motion";
import {
  Building2,
  ShieldAlert,
  Package,
  Truck,
  FileText,
  Shield,
  AlertTriangle,
  CheckCircle,
  Info,
} from "lucide-react";
import CarrierBasicInfoTable from "./CarrierBasicInfoTable";
import SafetyBasicsTable from "./SafetyBasicsTable";
import CargoCarriedTable from "./CargoCarriedTable";
import OperationClassificationTable from "./OperationClassificationTable";
import DocketNumbersTable from "./DocketNumbersTable";
import AuthorityTable from "./AuthorityTable";

const SectionHeader = ({ icon, title, description }) => (
  <motion.div
    className="mb-4"
    initial={{ opacity: 0, y: 10 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.4 }}
  >
    <div className="flex items-center mb-2">
      {icon}
      <h2 className="text-2xl font-bold text-gray-800 ml-2">{title}</h2>
    </div>
    {description && <p className="text-gray-600 ml-8">{description}</p>}
  </motion.div>
);

const Section = ({ children, delay = 0 }) => (
  <motion.div
    className="mb-12"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5, delay }}
  >
    {children}
  </motion.div>
);

const CarrierSummary = ({ carrier }) => {
  if (!carrier) return null;

  const { legalName, dotNumber, safetyRating, allowedToOperate } = carrier;

  const isAllowedToOperate =
    allowedToOperate === "Y" || allowedToOperate === true;
  const hasSafetyRating = safetyRating && Number(safetyRating) >= 3;

  return (
    <motion.div
      className="bg-white rounded-lg border border-gray-200 shadow-sm p-6 mb-8"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 mb-1">
            {legalName || "Unknown Carrier"}
          </h1>
          <p className="text-gray-600">
            DOT Number:{" "}
            <span className="font-medium">{dotNumber || "N/A"}</span>
          </p>
        </div>

        <div className="flex items-center space-x-4 mt-4 md:mt-0">
          <div className="flex items-center">
            {isAllowedToOperate ? (
              <CheckCircle className="text-green-500 mr-2" size={20} />
            ) : (
              <AlertTriangle className="text-red-500 mr-2" size={20} />
            )}
            <span
              className={`font-medium ${
                isAllowedToOperate ? "text-green-700" : "text-red-700"
              }`}
            >
              {isAllowedToOperate
                ? "Allowed to Operate"
                : "Not Allowed to Operate"}
            </span>
          </div>

          {safetyRating && (
            <div className="flex items-center">
              {hasSafetyRating ? (
                <ShieldAlert className="text-green-500 mr-2" size={20} />
              ) : (
                <ShieldAlert className="text-red-500 mr-2" size={20} />
              )}
              <span
                className={`font-medium ${
                  hasSafetyRating ? "text-green-700" : "text-red-700"
                }`}
              >
                Safety Rating: {safetyRating}
              </span>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const DisplayCarrierData = ({ carrierData }) => {
  if (!carrierData) {
    return (
      <div className="p-8 text-center bg-gray-50 rounded-lg border border-gray-200">
        <Info className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-lg font-medium text-gray-900">
          No Data Available
        </h3>
        <p className="mt-1 text-base text-gray-500">
          No carrier data is currently available.
        </p>
      </div>
    );
  }

  const {
    carrierByDOT,
    carrierBasics,
    carrierCargo,
    carrierOperation,
    carrierDocketNumbers,
    carrierAuthority,
  } = carrierData;

  const carrier = carrierByDOT?.content?.carrier;
  console.log("carrierData", carrierData);

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <CarrierSummary carrier={carrier} />

      <Section>
        <SectionHeader
          icon={<Building2 size={24} className="text-blue-600" />}
          title="Carrier Basic Information"
          description="Essential information about the carrier including legal name, DOT number, and operational details."
        />
        {carrier ? (
          <CarrierBasicInfoTable carrier={carrier} />
        ) : (
          <p className="text-gray-500 italic ml-8">
            No carrier basic information available.
          </p>
        )}
      </Section>

      <Section delay={0.1}>
        <SectionHeader
          icon={<ShieldAlert size={24} className="text-orange-600" />}
          title="Safety Basics"
          description="Safety ratings and inspection history for this carrier."
        />
        {carrierBasics?.content && carrierBasics.content.length > 0 ? (
          <SafetyBasicsTable basicsContent={carrierBasics.content} />
        ) : (
          <p className="text-gray-500 italic ml-8">
            No safety basics available.
          </p>
        )}
      </Section>

      <Section delay={0.2}>
        <SectionHeader
          icon={<Package size={24} className="text-green-600" />}
          title="Cargo Carried"
          description="Types of cargo this carrier is authorized to transport."
        />
        <CargoCarriedTable cargoContent={carrierCargo?.content || []} />
      </Section>

      <Section delay={0.3}>
        <SectionHeader
          icon={<Truck size={24} className="text-purple-600" />}
          title="Operation Classification"
          description="How this carrier's operations are classified by regulatory authorities."
        />
        {carrierOperation?.content && carrierOperation.content.length > 0 ? (
          <OperationClassificationTable
            opClassContent={carrierOperation.content}
          />
        ) : (
          <p className="text-gray-500 italic ml-8">
            No operation classification available.
          </p>
        )}
      </Section>

      <Section delay={0.4}>
        <SectionHeader
          icon={<FileText size={24} className="text-indigo-600" />}
          title="Docket Numbers"
          description="Regulatory docket numbers associated with this carrier."
        />
        {carrierDocketNumbers?.content &&
        carrierDocketNumbers.content.length > 0 ? (
          <DocketNumbersTable
            docketNumbersContent={carrierDocketNumbers.content}
          />
        ) : (
          <p className="text-gray-500 italic ml-8">
            No docket numbers available.
          </p>
        )}
      </Section>

      <Section delay={0.5}>
        <SectionHeader
          icon={<Shield size={24} className="text-red-600" />}
          title="Authority"
          description="Regulatory authorities and permissions granted to this carrier."
        />
        {carrierAuthority?.content && carrierAuthority.content.length > 0 ? (
          <AuthorityTable authorityContent={carrierAuthority.content} />
        ) : (
          <p className="text-gray-500 italic ml-8">
            No authority data available.
          </p>
        )}
      </Section>
    </div>
  );
};

export default DisplayCarrierData;
