import {
  HomeIcon,
  SquareStackIcon,
  GlobeIcon,
  ArchiveIcon,
  ShieldCheckIcon,
  UserCircleIcon,
  UserIcon,
  TruckIcon,
  FileTextIcon,
  BarChartIcon,
  BellIcon,
  Settings2Icon,
  LogOutIcon,
  PieChartIcon,
  LucideIcon,
  LayoutDashboardIcon,
} from "lucide-react";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarSeparator,
  SidebarTrigger,
} from "@/components/ui/sidebar";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { cn } from "@/lib/utils";

interface NavItem {
  icon: LucideIcon;
  text: string;
  to: string;
}

const mainNavigationItems: NavItem[] = [
  { icon: HomeIcon, text: "Dashboard", to: "/dashboard" },
  { icon: SquareStackIcon, text: "Shipments", to: "/dashboard/shipments" },
  { icon: TruckIcon, text: "Carriers", to: "/dashboard/carriers" },
  { icon: UserCircleIcon, text: "Drivers", to: "/dashboard/drivers" },
  { icon: ArchiveIcon, text: "Cargo Owners", to: "/dashboard/cargo-owners" },
  {
    icon: ShieldCheckIcon,
    text: "Verifications",
    to: "/dashboard/verifications",
  },
];

const yardManagementItems: NavItem[] = [
  { icon: LayoutDashboardIcon, text: "Dock View", to: "/dashboard/dock-view" },
];

const vettingItems: NavItem[] = [
  { icon: UserCircleIcon, text: "Vet Driver", to: "/dashboard/vet-driver" },
  { icon: TruckIcon, text: "Vet Carrier", to: "/dashboard/vet-carrier" },
];

const analyticsItems: NavItem[] = [
  { icon: PieChartIcon, text: "Reports", to: "/dashboard/reports" },
];

export function AppSidebar({
  variant,
}: {
  variant?: "sidebar" | "floating" | "inset";
}) {
  return (
    <Sidebar
      className="border-r bg-background w-64 overflow-hidden [&_ul]:list-none [&_li]:list-none [&_*]:list-none"
      {...(variant ? { variant } : {})}
      collapsible="icon"
    >
      <div className="flex h-full flex-col overflow-hidden">
        <SidebarContent className="px-1 [&_ul]:list-none overflow-y-auto overflow-x-hidden">
          <SidebarGroup>
            <SidebarGroupLabel className="px-2 py-0.5 text-xs font-medium">
              Main
            </SidebarGroupLabel>
            <SidebarGroupContent className="space-y-0">
              {mainNavigationItems.map((item, index) => (
                <SidebarMenuItem key={index}>
                  <SidebarMenuButton
                    asChild
                    tooltip={item.text}
                    isActive={window.location.pathname === item.to}
                  >
                    <a
                      href={item.to}
                      className={cn(
                        "flex items-center gap-3 px-2 py-0.5 rounded-md hover:bg-accent hover:text-accent-foreground w-full overflow-hidden",
                        window.location.pathname === item.to
                          ? "bg-accent text-accent-foreground font-medium"
                          : "text-muted-foreground"
                      )}
                    >
                      <item.icon className="h-4 w-4 flex-shrink-0" />
                      <span className="truncate">{item.text}</span>
                    </a>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarGroupContent>
          </SidebarGroup>

          <SidebarGroup className="mt-0">
            <SidebarGroupLabel className="px-2 py-0.5 text-xs font-medium">
              Dispatch Management
            </SidebarGroupLabel>
            <SidebarGroupContent className="space-y-0">
              {yardManagementItems.map((item, index) => (
                <SidebarMenuItem key={index}>
                  <SidebarMenuButton
                    asChild
                    tooltip={item.text}
                    isActive={window.location.pathname === item.to}
                  >
                    <a
                      href={item.to}
                      className={cn(
                        "flex items-center gap-3 px-2 py-1 rounded-md hover:bg-accent hover:text-accent-foreground w-full overflow-hidden",
                        window.location.pathname === item.to
                          ? "bg-accent text-accent-foreground font-medium"
                          : "text-muted-foreground"
                      )}
                    >
                      <item.icon className="h-4 w-4 flex-shrink-0" />
                      <span className="truncate">{item.text}</span>
                    </a>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarGroupContent>
          </SidebarGroup>

          <SidebarSeparator className="my-0" />

          <SidebarGroup>
            <SidebarGroupLabel className="px-2 py-0.5 text-xs font-medium">
              Vetting
            </SidebarGroupLabel>
            <SidebarGroupContent className="space-y-0">
              {vettingItems.map((item, index) => (
                <SidebarMenuItem key={index}>
                  <SidebarMenuButton
                    asChild
                    tooltip={item.text}
                    isActive={window.location.pathname === item.to}
                  >
                    <a
                      href={item.to}
                      className={cn(
                        "flex items-center gap-3 px-2 py-1 rounded-md hover:bg-accent hover:text-accent-foreground w-full overflow-hidden",
                        window.location.pathname === item.to
                          ? "bg-accent text-accent-foreground font-medium"
                          : "text-muted-foreground"
                      )}
                    >
                      <item.icon className="h-4 w-4 flex-shrink-0" />
                      <span className="truncate">{item.text}</span>
                    </a>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarGroupContent>
          </SidebarGroup>

          <SidebarSeparator className="my-0" />

          <SidebarGroup>
            <SidebarGroupLabel className="px-2 py-0.5 text-xs font-medium">
              Analytics
            </SidebarGroupLabel>
            <SidebarGroupContent className="space-y-0">
              <SidebarMenu>
                {analyticsItems.map((item) => (
                  <SidebarMenuItem key={item.to}>
                    <SidebarMenuButton
                      asChild
                      tooltip={item.text}
                      isActive={window.location.pathname === item.to}
                    >
                      <a
                        href={item.to}
                        className={cn(
                          "flex items-center gap-3 px-2 py-1 rounded-md hover:bg-accent hover:text-accent-foreground w-full overflow-hidden",
                          window.location.pathname === item.to
                            ? "bg-accent text-accent-foreground font-medium"
                            : "text-muted-foreground"
                        )}
                      >
                        <item.icon className="h-4 w-4 flex-shrink-0" />
                        <span className="truncate">{item.text}</span>
                      </a>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>

        <SidebarFooter className="border-t p-1.5 overflow-hidden">
          <div className="flex items-center gap-3 overflow-hidden">
            <Avatar className="h-8 w-8 flex-shrink-0">
              <AvatarImage src="https://cdn-icons-png.flaticon.com/512/1077/1077114.png" />
              <AvatarFallback>US</AvatarFallback>
            </Avatar>
            <div className="flex flex-col space-y-0.5 min-w-0 overflow-hidden">
              <span className="text-sm font-medium truncate">Omar Draz</span>
              <span className="text-xs text-muted-foreground truncate">
                omar@indemni.com
              </span>
            </div>
          </div>
        </SidebarFooter>
      </div>
    </Sidebar>
  );
}
