import React from "react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "lucide-react";
import { Button } from "./button";
import { FormControl } from "./form";
import { cn } from "@/lib/utils";
import "react-datepicker/dist/react-datepicker.css";

// Create a forwardRef wrapper for the Button component
const CustomButton = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <Button
      ref={ref}
      variant="outline"
      className={cn(
        "w-full justify-start text-left font-normal shrink [&>span]:w-full",
        className
      )}
      {...props}
    >
      {children}
    </Button>
  )
);

CustomButton.displayName = "CustomButton";

/**
 * SimpleDateTimePicker - An integrated date and time picker using react-datepicker
 */
const SimpleDateTimePicker = ({
  value,
  onChange,
  disabled,
  minDate = new Date(),
  placeholderText = "Select date and time",
  className,
  showTimeSelect = true,
  timeIntervals = 30,
  dateFormat = "MMM d, yyyy h:mm aa",
  timeCaption = "Time",
  filterTime,
  filterDate,
}) => {
  return (
    <div className={cn("relative w-full flex-grow", className)}>
      <DatePicker
        selected={value}
        onChange={onChange}
        disabled={disabled}
        minDate={minDate}
        showTimeSelect={showTimeSelect}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        filterTime={filterTime}
        filterDate={filterDate}
        placeholderText={placeholderText}
        dateFormat={dateFormat}
        customInput={
          <CustomButton
            className={cn(!value && "text-muted-foreground")}
            disabled={disabled}
          >
            {value ? (
              new Intl.DateTimeFormat("en-US", {
                dateStyle: "medium",
                timeStyle: showTimeSelect ? "short" : undefined,
              }).format(value)
            ) : (
              <span>{placeholderText}</span>
            )}
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
          </CustomButton>
        }
        popperClassName="z-50"
        popperPlacement="bottom-start"
      />
    </div>
  );
};

/**
 * FormDateTimePicker - For use with react-hook-form
 */
const FormDateTimePicker = React.forwardRef(({ ...props }, ref) => (
  <FormControl>
    <SimpleDateTimePicker {...props} ref={ref} />
  </FormControl>
));

FormDateTimePicker.displayName = "FormDateTimePicker";

export { SimpleDateTimePicker, FormDateTimePicker };
