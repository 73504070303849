// @ts-nocheck
import React, { useState } from "react";
import { Search, SlidersHorizontal } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

/**
 * DriversFilter - Component for filtering the drivers list
 *
 * @param {Object} props - Component props
 * @param {Function} props.onFilterChange - Callback for filter changes
 */
const DriversFilter = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    searchTerm: "",
  });

  // Handle input change for filters
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFilters = {
      ...filters,
      [name]: value,
    };

    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  // Handle filter reset
  const handleReset = () => {
    const resetFilters = {
      searchTerm: "",
    };
    setFilters(resetFilters);
    onFilterChange(resetFilters);
  };

  return (
    <div className="flex flex-col sm:flex-row gap-2 pb-4">
      <div className="relative flex-1">
        <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          type="text"
          name="searchTerm"
          placeholder="Search drivers by name, ID, license, or email..."
          value={filters.searchTerm}
          onChange={handleInputChange}
          className="pl-8"
        />
      </div>
      <div className="flex items-center gap-2">
        <Button
          variant="outline"
          size="icon"
          onClick={handleReset}
          disabled={!filters.searchTerm}
          title="Reset filters"
        >
          <SlidersHorizontal className="h-4 w-4" />
          <span className="sr-only">Reset filters</span>
        </Button>
      </div>
    </div>
  );
};

export default DriversFilter;
