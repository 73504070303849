import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { CheckCircle, AlertCircle, Clock, User } from "lucide-react";

/**
 * VerificationStats - Displays statistics about verifications
 *
 * @param {Object} props
 * @param {Array} props.verifications - Array of verification data
 */
export function VerificationStats({ verifications = [] }) {
  // Calculate statistics from verifications data
  const stats = React.useMemo(() => {
    if (!verifications.length) {
      return {
        total: 0,
        completed: 0,
        pending: 0,
        failed: 0,
      };
    }

    return verifications.reduce(
      (acc, verification) => {
        acc.total++;

        // Count verifications by persona status
        const personaStatus =
          verification.persona_verification_status?.toLowerCase() || "";
        if (
          personaStatus.includes("completed") ||
          personaStatus.includes("approved")
        ) {
          acc.completed++;
        } else if (
          personaStatus.includes("pending") ||
          personaStatus.includes("in progress")
        ) {
          acc.pending++;
        } else if (
          personaStatus.includes("failed") ||
          personaStatus.includes("rejected")
        ) {
          acc.failed++;
        }

        return acc;
      },
      { total: 0, completed: 0, pending: 0, failed: 0 }
    );
  }, [verifications]);

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Total Verifications
          </CardTitle>
          <User className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{stats.total}</div>
          <p className="text-xs text-muted-foreground">
            Overall verification count
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Completed</CardTitle>
          <CheckCircle className="h-4 w-4 text-green-500" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{stats.completed}</div>
          <p className="text-xs text-muted-foreground">
            {stats.total
              ? `${Math.round((stats.completed / stats.total) * 100)}%`
              : "0%"}{" "}
            of total
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Pending</CardTitle>
          <Clock className="h-4 w-4 text-yellow-500" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{stats.pending}</div>
          <p className="text-xs text-muted-foreground">
            {stats.total
              ? `${Math.round((stats.pending / stats.total) * 100)}%`
              : "0%"}{" "}
            of total
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Failed</CardTitle>
          <AlertCircle className="h-4 w-4 text-red-500" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{stats.failed}</div>
          <p className="text-xs text-muted-foreground">
            {stats.total
              ? `${Math.round((stats.failed / stats.total) * 100)}%`
              : "0%"}{" "}
            of total
          </p>
        </CardContent>
      </Card>
    </div>
  );
}
