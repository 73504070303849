import React from "react";

const LoadingSpinner = ({ size = "medium" }) => {
  const sizeClasses = {
    small: "h-6 w-6",
    medium: "h-12 w-12",
    large: "h-16 w-16",
  };

  return (
    <div
      className={`animate-spin rounded-full border-b-2 border-gray-900 ${sizeClasses[size]}`}
    />
  );
};

export default LoadingSpinner;
