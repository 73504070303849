// @ts-nocheck
import React, { useState } from "react";
import { Helmet } from "react-helmet"; // SEO helper
import { Button } from "../Components/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../Components/Card";
import { Input } from "../Components/Input";
import { Label } from "../Components/Label";
import { supabase } from "../../lib/supabase";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(null);
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Parse the hash fragment from the URL to extract auth data
    const hashFragment = location.hash;
    if (hashFragment && hashFragment.includes("access_token")) {
      // Set the session in Supabase from the hash
      supabase.auth
        .setSession({
          access_token: getParameterFromHash("access_token", hashFragment),
          refresh_token: getParameterFromHash("refresh_token", hashFragment),
        })
        .then(({ data, error }) => {
          if (error) {
            setError("Invalid or expired reset link");
            toast.error(
              "Invalid or expired reset link. Please request a new password reset.",
              {
                duration: 4000,
                position: "top-center",
                style: {
                  background:
                    "linear-gradient(135deg, #FFB74D 0%, #FF9800 100%)",
                  color: "#fff",
                  borderRadius: "8px",
                  padding: "12px 24px",
                  width: "500px",
                  boxShadow:
                    "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                  fontSize: "14px",
                  fontWeight: "500",
                },
              }
            );
            return;
          }

          if (data.session) {
            setSession(data.session);
          }
        });
    } else {
      setError("No reset token found");
      toast.error(
        "No reset token found. Please request a new password reset.",
        {
          duration: 4000,
          position: "top-center",
          style: {
            background: "linear-gradient(135deg, #FFB74D 0%, #FF9800 100%)",
            color: "#fff",
            borderRadius: "8px",
            padding: "12px 24px",
            width: "500px",
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            fontSize: "14px",
            fontWeight: "500",
          },
        }
      );
    }
  }, [location]);

  // Helper function to extract parameters from the hash fragment
  const getParameterFromHash = (paramName, hash) => {
    const paramString = hash.substring(1);
    const params = new URLSearchParams(paramString.replace(/#/g, "&"));
    return params.get(paramName);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Validate passwords match
      if (password !== confirmPassword) {
        throw new Error("Passwords do not match");
      }

      if (password.length < 6) {
        throw new Error("Password must be at least 6 characters");
      }

      if (!session) {
        throw new Error(
          "Invalid session. Please request a new password reset link."
        );
      }

      // Use Supabase to update the user's password
      const { error } = await supabase.auth.updateUser({
        password: password,
      });

      if (error) throw error;

      toast.success(
        "Password reset successful! You can now login with your new password.",
        {
          duration: 4000,
          position: "top-center",
          style: {
            background: "linear-gradient(135deg, #4CAF50 0%, #45a049 100%)",
            color: "#fff",
            borderRadius: "8px",
            padding: "12px 24px",
            width: "500px",
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            fontSize: "14px",
            fontWeight: "500",
          },
        }
      );

      // Sign out after successful reset
      await supabase.auth.signOut();
      navigate("/signin");
    } catch (error) {
      toast.error(error.message, {
        duration: 4000,
        position: "top-center",
        style: {
          background: "linear-gradient(135deg, #FFB74D 0%, #FF9800 100%)",
          color: "#fff",
          borderRadius: "8px",
          padding: "12px 24px",
          width: "500px",
          boxShadow:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          fontSize: "14px",
          fontWeight: "500",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative h-screen w-full bg-custom-photo bg-no-repeat bg-cover flex justify-center items-center">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Reset Password - Indemni</title>
        <meta
          name="description"
          content="Reset your password for Indemni's secure cargo risk management platform."
        />
        <link rel="canonical" href="https://indemni.com/reset-password" />
        <meta property="og:title" content="Reset Password - Indemni" />
        <meta
          property="og:description"
          content="Reset your password for Indemni's secure cargo risk management platform."
        />
        <meta property="og:url" content="https://indemni.com/reset-password" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Card
        className="w-full max-w-md shadow-lg rounded-lg overflow-hidden z-10"
        testID="reset-password-card"
      >
        <form onSubmit={handleSubmit}>
          <CardHeader className="text-left">
            <CardTitle className="text-2xl font-bold">
              Reset Your Password
            </CardTitle>
            <CardDescription className="text-sm text-gray-600 mt-2">
              Enter your new password below
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4 px-6">
            <div className="space-y-1">
              <Label htmlFor="password">New Password</Label>
              <Input
                id="password"
                type="password"
                placeholder="Enter your new password"
                className="w-full bg-white"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                testID="new-password-input"
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="confirm_password">Confirm New Password</Label>
              <Input
                id="confirm_password"
                type="password"
                placeholder="Confirm your new password"
                className="w-full bg-white"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                testID="confirm-password-input"
              />
            </div>
          </CardContent>
          <CardFooter className="px-6 py-4">
            <Button
              type="submit"
              className="w-full bg-blue-900 text-white py-2 rounded-md hover:bg-indigo-500"
              disabled={loading || error || !session}
              testID="reset-password-button"
            >
              {loading ? "Resetting Password..." : "Reset Password"}
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
}
// ... existing code ...
