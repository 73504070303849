// @ts-nocheck
import { supabase } from "../../../lib/supabase";

// Base URL for generating verification links
const SERVER_URL =
  process.env.REACT_APP_URL_BASE_FOR_SERVER || "https://localhost:3000";

// Sanitize inputs by allowing letters, numbers, specific symbols (including plus), and whitespace
const sanitizeInput = (input) => {
  if (!input) return "";
  return input
    .toString()
    .replace(/[^a-zA-Z0-9@._+\-\s]/g, "")
    .trim();
};

// Extract the access token from the Supabase session; returns an empty string if not available
const getRequestToken = async () => {
  const { data } = await supabase.auth.getSession();
  return data?.session?.access_token || "";
};

const NotificationService = {
  /**
   * Send an email using a preset email configuration.
   * The 'from' field is determined by the server based on the email type.
   *
   * @param {string} to - Recipient email address
   * @param {string} subject - Email subject line
   * @param {string} html - Email content in HTML format
   * @param {string} emailType - Type of the email (used to select the config)
   * @param {EmailOptions} [options] - Optional metadata for rate limiting
   * @returns {Promise<Object>} Response object with success status and a messageId on success
   */
  sendEmail: async (to, subject, html, emailType, options = {}) => {
    try {
      const sanitizedTo = sanitizeInput(to);
      const sanitizedSubject = sanitizeInput(subject);
      const requestToken = await getRequestToken();

      // Sanitize optional fields if present
      const sanitizedOptions = {
        firstName: options.firstName
          ? sanitizeInput(options.firstName)
          : undefined,
        lastName: options.lastName
          ? sanitizeInput(options.lastName)
          : undefined,
        companyName: options.companyName
          ? sanitizeInput(options.companyName)
          : undefined,
      };

      // Invoke the "send-email" edge function without TypeScript generics.
      const { data, error } = await supabase.functions.invoke("send-email", {
        body: {
          to: sanitizedTo,
          subject: sanitizedSubject,
          html,
          requestToken,
          emailType,
          ...sanitizedOptions,
        },
      });

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  },

  /**
   * Send a generic message (e.g., SMS message) via the 'send-message' function.
   *
   * @param {string} body - Text content of the message
   * @param {string} from - Sender's identifier/number
   * @param {string} to - Recipient's identifier/number
   * @returns {Promise<Object>} Response object indicating success or error
   */
  sendMessage: async (body, from, to) => {
    try {
      console.log("Sending message with parameters:", { body, from, to });

      const sanitizedFrom = sanitizeInput(from);
      const sanitizedTo = sanitizeInput(to);
      const requestToken = await getRequestToken();

      console.log("Sanitized parameters:", {
        sanitizedFrom,
        sanitizedTo,
        hasToken: !!requestToken,
      });

      console.log("Invoking send-message function...");
      const { data, error } = await supabase.functions.invoke("send-message", {
        body: {
          body: body,
          from: sanitizedFrom,
          to: sanitizedTo,
          requestToken,
        },
      });

      if (error) {
        console.error("Error from send-message function:", error);
        throw error;
      }

      console.log("Message sent successfully:", data);
      return data;
    } catch (error) {
      console.error("Error sending message:", error);
      throw error;
    }
  },

  /**
   * Send an SMS message. Uses the same parameter ordering as sendMessage.
   *
   * @param {string} body - SMS text content
   * @param {string} from - Sender's phone number
   * @param {string} to - Recipient's phone number
   * @returns {Promise<Object>} Response object indicating success or error
   */
  sendSMS: async (body, from, to) => {
    return NotificationService.sendMessage(body, from, to);
  },

  /**
   * Send a verification SMS containing a link for verification.
   *
   * @param {Object} verification - Object with shipment_id and phone_number properties
   * @returns {Promise<Object>} Response object indicating success or error
   */
  sendVerificationSMS: async (verification) => {
    const twilioFrom = process.env.REACT_APP_TWILIO_PHONE_NUMBER;
    if (!SERVER_URL || !twilioFrom) {
      throw new Error("SERVER_URL or TWILIO_PHONE_NUMBER is not set");
    }

    // Encode the shipment_id to preserve special characters
    const encodedShipmentId = encodeURIComponent(verification.shipment_id);
    const verificationUrl = `${SERVER_URL}/verify/${encodedShipmentId}`;

    // Use a plain URL - Twilio's Messaging Service will handle link formatting
    const body = `Please complete your verification at: ${verificationUrl}`;

    // Pass a messaging service SID instead of direct phone number
    const messagingServiceSid =
      process.env.REACT_APP_TWILIO_MESSAGING_SERVICE_SID;

    if (!messagingServiceSid) {
      return NotificationService.sendSMS(
        body,
        twilioFrom,
        verification.phone_number
      );
    } else {
      // Use messaging service with link shortening enabled
      return NotificationService.sendMessage(
        body,
        messagingServiceSid,
        verification.phone_number
      );
    }
  },

  /**
   * Send a verification SMS containing a link for business verification.
   *
   * @param {Object} verification - Object with carrier_id, persona_inquiry_id and phone_number properties
   * @returns {Promise<Object>} Response object indicating success or error
   */
  sendBusinessVerificationSMS: async (verification) => {
    const twilioFrom = process.env.REACT_APP_TWILIO_PHONE_NUMBER;
    if (!SERVER_URL || !twilioFrom) {
      throw new Error("SERVER_URL or TWILIO_PHONE_NUMBER is not set");
    }

    const inquiryId = verification.persona_inquiry_id || "missing";

    const encodedInquiryId = encodeURIComponent(inquiryId);

    // Construct the URL
    const verificationUrl = `${SERVER_URL}/verify-business/${encodedInquiryId}/`;

    // Use Twilio's Messaging Service with link shortening
    const messagingServiceSid =
      process.env.REACT_APP_TWILIO_MESSAGING_SERVICE_SID;

    // Format the message with text before and after the URL
    const body = `Please complete your business verification at: ${verificationUrl} (Link expires in 24 hours)`;

    if (messagingServiceSid) {
      return NotificationService.sendMessage(
        body,
        messagingServiceSid,
        verification.phone_number
      );
    } else {
      return NotificationService.sendSMS(
        body,
        twilioFrom,
        verification.phone_number
      );
    }
  },

  /**
   * Send a verification SMS containing a link for business owner verification.
   *
   * @param {Object} verificationInput - Object expected to have phone_number, persona_inquiry_id, businessOwner, businessName
   * @returns {Promise<Object>} Response object indicating success or error
   */
  sendBusinessOwnerVerificationSMS: async (verificationInput) => {
    // Log the EXACT object received
    console.log(
      "[sendBusinessOwnerVerificationSMS] Received input:",
      verificationInput
    );

    const twilioFrom = process.env.REACT_APP_TWILIO_PHONE_NUMBER;
    if (!SERVER_URL || !twilioFrom) {
      console.error("SERVER_URL or TWILIO_PHONE_NUMBER is not set", {
        SERVER_URL,
        twilioFrom,
      });
      throw new Error("SERVER_URL or TWILIO_PHONE_NUMBER is not set");
    }

    // Explicitly extract required fields with fallbacks
    const phoneNumber = verificationInput?.phone_number; // Extract the phone number
    const inquiryId = verificationInput?.persona_inquiry_id ?? "missing";
    const businessOwner = verificationInput?.businessOwner ?? "Valued Customer"; // Default if missing
    const businessName = verificationInput?.businessName ?? "Your Company"; // Default if missing

    // Log extracted values for debugging
    console.log(
      "[sendBusinessOwnerVerificationSMS] Extracted phone_number:",
      phoneNumber
    );
    console.log(
      "[sendBusinessOwnerVerificationSMS] Extracted persona_inquiry_id:",
      inquiryId
    );
    console.log(
      "[sendBusinessOwnerVerificationSMS] Extracted businessOwner:",
      businessOwner
    );
    console.log(
      "[sendBusinessOwnerVerificationSMS] Extracted businessName:",
      businessName
    );

    // === Add validation for the extracted phone number ===
    if (!phoneNumber) {
      console.error(
        "[sendBusinessOwnerVerificationSMS] Error: phone_number is missing, null, or empty in input object.",
        verificationInput
      );
      throw new Error(
        "Cannot send business owner verification SMS: phone_number is missing or invalid."
      );
    }
    // ======================================================

    const encodedInquiryId = encodeURIComponent(inquiryId);
    const verificationUrl = `${SERVER_URL}/verify-business-owner/${encodedInquiryId}/`;
    console.log(
      "[sendBusinessOwnerVerificationSMS] Verification URL:",
      verificationUrl
    );

    const messagingServiceSid =
      process.env.REACT_APP_TWILIO_MESSAGING_SERVICE_SID;
    console.log(
      "[sendBusinessOwnerVerificationSMS] Using messaging service SID:",
      messagingServiceSid || "Not set, using direct phone number"
    );

    // Use the explicitly extracted businessOwner and businessName
    const body = `Hi ${businessOwner},\n\nWe're verifying your role as a business owner at ${businessName}. Please complete your business verification at: ${verificationUrl}\n\nThis verification is required to maintain compliance and security. The link expires in 24 hours.\n\nThank you for your cooperation!`;
    console.log("[sendBusinessOwnerVerificationSMS] SMS body:", body);

    try {
      let result;
      // === Use the explicitly extracted phoneNumber variable ===
      if (messagingServiceSid) {
        result = await NotificationService.sendMessage(
          body,
          messagingServiceSid,
          phoneNumber // Use extracted variable
        );
      } else {
        result = await NotificationService.sendSMS(
          body,
          twilioFrom,
          phoneNumber // Use extracted variable
        );
      }
      // ========================================================
      console.log("Owner SMS sent successfully:", result);
      return result;
    } catch (error) {
      console.error("Error sending business owner verification SMS:", error);
      throw error;
    }
  },

  /**
   * Send an insurance verification SMS containing a link for business verification.
   *
   * @param {Object} verification - Object with carrier_id, persona_inquiry_id and phone_number properties
   * @returns {Promise<Object>} Response object indicating success or error
   */
  sendInsuranceVerificationSMS: async (verification) => {
    const twilioFrom = process.env.REACT_APP_TWILIO_PHONE_NUMBER;
    if (!SERVER_URL || !twilioFrom) {
      throw new Error("SERVER_URL or TWILIO_PHONE_NUMBER is not set");
    }

    const inquiryId = verification.persona_inquiry_id || "missing";

    const encodedInquiryId = encodeURIComponent(inquiryId);

    // Construct the full URL with encoded components
    const verificationUrl = `${SERVER_URL}/verify-insurance/${encodedInquiryId}`;

    const body = `Please complete your insurance verification at: ${verificationUrl}`;

    // Pass a messaging service SID instead of direct phone number
    const messagingServiceSid =
      process.env.REACT_APP_TWILIO_MESSAGING_SERVICE_SID;

    if (!messagingServiceSid) {
      return NotificationService.sendSMS(
        body,
        twilioFrom,
        verification.phone_number
      );
    } else {
      // Use messaging service with link shortening enabled
      return NotificationService.sendMessage(
        body,
        messagingServiceSid,
        verification.phone_number
      );
    }
  },
};

// Export selected functions for convenience
export const {
  sendEmail,
  sendSMS,
  sendVerificationSMS,
  sendBusinessVerificationSMS,
  sendInsuranceVerificationSMS,
  sendBusinessOwnerVerificationSMS,
} = NotificationService;
