import React from "react";
import AttributeGroup from "../AttributeGroup";
import { formatAttributeName, formatDate } from "../utils";

/**
 * Renders a business registrations lookup report
 * @param {Object} props
 * @param {Object} props.attributes - The report attributes
 */
const BusinessRegistrationsLookup = ({ attributes }) => {
  if (!attributes) {
    return (
      <div className="text-slate-500">
        No business registration data available
      </div>
    );
  }

  // Basic report information
  const basicInfo = [
    "status",
    "has-match",
    "created-at",
    "completed-at",
    "redacted-at",
    "is-recurring",
    "is-continuous",
    "report-template-version-name",
  ];

  return (
    <div className="space-y-6">
      <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
        <h3 className="text-blue-800 font-medium mb-3 text-sm flex items-center">
          <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          Report Information
        </h3>
        <div className="bg-white rounded-lg divide-y divide-slate-100 shadow-sm">
          <AttributeGroup attributes={attributes} keys={basicInfo} />
        </div>
      </div>

      {attributes["registry-records"] &&
        attributes["registry-records"].length > 0 && (
          <div className="bg-emerald-50 p-4 rounded-lg border border-emerald-100">
            <h3 className="text-emerald-800 font-medium mb-3 text-sm flex items-center">
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                ></path>
              </svg>
              Registry Records
            </h3>
            <div className="space-y-4">
              {attributes["registry-records"].map((record, index) => (
                <div
                  key={index}
                  className="bg-white p-4 rounded-lg border border-slate-200"
                >
                  <div className="flex justify-between items-start mb-3">
                    <div>
                      <h4 className="font-medium text-slate-800">
                        {record.registered_name || "Unknown Business Name"}
                      </h4>
                      <p className="text-xs text-slate-500">
                        {formatAttributeName(record.type)} • File #
                        {record.file_number || "N/A"}
                      </p>
                    </div>
                    {record.status && (
                      <span
                        className={`px-2 py-1 text-xs rounded-full ${
                          record.status.standardized === "active"
                            ? "bg-green-100 text-green-800"
                            : record.status.standardized === "inactive"
                            ? "bg-red-100 text-red-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {record.status.raw ||
                          formatAttributeName(record.status.standardized) ||
                          "Unknown Status"}
                      </span>
                    )}
                  </div>

                  <div className="grid md:grid-cols-2 gap-4 mb-3">
                    <div>
                      <span className="text-xs text-gray-500">
                        Issuing Authority
                      </span>
                      <p className="font-medium text-slate-700">
                        {formatAttributeName(record.issuing_authority) ||
                          "Not Found"}
                      </p>
                    </div>
                    <div>
                      <span className="text-xs text-gray-500">Issue Date</span>
                      <p className="font-medium text-slate-700">
                        {record.issue_date
                          ? formatDate(record.issue_date)
                          : "Not Found"}
                      </p>
                    </div>
                    <div>
                      <span className="text-xs text-gray-500">
                        Registration Type
                      </span>
                      <p className="font-medium text-slate-700">
                        {formatAttributeName(record.registration_type) ||
                          "Not Found"}
                      </p>
                    </div>
                    <div>
                      <span className="text-xs text-gray-500">
                        Registration State
                      </span>
                      <p className="font-medium text-slate-700">
                        {record.registration_state || "Not Found"}
                      </p>
                    </div>
                  </div>

                  {record.addresses && record.addresses.length > 0 && (
                    <div className="mt-4">
                      <h5 className="text-sm font-medium text-slate-700 mb-2">
                        Addresses
                      </h5>
                      <div className="bg-slate-50 rounded-lg p-3 border border-slate-200">
                        <div className="space-y-3">
                          {record.addresses.map((address, addrIndex) => (
                            <div
                              key={addrIndex}
                              className="p-2 bg-white rounded border border-slate-100"
                            >
                              <div className="flex justify-between">
                                <span className="text-xs font-medium text-slate-600">
                                  {formatAttributeName(address.type) ||
                                    "Address"}
                                </span>
                              </div>
                              <p className="text-sm mt-1 text-slate-700">
                                {[
                                  address.street_1,
                                  address.street_2,
                                  `${address.city || ""}, ${
                                    address.subdivision || ""
                                  } ${address.postal_code || ""}`,
                                  address.country_code,
                                ]
                                  .filter(Boolean)
                                  .join(", ")}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                  {record.associated_persons &&
                    record.associated_persons.length > 0 && (
                      <div className="mt-4">
                        <h5 className="text-sm font-medium text-slate-700 mb-2">
                          Associated Persons
                        </h5>
                        <div className="bg-slate-50 rounded-lg p-3 border border-slate-200">
                          <div className="space-y-3">
                            {record.associated_persons.map(
                              (person, personIndex) => (
                                <div
                                  key={personIndex}
                                  className="p-2 bg-white rounded border border-slate-100"
                                >
                                  <div className="text-sm text-slate-700">
                                    <span className="font-medium">
                                      {person.names && person.names.length > 0
                                        ? person.names[0].value
                                        : "Unknown Name"}
                                    </span>
                                    {person.positions &&
                                      person.positions.length > 0 && (
                                        <span className="text-xs text-slate-500 ml-2">
                                          {person.positions
                                            .map((pos) =>
                                              formatAttributeName(pos.value)
                                            )
                                            .join(", ")}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>
        )}
    </div>
  );
};

export default BusinessRegistrationsLookup;
