import React from "react";

const InputField = ({
  label,
  placeholder,
  type = "text",
  icon,
  htmlFor,
  value,
  onChange,
  name,
  ariaLabel,
  testId,
  variant = "light",
  className,
  error,
  helperText,
  ...props
}) => {
  const inputClasses = `block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset 
    ${
      variant === "dark"
        ? "bg-white/5 text-white placeholder-gray-400 ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        : "bg-white text-gray-900 placeholder-gray-400 ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
    } 
    ${error ? "ring-red-500 focus:ring-red-500" : ""}
    ${className || ""}`;

  return (
    <div className="flex flex-col space-y-1.5 mb-4">
      <label
        htmlFor={htmlFor}
        className={`block text-sm font-semibold leading-6 ${
          variant === "dark" ? "text-white" : "text-gray-900"
        }`}
      >
        {label}
      </label>
      <div className="relative">
        <input
          id={htmlFor}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          className={inputClasses}
          aria-label={ariaLabel}
          data-testid={testId}
          aria-invalid={error ? "true" : "false"}
          {...props}
        />
        {icon && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#7b61ff]">
            {icon}
          </div>
        )}
      </div>
      {error && <p className="text-sm text-red-500 mt-1">{error}</p>}
      {helperText && !error && (
        <p className="text-sm text-gray-500 mt-1">{helperText}</p>
      )}
    </div>
  );
};

export default InputField;
