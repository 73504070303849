import { supabase } from "../../../lib/supabase";

export const WorkflowService = {
  async saveWorkflow(workflowData) {
    const { data, error } = await supabase
      .from("workflows")
      .insert(workflowData)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  async updateWorkflow(id, workflow) {
    const { data, error } = await supabase
      .from("workflows")
      .update({
        name: workflow.name,
        nodes: workflow.nodes,
        edges: workflow.edges,
        updated_at: new Date().toISOString(),
      })
      .eq("id", id)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  async getWorkflow(id) {
    const { data, error } = await supabase
      .from("workflows")
      .select("*")
      .eq("id", id)
      .single();

    if (error) throw error;
    return data;
  },

  async getWorkflows() {
    const { data, error } = await supabase.from("workflows").select("*");

    if (error) throw error;
    return { workflows: data };
  },

  async getWorkflowTemplates() {
    const { data, error } = await supabase
      .from("workflows")
      .select("*")
      .eq("is_template", true);

    if (error) throw error;
    return data;
  },
};
