import * as React from "react";
import { Card } from "@/components/ui/card";
import { Package, Truck, CheckCircle, XCircle, Clock } from "lucide-react";

/**
 * StatCard - A compact card displaying a single statistic with an icon
 */
function StatCard({ title, value, icon: Icon, description, className = "" }) {
  return (
    <Card className={`p-4 relative ${className}`}>
      <div className="mb-2 flex justify-between items-center">
        <div className="text-sm font-medium">{title}</div>
        {Icon && <Icon className="h-5 w-5 text-gray-500" aria-hidden="true" />}
      </div>
      <div className="text-3xl font-bold mb-1">{value}</div>
      {description && <p className="text-xs text-gray-500">{description}</p>}
    </Card>
  );
}

/**
 * ShipmentStats - Displays summary statistics for shipments
 *
 * @param {Object} props - Component props
 * @param {Array} props.shipments - Array of shipment data
 */
export function ShipmentStats({ shipments = [] }) {
  // Calculate statistics based on shipment data
  const stats = React.useMemo(() => {
    const total = shipments.length;
    const pending = shipments.filter((s) => s.status === "pending").length;
    const processing = shipments.filter(
      (s) => s.status === "processing"
    ).length;
    const shipped = shipments.filter((s) => s.status === "shipped").length;
    const delivered = shipments.filter((s) => s.status === "delivered").length;
    const cancelled = shipments.filter((s) => s.status === "cancelled").length;

    const inProgress = pending + processing;
    const inTransit = shipped;
    const completed = delivered;

    return {
      total,
      inProgress,
      inTransit,
      completed,
      cancelled,
    };
  }, [shipments]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
      <StatCard
        title="Total Shipments"
        value={stats.total}
        icon={Package}
        description="All shipments"
        className="border-l-2 border-l-slate-400"
      />
      <StatCard
        title="In Progress"
        value={stats.inProgress}
        icon={Clock}
        description="Being prepared"
        className="border-l-2 border-l-amber-200"
      />
      <StatCard
        title="In Transit"
        value={stats.inTransit}
        icon={Truck}
        description="On the way"
        className="border-l-2 border-l-[#119DA4]"
      />
      <StatCard
        title="Delivered"
        value={stats.completed}
        icon={CheckCircle}
        description="Successfully delivered"
        className="border-l-2 border-l-emerald-200"
      />
      <StatCard
        title="Cancelled"
        value={stats.cancelled}
        icon={XCircle}
        description="Order cancelled"
        className="border-l-2 border-l-rose-200"
      />
    </div>
  );
}
