const { supabase } = require("../../lib/supabase");

// Define inquiry templates to match Edge Function's INQUIRY_CONFIGS
const INQUIRY_TEMPLATES = {
  "document-verification": "document-verification",
  "government-id": "government-id",
  "government-id-selfie": "government-id-selfie",
  "kyb-business": "kyb-business",
  "kyb-individual": "kyb-individual",
  "insurance-document": "insurance-document",
};

class PersonaProvider {
  constructor(config = {}) {
    this.config = {
      // Default configuration
      apiUrl: process.env.NEXT_PUBLIC_SUPABASE_URL || "",
      // Allow overriding defaults
      ...config,
    };
  }

  /**
   * Creates a new verification inquiry through Persona's API
   *
   * @param {Object} data - The data needed to create the inquiry
   * @param {string} data.entityType - Type of entity being verified ('carrier', 'driver', or 'shipment')
   * @param {string|number} data.entityId - ID of the entity being verified
   * @param {string} [data.phoneNumber] - Phone number for contact
   * @param {string} verificationMethod - The verification method to use
   * @param {string} [templateId=document-verification] - The Persona template ID to use (must match INQUIRY_TEMPLATES)
   * @returns {Promise<Object>} Object containing inquiry details
   * @returns {string} returns.inquiryId - The created Persona inquiry ID
   * @returns {string} returns.referenceId - The reference ID for tracking
   * @returns {string} returns.entityType - The type of entity verified
   * @returns {string|number} returns.entityId - The ID of entity verified
   * @throws {Error} If required fields are missing or API call fails
   */
  async createInquiry(
    data,
    verificationMethod,
    templateId = "document-verification"
  ) {
    try {
      if (!data.entityType || !data.entityId) {
        throw new Error(
          "Entity type and ID are required for creating an inquiry"
        );
      }

      console.log("🔍 [PersonaProvider] Creating inquiry:", {
        data,
        verificationMethod,
        templateId,
      });

      const { data: responseData, error } = await this._invokeFunction(
        "create-persona-inquiry",
        {
          template_id: templateId,
          phoneNumber: data.phoneNumber,
          referenceId: `${data.entityType}_${data.entityId}`,
          [data.entityType === "carrier"
            ? "carrier_id"
            : data.entityType === "driver"
            ? "driver_id"
            : "shipment_id"]: data.entityId,
          verification_method: verificationMethod,
          company_id: data.companyId || data.company_id || null,
          entity_type: data.entityType,
        }
      );

      if (error) {
        throw new Error(`Failed to create inquiry: ${error.message}`);
      }

      if (!responseData?.success) {
        throw new Error(
          responseData?.error || "Failed to create Persona Inquiry"
        );
      }

      return {
        inquiryId: responseData.inquiryId,
        referenceId: responseData.referenceId,
        entityType: data.entityType,
        entityId: data.entityId,
      };
    } catch (error) {
      console.error("🚨 [PersonaProvider] Error creating inquiry:", error);
      throw error;
    }
  }

  /**
   * Checks the status of a Persona inquiry
   *
   * @param {string} inquiryId - The ID of the Persona inquiry to check
   * @returns {Promise<Object>} Object containing inquiry status details
   * @returns {string} returns.inquiryId - The ID of the inquiry checked
   * @returns {string} returns.status - The raw status from Persona
   * @returns {boolean} returns.passed - Whether the inquiry passed verification
   * @returns {Object} returns.rawResponse - The complete response from Persona
   * @returns {string} returns.standardStatus - Mapped standard status (completed/pending/failed)
   * @throws {Error} If inquiryId is missing or API call fails
   */
  async checkPersonaInquiryStatus(inquiryId) {
    try {
      if (!inquiryId) {
        throw new Error("Inquiry ID is required");
      }

      console.log("🔍 [PersonaProvider] Checking inquiry status:", {
        inquiryId,
      });

      const { data: responseData, error } = await this._invokeFunction(
        "check-persona-status",
        {
          body: JSON.stringify({ inquiryId }),
        }
      );

      if (error) {
        throw new Error(`Failed to check inquiry status: ${error.message}`);
      }

      const inquiryAttributes = responseData?.data?.attributes || {};
      const rawStatus = inquiryAttributes?.status || "";
      const inquiryStatus = rawStatus.toLowerCase().replace(/_/g, " ");

      const inquiryPassed =
        inquiryStatus === "approved" || inquiryStatus === "completed";

      return {
        inquiryId,
        status: inquiryStatus,
        passed: inquiryPassed,
        rawResponse: responseData,
        standardStatus: this._mapStatus(inquiryStatus),
      };
    } catch (error) {
      console.error(
        "🚨 [PersonaProvider] Error checking inquiry status:",
        error
      );
      throw error;
    }
  }

  /**
   * Creates a new report through Persona's API
   *
   * @param {Object} reportData - The data needed to create the report
   * @param {string} reportData.reportType - Type of report to create (e.g. 'phone-risk-report', 'email-risk-report', 'business-verification-transaction')
   * @param {string} [reportData.referenceId] - Optional reference ID for tracking
   * @param {string|Object} [reportData.query] - Query parameters for the report
   * @param {number} [reportData.carrier_id] - Optional carrier ID to associate with report
   * @param {Object} [reportData.business_fields] - Optional fields for business reports and transactions
   * @param {string} reportData.business_fields.business_name - Business name
   * @param {string} [reportData.business_fields.phone_number] - Business phone
   * @param {string} [reportData.business_fields.email] - Business email
   * @param {string} [reportData.business_fields.website] - Business website
   * @param {string} [reportData.business_fields.ein] - Business EIN
   * @param {Object} [reportData.business_fields.business_registered_address] - Business address details
   * @param {string} reportData.business_fields.business_registered_address.street_1 - Street address line 1
   * @param {string} [reportData.business_fields.business_registered_address.street_2] - Street address line 2
   * @param {string} reportData.business_fields.business_registered_address.city - City
   * @param {string} reportData.business_fields.business_registered_address.subdivision - State/province
   * @param {string} reportData.business_fields.business_registered_address.postal_code - ZIP/Postal code
   * @param {string} reportData.business_fields.business_registered_address.country_code - Country code (e.g., "US")
   * @returns {Promise<Object>} The created report data from Persona
   * @throws {Error} If report creation fails or required fields are missing
   */
  async createReport(reportData) {
    try {
      // Data validation
      this._validateReportData(reportData);

      // Format request based on report type
      const formattedRequest = this._formatReportRequest(reportData);

      // Call Edge Function with prepared data
      const { data: responseData, error } = await this._invokeFunction(
        "create-persona-report-v2",
        formattedRequest
      );

      // Handle errors and transform response
      if (error) {
        throw new Error(`Failed to create report: ${error.message}`);
      }

      // Transform and return response
      return this._transformReportResponse(responseData);
    } catch (error) {
      console.error("🚨 [PersonaProvider] Error creating report:", error);
      throw error;
    }
  }

  /**
   * Checks the status of a Persona report
   *
   * @param {string} reportId - The ID of the Persona report to check
   * @returns {Promise<Object>} Object containing report status details
   * @returns {string} returns.reportId - The ID of the report checked
   * @returns {string} returns.status - The report status from Persona
   * @returns {Object} returns.attributes - Report attributes from Persona
   * @returns {Object} returns.rawResponse - The complete response from Persona
   * @throws {Error} If reportId is missing or API call fails
   */
  async checkReportStatus(reportId) {
    try {
      if (!reportId) {
        throw new Error("Report ID is required");
      }

      console.log("🔍 [PersonaProvider] Checking report status:", {
        reportId,
      });

      const { data: responseData, error } = await this._invokeFunction(
        "check-persona-report-status",
        {
          body: JSON.stringify({ reportId }),
        }
      );

      if (error) {
        throw new Error(`Failed to check report status: ${error.message}`);
      }

      return responseData?.data || null;
    } catch (error) {
      console.error(
        "🚨 [PersonaProvider] Error checking report status:",
        error
      );
      throw error;
    }
  }

  /**
   * Maps Persona statuses to standardized internal statuses
   *
   * @param {string} personaStatus - The status from Persona API
   * @returns {string} - Standardized internal status
   * @private
   */
  _mapStatus(personaStatus) {
    const statusMap = {
      approved: "completed",
      completed: "completed",
      declined: "failed",
      pending: "pending",
      reviewing: "pending",
      expired: "failed",
      errored: "failed",
    };

    return statusMap[personaStatus.toLowerCase()] || "pending";
  }

  /**
   * Maps Persona report statuses to standardized internal statuses
   *
   * @param {string} reportStatus - The status from Persona Report API
   * @returns {string} - Standardized internal status
   * @private
   */
  _mapReportStatus(reportStatus) {
    const statusMap = {
      ready: "completed",
      processing: "pending",
      pending: "pending",
      errored: "failed",
    };

    return statusMap[reportStatus.toLowerCase()] || "pending";
  }

  /**
   * Helper method to invoke Supabase Edge Functions
   *
   * @param {string} functionName - The name of the function to invoke
   * @param {Object} functionData - The data to pass to the function
   * @returns {Promise<Object>} - The function response
   * @private
   */
  async _invokeFunction(functionName, functionData) {
    if (functionName === "create-persona-report") {
      return await supabase.functions.invoke("create-persona-report-v2", {
        body: functionData,
      });
    }

    try {
      console.log(
        `📤 Invoking function ${functionName} with data:`,
        JSON.stringify(functionData, null, 2)
      );

      const response = await supabase.functions.invoke(functionName, {
        body: functionData,
      });

      // Check if the response is empty
      if (!response.data && !response.error) {
        throw new Error("Empty response received from function");
      }

      return response;
    } catch (error) {
      console.error(`❌ Error invoking function ${functionName}:`, error);
      throw error;
    }
  }

  // Private methods to handle business logic
  _validateReportData(reportData) {
    if (!reportData.reportType) {
      throw new Error("Report type is required");
    }

    // Additional validation for business reports
    if (
      [
        "business-verification-transaction",
        "business-registrations-lookup",
      ].includes(reportData.reportType)
    ) {
      if (!reportData.business_fields && !reportData.inputData) {
        throw new Error(
          `business_fields is required for ${reportData.reportType}`
        );
      }
    }
  }

  _formatReportRequest(reportData) {
    const { reportType, entityId, inputData, company_id } = reportData;

    // For email/phone risk reports
    if (reportType === "email-risk-report") {
      return {
        reportType,
        query: { "email-address": inputData.email },
        carrier_id: entityId,
        company_id: company_id,
      };
    }

    if (reportType === "phone-risk-report") {
      return {
        reportType,
        query: {
          "phone-number": inputData.phone_number || inputData.phoneNumber,
        },
        carrier_id: entityId,
        company_id: company_id,
      };
    }

    // For business verification reports
    if (
      [
        "business-verification-transaction",
        "business-registrations-lookup",
      ].includes(reportType)
    ) {
      // Log the input data for debugging
      console.log(
        "[PersonaProvider] Business verification input data:",
        inputData
      );

      // Use business_fields directly if it exists, otherwise create it from individual fields
      const business_fields = inputData.business_fields || {
        business_name: inputData.business_name,
        phone_number: inputData.phone_number,
        email: inputData.email,
        website: inputData.website,
        ein: inputData.ein,
        business_registered_address: inputData.address,
      };

      console.log(
        "[PersonaProvider] Formatted business_fields:",
        business_fields
      );

      return {
        reportType,
        carrier_id: entityId,
        business_fields,
        company_id: company_id,
      };
    }

    return { reportType, ...reportData };
  }

  _transformReportResponse(responseData) {
    if (!responseData) return null;

    // Transform based on success/failure
    if (!responseData.success) {
      return { error: responseData.error };
    }

    // For transaction data
    if (responseData.data?.type === "transaction") {
      return {
        transaction: responseData.data,
        referenceId: responseData.referenceId,
        success: true,
      };
    }

    // For report data
    return {
      report: responseData.data,
      referenceId: responseData.referenceId,
      success: true,
    };
  }
}

// Create a singleton instance
const personaProvider = new PersonaProvider();

// Export both the class, the singleton instance, and the templates
module.exports = PersonaProvider;
module.exports.personaProvider = personaProvider;
module.exports.INQUIRY_TEMPLATES = INQUIRY_TEMPLATES;
