// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useCustomerCompanyId } from "../../hooks/useCustomers";
import { useGetUserSession } from "../../hooks/users";
import { createInsuranceDocumentationVerification } from "../../api/services/verification/VerificationService";
import { useParams } from "react-router-dom";

// Import shadcn components
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Alert, AlertTitle, AlertDescription } from "../../components/ui/alert";
import { Separator } from "../../components/ui/separator";
import { ShieldCheck, Loader2, CheckCircle, AlertCircle } from "lucide-react";

// Validation schema
const insuranceDocumentationSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email address" }),
  phoneNumber: z.string().min(10, { message: "Phone number should be at least 10 digits" }),
  carrier_id: z.string().min(1, { message: "Carrier ID is required" }),
  policy_number: z.string().optional(),
});

const VetInsuranceDocumentation = () => {
  const { session, loading } = useGetUserSession();
  const { data: companyId } = useCustomerCompanyId(session?.user?.id);
  const urlParams = new URLSearchParams(window.location.search);
  const urlCarrierId = urlParams.get("carrier_id");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  // Initialize form with zod resolver
  const form = useForm({
    resolver: zodResolver(insuranceDocumentationSchema),
    defaultValues: {
      email: session?.user?.email || "",
      phoneNumber: "",
      carrier_id: urlCarrierId || "",
      policy_number: "",
    },
  });

  useEffect(() => {
    if (companyId && urlCarrierId) {
      form.setValue("carrier_id", urlCarrierId);
    }
    if (session?.user?.email) {
      form.setValue("email", session.user.email);
    }
  }, [urlCarrierId, companyId, session, form]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[300px]">
        <Loader2 className="w-8 h-8 animate-spin text-slate-700" />
      </div>
    );
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setStatusMessage("");
    setIsSuccess(false);

    try {
      // Call the createInsuranceDocumentationVerification function
      const result = await createInsuranceDocumentationVerification({
        email: data.email,
        phoneNumber: data.phoneNumber.replace(/\D/g, ""),
        carrier_id: data.carrier_id,
        policy_number: data.policy_number || null,
        companyId: companyId,
      });

      setIsSuccess(true);
      setStatusMessage(`Insurance verification initiated successfully.`);
    } catch (error) {
      setIsSuccess(false);
      setStatusMessage(
        "An error occurred during insurance verification. Please try again."
      );
      console.error("Error:", error);
      form.setError("root", { 
        type: "manual", 
        message: error.message || "Failed to initiate verification" 
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto p-4 sm:p-6 lg:p-8">
      <Card className="w-full">
        <CardHeader className="space-y-1">
          <div className="flex items-center gap-4">
            <div className="bg-slate-100 p-2 rounded-full">
              <ShieldCheck className="h-6 w-6 text-slate-700" />
            </div>
            <div>
              <CardTitle>Insurance Documentation Verification</CardTitle>
              <CardDescription>
                Enter insurance information for verification.
              </CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <div className="space-y-4">
                <h3 className="text-lg font-medium">Contact Information</h3>
                <Separator />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email *</FormLabel>
                        <FormControl>
                          <Input
                            type="email"
                            placeholder="contact@example.com"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Phone Number *</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="123-456-7890"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="text-lg font-medium">Insurance Information</h3>
                <Separator />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="carrier_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Carrier ID *</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Enter Carrier ID"
                            {...field}
                            disabled={!!urlCarrierId}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="policy_number"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Policy Number</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Optional policy number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              {statusMessage && (
                <Alert className={isSuccess ? "bg-green-50 border-green-200" : "bg-red-50 border-red-200"}>
                  {isSuccess ? (
                    <CheckCircle className="h-4 w-4 text-green-500" />
                  ) : (
                    <AlertCircle className="h-4 w-4 text-red-500" />
                  )}
                  <AlertTitle className={isSuccess ? "text-green-700" : "text-red-700"}>
                    {isSuccess ? "Success" : "Error"}
                  </AlertTitle>
                  <AlertDescription className={isSuccess ? "text-green-600" : "text-red-600"}>
                    {statusMessage}
                  </AlertDescription>
                </Alert>
              )}

              {form.formState.errors.root && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{form.formState.errors.root.message}</AlertDescription>
                </Alert>
              )}

              <Button
                type="submit"
                className="w-full"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Processing...
                  </>
                ) : (
                  "Start Insurance Verification"
                )}
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

export default VetInsuranceDocumentation;
