import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import dockRepository from "../api/repositories/DockRepository";

/**
 * Fetch docks from the repository with optional filters
 */
async function fetchDocks(companyId, filters = {}) {
  try {
    return await dockRepository.findByCompanyId(companyId, filters);
  } catch (error) {
    console.error("Failed to fetch docks:", error);
    throw error;
  }
}

/**
 * Update dock status using the repository
 */
async function updateDockStatus(dockId, status) {
  try {
    return await dockRepository.updateStatus(dockId, status);
  } catch (error) {
    console.error("Failed to update dock status:", error);
    throw error;
  }
}

/**
 * Hook to fetch docks with polling for real-time feel
 */
export function useFetchDocks(companyId, filters, options = {}) {
  return useQuery({
    queryKey: ["docks", companyId, filters],
    queryFn: () => fetchDocks(companyId, filters),
    enabled: !!companyId, // Only run query if companyId is available
    refetchInterval: options.enablePolling ? 15000 : false, // Refetch every 15 seconds if polling enabled
    staleTime: options.enablePolling ? 5000 : 60000, // Data considered fresh for longer if not polling
    ...options, // Allow overriding defaults
  });
}

/**
 * Hook for mutation to update dock status
 */
export function useUpdateDockStatus() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ dockId, status }) => updateDockStatus(dockId, status),
    onSuccess: (updatedDock) => {
      // Invalidate and refetch docks query to update the list
      queryClient.invalidateQueries({
        queryKey: ["docks"],
      });

      // Show success message
      console.log(
        `Dock ${updatedDock.id} status updated to ${updatedDock.status}`
      );
    },
    onError: (error) => {
      console.error("Error updating dock status:", error);
      // TODO: Add user feedback (e.g., toast notification)
    },
  });
}

/**
 * Hook to create a new dock
 */
export function useCreateDock() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dockData) => dockRepository.create(dockData),
    onSuccess: (newDock) => {
      queryClient.invalidateQueries({
        queryKey: ["docks"],
      });
      console.log(`New dock created: ${newDock.name}`);
    },
    onError: (error) => {
      console.error("Error creating dock:", error);
    },
  });
}

/**
 * Hook to delete a dock
 */
export function useDeleteDock() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dockId) => dockRepository.delete(dockId),
    onSuccess: (deleted, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["docks"],
      });
      console.log(`Dock ${variables} deleted successfully`);
    },
    onError: (error) => {
      console.error("Error deleting dock:", error);
    },
  });
}

/**
 * Hook to update dock details
 */
export function useUpdateDock() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ dockId, updateData }) =>
      dockRepository.update(dockId, updateData),
    onSuccess: (updatedDock) => {
      queryClient.invalidateQueries({
        queryKey: ["docks"],
      });
      console.log(`Dock ${updatedDock.id} updated successfully`);
    },
    onError: (error) => {
      console.error("Error updating dock:", error);
    },
  });
}

// TODO: Add additional hooks as needed, e.g.:
// export function useAssignShipmentToDock() { ... }
