import React from "react";
import AttributeGroup from "../AttributeGroup";
import { formatAttributeName } from "../utils";

/**
 * Default report renderer for any report type not specifically handled
 * @param {Object} props
 * @param {Object} props.attributes - The report attributes
 * @param {string} props.reportType - The report type
 */
const DefaultReport = ({ attributes, reportType }) => {
  if (!attributes) {
    return <div className="text-gray-500">No attributes found</div>;
  }

  // Get basic attributes that aren't objects
  const basicAttributes = Object.keys(attributes).filter(
    (key) =>
      typeof attributes[key] !== "object" && key !== "id" && key !== "type"
  );

  // Get nested object attributes
  const nestedAttributes = Object.keys(attributes).filter(
    (key) =>
      typeof attributes[key] === "object" &&
      attributes[key] !== null &&
      key !== "data"
  );

  return (
    <div className="mt-4 space-y-6">
      {/* General Report Information */}
      <div className="mb-4">
        <h3 className="text-md font-medium text-slate-700 mb-2">
          Report Information
        </h3>
        <div className="bg-slate-50 p-4 rounded-lg border border-slate-200">
          <div className="grid md:grid-cols-2 gap-4">
            {/* Report Type */}
            <div>
              <span className="text-xs text-gray-500">Report Type</span>
              <p className="font-medium">{formatAttributeName(reportType)}</p>
            </div>

            {/* Render all other basic attributes */}
            {basicAttributes.length > 0 && (
              <AttributeGroup attributes={attributes} keys={basicAttributes} />
            )}
          </div>
        </div>
      </div>

      {/* Render any nested objects as separate sections */}
      {nestedAttributes.map((key) => (
        <div key={key} className="mb-4">
          <h3 className="text-md font-medium text-slate-700 mb-2">
            {formatAttributeName(key)}
          </h3>
          <div className="bg-slate-50 p-4 rounded-lg border border-slate-200">
            {typeof attributes[key] === "object" && attributes[key] !== null ? (
              Array.isArray(attributes[key]) ? (
                <div className="space-y-3">
                  {attributes[key].map((item, index) => (
                    <div key={index} className="bg-white p-3 rounded border">
                      {typeof item === "object" ? (
                        <AttributeGroup
                          attributes={item}
                          keys={Object.keys(item)}
                        />
                      ) : (
                        <p>{String(item)}</p>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <AttributeGroup
                  attributes={attributes[key]}
                  keys={Object.keys(attributes[key])}
                />
              )
            ) : (
              <p className="text-slate-700">{String(attributes[key])}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DefaultReport;
