/**
 * Service for carrier vetting processes
 */
import { carrierDataService } from "../../services/carrier/CarrierDataService";
import { CarrierService } from "../../services/carrier/CarrierService";
import * as VerificationService from "../../services/verification/VerificationService";
import { mapPersonaInquiryStatus } from "../../utils/PersonaStatusUtils";
import { validateDOT } from "../../../lib/utils";

/**
 * @typedef {Object} VettingOptions
 * @property {function} onLoadingStatusChange - Callback for loading status updates
 */

export const CarrierVettingService = {
  /**
   * Validate a DOT number
   * @param {string} dotNumber - DOT number to validate
   * @returns {boolean} - Whether the DOT number is valid
   */
  validateDotNumber(dotNumber) {
    return validateDOT(dotNumber);
  },

  /**
   * Fetch carrier data by DOT number
   * @param {string} dotNumber - DOT number
   * @param {function} onStatusChange - Status change callback
   * @returns {Promise<Object>} - Carrier data
   */
  async fetchCarrierData(dotNumber, onStatusChange) {
    return carrierDataService.fetchCarrierData(dotNumber, onStatusChange);
  },

  /**
   * Normalize persona input data for carrier
   * @param {Object} formData - Form data
   * @param {Object} fmcsaData - FMCSA data
   * @returns {Object} - Normalized persona input
   */
  normalizePersonaInput(formData, fmcsaData) {
    return {
      business_fields: {
        ...(formData.business_fields || {}),
        business_name:
          formData.business_fields?.business_name || formData.business_name,
        email: formData.business_fields?.email || formData.email,
        phone_number:
          formData.business_fields?.phone_number ||
          formData.phone_number ||
          formData.phoneNumber,
      },
      carrierData: fmcsaData,
      business_name:
        formData.business_fields?.business_name || formData.business_name,
      email: formData.business_fields?.email || formData.email,
      phone_number:
        formData.business_fields?.phone_number ||
        formData.phone_number ||
        formData.phoneNumber,
      carrier_id: formData.carrier_id,
      company_id: formData.company_id,
    };
  },

  /**
   * Create verification records for Persona report results
   * @param {Object} reportResults - Report results
   * @param {Object} personaInput - Persona input data
   * @param {Object} carrier - Carrier data
   * @returns {Promise<Array>} - Created verification records
   */
  async createVerificationRecords(reportResults, personaInput, carrier) {
    const createdVerifications = [];

    try {
      // Phone verification record
      if (reportResults.results?.phone_risk_report) {
        const phoneVerificationData = {
          phone_number:
            personaInput.phone_number ||
            personaInput.business_fields?.phone_number,
          persona_inquiry_id:
            reportResults.results.phone_risk_report.id ||
            reportResults.results.phone_risk_report.referenceId,
          persona_verification_complete:
            !!reportResults.results.phone_risk_report.success,
          status: reportResults.results.phone_risk_report.success
            ? mapPersonaInquiryStatus("completed")
            : mapPersonaInquiryStatus("pending"),
          verification_type: "phone_verification",
          entity_type: "carrier",
          entity_id: carrier.id,
          metadata: {
            status: reportResults.results.phone_risk_report.success
              ? "completed"
              : "pending",
            started_at: new Date().toISOString(),
            completed_at: reportResults.results.phone_risk_report.success
              ? new Date().toISOString()
              : null,
            phone_number:
              personaInput.phone_number ||
              personaInput.business_fields?.phone_number,
            success: !!reportResults.results.phone_risk_report.success,
            referenceId: reportResults.results.phone_risk_report.referenceId,
            report_id: reportResults.results.phone_risk_report.id,
          },
        };

        const phoneVerification = await VerificationService.createVerification(
          phoneVerificationData
        );

        if (!phoneVerification.error) {
          createdVerifications.push(phoneVerification.data);
        }
      }

      // Email verification record
      if (reportResults.results?.email_risk_report) {
        const emailVerificationData = {
          email: personaInput.email || personaInput.business_fields?.email,
          persona_inquiry_id:
            reportResults.results.email_risk_report.id ||
            reportResults.results.email_risk_report.referenceId,
          persona_verification_complete:
            !!reportResults.results.email_risk_report.success,
          status: reportResults.results.email_risk_report.success
            ? mapPersonaInquiryStatus("completed")
            : mapPersonaInquiryStatus("pending"),
          verification_type: "email_verification",
          entity_type: "carrier",
          entity_id: carrier.id,
          metadata: {
            status: reportResults.results.email_risk_report.success
              ? "completed"
              : "pending",
            started_at: new Date().toISOString(),
            completed_at: reportResults.results.email_risk_report.success
              ? new Date().toISOString()
              : null,
            email: personaInput.email || personaInput.business_fields?.email,
            success: !!reportResults.results.email_risk_report.success,
            referenceId: reportResults.results.email_risk_report.referenceId,
            report_id: reportResults.results.email_risk_report.id,
          },
        };

        const emailVerification = await VerificationService.createVerification(
          emailVerificationData
        );

        if (!emailVerification.error) {
          createdVerifications.push(emailVerification.data);
        }
      }

      // Business verification report record
      if (reportResults.results?.business_verification_report) {
        const businessReportData = {
          business_name:
            personaInput.business_name ||
            personaInput.business_fields?.business_name,
          persona_inquiry_id:
            reportResults.results.business_verification_report.id ||
            reportResults.results.business_verification_report.referenceId,
          persona_verification_complete:
            !!reportResults.results.business_verification_report.success,
          status: reportResults.results.business_verification_report.success
            ? mapPersonaInquiryStatus("completed")
            : mapPersonaInquiryStatus("pending"),
          verification_type: "business_verification_report",
          entity_type: "carrier",
          entity_id: carrier.id,
          metadata: {
            status: reportResults.results.business_verification_report.success
              ? "completed"
              : "pending",
            started_at: new Date().toISOString(),
            completed_at: reportResults.results.business_verification_report
              .success
              ? new Date().toISOString()
              : null,
            business_name:
              personaInput.business_name ||
              personaInput.business_fields?.business_name,
            success:
              !!reportResults.results.business_verification_report.success,
            referenceId:
              reportResults.results.business_verification_report.referenceId,
            report_id: reportResults.results.business_verification_report.id,
          },
        };

        const businessReportVerification =
          await VerificationService.createVerification(businessReportData);

        if (!businessReportVerification.error) {
          createdVerifications.push(businessReportVerification.data);
        }
      }

      // Business verification transaction record
      if (reportResults.results?.business_verification_transaction) {
        const businessTransactionData = {
          business_name:
            personaInput.business_name ||
            personaInput.business_fields?.business_name,
          persona_inquiry_id:
            reportResults.results.business_verification_transaction.id ||
            reportResults.results.business_verification_transaction.referenceId,
          persona_verification_complete:
            !!reportResults.results.business_verification_transaction.result
              ?.success,
          status: reportResults.results.business_verification_transaction.result
            ?.success
            ? mapPersonaInquiryStatus("completed")
            : mapPersonaInquiryStatus("pending"),
          verification_type: "business_verification_transaction",
          entity_type: "carrier",
          entity_id: carrier.id,
          metadata: {
            status: reportResults.results.business_verification_transaction
              .result?.success
              ? "completed"
              : "pending",
            started_at: new Date().toISOString(),
            completed_at: reportResults.results
              .business_verification_transaction.result?.success
              ? new Date().toISOString()
              : null,
            business_name:
              personaInput.business_name ||
              personaInput.business_fields?.business_name,
            success:
              !!reportResults.results.business_verification_transaction.result
                ?.success,
            referenceId:
              reportResults.results.business_verification_transaction
                .referenceId,
            transaction_id:
              reportResults.results.business_verification_transaction.id,
            transaction_attributes:
              reportResults.results.business_verification_transaction.result
                ?.attributes || {},
          },
        };

        const businessTransactionVerification =
          await VerificationService.createVerification(businessTransactionData);

        if (!businessTransactionVerification.error) {
          createdVerifications.push(businessTransactionVerification.data);
        }
      }

      return createdVerifications;
    } catch (error) {
      console.error(
        "[CarrierVettingService] Error creating verification records:",
        error
      );
      return createdVerifications; // Return any records we've managed to create
    }
  },

  /**
   * Determine overall verification status from report results
   * @param {Object} reportResults - Persona report results
   * @returns {Object} - Verification status info
   */
  determineVerificationStatus(reportResults) {
    const phoneSuccess = reportResults.results?.phone_risk_report?.success;
    const emailSuccess = reportResults.results?.email_risk_report?.success;
    const businessReportSuccess =
      reportResults.results?.business_verification_report?.success;
    const businessTransactionSuccess =
      reportResults.results?.business_verification_transaction?.result?.success;

    // Overall verification is complete if all present reports were successful
    const verificationComplete =
      (phoneSuccess || !reportResults.results?.phone_risk_report) &&
      (emailSuccess || !reportResults.results?.email_risk_report) &&
      (businessReportSuccess ||
        !reportResults.results?.business_verification_report) &&
      (businessTransactionSuccess ||
        !reportResults.results?.business_verification_transaction);

    return {
      verificationComplete,
      phone_verification: phoneSuccess,
      email_verification: emailSuccess,
      business_verification:
        businessReportSuccess || businessTransactionSuccess,
    };
  },

  /**
   * Update carrier verification status
   * @param {string} carrierId - Carrier ID
   * @param {Object} verificationStatus - Verification status details
   * @param {Array} createdVerifications - Created verification records
   * @returns {Promise<Object>} - Updated carrier
   */
  async updateCarrierVerificationStatus(
    carrierId,
    verificationStatus,
    createdVerifications // This parameter is no longer used here but kept for signature consistency if needed elsewhere
  ) {
    const carrierUpdateData = {
      is_vetted: verificationStatus.verificationComplete ? true : false,
      vetted_at: new Date().toISOString(),
    };

    console.log(
      `[CarrierVettingService] Updating carrier ${carrierId} vetting status:`,
      carrierUpdateData
    );
    // Use the general updateCarrier method now
    return CarrierService.updateCarrier({
      id: carrierId,
      updatedFields: carrierUpdateData,
    });
  },

  /**
   * Complete carrier vetting process
   * @param {Object} formData - Form data with DOT number
   * @param {VettingOptions} options - Vetting options
   * @returns {Promise<Object>} - Vetting result
   */
  async vetCarrier(formData, options = {}) {
    const { onLoadingStatusChange } = options;

    // Helper function to update status
    const updateStatus = (operation) => {
      if (onLoadingStatusChange) {
        onLoadingStatusChange({ currentOperation: operation });
      }
    };

    if (!formData.dot_number) {
      throw new Error("DOT number is required");
    }

    const dotNumber = formData.dot_number.toString();

    if (!this.validateDotNumber(dotNumber)) {
      throw new Error("Invalid DOT number");
    }

    // Fetch carrier data with progress callback
    const carrierResult = await this.fetchCarrierData(
      dotNumber,
      onLoadingStatusChange
    );

    const { carrier, fmcsaData } = carrierResult;

    if (!carrier) {
      throw new Error("No carrier found for this DOT number");
    }

    // --- Create FMCSA Verification Record ---
    updateStatus("Creating FMCSA verification record");
    try {
      const fmcsaVerificationData = {
        carrier_id: carrier.id, // Map directly to schema column
        verification_type: "fmcsa_lookup", // Changed type for clarity
        state: "completed", // FMCSA lookup is synchronous
        results: fmcsaData, // Store the fetched FMCSA data
        reference_id: dotNumber,
        // created_at and updated_at are handled by DB trigger
      };
      console.log(
        `[CarrierVettingService] Creating FMCSA verification record for carrier ${carrier.id}`,
        fmcsaVerificationData
      );
      const fmcsaVerificationResult =
        await VerificationService.createVerification(fmcsaVerificationData);
      if (fmcsaVerificationResult.error) {
        console.error(
          `[CarrierVettingService] Error creating FMCSA verification record: ${fmcsaVerificationResult.error.message}`
        );
        // Non-fatal error, log and continue
      }
    } catch (fmcsaError) {
      console.error(
        `[CarrierVettingService] Exception during FMCSA verification record creation: ${fmcsaError.message}`
      );
      // Non-fatal error, log and continue
    }
    // --- End FMCSA Verification Record ---

    // Normalize persona input data
    updateStatus("Preparing fraud check data");
    const personaInput = this.normalizePersonaInput(formData, fmcsaData);

    // Run persona reports through the VerificationService
    updateStatus("Running fraud checks");
    const personaResults = await VerificationService.runCarrierReports(
      personaInput,
      carrier.id
    );

    // Create verification records
    updateStatus("Saving verification records");
    const createdVerifications = await this.createVerificationRecords(
      personaResults,
      personaInput,
      carrier
    );

    // Determine verification status
    updateStatus("Analyzing verification results");
    const verificationStatus = this.determineVerificationStatus(personaResults);

    // Update carrier record with verification status
    updateStatus("Updating carrier verification status");
    await this.updateCarrierVerificationStatus(
      carrier.id,
      verificationStatus,
      createdVerifications
    );

    updateStatus("Verification complete");
    return {
      success: true,
      verified: verificationStatus.verificationComplete,
      carrierData: carrier,
      personaResults,
      verifications: createdVerifications,
    };
  },

  /**
   * Get carrier verification status
   * @param {string} carrierId - Carrier ID
   * @returns {Promise<Object>} - Verification status
   */
  async getCarrierVerificationStatus(carrierId) {
    if (!carrierId) {
      return { success: false, error: "Carrier ID is required" };
    }

    try {
      const verificationStatus =
        await VerificationService.getCarrierVerificationStatus(carrierId);

      return {
        success: true,
        status: verificationStatus,
      };
    } catch (error) {
      console.error(
        "[CarrierVettingService] Error getting verification status:",
        error
      );
      return { success: false, error: error.message };
    }
  },
};
