// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import image_v2 from "../../../public/static/screenshots/new_hero.jpg";
import { useSpring, animated, config } from "@react-spring/web";
import { motion } from "framer-motion";
import { Button } from "../../Components/Button";
import IWLA from "../../../public/static/logo/IWLA.png";
import hda from "../../../public/static/logo/hda.png";
import tapa from "../../../public/static/logo/tapa.png";

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 },
};

const Hero = () => {
  // Add ref to track the element
  const heroRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [hoveredFeature, setHoveredFeature] = useState(null);

  // Initialize a spring animation with useSpring hook
  const [springs, api] = useSpring(() => ({
    scale: 0.7,
    config: {
      tension: 280,
      friction: 60,
    },
  }));

  useEffect(() => {
    // Set visible after component mounts for animations
    const timer = setTimeout(() => setIsVisible(true), 300);

    const handleScroll = () => {
      // Skip if ref isn't attached
      if (!heroRef.current) return;

      // Get the element's position and dimensions
      const element = heroRef.current;
      const rect = element.getBoundingClientRect();

      // Get viewport height
      const windowHeight = window.innerHeight;

      // Calculate how far the element is from the viewport top
      const elementTop = rect.top;
      const elementHeight = rect.height;

      // Calculate progress based on element position
      // Progress will be 0 when element enters viewport and 1 when it exits
      const progress = Math.min(
        Math.max(
          1 - (elementTop + elementHeight) / (windowHeight + elementHeight),
          0
        ),
        1
      );

      // Rest of the scale calculation remains the same
      const scale =
        progress < 0.7
          ? 0.6 + (progress / 0.7) * 0.4 // Scale up from 0.6 to 1.0 until 70%
          : progress > 0.95
          ? 1.0 - ((progress - 0.95) / 0.05) * 0.4 // Scale down from 1.0 to 0.6 in last 5%
          : 1.0; // Hold at 1.0 between 70% and 95%

      api.start({ scale });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timer);
    };
  }, [api]);

  // Dashboard highlights that appear on hover
  const dashboardFeatures = [
    { id: 1, label: "Real-time Fraud Detection", x: "25%", y: "30%" },
    { id: 2, label: "Security Score Analysis", x: "75%", y: "45%" },
    { id: 3, label: "One-Click Verification", x: "60%", y: "65%" },
  ];

  return (
    <div ref={heroRef} className="bg-gray-900 relative z-0 overflow-hidden">
      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -inset-[10%] opacity-30">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute bg-[#119DA5]/10 rounded-full blur-xl"
              style={{
                width: `${Math.random() * 50 + 10}px`,
                height: `${Math.random() * 50 + 10}px`,
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDuration: `${Math.random() * 50 + 20}s`,
                animationDelay: `${Math.random() * 5}s`,
              }}
            />
          ))}
        </div>
      </div>

      <div className="relative">
        {/* Top gradient decoration */}
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80 pointer-events-none"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>

        {/* Main content */}
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
            {/* Left column */}
            <div className="lg:col-span-7 flex flex-col justify-center">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                  className="text-center lg:text-left"
                  initial={fadeIn.initial}
                  animate={fadeIn.animate}
                  transition={fadeIn.transition}
                >
                  <div className="inline-block px-3 py-1 rounded-full bg-[#119DA5]/10 text-[#119DA5] text-sm font-medium mb-6 border border-[#119DA5]/20">
                    <div className="flex items-center">
                      <span className="w-2 h-2 rounded-full bg-[#119DA5] mr-2 animate-pulse"></span>
                      NEW: AI-Powered Carrier Verification Technology
                    </div>
                  </div>

                  <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
                    <span className="text-[#119DA5]">
                      Turn Cargo Theft Losses into Profits
                    </span>
                    <br />
                    <span className="relative">
                      <span className="relative z-10">
                        with an end-to-end prevention system
                      </span>
                      <span className="absolute bottom-1 left-0 w-full h-3 bg-[#119DA5]/20 -z-0 transform -rotate-1 hidden md:block"></span>
                    </span>
                  </h1>
                  <p className="text-xl text-gray-300 mb-4 max-w-2xl mx-auto lg:mx-0">
                    Indemni’s proactive fraud detection secures every shipment,
                    saving you millions in potential losses and preserving your
                    brand’s reputation.
                  </p>

                  {/* Trust indicators and social proof */}
                  <div className="flex items-center mb-8 text-gray-400">
                    <div className="flex -space-x-2 mr-3">
                      {/* Representative company logos or user avatars */}
                      <div className="w-8 h-8 rounded-full bg-blue-600 border-2 border-gray-900 flex items-center justify-center text-xs text-white font-bold">
                        LL
                      </div>
                      <div className="w-8 h-8 rounded-full bg-green-600 border-2 border-gray-900 flex items-center justify-center text-xs text-white font-bold">
                        LD
                      </div>
                      <div className="w-8 h-8 rounded-full bg-purple-600 border-2 border-gray-900 flex items-center justify-center text-xs text-white font-bold">
                        CFS
                      </div>
                      <div className="w-8 h-8 rounded-full bg-yellow-600 border-2 border-gray-900 flex items-center justify-center text-xs text-white font-bold">
                        +
                      </div>
                    </div>
                    <span className="text-sm">
                      Trusted by logistics companies nationwide
                    </span>
                  </div>

                  <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                    <Button
                      className="w-full sm:w-auto bg-[#119DA5] text-white px-8 py-4 text-lg rounded-lg hover:bg-[#0E8A91] transition-colors font-medium shadow-md flex items-center justify-center"
                      onClick={() =>
                        (window.location.href =
                          "https://calendly.com/meet-indemni/30min")
                      }
                    >
                      <span>Secure Your Cargo Now</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 ml-2"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Button>
                    <Button
                      className="w-full sm:w-auto bg-gray-800 text-white px-8 py-4 text-lg rounded-lg border border-gray-700 hover:bg-gray-700 transition-colors group relative overflow-hidden"
                      onClick={() => (window.location.href = "/vetting")}
                    >
                      <span className="relative z-10 flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 mr-2 text-[#119DA5]"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>
                          Verify a Carrier for{" "}
                          <span className="text-[#119DA5]">Free</span>
                        </span>
                      </span>
                      <span className="absolute inset-0 bg-gradient-to-r from-[#119DA5]/0 via-[#119DA5]/10 to-[#119DA5]/0 transform translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000"></span>
                    </Button>
                  </div>

                  {/* Trust badges/certifications */}
                  <div className="mt-8 pt-6 border-t border-gray-800">
                    <p className="text-xs text-gray-500 mb-2">
                      RECOGNIZED BY INDUSTRY ASSOCIATIONS
                    </p>
                    <div className="flex flex-wrap items-center gap-6">
                      <div className="opacity-70 hover:opacity-100 transition-opacity">
                        <img 
                          src={tapa} 
                          alt="TAPA" 
                          className="h-8 w-auto object-contain grayscale hover:grayscale-0 transition-all duration-300" 
                        />
                      </div>
                      <div className="opacity-70 hover:opacity-100 transition-opacity">
                        <img 
                          src={hda} 
                          alt="HDA" 
                          className="h-8 w-auto object-contain grayscale hover:grayscale-0 transition-all duration-300" 
                        />
                      </div>
                      <div className="opacity-70 hover:opacity-100 transition-opacity">
                        <img 
                          src={IWLA} 
                          alt="IWLA" 
                          className="h-8 w-auto object-contain grayscale hover:grayscale-0 transition-all duration-300" 
                        />
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>

            {/* Right column */}
            <div className="mt-16 sm:mt-24 lg:col-span-5 lg:mt-0 lg:h-full">
              <div className="relative h-full">
                {/* Security grid pattern background */}
                <div className="absolute -inset-x-8 -inset-y-8 z-0">
                  <div
                    className="absolute inset-0 bg-gradient-to-r from-[#143560]/5 to-[#119DA5]/5"
                    style={{
                      backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 1L1 3.5V6c0 2.5 2 4.5 5 5.5 3-1 5-3 5-5.5V3.5L6 1z' stroke='%23119DA5' stroke-opacity='0.1' fill='none'/%3E%3C/svg%3E")`,
                      backgroundSize: "24px 24px",
                    }}
                  />
                </div>

                {/* Floating elements */}
                <div className="absolute -left-4 top-1/4 w-8 h-8 bg-[#119DA5]/10 rounded-full blur-xl animate-pulse" />
                <div className="absolute -right-4 top-3/4 w-12 h-12 bg-[#143560]/10 rounded-full blur-xl animate-pulse delay-1000" />

                {/* Main image container */}
                <div className="relative h-full">
                  {/* Glass card effect */}
                  <div className="absolute inset-0 bg-gradient-to-br from-white/10 to-white/5 rounded-2xl backdrop-blur-xl" />

                  {/* Border glow */}
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-[#119DA5]/30 to-[#143560]/30 rounded-2xl blur-2xl opacity-75" />

                  {/* Main content wrapper */}
                  <div className="relative h-full overflow-hidden rounded-xl border border-white/10 bg-gray-900/90 shadow-[0_0_15px_rgba(17,157,165,0.5)] backdrop-blur-3xl">
                    {/* Animated gradient overlay */}
                    <div className="absolute inset-0 bg-gradient-to-tr from-[#143560]/10 via-transparent to-[#119DA5]/10 animate-gradient-shift" />

                    {/* Scanner line effect */}
                    <div className="absolute inset-0 overflow-hidden">
                      <div className="absolute inset-0 translate-y-full animate-scanner bg-gradient-to-b from-transparent via-[#119DA5]/20 to-transparent" />
                    </div>

                    {/* Image with interactive feature highlights */}
                    <div className="relative z-10 h-full">
                      <animated.img
                        alt="Shipment dashboard interface showing cargo tracking and security features"
                        src={image_v2}
                        width={2432}
                        height={1542}
                        className="relative z-10 h-full w-[100%] max-w-none object-cover object-left transition-all duration-700 hover:scale-105"
                      />

                      {/* Interactive feature highlight points */}
                      {dashboardFeatures.map((feature) => (
                        <div
                          key={feature.id}
                          className="absolute z-20 cursor-pointer transform -translate-x-1/2 -translate-y-1/2"
                          style={{ left: feature.x, top: feature.y }}
                          onMouseEnter={() => setHoveredFeature(feature.id)}
                          onMouseLeave={() => setHoveredFeature(null)}
                        >
                          <div
                            className={`relative flex items-center justify-center w-6 h-6 rounded-full ${
                              hoveredFeature === feature.id
                                ? "bg-[#119DA5]"
                                : "bg-[#119DA5]/50"
                            } transition-all duration-300`}
                          >
                            <div className="absolute w-full h-full rounded-full bg-[#119DA5]/30 animate-ping" />
                            <div className="w-2 h-2 rounded-full bg-white" />
                          </div>

                          {/* Feature tooltip */}
                          {hoveredFeature === feature.id && (
                            <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-gray-800 text-white text-xs px-3 py-2 rounded shadow-lg whitespace-nowrap">
                              {feature.label}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Bottom decorative elements */}
                <div className="absolute -bottom-4 left-[10%] right-[10%] h-px bg-gradient-to-r from-transparent via-[#119DA5] to-transparent opacity-50 blur-sm" />
                <div className="absolute -bottom-6 left-[20%] right-[20%] h-px bg-gradient-to-r from-transparent via-[#119DA5] to-transparent opacity-30 blur-sm" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add a floating "Schedule Demo" button that appears on scroll */}
      <motion.div
        className="fixed bottom-6 right-6 z-50"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
        transition={{ delay: 3 }}
      >
        <button
          onClick={() =>
            (window.location.href = "https://calendly.com/meet-indemni/30min")
          }
          className="bg-[#119DA5] text-white rounded-full p-4 shadow-lg hover:bg-[#0E8A91] transition-colors group"
        >
          <div className="absolute -inset-1 bg-[#119DA5]/30 rounded-full blur-sm opacity-75 group-hover:opacity-100 transition-opacity"></div>
          <div className="relative flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </button>
      </motion.div>
    </div>
  );
};

export default Hero;
