import React from "react";
import RiskLookup from "./RiskLookup";

const RiskLookupForm = () => {
  return (
    <div className="min-h-screen bg-white flex flex-row gap-10 p-10">
      <RiskLookup />
    </div>
  );
};

export default RiskLookupForm;
