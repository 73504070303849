import { supabase } from "../../lib/supabase";

/**
 * Repository for managing ID verification details
 */
class PersonaIDRepository {
  constructor() {
    this.supabase = supabase;
  }

  // Create a new ID verification detail record
  async create(params) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .insert(params)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  // Get an ID verification detail by id
  async findById(id) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .eq("id", id)
      .single();

    if (error) throw error;
    return data;
  }

  // Find ID verification details by verification id
  async findByVerificationId(verificationId) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .eq("verification_id", verificationId)
      .single();

    if (error && error.code !== "PGRST116") throw error;
    return data;
  }

  // Find ID verification details by Persona inquiry ID
  async findByPersonaInquiryId(inquiryId) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .eq("persona_inquiry_id", inquiryId)
      .single();

    if (error && error.code !== "PGRST116") throw error;
    return data;
  }

  // Find ID verification details by subject type
  async findBySubjectType(subjectType) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .eq("subject_type", subjectType)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Find ID verification details by verification method
  async findByVerificationMethod(method) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .eq("verification_method", method)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Find ID verification details by persona verification status
  async findByPersonaVerificationStatus(isComplete) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .eq("persona_verification_complete", isComplete)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Find ID verification details by OCR verification status
  async findByOCRVerificationStatus(isComplete) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .eq("ocr_verification_complete", isComplete)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Find ID verification details by phone number
  async findByPhoneNumber(phoneNumber) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .eq("phone_number", phoneNumber)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Find ID verification details by verified name
  async findByVerifiedName(name) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .ilike("verified_name", `%${name}%`)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Find by token
  async findByToken(token) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .eq("token", token)
      .single();

    if (error && error.code !== "PGRST116") throw error;
    return data;
  }

  // Update an ID verification detail
  async update(id, params) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .update(params)
      .eq("id", id)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  // Update by Persona inquiry ID
  async updateByPersonaInquiryId(inquiryId, params) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .update(params)
      .eq("persona_inquiry_id", inquiryId)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  // Delete an ID verification detail
  async delete(id) {
    const { error } = await this.supabase
      .from("id_verification_details")
      .delete()
      .eq("id", id);

    if (error) throw error;
  }

  // Get all ID verification details
  async getAll() {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Find ID verification details that are fully verified (both Persona and OCR if applicable)
  async findFullyVerified() {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .eq("persona_verification_complete", true)
      .or("ocr_verification_complete.is.null,ocr_verification_complete.eq.true")
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Find verification details for a specific date of birth
  async findByDateOfBirth(dob) {
    const { data, error } = await this.supabase
      .from("id_verification_details")
      .select("*")
      .eq("dob", dob)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }
}

export default PersonaIDRepository;
