// @ts-nocheck
import { supabase } from "../../../lib/supabase";
import { driverSchema } from "../../../schemas/driverSchema";

// Simple logger
const logger = {
  info: (message) => console.log(`INFO: ${message}`),
  error: (message) => console.error(`ERROR: ${message}`),
};

function generatePassword(length = 12) {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let password = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
}

function generateValidEmail() {
  const timestamp = Date.now().toString(); // This provides a unique part
  return `driver_${timestamp}@indemni.com`;
}

export const DriverService = {
  // Improved createDriver function with detailed logging
  // createDriver function where the user is created first, then the driver
  async createDriver(driverData) {
    try {
      // Validate driver data
      const validatedData = driverSchema.partial().parse(driverData);

      // Step 1: Generate password and email
      const generatedPassword = generatePassword();
      const email = generateValidEmail();

      logger.info(`Creating user with email: ${email}`);
      logger.info(`Creating user with password: ${generatedPassword}`);
      logger.info(`Creating user with company_id: ${validatedData.company_id}`);

      logger.info(`Type of email: ${typeof email}`);
      logger.info(`Type of password: ${typeof generatedPassword}`);
      logger.info(`Type of company_id: ${typeof validatedData.company_id}`);

      // Correct validation: check if email or password is missing
      if (!email || !generatedPassword) {
        throw new Error("Email and password are required to create a user.");
      }

      // Step 2: Sign up the user
      const { data: userDataResult, error: userError } =
        await supabase.auth.signUp({
          email: email,
          password: generatedPassword,
          options: {
            data: {
              role: "driver",
              company_id: validatedData.company_id,
            },
          },
        });

      // Log the result or error from user creation
      if (userError) {
        logger.error(`Error creating user: ${userError.message}`);
        throw userError;
      } else {
        logger.info(
          `User created successfully with ID: ${userDataResult.user.id}`
        );
      }

      // Step 3: Insert the driver using the user_id from the previous step
      const driverDataWithUserId = {
        ...validatedData,
        user_id: userDataResult.user.id,
      };

      const { data: driverDataResult, error: driverError } = await supabase
        .from("drivers")
        .insert(driverDataWithUserId)
        .select()
        .single();

      // Log the result or error from driver creation
      if (driverError) {
        logger.error(`Error creating driver: ${driverError.message}`);
        throw driverError;
      } else {
        logger.info(
          `Driver created successfully with ID: ${driverDataResult.id}`
        );
      }

      return {
        message: "User and driver created successfully",
        userId: userDataResult.user.id,
        driverId: driverDataResult.id,
      };
    } catch (error) {
      // Log the full error stack and the message
      logger.error(`Error creating user and driver: ${error.message}`);
      logger.error(`Stack trace: ${error.stack}`);
      throw error;
    }
  },

  async getDrivers(page = 1, limit = 10) {
    try {
      const { data, error, count } = await supabase
        .from("drivers")
        .select("*", { count: "exact" })
        .range((page - 1) * limit, page * limit - 1);

      if (error) throw error;
      return { drivers: data, total: count };
    } catch (error) {
      logger.error(`Error fetching drivers: ${error.message}`);
      throw error;
    }
  },

  async getAllDrivers() {
    try {
      const { data, error, count } = await supabase
        .from("drivers")
        .select("*", { count: "exact" });

      if (error) throw error;
      return { drivers: data, total: count };
    } catch (error) {
      logger.error(`Error fetching drivers: ${error.message}`);
      throw error;
    }
  },

  async getDriver(id) {
    try {
      const { data, error } = await supabase
        .from("drivers")
        .select("*")
        .eq("id", id)
        .single();

      if (error) {
        if (error.code === "PGRST116") {
          return { message: "Driver not found" };
        }
        throw error;
      }
      return data;
    } catch (error) {
      logger.error(`Error fetching driver ${id}: ${error.message}`);
      throw error;
    }
  },

  async updateDriver(id, driverData) {
    try {
      const validatedData = driverSchema.partial().parse(driverData);
      const { data, error } = await supabase
        .from("drivers")
        .update(validatedData)
        .eq("id", id)
        .select()
        .single();

      if (error) {
        if (error.code === "PGRST116") {
          return { message: "Driver not found" };
        }
        throw error;
      }
      logger.info(`Driver updated: ${id}`);
      return { message: "Driver updated successfully", driver: data };
    } catch (error) {
      logger.error(`Error updating driver ${id}: ${error.message}`);
      throw error;
    }
  },

  async deleteDriver(id) {
    try {
      const { error } = await supabase.from("drivers").delete().eq("id", id);

      if (error) {
        if (error.code === "PGRST116") {
          return { message: "Driver not found" };
        }
        throw error;
      }
      logger.info(`Driver deleted: ${id}`);
      return { message: "Driver deleted successfully" };
    } catch (error) {
      logger.error(`Error deleting driver ${id}: ${error.message}`);
      throw error;
    }
  },
};
