import { VerificationRepository } from "../repositories/VerificationRepository";

/**
 * Strategy for handling business verification processes
 * Implements verification workflows specific to business entities
 */
class BusinessVerificationStrategy {
  constructor(dependencies = {}) {
    // Required dependencies
    this.verificationRepository =
      dependencies.verificationRepository || new VerificationRepository();
    this.personaProvider = dependencies.personaProvider;
    this.businessRegistrationRepository =
      dependencies.businessRegistrationRepository;
    this.businessReportRepository = dependencies.businessReportRepository;
    this.documentProvider = dependencies.documentProvider;
  }

  /**
   * Creates a new business verification record
   *
   * @param {Object} data - Data needed for verification
   * @param {string|number} data.businessId - ID of the business being verified
   * @param {string} [data.businessName] - Name of the business
   * @param {string} [data.ein] - Employer Identification Number
   * @param {Object} [data.address] - Business address
   * @param {string} [data.referenceId] - Optional reference ID
   * @returns {Promise<Object>} Created verification record
   */
  async createVerification(data) {
    try {
      if (!data.businessId) {
        throw new Error("Business ID is required for verification");
      }

      // 1. Create base verification record
      const verification = await this.verificationRepository.create({
        entity_type: "business",
        entity_id: data.businessId,
        verification_type: "business_verification",
        status: "pending",
        metadata: {
          business_name: data.businessName,
          ein: data.ein,
        },
      });

      // 2. Create business registration detail record if verification was created
      if (verification) {
        await this.businessRegistrationRepository.create({
          verification_id: verification.id,
          business_name: data.businessName,
          ein: data.ein,
          registered_address: data.address,
          verification_status: "pending",
        });
      }

      return verification;
    } catch (error) {
      console.error("Error creating business verification:", error);
      throw error;
    }
  }

  /**
   * Checks the status of a business verification
   *
   * @param {string} verificationId - ID of the verification to check
   * @returns {Promise<Object>} Current verification status and details
   */
  async checkStatus(verificationId) {
    try {
      // 1. Get the verification record
      const verification = await this.verificationRepository.findById(
        verificationId
      );
      if (!verification) {
        throw new Error(`Verification not found: ${verificationId}`);
      }

      // 2. Check business registration details
      const registrationDetails =
        await this.businessRegistrationRepository.findByVerificationId(
          verificationId
        );

      // 3. Check any associated reports
      const reportDetails =
        await this.businessReportRepository.findByVerificationId(
          verificationId
        );

      // 4. If there's a Persona report ID, check its status
      let reportStatus = null;
      if (reportDetails?.report_id) {
        // Use proper public method instead of private _invokeFunction
        reportStatus = await this.personaProvider.checkReportStatus(
          reportDetails.report_id
        );

        if (reportStatus) {
          // Update report details with latest status
          await this.businessReportRepository.update(reportDetails.id, {
            report_status: reportStatus.attributes?.status || "pending",
            raw_provider_data: reportStatus,
          });
        }
      }

      // 5. Determine overall verification status based on reports and registration
      const updatedStatus = this._determineVerificationStatus(
        registrationDetails,
        reportDetails,
        reportStatus
      );

      // 6. Update verification status if changed
      if (updatedStatus && updatedStatus !== verification.status) {
        await this.verificationRepository.update(verificationId, {
          status: updatedStatus,
        });
      }

      return {
        verification,
        registrationDetails,
        reportDetails,
        reportStatus,
      };
    } catch (error) {
      console.error("Error checking business verification status:", error);
      throw error;
    }
  }

  /**
   * Creates a business verification report using Persona
   *
   * @param {string} verificationId - ID of the verification
   * @param {Object} businessData - Business data for the report
   * @returns {Promise<Object>} Created report details
   */
  async createBusinessReport(verificationId, businessData) {
    try {
      // 1. Get the verification record
      const verification = await this.verificationRepository.findById(
        verificationId
      );
      if (!verification) {
        throw new Error(`Verification not found: ${verificationId}`);
      }

      // 2. Get registration details (if available)
      const registrationDetails =
        await this.businessRegistrationRepository.findByVerificationId(
          verificationId
        );

      // 3. Prepare data for Persona report
      const reportData = {
        reportType: "business-verification-transaction",
        referenceId: `business_${verification.entity_id}_${Date.now()}`,
        carrier_id: verification.entity_id,
        business_fields: {
          business_name:
            businessData.business_name || registrationDetails?.business_name,
          ein: businessData.ein || registrationDetails?.ein,
          phone_number: businessData.phone_number,
          email: businessData.email,
          website: businessData.website,
          business_registered_address:
            businessData.address || registrationDetails?.registered_address,
        },
      };

      // 4. Create report through Persona
      const personaResponse = await this.personaProvider.createReport(
        reportData
      );

      // 5. Store report details
      const reportDetails = await this.businessReportRepository.create({
        verification_id: verificationId,
        report_id: personaResponse.report?.id,
        report_type: "business_verification",
        report_status: personaResponse.report?.attributes?.status || "pending",
        raw_provider_data: personaResponse,
      });

      return reportDetails;
    } catch (error) {
      console.error("Error creating business report:", error);
      throw error;
    }
  }

  /**
   * Processes document uploads for business verification
   *
   * @param {string} verificationId - ID of the verification
   * @param {File|Object} document - Document file to process
   * @param {string} documentType - Type of document
   * @returns {Promise<Object>} Document processing result
   */
  async processBusinessDocument(verificationId, document, documentType) {
    try {
      // 1. Get the verification record first
      const verification = await this.verificationRepository.findById(
        verificationId
      );
      if (!verification) {
        throw new Error(`Verification not found: ${verificationId}`);
      }

      // 2. Upload document using document provider
      const uploadedDocument = await this.documentProvider.uploadDocument({
        file: document,
        verificationId: verificationId,
        fileType: documentType,
        metadata: {
          entity_type: "business",
          document_type: documentType,
        },
      });

      // 3. Update verification with document reference
      await this.verificationRepository.update(verificationId, {
        documents: [
          ...(verification.documents || []),
          {
            id: uploadedDocument.id,
            type: documentType,
          },
        ],
      });

      return uploadedDocument;
    } catch (error) {
      console.error("Error processing business document:", error);
      throw error;
    }
  }

  /**
   * Updates business registration details
   *
   * @param {string} verificationId - ID of the verification
   * @param {Object} registrationData - Updated registration data
   * @returns {Promise<Object>} Updated registration details
   */
  async updateBusinessRegistration(verificationId, registrationData) {
    try {
      // 1. Get the verification record
      const verification = await this.verificationRepository.findById(
        verificationId
      );
      if (!verification) {
        throw new Error(`Verification not found: ${verificationId}`);
      }

      // 2. Get the existing registration details
      const existingRegistration =
        await this.businessRegistrationRepository.findByVerificationId(
          verificationId
        );

      if (!existingRegistration) {
        // Create new registration details if none exist
        return await this.businessRegistrationRepository.create({
          verification_id: verificationId,
          ...registrationData,
        });
      }

      // 3. Update the registration details
      const updatedRegistration =
        await this.businessRegistrationRepository.update(
          existingRegistration.id,
          registrationData
        );

      // 4. Update verification metadata
      if (registrationData.business_name || registrationData.ein) {
        await this.verificationRepository.update(verificationId, {
          metadata: {
            ...verification.metadata,
            business_name:
              registrationData.business_name ||
              verification.metadata?.business_name,
            ein: registrationData.ein || verification.metadata?.ein,
          },
        });
      }

      return updatedRegistration;
    } catch (error) {
      console.error("Error updating business registration:", error);
      throw error;
    }
  }

  /**
   * Determines overall verification status based on registration and reports
   *
   * @param {Object} registrationDetails - Business registration details
   * @param {Object} reportDetails - Business report details
   * @param {Object} reportStatus - Latest report status from provider
   * @returns {string} Determined verification status
   * @private
   */
  _determineVerificationStatus(
    registrationDetails,
    reportDetails,
    reportStatus
  ) {
    // If no registration or report, status remains pending
    if (!registrationDetails && !reportDetails) {
      return "pending";
    }

    // If report exists and is ready/completed, use its status
    if (
      reportDetails &&
      (reportDetails.report_status === "Ready" ||
        reportStatus?.attributes?.status === "Ready")
    ) {
      // Check for risk signals or failed verification
      const riskSignals =
        reportDetails.risk_signals || reportStatus?.attributes?.risk_signals;

      if (riskSignals && Object.keys(riskSignals).length > 0) {
        return "rejected"; // Has risk signals
      }

      return "verified"; // No risk signals found
    }

    // If report exists but is still processing
    if (
      reportDetails &&
      (reportDetails.report_status === "Pending" ||
        reportDetails.report_status === "Processing")
    ) {
      return "pending";
    }

    // If report failed
    if (
      reportDetails &&
      (reportDetails.report_status === "Errored" ||
        reportStatus?.attributes?.status === "Errored")
    ) {
      return "failed";
    }

    // Default to current registration status if available
    return registrationDetails?.verification_status || "pending";
  }

  /**
   * Saves business owner verification data to the database
   *
   * @param {string} inquiryId - The Persona inquiry ID
   * @param {Object} verificationResult - Result data from the verification process
   * @param {number|string} carrierId - ID of the carrier associated with the business owner
   * @returns {Promise<Object>} Object containing status and verification data
   */
  async saveBusinessOwnerVerification(
    inquiryId,
    verificationResult,
    carrierId
  ) {
    try {
      console.log(
        "📝 [BusinessVerificationStrategy] Saving business owner verification data for inquiry:",
        inquiryId
      );

      // Determine verification status
      const isVerified =
        verificationResult.passed ||
        verificationResult.inquiryPassed ||
        verificationResult.inquiryStatus?.toLowerCase() === "approved" ||
        verificationResult.inquiryStatus?.toLowerCase() === "completed";

      // Prepare verification data
      const verificationData = {
        persona_inquiry_id: inquiryId,
        carrier_id: carrierId,
        verification_type: "business_owner",
        status: isVerified ? "verified" : "pending",
        state: verificationResult.passed
          ? "persona_complete"
          : verificationResult.inquiryStatus?.toLowerCase() === "completed"
          ? "persona_complete"
          : "Started",
        metadata: {
          passed: verificationResult.passed,
          status: verificationResult.inquiryStatus,
          lastUpdated: new Date().toISOString(),
          personaData: {
            passed: verificationResult.inquiryPassed,
            status: verificationResult.inquiryStatus,
            inquiryId: inquiryId,
            referenceId: verificationResult.inquiryData?.reference_id || null,
          },
          businessOwnerId: verificationResult.businessOwnerId,
          fullData: verificationResult,
        },
      };

      // Check if verification already exists
      let verification =
        await this.verificationRepository.findByPersonaInquiryId(inquiryId);

      if (verification) {
        // Update existing verification
        console.log("✅ Found existing verification record:", verification.id);
        verification = await this.verificationRepository.update(
          verification.id,
          {
            ...verificationData,
            updated_at: new Date().toISOString(),
          }
        );
      } else {
        // Create new verification
        console.log("Creating new verification record");
        verification = await this.verificationRepository.create({
          ...verificationData,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        });
      }

      // Update business owner record if ID is provided
      if (verificationResult.businessOwnerId) {
        console.log("Updating business owner verification status:", {
          ownerId: verificationResult.businessOwnerId,
          isVerified,
        });

        // Business owner update via repository
        // In a full implementation, we would use a BusinessOwnerRepository here
        // For now, we'll use a simplified approach to maintain backward compatibility
        if (this.businessOwnerRepository) {
          await this.businessOwnerRepository.updateVerificationStatus(
            verificationResult.businessOwnerId,
            {
              isVerified,
              inquiryId,
              status: verificationResult.inquiryStatus,
              referenceId: verificationResult.inquiryData?.reference_id || null,
            }
          );
        } else {
          // If no repository is available, log a warning
          console.warn(
            "BusinessOwnerRepository not available - business owner record not updated"
          );
        }
      }

      return {
        success: true,
        verification,
        isVerified,
        message: "Business owner verification saved successfully",
      };
    } catch (error) {
      console.error(
        "🚨 [BusinessVerificationStrategy] Error saving business owner verification:",
        error
      );
      throw error;
    }
  }

  /**
   * Get verification by Persona inquiry ID
   * @param {string} inquiryId - Persona inquiry ID
   * @returns {Promise<Object>} Verification record
   */
  async getVerificationByInquiryId(inquiryId) {
    console.log("Getting business verification by inquiry ID:", inquiryId);
    return this.verificationRepository.findByPersonaInquiryId(inquiryId);
  }

  /**
   * Update verification by Persona inquiry ID
   * @param {string} inquiryId - Persona inquiry ID
   * @param {Object} data - Data to update
   * @returns {Promise<Object>} Updated verification record
   */
  async updateVerificationByInquiryId(inquiryId, data) {
    console.log("Updating business verification by inquiry ID:", inquiryId, data);
    const verification = await this.getVerificationByInquiryId(inquiryId);
    
    if (!verification) {
      throw new Error(`Verification not found for inquiry ID: ${inquiryId}`);
    }
    
    return this.verificationRepository.update(verification.id, data);
  }
}

export default BusinessVerificationStrategy;
