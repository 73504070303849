import React from "react";
import RiskReportAttributeGroup from "../RiskReportAttributeGroup";

/**
 * Renders an email risk report
 * @param {Object} props
 * @param {Object} props.attributes - The report attributes
 */
const EmailRiskReport = ({ attributes }) => {
  // Group email report fields based on the actual data structure
  const basicInfo = [
    "email-address",
    "email-domain",
    "status",
    "has-match",
    "created-at",
    "completed-at",
    "redacted-at",
    "is-recurring",
    "is-continuous",
  ];

  const domainDetails = [
    "email-domain-exists",
    "email-domain-age-days",
    "email-domain-reputation",
    "email-is-free-provider",
  ];

  const securityIndicators = [
    "email-reputation",
    "email-is-suspicious",
    "email-is-blocklisted",
    "email-is-spam",
    "email-is-disposable",
    "email-credentials-leaked",
    "email-credentials-leaked-recent",
    "email-data-breached",
    "email-malicious-activity-detected",
    "email-malicious-activity-detected-recent",
  ];

  return (
    <div className="space-y-6">
      <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
        <h3 className="text-blue-800 font-medium mb-3 text-sm flex items-center">
          <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          Basic Information
        </h3>
        <div className="bg-white rounded-lg divide-y divide-slate-100 shadow-sm">
          <RiskReportAttributeGroup attributes={attributes} keys={basicInfo} />
        </div>
      </div>

      <div className="bg-indigo-50 p-4 rounded-lg border border-indigo-100">
        <h3 className="text-indigo-800 font-medium mb-3 text-sm flex items-center">
          <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
            ></path>
          </svg>
          Domain Details
        </h3>
        <div className="bg-white rounded-lg divide-y divide-slate-100 shadow-sm">
          <RiskReportAttributeGroup
            attributes={attributes}
            keys={domainDetails}
          />
        </div>
      </div>

      <div className="bg-rose-50 p-4 rounded-lg border border-rose-100">
        <h3 className="text-rose-800 font-medium mb-3 text-sm flex items-center">
          <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            ></path>
          </svg>
          Security Indicators
        </h3>
        <div className="bg-white rounded-lg divide-y divide-slate-100 shadow-sm">
          <RiskReportAttributeGroup
            attributes={attributes}
            keys={securityIndicators}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailRiskReport;
