import * as React from "react";
import { ShipmentDataTable } from "./ShipmentDataTable";
import { ShipmentStats } from "./ShipmentStats";
import { Button } from "@/components/ui/button";
import { Download, Plus } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useShipments } from "@/hooks/shipments";
import { Link } from "react-router-dom";

/**
 * ShipmentDashboard - Main container for the shipments page
 */
export function ShipmentDashboard() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeView, setActiveView] = React.useState("all");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(1000);
  const [filters, setFilters] = React.useState({});

  // Fetch shipments using the useShipments hook
  const {
    data: shipmentsData,
    isLoading: shipmentLoading,
    error,
  } = useShipments(page, limit, filters);

  // Extract the shipments from the data
  const shipments = React.useMemo(() => {
    console.log("ShipmentDashboard - Raw shipmentsData:", shipmentsData);
    const extractedShipments = shipmentsData?.shipments || [];
    console.log("ShipmentDashboard - Extracted shipments:", extractedShipments);
    return extractedShipments;
  }, [shipmentsData]);

  // Filter shipments based on active view
  const filteredShipments = React.useMemo(() => {
    console.log("ShipmentDashboard - Filtering shipments with activeView:", activeView);
    console.log("ShipmentDashboard - Shipments to filter:", shipments);
    
    if (activeView === "all") {
      console.log("ShipmentDashboard - Returning all shipments");
      return shipments;
    }
    
    // Convert status values to lowercase for case-insensitive comparison
    const filtered = shipments.filter((shipment) => {
      const shipmentStatus = (shipment.status || "").toLowerCase();
      const activeViewStatus = activeView.toLowerCase();
      
      console.log(`ShipmentDashboard - Comparing shipment status: "${shipmentStatus}" with active view: "${activeViewStatus}"`);
      
      // Handle special cases for status mapping
      if (activeViewStatus === "shipped" && shipmentStatus === "in transit") {
        console.log("ShipmentDashboard - Special case: 'shipped' matches 'in transit'");
        return true;
      }
      
      const isMatch = shipmentStatus === activeViewStatus;
      console.log(`ShipmentDashboard - Status match: ${isMatch}`);
      return isMatch;
    });
    
    console.log("ShipmentDashboard - Filtered shipments:", filtered);
    return filtered;
  }, [shipments, activeView]);

  // Handler for exporting shipments
  const handleExport = () => {
    setIsLoading(true);

    // Simulate export process
    setTimeout(() => {
      setIsLoading(false);
      alert("Shipment data has been exported to CSV");
    }, 1500);
  };

  // If there's an error loading shipments
  if (error) {
    return (
      <div className="flex-1 overflow-auto">
        <div className="p-4 sm:p-6 lg:p-8">
          <div className="text-center text-red-500 p-4">
            Error loading shipments: {error.message}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-auto">
      <div className="p-4 sm:p-6 lg:p-8 space-y-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0">
          <div>
            <h2 className="text-2xl font-bold tracking-tight">Shipments</h2>
            <p className="text-muted-foreground">
              Manage and track your shipments
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="sm"
              className="h-9"
              onClick={handleExport}
              disabled={isLoading}
            >
              <Download className="mr-2 h-4 w-4" />
              Export
            </Button>
            <Link to="/dashboard/add-shipment">
              <Button variant="default" size="sm" className="h-9">
                <Plus className="mr-2 h-4 w-4" />
                Add Shipment
              </Button>
            </Link>
          </div>
        </div>

        <ShipmentStats shipments={shipments} />

        <Tabs
          defaultValue="all"
          onValueChange={setActiveView}
          className="space-y-4"
        >
          <TabsList>
            <TabsTrigger value="all">All Shipments</TabsTrigger>
            <TabsTrigger value="pending">Pending</TabsTrigger>
            <TabsTrigger value="processing">Processing</TabsTrigger>
            <TabsTrigger value="shipped">Shipped</TabsTrigger>
            <TabsTrigger value="delivered">Delivered</TabsTrigger>
          </TabsList>
          <TabsContent value={activeView}>
            <Card>
              <CardHeader className="pb-0">
                <CardTitle>Shipment Management</CardTitle>
                <CardDescription>
                  View, filter and manage your shipments
                </CardDescription>
              </CardHeader>
              <CardContent className="pt-6">
                {shipmentLoading ? (
                  <div className="text-center py-8 text-muted-foreground">
                    Loading shipments...
                  </div>
                ) : filteredShipments.length > 0 ? (
                  <ShipmentDataTable
                    data={filteredShipments}
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    totalPages={Math.ceil((shipmentsData?.total || 0) / limit)}
                  />
                ) : (
                  <div className="text-center py-8 text-muted-foreground">
                    No shipments found
                  </div>
                )}
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
