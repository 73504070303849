import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { getVerificationColumns } from "./columns";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, Search, X } from "lucide-react";

// Import the detail components
import DefaultVerificationDetails from "./VerificationDetails/DefaultVerificationDetails";
import DriverVerificationDetails from "./VerificationDetails/DriverVerificationDetails"; // Used for id_verification
import EmailRiskReportDetails from "./VerificationDetails/EmailRiskReportDetails";
import PhoneRiskReportDetails from "./VerificationDetails/PhoneRiskReportDetails";
import InsuranceVerificationDetails from "./VerificationDetails/InsuranceVerificationDetails";
import BusinessReportDetails from "./VerificationDetails/BusinessReportDetails";
import BusinessRegistrationDetails from "./VerificationDetails/BusinessRegistrationDetails";
import FMSCALookupDetails from "./VerificationDetails/FMSCALookupDetails";

/**
 * Helper to safely convert to lowercase string
 * @param {*} value - The value to convert
 * @returns {string} Lowercase string or empty string if value is not a string
 */
const safeToLowerCase = (value) => {
  if (typeof value === "string") {
    return value.toLowerCase();
  }
  return value ? String(value).toLowerCase() : "";
};

/**
 * VerificationsDataTable - Renders a data table for verifications
 *
 * @param {Object} props - Component props
 * @param {Array} props.data - Verification data to display
 * @param {number} props.page - Current page number
 * @param {Function} props.setPage - Function to set page number
 * @param {number} props.limit - Number of items per page
 * @param {string} props.verificationGroup - The group this table belongs to (used for column selection)
 */
export function VerificationsDataTable({
  data = [],
  page = 1,
  setPage = () => {},
  limit = 10,
  verificationGroup = "default", // Use group for columns now
}) {
  // Reference to table container for maintaining scroll position
  const tableContainerRef = React.useRef(null);

  // Get the columns based on verification *group*
  const columns = React.useMemo(() => {
    // Pass the group to determine columns. Adjust getVerificationColumns if needed.
    return getVerificationColumns(verificationGroup);
  }, [verificationGroup]);

  // Initialize state for global filter (search)
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);

  // State for expanded rows
  const [expandedRows, setExpandedRows] = React.useState(new Set());

  // Filter data based on search query
  const filteredData = React.useMemo(() => {
    if (!globalFilter) return data;

    const searchTerm = globalFilter.toLowerCase();
    return data.filter(
      (item) =>
        // Search by id
        (item.id && safeToLowerCase(item.id).includes(searchTerm)) ||
        // Search by phone number
        (item.phone_number &&
          safeToLowerCase(item.phone_number).includes(searchTerm)) ||
        // Search by shipment id
        (item.shipment_id &&
          safeToLowerCase(item.shipment_id).includes(searchTerm)) ||
        // Search by driver id
        (item.driver_id &&
          safeToLowerCase(item.driver_id).includes(searchTerm)) ||
        // Search by state
        (item.state && safeToLowerCase(item.state).includes(searchTerm)) ||
        // Search by carrier id
        (item.carrier_id &&
          safeToLowerCase(item.carrier_id.toString()).includes(searchTerm)) ||
        // Search by email
        (item.email && safeToLowerCase(item.email).includes(searchTerm))
    );
  }, [data, globalFilter]);

  // Simple sorting function
  const sortedData = React.useMemo(() => {
    if (!sorting.length) return filteredData;

    return [...filteredData].sort((a, b) => {
      for (const sort of sorting) {
        const { id, desc } = sort;

        if (!a[id] && !b[id]) return 0;
        if (!a[id]) return desc ? 1 : -1;
        if (!b[id]) return desc ? -1 : 1;

        const comparison = String(a[id]).localeCompare(String(b[id]));
        if (comparison !== 0) {
          return desc ? -comparison : comparison;
        }
      }
      return 0;
    });
  }, [filteredData, sorting]);

  // Calculate the paginated data
  const paginatedData = React.useMemo(() => {
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    return sortedData.slice(startIndex, endIndex);
  }, [sortedData, page, limit]);

  // Calculate total pages
  const totalPages = React.useMemo(() => {
    return Math.max(1, Math.ceil(sortedData.length / limit));
  }, [sortedData.length, limit]);

  // Handle sorting when a column header is clicked
  const handleSort = (columnId) => {
    setSorting((prevSorting) => {
      const existingSort = prevSorting.find((sort) => sort.id === columnId);

      if (!existingSort) {
        return [...prevSorting, { id: columnId, desc: false }];
      }

      if (!existingSort.desc) {
        return prevSorting.map((sort) =>
          sort.id === columnId ? { ...sort, desc: true } : sort
        );
      }

      return prevSorting.filter((sort) => sort.id !== columnId);
    });
  };

  // Get sort direction for a column
  const getSortDirection = (columnId) => {
    const sort = sorting.find((s) => s.id === columnId);
    return sort ? (sort.desc ? "desc" : "asc") : null;
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);

    // Restore scroll position after the component updates
    setTimeout(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = 0; // Reset scroll to top
      }
    }, 0);
  };

  // Toggle row expansion
  const toggleRowExpansion = (rowId) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(rowId)) {
        newSet.delete(rowId);
      } else {
        newSet.add(rowId);
      }
      return newSet;
    });
  };

  // Component selector for rendering row details based on verification_type
  const renderRowDetailsComponent = (verification) => {
    switch (verification.verification_type) {
      case "id_verification":
        return <DriverVerificationDetails verification={verification} />;
      case "email_risk_report":
        return <EmailRiskReportDetails verification={verification} />;
      case "phone_risk_report":
        return <PhoneRiskReportDetails verification={verification} />;
      case "insurance_verification":
        return <InsuranceVerificationDetails verification={verification} />;
      case "business_report":
        return <BusinessReportDetails verification={verification} />;
      case "business_registration":
        return <BusinessRegistrationDetails verification={verification} />;
      case "fmcsa_lookup":
        return <FMSCALookupDetails verification={verification} />;
      default:
        console.warn(
          `Using default details for unknown verification type: ${verification.verification_type}`
        );
        return <DefaultVerificationDetails verification={verification} />;
    }
  };

  // No data state
  if (data.length === 0 && !globalFilter) {
    // Only show if not actively filtering
    return (
      <div className="text-center py-8 text-muted-foreground">
        No verifications found.
      </div>
    );
  }
  if (paginatedData.length === 0 && globalFilter) {
    // Show if filtering results in no data
    return (
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <div className="relative flex-1">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search verifications..."
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className="pl-8"
            />
            {globalFilter && (
              <Button
                variant="ghost"
                size="sm"
                className="absolute right-1 top-1.5 h-6 w-6 px-0"
                onClick={() => setGlobalFilter("")}
              >
                <X className="h-4 w-4" />
                <span className="sr-only">Clear search</span>
              </Button>
            )}
          </div>
        </div>
        <div className="text-center py-8 text-muted-foreground">
          No verifications match your search criteria.
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <div className="relative flex-1">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search verifications..."
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="pl-8"
          />
          {globalFilter && (
            <Button
              variant="ghost"
              size="sm"
              className="absolute right-1 top-1.5 h-6 w-6 px-0"
              onClick={() => setGlobalFilter("")}
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Clear search</span>
            </Button>
          )}
        </div>
      </div>

      <div
        ref={tableContainerRef}
        className="rounded-md border overflow-auto"
        style={{
          minHeight: `${Math.min(paginatedData.length, 5) * 60 + 40}px`, // Adjust height based on data
          maxHeight: "60vh", // Add a max height for very long lists
        }}
      >
        <Table>
          <TableHeader>
            <TableRow>
              {columns.map((column) => (
                <TableHead
                  key={column.id}
                  className="whitespace-nowrap sticky top-0 bg-background z-10" // Make header sticky
                  onClick={() =>
                    column.id !== "actions" && handleSort(column.id)
                  }
                  style={{
                    cursor: column.id !== "actions" ? "pointer" : "default",
                  }}
                >
                  <div className="flex items-center space-x-1">
                    <span>{column.header}</span>
                    {column.id !== "actions" && getSortDirection(column.id) && (
                      <span
                        className={
                          getSortDirection(column.id) === "desc"
                            ? "transform rotate-180 transition-transform duration-150" // Add rotation transition
                            : "transition-transform duration-150"
                        }
                      >
                        ▲{" "}
                        {/* Consider using an icon library like lucide-react here */}
                      </span>
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedData.map((verification) => (
              <React.Fragment key={verification.id}>
                <TableRow
                  data-state={
                    expandedRows.has(verification.id) ? "open" : "closed"
                  } // Add data-state for potential styling
                  className="cursor-pointer hover:bg-muted/50 transition-colors" // Use theme color and add transition
                  onClick={() => toggleRowExpansion(verification.id)}
                >
                  {columns.map((column) => (
                    <TableCell key={column.id} className="py-2 px-4">
                      {column.cell ? (
                        column.cell({
                          row: {
                            getValue: (key) => verification[column.accessorKey],
                            original: verification,
                          },
                        })
                      ) : (
                        <span>{verification[column.accessorKey] ?? "N/A"}</span> // Handle null/undefined
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                {expandedRows.has(verification.id) && (
                  <TableRow className="bg-muted/20">
                    <TableCell colSpan={columns.length} className="p-0">
                      {renderRowDetailsComponent(verification)}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </div>

      {/* Pagination controls */}
      <div className="flex items-center justify-between pt-2">
        <div className="text-sm text-muted-foreground">
          Showing {(page - 1) * limit + 1} to{" "}
          {Math.min(page * limit, sortedData.length)} of {sortedData.length}{" "}
          entries
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Previous Page</span>
          </Button>
          <div className="text-sm font-medium w-20 text-center">
            Page {page} of {totalPages}
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
          >
            <ChevronRight className="h-4 w-4" />
            <span className="sr-only">Next Page</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
