import React from "react";
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "What exactly is Indemni's cargo theft prevention solution?",
    answer:
      "Indemni is a cutting-edge cargo security platform that combines Real-Time identity verification with cargo photo evidence tracking. Our solution provides end-to-end visibility and immediate alerts to proactively prevent cargo theft before it happens.",
  },
  {
    question:
      "What makes Indemni different from traditional cargo security solutions?",
    answer:
      "Unlike traditional systems focus on vetting and Real-Time tracking, Indemni offers Real-Time identity verification and photo evidence tracking to proactively prevent cargo theft before it happens.",
  },
  {
    question: "What types of cargo can be protected with Indemni?",
    answer:
      "Indemni is designed to protect all types of cargo, from high-value electronics and pharmaceuticals to consumer goods and industrial equipment. Our solution can be customized based on your specific cargo type, route requirements, and security needs.",
  },
  {
    question: "How quickly can you respond to a potential theft attempt?",
    answer:
      "Our system provides instant alerts within seconds of detecting suspicious activity, and can alert you immediately if there is suspicion of a risk attempt.",
  },
  {
    question: "Can Indemni integrate with our existing logistics systems?",
    answer:
      "Yes! Indemni is designed to seamlessly integrate with major TMS (Transportation Management Systems), ERP systems, and other logistics software through our API. Please contact us to learn more.",
  },
  {
    question: "What kind of reporting and analytics do you provide?",
    answer:
      "Our platform offers comprehensive analytics including route risk assessments, theft attempt patterns, and security performance indicators. You can generate custom reports, set up automated alerts, and access real-time dashboards to monitor your cargo security status.",
  },
  {
    question: "Is Indemni available internationally?",
    answer:
      "Yes, Indemni can operate globally with coverage across major shipping routes worldwide.",
  },
  {
    question: "What kind of support do you provide?",
    answer:
      "We offer 24/7 emergency support through our help center, along with dedicated account managers for each client. For more information, please contact us at support@indemni.com.",
  },
];

export default function Example() {
  return (
    <div className="bg-gray-900 relative">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl">
            Frequently asked questions
          </h2>
          <div>
            {faqs.map((faq) => (
              <Disclosure
                as="div"
                key={faq.question}
                className="border-b border-white/10"
              >
                {({ open }) => (
                  <div className="py-6">
                    <Disclosure.Button className="flex w-full items-center justify-between text-left">
                      <span className="text-base font-semibold text-white">
                        {faq.question}
                      </span>
                      <span className="ml-6 flex-shrink-0">
                        {open ? (
                          <MinusIcon className="h-6 w-6 text-white" />
                        ) : (
                          <PlusIcon className="h-6 w-6 text-white" />
                        )}
                      </span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="mt-4">
                      <p className="text-base text-gray-300">{faq.answer}</p>
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            ))}
          </div>
        </div>
      </div>

      {/* Background effects */}
      <div
        aria-hidden="true"
        className="absolute inset-0 transform-gpu overflow-hidden blur-3xl pointer-events-none"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[-30rem] top-[25rem] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-30 sm:left-[-30rem] sm:w-[72.1875rem]"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-0 transform-gpu overflow-hidden blur-3xl pointer-events-none"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%+3rem)] top-[-20rem] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
      </div>
    </div>
  );
}
