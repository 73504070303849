import * as React from "react";
import { useReactTable, flexRender } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useShipmentMutations } from "@/hooks/shipments";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { getColumns } from "./columns";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

/**
 * Helper to safely convert to lowercase string
 * @param {*} value - The value to convert
 * @returns {string} Lowercase string or empty string if value is not a string
 */
const safeToLowerCase = (value) => {
  if (typeof value === "string") {
    return value.toLowerCase();
  }
  return value ? String(value).toLowerCase() : "";
};

/**
 * ShipmentDataTable - Renders a data table for shipments
 *
 * @param {Object} props - Component props
 * @param {Array} props.data - Shipment data to display
 * @param {number} props.page - Current page number
 * @param {Function} props.setPage - Function to set page number
 * @param {number} props.limit - Number of items per page
 * @param {number} props.totalPages - Total number of pages
 */
export function ShipmentDataTable({
  data = [],
  page = 1,
  setPage = () => {},
  limit = 10,
  totalPages = 1,
}) {
  console.log("ShipmentDataTable - Received data:", data);
  console.log("ShipmentDataTable - Page:", page);
  console.log("ShipmentDataTable - Limit:", limit);
  console.log("ShipmentDataTable - Total Pages:", totalPages);

  // Reference to table container for maintaining scroll position
  const tableContainerRef = React.useRef(null);

  // Initialize state for global filter (search)
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);

  // Add Hooks
  const navigate = useNavigate();
  const { deleteShipment: deleteShipmentMutation } = useShipmentMutations();

  // Define Action Handlers
  const handleView = React.useCallback(
    (id) => {
      if (id) {
        navigate(`/dashboard/shipment/${id}`);
      } else {
        console.error("View action called without ID");
      }
    },
    [navigate]
  );

  const handleEdit = React.useCallback(
    (id) => {
      if (id) {
        // Navigate to add form with ID as query param
        navigate(`/dashboard/add-shipment?id=${id}`);
      } else {
        console.error("Edit action called without ID");
      }
    },
    [navigate]
  );

  const handleDelete = React.useCallback(
    (id) => {
      if (!id) {
        console.error("Delete action called without ID");
        return;
      }
      if (window.confirm("Are you sure you want to delete this shipment?")) {
        deleteShipmentMutation.mutate(id, {
          onSuccess: () => {
            console.log(`Shipment ${id} deleted successfully.`);
            // Data refetch/invalidation is handled by useShipmentMutations hook
          },
          onError: (error) => {
            console.error(`Error deleting shipment ${id}:`, error);
            // TODO: Add user-facing error notification (e.g., using react-toastify)
            alert(
              `Error deleting shipment: ${error.message || "Unknown error"}`
            );
          },
        });
      }
    },
    [deleteShipmentMutation]
  );

  // Generate Columns Dynamically
  const columns = React.useMemo(
    () =>
      getColumns({
        onView: handleView,
        onEdit: handleEdit,
        onDelete: handleDelete,
      }),
    [handleView, handleEdit, handleDelete] // Dependencies
  );

  // Filter data based on search query
  const filteredData = React.useMemo(() => {
    if (!globalFilter) return data;

    const searchTerm = globalFilter.toLowerCase();
    const filtered = data.filter(
      (item) =>
        // Search by load_id (correct field name)
        (item.load_id && safeToLowerCase(item.load_id).includes(searchTerm)) ||
        // Search by cargo owner company name
        (item.cargo_owners?.company_name &&
          safeToLowerCase(item.cargo_owners.company_name).includes(
            searchTerm
          )) ||
        // Search by origin address
        (item.origin_address &&
          safeToLowerCase(item.origin_address).includes(searchTerm)) ||
        // Search by destination address
        (item.destination_address &&
          safeToLowerCase(item.destination_address).includes(searchTerm)) ||
        // Search by status
        (item.status && safeToLowerCase(item.status).includes(searchTerm)) ||
        // Search by vehicle number
        (item.vehicle_number &&
          safeToLowerCase(item.vehicle_number).includes(searchTerm)) ||
        // Search by dock assignment
        (item.dock_assignment &&
          safeToLowerCase(item.dock_assignment).includes(searchTerm))
    );

    console.log("ShipmentDataTable - Filtered data by search:", filtered);
    return filtered;
  }, [data, globalFilter]);

  // Simple sorting function
  const sortedData = React.useMemo(() => {
    if (!sorting.length) return filteredData;

    return [...filteredData].sort((a, b) => {
      for (const sort of sorting) {
        const { id, desc } = sort;

        if (!a[id] && !b[id]) return 0;
        if (!a[id]) return desc ? 1 : -1;
        if (!b[id]) return desc ? -1 : 1;

        const comparison = String(a[id]).localeCompare(String(b[id]));
        if (comparison !== 0) {
          return desc ? -comparison : comparison;
        }
      }
      return 0;
    });
  }, [filteredData, sorting]);

  // Calculate the paginated data - This ensures we only show the correct items per page
  const paginatedData = React.useMemo(() => {
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    return sortedData.slice(startIndex, endIndex);
  }, [sortedData, page, limit]);

  // Calculate the total number of pages based on filtered data
  const calculatedTotalPages = React.useMemo(() => {
    return Math.ceil(sortedData.length / limit);
  }, [sortedData, limit]);

  // Simple column state management
  const handleSort = (columnId) => {
    setSorting((prev) => {
      // Find if we're already sorting by this column
      const existingSort = prev.find((sort) => sort.id === columnId);

      if (!existingSort) {
        // New sort
        return [{ id: columnId, desc: false }];
      } else if (!existingSort.desc) {
        // Change to descending
        return [{ id: columnId, desc: true }];
      } else {
        // Remove sort
        return prev.filter((sort) => sort.id !== columnId);
      }
    });
  };

  // Get sort direction for a column
  const getSortDirection = (columnId) => {
    const sort = sorting.find((sort) => sort.id === columnId);
    return sort ? (sort.desc ? "desc" : "asc") : null;
  };

  // Handle pagination with scroll preservation
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= calculatedTotalPages) {
      // Get current scroll position of the table container
      const tablePosition = tableContainerRef.current?.getBoundingClientRect();

      // Change the page
      setPage(newPage);

      // After state update, maintain scroll position
      if (tablePosition) {
        setTimeout(() => {
          window.scrollTo({
            top: window.scrollY + tablePosition.top,
            behavior: "smooth",
          });
        }, 10);
      }
    }
  };

  return (
    <div className="space-y-4" ref={tableContainerRef}>
      {/* Simple Search */}
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Input
            placeholder="Search shipments..."
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="max-w-sm"
          />
          {globalFilter && (
            <Button
              variant="ghost"
              onClick={() => setGlobalFilter("")}
              className="h-8 px-2 lg:px-3"
            >
              Clear
            </Button>
          )}
        </div>
      </div>

      {/* Table */}
      <div className="rounded-md border overflow-auto">
        <Table>
          <TableHeader>
            <TableRow>
              {columns.map((column) => (
                <TableHead key={column.id || column.accessorKey}>
                  <div
                    className={
                      column.enableSorting ? "cursor-pointer select-none" : ""
                    }
                    onClick={
                      column.enableSorting
                        ? () => handleSort(column.accessorKey)
                        : undefined
                    }
                  >
                    {column.header}
                    {{
                      asc: " ",
                      desc: " ",
                    }[getSortDirection(column.accessorKey)] ?? null}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedData.length > 0 ? (
              paginatedData.map((row, rowIndex) => (
                <TableRow
                  key={row.id || rowIndex}
                  data-state={row.selected && "selected"}
                >
                  {columns.map((column, columnIndex) => (
                    <TableCell key={columnIndex}>
                      {column.cell
                        ? column.cell({ row: { original: row } })
                        : row[column.accessorKey]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {/* Simple Pagination */}
      <div className="flex items-center justify-between">
        <div className="text-sm text-muted-foreground">
          Showing{" "}
          <strong>{sortedData.length ? (page - 1) * limit + 1 : 0}</strong> to{" "}
          <strong>{Math.min(page * limit, sortedData.length)}</strong> of{" "}
          <strong>{sortedData.length}</strong> results
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
          >
            &lt;
          </Button>
          <div className="text-sm font-medium">
            Page {page} of {calculatedTotalPages || 1}
          </div>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= calculatedTotalPages}
          >
            &gt;
          </Button>
        </div>
      </div>
    </div>
  );
}
