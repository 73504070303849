// @ts-check
import { supabase } from "../../../lib/supabase";
import customerSchema from "../../../schemas/customerSchema";

// Simple logger
const logger = {
  info: (message) => console.log(`INFO: ${message}`),
  error: (message) => console.error(`ERROR: ${message}`),
};

/**
 * Service for customer-related operations
 */
const CustomersService = {
  /**
   * Create a new customer
   * @param {Object} customerData - The customer data to create
   * @returns {Promise<Object>} Result of the operation
   */
  async createCustomer(customerData) {
    try {
      const validatedData = customerSchema.parse(customerData);
      const { data, error } = await supabase
        .from("customers")
        .insert(validatedData)
        .select()
        .single();

      if (error) throw error;
      logger.info(`Customer created: ${data.id}`);
      return { message: "Customer created successfully", customerId: data.id };
    } catch (error) {
      logger.error(`Error creating customer: ${error.message}`);
      throw error;
    }
  },

  /**
   * Get paginated list of customers
   * @param {number} page - Page number to fetch
   * @param {number} limit - Number of items per page
   * @returns {Promise<Object>} Paginated customers and total count
   */
  async getCustomers(page = 1, limit = 10) {
    try {
      const { data, error, count } = await supabase
        .from("customers")
        .select("*", { count: "exact" })
        .range((page - 1) * limit, page * limit - 1);

      if (error) throw error;
      return { customers: data, total: count };
    } catch (error) {
      logger.error(`Error fetching customers: ${error.message}`);
      throw error;
    }
  },

  /**
   * Get a customer by ID
   * @param {string} id - Customer ID to fetch
   * @returns {Promise<Object>} Customer data
   */
  async getCustomer(id) {
    try {
      const { data, error } = await supabase
        .from("customers")
        .select("*")
        .eq("id", id)
        .single();

      if (error) {
        if (error.code === "PGRST116") {
          return { message: "Customer not found" };
        }
        throw error;
      }
      return data;
    } catch (error) {
      logger.error(`Error fetching customer ${id}: ${error.message}`);
      throw error;
    }
  },

  /**
   * Update a customer
   * @param {string} id - Customer ID to update
   * @param {Object} customerData - Updated customer data
   * @returns {Promise<Object>} Result of the operation
   */
  async updateCustomer(id, customerData) {
    try {
      const validatedData = customerSchema.partial().parse(customerData);
      const { data, error } = await supabase
        .from("customers")
        .update(validatedData)
        .eq("id", id)
        .select()
        .single();

      if (error) {
        if (error.code === "PGRST116") {
          return { message: "Customer not found" };
        }
        throw error;
      }
      logger.info(`Customer updated: ${id}`);
      return { message: "Customer updated successfully", customer: data };
    } catch (error) {
      logger.error(`Error updating customer ${id}: ${error.message}`);
      throw error;
    }
  },

  /**
   * Delete a customer
   * @param {string} id - Customer ID to delete
   * @returns {Promise<Object>} Result of the operation
   */
  async deleteCustomer(id) {
    try {
      const { error } = await supabase.from("customers").delete().eq("id", id);

      if (error) {
        if (error.code === "PGRST116") {
          return { message: "Customer not found" };
        }
        throw error;
      }
      logger.info(`Customer deleted: ${id}`);
      return { message: "Customer deleted successfully" };
    } catch (error) {
      logger.error(`Error deleting customer ${id}: ${error.message}`);
      throw error;
    }
  },

  /**
   * Get customers by company ID
   * @param {string} companyId - Company ID to fetch customers for
   * @param {number} page - Page number to fetch
   * @param {number} limit - Number of items per page
   * @returns {Promise<Object>} Paginated customers and total count
   */
  async getCustomersByCompany(companyId, page = 1, limit = 10) {
    try {
      const { data, error, count } = await supabase
        .from("customers")
        .select("*", { count: "exact" })
        .eq("company_id", companyId)
        .range((page - 1) * limit, page * limit - 1);

      if (error) throw error;
      return { customers: data, total: count };
    } catch (error) {
      logger.error(
        `Error fetching customers for company ${companyId}: ${error.message}`
      );
      throw error;
    }
  },

  /**
   * Get a customer by user ID
   * @param {string} userId - User ID to fetch customer for
   * @returns {Promise<Object>} Customer data
   */
  async getCustomerByUserId(userId) {
    try {
      const { data, error } = await supabase
        .from("customers")
        .select("*")
        .eq("user_id", userId)
        .single();

      if (error) {
        if (error.code === "PGRST116") {
          return { message: "Customer not found" };
        }
        throw error;
      }
      return data;
    } catch (error) {
      logger.error(
        `Error fetching customer by user_id ${userId}: ${error.message}`
      );
      throw error;
    }
  },

  /**
   * Get company ID for a customer by user ID
   * @param {string} userId - User ID to fetch company ID for
   * @returns {Promise<string|null>} Company ID if found
   */
  async getCustomerCompanyId(userId) {
    try {
      const { data, error } = await supabase
        .from("customers")
        .select("company_id")
        .eq("user_id", userId)
        .single();

      if (error) {
        if (error.code === "PGRST116") {
          return null; // No customer found for this user
        }
        throw error;
      }
      return data?.company_id;
    } catch (error) {
      logger.error(
        `Error fetching company ID for user ${userId}: ${error.message}`
      );
      throw error;
    }
  },
};

export default CustomersService;
