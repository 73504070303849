import React from "react";
// Consider importing components/styles from RiskReportsSection if reusable
// import { InsuranceVerificationCard } from '@/components/RiskReports/RiskReportsSection'; // Note: This is the whole card

/**
 * InsuranceVerificationDetails - Renders details specific to insurance verifications.
 * TODO: Implement insurance verification snapshot view, potentially reusing logic/UI
 * from src/components/RiskReports/RiskReportsSection.jsx (InsuranceVerificationCard)
 *
 * @param {Object} props - Component props
 * @param {Object} props.verification - The verification data object (contains insurance data)
 */
export default function InsuranceVerificationDetails({ verification }) {
  // Extract relevant data, similar to InsuranceVerificationCard
  const verificationDocument = verification?.results?.included?.find(
    (item) => item.type === "verification/document"
  );
  const attributes = verificationDocument?.attributes || {};
  const extractedData = attributes.fields || {};

  return (
    <div className="p-4 bg-cyan-50 space-y-2 text-sm border border-cyan-200 rounded">
      <h4 className="font-semibold text-cyan-700">
        Insurance Verification Details
      </h4>
      <p className="text-slate-500">Verification ID: {verification.id}</p>
      <p className="text-slate-500">State: {verification.state || "N/A"}</p>
      <p className="text-orange-600 italic">
        -- Insurance verification snapshot placeholder --
      </p>
      {/* Build specific view based on extractedData here */}
      <pre className="text-xs bg-slate-100 p-2 rounded overflow-auto max-h-60">
        {JSON.stringify({ extractedData, status: attributes.status }, null, 2)}
      </pre>
    </div>
  );
}
