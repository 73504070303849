// @ts-nocheck
import { supabase } from "../../../lib/supabase";
import { shipmentSchema } from "../../../schemas/shipmentSchema";
import { v4 as uuidv4 } from "uuid";

export const ShipmentService = {
  async createShipment(shipmentData) {
    try {
      console.log("Raw shipment data received:", shipmentData);
      console.log(
        "Type of cargo_owner_id:",
        typeof shipmentData.cargo_owner_id
      );
      console.log("Type of company_id:", typeof shipmentData.company_id);

      // Validate data using the schema
      try {
        // z.coerce will automatically convert string IDs to numbers
        const validatedData = shipmentSchema.parse(shipmentData);
        console.log("Data after validation/coercion:", validatedData);
        console.log(
          "Validated cargo_owner_id type:",
          typeof validatedData.cargo_owner_id
        );
        console.log(
          "Validated company_id type:",
          typeof validatedData.company_id
        );

        const dataToInsert = {
          ...validatedData,
          load_id: validatedData.load_id || uuidv4(),
        };

        console.log("Creating shipment with data:", dataToInsert);

        const { data, error } = await supabase
          .from("shipments")
          .insert(dataToInsert)
          .select()
          .single();

        if (error) {
          console.error("Supabase error creating shipment:", error);
          throw error;
        }

        console.log("Successfully created shipment:", data);
        return data;
      } catch (validationError) {
        console.error("Schema validation error:", validationError);
        console.error(
          "Error format:",
          validationError.format
            ? validationError.format()
            : "No format available"
        );
        throw validationError;
      }
    } catch (error) {
      console.error("Error in createShipment:", error);
      if (error.errors) {
        console.error("Validation errors:", error.errors);
      }
      throw error;
    }
  },

  async getShipments(page = 1, limit = 10, filters = {}) {
    try {
      let query = supabase.from("shipments").select(
        `
          *,
          cargo_owners (
            id,
            company_name
          )
        `,
        { count: "exact" }
      );

      // Filter by cargo owner ID
      if (filters?.cargoOwnerId) {
        query = query.eq("cargo_owner_id", filters.cargoOwnerId);
      }

      // Apply other filters
      if (filters?.status) {
        query = query.eq("status", filters.status);
      }

      // Update the search filter to properly handle load_id
      if (filters?.search) {
        const cleanedSearch = filters.search.replace(/\s+/g, "");
        query = query.or(
          `load_id.ilike.%${cleanedSearch}%,carrier_number.ilike.%${cleanedSearch}%`
        );
      }

      const { data, error, count } = await query
        .range((page - 1) * limit, page * limit - 1)
        .order("created_at", { ascending: false });

      if (error) throw error;
      return { shipments: data, total: count };
    } catch (error) {
      console.error("Error fetching shipments:", error);
      throw error;
    }
  },

  async getShipment(id, cargoOwnerId = null) {
    try {
      let query = supabase
        .from("shipments")
        .select(
          `
          *,
          cargo_owners (
            id,
            company_name
          )
        `
        )
        .eq("load_id", id);

      if (cargoOwnerId) {
        query = query.eq("cargo_owner_id", cargoOwnerId);
      }

      const { data, error } = await query.limit(1);

      if (error) throw error;
      return data[0] || null;
    } catch (error) {
      console.error("Error fetching shipment:", error);
      throw error;
    }
  },

  async getAllShipments(companyId) {
    let query = supabase.from("shipments").select("*", { count: "exact" });

    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    const { data, error, count } = await query;
    if (error) throw error;
    return { shipments: data, total: count };
  },

  async updateShipment(id, shipmentData) {
    const validatedData = shipmentSchema.partial().parse(shipmentData);
    const { data, error } = await supabase
      .from("shipments")
      .update(validatedData)
      .eq("id", id)
      .select()
      .single();
    if (error) throw error;
    return data;
  },

  async deleteShipment(id) {
    const { error } = await supabase.from("shipments").delete().eq("id", id);
    if (error) throw error;
  },

  async updateShipmentStatus(id, status) {
    console.log("Triggering updateShipmentStatus API", id, status);
    try {
      const { data, error } = await supabase
        .from("shipments")
        .update({ status, verified_at: new Date().toISOString() })
        .eq("load_id", id)
        .select()
        .single();

      if (error) {
        console.error("Error updating shipment status:", error);
        throw error;
      }

      console.log("Successfully updated shipment status:", data);
      return data;
    } catch (error) {
      console.error("Unexpected error in updateShipmentStatus:", error);
      throw error;
    }
  },

  async addShipmentPhoto(id, photoData) {
    const { data, error } = await supabase
      .from("shipments")
      .update({ photo: photoData })
      .eq("id", id)
      .select()
      .single();
    if (error) throw error;
    return data;
  },
};
