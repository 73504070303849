import React, { useState, useEffect } from "react";
import {
  ChevronDown,
  ChevronUp,
  CheckCircle,
  XCircle,
  AlertCircle,
} from "lucide-react";
import VerificationBadge from "../VerificationBadge";

/**
 * Component for displaying business owner verification with expandable details
 *
 * @param {Object} props
 * @param {Object} props.verification - The verification data
 * @param {Object} props.owner - The business owner data
 */
const BusinessOwnerVerificationBadge = ({ verification, owner }) => {
  const [expanded, setExpanded] = useState(false);

  // Add debugging to see what verification data is received
  useEffect(() => {
    console.log(
      `BusinessOwnerVerificationBadge for ${owner?.first_name} ${owner?.last_name}:`,
      {
        owner,
        verification,
        hasVerification: !!verification,
      }
    );
  }, [verification, owner]);

  if (!verification) {
    return (
      <div className="inline-flex items-center">
        <VerificationBadge status="unknown" showLabel={true} size="sm" />
        <button
          className="ml-1 text-xs text-blue-600 hover:text-blue-800 underline"
          onClick={() =>
            alert(
              `Start verification for ${owner.first_name} ${owner.last_name}`
            )
          }
        >
          Start verification
        </button>
      </div>
    );
  }

  // Extract verification details - handle different possible structures
  const {
    state = "pending",
    status,
    persona_verification_complete = false,
    created_at,
    updated_at,
    persona_inquiry_id,
    business_owner_id,
    verification_type,
    carrier_id,
  } = verification;

  // Use status field if state is not available
  const verificationState = state || status || "pending";

  // Determine human-readable status text
  let statusText = "ID Verification";
  if (persona_verification_complete && state === "persona_complete") {
    statusText = "Verified";
  } else if (state === "persona_pending") {
    statusText = "Pending";
  } else if (state === "persona_failed") {
    statusText = "Failed";
  } else if (
    state === "complete" ||
    state === "completed" ||
    state === "approved"
  ) {
    statusText = "Verified";
  }

  // Determine if this verification is for the current owner
  const isForThisOwner = business_owner_id === owner.id;

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <VerificationBadge
            status={verificationState}
            success={persona_verification_complete}
            size="md"
          />
          <button
            className="ml-2 text-xs text-gray-500 hover:text-gray-700 flex items-center"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
            <span className="ml-1">{expanded ? "Hide" : "Details"}</span>
          </button>
        </div>
      </div>

      {expanded && (
        <div className="mt-2 text-sm bg-gray-50 p-3 rounded-md">
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center">
              <span className="text-gray-600">Status:</span>
              <span className="ml-2 font-medium capitalize">{statusText}</span>
            </div>
            <div className="flex items-center">
              <span className="text-gray-600">Completed:</span>
              <span className="ml-2">
                {persona_verification_complete ? (
                  <CheckCircle size={16} className="text-green-500" />
                ) : (
                  <XCircle size={16} className="text-gray-400" />
                )}
              </span>
            </div>
            {business_owner_id && (
              <div className="col-span-2 flex items-center">
                <span className="text-gray-600">Owner ID:</span>
                <span className="ml-2 text-xs font-mono overflow-hidden text-ellipsis">
                  {business_owner_id}
                </span>
              </div>
            )}
            {persona_inquiry_id && (
              <div className="col-span-2 flex items-center">
                <span className="text-gray-600">Inquiry ID:</span>
                <span className="ml-2 text-xs font-mono overflow-hidden text-ellipsis">
                  {persona_inquiry_id}
                </span>
              </div>
            )}
            {verification_type && (
              <div className="flex items-center">
                <span className="text-gray-600">Type:</span>
                <span className="ml-2 text-xs">{verification_type}</span>
              </div>
            )}
            {created_at && (
              <div className="flex items-center">
                <span className="text-gray-600">Created:</span>
                <span className="ml-2 text-xs">
                  {new Date(created_at).toLocaleString()}
                </span>
              </div>
            )}
            {updated_at && (
              <div className="flex items-center">
                <span className="text-gray-600">Updated:</span>
                <span className="ml-2 text-xs">
                  {new Date(updated_at).toLocaleString()}
                </span>
              </div>
            )}
          </div>
          <div className="mt-2 pt-2 border-t">
            <button
              className="text-xs bg-blue-50 text-blue-600 px-2 py-1 rounded hover:bg-blue-100"
              onClick={() =>
                alert(`Check status for inquiry ID: ${persona_inquiry_id}`)
              }
            >
              Refresh Status
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessOwnerVerificationBadge;
