// @ts-nocheck
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";
import {
  FileDigit,
  Shield,
  Briefcase,
  Home,
  Users,
  Truck,
  CheckCircle,
  FileCheck,
  FileText,
  Hash,
  AlignLeft,
  ClipboardList,
} from "lucide-react";
import { motion } from "framer-motion";

const AuthorityTable = ({ authorityContent = [] }) => {
  if (!Array.isArray(authorityContent) || authorityContent.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="p-6 text-center bg-gray-50 rounded-lg border border-gray-200"
      >
        <Shield className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          No Authority Data
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          No authority information is available for this carrier.
        </p>
      </motion.div>
    );
  }

  const getRowIcon = (label) => {
    const iconProps = { size: 16, className: "text-gray-500" };

    switch (label) {
      case "Applicant ID":
        return <FileDigit {...iconProps} />;
      case "Authority":
        return <Shield {...iconProps} />;
      case "Authorized For Broker":
        return <Briefcase {...iconProps} />;
      case "Authorized For Household Goods":
        return <Home {...iconProps} />;
      case "Authorized For Passenger":
        return <Users {...iconProps} />;
      case "Authorized For Property":
        return <Truck {...iconProps} />;
      case "Broker Authority Status":
        return <CheckCircle {...iconProps} />;
      case "Common Authority Status":
        return <FileCheck {...iconProps} />;
      case "Contract Authority Status":
        return <FileText {...iconProps} />;
      case "Docket Number":
        return <Hash {...iconProps} />;
      case "DOT Number":
        return <FileDigit {...iconProps} />;
      case "Prefix":
        return <AlignLeft {...iconProps} />;
      default:
        return null;
    }
  };

  return (
    <>
      {authorityContent.map((item, idx) => {
        const auth = item?.carrierAuthority;
        return (
          <motion.div
            key={idx}
            className="mb-8"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: idx * 0.1 }}
          >
            <div className="flex items-center mb-3">
              <ClipboardList className="mr-2 text-blue-500" size={20} />
              <h3 className="text-lg font-bold text-gray-800">
                Authority Record {idx + 1}
              </h3>
            </div>

            <div className="rounded-lg border border-gray-200 shadow-sm overflow-hidden">
              <Table className="w-full">
                <TableHeader>
                  <TableRow className="bg-gray-50">
                    <TableHead className="py-3 px-4 text-gray-700">
                      Field
                    </TableHead>
                    <TableHead className="py-3 px-4 text-gray-700">
                      Value
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {[
                    { label: "Applicant ID", value: auth?.applicantID },
                    { label: "Authority", value: auth?.authority },
                    {
                      label: "Authorized For Broker",
                      value: auth?.authorizedForBroker,
                    },
                    {
                      label: "Authorized For Household Goods",
                      value: auth?.authorizedForHouseholdGoods,
                    },
                    {
                      label: "Authorized For Passenger",
                      value: auth?.authorizedForPassenger,
                    },
                    {
                      label: "Authorized For Property",
                      value: auth?.authorizedForProperty,
                    },
                    {
                      label: "Broker Authority Status",
                      value: auth?.brokerAuthorityStatus,
                    },
                    {
                      label: "Common Authority Status",
                      value: auth?.commonAuthorityStatus,
                    },
                    {
                      label: "Contract Authority Status",
                      value: auth?.contractAuthorityStatus,
                    },
                    { label: "Docket Number", value: auth?.docketNumber },
                    { label: "DOT Number", value: auth?.dotNumber },
                    { label: "Prefix", value: auth?.prefix },
                  ].map((row, rowIdx) => (
                    <TableRow
                      key={rowIdx}
                      className="hover:bg-gray-50 transition-colors"
                    >
                      <TableCell className="py-3 px-4 font-medium text-gray-700 w-1/3">
                        <div className="flex items-center space-x-2">
                          {getRowIcon(row.label)}
                          <span>{row.label}</span>
                        </div>
                      </TableCell>
                      <TableCell className="py-3 px-4 text-gray-600 font-medium">
                        {row.value || "N/A"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </motion.div>
        );
      })}
    </>
  );
};

export default AuthorityTable;
