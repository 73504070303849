import React, { useState } from "react";
import {
  Phone,
  Smartphone,
  Activity,
  AlertCircle,
  AlertTriangle,
  CheckCircle,
  Info,
  Calendar,
  Network,
  ShieldCheck,
  User,
  Clock,
  FileText,
  X,
  FileCode,
  TrendingUp,
  Check,
  Minus,
  RotateCw,
  Infinity,
} from "lucide-react";
import { format } from "date-fns";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { Progress } from "@/components/ui/progress";
import { Separator } from "@/components/ui/separator";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { cn } from "@/lib/utils";

// --- Helper Functions ---

const FormatBoolean = ({ value }) => {
  if (value === true)
    return (
      <span className="flex items-center text-emerald-600">
        <Check className="h-4 w-4 mr-1 flex-shrink-0" /> Yes
      </span>
    );
  if (value === false)
    return (
      <span className="flex items-center text-rose-600">
        <X className="h-4 w-4 mr-1 flex-shrink-0" /> No
      </span>
    );
  return (
    <span className="flex items-center text-muted-foreground">
      <Minus className="h-4 w-4 mr-1 flex-shrink-0" /> N/A
    </span>
  );
};

const getProgressColorClass = (score) => {
  if (score === null || score === undefined) return "bg-muted";
  if (score >= 700) return "bg-destructive";
  if (score >= 400) return "bg-warning";
  return "bg-success";
};

const getBadgeVariant = (level) => {
  const lowerLevel = level?.toLowerCase();
  switch (lowerLevel) {
    case "high":
      return "destructive";
    case "medium":
      return "warning";
    case "low":
      return "success";
    default:
      return "secondary";
  }
};

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  try {
    return format(new Date(dateString), "PPpp");
  } catch (e) {
    console.error("Invalid Date:", dateString, e);
    return "Invalid Date";
  }
};

const formatPhoneNumber = (number) => {
  if (!number) return "N/A";
  const cleaned = String(number).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return number;
};

// --- UI Components ---

const DetailItem = ({ label, value, icon: Icon }) => (
  <div className="flex flex-col sm:flex-row sm:items-start justify-between py-3">
    <div className="flex items-center text-sm text-muted-foreground mb-1 sm:mb-0 mr-4 whitespace-nowrap">
      {Icon && <Icon className="h-4 w-4 mr-2 flex-shrink-0" />}
      {label}
    </div>
    <div className="text-sm font-medium text-foreground text-left sm:text-right break-words max-w-[70%] sm:max-w-[50%]">
      {typeof value === "boolean" ? (
        <FormatBoolean value={value} />
      ) : (
        value ?? <span className="text-muted-foreground">N/A</span>
      )}
    </div>
  </div>
);

const Section = ({ title, icon: Icon, children, className = "" }) => (
  <div className={cn("mb-6", className)}>
    <h3 className="text-md font-semibold text-foreground flex items-center mb-3 border-b pb-2">
      {Icon && <Icon className="h-5 w-5 mr-2 text-primary/90 flex-shrink-0" />}
      {title}
    </h3>
    <div className="space-y-0 divide-y divide-border/60">{children}</div>
  </div>
);

// --- Main Components ---

const PhoneRiskSummaryCard = ({ phoneRiskReport }) => {
  const noData = !phoneRiskReport || !phoneRiskReport.results;

  if (noData) {
    return (
      <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm">
        <div className="absolute inset-0 bg-gradient-to-r from-slate-50 to-white opacity-50"></div>
        <div className="relative p-5">
          <div className="flex items-center space-x-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-100">
              <Phone className="h-5 w-5 text-slate-400" />
            </div>
            <div>
              <h3 className="text-base font-medium text-slate-800">
                Phone Risk Analysis
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                No risk assessment data available
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const resultsData = phoneRiskReport.results || {};
  const attributes = resultsData.rawData?.data?.attributes || {};
  const riskLevel =
    resultsData.phoneRiskLevel || attributes["phone-risk-level"] || "Unknown";
  const riskScore =
    resultsData.phoneRiskScore || attributes["phone-risk-score"] || 0;
  const phoneNumber = resultsData.phoneNumber || attributes["phone-number"];
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  const isHighRisk = riskLevel.toLowerCase() === "high";
  const isMediumRisk = riskLevel.toLowerCase() === "medium";
  const isLowRisk = !isHighRisk && !isMediumRisk;

  const riskConfig = {
    high: {
      gradient: "from-rose-50 to-pink-50",
      color: "rose",
      border: "border-rose-200",
      iconBg: "bg-rose-500",
      progressBg: "bg-rose-500",
      label: "High Risk",
    },
    medium: {
      gradient: "from-amber-50 to-orange-50",
      color: "amber",
      border: "border-amber-200",
      iconBg: "bg-amber-500",
      progressBg: "bg-amber-500",
      label: "Medium Risk",
    },
    low: {
      gradient: "from-emerald-50 to-teal-50",
      color: "emerald",
      border: "border-emerald-200",
      iconBg: "bg-emerald-500",
      progressBg: "bg-emerald-500",
      label: "Low Risk",
    },
  };

  const config = isHighRisk
    ? riskConfig.high
    : isMediumRisk
    ? riskConfig.medium
    : riskConfig.low;

  const riskScorePercentage = (riskScore / 1000) * 100;

  return (
    <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm transition-all duration-300 hover:shadow-md">
      {/* Top status gradient banner */}
      <div
        className={`absolute top-0 inset-x-0 h-1.5 bg-gradient-to-r ${config.gradient}`}
      ></div>

      <div className="pt-5 px-5">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center space-x-3">
            <div
              className={`flex h-10 w-10 items-center justify-center rounded-full text-white ${config.iconBg}`}
            >
              <Phone className="h-5 w-5" />
            </div>
            <div>
              <h3 className="text-base font-medium text-slate-800">
                Phone Risk Analysis
              </h3>
              <p className="mt-0.5 text-sm text-slate-500">
                {formattedPhoneNumber}
              </p>
            </div>
          </div>

          <div
            className={`flex items-center px-3 py-1 rounded-full border bg-${config.color}-50 text-${config.color}-700 ${config.border} text-xs font-medium`}
          >
            <AlertCircle className="h-3.5 w-3.5 mr-1.5" />
            {config.label}
          </div>
        </div>

        <div className="mt-4 space-y-2">
          <div className="flex justify-between items-center text-xs">
            <span className="text-slate-500">Risk Score</span>
            <span className={`font-medium text-${config.color}-700`}>
              {riskScore} / 1000
            </span>
          </div>

          <div className="h-2 w-full overflow-hidden rounded-full bg-slate-100">
            <div
              className={`h-full rounded-full ${config.progressBg}`}
              style={{ width: `${riskScorePercentage}%` }}
            ></div>
          </div>

          <div className="flex justify-between text-xs text-slate-400 mt-1 px-0.5">
            <span>0</span>
            <span>500</span>
            <span>1000</span>
          </div>
        </div>

        <div className="mt-4 pt-4 border-t border-slate-100">
          <button
            className={`w-full py-2 px-3 text-xs font-medium rounded-md border bg-white hover:bg-slate-50 text-slate-700 transition-colors duration-200`}
          >
            View Full Report
          </button>
        </div>
      </div>
    </div>
  );
};

const PhoneRiskReport = ({ phoneRiskReport, showTitle = true }) => {
  const [activeTab, setActiveTab] = useState("summary");

  if (!phoneRiskReport || !phoneRiskReport.results) {
    return (
      <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm">
        <div className="absolute inset-0 bg-gradient-to-r from-slate-50 to-white opacity-50"></div>
        <div className="relative p-5">
          <div className="flex items-center space-x-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-100">
              <Phone className="h-5 w-5 text-slate-400" />
            </div>
            <div>
              <h3 className="text-base font-medium text-slate-800">
                Phone Risk Report
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                No data available for this report
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const resultsData = phoneRiskReport.results || {};
  const rawData = resultsData.rawData?.data || {};
  const attributes = rawData.attributes || {};
  const included = resultsData.rawData?.included || [];

  // Extract data from the right places in the response
  const phoneNumber = resultsData.phoneNumber || attributes["phone-number"];
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  // Map Persona risk levels to our simplified levels for UI
  let riskLevel = resultsData.phoneRiskLevel || attributes["phone-risk-level"] || "Unknown";

  // Handle compound risk levels like "medium-low" by taking the first part
  if (riskLevel.includes("-")) {
    riskLevel = riskLevel.split("-")[0];
  }

  const riskScore = resultsData.phoneRiskScore || attributes["phone-risk-score"] || 0;
  const phoneType = resultsData.phoneType || attributes["phone-type"];
  const phoneCarrier = resultsData.phoneCarrier || attributes["phone-carrier"];
  const lastReportDate = attributes["completed-at"] || attributes["created-at"];
  const formattedLastReportDate = formatDate(lastReportDate);
  const recommendation = resultsData.phoneRiskRecommendation || attributes["phone-risk-recommendation"];

  // Check if data exists in expected format
  const hasCarrierInfo = !!phoneCarrier;
  const hasLineTypeInfo = !!phoneType;
  const hasPortingInfo = false; // No porting data in this response

  // Logic for risk levels
  const isHighRisk = riskLevel.toLowerCase() === "high";
  const isMediumRisk = riskLevel.toLowerCase() === "medium";
  const isLowRisk = !isHighRisk && !isMediumRisk;

  const riskConfig = {
    high: {
      gradient: "from-rose-50 to-pink-50",
      color: "rose",
      border: "border-rose-200",
      iconBg: "bg-rose-500",
      progressBg: "bg-rose-500",
      textColor: "text-rose-700",
      bgColor: "bg-rose-50",
      icon: AlertCircle,
      label: "High Risk",
      recommendation: "High Risk: Increased Scrutiny Recommended",
    },
    medium: {
      gradient: "from-amber-50 to-orange-50",
      color: "amber",
      border: "border-amber-200",
      iconBg: "bg-amber-500",
      progressBg: "bg-amber-500",
      textColor: "text-amber-700",
      bgColor: "bg-amber-50",
      icon: AlertTriangle,
      label: "Medium Risk",
      recommendation: "Enhanced Verification Recommended",
    },
    low: {
      gradient: "from-emerald-50 to-teal-50",
      color: "emerald",
      border: "border-emerald-200",
      iconBg: "bg-emerald-500",
      progressBg: "bg-emerald-500",
      textColor: "text-emerald-700",
      bgColor: "bg-emerald-50",
      icon: CheckCircle,
      label: "Low Risk",
      recommendation: "Standard Procedures Recommended",
    },
  };

  const config = isHighRisk
    ? riskConfig.high
    : isMediumRisk
    ? riskConfig.medium
    : riskConfig.low;

  const riskScorePercentage = (riskScore / 1000) * 100;

  // Custom recommendation text based on Persona's recommendation
  let recommendationText = config.recommendation;
  if (recommendation === "allow") {
    recommendationText = "Proceed with standard verification";
  } else if (recommendation === "deny") {
    recommendationText = "Consider additional verification steps";
  } else if (recommendation === "review") {
    recommendationText = "Manual review recommended";
  }

  const renderDetailItems = (items) => {
    return items.map(
      (item, index) =>
        item && (
          <div
            key={index}
            className="py-3 border-b border-slate-100 last:border-b-0"
          >
            <div className="flex items-start justify-between">
              <div className="flex items-start">
                {item.icon && (
                  <div className="mt-0.5 mr-2.5 text-slate-400">
                    <item.icon className="h-4 w-4" />
                  </div>
                )}
                <span className="text-sm font-medium text-slate-700">
                  {item.label}
                </span>
              </div>
              <div className="text-sm text-right">
                {typeof item.value === "boolean" ? (
                  <FormatBoolean value={item.value} />
                ) : (
                  <span className="text-slate-900">
                    {item.value || <span className="text-slate-400">N/A</span>}
                  </span>
                )}
              </div>
            </div>
          </div>
        )
    );
  };

  // Build data items using available data
  const summaryItems = [
    { label: "Phone Number", value: formattedPhoneNumber, icon: Phone },
    { label: "Risk Level", value: riskLevel || "Unknown", icon: AlertCircle },
    { label: "Risk Score", value: riskScore || "N/A", icon: Activity },
    { label: "Phone Type", value: phoneType || "N/A", icon: Smartphone },
    { label: "Carrier", value: phoneCarrier || "N/A", icon: Network },
    { label: "Last Updated", value: formattedLastReportDate, icon: Calendar },
    {
      label: "Recommendation",
      value: recommendation || "N/A",
      icon: recommendation === "allow" ? CheckCircle : AlertTriangle,
    },
  ];

  const carrierItems = hasCarrierInfo
    ? [
        { label: "Carrier Name", value: phoneCarrier, icon: Network },
        { label: "Risk Assessment", value: riskLevel, icon: AlertCircle },
        {
          label: "Last Updated",
          value: formattedLastReportDate,
          icon: Calendar,
        },
      ]
    : [{ label: "Carrier Info", value: "Not Available", icon: Network }];

  const lineTypeItems = hasLineTypeInfo
    ? [
        {
          label: "Line Type",
          value: phoneType,
          icon: Smartphone,
        },
        {
          label: "Phone Verified",
          value: attributes["has-match"] === true,
          icon: CheckCircle,
        },
        {
          label: "Is Recurring",
          value: attributes["is-recurring"] === true,
          icon: RotateCw,
        },
        {
          label: "Is Continuous",
          value: attributes["is-continuous"] === true,
          icon: Infinity,
        },
      ]
    : [{ label: "Line Type Info", value: "Not Available", icon: Activity }];

  const portingItems = hasPortingInfo
    ? [
        {
          label: "Most Recent Port Date",
          value: "Not Available",
          icon: Calendar,
        },
        {
          label: "Porting Count",
          value: "Not Available",
          icon: Clock,
        },
        {
          label: "Is Ported",
          value: false,
          icon: CheckCircle,
        },
      ]
    : [{ label: "Porting Info", value: "Not Available", icon: TrendingUp }];

  return (
    <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm transition-all duration-300 hover:shadow-md">
      {/* Top status gradient banner */}
      <div
        className={`absolute top-0 inset-x-0 h-1.5 bg-gradient-to-r ${config.gradient}`}
      ></div>

      <div className="pt-6 px-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3">
          <div className="flex items-center space-x-3">
            <div
              className={`flex h-10 w-10 items-center justify-center rounded-full text-white ${config.iconBg}`}
            >
              <Phone className="h-5 w-5" />
            </div>
            <div>
              <h3 className="text-base font-semibold text-slate-800">
                Phone Risk Report
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                Analysis for{" "}
                <span className="font-medium">{formattedPhoneNumber}</span>
              </p>
            </div>
          </div>

          <div
            className={`flex items-center px-3 py-1 rounded-full border ${config.bgColor} ${config.textColor} ${config.border} text-xs font-medium`}
          >
            <config.icon className="h-3.5 w-3.5 mr-1.5" />
            {config.label}
          </div>
        </div>

        <div className="mt-6 space-y-6">
          {/* Top Section: Score and Recommendation */}
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 lg:gap-6 items-start">
            <div className="lg:col-span-3 rounded-lg p-4 space-y-3 bg-slate-50/70 border border-slate-100">
              <p className="text-sm font-medium text-slate-700">
                Risk Score Assessment
              </p>
              <div className="flex items-center space-x-3">
                <div className="flex-1">
                  <div className="h-2.5 w-full overflow-hidden rounded-full bg-slate-100">
                    <div
                      className={`h-full rounded-full ${config.progressBg}`}
                      style={{ width: `${riskScorePercentage}%` }}
                    ></div>
                  </div>
                  <div className="flex justify-between text-xs text-slate-500 mt-1.5">
                    <span>Low (0-399)</span>
                    <span>Medium (400-699)</span>
                    <span>High (700+)</span>
                  </div>
                </div>
                <div className="text-xl font-bold text-slate-800 whitespace-nowrap">
                  {riskScore}
                  <span className="text-sm font-normal text-slate-500 ml-0.5">
                    /1000
                  </span>
                </div>
              </div>
            </div>

            <div className="lg:col-span-2">
              <div
                className={`rounded-lg border p-4 ${config.bgColor} ${config.border}`}
              >
                <div className="flex items-center">
                  <config.icon className={`h-4 w-4 ${config.textColor}`} />
                  <p className={`ml-2 font-medium ${config.textColor}`}>
                    Recommendation
                  </p>
                </div>
                <p className={`mt-1.5 text-sm ${config.textColor}`}>
                  {recommendationText}
                </p>
              </div>
            </div>
          </div>

          <div className="border-t border-slate-100 -mx-6 my-4"></div>

          {/* Tabs for Detailed Information */}
          <div className="space-y-4">
            <nav className="flex space-x-1 overflow-x-auto pb-1">
              <button
                onClick={() => setActiveTab("summary")}
                className={`px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                  activeTab === "summary"
                    ? "bg-slate-100 text-slate-900"
                    : "text-slate-600 hover:text-slate-900 hover:bg-slate-50"
                }`}
              >
                Summary
              </button>
              <button
                onClick={() => setActiveTab("carrier")}
                className={`px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                  activeTab === "carrier"
                    ? "bg-slate-100 text-slate-900"
                    : "text-slate-600 hover:text-slate-900 hover:bg-slate-50"
                }`}
              >
                Carrier
              </button>
              <button
                onClick={() => setActiveTab("lineType")}
                className={`px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                  activeTab === "lineType"
                    ? "bg-slate-100 text-slate-900"
                    : "text-slate-600 hover:text-slate-900 hover:bg-slate-50"
                }`}
              >
                Line Type
              </button>
              <button
                onClick={() => setActiveTab("porting")}
                className={`px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                  activeTab === "porting"
                    ? "bg-slate-100 text-slate-900"
                    : "text-slate-600 hover:text-slate-900 hover:bg-slate-50"
                }`}
              >
                Porting
              </button>
            </nav>

            {/* Tab Content */}
            <div className="rounded-lg border border-slate-100 bg-slate-50/70 p-4">
              {activeTab === "summary" && (
                <div>
                  <h4 className="text-sm font-medium text-slate-500 flex items-center mb-3">
                    <Smartphone className="mr-2 h-4 w-4 text-slate-400" />
                    Phone Number Overview
                  </h4>
                  <div className="rounded-lg bg-white border border-slate-100 divide-y divide-slate-100">
                    {renderDetailItems(summaryItems)}
                  </div>
                </div>
              )}

              {activeTab === "carrier" && (
                <div>
                  <h4 className="text-sm font-medium text-slate-500 flex items-center mb-3">
                    <Network className="mr-2 h-4 w-4 text-slate-400" />
                    Carrier Details
                  </h4>
                  <div className="rounded-lg bg-white border border-slate-100 divide-y divide-slate-100">
                    {renderDetailItems(carrierItems)}
                  </div>
                </div>
              )}

              {activeTab === "lineType" && (
                <div>
                  <h4 className="text-sm font-medium text-slate-500 flex items-center mb-3">
                    <Activity className="mr-2 h-4 w-4 text-slate-400" />
                    Line Type Information
                  </h4>
                  <div className="rounded-lg bg-white border border-slate-100 divide-y divide-slate-100">
                    {renderDetailItems(lineTypeItems)}
                  </div>
                </div>
              )}

              {activeTab === "porting" && (
                <div>
                  <h4 className="text-sm font-medium text-slate-500 flex items-center mb-3">
                    <TrendingUp className="mr-2 h-4 w-4 text-slate-400" />
                    Porting Details
                  </h4>
                  <div className="rounded-lg bg-white border border-slate-100 divide-y divide-slate-100">
                    {renderDetailItems(portingItems)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PhoneRiskReport, PhoneRiskSummaryCard };
