// @ts-nocheck
import React, { useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useCarrierVetting } from "../../../hooks/Vetting/useCarrierVetting";
import { useAuth } from "../../../providers/AuthProvider";
import { validateDOT, validateEmail, validatePhone } from "../../../lib/utils";

// Import shadcn components
import { Form } from "@/components/ui/form";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Progress } from "@/components/ui/progress";
import { Separator } from "@/components/ui/separator";
import { FormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage } from "@/components/ui/form";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { CheckCircle, XCircle, AlertCircle, Loader2 } from "lucide-react";

// Validation schema using zod
const carrierSchema = z.object({
  business_name: z.string().min(1, "Business name is required"),
  dot_number: z.string().refine(val => validateDOT(val), {
    message: "Please enter a valid DOT number",
  }),
  email: z.string().email("Please enter a valid email").optional().or(z.literal("")),
  phone_number: z.string().refine(val => !val || validatePhone(val), {
    message: "Please enter a valid phone number",
  }).optional().or(z.literal("")),
  website: z.string().optional().or(z.literal("")),
  ein: z.string().refine(val => !val || val.replace(/\D/g, "").length === 9, {
    message: "EIN should be 9 digits",
  }).optional().or(z.literal("")),
  street_1: z.string().optional().or(z.literal("")),
  street_2: z.string().optional().or(z.literal("")),
  city: z.string().optional().or(z.literal("")),
  subdivision: z.string().optional().or(z.literal("")),
  postal_code: z.string().optional().or(z.literal("")),
  country_code: z.string().min(1, "Country code is required when providing address information").optional().or(z.literal("")),
});

const VetCarrier = () => {
  const { state } = useLocation();
  const { customer } = useAuth();
  const navigate = useNavigate();
  
  // Add state for tracking verification step
  const [isVerificationComplete, setIsVerificationComplete] = useState(false);
  const [verifiedCarrierId, setVerifiedCarrierId] = useState(null);

  // Set up form with validation
  const form = useForm({
    resolver: zodResolver(carrierSchema),
    defaultValues: {
      business_name: state?.business_name || "",
      email: state?.email || "",
      dot_number: state?.dot_number || "",
      phone_number: state?.phone_number || "",
      website: state?.website || "",
      ein: state?.ein || "",
      street_1: state?.street_1 || "",
      street_2: state?.street_2 || "",
      city: state?.city || "",
      subdivision: state?.subdivision || "",
      postal_code: state?.postal_code || "",
      country_code: state?.country_code || "US",
    },
  });

  const {
    vetCarrier,
    carrierData,
    personaResults,
    isLoading,
    error,
    message,
    loadingSteps,
    currentOperation,
  } = useCarrierVetting();

  // Verification steps
  const steps = [
    { title: "Submit Data", description: "Fill out the form above" },
    { title: "FMCSA Verification", description: "Pulling carrier data" },
    {
      title: "Business Verification",
      description: "Validating business information",
    },
    {
      title: "Fraud Checks",
      description:
        currentOperation ||
        (personaResults?.partialSuccess && personaResults?.readyCount > 0
          ? `Completed ${personaResults?.readyCount}/${personaResults?.totalCount} checks`
          : "Verifying contact details"),
    },
  ];

  // Function to handle EIN formatting
  const formatEIN = (value) => {
    // Remove all non-digits
    const digitsOnly = value.replace(/\D/g, "");

    // Format as XX-XXXXXXX if we have enough digits
    let formattedValue = digitsOnly;
    if (digitsOnly.length > 2) {
      formattedValue = `${digitsOnly.substring(0, 2)}-${digitsOnly.substring(
        2,
        9
      )}`;
    }
    
    return formattedValue;
  };

  // Function to check if any address field is filled
  const hasPartialAddress = () => {
    const values = form.getValues();
    return (
      values.street_1 ||
      values.street_2 ||
      values.city ||
      values.subdivision ||
      values.postal_code
    );
  };

  const getCurrentStep = () => {
    if (loadingSteps?.fraudChecks) return 3;
    if (loadingSteps?.businessVerification) return 2;
    if (loadingSteps?.fmcsaVerification) return 1;
    if (loadingSteps?.submitData) return 0;
    return 0;
  };

  // This tells us if the fraud checks step is in-progress but not complete
  const isFraudChecksInProgress = () => {
    return (
      (currentOperation &&
        currentOperation.includes("fraud checks") &&
        !loadingSteps?.fraudChecks) ||
      // Also consider partial success as a special state
      (personaResults?.partialSuccess &&
        personaResults?.readyCount > 0 &&
        personaResults?.readyCount < personaResults?.totalCount)
    );
  };

  // Function to determine if fraud checks have at least partial success
  const hasFraudChecksPartialSuccess = () => {
    return (
      personaResults?.partialSuccess &&
      personaResults?.readyCount > 0 &&
      personaResults?.readyCount < personaResults?.totalCount
    );
  };

  // Function to determine if fraud checks are complete (either fully or partially)
  const isFraudChecksComplete = () => {
    return (
      isVerificationComplete ||
      loadingSteps?.fraudChecks ||
      personaResults?.success ||
      hasFraudChecksPartialSuccess()
    );
  };

  const onSubmit = async (data) => {
    // Country code is required when providing address information
    if (hasPartialAddress() && !data.country_code) {
      form.setError("country_code", {
        type: "manual",
        message: "Country Code is required when providing an address"
      });
      return;
    }

    try {
      // Format EIN to ensure it's clean (remove any non-digit characters) if provided
      const formattedEin = data.ein ? data.ein.replace(/\D/g, "") : undefined;

      // Prepare business fields for transaction verification
      const business_fields = {
        business_name: data.business_name,
        phone_number: data.phone_number || "",
        email: data.email || "",
        website: data.website || "",
        ein: formattedEin || "", 
        business_registered_address: {
          street_1: data.street_1 || "",
          street_2: data.street_2 || "",
          city: data.city || "",
          subdivision: data.subdivision || "",
          postal_code: data.postal_code || "",
          country_code: data.country_code || "US",
        },
        carrier_id: state?.carrierId || "",
      };

      // Pass the enhanced data to the vetting function
      const formDataWithBusinessFields = {
        ...data,
        business_fields,
        company_id: customer?.id,
      };

      const result = await vetCarrier(formDataWithBusinessFields);
      
      if (result && result.carrierData) {
        setVerifiedCarrierId(result.carrierData.id);
        setIsVerificationComplete(true);
      } else {
        console.error("Incomplete result returned from vetCarrier:", result);
        form.setError("root", {
          type: "manual",
          message: "Verification completed but carrier data is missing. Please try again."
        });
      }
    } catch (error) {
      console.error("Error during submission:", error);
      form.setError("root", {
        type: "manual",
        message: error.message
      });
    }
  };

  const handleViewVerification = () => {
    navigate(`/dashboard/vet-carrier/${verifiedCarrierId}`);
  };

  return (
    <div className="container mx-auto p-4 sm:p-6 lg:p-8 space-y-6">
      <div className="flex flex-col gap-4">
        <Card className="w-full">
          <CardHeader>
            <CardTitle>Vet Carrier</CardTitle>
            <CardDescription>
              Complete the form below to run a vetting process on the carrier.
            </CardDescription>
          </CardHeader>
          
          <CardContent>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                {/* Basic Information Section */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium">Basic Information</h3>
                  <Separator />
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="business_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Legal Business Name *</FormLabel>
                          <FormControl>
                            <Input placeholder="Trucking Logistics, Inc." {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="dot_number"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>DOT Number *</FormLabel>
                          <FormControl>
                            <Input placeholder="1234567" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email (optional)</FormLabel>
                          <FormControl>
                            <Input placeholder="info@truckinglogistics.com" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="phone_number"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Phone Number (optional)</FormLabel>
                          <FormControl>
                            <Input placeholder="(123) 456-7890" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="website"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Website (optional)</FormLabel>
                          <FormControl>
                            <Input placeholder="www.truckinglogistics.com" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="ein"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>EIN/Tax ID (Optional but Recommended)</FormLabel>
                          <FormControl>
                            <Input 
                              placeholder="12-3456789" 
                              {...field} 
                              onChange={(e) => {
                                const formatted = formatEIN(e.target.value);
                                field.onChange(formatted);
                              }}
                            />
                          </FormControl>
                          <FormDescription>Providing an EIN improves verification accuracy but is not required</FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                
                {/* Business Address Section */}
                <div className="space-y-4">
                  <h3 className="text-lg font-medium">Business Registered Address</h3>
                  <Separator />
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="street_1"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Street Address</FormLabel>
                          <FormControl>
                            <Input placeholder="123 Main St" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="street_2"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Suite/Apt (optional)</FormLabel>
                          <FormControl>
                            <Input placeholder="Suite 100" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="city"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>City</FormLabel>
                          <FormControl>
                            <Input placeholder="New York" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="subdivision"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>State/Province</FormLabel>
                          <FormControl>
                            <Input placeholder="NY" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="postal_code"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Zip/Postal Code</FormLabel>
                          <FormControl>
                            <Input placeholder="10001" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="country_code"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Country Code {hasPartialAddress() && "*"}</FormLabel>
                          <FormControl>
                            <Input placeholder="US" {...field} />
                          </FormControl>
                          {hasPartialAddress() && (
                            <FormDescription>Required when providing address information</FormDescription>
                          )}
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                
                {/* Verification Progress Section */}
                <div className="space-y-4 mt-6 bg-slate-50 p-6 rounded-lg border border-slate-200">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-medium">Verification Progress</h3>
                    <span className="text-sm text-slate-600">
                      {getCurrentStep() + 1} of {steps.length}
                    </span>
                  </div>
                  
                  <div className="space-y-6">
                    {/* Progress bar */}
                    <Progress 
                      value={(getCurrentStep() + 1) * (100 / steps.length)} 
                      className="h-2 w-full"
                    />
                    
                    {/* Step indicators */}
                    <div className="grid grid-cols-4 gap-2">
                      {steps.map((step, index) => (
                        <div 
                          key={index} 
                          className={`flex flex-col items-center text-center ${
                            index < 3 && index <= getCurrentStep()
                              ? "text-slate-900"
                              : index === 3 && isFraudChecksComplete()
                              ? "text-slate-900"
                              : index === 3 && isFraudChecksInProgress()
                              ? "text-amber-600"
                              : index === 3 && hasFraudChecksPartialSuccess()
                              ? "text-indigo-600"
                              : "text-slate-400"
                          }`}
                        >
                          <div 
                            className={`w-8 h-8 rounded-full flex items-center justify-center mb-2 ${
                              index < getCurrentStep()
                                ? "bg-slate-900 text-white"
                                : index === getCurrentStep()
                                ? "bg-slate-200 text-slate-500"
                                : index === 3 && isFraudChecksComplete()
                                ? "bg-slate-900 text-white"
                                : index === 3 && isFraudChecksInProgress()
                                ? "bg-amber-400 text-white animate-pulse"
                                : index === 3 && hasFraudChecksPartialSuccess()
                                ? "bg-indigo-500 text-white"
                                : "bg-slate-200 text-slate-500"
                            }`}
                          >
                            {index < getCurrentStep() ? (
                              <CheckCircle className="w-4 h-4" />
                            ) : index === getCurrentStep() ? (
                              isLoading ? <Loader2 className="w-4 h-4 animate-spin" /> : index + 1
                            ) : (
                              index + 1
                            )}
                          </div>
                          <span className="text-sm font-medium">{step.title}</span>
                          <span className="text-xs mt-1">{step.description}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                
                {/* Success Message Section */}
                {message && (
                  <Alert 
                    className={`animate-fadeIn ${
                      hasFraudChecksPartialSuccess() ? "bg-indigo-50 border-indigo-200" : "bg-green-50 border-green-200"
                    }`}
                  >
                    <div className="flex items-center">
                      <CheckCircle className={`w-5 h-5 mr-2 ${
                        hasFraudChecksPartialSuccess() ? "text-indigo-500" : "text-green-500"
                      }`} />
                      <div>
                        <AlertTitle className={`${
                          hasFraudChecksPartialSuccess() ? "text-indigo-700" : "text-green-700"
                        } font-medium`}>
                          {message}
                        </AlertTitle>
                        <AlertDescription className={`${
                          hasFraudChecksPartialSuccess() ? "text-indigo-600" : "text-green-600"
                        } text-sm`}>
                          {hasFraudChecksPartialSuccess()
                            ? `${personaResults?.readyCount} of ${personaResults?.totalCount} checks completed successfully`
                            : "Your carrier verification was successful"}
                        </AlertDescription>
                      </div>
                    </div>
                  </Alert>
                )}
                
                {/* Form Error Message */}
                {form.formState.errors.root && (
                  <Alert variant="destructive">
                    <AlertCircle className="w-4 h-4 mr-2" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>
                      {form.formState.errors.root.message}
                    </AlertDescription>
                  </Alert>
                )}
                
                {/* Submit Button Section */}
                <div className="pt-4">
                  {isVerificationComplete ? (
                    <Button
                      type="button"
                      onClick={handleViewVerification}
                      className="w-full bg-green-600 hover:bg-green-700"
                    >
                      View Verification
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      disabled={isLoading}
                      className="w-full"
                    >
                      {isLoading ? (
                        <>
                          <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                          Processing...
                        </>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  )}
                </div>
              </form>
            </Form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default VetCarrier;
