// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useVerification,
  useVerificationMutations,
} from "../../hooks/useVerifications";
import { useShipment, useShipmentMutations } from "../../hooks/useShipments";
import { useQueryClient } from "@tanstack/react-query";
import { supabase } from "../../lib/supabase";
import FlowComponent from "../VerificationFlow/FlowComponent";
import PhotoCapture from "../Components/PhotoCapture";
import OCRResultDisplay from "../VerificationFlow/OCRResultDisplay";
import SuccessPage from "../Components/SuccessPage";
import FailurePage from "../Components/FailurePage";
import LoadingSpinner from "../Components/LoadingSpinner";
import createPersonaClient from "../../lib/persona";
import { QRCodeSVG } from "qrcode.react";
import OCRService from "../../api/services/core/OCRService";
import {
  checkVerificationStatus,
  getVerificationStatus,
} from "../../api/services/verification/VerificationService";

const IdentityVerification = () => {
  const { shipmentId } = useParams();
  console.log(
    `[IdentityVerification] Extracted shipmentId from URL: "${shipmentId}"`
  );
  console.log(`[IdentityVerification] shipmentId type: ${typeof shipmentId}`);
  console.log(
    `[IdentityVerification] shipmentId length: ${shipmentId?.length}`
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [capturedFile, setCapturedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const [completionMessage, setCompletionMessage] = useState("");
  const [isPersonaLoading, setIsPersonaLoading] = useState(false);
  const [isOCRLoading, setIsOCRLoading] = useState(false);
  const [verificationError, setVerificationError] = useState(null);
  const steps = [
    "Identity Verification",
    "Capture Photo",
    "OCR Processing",
    "Complete",
  ];

  // Use the existing hooks
  const { data: shipment } = useShipment(shipmentId);
  const {
    data: verification,
    isLoading: isVerificationLoading,
    refetch: refetchVerification,
  } = useVerification(shipmentId);
  console.log(shipmentId);
  console.log(verification);
  const { updateVerification, processOCR } = useVerificationMutations();
  const { updateShipmentStatus } = useShipmentMutations();
  const queryClient = useQueryClient();

  // Clear error message if verification data loads successfully
  useEffect(() => {
    if (verification) {
      setVerificationError(null);
    }
  }, [verification]);

  const handleNext = () => {
    if (currentStep < steps.length) {
      console.log("Current step:", currentStep);
      setCurrentStep(currentStep + 1);
    } else {
      console.log("Flow completed!");
      handleFlowComplete();
    }
  };

  const generatePinCode = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  const handleFlowComplete = async () => {
    console.log("Handling flow completion for verification:", verification?.id);
    console.log("Verification object:", verification);
    console.log("Shipment ID:", shipmentId);

    // Track completion status
    let verificationUpdated = false;
    let shipmentUpdated = false;

    // Step 1: Update verification status
    updateVerification.mutate(
      { shipmentId, data: { state: "Complete" } },
      {
        onSuccess: () => {
          console.log("Verification status updated successfully");
          verificationUpdated = true;
          checkCompletion();
        },
        onError: (error) => {
          console.error("Error updating verification:", error);
          setCompletionMessage(
            "An error occurred while completing the verification."
          );
        },
      }
    );

    // Step 2: Update shipment status
    updateShipmentStatus.mutate(
      { id: shipmentId, status: "Verified" },
      {
        onSuccess: () => {
          console.log("Shipment status updated successfully");
          shipmentUpdated = true;
          checkCompletion();
        },
        onError: (error) => {
          console.error("Error updating shipment status:", error);
          setCompletionMessage(
            "An error occurred while updating the shipment status."
          );
        },
      }
    );

    // Step 3: Check if both updates completed and update PIN code
    const checkCompletion = async () => {
      console.log(
        "checkCompletion called. Verification updated:",
        verificationUpdated,
        "Shipment updated:",
        shipmentUpdated
      );

      if (verificationUpdated && shipmentUpdated) {
        console.log("Both verification and shipment updates completed");

        try {
          // First, refetch the verification data to ensure we have the latest state
          console.log("Refetching verification data...");
          await refetchVerification();

          // Wait a moment to ensure the data is refreshed
          await new Promise((resolve) => setTimeout(resolve, 500));

          // Get the latest verification data
          const latestVerification = await getVerificationStatus(shipmentId);
          console.log("Latest verification data:", latestVerification);

          // Generate a PIN code
          const pinCode = generatePinCode();
          console.log("Generated PIN code:", pinCode);

          if (!latestVerification?.id) {
            console.error("Missing verification ID, cannot update PIN code");
            setCompletionMessage(
              "Verification completed, but there was an issue generating the PIN code (missing ID)."
            );
            return;
          }

          // Use direct Supabase access for updating the PIN code
          // This bypasses any service layer issues
          const { data, error } = await supabase
            .from("verifications")
            .update({
              pin_code: pinCode,
              updated_at: new Date().toISOString(),
            })
            .eq("id", latestVerification.id)
            .select()
            .single();

          if (error) {
            console.error(
              "Error updating PIN code via direct Supabase access:",
              error
            );
            throw error;
          }

          console.log("PIN code update result:", data);

          if (data && data.pin_code === pinCode) {
            console.log("PIN code successfully updated:", pinCode);

            // Refresh verification data to reflect the changes
            refetchVerification();

            // Set completion message with PIN code
            setCompletionMessage({
              pin: pinCode,
              verificationId: latestVerification.id,
              shipmentId: shipmentId,
            });
          } else {
            console.warn("PIN code may not have been saved correctly");
            console.warn("Data returned:", data);
            setCompletionMessage(
              "Verification completed, but there was an issue generating the PIN code."
            );
          }
        } catch (error) {
          console.error("Error in PIN code update process:", error);
          setCompletionMessage(
            "Verification completed, but there was an error generating the PIN code."
          );
        }
      }
    };
  };

  const handlePersonaVerification = async () => {
    console.log("Triggering Persona Verification");
    console.log("Verification:", verification?.id);
    console.log("Verification info:", {
      inquiryId: verification?.persona_inquiry_id,
      status: verification?.state,
    });

    const template_id = process.env.REACT_APP_PERSONA_TEMPLATE_ID_GOVERNMENT_ID;

    if (!template_id) {
      console.error("Persona Template ID is required");
      setStatusMessage("Configuration error: missing template ID");
      return null;
    }

    setIsPersonaLoading(true);
    setStatusMessage("Initializing verification...");

    try {
      // Determine if we have an existing inquiry ID to continue
      const existingInquiryId = verification?.persona_inquiry_id || null;

      if (existingInquiryId) {
        console.log(`Using existing inquiry ID: ${existingInquiryId}`);
        setStatusMessage("Continuing existing verification session...");
      } else {
        console.log("Starting new verification session");
        setStatusMessage("Starting new verification session...");
      }

      // First, ensure the verification record is properly initialized
      try {
        await updateVerification.mutateAsync({
          shipmentId,
          data: {
            state: "Started",
            updated_at: new Date().toISOString(),
          },
        });
        console.log("Verification record initialized before starting Persona");
      } catch (updateError) {
        console.error(
          "Warning: Could not initialize verification record:",
          updateError
        );
        // Continue anyway as this is not a critical error
      }

      console.log("Triggering persona client");
      createPersonaClient(
        verification.persona_inquiry_id,
        template_id,
        async (data) => {
          try {
            console.log(
              "Persona verification completed, updating verification status",
              data
            );

            // Store the inquiry ID returned from Persona to ensure we use it for future operations
            const inquiryIdToUse = data?.inquiryId || existingInquiryId;

            if (!inquiryIdToUse) {
              console.error("No inquiry ID available after Persona completion");
              setStatusMessage(
                "Verification completed but no ID was returned. Please check status."
              );
              setIsPersonaLoading(false);
              return;
            }

            console.log(
              `Updating verification with inquiry ID: ${inquiryIdToUse}`
            );

            await updateVerification.mutateAsync({
              shipmentId,
              data: {
                persona_verification_complete: true,
                state: "pending",
                // Update persona_inquiry_id if we got a new one from Persona
                ...(data?.inquiryId &&
                  data.inquiryId !== verification?.persona_inquiry_id && {
                    persona_inquiry_id: data.inquiryId,
                  }),
              },
            });

            console.log("Verification updated successfully.");
            setStatusMessage("Verification completed. Checking status...");

            // Fetch the latest verification data
            await refetchVerification();

            // Automatically check status after completion
            await handleCheckStatus();
          } catch (error) {
            console.error("Error updating verification:", error);
            setStatusMessage(`Error: ${error.message}`);
          } finally {
            setIsPersonaLoading(false);
          }
        },
        existingInquiryId // Pass existing inquiry ID if available
      );
    } catch (error) {
      console.error("Error creating Persona client:", error);
      // Provide more specific guidance based on the error message
      if (
        error.message?.includes("Only one of templateId") ||
        error.message?.includes("should be specified to start a flow")
      ) {
        setStatusMessage(
          "Configuration error: There's a conflict between your existing verification and starting a new one. Please try refreshing the page."
        );
      } else {
        setStatusMessage(
          `Failed to start verification process: ${error.message}`
        );
      }
      setIsPersonaLoading(false);
    }
  };

  const handleImageCapture = async (file) => {
    setCapturedFile(file);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreviewUrl(previewUrl);

      try {
        const signedUrl = await OCRService.uploadImage(file, shipmentId);
        setUploadedImageUrl(signedUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleOCRProcessing = async () => {
    setIsOCRLoading(true);
    setStatusMessage(""); // Clear previous messages

    try {
      if (!shipmentId) {
        console.error("Missing shipment ID from URL parameters");
        setStatusMessage("Error: Missing shipment ID. Please check the URL.");
        setIsOCRLoading(false);
        return;
      }

      if (!capturedFile) {
        setStatusMessage("Please capture a document photo first.");
        setIsOCRLoading(false);
        return;
      }

      console.log(`Starting OCR processing for shipment ID: ${shipmentId}`);
      console.log("File type:", capturedFile.type);
      console.log("File size:", Math.round(capturedFile.size / 1024), "KB");
      console.log("Verification status before OCR:", verification);

      // Show processing status to the user
      setStatusMessage("Uploading and processing your document...");

      try {
        // Use the processOCR mutation which handles upload and backend processing
        const ocrResult = await processOCR.mutateAsync({
          shipmentId: shipmentId,
          file: capturedFile, // Pass the captured file object
        });

        // If mutateAsync succeeds, it means the backend processed it (including update)
        console.log("OCR process mutation completed successfully", ocrResult);

        // Success! Let's refetch and continue.
        await refetchVerification();

        // Check verification status
        const updatedVerification = await getVerificationStatus(shipmentId);
        console.log("Verification status after OCR:", updatedVerification);

        if (updatedVerification?.ocr_verification_complete === true) {
          setStatusMessage(
            "Document processed successfully! Matching shipment ID found. Moving to next step..."
          );
          // Short delay to allow user to see success message
          setTimeout(() => {
            handleNext(); // Move to the next step
          }, 1500);
          return;
        } else {
          // OCR completed but validation failed
          setStatusMessage(
            "Document verification failed. The shipment ID was not found in the document. Please ensure you're using the correct document."
          );
        }
      } catch (error) {
        console.error("Error during OCR processing:", error);

        // Determine error type and provide helpful message
        if (error.message?.includes("OCR VALIDATION FAILED")) {
          setStatusMessage(
            "Verification failed: The document doesn't contain the shipment ID. Please ensure you're using the correct document with the shipment ID clearly visible."
          );
        } else if (error.message?.includes("upload")) {
          setStatusMessage(
            "Error: Failed to upload the document image. Please try again with a smaller image file."
          );
        } else if (error.message?.includes("too large")) {
          setStatusMessage(
            "Error: The image file is too large. Please try taking the photo again at a lower resolution."
          );
        } else if (error.message?.includes("bucket")) {
          setStatusMessage(
            "Error: Storage configuration issue. Please contact support with error code: BUCKET-MISSING"
          );
        } else {
          // Generic error with the original message
          setStatusMessage(
            `Error processing document: ${
              error.message || "Unknown error. Please try again."
            }`
          );
        }

        // For debugging - log more details about the error
        console.log("Error details:", {
          name: error.name,
          message: error.message,
          stack: error.stack,
        });
      }

      // Always refetch verification data to keep UI in sync
      await refetchVerification();
    } catch (error) {
      console.error("Unexpected error in OCR processing:", error);
      setStatusMessage(
        `Unexpected error: ${
          error.message || "Unknown error. Please try again or contact support."
        }`
      );
    } finally {
      setIsOCRLoading(false);
    }
  };

  const handleCheckStatus = async () => {
    console.log("Checking verification status for shipment:", shipmentId);
    setIsCheckingStatus(true);
    setStatusMessage("Checking verification status...");

    try {
      // Ensure we have verification data
      if (!verification) {
        await refetchVerification();

        if (!verification) {
          console.log("No verification data found even after refetch");
          setStatusMessage(
            "No verification data found. Please check back later."
          );
          setIsCheckingStatus(false);
          return;
        }
      }

      console.log("Verification data:", verification);
      console.log("Using shipment ID:", shipmentId);
      console.log(
        "Using persona inquiry ID:",
        verification?.persona_inquiry_id
      );

      if (!verification.persona_inquiry_id) {
        console.log("Missing inquiry ID");
        setStatusMessage("Missing inquiry ID. Please try again later.");
        setIsCheckingStatus(false);
        return;
      }

      // Check the actual verification status from Persona
      try {
        const statusResult = await checkVerificationStatus(
          verification.persona_inquiry_id
        );
        console.log("Status check result:", statusResult);

        // Prepare update data with valid structure
        let updateData = {
          updated_at: new Date().toISOString(), // Always include updated_at
        };

        if (statusResult.success) {
          // Verification is approved
          console.log("Verification approved, updating status");
          updateData.persona_verification_complete = true;
          updateData.state = "persona_complete";
          setStatusMessage("Verification approved successfully!");
        } else if (statusResult.status === "Declined") {
          // Verification was declined
          console.log("Verification declined");
          updateData.persona_verification_complete = false;
          updateData.state = "Declined";
          setStatusMessage(
            "Verification was declined. Please contact support."
          );
        } else if (
          statusResult.status === "Started" ||
          statusResult.status === "pending"
        ) {
          // Verification is still in progress
          console.log("Verification in progress");
          updateData.persona_verification_complete = false;
          updateData.state = "Started";
          setStatusMessage(
            "Verification is still in progress. Please check back later."
          );
        } else {
          // Other status
          console.log(`Verification status: ${statusResult.status}`);
          updateData.persona_verification_complete = false;
          updateData.state = statusResult.status || "pending";
          setStatusMessage(
            `Current status: ${statusResult.status || "pending"}. ${
              statusResult.message || ""
            }`
          );
        }

        console.log("Updating verification with data:", updateData);

        try {
          // Update the verification data in the database
          const result = await updateVerification.mutateAsync({
            shipmentId,
            data: updateData,
          });
          console.log("Update result:", result);
        } catch (updateError) {
          console.error("Error updating verification:", updateError);
          // Show specific error message based on the error type
          if (updateError.message?.includes("not found")) {
            setStatusMessage(
              "Verification record not found. Please check the shipment ID."
            );
          } else {
            setStatusMessage(`Error updating status: ${updateError.message}`);
          }
        }
      } catch (statusError) {
        console.error("Error checking status via API:", statusError);

        // Handle the specific error about multiple rows
        if (statusError.message?.includes("multiple (or no) rows returned")) {
          setStatusMessage(
            "Found multiple verification records. Please contact support to resolve this issue."
          );
        } else {
          setStatusMessage(
            "Unable to check verification status. Please try again later."
          );
        }
      }

      // Always refetch to update UI with latest data
      await refetchVerification();
    } catch (error) {
      console.error("Error in handleCheckStatus:", error);
      setStatusMessage(
        "Error checking status: " + (error.message || "Unknown error")
      );
    } finally {
      setIsCheckingStatus(false);
    }
  };

  const renderContent = () => {
    if (isVerificationLoading) {
      return <LoadingSpinner />;
    }

    if (isPersonaLoading) {
      return <LoadingSpinner />;
    }

    if (isOCRLoading) {
      return <LoadingSpinner />;
    }

    // Handle verification not found state
    if (!verification && !isVerificationLoading) {
      return (
        <div className="space-y-4 w-full mb-24">
          <div className="p-4 border border-red-200 bg-red-50 rounded-md">
            <h3 className="text-lg font-semibold text-red-700">
              Verification Not Found
            </h3>
            <p className="text-red-600 mt-2">
              No verification record was found for shipment ID: {shipmentId}
            </p>
            <p className="text-gray-600 mt-4">
              Please check if the shipment ID is correct and try again later.
            </p>
          </div>
        </div>
      );
    }

    if (completionMessage) {
      return (
        <div className="w-full rounded-lg border border-green-200 bg-green-50 p-4 mb-24">
          <div className="flex flex-col items-center space-y-4">
            <p className="text-green-700 font-medium">
              All verifications are complete. Please share the QR code with the
              warehouse representative.
            </p>

            <div className="bg-white p-4 rounded-lg">
              <QRCodeSVG
                value={JSON.stringify({
                  pin: completionMessage.pin,
                  verificationId: completionMessage.verificationId,
                  shipmentId: completionMessage.shipmentId,
                  timestamp: new Date().toISOString(),
                })}
                size={200}
                level="H"
                includeMargin={true}
              />
            </div>

            <p className="text-sm text-gray-600">
              Scan this QR code to verify the shipment details
            </p>
          </div>
        </div>
      );
    }

    if (verification?.isComplete) {
      return verification.personaSuccess && verification.ocrSuccess ? (
        <SuccessPage />
      ) : (
        <FailurePage
          personaFailed={!verification.personaSuccess}
          ocrFailed={!verification.ocrSuccess}
        />
      );
    }

    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-4 w-full mb-24">
            <h3 className="text-lg font-semibold">
              Step 1: Identity Verification
            </h3>
            <button
              className="w-full px-4 py-2 bg-blue-500 text-white rounded-md text-sm font-medium font-['Sharp Sans Display No1'] leading-normal mb-2 active:bg-blue-600 transition-colors duration-150"
              onClick={handleCheckStatus}
              disabled={
                isCheckingStatus || verification?.persona_verification_complete
              }
            >
              {isCheckingStatus
                ? "Checking Status..."
                : verification?.persona_verification_complete
                ? "Verification Complete"
                : "Check Verification Status"}
            </button>
            {statusMessage && (
              <p
                className={`text-sm mt-2 ${
                  statusMessage.includes("Error")
                    ? "text-red-600"
                    : "text-gray-600"
                }`}
              >
                {statusMessage}
              </p>
            )}
            <button
              className="w-full px-4 py-2 bg-slate-300 rounded-md text-slate-900 text-sm font-medium font-['Sharp Sans Display No1'] leading-normal active:bg-slate-400 transition-colors duration-150"
              onClick={handlePersonaVerification}
              disabled={verification?.persona_verification_complete}
            >
              {verification?.persona_verification_complete
                ? "Verification Complete"
                : "Start Persona Verification"}
            </button>
          </div>
        );
      case 2:
        return (
          <div className="space-y-4 w-full mb-24">
            <h3 className="text-lg font-semibold">
              Step 2: Capture Document Photo
            </h3>
            <PhotoCapture
              label="Capture Document"
              photo={capturedFile}
              setPhoto={handleImageCapture}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
            />
            {capturedFile && (
              <button
                onClick={() => {
                  setCapturedFile(null);
                  setPreviewUrl(null);
                  setUploadedImageUrl(null);
                }}
                className="bg-red-500 text-white px-4 py-2 rounded-md mt-2 active:bg-red-600 transition-colors duration-150"
              >
                Retry Capture
              </button>
            )}
            <p>Capture the document photo for verification.</p>
          </div>
        );
      case 3:
        return (
          <div className="space-y-4 w-full mb-24">
            <h3 className="text-lg font-semibold">
              Step 3: Process the document for verification
            </h3>
            <button
              className="w-full px-4 py-2 bg-slate-300 rounded-md text-slate-900 text-sm font-medium font-['Sharp Sans Display No1'] leading-normal active:bg-slate-400 transition-colors duration-150"
              onClick={handleOCRProcessing}
              disabled={!capturedFile || isOCRLoading}
            >
              {isOCRLoading ? "Processing..." : "Start OCR Processing"}
            </button>

            {statusMessage && (
              <p
                className={`text-sm mt-2 ${
                  statusMessage.includes("Error")
                    ? "text-red-600"
                    : statusMessage.includes("success")
                    ? "text-green-600"
                    : "text-gray-600"
                }`}
              >
                {statusMessage}
              </p>
            )}
          </div>
        );
      case 4:
        return (
          <div className="w-full rounded-lg border border-slate-200 p-4 space-y-4 mb-24">
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium">
                Persona Verification Status:
              </span>
              <span
                className={`text-sm font-bold ${
                  verification?.persona_verification_complete
                    ? "text-green-600"
                    : "text-red-600"
                }`}
              >
                {verification?.persona_verification_complete
                  ? "Verified"
                  : "Not Verified"}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium">
                Document Verification Status:
              </span>
              <span
                className={`text-sm font-bold ${
                  verification?.ocr_verification_complete
                    ? "text-green-600"
                    : "text-red-600"
                }`}
              >
                {verification?.ocr_verification_complete
                  ? "Verified"
                  : "Not Verified"}
              </span>
            </div>
          </div>
        );
    }
  };

  // Add this new function to handle going back
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <FlowComponent
      title="Identity Verification"
      subtitle="Please complete the following steps to verify your identity and shipping documents."
      steps={steps}
      currentStep={currentStep}
      onSubmit={handleNext}
      onBack={handleBack}
      buttonText={currentStep === steps.length ? "Complete" : "Next"}
      showBackButton={currentStep > 1}
      verification={verification}
    >
      {renderContent()}
    </FlowComponent>
  );
};

export default IdentityVerification;
