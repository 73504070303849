// @ts-nocheck
import Persona from "persona";

/**
 * Creates a Persona client for verification
 *
 * @param {string} referenceId - Reference ID for the verification
 * @param {string} templateId - Template ID to use for verification
 * @param {Function} onCompleteCallback - Callback to execute when verification completes
 * @param {string|null} existingInquiryId - Optional existing inquiry ID to continue session
 * @returns {Object|null} Persona client instance or null if creation fails
 */
const createPersonaClient = (
  referenceId,
  templateId,
  onCompleteCallback,
  existingInquiryId = null
) => {
  console.log("Creating Persona Client with params:", {
    referenceId,
    templateId: existingInquiryId
      ? "Not using template ID when inquiry ID exists"
      : templateId,
    hasCallback: !!onCompleteCallback,
    existingInquiryId,
  });

  // Validate required parameters
  if (!referenceId) {
    console.error("ERROR: Reference ID is required for Persona client");
    return null;
  }

  // Either template ID or inquiry ID must be provided
  if (!existingInquiryId && !templateId) {
    console.error("ERROR: Either Template ID or Inquiry ID is required");
    return null;
  }

  if (!process.env.REACT_APP_PERSONA_ENVIRONMENT_ID) {
    console.error(
      "ERROR: Persona Environment ID is required in environment variables"
    );
    return null;
  }

  // Log whether we're creating a new inquiry or continuing existing one
  if (existingInquiryId) {
    console.log(`Continuing existing Persona inquiry: ${existingInquiryId}`);
  } else {
    console.log(`Starting new Persona inquiry with reference: ${referenceId}`);
  }

  try {
    // Configure the client differently based on whether we're resuming or creating
    const clientOptions = {
      referenceId: referenceId,
      environmentId: process.env.REACT_APP_PERSONA_ENVIRONMENT_ID,
      onReady: () => {
        console.log("Persona client ready, opening verification flow");
        client.open();
      },
      onComplete: (data) => {
        console.log("Persona verification completed with data:", {
          inquiryId: data.inquiryId,
          sessionToken: data.sessionToken ? "[REDACTED]" : undefined,
          status: "complete",
        });

        // Call the passed callback if provided
        if (onCompleteCallback && typeof onCompleteCallback === "function") {
          try {
            onCompleteCallback(data);
          } catch (callbackError) {
            console.error("Error in onComplete callback:", callbackError);
          }
        } else {
          console.warn(
            "No valid onComplete callback provided to Persona client"
          );
        }
      },
      onCancel: ({ inquiryId, sessionToken }) => {
        console.log("Persona verification canceled", { inquiryId });
      },
      onError: (error) => {
        console.error("Persona client error:", error);
      },
    };

    // Add EITHER templateId OR inquiryId to the options, not both
    if (existingInquiryId) {
      // If we have an existing inquiry ID, use that to resume the session
      clientOptions.inquiryId = existingInquiryId;
    } else {
      // Otherwise use the template ID to start a new session
      clientOptions.templateId = templateId;
    }

    // Create the Persona client instance
    const client = new Persona.Client(clientOptions);
    console.log("Persona client created successfully");

    return client;
  } catch (error) {
    console.error("Failed to create Persona client:", error);
    throw new Error(`Persona client creation failed: ${error.message}`);
  }
};

export default createPersonaClient;
