/**
 * Class for creating and validating verification data objects
 */
class VerificationData {
  /**
   * Creates a new VerificationData instance
   * @param {Object} data - Initial data to populate the verification object
   */
  constructor(data = {}) {
    // Required fields
    this.state = data.state || "pending";

    // Common fields with defaults
    this.persona_verification_complete = Boolean(
      data.persona_verification_complete || false
    );
    this.ocr_verification_complete = Boolean(
      data.ocr_verification_complete || false
    );
    this.verification_type = data.verification_type || null;

    // Optional fields (only set if provided)
    if (data.phone_number !== undefined) this.phone_number = data.phone_number;
    if (data.shipment_id !== undefined) this.shipment_id = data.shipment_id;
    if (data.driver_id !== undefined)
      this.driver_id = Number(data.driver_id) || null;
    if (data.carrier_id !== undefined)
      this.carrier_id = Number(data.carrier_id) || null;
    if (data.persona_inquiry_id !== undefined)
      this.persona_inquiry_id = data.persona_inquiry_id;
    if (data.persona_verification_url !== undefined)
      this.persona_verification_url = data.persona_verification_url;
    if (data.pin_code !== undefined)
      this.pin_code = data.pin_code ? Number(data.pin_code) : null;
    if (data.policy_number !== undefined)
      this.policy_number = data.policy_number;
    if (data.results !== undefined) this.results = data.results;
    if (data.token !== undefined) this.token = data.token;

    // Timestamps - we'll let the database handle these by default
    if (data.created_at) this.created_at = data.created_at;
    if (data.updated_at) this.updated_at = data.updated_at;
  }

  /**
   * Validates the verification data
   * @returns {Object} An object with isValid flag and any error messages
   */
  validate() {
    const errors = [];

    // State is required
    if (!this.state) {
      errors.push("State is required");
    }

    // Verification type specific validations
    if (this.verification_type === "driver_id" && !this.driver_id) {
      errors.push("Driver ID is required for driver verification");
    }

    if (
      (this.verification_type === "business" ||
        this.verification_type === "business_registration" ||
        this.verification_type === "business_report") &&
      !this.carrier_id
    ) {
      errors.push("Carrier ID is required for business verification");
    }

    if (this.verification_type === "insurance" && !this.carrier_id) {
      errors.push("Carrier ID is required for insurance verification");
    }

    if (this.verification_type === "ocr") {
      // At least one of these IDs should be present
      if (!this.driver_id && !this.carrier_id && !this.shipment_id) {
        errors.push(
          "At least one ID (driver, carrier, or shipment) is required for OCR verification"
        );
      }
    }

    return {
      isValid: errors.length === 0,
      errors,
    };
  }

  /**
   * Converts the verification data to a plain object for database insertion
   * @returns {Object} Plain object representation
   */
  toObject() {
    // Creates a clean object with only defined properties
    return Object.entries(this).reduce((obj, [key, value]) => {
      if (value !== undefined) obj[key] = value;
      return obj;
    }, {});
  }

  /**
   * Factory method to create driver verification data
   * @param {Object} data - Driver verification specific data
   * @returns {VerificationData} A new VerificationData instance for driver verification
   */
  static forDriverVerification(data = {}) {
    return new VerificationData({
      verification_type: "driver_id",
      driver_id: data.driverId,
      phone_number: data.phoneNumber,
      shipment_id: data.shipmentId,
      persona_inquiry_id: data.personaInquiryId,
      state: data.state || "pending",
      persona_verification_complete: false,
      ocr_verification_complete: false,
      persona_verification_url: data.personaVerificationUrl,
      ...data,
    });
  }

  /**
   * Factory method to create business registration verification data
   * @param {Object} data - Business registration verification specific data
   * @returns {VerificationData} A new VerificationData instance for business registration
   */
  static forBusinessRegistrationVerification(data = {}) {
    return new VerificationData({
      verification_type: "business_registration",
      carrier_id: data.carrierId,
      state: data.state || "pending",
      persona_inquiry_id: data.personaInquiryId,
      persona_verification_complete: false,
      ocr_verification_complete: false,
      phone_number: data.phoneNumber,
      results: data.results, // For storing business registration details
      ...data,
    });
  }

  /**
   * Factory method to create business report verification data
   * @param {Object} data - Business report verification specific data
   * @returns {VerificationData} A new VerificationData instance for business reports
   */
  static forBusinessReportVerification(data = {}) {
    return new VerificationData({
      verification_type: "business_report",
      carrier_id: data.carrierId,
      state: data.state || "pending",
      persona_inquiry_id: data.personaInquiryId || data.reportId, // Could store report ID here
      persona_verification_complete: false,
      ocr_verification_complete: false,
      results: data.results, // For storing report results/risk signals
      ...data,
    });
  }

  /**
   * Factory method that provides backward compatibility
   * @param {Object} data - Business verification data
   * @returns {VerificationData} A new VerificationData instance
   */
  static forBusinessVerification(data = {}) {
    // For backward compatibility, defaulting to registration
    return this.forBusinessRegistrationVerification(data);
  }

  /**
   * Factory method to create insurance verification data
   * @param {Object} data - Insurance verification specific data
   * @returns {VerificationData} A new VerificationData instance for insurance verification
   */
  static forInsuranceVerification(data = {}) {
    return new VerificationData({
      verification_type: "insurance_verification",
      carrier_id: data.carrierId,
      policy_number: data.policyNumber,
      state: data.state || "pending",
      persona_verification_complete: false,
      ocr_verification_complete: false,
      ...data,
    });
  }

  /**
   * Factory method to create document verification data
   * @param {Object} data - Document verification specific data
   * @returns {VerificationData} A new VerificationData instance for document verification
   */
  static forDocumentVerification(data = {}) {
    return new VerificationData({
      verification_type: "document",
      driver_id: data.driverId,
      carrier_id: data.carrierId,
      state: data.state || "pending",
      persona_verification_complete: false,
      ocr_verification_complete: false,
      results: data.results,
      ...data,
    });
  }

  /**
   * Factory method to create OCR verification data
   * @param {Object} data - OCR verification specific data
   * @returns {VerificationData} A new VerificationData instance for OCR verification
   */
  static forOCRVerification(data = {}) {
    return new VerificationData({
      verification_type: "ocr",
      driver_id: data.driverId,
      shipment_id: data.shipmentId,
      carrier_id: data.carrierId, // Optional, for carrier documents
      state: data.state || "pending",
      persona_verification_complete: false,
      ocr_verification_complete: false,
      results: data.results, // For storing OCR results
      ...data,
    });
  }
}

export default VerificationData;
