export default function PhoneRiskReportDetails({ verification }) {
  // Extract relevant data, similar to PhoneRiskReport in RiskReportsSection
  const riskData = verification.results?.phone_risk || {}; // Example structure, adjust based on actual data

  return (
    <div className="p-4 bg-purple-50 space-y-2 text-sm border border-purple-200 rounded">
      <h4 className="font-semibold text-purple-700">
        Phone Risk Report Details
      </h4>
      <p className="text-slate-500">Verification ID: {verification.id}</p>
      <p className="text-slate-500">
        Phone: {verification.phone_number || "N/A"}
      </p>
      <p className="text-slate-500">State: {verification.state || "N/A"}</p>
      <p className="text-orange-600 italic">
        -- Phone risk report snapshot placeholder --
      </p>
      {/* Reuse PhoneRiskSummaryCard or build specific view here */}
      {/* <PhoneRiskSummaryCard phoneRiskReport={verification} detailed /> */}
      <pre className="text-xs bg-slate-100 p-2 rounded overflow-auto max-h-60">
        {JSON.stringify(riskData, null, 2)}
      </pre>
    </div>
  );
}
