/**
 * Repository for verification operations
 */
export class VerificationRepository {
  constructor(supabase) {
    this.supabase = supabase;
  }

  // Create a new verification record
  async create(params) {
    const { data, error } = await this.supabase
      .from("verifications")
      .insert(params)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  // Get a verification record by id
  async findById(id) {
    const { data, error } = await this.supabase
      .from("verifications")
      .select("*")
      .eq("id", id)
      .single();

    if (error && error.code !== "PGRST116") throw error;
    return data;
  }

  // Find verification by shipment ID
  async findByShipmentId(shipmentId) {
    console.log(
      `[VerificationRepository] Finding verification for shipment ID: "${shipmentId}"`
    );

    const { data, error } = await this.supabase
      .from("verifications")
      .select("*")
      .eq("shipment_id", shipmentId)
      .order("created_at", { ascending: false })
      .limit(1);

    console.log(`[VerificationRepository] Query result:`, { data, error });

    if (error) throw error;
    // Return the first item if data is an array, or null if no results
    return data && data.length > 0 ? data[0] : null;
  }

  // Find verification by persona inquiry ID
  async findByPersonaInquiryId(inquiryId) {
    console.log(
      `[VerificationRepository] Finding verification for persona inquiry ID: "${inquiryId}"`
    );

    const { data, error } = await this.supabase
      .from("verifications")
      .select()
      .eq("persona_inquiry_id", inquiryId)
      .order("created_at", { ascending: false })
      .limit(1);

    console.log(`[VerificationRepository] Query result:`, { data, error });

    if (error) throw error;
    // Return the first item if data is an array, or null if no results
    return data && data.length > 0 ? data[0] : null;
  }

  // Find verification by driver ID
  async findByDriverId(driverId) {
    const { data, error } = await this.supabase
      .from("verifications")
      .select()
      .eq("driver_id", driverId)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Find verification by carrier ID
  async findByCarrierId(carrierId) {
    const { data, error } = await this.supabase
      .from("verifications")
      .select()
      .eq("carrier_id", carrierId)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Find latest verification of a specific type for a driver
  async findLatestDriverVerificationByType(driverId, type) {
    const { data, error } = await this.supabase
      .from("verifications")
      .select()
      .eq("driver_id", driverId)
      .eq("verification_type", type)
      .order("created_at", { ascending: false })
      .limit(1);

    if (error) throw error;
    return data || [];
  }

  // Find latest verification of a specific type for a carrier
  async findLatestCarrierVerificationByType(carrierId, type) {
    const { data, error } = await this.supabase
      .from("verifications")
      .select()
      .eq("carrier_id", carrierId)
      .eq("verification_type", type)
      .order("created_at", { ascending: false })
      .limit(1);

    if (error) throw error;
    return data || [];
  }

  async update(id, params) {
    const { data, error } = await this.supabase
      .from("verifications")
      .update(params)
      .eq("id", id)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  // Delete a verification record by id
  async delete(id) {
    const { error } = await this.supabase
      .from("verifications")
      .delete()
      .eq("id", id);

    if (error) throw error;
  }

  // Get all verification records
  async getAll() {
    console.log("VerificationRepository: Getting all verifications...");
    const { data, error } = await this.supabase
      .from("verifications")
      .select()
      .order("created_at", { ascending: false });

    if (error) {
      console.error(
        "VerificationRepository: Error fetching verifications:",
        error
      );
      throw error;
    }

    console.log("VerificationRepository: Fetched verifications:", data);
    return data || [];
  }

  // Find verifications by entity type and ID
  async findByEntityTypeAndId(entityType, entityId) {
    const { data, error } = await this.supabase
      .from("verifications")
      .select("*")
      .eq("entity_type", entityType)
      .eq("entity_id", entityId);

    if (error) throw error;
    return data || [];
  }
}
