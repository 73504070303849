import React from "react";
import { STATUS_CONFIG } from "./utils";

/**
 * Status badge component for displaying verification status
 * @param {Object} props
 * @param {string} props.status - The status value (completed, failed, pending, error)
 */
const StatusBadge = ({ status }) => {
  const config = STATUS_CONFIG[status] || STATUS_CONFIG.error;

  return (
    <span
      className={`px-3 py-1.5 rounded-full text-sm font-medium flex items-center gap-1.5 ${config.color} border`}
    >
      {config.label}
      <span className="ml-2 mr-4text-xs">{config.icon}</span>
    </span>
  );
};

export default StatusBadge;
