// @ts-nocheck
import React, { useState, useMemo } from "react";
import { useCustomerCompanyId } from "../../../hooks/useCustomers";
import { useGetUserSession } from "../../../hooks/useUsers";
import { useCreateCarrier } from "../../../hooks/useCarriers";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import Toast from "@/pages/Components/Toast";

// Import MultiStepForms components
import {
  useMultiStepForm,
  FormStepIndicator,
  FormStep,
} from "@/components/forms/MultiStepForms";

// Import UI components
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Textarea } from "@/components/ui/textarea";
import { MapboxAddressInput } from "@/components/ui/mapbox-address-input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

// Define the carrier validation schema
const carrierSchema = z.object({
  name: z.string().min(1, { message: "Carrier name is required" }),
  address: z.string().min(1, { message: "Address is required" }),
  city: z.string().min(1, { message: "City is required" }),
  state: z.string().min(1, { message: "State is required" }),
  zip_code: z.string().min(1, { message: "Zip code is required" }),
  country: z.string().min(1, { message: "Country is required" }).default("USA"),
  phone_number: z.string().min(1, { message: "Phone number is required" }),
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Email is required" }),
  website: z
    .string()
    .url({ message: "Invalid website URL" })
    .optional()
    .or(z.literal("")),
  dot_number: z.string().optional().or(z.literal("")),
  mc_number: z.string().optional().or(z.literal("")),
  tax_id: z.string().optional().or(z.literal("")),
  number_of_trucks: z
    .string()
    .transform((val) => (val === "" ? null : parseInt(val, 10)))
    .optional()
    .or(z.literal("")),
  number_of_drivers: z
    .string()
    .transform((val) => (val === "" ? null : parseInt(val, 10)))
    .optional()
    .or(z.literal("")),
  safety_rating: z.string().optional().or(z.literal("")),
  insurance_provider: z.string().optional().or(z.literal("")),
  insurance_policy_number: z.string().optional().or(z.literal("")),
  is_active: z.boolean().default(true),
  notes: z.string().optional().or(z.literal("")),
  is_vetted: z.boolean().default(false).optional(),
});

const AddCarrier = () => {
  const { session, loading } = useGetUserSession();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutate: createCarrier, mutateAsync: createCarrierAsync } =
    useCreateCarrier();

  // Define form steps
  const steps = useMemo(
    () => [
      {
        label: "Basic Info",
        fields: ["name", "address", "city", "state", "zip_code", "country"],
      },
      { label: "Contact Info", fields: ["phone_number", "email", "website"] },
      {
        label: "Operations",
        fields: [
          "dot_number",
          "number_of_trucks",
          "number_of_drivers",
          "insurance_provider",
          "insurance_policy_number",
        ],
      },
      { label: "Review & Submit", fields: [] },
    ],
    []
  );

  // Add a ref to track toast initialization
  const toastInitialized = React.useRef(false);

  // Initialize toast container on mount
  React.useEffect(() => {
    // Only run this once
    if (toastInitialized.current) return;

    // Mark as initialized
    toastInitialized.current = true;

    // Initialize the toast container
    Toast.init();
  }, []);

  // Set up react-hook-form with zod validation
  const form = useForm({
    resolver: zodResolver(carrierSchema),
    defaultValues: {
      name: "",
      address: "",
      city: "",
      state: "",
      zip_code: "",
      country: "USA",
      phone_number: "",
      email: "",
      website: "",
      dot_number: "",
      mc_number: "",
      tax_id: "",
      number_of_trucks: "",
      number_of_drivers: "",
      safety_rating: "",
      insurance_provider: "",
      insurance_policy_number: "",
      is_active: true,
      notes: "",
      is_vetted: false,
    },
    mode: "onBlur", // Only validate on blur to prevent validation on every keystroke
  });

  // Use the MultiStepForm hook
  const { currentStep, handleNextStep, handlePreviousStep } = useMultiStepForm({
    steps,
    initialStep: 0,
  });

  // Basic Information Step - Memoized to prevent re-render on parent component changes
  const BasicInfoStep = useMemo(() => {
    return () => (
      <>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Carrier Name *</FormLabel>
              <FormControl>
                <Input placeholder="ABC Trucking" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Address *</FormLabel>
              <FormControl>
                <MapboxAddressInput
                  placeholder="123 Main St, San Francisco, CA 94105"
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    // Don't trigger form setValue here as it causes re-renders and focus loss
                  }}
                  onAddressSelected={(address) => {
                    console.log("Address selected:", address);
                    // Only set values when an actual address is selected from the dropdown
                    field.onChange(address.formatted_address);

                    // Use setTimeout to delay the form updates until after the focus event
                    setTimeout(() => {
                      try {
                        const addressParts =
                          address.formatted_address.split(",");
                        console.log("Address parts:", addressParts);

                        if (addressParts.length >= 3) {
                          // Extract city from the second part
                          const city = addressParts[1].trim();
                          form.setValue("city", city, {
                            shouldValidate: false, // Don't validate immediately
                          });
                          console.log("Setting city:", city);

                          // Handle state and zip from the last part
                          const stateZipPart =
                            addressParts[addressParts.length - 1].trim();
                          console.log("State/Zip part:", stateZipPart);

                          // More flexible regex that can match patterns like "OH 43948" or "Ohio 43948"
                          const stateZipMatch = stateZipPart.match(
                            /([A-Za-z]{2,})\s+(\d{5}(?:-\d{4})?)/
                          );

                          if (stateZipMatch) {
                            const state = stateZipMatch[1];
                            const zipCode = stateZipMatch[2];

                            console.log(
                              "Extracted state:",
                              state,
                              "zip:",
                              zipCode
                            );
                            form.setValue("state", state, {
                              shouldValidate: false, // Don't validate immediately
                            });
                            form.setValue("zip_code", zipCode, {
                              shouldValidate: false, // Don't validate immediately
                            });
                          }
                        }
                      } catch (error) {
                        console.error("Error parsing address:", error);
                      }
                    }, 100);
                  }}
                />
              </FormControl>
              <FormDescription>
                Enter the carrier's full address
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem>
                <FormLabel>City *</FormLabel>
                <FormControl>
                  <Input placeholder="San Francisco" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="state"
            render={({ field }) => (
              <FormItem>
                <FormLabel>State *</FormLabel>
                <FormControl>
                  <Input placeholder="CA" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="zip_code"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Zip Code *</FormLabel>
                <FormControl>
                  <Input placeholder="94105" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Country *</FormLabel>
                <FormControl>
                  <Input placeholder="USA" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </>
    );
  }, [form]);

  // Contact Information Step
  const ContactInfoStep = useMemo(() => {
    return () => (
      <>
        <FormField
          control={form.control}
          name="phone_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone Number *</FormLabel>
              <FormControl>
                <Input placeholder="123-456-7890" {...field} />
              </FormControl>
              <FormDescription>
                The carrier's primary contact number.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email *</FormLabel>
              <FormControl>
                <Input placeholder="contact@carrier.com" {...field} />
              </FormControl>
              <FormDescription>
                The carrier's primary email address.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="website"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Website</FormLabel>
              <FormControl>
                <Input placeholder="https://carrier.com" {...field} />
              </FormControl>
              <FormDescription>
                Optional. The carrier's website URL.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="mc_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>MC Number</FormLabel>
              <FormControl>
                <Input placeholder="MC12345" {...field} />
              </FormControl>
              <FormDescription>
                Optional. Motor Carrier number issued by the FMCSA.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="tax_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Tax ID</FormLabel>
              <FormControl>
                <Input placeholder="12-3456789" {...field} />
              </FormControl>
              <FormDescription>
                Optional. The carrier's EIN or tax identification number.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </>
    );
  }, [form]);

  // Operations Information Step
  const OperationsInfoStep = useMemo(() => {
    return () => (
      <>
        <FormField
          control={form.control}
          name="dot_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>DOT Number</FormLabel>
              <FormControl>
                <Input placeholder="DOT123456" {...field} />
              </FormControl>
              <FormDescription>
                Optional. The Department of Transportation identification
                number.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="safety_rating"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Safety Rating</FormLabel>
              <FormControl>
                <Select
                  value={field.value || ""}
                  onValueChange={field.onChange}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select rating" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Satisfactory">Satisfactory</SelectItem>
                    <SelectItem value="Conditional">Conditional</SelectItem>
                    <SelectItem value="Unsatisfactory">
                      Unsatisfactory
                    </SelectItem>
                    <SelectItem value="Not Rated">Not Rated</SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormDescription>
                Optional. The carrier's FMCSA safety rating.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="number_of_trucks"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Number of Trucks</FormLabel>
                <FormControl>
                  <Input type="number" placeholder="10" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="number_of_drivers"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Number of Drivers</FormLabel>
                <FormControl>
                  <Input type="number" placeholder="20" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="insurance_provider"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Insurance Provider</FormLabel>
                <FormControl>
                  <Input placeholder="Provider Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="insurance_policy_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Insurance Policy Number</FormLabel>
                <FormControl>
                  <Input placeholder="Policy123" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Notes</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Additional notes about this carrier"
                  className="min-h-[120px]"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                Optional. Any additional information about this carrier.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </>
    );
  }, [form]);

  // Review Step
  const ReviewStep = useMemo(() => {
    return () => {
      const values = form.getValues();

      return (
        <div className="space-y-6">
          <div className="bg-muted/50 p-4 rounded-lg">
            <h3 className="font-medium mb-4">Basic Information</h3>
            <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3 text-sm">
              <div>
                <dt className="font-medium text-muted-foreground">
                  Carrier Name
                </dt>
                <dd>{values.name}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">Address</dt>
                <dd>{values.address}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">City</dt>
                <dd>{values.city}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">State</dt>
                <dd>{values.state}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">Zip Code</dt>
                <dd>{values.zip_code}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">Country</dt>
                <dd>{values.country}</dd>
              </div>
            </dl>
          </div>

          <div className="bg-muted/50 p-4 rounded-lg">
            <h3 className="font-medium mb-4">Contact Information</h3>
            <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3 text-sm">
              <div>
                <dt className="font-medium text-muted-foreground">
                  Phone Number
                </dt>
                <dd>{values.phone_number}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">Email</dt>
                <dd>{values.email}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">Website</dt>
                <dd>{values.website || "Not provided"}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">MC Number</dt>
                <dd>{values.mc_number || "Not provided"}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">Tax ID</dt>
                <dd>{values.tax_id || "Not provided"}</dd>
              </div>
            </dl>
          </div>

          <div className="bg-muted/50 p-4 rounded-lg">
            <h3 className="font-medium mb-4">Operations Information</h3>
            <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3 text-sm">
              <div>
                <dt className="font-medium text-muted-foreground">
                  DOT Number
                </dt>
                <dd>{values.dot_number || "Not provided"}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">
                  Safety Rating
                </dt>
                <dd>{values.safety_rating || "Not provided"}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">
                  Number of Trucks
                </dt>
                <dd>{values.number_of_trucks || "Not specified"}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">
                  Number of Drivers
                </dt>
                <dd>{values.number_of_drivers || "Not specified"}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">
                  Insurance Provider
                </dt>
                <dd>{values.insurance_provider || "Not provided"}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">
                  Insurance Policy Number
                </dt>
                <dd>{values.insurance_policy_number || "Not provided"}</dd>
              </div>
            </dl>
          </div>

          {values.notes && (
            <div className="bg-muted/50 p-4 rounded-lg">
              <h3 className="font-medium mb-4">Additional Notes</h3>
              <p className="text-sm">{values.notes}</p>
            </div>
          )}
        </div>
      );
    };
  }, [form]);

  // Validate current step fields
  const validateCurrentStep = () => {
    const fieldsToValidate = steps[currentStep].fields;
    if (fieldsToValidate.length === 0) return true;

    return form.trigger(fieldsToValidate).then((isValid) => {
      if (!isValid) {
        // Trigger validation errors to display
        fieldsToValidate.forEach((field) => form.trigger(field));
      }
      return isValid;
    });
  };

  const handleSubmit = async (data) => {
    console.log("Submitting form with data:", data);
    setIsSubmitting(true);
    setError("");

    try {
      // Use the createCarrierAsync method to get a Promise we can await
      console.log(
        "Calling createCarrierAsync with data:",
        JSON.stringify(data, null, 2)
      );
      Toast.info("Processing carrier submission...", 3000);

      const result = await createCarrierAsync(data);
      console.log("Carrier created successfully:", result);

      // Handle success
      setMessage(`Carrier ${result.name} added successfully.`);
      setError("");
      form.reset();

      // Show success toast
      Toast.success("Carrier created successfully!", 5000);

      // Reset to first step
      if (typeof handleNextStep === "function") {
        setTimeout(() => {
          while (currentStep > 0) {
            handlePreviousStep();
          }
        }, 1000);
      }
    } catch (error) {
      console.error("Error in try/catch:", error);
      handleCarrierError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Separate function to handle carrier creation errors
  const handleCarrierError = (error) => {
    console.error("Handling carrier error:", error);
    console.error("Error details:", JSON.stringify(error, null, 2));

    // Check for unique constraint violations (409 conflict)
    if (error?.status === 409 || error?.code === "23505") {
      // If we have information about the specific field that caused the conflict
      const field = error?.field || "";
      console.log("Conflict in field:", field);

      // Extract value to check if it's empty
      const formValues = form.getValues();

      // Set specific error messages based on the field
      if (field.includes("email") || error?.message?.includes("email")) {
        setError("A carrier with this email address already exists");
        form.setError("email", {
          type: "conflict",
          message: "This email is already registered",
        });
        Toast.error("A carrier with this email address already exists", 5000);
      } else if (
        field.includes("phone_number") ||
        error?.message?.includes("phone_number")
      ) {
        setError("A carrier with this phone number already exists");
        form.setError("phone_number", {
          type: "conflict",
          message: "This phone number is already registered",
        });
        Toast.error("A carrier with this phone number already exists", 5000);
      } else if (
        field.includes("dot_number") ||
        error?.message?.includes("dot_number")
      ) {
        setError("A carrier with this DOT number already exists");
        form.setError("dot_number", {
          type: "conflict",
          message: "This DOT number is already registered",
        });
        Toast.error("A carrier with this DOT number already exists", 5000);
      } else if (
        field.includes("mc_number") ||
        error?.message?.includes("mc_number")
      ) {
        setError("A carrier with this MC number already exists");
        form.setError("mc_number", {
          type: "conflict",
          message: "This MC number is already registered",
        });
        Toast.error("A carrier with this MC number already exists", 5000);
      } else if (
        (field.includes("tax_id") || error?.message?.includes("tax_id")) &&
        formValues.tax_id &&
        formValues.tax_id.trim() !== ""
      ) {
        // Only show tax_id error if the user actually provided a tax_id value
        setError("A carrier with this Tax ID already exists");
        form.setError("tax_id", {
          type: "conflict",
          message: "This Tax ID is already registered",
        });
        Toast.error("A carrier with this Tax ID already exists", 5000);
      } else if (field === "carrier_identity") {
        // This is the case where we have a duplicate carrier but can't determine which field caused it
        const errorMessage =
          "A carrier with the same information already exists. Please check name, address, and other identifying details.";
        setError(errorMessage);
        Toast.error(errorMessage, 5000);
      } else {
        // Generic duplicate error message if we don't know which field
        const errorMessage =
          "This carrier information conflicts with an existing record. Please check your entries for uniqueness.";
        setError(errorMessage);
        Toast.error(errorMessage, 5000);
      }
    } else {
      // General error handling
      const errorMessage =
        error?.message ||
        "An error occurred while adding the carrier. Please try again.";
      setError(errorMessage);
      Toast.error(errorMessage, 5000);
    }
  };

  // Handler for submitting the form on the last step
  const handleSubmitForm = () => {
    const formValues = form.getValues();
    console.log("Form values for submission:", formValues);

    if (!isSubmitting) {
      console.log("Calling handleSubmit");
      // Fix: use the form handleSubmit to trigger the actual submission
      form.handleSubmit((data) => {
        console.log("Form validated successfully, submitting with data:", data);
        handleSubmit(data);
      })();
    }
  };

  if (loading) {
    return (
      <div className="w-full mx-auto p-6 flex justify-center items-center min-h-[300px]">
        <Loader2 className="w-8 h-8 animate-spin text-slate-700" />
      </div>
    );
  }

  return (
    <div className="w-full mx-auto p-4 sm:p-6 lg:p-8 space-y-6">
      <div className="flex flex-col">
        <div className="space-y-2">
          <h2 className="text-slate-900 text-xl font-semibold">Add Carrier</h2>
          <p className="text-slate-500 text-sm">Enter carrier information.</p>
        </div>
      </div>

      {/* Progress indicator using our custom component */}
      <FormStepIndicator steps={steps} currentStep={currentStep} />

      {/* Error message display */}
      {error && (
        <Alert variant="destructive" className="mb-6">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {/* Success message display */}
      {message && (
        <Alert className="mb-6 bg-green-50 border-green-200">
          <AlertTitle className="text-green-800">Success</AlertTitle>
          <AlertDescription className="text-green-700">
            {message}
          </AlertDescription>
        </Alert>
      )}

      {/* Main form */}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
          {/* Step 1: Basic Information */}
          <FormStep
            step={0}
            title="Basic Information"
            description="Enter the carrier's basic details"
            isActive={currentStep === 0}
            footer={
              <div className="flex justify-end">
                <Button
                  type="button"
                  onClick={() => handleNextStep(validateCurrentStep)}
                >
                  Continue to Contact Info
                </Button>
              </div>
            }
          >
            <BasicInfoStep />
          </FormStep>

          {/* Step 2: Contact Information */}
          <FormStep
            step={1}
            title="Contact Information"
            description="Enter contact details for the carrier"
            isActive={currentStep === 1}
            footer={
              <div className="flex justify-between space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={handlePreviousStep}
                >
                  Back
                </Button>
                <Button
                  type="button"
                  onClick={() => handleNextStep(validateCurrentStep)}
                >
                  Continue to Operations
                </Button>
              </div>
            }
          >
            <ContactInfoStep />
          </FormStep>

          {/* Step 3: Operations Information */}
          <FormStep
            step={2}
            title="Operations Information"
            description="Enter details about carrier operations"
            isActive={currentStep === 2}
            footer={
              <div className="flex justify-between space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={handlePreviousStep}
                >
                  Back
                </Button>
                <Button
                  type="button"
                  onClick={() => handleNextStep(validateCurrentStep)}
                >
                  Continue to Review
                </Button>
              </div>
            }
          >
            <OperationsInfoStep />
          </FormStep>

          {/* Step 4: Review & Submit */}
          <FormStep
            step={3}
            title="Review & Submit"
            description="Review carrier information before submitting"
            isActive={currentStep === 3}
            footer={
              <div className="flex justify-between space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={handlePreviousStep}
                >
                  Back
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleSubmitForm}
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Submitting...
                    </>
                  ) : (
                    "Add Carrier"
                  )}
                </Button>
              </div>
            }
          >
            <ReviewStep />
          </FormStep>
        </form>
      </Form>
    </div>
  );
};

export default AddCarrier;
