import React from "react";
import DriverVerificationForm from "./DriverVerificationForm";
import ScheduledDrivers from "./ScheduledDrivers";
import { Card, CardContent } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";

const VerificationPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <DriverVerificationForm />
    </div>
  );
};

export default VerificationPage;
