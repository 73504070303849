import { supabase } from "../../../lib/supabase";
import { ShipmentService } from "../business/ShipmentService";
import { sendVerificationSMS } from "../core/NotificationService";
import { CommonVerificationService } from "./CommonVerificationService";
import { VerificationRepository } from "../../repositories/VerificationRepository";
import OCRProvider from "../../providers/OCRProvider";
import OCRRepository from "../../repositories/OCRRepository";
import OCRService from "../../services/core/OCRService";
import {
  personaProvider,
  INQUIRY_TEMPLATES,
} from "../../providers/PersonaProvider";

// Create singleton instances
const verificationRepository = new VerificationRepository(supabase);
const ocrRepository = new OCRRepository();
const ocrProvider = new OCRProvider({
  ocrRepository: ocrRepository,
  ocrService: OCRService,
});

/**
 * Service for driver verification-related operations
 */
export const DriverVerificationService = {
  /**
   * Creates a driver verification record and initiates the verification process
   *
   * @param {Object} data - Data for driver verification
   * @param {string} data.phoneNumber - Driver's phone number
   * @param {string} data.email - Driver's email address
   * @param {string} data.shipment_id - ID of the shipment to verify
   * @param {string} data.companyId - ID of the company
   * @param {string} [data.driver_id] - Optional driver ID
   * @param {number} [data.dock_id] - Optional dock ID for assigning shipment to a dock
   * @returns {Promise<Object>} Created verification record and shipment details
   */
  async createDriverVerification(data) {
    try {
      console.log("🔍 [createDriverVerification] Starting with data:", data);

      // 1. Create Persona inquiry using PersonaProvider
      const { referenceId, inquiryId } = await personaProvider.createInquiry(
        {
          phoneNumber: data.phoneNumber,
          email: data.email,
          companyId: data.companyId,
          verificationMethod: "government-id",
          entityType: "shipment",
          entityId: data.shipment_id,
        },
        "government-id",
        INQUIRY_TEMPLATES["document-verification"]
      );

      console.log("✅ [createDriverVerification] Persona inquiry created:", {
        inquiryId,
        referenceId,
      });

      // Fetch the current shipment data
      const currentShipment = await ShipmentService.getShipment(
        data.shipment_id
      );
      console.log("Current shipment data:", currentShipment);

      // 2. Update shipment status and dock_id if provided
      const updateData = {
        status: "Unverified",
      };

      // Only set dock_id if it was provided and the shipment doesn't already have one
      if (
        data.dock_id &&
        (!currentShipment.dock_id || currentShipment.dock_id !== data.dock_id)
      ) {
        updateData.dock_id = parseInt(data.dock_id, 10);
        console.log(`Assigning shipment to dock: ${data.dock_id}`);
      }

      // Update the shipment using ShipmentService.updateShipment instead of updateShipmentStatus
      // to ensure we can set dock_id
      const shipment = currentShipment
        ? await ShipmentService.updateShipment(currentShipment.id, updateData)
        : await ShipmentService.updateShipmentStatus(
            data.shipment_id,
            "Unverified",
            data.companyId
          );

      if (!shipment) {
        console.error(
          "❌ [createDriverVerification] No shipment data returned"
        );
        throw new Error("Failed to update shipment status");
      }

      // 3. Create verification record using VerificationRepository
      const verificationData = {
        phone_number: data.phoneNumber,
        shipment_id: shipment.load_id,
        driver_id: data.driver_id,
        persona_inquiry_id: inquiryId,
        persona_verification_complete: false,
        state: "created",
        ocr_verification_complete: false,
        verification_type: "driver_shipment",
      };

      const verification = await verificationRepository.create(
        verificationData
      );

      if (!verification) {
        console.error(
          "❌ [createDriverVerification] Failed to create verification record"
        );
        throw new Error("Failed to create verification record");
      }

      console.log(
        "✅ [createDriverVerification] Verification record created:",
        {
          id: verification.id,
        }
      );

      // 4. Send verification SMS notification
      await sendVerificationSMS(verification);
      console.log("✅ [createDriverVerification] SMS notification sent");

      return { verification, shipment };
    } catch (error) {
      console.error("❌ [createDriverVerification] Error:", error);
      throw error;
    }
  },

  /**
   * Performs OCR verification on a shipment document
   *
   * @param {string} shipmentId - The ID of the shipment to be verified
   * @param {File} imageFile - The file object of the captured image
   * @param {string} imageURL - The URL of the uploaded image
   * @returns {Promise<Object>} Object containing OCR results, verification results, and shipment details
   */
  async performVerificationOCR(shipmentId, imageFile, imageURL) {
    try {
      console.log(`Performing OCR verification for shipment: ${shipmentId}`);

      // 1. Get the verification record
      const verification =
        await CommonVerificationService.getVerificationStatus(shipmentId);

      if (!verification) {
        console.error(`No verification found for shipment: ${shipmentId}`);
        throw new Error(
          `Verification not found for shipment ID: ${shipmentId}`
        );
      }

      // 2. Process the image with OCR provider
      const ocrResult = await ocrProvider.processDocument(
        verification.id,
        imageFile,
        {
          documentType: "shipment_document",
          entityId: shipmentId,
          entityType: "shipment",
          imageUrl: imageURL,
        }
      );

      // 3. Fetch shipment details using the shipment service
      const shipment = await ShipmentService.getShipment(
        verification.shipment_id
      );

      // 4. Verify shipment ID using the extracted OCR data
      let verificationResult;

      if (
        ocrResult &&
        ocrResult.extractedData &&
        ocrResult.extractedData.ocrText
      ) {
        verificationResult = ocrProvider.verifyShipmentId(
          ocrResult.extractedData.ocrText,
          shipmentId
        );
      } else {
        verificationResult = {
          expected: shipmentId,
          match: false,
          bestMatch: null,
        };
      }

      // 5. Update the verification status based on the match result
      await CommonVerificationService.updateVerificationStatus(shipmentId, {
        ocr_verification_complete: true,
        state: verificationResult.match ? "complete" : "ocr_complete",
      });

      return {
        ocrResult,
        verificationResult,
        shipmentDetails: shipment,
      };
    } catch (error) {
      console.error(`Error performing OCR verification: ${error.message}`);

      // Even in case of error, try to update the verification status
      try {
        const verification =
          await CommonVerificationService.getVerificationStatus(shipmentId);

        await CommonVerificationService.updateVerificationStatus(shipmentId, {
          ocr_verification_complete: false,
          state: "error",
          metadata: {
            ...(verification?.metadata || {}),
            error: error.message,
            errorTimestamp: new Date().toISOString(),
          },
        });
      } catch (updateError) {
        console.error(
          `Failed to update verification status after OCR error: ${updateError.message}`
        );
      }

      throw error;
    }
  },

  /**
   * Checks driver verification status
   *
   * @param {string} persona_inquiry_id - Persona inquiry ID
   * @returns {Promise<Object>} Status information
   */
  async checkDriverVerificationStatus(persona_inquiry_id) {
    return CommonVerificationService.checkVerificationStatus(
      persona_inquiry_id
    );
  },
};
