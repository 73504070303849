/**
 * BusinessVerificationPersonaService.js
 *
 * This service handles business verification related operations using Persona
 */

import PersonaProvider, {
  personaProvider,
} from "../../../providers/PersonaProvider";
import { VerificationRepository } from "../../../repositories/VerificationRepository";
import { supabase } from "../../../../lib/supabase";
import {
  mapPersonaInquiryStatus,
  isVerificationSuccessful,
  mapStatusToVerificationState,
} from "../../../utils/PersonaStatusUtils";

// UUID generator function to replace crypto dependency
function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

class BusinessVerificationPersonaService {
  constructor(dependencies = {}) {
    this.personaProvider = dependencies.personaProvider || personaProvider;
    this.verificationRepository =
      dependencies.verificationRepository ||
      new VerificationRepository(supabase);
  }

  /**
   * Verifies a business owner using a Persona inquiry
   *
   * @param {string} inquiryId - Persona inquiry ID
   * @param {Object} businessOwner - Business owner data
   * @returns {Promise<Object>} Verification result
   */
  async verifyBusinessOwnerWithInquiry(inquiryId, businessOwner) {
    try {
      console.log(
        "🔍 [BusinessVerificationPersonaService] Verifying business owner with inquiry:",
        {
          inquiryId,
          businessOwnerId: businessOwner?.id,
        }
      );

      if (!inquiryId) {
        throw new Error("Inquiry ID is required");
      }

      if (!businessOwner) {
        throw new Error("Business owner data is required");
      }

      // 1. Get the inquiry from Persona using our provider
      const inquiryData = await this.personaProvider.getInquiry(inquiryId);
      console.log(
        "✅ [BusinessVerificationPersonaService] Retrieved inquiry:",
        inquiryData.id
      );

      // Map the Persona status to our standardized status
      const standardStatus = mapPersonaInquiryStatus(inquiryData.status);
      const isComplete = isVerificationSuccessful(inquiryData.status);
      const verificationState = mapStatusToVerificationState(standardStatus);

      // 2. Associate the inquiry with the business owner
      const verificationData = {
        type: "business_owner_verification",
        verification_type: "business_owner",
        state: verificationState,
        persona_inquiry_id: inquiryId,
        reference_id: generateUUID(),
        business_owner_id: businessOwner.id,
        persona_verification_complete: isComplete,
        metadata: {
          business_owner: businessOwner,
          inquiry_data: inquiryData,
        },
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      // 3. Save the verification record
      const savedVerification = await this.verificationRepository.create(
        verificationData
      );

      console.log(
        "✅ [BusinessVerificationPersonaService] Created verification record:",
        savedVerification.id
      );

      return {
        success: true,
        verification_id: savedVerification.id,
        inquiry_id: inquiryId,
        reference_id: verificationData.reference_id,
        status: standardStatus,
      };
    } catch (error) {
      console.error(
        "❌ [BusinessVerificationPersonaService] Error verifying business owner:",
        error
      );
      throw error;
    }
  }

  /**
   * Gets the verification status for a business
   *
   * @param {string} inquiryId - Persona inquiry ID
   * @param {string} carrierId - Carrier ID
   * @param {string} referenceId - Reference ID
   * @param {string} entityType - Entity type (defaults to "carrier")
   * @returns {Promise<Object>} Verification status
   */
  async getPersonaVerificationStatusDirect(
    inquiryId,
    carrierId,
    referenceId,
    entityType = "carrier"
  ) {
    try {
      console.log(
        "🔍 [BusinessVerificationPersonaService] Getting verification status:",
        {
          inquiryId,
          carrierId,
          referenceId,
          entityType,
        }
      );

      // Find by inquiry ID if provided
      if (inquiryId) {
        const verificationRecord =
          await this.verificationRepository.findByPersonaInquiryId(inquiryId);

        if (verificationRecord) {
          // Map status using our utility functions
          const statusFromResults = verificationRecord.results?.status;
          const standardStatus = statusFromResults
            ? mapPersonaInquiryStatus(statusFromResults)
            : mapStatusToVerificationState(verificationRecord.state) ===
              "persona_complete"
            ? "completed"
            : "pending";

          return {
            success: true,
            isComplete: verificationRecord.persona_verification_complete,
            status: standardStatus,
            verification: verificationRecord,
          };
        }
      }

      // Find by reference ID if provided
      if (referenceId) {
        const verificationRecords =
          await this.verificationRepository.findByReferenceId(referenceId);

        if (verificationRecords && verificationRecords.length > 0) {
          const verificationRecord = verificationRecords[0];

          // Map status using our utility functions
          const statusFromResults = verificationRecord.results?.status;
          const standardStatus = statusFromResults
            ? mapPersonaInquiryStatus(statusFromResults)
            : mapStatusToVerificationState(verificationRecord.state) ===
              "persona_complete"
            ? "completed"
            : "pending";

          return {
            success: true,
            isComplete: verificationRecord.persona_verification_complete,
            status: standardStatus,
            verification: verificationRecord,
          };
        }
      }

      // Find by carrier ID if provided
      if (carrierId && entityType === "carrier") {
        const verificationRecords =
          await this.verificationRepository.findByCarrierId(carrierId);

        if (verificationRecords && verificationRecords.length > 0) {
          // Sort by created_at descending to get the most recent verification
          verificationRecords.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          const verificationRecord = verificationRecords[0];

          // Map status using our utility functions
          const statusFromResults = verificationRecord.results?.status;
          const standardStatus = statusFromResults
            ? mapPersonaInquiryStatus(statusFromResults)
            : mapStatusToVerificationState(verificationRecord.state) ===
              "persona_complete"
            ? "completed"
            : "pending";

          return {
            success: true,
            isComplete: verificationRecord.persona_verification_complete,
            status: standardStatus,
            verification: verificationRecord,
          };
        }
      }

      // No verification found
      return {
        success: false,
        message: "No verification record found",
        isComplete: false,
        status: "not_found",
      };
    } catch (error) {
      console.error(
        "❌ [BusinessVerificationPersonaService] Error getting verification status:",
        error
      );
      throw error;
    }
  }
}

// Export as singleton instance
export default new BusinessVerificationPersonaService();
