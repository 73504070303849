// @ts-nocheck
import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { Button } from "../../Components/Button";
import { Card, CardContent } from "../../Components/Card";
import {
  ShieldCheck,
  TruckIcon,
  ClipboardCheck,
  Brain,
  BarChart4,
  Clock,
} from "lucide-react";

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 },
};

const FreightBrokers = () => {
  return (
    <div className="bg-gradient-to-b from-slate-900 to-slate-800 min-h-screen">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Freight Brokers | Comprehensive Carrier Vetting Solution</title>
        <meta
          name="description"
          content="Purpose-built vetting solution for freight brokers to reduce carrier-related risks, prevent cargo loss, and streamline operations with AI-powered verification."
        />
        <link rel="canonical" href="https://indemni.com/freight-brokers" />
        <meta
          property="og:title"
          content="Freight Brokers | Comprehensive Carrier Vetting Solution"
        />
        <meta
          property="og:description"
          content="Purpose-built vetting solution for freight brokers to reduce carrier-related risks, prevent cargo loss, and streamline operations with AI-powered verification."
        />
        <meta property="og:url" content="https://indemni.com/freight-brokers" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Freight Brokers",
              "url": "https://indemni.com/freight-brokers",
              "description": "Purpose-built vetting solution for freight brokers to reduce carrier-related risks and prevent cargo loss."
            }
          `}
        </script>
      </Helmet>

      {/* Hero Section */}
      <section className="relative py-12 md:py-20 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            className="text-center"
            initial={fadeIn.initial}
            animate={fadeIn.animate}
            transition={fadeIn.transition}
          >
            <div className="inline-block px-3 py-1 md:px-4 md:py-1 bg-[#119DA5]/10 rounded-full mb-4">
              <span className="text-[#119DA5] text-sm md:text-base font-semibold">
                Trusted by Leading Freight Brokers
              </span>
            </div>
            <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold text-white mb-4 md:mb-6">
              Vet Carriers with Confidence
              <br />
              <span className="text-[#119DA5]">Reduce Risk & Liability</span>
              <br />
              Protect Your Bottom Line
            </h1>
            <p className="text-lg md:text-xl text-gray-300 mb-6 md:mb-8 max-w-2xl mx-auto px-4">
              The industry's most comprehensive carrier vetting platform that
              helps you prevent fraud, verify insurance, and reduce cargo claims
              through AI-powered verification.
            </p>
            <div className="flex flex-col items-center justify-center space-y-4 px-4">
              <Button
                className="w-96 bg-[#119DA5] text-white px-8 py-4 md:px-10 md:py-6 text-lg md:text-xl rounded-lg hover:bg-[#0E8A91] transition-colors"
                onClick={() =>
                  (window.location.href =
                    "https://calendly.com/meet-indemni/30min")
                }
              >
                Vet Carriers Faster
              </Button>
              <Button
                className="w-96 bg-transparent border-2 border-[#119DA5] text-white px-8 py-4 md:px-10 md:py-6 text-lg md:text-xl rounded-lg hover:bg-[#119DA5]/10 transition-colors"
                onClick={() => (window.location.href = "/vetting")}
              >
                Try our free carrier lookup tool →
              </Button>
              <span className="text-sm text-gray-400 block mt-2">
                No commitment required
              </span>
            </div>
          </motion.div>
        </div>

        {/* Decorative Elements */}
        <div className="absolute top-0 right-0 -z-10 opacity-20">
          <svg width="400" height="400" viewBox="0 0 100 100" fill="none">
            <circle cx="50" cy="50" r="40" stroke="#119DA5" strokeWidth="0.5" />
          </svg>
        </div>
      </section>

      {/* Problem Statement Section */}
      <section className="py-12 md:py-20 bg-slate-800/50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-start"
            initial={fadeIn.initial}
            animate={fadeIn.animate}
            transition={fadeIn.transition}
          >
            <div>
              <h2 className="text-3xl font-bold text-white mb-6 text-center md:text-left">
                Why Freight Brokers Choose Indemni
              </h2>
              <div className="space-y-6">
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-[#119DA5] flex items-center justify-center mt-1">
                    <span className="text-white text-sm">1</span>
                  </div>
                  <div>
                    <p className="text-gray-300">
                      <strong className="text-white">Rising Fraud:</strong>{" "}
                      Billions in annual losses from identity theft, double
                      brokering, and fraudulent documentation
                    </p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-[#119DA5] flex items-center justify-center mt-1">
                    <span className="text-white text-sm">2</span>
                  </div>
                  <div>
                    <p className="text-gray-300">
                      <strong className="text-white">
                        Insurance Verification Challenges:
                      </strong>{" "}
                      Manual checks are time-consuming and prone to error,
                      creating potential liability gaps
                    </p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-[#119DA5] flex items-center justify-center mt-1">
                    <span className="text-white text-sm">3</span>
                  </div>
                  <div>
                    <p className="text-gray-300">
                      <strong className="text-white">
                        Complete Vetting Solution:
                      </strong>{" "}
                      Automated carrier verification, insurance validation, and
                      driver authentication in one platform
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Updated stats cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 md:mt-0">
              <Card className="bg-slate-700 border-none h-full">
                <CardContent className="p-6 flex flex-col h-full">
                  <h3 className="text-4xl font-bold text-[#119DA5] mb-2 text-center sm:text-left">
                    Faster
                  </h3>
                  <p className="text-gray-300 font-medium mb-2 text-center sm:text-left">
                    Carrier Vetting
                  </p>
                  <span className="text-sm text-gray-400 text-center sm:text-left">
                    Automate manual verification tasks and reduce onboarding
                    time
                  </span>
                </CardContent>
              </Card>
              <Card className="bg-slate-700 border-none h-full">
                <CardContent className="p-6 flex flex-col h-full">
                  <h3 className="text-4xl font-bold text-[#119DA5] mb-2 text-center sm:text-left">
                    Mitigate
                  </h3>
                  <p className="text-gray-300 font-medium mb-2 text-center sm:text-left">
                    Fraud Attempts
                  </p>
                  <span className="text-sm text-gray-400 text-center sm:text-left">
                    Identify suspicious patterns and prevent carrier-related
                    fraud
                  </span>
                </CardContent>
              </Card>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-12 md:py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            className="text-center mb-12 md:mb-16"
            initial={fadeIn.initial}
            animate={fadeIn.animate}
            transition={fadeIn.transition}
          >
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              Comprehensive Vetting Platform
            </h2>
            <p className="text-lg md:text-xl text-gray-300 px-4">
              Identify and prevent fraud, verify insurance coverage, and ensure
              compliance with our all-in-one vetting solution
            </p>
          </motion.div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:gap-8">
            {features.map((feature, index) => (
              <Card
                key={index}
                className="bg-slate-700 border-none hover:bg-slate-600 transition-colors"
              >
                <CardContent className="p-6 text-center sm:text-left">
                  <feature.icon className="w-12 h-12 text-[#119DA5] mb-4 mx-auto sm:mx-0" />
                  <h3 className="text-xl font-bold text-white mb-2">
                    {feature.title}
                  </h3>
                  <p className="text-gray-300">{feature.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-12 md:py-20 bg-slate-800/30">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            className="text-center mb-12"
            initial={fadeIn.initial}
            animate={fadeIn.animate}
            transition={fadeIn.transition}
          >
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              How It Works
            </h2>
            <p className="text-lg text-gray-300 max-w-3xl mx-auto">
              A streamlined process designed specifically for freight brokers
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <Card className="bg-slate-700 border-none">
              <CardContent className="p-6 text-center">
                <div className="bg-[#119DA5]/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                  <TruckIcon className="w-8 h-8 text-[#119DA5]" />
                </div>
                <h3 className="text-xl font-bold text-white mb-2">
                  1. Carrier Submission
                </h3>
                <p className="text-gray-300">
                  Submit carrier information through our platform or API
                  integration with your TMS
                </p>
              </CardContent>
            </Card>

            <Card className="bg-slate-700 border-none">
              <CardContent className="p-6 text-center">
                <div className="bg-[#119DA5]/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                  <ClipboardCheck className="w-8 h-8 text-[#119DA5]" />
                </div>
                <h3 className="text-xl font-bold text-white mb-2">
                  2. AI-Powered Verification
                </h3>
                <p className="text-gray-300">
                  Our system automatically validates authority, insurance,
                  safety records, and identifies fraud indicators
                </p>
              </CardContent>
            </Card>

            <Card className="bg-slate-700 border-none">
              <CardContent className="p-6 text-center">
                <div className="bg-[#119DA5]/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                  <ShieldCheck className="w-8 h-8 text-[#119DA5]" />
                </div>
                <h3 className="text-xl font-bold text-white mb-2">
                  3. Results & Continuous Monitoring
                </h3>
                <p className="text-gray-300">
                  Receive detailed risk assessment and continuous monitoring of
                  carrier credentials
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-12 md:py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            className="text-center mb-12"
            initial={fadeIn.initial}
            animate={fadeIn.animate}
            transition={fadeIn.transition}
          >
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              Benefits for Freight Brokers
            </h2>
            <p className="text-lg text-gray-300 max-w-3xl mx-auto">
              Purpose-built to solve your most pressing challenges
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Card className="bg-slate-700 border-none">
              <CardContent className="p-6">
                <BarChart4 className="w-10 h-10 text-[#119DA5] mb-4" />
                <h3 className="text-xl font-bold text-white mb-2">
                  Increased Operational Efficiency
                </h3>
                <p className="text-gray-300">
                  Reduce carrier onboarding time by up to 65% while maintaining
                  rigorous compliance standards
                </p>
              </CardContent>
            </Card>

            <Card className="bg-slate-700 border-none">
              <CardContent className="p-6">
                <ShieldCheck className="w-10 h-10 text-[#119DA5] mb-4" />
                <h3 className="text-xl font-bold text-white mb-2">
                  Reduced Liability Exposure
                </h3>
                <p className="text-gray-300">
                  Verify and document proper insurance coverage with continuous
                  monitoring and alerts
                </p>
              </CardContent>
            </Card>

            <Card className="bg-slate-700 border-none">
              <CardContent className="p-6">
                <Brain className="w-10 h-10 text-[#119DA5] mb-4" />
                <h3 className="text-xl font-bold text-white mb-2">
                  Fraud Prevention
                </h3>
                <p className="text-gray-300">
                  Detect identity theft, double brokering, and fraudulent
                  documentation before it impacts your business
                </p>
              </CardContent>
            </Card>

            <Card className="bg-slate-700 border-none">
              <CardContent className="p-6">
                <Clock className="w-10 h-10 text-[#119DA5] mb-4" />
                <h3 className="text-xl font-bold text-white mb-2">
                  Time & Resource Savings
                </h3>
                <p className="text-gray-300">
                  Free up your team from manual verification tasks and focus on
                  growth and customer service
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-12 md:py-20 bg-[#119DA5]/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.div
            initial={fadeIn.initial}
            animate={fadeIn.animate}
            transition={fadeIn.transition}
          >
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 md:mb-6">
              Ready to Transform Your Vetting Process?
            </h2>
            <p className="text-lg md:text-xl text-gray-300 mb-6 md:mb-8 max-w-2xl mx-auto px-4">
              Join industry-leading freight brokers who have reduced risk,
              prevented fraud, and streamlined operations with our comprehensive
              vetting platform.
            </p>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 justify-center">
              <Button
                className="bg-white text-slate-900 px-6 py-3 md:px-8 md:py-4 text-base md:text-lg rounded-lg hover:bg-gray-100 transition-colors"
                onClick={() =>
                  (window.location.href =
                    "https://calendly.com/meet-indemni/30min")
                }
              >
                Schedule a Demo
              </Button>
              <Button
                className="bg-transparent border-2 border-white text-white px-6 py-3 md:px-8 md:py-4 text-base md:text-lg rounded-lg hover:bg-white/10 transition-colors"
                onClick={() => (window.location.href = "/vetting")}
              >
                Try Free Lookup Tool
              </Button>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

const features = [
  {
    title: "Automated Insurance Verification",
    description:
      "Instantly verify coverage, validate certificates, and monitor policy changes to reduce liability",
    icon: ShieldCheck,
  },
  {
    title: "Carrier Authority Validation",
    description:
      "Comprehensive checks for MC/DOT numbers, safety ratings, and operating authority status",
    icon: ClipboardCheck,
  },
  {
    title: "Fraud Detection & Prevention",
    description:
      "Identify suspicious patterns, double brokering attempts, and fraudulent documentation in real-time",
    icon: Brain,
  },
];

export default FreightBrokers;
