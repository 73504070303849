/**
 * Utility functions for string manipulation and comparison
 */

/**
 * Calculates the Levenshtein distance between two strings.
 * This measures the difference between two sequences by counting
 * the minimum number of single-character edits required to change one string into the other.
 *
 * @param {string} a - First string to compare
 * @param {string} b - Second string to compare
 * @returns {number} The Levenshtein distance between the two strings
 */
export function calculateLevenshteinDistance(a, b) {
  const matrix = Array.from({ length: b.length + 1 }, (_, i) => [i]);

  for (let i = 0; i <= a.length; i++) {
    matrix[0][i] = i;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b[i - 1] === a[j - 1]) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          matrix[i][j - 1] + 1, // insertion
          matrix[i - 1][j] + 1 // deletion
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

/**
 * Normalizes OCR text by removing unwanted characters and extra spaces.
 *
 * @param {string} ocrText - The OCR text to be cleaned.
 * @returns {string} The normalized OCR text.
 */
export function normalizeOCRText(ocrText) {
  return ocrText
    .replace(/[^a-zA-Z0-9\s:-]/g, "") // Remove non-alphanumeric and non-essential characters
    .replace(/\s+/g, " ") // Normalize spaces
    .trim();
}
