import * as React from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MoreHorizontal, Eye, Edit, Trash, Info, Users } from "lucide-react";
import { useNavigate } from "react-router-dom";

// Status style mapping
const statusStyles = {
  active: {
    label: "Active",
    className:
      "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
  },
  inactive: {
    label: "Inactive",
    className: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100",
  },
};

// Format phone number helper
const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return "Not provided";
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};

// Actions component that properly uses the useNavigate hook
function ActionsCell({ cargoOwner }) {
  const navigate = useNavigate();

  const handleViewDetails = (id) => {
    console.log(`View cargo owner ${id}`);
  };

  const handleEdit = (id) => {
    console.log(`Edit cargo owner ${id}`);
  };

  const handleDelete = (id) => {
    console.log(`Delete cargo owner ${id}`);
  };

  const handleManageUsers = (id) => {
    navigate(`/dashboard/cargo-owners/${id}/users`);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Open menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => handleViewDetails(cargoOwner?.id)}>
          <Eye className="mr-2 h-4 w-4" />
          <span>View</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleEdit(cargoOwner?.id)}>
          <Edit className="mr-2 h-4 w-4" />
          <span>Edit</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleManageUsers(cargoOwner?.id)}>
          <Users className="mr-2 h-4 w-4" />
          <span>Manage Users</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleDelete(cargoOwner?.id)}>
          <Trash className="mr-2 h-4 w-4" />
          <span>Delete</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

// Core/essential columns that are always displayed
export const columns = [
  {
    accessorKey: "id",
    header: "ID",
    enableSorting: true,
    cell: ({ row }) => {
      const id = row.original?.id;

      // If no ID, return empty string
      if (!id) return <div className="font-medium">-</div>;

      // Truncate long IDs
      const truncatedId =
        id.length > 10
          ? `${id.substring(0, 8)}...${id.substring(id.length - 4)}`
          : id;

      return (
        <div className="font-medium font-mono relative group">
          <span>{truncatedId}</span>

          {/* Tooltip with full ID */}
          {id.length > 10 && (
            <div className="absolute z-10 hidden group-hover:block mt-1 p-2 bg-slate-800 text-white text-xs rounded shadow-lg whitespace-nowrap">
              {id}
              <button
                className="ml-2 text-blue-300 hover:text-blue-100"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(id);
                }}
              >
                Copy
              </button>
            </div>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "company_name",
    header: "Company Name",
    enableSorting: true,
    cell: ({ row }) => {
      const companyName = row.original?.company_name;
      return (
        <div className="truncate max-w-[200px]" title={companyName}>
          {companyName || "Unnamed Company"}
        </div>
      );
    },
  },
  {
    accessorKey: "email",
    header: "Email",
    enableSorting: true,
    cell: ({ row }) => {
      const email = row.original?.email;
      return (
        <div className="truncate max-w-[150px] relative group">
          <span title={email}>{email || "No email"}</span>
          {email && (
            <div className="absolute z-10 hidden group-hover:block mt-1 p-2 bg-slate-800 text-white text-xs rounded shadow-lg whitespace-nowrap">
              {email}
              <button
                className="ml-2 text-blue-300 hover:text-blue-100"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(email);
                }}
              >
                Copy
              </button>
            </div>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "phone_number",
    header: "Phone",
    enableSorting: true,
    cell: ({ row }) => {
      const phoneNumber = formatPhoneNumber(row.original?.phone_number);
      return (
        <div className="truncate max-w-[120px]" title={phoneNumber}>
          {phoneNumber}
        </div>
      );
    },
  },
  {
    accessorKey: "is_active",
    header: "Status",
    enableSorting: true,
    cell: ({ row }) => {
      const isActive = row.original?.is_active;
      const status = isActive ? "active" : "inactive";
      const { label, className } = statusStyles[status];

      return (
        <Badge className={className} variant="outline">
          {label}
        </Badge>
      );
    },
  },
  {
    id: "details",
    header: "Details",
    enableSorting: false,
    cell: ({ row }) => {
      const cargoOwner = row.original;

      // Combine additional info for tooltip
      const industryInfo = cargoOwner?.industry || "Not specified";
      const companyIdInfo = cargoOwner?.company_id || "Not available";
      const accessIdInfo = cargoOwner?.company_access_id || "Not available";

      return (
        <div className="relative group">
          <Button variant="ghost" className="h-8 p-1">
            <Info className="h-4 w-4 text-gray-500" />
          </Button>

          <div className="absolute z-10 hidden group-hover:block left-0 mt-1 p-2 bg-slate-800 text-white text-xs rounded shadow-lg whitespace-nowrap">
            <div>
              <strong>Industry:</strong> {industryInfo}
            </div>
            <div>
              <strong>Company ID:</strong> {companyIdInfo}
            </div>
            <div>
              <strong>Access ID:</strong> {accessIdInfo}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    cell: ({ row }) => {
      return <ActionsCell cargoOwner={row.original} />;
    },
  },
];
