// @ts-check
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CustomersService from "../api/services/business/CustomersService";

/**
 * Hook to fetch paginated customers
 * @param {number} page - The page number to fetch
 * @param {number} limit - Number of items per page
 * @returns {Object} The query result containing paginated customers
 */
export const useCustomers = (page = 1, limit = 10) => {
  return useQuery({
    queryKey: ["customers", page, limit],
    queryFn: async () => {
      try {
        const result = await CustomersService.getCustomers(page, limit);
        return result;
      } catch (error) {
        // Ensure error is properly structured
        throw new Error(error.message || "Failed to fetch customers");
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

/**
 * Hook to fetch the company ID for a customer by user ID
 * @param {string} userId - The user ID to fetch company ID for
 * @returns {Object} The query result containing company ID
 */
export const useCustomerCompanyId = (userId) => {
  return useQuery({
    queryKey: ["customerCompanyId", userId],
    queryFn: async () => {
      try {
        return await CustomersService.getCustomerCompanyId(userId);
      } catch (error) {
        throw new Error(error.message || "Failed to fetch company ID");
      }
    },
    enabled: !!userId,
    staleTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to fetch a specific customer by ID
 * @param {string} id - The customer ID to fetch
 * @returns {Object} The query result containing customer details
 */
export const useCustomer = (id) => {
  return useQuery({
    queryKey: ["customer", id],
    queryFn: async () => {
      try {
        const result = await CustomersService.getCustomer(id);
        return result;
      } catch (error) {
        throw new Error(error.message || "Failed to fetch customer");
      }
    },
    enabled: !!id,
    retry: false, // Prevent retries
    refetchOnWindowFocus: false, // Prevent auto-refetching
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

/**
 * Hook to fetch customers by company ID
 * @param {string} companyId - The company ID to fetch customers for
 * @param {number} page - The page number to fetch
 * @param {number} limit - Number of items per page
 * @returns {Object} The query result containing paginated customers
 */
export const useCustomersByCompany = (companyId, page = 1, limit = 10) => {
  return useQuery({
    queryKey: ["customers", "company", companyId, page, limit],
    queryFn: () =>
      CustomersService.getCustomersByCompany(companyId, page, limit),
    enabled: !!companyId,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

/**
 * Hook to fetch a customer by user ID
 * @param {string} userId - The user ID to fetch customer for
 * @returns {Object} The query result containing customer details
 */
export const useCustomerByUserId = (userId) => {
  return useQuery({
    queryKey: ["customer", "user", userId],
    queryFn: () => CustomersService.getCustomerByUserId(userId),
    enabled: !!userId,
    staleTime: 10 * 60 * 1000, // 10 minutes
  });
};

/**
 * Hook providing all customer-related mutations
 * @returns {Object} Mutation functions for customer operations
 */
export const useCustomerMutations = () => {
  const queryClient = useQueryClient();

  const createCustomer = useMutation({
    mutationFn: (customerData) => CustomersService.createCustomer(customerData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["customers"] });
    },
    onError: (error) => {
      console.error("Error creating customer:", error);
    },
  });

  const updateCustomer = useMutation({
    mutationFn: ({ id, customerData }) =>
      CustomersService.updateCustomer(id, customerData),
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: ["customers"] });
      queryClient.invalidateQueries({ queryKey: ["customer", id] });
    },
    onError: (error) => {
      console.error("Error updating customer:", error);
    },
  });

  const deleteCustomer = useMutation({
    mutationFn: (id) => CustomersService.deleteCustomer(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["customers"] });
    },
    onError: (error) => {
      console.error("Error deleting customer:", error);
    },
  });

  return {
    createCustomer,
    updateCustomer,
    deleteCustomer,
  };
};
