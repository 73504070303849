import React from "react";
import { Shield, ShieldCheck, ShieldAlert, ShieldX, Clock } from "lucide-react";

/**
 * A reusable verification status badge component
 *
 * @param {Object} props
 * @param {string} props.status - The verification status (approved, completed, declined, pending, expired, etc.)
 * @param {boolean} props.success - Whether the verification was successful
 * @param {string} props.size - Size of badge (sm, md, lg)
 * @param {boolean} props.showLabel - Whether to show the status text
 * @param {string} props.customLabel - Custom label to override default status text
 */
const VerificationBadge = ({
  status,
  success,
  size = "md",
  showLabel = true,
  customLabel = null,
}) => {
  // Normalize status to lowercase
  const normalizedStatus = (status || "").toLowerCase();

  // Determine status details based on status string and success flag
  let statusColor = "gray";
  let statusText = "Unknown";
  let Icon = Shield;

  if (
    success ||
    [
      "approved",
      "complete",
      "completed",
      "verified",
      "persona_complete",
    ].includes(normalizedStatus)
  ) {
    statusColor = "green";
    statusText = customLabel || "Verified";
    Icon = ShieldCheck;
  } else if (
    ["declined", "failed", "rejected", "persona_failed"].includes(
      normalizedStatus
    )
  ) {
    statusColor = "red";
    statusText = customLabel || "Failed";
    Icon = ShieldX;
  } else if (
    [
      "pending",
      "processing",
      "started",
      "in_progress",
      "in progress",
      "persona_pending",
    ].includes(normalizedStatus)
  ) {
    statusColor = "yellow";
    statusText = customLabel || "Pending";
    Icon = Clock;
  } else if (["expired", "abandoned"].includes(normalizedStatus)) {
    statusColor = "red";
    statusText = customLabel || "Expired";
    Icon = ShieldAlert;
  } else if (["created"].includes(normalizedStatus)) {
    statusColor = "blue";
    statusText = customLabel || "Created";
    Icon = Shield;
  }

  // Size classes
  const sizeClasses = {
    sm: "text-xs px-2 py-0.5",
    md: "text-sm px-3 py-1",
    lg: "text-base px-4 py-1.5",
  };

  // Color classes
  const colorClasses = {
    green: "bg-green-100 text-green-800 border-green-200",
    red: "bg-red-100 text-red-800 border-red-200",
    yellow: "bg-yellow-100 text-yellow-800 border-yellow-200",
    blue: "bg-blue-100 text-blue-800 border-blue-200",
    gray: "bg-gray-100 text-gray-800 border-gray-200",
  };

  return (
    <div
      className={`inline-flex items-center rounded-full border ${colorClasses[statusColor]} ${sizeClasses[size]}`}
    >
      <Icon
        size={size === "sm" ? 14 : size === "md" ? 16 : 20}
        className="mr-1.5"
      />
      {showLabel && <span className="font-medium">{statusText}</span>}
    </div>
  );
};

export default VerificationBadge;
