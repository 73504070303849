// Utility functions for Persona Results components
import React from "react";

// Define which fields should be rendered as dates
export const dateFields = [
  "created-at",
  "completed-at",
  "redacted-at",
  "submitted-at",
];

// Format date strings consistently
export const formatDate = (dateString) => {
  if (!dateString)
    return <span className="text-slate-400 italic">Not Available</span>;

  try {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return <span className="text-slate-400 italic">Invalid Date</span>;
    }

    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  } catch (error) {
    return <span className="text-slate-400 italic">Invalid Date</span>;
  }
};

// Helper function to format attribute names properly
export const formatAttributeName = (key) => {
  if (!key) return "Not Found";

  // Convert kebab-case or snake_case to Title Case with spaces
  return key
    .replace(/[-_]/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Handle camelCase
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// Helper function to format attribute values properly
export const formatAttributeValue = (value) => {
  if (typeof value !== "string") return value;

  // Handle specific formatting for known values
  if (value === "MOBILE") return "Mobile";
  if (value === "LANDLINE") return "Landline";
  if (value === "VOIP") return "VoIP";

  // For other values that use dashes or underscores, capitalize each word
  if (value.includes("-") || value.includes("_")) {
    return value
      .split(/[-_]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  // For single word values, capitalize first letter
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

// Helper function to render boolean values consistently
export const renderBooleanValue = (value) => {
  // Convert various formats to boolean
  const boolValue = value === true || value === "true" || value === 1;

  return (
    <span
      className={`inline-flex items-center ${
        boolValue ? "text-emerald-600" : "text-red-600"
      }`}
    >
      <span
        className={`mr-1.5 h-2.5 w-2.5 rounded-full ${
          boolValue ? "bg-emerald-500" : "bg-red-500"
        }`}
      />
      {boolValue ? "Yes" : "No"}
    </span>
  );
};

// Render attribute value with proper formatting
export const renderAttributeValue = (value) => {
  if (value === null || value === undefined) {
    return <span className="text-slate-400 italic">Not Found</span>;
  }

  if (typeof value === "boolean") {
    return renderBooleanValue(value);
  }

  if (typeof value === "string") {
    // Format special values
    if (value === "not_applicable") {
      return <span className="text-slate-500">Not Applicable</span>;
    }

    // Return with a visible text color for string values
    return <span className="text-slate-700">{value}</span>;
  }

  if (typeof value === "object") {
    if (Array.isArray(value) && value.length === 0) {
      return <span className="text-slate-400 italic">None</span>;
    }

    return (
      <pre className="text-xs bg-white p-3 rounded border border-slate-200 overflow-x-auto text-slate-700">
        {JSON.stringify(value, null, 2)}
      </pre>
    );
  }

  // Default return with text color
  return <span className="text-slate-700">{value}</span>;
};

// Special value renderer for risk reports
export const renderRiskReportAttributeValue = (value, key) => {
  // Handle null/undefined/empty values
  if (value === null || value === undefined || value === "") {
    return <span className="text-slate-400 italic">Not Available</span>;
  }

  // Handle boolean values
  if (typeof value === "boolean") {
    return renderBooleanValue(value);
  }

  // Handle string values that represent booleans
  if (
    typeof value === "string" &&
    (value.toLowerCase() === "true" || value.toLowerCase() === "false")
  ) {
    return renderBooleanValue(value.toLowerCase() === "true");
  }

  // Handle number values and ensure days fields are rendered as numbers
  if (
    typeof value === "number" ||
    (key.includes("days") && !isNaN(parseInt(value)))
  ) {
    // Convert string to number if needed
    const numValue = typeof value === "number" ? value : parseInt(value);

    // Format with comma separators for thousands
    return numValue.toLocaleString();
  }

  // Handle string values
  if (typeof value === "string") {
    // Handle email reputations specifically
    if (key === "email-reputation" || key === "phone-risk-level") {
      const reputationClassMap = {
        high: "text-emerald-600 font-medium",
        medium: "text-amber-600 font-medium",
        "medium-high": "text-amber-600 font-medium",
        "medium-low": "text-amber-500 font-medium",
        low: "text-red-600 font-medium",
      };

      return (
        <span className={reputationClassMap[value] || "text-slate-700"}>
          {formatAttributeValue(value)}
        </span>
      );
    }

    // For other string values, format with proper capitalization
    return formatAttributeValue(value);
  }

  // Return the value as is if no special handling is needed
  return String(value);
};

// Constants
export const REPORT_TYPE_NAMES = {
  "report/phone-number": "Phone Risk Report",
  "report/email-address": "Email Risk Report",
  "report/business-lookup": "Business Lookup Report",
  "report/business-adverse-media": "Business Adverse Media Report",
  "report/business-registrations-lookup":
    "Business Registrations Lookup Report",
  "verification/database-business": "Business Verification",
  // Add Transaction types
  transaction: "Business Verification Transaction",
  // Add specific mappings for business verification
  business_verification: "Business Registration Verification",
  "business-verification-transaction": "Business Registration Transaction",
  // Add fallback mapping for any unknown types
  unknown: "Unknown Report Type",
};

export const STATUS_CONFIG = {
  completed: {
    label: "Completed",
    color: "bg-emerald-100 text-emerald-800 border-emerald-200",
    icon: "✓",
  },
  failed: {
    label: "Failed",
    color: "bg-red-100 text-red-800 border-red-200",
    icon: "✗",
  },
  pending: {
    label: "In Progress",
    color: "bg-amber-100 text-amber-800 border-amber-200",
    icon: "⟳",
  },
  error: {
    label: "Error",
    color: "bg-red-100 text-red-800 border-red-200",
    icon: "!",
  },
};
