import React from "react";
import { CalendarIcon, ChevronLeft, ChevronRight } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";

// Sample data - in a real app, this would come from an API
const DRIVERS = [
  { name: "Carlos Rodriguez", time: "08:30 AM", date: "Mar 15" },
  { name: "Maria Gonzalez", time: "09:45 AM", date: "Mar 15" },
  { name: "John Smith", time: "11:00 AM", date: "Mar 15" },
  { name: "Ana Martinez", time: "02:30 PM", date: "Mar 15" },
  { name: "James Johnson", time: "10:15 AM", date: "Mar 16" },
  { name: "Isabella Ramirez", time: "01:00 PM", date: "Mar 16" },
  { name: "Jose Torres", time: "03:30 PM", date: "Mar 16" },
  { name: "Carmen Rivera", time: "09:00 AM", date: "Mar 17" },
  { name: "Michael Sanchez", time: "11:30 AM", date: "Mar 17" },
  { name: "Rosa Chavez", time: "04:15 PM", date: "Mar 17" },
  { name: "David Brown", time: "10:45 AM", date: "Mar 18" },
  { name: "Lucia Ortiz", time: "02:00 PM", date: "Mar 18" },
];

// Mini-calendar day component
const CalendarDay = ({ day, isCurrentMonth, isSelected, hasSchedule }) => (
  <Button
    variant={isSelected ? "default" : "ghost"}
    className={`w-8 h-8 p-0 text-xs ${
      !isCurrentMonth ? "text-muted-foreground opacity-50" : ""
    } ${hasSchedule && !isSelected ? "border border-primary/40" : ""}`}
    disabled={!isCurrentMonth}
  >
    {day}
  </Button>
);

// Driver list item component
const DriverListItem = ({ driver }) => {
  const initials = driver.name
    .split(" ")
    .map((n) => n[0])
    .join("");

  return (
    <div className="flex items-center gap-3 mb-2 py-2 px-3 rounded-md hover:bg-muted transition-colors">
      <Avatar className="h-8 w-8">
        <AvatarFallback className="bg-primary/10 text-primary">
          {initials}
        </AvatarFallback>
      </Avatar>
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium leading-none truncate">
          {driver.name}
        </p>
        <div className="flex items-center text-xs text-muted-foreground mt-1">
          <CalendarIcon className="mr-1 h-3 w-3" />
          <span>{driver.time}</span>
        </div>
      </div>
    </div>
  );
};

const ScheduledDrivers = () => {
  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.toLocaleString("default", { month: "long" });
  const currentYear = today.getFullYear();

  // Generate days for mini calendar
  const generateCalendarDays = () => {
    const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    const daysInMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).getDate();
    const firstDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    ).getDay();

    // Days with scheduled drivers (would come from real data)
    const scheduledDays = [2, 5, 8, 12, 15, 16, 17, 18];

    // Calculate previous month's spillover days
    const prevMonthDays = Array.from({ length: firstDayOfMonth }, (_, i) => ({
      day:
        new Date(today.getFullYear(), today.getMonth(), 0).getDate() -
        firstDayOfMonth +
        i +
        1,
      currentMonth: false,
      hasSchedule: false,
    }));

    // Current month days
    const currentMonthDays = Array.from({ length: daysInMonth }, (_, i) => ({
      day: i + 1,
      currentMonth: true,
      hasSchedule: scheduledDays.includes(i + 1),
    }));

    // Next month spillover days
    const nextMonthDays = Array.from(
      { length: 42 - (prevMonthDays.length + currentMonthDays.length) },
      (_, i) => ({
        day: i + 1,
        currentMonth: false,
        hasSchedule: false,
      })
    );

    return {
      daysOfWeek,
      calendarDays: [...prevMonthDays, ...currentMonthDays, ...nextMonthDays],
    };
  };

  const { daysOfWeek, calendarDays } = generateCalendarDays();
  const todaysDrivers = DRIVERS.filter((d) => d.date === "Mar 15");

  return (
    <Card className="h-full">
      <CardHeader className="px-6 py-4">
        <CardTitle className="text-base font-medium">
          Scheduled Verifications
        </CardTitle>
      </CardHeader>

      <CardContent className="p-0 mb-6">
        {/* Today's drivers */}
        <div className="px-6 pb-4">
          <Badge variant="outline" className="mb-2 bg-primary/10 text-primary">
            Today
          </Badge>
          <ScrollArea className="h-[180px] pr-4">
            {todaysDrivers.length > 0 ? (
              todaysDrivers.map((driver, i) => (
                <DriverListItem key={i} driver={driver} />
              ))
            ) : (
              <p className="text-sm text-muted-foreground py-2">
                No drivers scheduled for today
              </p>
            )}
          </ScrollArea>
        </div>
      </CardContent>

      <CardContent className="p-0">
        {/* Mini calendar */}
        <div className="border-t px-6 py-4">
          <div className="flex items-center justify-between mb-4">
            <Button variant="ghost" size="icon" className="h-7 w-7">
              <ChevronLeft className="h-4 w-4" />
              <span className="sr-only">Previous month</span>
            </Button>
            <h2 className="text-sm font-medium">
              {currentMonth} {currentYear}
            </h2>
            <Button variant="ghost" size="icon" className="h-7 w-7">
              <ChevronRight className="h-4 w-4" />
              <span className="sr-only">Next month</span>
            </Button>
          </div>

          <div className="grid grid-cols-7 gap-1 text-center">
            {/* Day headers */}
            {daysOfWeek.map((day) => (
              <div
                key={`header-${day}`}
                className="text-xs text-muted-foreground font-medium"
              >
                {day}
              </div>
            ))}

            {/* Calendar days */}
            {calendarDays.map((day, i) => (
              <CalendarDay
                key={`day-${i}`}
                day={day.day}
                isCurrentMonth={day.currentMonth}
                isSelected={day.currentMonth && day.day === currentDay}
                hasSchedule={day.hasSchedule}
              />
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ScheduledDrivers;
