// src/api/strategies/VerificationFactory.js
import { supabase } from "../../lib/supabase";
import { VerificationRepository } from "../repositories/VerificationRepository";
import { providerFactory } from "../factories/ProviderFactory";
import { DriverVerificationStrategy } from "./DriverVerificationStrategy";
import { AllVerificationStrategy } from "./AllVerificationStrategy";
import BusinessVerificationStrategy from "./BusinessVerificationStrategy";
import InsuranceVerificationStrategy from "./InsuranceVerificationStrategy";
import { BusinessVerificationService } from "../services/verification/BusinessVerificationService";
import { InsuranceVerificationService } from "../services/verification/InsuranceVerificationService";

export class VerificationFactory {
  constructor(dependencies = {}) {
    this._dependencies = {
      supabase,
      verificationRepository: new VerificationRepository(supabase),
      providerFactory,
      ...dependencies,
    };
    this._driverStrategy = null;
    this._allStrategy = null;
    this._businessStrategy = null;
    this._insuranceStrategy = null;
    this.businessService =
      dependencies.businessService || BusinessVerificationService;
    this.insuranceService =
      dependencies.insuranceService || InsuranceVerificationService;
  }

  get driverStrategy() {
    if (!this._driverStrategy) {
      this._driverStrategy = new DriverVerificationStrategy(this._dependencies);
    }
    return this._driverStrategy;
  }

  get allStrategy() {
    if (!this._allStrategy) {
      this._allStrategy = new AllVerificationStrategy(this._dependencies);
    }
    return this._allStrategy;
  }

  get businessStrategy() {
    if (!this._businessStrategy) {
      this._businessStrategy = new BusinessVerificationStrategy(this._dependencies);
    }
    return this._businessStrategy;
  }

  get insuranceStrategy() {
    if (!this._insuranceStrategy) {
      this._insuranceStrategy = new InsuranceVerificationStrategy(this._dependencies);
    }
    return this._insuranceStrategy;
  }

  getStrategy(verificationType) {
    console.log(
      "VerificationFactory: Getting strategy for type:",
      verificationType
    );

    if (verificationType === "all") {
      console.log("VerificationFactory: Using all strategy");
      return this.allStrategy;
    }

    if (verificationType === "driver_shipment") {
      console.log("VerificationFactory: Using driver strategy");
      return this.driverStrategy;
    }

    if (verificationType === "driver_verification") {
      console.log("VerificationFactory: Using driver strategy for driver_verification");
      return this.driverStrategy;
    }

    if (verificationType === "business_verification") {
      console.log("VerificationFactory: Using business verification strategy");
      return this.businessStrategy;
    }

    if (verificationType === "insurance_verification") {
      console.log("VerificationFactory: Using insurance verification strategy");
      return this.insuranceStrategy;
    }

    // Default to all strategy for unknown types
    console.log("VerificationFactory: Unknown type, using all strategy");
    return this.allStrategy;
  }
}

// Create and export a singleton instance with required dependencies
export const verificationFactory = new VerificationFactory();
