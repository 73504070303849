// @ts-check
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ShipmentService } from "../api/services/business/ShipmentService";

/**
 * Hook to fetch paginated shipments
 * @param {number} page - The page number to fetch
 * @param {number} limit - Number of items per page
 * @param {Object} filters - Filters to apply to the query
 * @returns {Object} The query result containing shipments
 */
export const useShipments = (page = 1, limit = 1000, filters = {}) => {
  return useQuery({
    queryKey: ["shipments", page, limit, filters],
    queryFn: () => ShipmentService.getShipments(page, limit, filters),
    keepPreviousData: true,
    staleTime: 30000, // 30 seconds
    cacheTime: 300000, // 5 minutes
  });
};

/**
 * Hook to fetch shipments for a specific cargo owner
 * @param {number} page - The page number to fetch
 * @param {number} limit - Number of items per page
 * @param {Object} filters - Filters to apply to the query
 * @param {string} cargoOwnerId - ID of the cargo owner
 * @returns {Object} The query result containing shipments
 */
export const useShipmentByCargoOwnerId = (
  page = 1,
  limit = 10,
  filters = {},
  cargoOwnerId
) => {
  return useQuery({
    queryKey: ["shipments", page, limit, filters, cargoOwnerId],
    queryFn: () =>
      ShipmentService.getShipments(page, limit, filters, cargoOwnerId),
    enabled: !!cargoOwnerId,
    staleTime: 30000, // 30 seconds
    cacheTime: 300000, // 5 minutes
  });
};

/**
 * Hook to fetch a specific shipment by ID
 * @param {string} id - The shipment ID to fetch
 * @param {string|null} cargoOwnerId - Optional cargo owner ID for filtering
 * @returns {Object} The query result containing shipment details
 */
export const useShipment = (id, cargoOwnerId = null) => {
  return useQuery({
    queryKey: ["shipment", id, cargoOwnerId],
    queryFn: () => ShipmentService.getShipment(id, cargoOwnerId),
    enabled: !!id,
    staleTime: 30000, // 30 seconds
    cacheTime: 300000, // 5 minutes
  });
};

/**
 * Hook providing all shipment-related mutations
 * @param {string|null} cargoOwnerId - Optional cargo owner ID for filtering
 * @returns {Object} Mutation functions for shipment operations
 */
export const useShipmentMutations = (cargoOwnerId = null) => {
  const queryClient = useQueryClient();

  /**
   * Helper to invalidate shipment queries
   * @param {string} id - Optional shipment ID to invalidate specific queries
   */
  const invalidateShipments = async (id) => {
    if (cargoOwnerId) {
      await queryClient.invalidateQueries({
        queryKey: ["shipments", { cargoOwnerId }],
      });
      if (id) {
        await queryClient.invalidateQueries({
          queryKey: ["shipment", id, cargoOwnerId],
        });
      }
    } else {
      await queryClient.invalidateQueries({ queryKey: ["shipments"] });
      if (id) {
        await queryClient.invalidateQueries({ queryKey: ["shipment", id] });
      }
    }
  };

  const updateShipmentStatus = useMutation({
    mutationFn: ({ id, status }) =>
      ShipmentService.updateShipmentStatus(id, status),
    onMutate: async ({ id, status }) => {
      await queryClient.cancelQueries({
        queryKey: ["shipment", id, cargoOwnerId],
      });

      const previousShipment = queryClient.getQueryData([
        "shipment",
        id,
        cargoOwnerId,
      ]);

      queryClient.setQueryData(["shipment", id, cargoOwnerId], (old) => ({
        ...old,
        status,
        verified_at: new Date().toISOString(),
      }));

      return { previousShipment };
    },
    onError: (err, variables, context) => {
      if (context?.previousShipment) {
        queryClient.setQueryData(
          ["shipment", variables.id, cargoOwnerId],
          context.previousShipment
        );
      }
      console.error("Error updating shipment status:", err);
    },
    onSettled: (_, __, { id }) => {
      invalidateShipments(id);
    },
  });

  const createShipment = useMutation({
    mutationFn: ShipmentService.createShipment,
    onSuccess: () => invalidateShipments(),
    onError: (error) => {
      console.error("Error creating shipment:", error);
    },
  });

  const updateShipment = useMutation({
    mutationFn: ({ id, shipmentData }) =>
      ShipmentService.updateShipment(id, shipmentData),
    onSuccess: (_, { id }) => invalidateShipments(id),
    onError: (error) => {
      console.error("Error updating shipment:", error);
    },
  });

  const deleteShipment = useMutation({
    mutationFn: ShipmentService.deleteShipment,
    onSuccess: (_, id) => invalidateShipments(id),
    onError: (error) => {
      console.error("Error deleting shipment:", error);
    },
  });

  const addShipmentPhoto = useMutation({
    mutationFn: ({ id, photoData }) =>
      ShipmentService.addShipmentPhoto(id, photoData),
    onSuccess: (_, { id }) => invalidateShipments(id),
    onError: (error) => {
      console.error("Error adding shipment photo:", error);
    },
  });

  return {
    createShipment,
    updateShipment,
    deleteShipment,
    updateShipmentStatus,
    addShipmentPhoto,
  };
};
