// @ts-check
import { CarrierService } from "./CarrierService";
import FMSCAService from "./FMSCA"; // Use the existing service

/**
 * Service for fetching and managing carrier data from FMCSA and local database
 */
export class CarrierDataService {
  constructor() {
    // Cache for storing previously fetched data
    this.dataCache = {};
  }

  /**
   * Checks if data for a DOT number is cached
   * @param {string|number} dotNumber - The DOT number to check
   * @returns {boolean} Whether data is cached
   */
  isDataCached(dotNumber) {
    const formattedDot = dotNumber?.toString().trim();
    return Boolean(formattedDot) && Boolean(this.dataCache[formattedDot]);
  }

  /**
   * Gets cached data for a DOT number if available
   * @param {string|number} dotNumber - The DOT number to get data for
   * @returns {Object|null} The cached data or null
   */
  getCachedData(dotNumber) {
    const formattedDot = dotNumber?.toString().trim();
    return formattedDot ? this.dataCache[formattedDot] || null : null;
  }

  /**
   * Fetches all carrier data from FMCSA and updates local database
   * @param {string|number} dotNumber - The DOT number to fetch data for
   * @param {Function} [progressCallback] - Optional callback for progress updates
   * @returns {Promise<Object>} The fetched carrier data and database record
   */
  async fetchCarrierData(dotNumber, progressCallback = () => {}) {
    const formattedDotNumber = dotNumber?.toString().trim();

    if (!formattedDotNumber) {
      throw new Error("No DOT number provided");
    }

    try {
      // Update progress
      progressCallback({
        fmcsaLoading: true,
        carrierDbLoading: false,
        error: null,
        progress: 0,
        currentOperation: "Fetching FMCSA data",
      });

      console.log("Fetching carrier data for DOT:", formattedDotNumber);

      // Fetch all data from FMCSA concurrently
      // Using the existing FMSCA service methods with correct endpoint paths
      const [
        carrierByDOT,
        carrierBasics,
        carrierCargo,
        carrierOperation,
        carrierOOS,
        carrierDocketNumbers,
        carrierAuthority,
      ] = await Promise.all([
        FMSCAService.getCarrierByDOT(formattedDotNumber),
        FMSCAService.getCarrierBasics(formattedDotNumber),
        FMSCAService.getCarrierCargoCarried(formattedDotNumber),
        FMSCAService.getCarrierOperationClassification(formattedDotNumber),
        FMSCAService.getCarrierOOS(formattedDotNumber),
        FMSCAService.getCarrierDocketNumbers(formattedDotNumber),
        FMSCAService.getCarrierAuthority(formattedDotNumber),
      ]);

      if (!carrierByDOT) {
        throw new Error("No carrier data found. Please check your DOT number.");
      }

      // Assemble the complete carrier data
      const result = {
        carrierByDOT,
        carrierBasics,
        carrierCargo,
        carrierOperation,
        carrierOOS,
        carrierDocketNumbers,
        carrierAuthority,
      };

      console.log("The result from the API is", result);

      // Cache the result
      this.dataCache[formattedDotNumber] = result;

      progressCallback({
        fmcsaLoading: false,
        carrierDbLoading: true,
        currentOperation: "Processing carrier data",
        progress: 95,
      });

      // Get existing carrier data
      const existingCarrier = await CarrierService.getCarrierByDOT(
        formattedDotNumber
      );

      // Helper function to create carrier object from data
      const createCarrierObject = (data) => ({
        name: data.carrierByDOT.content.carrier.legalName,
        address: data.carrierByDOT.content.carrier.phyStreet,
        city: data.carrierByDOT.content.carrier.phyCity,
        state: data.carrierByDOT.content.carrier.phyState,
        zip_code: data.carrierByDOT.content.carrier.phyZipcode,
        country:
          data.carrierByDOT.content.carrier.phyCountry === "US"
            ? "USA"
            : data.carrierByDOT.content.carrier.phyCountry,
        // Required fields - need to be provided elsewhere
        phone_number: "", // Required but not in FMCSA data
        email: "", // Required but not in FMCSA data

        // Optional fields
        website: null,
        dot_number: data.carrierByDOT.content.carrier.dotNumber?.toString(),
        mc_number:
          data.carrierDocketNumbers.content[0]?.docketNumber?.toString(),
        tax_id: data.carrierByDOT.content.carrier.ein?.toString(),
        number_of_trucks: data.carrierByDOT.content.carrier.totalPowerUnits,
        number_of_drivers: data.carrierByDOT.content.carrier.totalDrivers,
        safety_rating: data.carrierByDOT.content.carrier.safetyRating,
        is_active: data.carrierByDOT.content.carrier.statusCode === "A",

        // Optional fields we don't have data for
        insurance_provider: null,
        insurance_policy_number: null,
        notes: null,
      });

      let carrierRecord;

      if (!existingCarrier && result.carrierByDOT?.content?.carrier) {
        progressCallback({
          currentOperation: "Creating new carrier record",
          progress: 98,
        });
        const newCarrier = createCarrierObject(result);
        carrierRecord = await CarrierService.insertCarrier(newCarrier);
      } else if (
        this.dataCache[formattedDotNumber] &&
        !existingCarrier &&
        this.dataCache[formattedDotNumber].carrierByDOT?.content?.carrier
      ) {
        progressCallback({
          currentOperation: "Creating carrier from cached data",
          progress: 98,
        });
        const cachedCarrier = createCarrierObject(
          this.dataCache[formattedDotNumber]
        );
        carrierRecord = await CarrierService.insertCarrier(cachedCarrier);
      } else {
        carrierRecord = existingCarrier;
      }

      progressCallback({
        fmcsaLoading: false,
        carrierDbLoading: false,
        currentOperation: "Data fetch complete",
        progress: 100,
      });

      return {
        fmcsaData: result,
        carrier: carrierRecord,
        isNewCarrier: !existingCarrier,
      };
    } catch (error) {
      console.error("[CarrierDataService] Error:", error);
      progressCallback({
        fmcsaLoading: false,
        carrierDbLoading: false,
        error: error.message,
        currentOperation: "Error",
      });
      throw error;
    }
  }
}

// Create a singleton instance
export const carrierDataService = new CarrierDataService();
