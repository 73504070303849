import * as React from "react";
import { VerificationsDataTable } from "./VerificationsDataTable";
import { VerificationStats } from "./VerificationStats";
import { Button } from "@/components/ui/button";
import { Download, Plus } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useGetAllVerifications } from "../../hooks/useVerifications";
import { Link } from "react-router-dom";

// Map *grouped* verification types to display names
const verificationGroupDisplayNames = {
  driver_verification: "Driver Verification",
  business_verification: "Business Verifications",
  risk_report: "Risk Reports",
  // Add other potential top-level groups here if needed
};

// Map original verification types to their group
const verificationTypeToGroup = {
  id_verification: "driver_verification", // Renamed
  insurance_verification: "business_verification",
  business_report: "business_verification",
  business_registration: "business_verification",
  email_risk_report: "risk_report",
  phone_risk_report: "risk_report",
  // Add mappings for any other types like fmcsa_lookup if they exist
};

/**
 * VerificationsDashboard - Main container for the verifications page
 */
export function VerificationsDashboard() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeGroup, setActiveGroup] = React.useState("driver_verification"); // Default to Driver Verification
  const [activeStatus, setActiveStatus] = React.useState("all");
  const [page, setPage] = React.useState(1);
  const [limit] = React.useState(10);

  // Fetch verifications using the useGetAllVerifications hook
  const {
    data: verificationsData,
    isLoading: verificationsLoading,
    error,
  } = useGetAllVerifications();

  // Extract the verifications from the data
  const verifications = React.useMemo(() => {
    return verificationsData || [];
  }, [verificationsData]);

  // Get unique verification *groups* present in the data
  const verificationGroups = React.useMemo(() => {
    const groups = new Set(
      verifications
        .map((v) => verificationTypeToGroup[v.verification_type])
        .filter((group) => group) // Filter out types not mapped to a group
    );
    // Ensure a default order or sort if needed
    return Array.from(groups);
    // Example sorting: return Array.from(groups).sort();
  }, [verifications]);

  // Set a default active group if the initial one isn't present
  React.useEffect(() => {
    if (
      verificationGroups.length > 0 &&
      !verificationGroups.includes(activeGroup)
    ) {
      setActiveGroup(verificationGroups[0]);
    }
  }, [verificationGroups, activeGroup]);

  // Filter verifications based on active group and status
  const filteredVerifications = React.useMemo(() => {
    let filtered = verifications;

    // Filter by group
    if (activeGroup) {
      filtered = filtered.filter(
        (v) => verificationTypeToGroup[v.verification_type] === activeGroup
      );
    }

    // Filter by status (logic remains similar, applies to items within the group)
    if (activeStatus !== "all") {
      filtered = filtered.filter((v) => {
        // Combine statuses that might apply within a group if necessary
        // Example: check persona_verification_status, ocr_status, etc. based on group
        const status = (
          v.persona_verification_status ||
          v.ocr_status || // Add other relevant status fields if needed
          ""
        ).toLowerCase();
        switch (activeStatus) {
          case "completed":
            return status.includes("completed") || status.includes("approved");
          case "pending":
            return status.includes("pending") || status.includes("in progress");
          case "failed":
            return status.includes("failed") || status.includes("rejected");
          default:
            return true;
        }
      });
    }

    return filtered;
  }, [verifications, activeGroup, activeStatus]);

  // Reset pagination when filters change
  React.useEffect(() => {
    setPage(1);
  }, [activeGroup, activeStatus]);

  // Handler for exporting verifications
  const handleExport = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      alert("Verification data has been exported to CSV");
    }, 1500);
  };

  // If there's an error loading verifications
  if (error) {
    return (
      <div className="flex-1 overflow-auto">
        <div className="p-4 sm:p-6 lg:p-8">
          <div className="text-center text-red-500 p-4">
            Error loading verifications: {error.message}
          </div>
        </div>
      </div>
    );
  }

  // Determine the initial active tab if the default isn't available
  const initialActiveTab = verificationGroups.includes(activeGroup)
    ? activeGroup
    : verificationGroups[0] || "";

  return (
    <div className="flex-1 overflow-auto">
      <div className="p-4 sm:p-6 lg:p-8 space-y-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0">
          <div>
            <h2 className="text-2xl font-bold tracking-tight">Verifications</h2>
            <p className="text-muted-foreground">
              Manage and track identity verifications
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="sm"
              className="h-9"
              onClick={handleExport}
              disabled={isLoading}
            >
              <Download className="mr-2 h-4 w-4" />
              Export
            </Button>
            <Link to="/dashboard/vet-driver">
              <Button variant="default" size="sm" className="h-9">
                <Plus className="mr-2 h-4 w-4" />
                Add Verification
              </Button>
            </Link>
          </div>
        </div>

        <VerificationStats verifications={filteredVerifications} />

        <Tabs
          defaultValue={initialActiveTab}
          value={activeGroup}
          onValueChange={setActiveGroup}
          className="space-y-4"
        >
          <TabsList className="flex flex-wrap">
            {verificationGroups.map((group) => (
              <TabsTrigger key={group} value={group}>
                {verificationGroupDisplayNames[group] ||
                  group
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
              </TabsTrigger>
            ))}
          </TabsList>

          {verificationGroups.map((group) => (
            <TabsContent key={group} value={group}>
              <Card>
                <CardHeader className="pb-0">
                  <CardTitle>
                    {verificationGroupDisplayNames[group] ||
                      group
                        .split("_")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                  </CardTitle>
                  <CardDescription>
                    View, filter and manage your verifications for this group.
                  </CardDescription>
                </CardHeader>
                <CardContent className="pt-6">
                  <Tabs
                    defaultValue="all"
                    value={activeStatus}
                    onValueChange={setActiveStatus}
                    className="mb-4"
                  >
                    <TabsList>
                      <TabsTrigger value="all">All Status</TabsTrigger>
                      <TabsTrigger value="completed">Completed</TabsTrigger>
                      <TabsTrigger value="pending">Pending</TabsTrigger>
                      <TabsTrigger value="failed">Failed</TabsTrigger>
                    </TabsList>
                  </Tabs>

                  {verificationsLoading ? (
                    <div className="text-center py-8 text-muted-foreground">
                      Loading verifications...
                    </div>
                  ) : filteredVerifications.length > 0 ? (
                    <VerificationsDataTable
                      data={filteredVerifications}
                      page={page}
                      setPage={setPage}
                      limit={limit}
                    />
                  ) : (
                    <div className="text-center py-8 text-muted-foreground">
                      No verifications found for this group and status.
                    </div>
                  )}
                </CardContent>
              </Card>
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </div>
  );
}
