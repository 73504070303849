import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../../components/ui/tabs";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../components/ui/tooltip";
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from "../../components/ui/collapsible";
import { format, differenceInCalendarDays } from "date-fns";
import {
  Car,
  User,
  Mail,
  MailX,
  Calendar,
  Check,
  Briefcase,
  Award,
  Hash,
  FileText,
  Copy,
  ShieldCheck,
  ShieldAlert,
  XCircle,
  Clock,
  Building,
  BadgeCheck,
  Info,
  AlertTriangle,
  AlertCircle,
  CheckCircle,
  ChevronRight,
  X,
  Activity,
  ThumbsUp,
  ThumbsDown,
  Smartphone,
  Map,
  Network,
  Circle,
  ChevronDown,
  MapPin,
  ListChecks,
  UserCheck,
  Users,
} from "lucide-react";
import { cn } from "../../lib/utils";
import { PhoneRiskReport, PhoneRiskSummaryCard } from "./PhoneRiskReport";

const getLatestReport = (reports = []) => {
  if (!reports || reports.length === 0) return null;
  return [...reports].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  )[0];
};

const getBadgeVariant = (level) => {
  const lowerLevel = level?.toLowerCase();
  if (lowerLevel === "high") return "destructive";
  if (lowerLevel === "medium") return "warning";
  if (lowerLevel === "low") return "success";
  return "secondary";
};

const formatBoolean = (value) => {
  if (value === true) return "Yes";
  if (value === false) return "No";
  return "N/A";
};

const DetailRow = ({
  label,
  value,
  icon,
  valueClassName = "",
  badge = null,
  copyable = false,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(() => {
    if (value === "N/A") return;
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [value]);

  return (
    <div className="flex flex-col space-y-1">
      <p className="text-xs text-slate-500 flex items-center">
        {icon && <span className="mr-1.5">{icon}</span>}
        {label}
      </p>
      <div className="flex items-center">
        <p className={cn("text-sm truncate max-w-[200px]", valueClassName)}>
          {value}
          {badge}
        </p>
        {copyable && value !== "N/A" && (
          <Button
            variant="ghost"
            size="icon"
            className="h-6 w-6 ml-1"
            onClick={handleCopy}
            title="Copy to clipboard"
          >
            {copied ? (
              <Check className="h-3.5 w-3.5 text-emerald-500" />
            ) : (
              <Copy className="h-3.5 w-3.5 text-slate-400" />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

// Define DetailItem helper at the top level scope
const DetailItem = ({ label, value, valueClassName = "" }) => (
  <div className="space-y-1.5">
    <p className="text-xs text-slate-500">{label}</p>
    <p className={cn("text-sm font-medium text-slate-800", valueClassName)}>
      {value}
    </p>
  </div>
);

const EmailRiskCard = ({ emailRiskReport, detailed = false }) => {
  // Handle empty state
  if (!emailRiskReport || !emailRiskReport.results?.rawData?.data?.attributes) {
    return (
      <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm transition-all duration-300 hover:shadow-md">
        <div className="absolute inset-0 bg-gradient-to-r from-slate-50 to-white opacity-50"></div>
        <div className="relative p-5">
          <div className="flex items-center space-x-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-100">
              <MailX className="h-5 w-5 text-slate-400" />
            </div>
            <div>
              <h3 className="text-base font-medium text-slate-800">
                Email Risk Report
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                No email risk data available
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Get the rich data from the nested structure
  const reportData = emailRiskReport.results?.rawData?.data || {};
  const attributes = reportData.attributes || {};

  // --- Data Extraction ---
  const emailAddress = attributes["email-address"] || "N/A";
  const emailDomain = attributes["email-domain"] || "N/A";
  const isDeliverable = attributes["email-is-deliverable"]; // boolean | null
  const isValidMx = attributes["email-is-valid-mx"]; // boolean | null
  const domainExists = attributes["email-domain-exists"]; // boolean | null
  const isDisposable = attributes["email-is-disposable"]; // boolean | null
  const isSpam = attributes["email-is-spam"]; // boolean | null (Not used in UI currently, but available)
  const isSuspicious = attributes["email-is-suspicious"]; // boolean | null
  const isBlocklisted = attributes["email-is-blocklisted"]; // boolean | null
  const isSpoofable = attributes["email-is-spoofable"]; // boolean | null
  const isSpfStrict = attributes["email-is-spf-strict"]; // boolean | null
  const isDmarcEnforced = attributes["email-is-dmarc-enforced"]; // boolean | null
  const emailCredentialsLeaked = attributes["email-credentials-leaked"]; // boolean | null
  const emailCredentialsLeakedRecent =
    attributes["email-credentials-leaked-recent"]; // boolean | null
  const emailDataBreached = attributes["email-data-breached"]; // boolean | null (Not used in UI currently)
  const maliciousActivityDetected =
    attributes["email-malicious-activity-detected"]; // boolean | null
  const maliciousActivityDetectedRecent =
    attributes["email-malicious-activity-detected-recent"]; // boolean | null
  const emailReputation = attributes["email-reputation"] || "unknown"; // high, medium, low, unknown
  const domainReputation = attributes["email-domain-reputation"] || "unknown"; // high, medium, low, unknown
  const domainAgeDays = attributes["email-domain-age-days"]; // number | null
  const estimatedAgeDays = attributes["email-estimated-age-days"]; // number | null
  const isFreeProvider = attributes["email-is-free-provider"]; // boolean | null
  const reportCompletionDate = attributes["completed-at"]; // ISO string | null
  const reportCreationDate = emailRiskReport.created_at; // ISO string

  // --- Risk Level Determination ---
  let riskLevelLabel = "Unknown";
  if (emailReputation === "high") {
    riskLevelLabel = "Low Risk";
  } else if (emailReputation === "medium") {
    riskLevelLabel = "Medium Risk";
  } else if (emailReputation === "low") {
    riskLevelLabel = "High Risk";
  } else if (
    isDeliverable === false || // Explicitly false is high risk
    isDisposable === true ||
    isSuspicious === true ||
    isBlocklisted === true
  ) {
    riskLevelLabel = "High Risk";
  } else if (
    emailCredentialsLeakedRecent === true ||
    maliciousActivityDetectedRecent === true
  ) {
    riskLevelLabel = "High Risk";
  } else if (
    emailCredentialsLeaked === true ||
    maliciousActivityDetected === true
  ) {
    riskLevelLabel = "Medium Risk"; // Non-recent leaks/activity as medium risk
  } else if (
    isDeliverable === true &&
    isValidMx === true &&
    domainExists === true
  ) {
    // If basic checks pass and no high/medium flags, consider low risk
    riskLevelLabel = "Low Risk";
  }

  // --- UI Configuration Map ---
  const riskLevelMap = {
    "Low Risk": {
      color: "emerald",
      icon: <ShieldCheck className="h-4 w-4" />,
      label: "Low Risk",
      badgeBg: "bg-emerald-100",
      badgeText: "text-emerald-700",
      badgeBorder: "border-emerald-200",
      progressBg: "bg-emerald-500",
      percent: 90,
      bgGradient: "from-emerald-50 to-teal-50",
      iconBg: "bg-emerald-500",
    },
    "Medium Risk": {
      color: "amber",
      icon: <AlertTriangle className="h-4 w-4" />,
      label: "Medium Risk",
      badgeBg: "bg-amber-100",
      badgeText: "text-amber-700",
      badgeBorder: "border-amber-200",
      progressBg: "bg-amber-500",
      percent: 50,
      bgGradient: "from-amber-50 to-orange-50",
      iconBg: "bg-amber-500",
    },
    "High Risk": {
      color: "rose",
      icon: <ShieldAlert className="h-4 w-4" />,
      label: "High Risk",
      badgeBg: "bg-rose-100",
      badgeText: "text-rose-700",
      badgeBorder: "border-rose-200",
      progressBg: "bg-rose-500",
      percent: 20,
      bgGradient: "from-rose-50 to-pink-50",
      iconBg: "bg-rose-500",
    },
    Unknown: {
      color: "slate",
      icon: <AlertCircle className="h-4 w-4" />,
      label: "Unknown",
      badgeBg: "bg-slate-100",
      badgeText: "text-slate-700",
      badgeBorder: "border-slate-200",
      progressBg: "bg-slate-400",
      percent: 0, // Represent unknown as 0% progress visually
      bgGradient: "from-slate-50 to-gray-50",
      iconBg: "bg-slate-500",
    },
  };

  const riskInfo = riskLevelMap[riskLevelLabel];

  // --- Formatting & Helpers ---
  const reportDate = reportCompletionDate || reportCreationDate;
  const formattedReportDate = reportDate
    ? format(new Date(reportDate), "MMM d, yyyy · h:mm a")
    : "N/A";

  const formatBooleanOrNull = (
    value,
    positiveLabel = "Yes",
    negativeLabel = "No",
    nullLabel = "Unknown"
  ) => {
    if (value === true) return positiveLabel;
    if (value === false) return negativeLabel;
    return nullLabel;
  };

  const formatLeakStatus = (leaked, recent) => {
    if (recent === true) return "Recently";
    if (leaked === true) return "Yes";
    if (leaked === false) return "No"; // Explicitly false means checked and none found
    return "Unknown"; // null means check wasn't performed or inconclusive
  };

  const formatReputation = (reputation) => {
    if (!reputation || reputation === "unknown") return "Unknown";
    return reputation.charAt(0).toUpperCase() + reputation.slice(1);
  };

  const getReputationClass = (reputation) => {
    if (reputation === "high") return "text-emerald-600";
    if (reputation === "medium") return "text-amber-600";
    if (reputation === "low") return "text-rose-600";
    return "text-slate-600";
  };

  // --- Render ---
  return (
    <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm transition-all duration-300 hover:shadow-md">
      {/* Top status gradient banner */}
      <div
        className={`absolute top-0 inset-x-0 h-1.5 bg-gradient-to-r ${riskInfo.bgGradient}`}
      ></div>

      <div className="pt-6 px-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div
              className={`flex h-10 w-10 items-center justify-center rounded-full text-white ${riskInfo.iconBg}`}
            >
              <Mail className="h-5 w-5" />
            </div>
            <div>
              <h3 className="text-base font-semibold text-slate-800">
                Email Risk Report
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                {formattedReportDate}
              </p>
            </div>
          </div>
          <div
            className={`flex items-center px-3 py-1 rounded-full border ${riskInfo.badgeBg} ${riskInfo.badgeText} ${riskInfo.badgeBorder} text-xs font-medium`}
          >
            {React.cloneElement(riskInfo.icon, { className: "h-3.5 w-3.5" })}
            <span className="ml-1.5">{riskInfo.label}</span>
          </div>
        </div>

        {/* Content Sections */}
        <div className="mt-6 space-y-6">
          {/* Email Info Section */}
          <div className="space-y-4">
            <div className="flex items-center">
              <Mail className="h-4 w-4 text-slate-400" />
              <h4 className="ml-2 text-sm font-medium text-slate-700">
                Email Information
              </h4>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 rounded-lg border border-slate-100 bg-slate-50/70 p-4">
              <DetailItem label="Email Address" value={emailAddress} />
              <DetailItem label="Domain" value={emailDomain} />
            </div>
          </div>

          {/* Deliverability & Security Section */}
          <div className="space-y-4">
            <div className="flex items-center">
              <ShieldCheck className="h-4 w-4 text-slate-400" />
              <h4 className="ml-2 text-sm font-medium text-slate-700">
                Deliverability & Security
              </h4>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 rounded-lg border border-slate-100 bg-slate-50/70 p-4">
              <DetailItem
                label="Deliverable"
                value={formatBooleanOrNull(isDeliverable)}
              />
              <DetailItem
                label="Valid MX Records"
                value={formatBooleanOrNull(isValidMx)}
              />
              <DetailItem
                label="Domain Exists"
                value={formatBooleanOrNull(domainExists)}
              />
              <DetailItem
                label="SPF Strict"
                value={formatBooleanOrNull(isSpfStrict)}
              />
              <DetailItem
                label="DMARC Enforced"
                value={formatBooleanOrNull(isDmarcEnforced)}
              />
              <DetailItem
                label="Spoofable"
                value={formatBooleanOrNull(isSpoofable)}
              />
            </div>
          </div>

          {/* Risk Assessment Section */}
          <div className="space-y-4">
            <div className="flex items-center">
              <AlertTriangle className="h-4 w-4 text-slate-400" />
              <h4 className="ml-2 text-sm font-medium text-slate-700">
                Risk Assessment
              </h4>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 rounded-lg border border-slate-100 bg-slate-50/70 p-4">
              <DetailItem
                label="Email Reputation"
                value={formatReputation(emailReputation)}
                valueClassName={getReputationClass(emailReputation)}
              />
              <DetailItem
                label="Disposable"
                value={formatBooleanOrNull(
                  isDisposable,
                  "Yes",
                  "No",
                  "Unknown"
                )}
              />
              <DetailItem
                label="Suspicious"
                value={formatBooleanOrNull(isSuspicious)}
              />
              <DetailItem
                label="Blocklisted"
                value={formatBooleanOrNull(isBlocklisted)}
              />
              <DetailItem
                label="Free Provider"
                value={formatBooleanOrNull(isFreeProvider)}
              />
              <DetailItem
                label="Credentials Leaked"
                value={formatLeakStatus(
                  emailCredentialsLeaked,
                  emailCredentialsLeakedRecent
                )}
                valueClassName={
                  emailCredentialsLeakedRecent
                    ? "text-rose-600 font-semibold"
                    : emailCredentialsLeaked
                    ? "text-amber-600"
                    : ""
                }
              />
              <DetailItem
                label="Malicious Activity"
                value={formatLeakStatus(
                  maliciousActivityDetected,
                  maliciousActivityDetectedRecent
                )}
                valueClassName={
                  maliciousActivityDetectedRecent
                    ? "text-rose-600 font-semibold"
                    : maliciousActivityDetected
                    ? "text-amber-600"
                    : ""
                }
              />
            </div>
          </div>

          {/* History & Age Section */}
          <div className="space-y-4">
            <div className="flex items-center">
              <Calendar className="h-4 w-4 text-slate-400" />
              <h4 className="ml-2 text-sm font-medium text-slate-700">
                History & Age
              </h4>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 rounded-lg border border-slate-100 bg-slate-50/70 p-4">
              <DetailItem
                label="Domain Reputation"
                value={formatReputation(domainReputation)}
                valueClassName={getReputationClass(domainReputation)}
              />
              <DetailItem
                label="Domain Age"
                value={
                  domainAgeDays
                    ? `${domainAgeDays.toLocaleString()} days`
                    : "N/A"
                }
              />
              <DetailItem
                label="Est. Email Age"
                value={
                  estimatedAgeDays
                    ? `${estimatedAgeDays.toLocaleString()} days`
                    : "N/A"
                }
              />
            </div>
          </div>

          {/* Debug Raw Data */}
          {detailed && (
            <Collapsible className="mt-6 border rounded-md border-slate-200">
              <CollapsibleTrigger className="flex w-full items-center justify-between p-3 text-sm font-medium hover:bg-slate-50">
                <span>Raw Response Data</span>
                <ChevronDown className="h-4 w-4 transition-transform data-[state=open]:rotate-180" />
              </CollapsibleTrigger>
              <CollapsibleContent className="p-4 pt-0 text-xs">
                <pre className="text-xs overflow-x-auto max-h-[300px] bg-slate-100 p-3 rounded">
                  {JSON.stringify(reportData, null, 2)}
                </pre>
              </CollapsibleContent>
            </Collapsible>
          )}
        </div>
      </div>

      {/* Footer with Progress */}
      <div className="mt-6 border-t border-slate-100 pt-4 pb-5 px-6">
        <div className="flex items-center justify-between text-xs mb-1.5">
          <span className="text-slate-500 font-medium">Risk Level</span>
          <div
            className={`flex items-center font-medium text-${riskInfo.color}-600`}
          >
            {React.cloneElement(riskInfo.icon, {
              className: "h-3.5 w-3.5 mr-1",
            })}
            <span>{riskInfo.label}</span>
          </div>
        </div>
        <div className="mt-2 h-2 w-full overflow-hidden rounded-full bg-slate-200">
          <div
            className={cn("h-full rounded-full", riskInfo.progressBg)}
            style={{ width: `${riskInfo.percent}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const BusinessVerificationCard = ({ businessReport, detailed = false }) => {
  // Handle empty/invalid state
  if (!businessReport || !businessReport.results) {
    return (
      <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm transition-all duration-300 hover:shadow-md">
        <div className="absolute inset-0 bg-gradient-to-r from-slate-50 to-white opacity-50"></div>
        <div className="relative p-5">
          <div className="flex items-center space-x-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-100">
              <Building className="h-5 w-5 text-slate-400" />
            </div>
            <div>
              <h3 className="text-base font-medium text-slate-800">
                Business Verification
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                No verification data available
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // --- Data Extraction ---
  const resultsData = businessReport.results || {};
  const verificationAttributes = resultsData.rawData?.data?.attributes || {};
  const status = verificationAttributes["status"]?.toLowerCase() || "unknown"; // e.g., passed, failed, pending
  const businessName = verificationAttributes["business-name"] || "N/A";
  const reportCreationDate = businessReport.created_at;
  const registrationNumber =
    verificationAttributes["registration-number"] || "N/A";
  const addressStreet1 = verificationAttributes["address-street-1"] || null;
  const addressStreet2 = verificationAttributes["address-street-2"] || null;
  const addressCity = verificationAttributes["address-city"] || null;
  const addressSubdivision =
    verificationAttributes["address-subdivision"] || null;
  const addressPostalCode =
    verificationAttributes["address-postal-code"] || null;
  const addressCountryCode =
    verificationAttributes["address-country-code"] || null;
  const verificationCompletedAt =
    verificationAttributes["completed-at"] || null;

  // Extract detailed check results
  const checks = verificationAttributes["checks"] || [];
  const identityComparisonCheck = checks.find(
    (chk) => chk.name === "database_business_identity_comparison"
  );
  const matchResults =
    identityComparisonCheck?.metadata?.["match-requirement-results"] || [];

  // Combine street address parts
  const streetAddress =
    [addressStreet1, addressStreet2].filter(Boolean).join(", ") || "N/A";

  // --- Status Configuration Map ---
  const verificationStatusMap = {
    passed: {
      color: "emerald",
      icon: <BadgeCheck className="h-4 w-4" />,
      label: "Verified",
      badgeBg: "bg-emerald-100",
      badgeText: "text-emerald-700",
      badgeBorder: "border-emerald-200",
      progressBg: "bg-emerald-500",
      percent: 100,
      bgGradient: "from-emerald-50 to-teal-50",
      iconBg: "bg-emerald-500",
    },
    failed: {
      color: "rose",
      icon: <XCircle className="h-4 w-4" />,
      label: "Failed",
      badgeBg: "bg-rose-100",
      badgeText: "text-rose-700",
      badgeBorder: "border-rose-200",
      progressBg: "bg-rose-500",
      percent: 100, // Show full bar but red
      bgGradient: "from-rose-50 to-pink-50",
      iconBg: "bg-rose-500",
    },
    pending: {
      color: "blue",
      icon: <Clock className="h-4 w-4" />,
      label: "Pending",
      badgeBg: "bg-blue-100",
      badgeText: "text-blue-700",
      badgeBorder: "border-blue-200",
      progressBg: "bg-blue-500",
      percent: 50,
      bgGradient: "from-blue-50 to-indigo-50",
      iconBg: "bg-blue-500",
    },
    unknown: {
      color: "slate",
      icon: <AlertCircle className="h-4 w-4" />,
      label: "Unknown",
      badgeBg: "bg-slate-100",
      badgeText: "text-slate-700",
      badgeBorder: "border-slate-200",
      progressBg: "bg-slate-400",
      percent: 0,
      bgGradient: "from-slate-50 to-gray-50",
      iconBg: "bg-slate-500",
    },
  };

  const statusInfo =
    verificationStatusMap[status] || verificationStatusMap.unknown;

  // --- Formatting & Helpers ---
  const reportDate = verificationCompletedAt || reportCreationDate;
  const formattedReportDate = reportDate
    ? format(new Date(reportDate), "MMM d, yyyy · h:mm a")
    : "N/A";

  // --- Render ---
  return (
    <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm transition-all duration-300 hover:shadow-md">
      {/* Top status gradient banner */}
      <div
        className={`absolute top-0 inset-x-0 h-1.5 bg-gradient-to-r ${statusInfo.bgGradient}`}
      ></div>

      <div className="pt-6 px-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div
              className={`flex h-10 w-10 items-center justify-center rounded-full text-white ${statusInfo.iconBg}`}
            >
              <Building className="h-5 w-5" />
            </div>
            <div>
              <h3 className="text-base font-semibold text-slate-800">
                Business Verification
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                {formattedReportDate}
              </p>
            </div>
          </div>
          <div
            className={`flex items-center px-3 py-1 rounded-full border ${statusInfo.badgeBg} ${statusInfo.badgeText} ${statusInfo.badgeBorder} text-xs font-medium`}
          >
            {React.cloneElement(statusInfo.icon, { className: "h-3.5 w-3.5" })}
            <span className="ml-1.5">{statusInfo.label}</span>
          </div>
        </div>

        {/* Content Sections */}
        <div className="mt-6 space-y-6">
          {/* Verification Details Section */}
          <div className="space-y-4">
            <div className="flex items-center">
              <Building className="h-4 w-4 text-slate-400" />
              <h4 className="ml-2 text-sm font-medium text-slate-700">
                Verification Details
              </h4>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 rounded-lg border border-slate-100 bg-slate-50/70 p-4">
              <DetailItem label="Business Name Match" value={businessName} />
              <DetailItem
                label="Registration Number"
                value={registrationNumber}
              />
              <DetailItem
                label="Verification Status"
                value={statusInfo.label}
                valueClassName={`${statusInfo.badgeText} font-semibold`}
              />
            </div>
          </div>

          {/* Registered Address Section */}
          <div className="space-y-4">
            <div className="flex items-center">
              <MapPin className="h-4 w-4 text-slate-400" />
              <h4 className="ml-2 text-sm font-medium text-slate-700">
                Registered Address
              </h4>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 rounded-lg border border-slate-100 bg-slate-50/70 p-4">
              <DetailItem label="Street Address" value={streetAddress} />
              <DetailItem label="City" value={addressCity || "N/A"} />
              <DetailItem
                label="State / Subdivision"
                value={addressSubdivision || "N/A"}
              />
              <DetailItem
                label="Postal Code"
                value={addressPostalCode || "N/A"}
              />
              <DetailItem label="Country" value={addressCountryCode || "N/A"} />
            </div>
          </div>

          {/* Identity Comparison Checks Section */}
          {matchResults.length > 0 && (
            <div className="space-y-4">
              <div className="flex items-center">
                <ListChecks className="h-4 w-4 text-slate-400" />
                <h4 className="ml-2 text-sm font-medium text-slate-700">
                  Identity Comparison Checks
                </h4>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-4 rounded-lg border border-slate-100 bg-slate-50/70 p-4">
                {matchResults.map((result) => {
                  const fieldName = result.name
                    .replace(/_/g, " ")
                    .replace("address ", "") // Remove redundant 'address' prefix
                    .replace(/\b\w/g, (l) => l.toUpperCase()); // Capitalize words

                  const statusPassed = result.status === "passed";
                  const matchLevel = result["match-result"] || "unknown"; // full, partial, none

                  const matchLevelStyles = {
                    full: "text-emerald-600",
                    partial: "text-amber-600",
                    none: "text-rose-600",
                    unknown: "text-slate-500",
                  };
                  const matchLevelText = {
                    full: "Full Match",
                    partial: "Partial Match",
                    none: "No Match",
                    unknown: "Unknown",
                  };

                  return (
                    <div
                      key={result.name}
                      className="flex items-center justify-between space-x-2"
                    >
                      <div className="flex items-center">
                        {statusPassed ? (
                          <CheckCircle className="h-4 w-4 text-emerald-500 mr-2 flex-shrink-0" />
                        ) : (
                          <XCircle className="h-4 w-4 text-rose-500 mr-2 flex-shrink-0" />
                        )}
                        <span className="text-sm text-slate-700">
                          {fieldName}
                        </span>
                      </div>
                      <span
                        className={cn(
                          "text-sm font-medium",
                          matchLevelStyles[matchLevel]
                        )}
                      >
                        {matchLevelText[matchLevel]}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* Debug Raw Data */}
          {detailed && (
            <Collapsible className="mt-6 border rounded-md border-slate-200">
              <CollapsibleTrigger className="flex w-full items-center justify-between p-3 text-sm font-medium hover:bg-slate-50">
                <span>Raw Response Data</span>
                <ChevronDown className="h-4 w-4 transition-transform data-[state=open]:rotate-180" />
              </CollapsibleTrigger>
              <CollapsibleContent className="p-4 pt-0 text-xs">
                <pre className="text-xs overflow-x-auto max-h-[300px] bg-slate-100 p-3 rounded">
                  {JSON.stringify(resultsData, null, 2)}{" "}
                  {/* Show resultsData for debugging */}
                </pre>
              </CollapsibleContent>
            </Collapsible>
          )}
        </div>
      </div>

      {/* Footer with Progress */}
      <div className="mt-6 border-t border-slate-100 pt-4 pb-5 px-6">
        <div className="flex items-center justify-between text-xs mb-1.5">
          <span className="text-slate-500 font-medium">
            Verification Progress
          </span>
          <div
            className={`flex items-center font-medium text-${statusInfo.color}-600`}
          >
            {/* Using the same icon as the badge for consistency */}
            {React.cloneElement(statusInfo.icon, {
              className: "h-3.5 w-3.5 mr-1",
            })}
            <span>{statusInfo.label}</span>
          </div>
        </div>
        <div className="mt-2 h-2 w-full overflow-hidden rounded-full bg-slate-200">
          <div
            className={cn("h-full rounded-full", statusInfo.progressBg)}
            style={{ width: `${statusInfo.percent}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const BusinessRegistrationCard = ({
  businessRegistration,
  detailed = false,
}) => {
  console.log("The business registration data", businessRegistration);
  // Handle empty/invalid state
  if (!businessRegistration) {
    return (
      <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm transition-all duration-300 hover:shadow-md">
        <div className="absolute inset-0 bg-gradient-to-r from-slate-50 to-white opacity-50"></div>
        <div className="relative p-5">
          <div className="flex items-center space-x-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-100">
              <BadgeCheck className="h-5 w-5 text-slate-400" />
            </div>
            <div>
              <h3 className="text-base font-medium text-slate-800">
                Business Registration
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                No registration data available
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // --- Data Extraction ---
  const reportData = businessRegistration?.results || {};
  const processStatus = businessRegistration?.state?.toLowerCase() || "unknown"; // completed, failed, pending(?), unknown
  const reportCreationDate = businessRegistration?.created_at;
  // const lastUpdate = businessRegistration?.updated_at ? new Date(businessRegistration.updated_at).toLocaleString() : "N/A";

  // const success = reportData.success; // Available but maybe less informative than processStatus
  const businessName = reportData.business_name || "N/A";
  const registryRecords = reportData.registry_records || [];

  const activeRecord = registryRecords.find(
    (r) => r.status?.standardized === "active"
  );
  // Use first record as fallback if no active one is found
  const displayRecord = activeRecord || registryRecords[0] || {};
  const displayStatus = displayRecord.status?.standardized || "Unknown";
  const displayState = displayRecord.registration_state || "N/A";
  const businessType = reportData.business_type || "N/A";
  const yearEstablished = reportData.year_established || "N/A";

  // --- Status Configuration Map (for overall process) ---
  const registrationStatusMap = {
    completed: {
      color: "emerald",
      icon: <BadgeCheck className="h-4 w-4" />,
      label: "Completed",
      badgeBg: "bg-emerald-100",
      badgeText: "text-emerald-700",
      badgeBorder: "border-emerald-200",
      progressBg: "bg-emerald-500",
      percent: 100,
      bgGradient: "from-emerald-50 to-teal-50",
      iconBg: "bg-emerald-500",
    },
    failed: {
      color: "rose",
      icon: <XCircle className="h-4 w-4" />,
      label: "Failed",
      badgeBg: "bg-rose-100",
      badgeText: "text-rose-700",
      badgeBorder: "border-rose-200",
      progressBg: "bg-rose-500",
      percent: 100, // Show full bar but red
      bgGradient: "from-rose-50 to-pink-50",
      iconBg: "bg-rose-500",
    },
    // Add pending state if applicable, otherwise it falls to unknown
    pending: {
      color: "blue",
      icon: <Clock className="h-4 w-4" />,
      label: "Pending",
      badgeBg: "bg-blue-100",
      badgeText: "text-blue-700",
      badgeBorder: "border-blue-200",
      progressBg: "bg-blue-500",
      percent: 50,
      bgGradient: "from-blue-50 to-indigo-50",
      iconBg: "bg-blue-500",
    },
    unknown: {
      color: "slate",
      icon: <AlertCircle className="h-4 w-4" />,
      label: "Unknown",
      badgeBg: "bg-slate-100",
      badgeText: "text-slate-700",
      badgeBorder: "border-slate-200",
      progressBg: "bg-slate-400",
      percent: 0,
      bgGradient: "from-slate-50 to-gray-50",
      iconBg: "bg-slate-500",
    },
  };

  const statusInfo =
    registrationStatusMap[processStatus] || registrationStatusMap.unknown;

  // --- Formatting & Helpers ---
  const formattedReportDate = reportCreationDate
    ? format(new Date(reportCreationDate), "MMM d, yyyy · h:mm a")
    : "N/A";

  const formatRegistrationStatus = (status) => {
    if (!status || status === "unknown") return "Unknown";
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  const formatIssueDate = (dateStr) => {
    if (!dateStr) return "N/A";
    try {
      return format(new Date(dateStr), "MMM d, yyyy"); // Adjust format as needed
    } catch {
      return dateStr; // Return original if formatting fails
    }
  };

  const formatRegistrationType = (type) => {
    if (!type || type === "unknown") return "Unknown";
    return type.charAt(0).toUpperCase() + type.slice(1);
  };

  const formatIssuingAuthority = (authority) => {
    if (!authority || authority === "unknown") return "Unknown";
    // Simple formatting: replace underscores, capitalize words
    return authority
      .replace(/_/g, " ")
      .replace(/\b\w/g, (l) => l.toUpperCase());
  };

  const findRegisteredAgent = (persons) => {
    const agent = persons.find((p) =>
      p.positions?.some(
        (pos) => pos.value?.toLowerCase() === "registered agent"
      )
    );
    return agent?.names?.[0]?.value || "N/A";
  };

  const getRegistrationStatusClass = (status) => {
    const lowerStatus = status?.toLowerCase();
    if (lowerStatus === "active") return "text-emerald-600 font-semibold";
    // Add other statuses like inactive, dissolved if needed
    if (lowerStatus === "inactive" || lowerStatus === "dissolved")
      return "text-rose-600";
    return "text-slate-600";
  };

  // Helper to find other officers
  const findOfficers = (persons) => {
    return persons
      .filter((p) =>
        p.positions?.some(
          (pos) => pos.value?.toLowerCase() !== "registered agent"
        )
      )
      .map((p) => ({
        name: p.names?.[0]?.value || "Unknown Name",
        position:
          p.positions
            ?.map((pos) => formatRegistrationStatus(pos.value))
            .join(", ") || "Unknown Position",
      }));
  };

  // Helper to find and format a primary address from a record's addresses array
  const formatRecordAddress = (addresses) => {
    if (!addresses || addresses.length === 0) return "N/A";
    // Prioritize registered or headquarters, fallback to first address
    const preferredTypes = ["registered", "headquarters"];
    let addressToFormat = addresses.find((addr) =>
      preferredTypes.includes(addr.type)
    );
    if (!addressToFormat) addressToFormat = addresses[0];

    const parts = [
      addressToFormat["street-1"],
      addressToFormat["street-2"],
      addressToFormat["city"],
      addressToFormat["subdivision"],
      addressToFormat["postal-code"],
      addressToFormat["country-code"],
    ];
    return parts.filter(Boolean).join(", ") || "N/A";
  };

  // --- Render ---
  return (
    <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm transition-all duration-300 hover:shadow-md">
      {/* Top status gradient banner */}
      <div
        className={`absolute top-0 inset-x-0 h-1.5 bg-gradient-to-r ${statusInfo.bgGradient}`}
      ></div>

      <div className="pt-6 px-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div
              className={`flex h-10 w-10 items-center justify-center rounded-full text-white ${statusInfo.iconBg}`}
            >
              {/* Using BadgeCheck icon for registration theme */}
              <BadgeCheck className="h-5 w-5" />
            </div>
            <div>
              <h3 className="text-base font-semibold text-slate-800">
                Business Registration
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                {formattedReportDate}
              </p>
            </div>
          </div>
          <div
            className={`flex items-center px-3 py-1 rounded-full border ${statusInfo.badgeBg} ${statusInfo.badgeText} ${statusInfo.badgeBorder} text-xs font-medium`}
          >
            {React.cloneElement(statusInfo.icon, { className: "h-3.5 w-3.5" })}
            <span className="ml-1.5">{statusInfo.label}</span>
          </div>
        </div>

        {/* Content Section */}
        <div className="mt-6 space-y-6">
          {/* Top Level Business Info */}
          <div className="space-y-4">
            <div className="flex items-center">
              <Building className="h-4 w-4 text-slate-400" />
              <h4 className="ml-2 text-sm font-medium text-slate-700">
                Business Overview
              </h4>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 rounded-lg border border-slate-100 bg-slate-50/70 p-4">
              <DetailItem label="Queried Business Name" value={businessName} />
              <DetailItem label="Business Type" value={businessType} />
              <DetailItem label="Year Established" value={yearEstablished} />
            </div>
          </div>

          {/* Loop through each Registry Record */}
          {registryRecords.map((record, index) => {
            const recordStatus = record.status?.standardized || "Unknown";
            const recordState = record.registration_state || "N/A";
            const recordFileNumber = record["file-number"] || "N/A";
            const recordIssueDate = record["issue-date"] || null;
            const recordRegType = record["registration-type"] || "Unknown";
            const recordHomeJurisdiction =
              record["home-jurisdiction-state"] || null;
            const recordAuthority = record["issuing-authority"] || "Unknown";
            const recordPersons = record["associated-persons"] || [];
            const registeredAgent = findRegisteredAgent(recordPersons);
            const officers = findOfficers(recordPersons);
            const recordAddress = formatRecordAddress(record.addresses);

            return (
              <div
                key={index}
                className="space-y-4 pt-4 border-t border-dashed border-slate-200 first:border-t-0 first:pt-0"
              >
                <div className="flex items-center">
                  <BadgeCheck className="h-4 w-4 text-slate-400" />
                  <h4 className="ml-2 text-sm font-medium text-slate-700">
                    Registration Record: {recordState}
                  </h4>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-4 rounded-lg border border-slate-100 bg-slate-50/70 p-4">
                  <DetailItem
                    label="Status"
                    value={formatRegistrationStatus(recordStatus)}
                    valueClassName={getRegistrationStatusClass(recordStatus)}
                  />
                  <DetailItem
                    label="Issuing Authority"
                    value={formatIssuingAuthority(recordAuthority)}
                  />
                  <DetailItem label="File Number" value={recordFileNumber} />
                  <DetailItem
                    label="Issue Date"
                    value={formatIssueDate(recordIssueDate)}
                  />
                  <DetailItem
                    label="Registration Type"
                    value={formatRegistrationType(recordRegType)}
                  />
                  {recordRegType?.toLowerCase() === "foreign" &&
                    recordHomeJurisdiction && (
                      <DetailItem
                        label="Home Jurisdiction"
                        value={recordHomeJurisdiction}
                      />
                    )}
                  <DetailItem
                    label="Registered Agent"
                    value={registeredAgent}
                  />
                  <DetailItem label="Primary Address" value={recordAddress} />

                  {/* Display Officers if any */}
                  {officers.length > 0 && (
                    <div className="md:col-span-2 mt-2 pt-2 border-t border-slate-200">
                      <div className="flex items-center mb-2">
                        <Users className="h-4 w-4 text-slate-400" />
                        <h5 className="ml-2 text-xs font-medium text-slate-600">
                          Officers / Associated Persons
                        </h5>
                      </div>
                      <div className="space-y-1.5">
                        {officers.map((officer, idx) => (
                          <p key={idx} className="text-sm text-slate-800">
                            <span className="font-medium">{officer.name}</span>{" "}
                            -{" "}
                            <span className="text-slate-600 text-xs">
                              {officer.position}
                            </span>
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {/* Debug Raw Data */}
          {detailed && (
            <Collapsible className="mt-6 border rounded-md border-slate-200">
              <CollapsibleTrigger className="flex w-full items-center justify-between p-3 text-sm font-medium hover:bg-slate-50">
                <span>Raw Response Data</span>
                <ChevronDown className="h-4 w-4 transition-transform data-[state=open]:rotate-180" />
              </CollapsibleTrigger>
              <CollapsibleContent className="p-4 pt-0 text-xs">
                <pre className="text-xs overflow-x-auto max-h-[300px] bg-slate-100 p-3 rounded">
                  {JSON.stringify(reportData, null, 2)}
                </pre>
              </CollapsibleContent>
            </Collapsible>
          )}
        </div>
      </div>

      {/* Footer with Progress */}
      <div className="mt-6 border-t border-slate-100 pt-4 pb-5 px-6">
        <div className="flex items-center justify-between text-xs mb-1.5">
          <span className="text-slate-500 font-medium">Check Progress</span>
          <div
            className={`flex items-center font-medium text-${statusInfo.color}-600`}
          >
            {React.cloneElement(statusInfo.icon, {
              className: "h-3.5 w-3.5 mr-1",
            })}
            <span>{statusInfo.label}</span>
          </div>
        </div>
        <div className="mt-2 h-2 w-full overflow-hidden rounded-full bg-slate-200">
          <div
            className={cn("h-full rounded-full", statusInfo.progressBg)}
            style={{ width: `${statusInfo.percent}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const InsuranceVerificationCard = ({
  insuranceVerification,
  detailed = false,
}) => {
  const [copied, setCopied] = useState(false);

  console.log("The insurance verification data", insuranceVerification);

  // Find the relevant verification document object in the included array
  const verificationDocument = insuranceVerification?.results?.included?.find(
    (item) => item.type === "verification/document"
  );

  // Extract data from the verification document attributes if available
  const attributes = verificationDocument?.attributes || {};
  const extractedData = attributes.fields || {}; // Extracted fields are here

  // Extract insurance details
  const insuranceData = {
    companyName: extractedData.insuranceCompanyName?.value || "N/A", // Default to N/A if not found
    policyHolder: extractedData.policyHolder?.value || "N/A",
    effectiveDate: extractedData.insuranceEffectiveDate?.value || "N/A",
    expirationDate: extractedData.insuranceExpirationDate?.value || "N/A",
    vehicleMake: extractedData.vehicleMake?.value || "N/A",
    vehicleModel: extractedData.vehicleModel?.value || "N/A",
    vehicleYear: extractedData.vehicleYear?.value || "N/A",
    vin: extractedData.vehicleIdentificationNumber?.value || "N/A",
  };

  // Define copyVIN callback with useCallback hook
  const handleCopy = useCallback(() => {
    if (insuranceData.vin === "N/A") return;
    navigator.clipboard.writeText(insuranceData.vin);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [insuranceData.vin]);

  // Handle case when no data or verification document is found
  if (!insuranceVerification || !verificationDocument) {
    return (
      <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm transition-all duration-300 hover:shadow-md">
        <div className="absolute inset-0 bg-gradient-to-r from-slate-50 to-white opacity-50"></div>
        <div className="relative p-5">
          <div className="flex items-center space-x-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-100">
              <ShieldAlert className="h-5 w-5 text-slate-400" />
            </div>
            <div>
              <h3 className="text-base font-medium text-slate-800">
                Vehicle Insurance Verification
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                No verification data available
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Get verification status from the verification document
  const status = attributes.status || "Pending"; // e.g., "passed", "failed", "pending"
  const isPassed = status.toLowerCase() === "passed";
  const isPending = status.toLowerCase() === "pending";
  const isFailed = !isPassed && !isPending;

  // Format dates if available
  const formatDateString = (dateStr) => {
    if (!dateStr || dateStr === "N/A") return "N/A";

    // Handle different date formats
    try {
      // Try to parse the date (handles formats like MM/DD/YYYY or ISO dates)
      const date = new Date(dateStr);
      if (isNaN(date.getTime())) {
        // If invalid date, return the original string
        return dateStr;
      }
      // Check if the year seems reasonable (e.g., not 1970 from bad parse)
      if (date.getFullYear() < 1980 || date.getFullYear() > 2050) {
        return dateStr; // Return original if year is unlikely
      }
      return format(date, "MMM d, yyyy");
    } catch {
      return dateStr;
    }
  };

  // Check if expiration date is near (within 30 days)
  const isExpirationNear = () => {
    if (insuranceData.expirationDate === "N/A") return false;
    try {
      const expirationDate = new Date(insuranceData.expirationDate);
      const today = new Date();
      const differenceInDays = differenceInCalendarDays(expirationDate, today);
      return differenceInDays <= 30 && differenceInDays > 0;
    } catch {
      return false;
    }
  };

  // Check if insurance is expired
  const isExpired = () => {
    if (insuranceData.expirationDate === "N/A") return false;
    try {
      const expirationDate = new Date(insuranceData.expirationDate);
      const today = new Date();
      return expirationDate < today;
    } catch {
      return false;
    }
  };

  const formattedEffectiveDate = formatDateString(insuranceData.effectiveDate);
  const formattedExpirationDate = formatDateString(
    insuranceData.expirationDate
  );

  const getStatusConfig = () => {
    if (isPassed) {
      return {
        color: "emerald",
        icon: <ShieldCheck className="h-4 w-4" />,
        label: "Verified",
        bgGradient: "from-emerald-50 to-teal-50",
        accentColor: "border-emerald-200",
        statusBg: "bg-emerald-50 text-emerald-700 border-emerald-200",
      };
    } else if (isPending) {
      return {
        color: "blue",
        icon: <Clock className="h-4 w-4" />,
        label: "Pending",
        bgGradient: "from-blue-50 to-indigo-50",
        accentColor: "border-blue-200",
        statusBg: "bg-blue-50 text-blue-700 border-blue-200",
      };
    } else {
      return {
        color: "rose",
        icon: <XCircle className="h-4 w-4" />,
        label: "Failed",
        bgGradient: "from-rose-50 to-pink-50",
        accentColor: "border-rose-200",
        statusBg: "bg-rose-50 text-rose-700 border-rose-200",
      };
    }
  };

  const statusConfig = getStatusConfig();

  return (
    <div className="relative overflow-hidden rounded-xl border border-slate-200 bg-white shadow-sm transition-all duration-300 hover:shadow-md">
      {/* Top status gradient banner */}
      <div
        className={`absolute top-0 inset-x-0 h-1.5 bg-gradient-to-r ${statusConfig.bgGradient}`}
      ></div>

      <div className="pt-6 px-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div
              className={`flex h-10 w-10 items-center justify-center rounded-full text-white bg-${statusConfig.color}-500`}
            >
              <Car className="h-5 w-5" />
            </div>
            <div>
              <h3 className="text-base font-semibold text-slate-800">
                Vehicle Insurance Verification
              </h3>
              <p className="mt-1 text-sm text-slate-500">
                {insuranceVerification.created_at
                  ? format(
                      new Date(insuranceVerification.created_at),
                      "MMM d, yyyy · h:mm a"
                    )
                  : "N/A"}
              </p>
            </div>
          </div>

          <div
            className={`flex items-center px-3 py-1 rounded-full border ${statusConfig.statusBg} text-xs font-medium`}
          >
            {statusConfig.icon}
            <span className="ml-1.5">{statusConfig.label}</span>
          </div>
        </div>

        <div className="mt-6 space-y-6">
          {/* Insurance Details Section */}
          <div className="space-y-4">
            <div className="flex items-center">
              <Briefcase className="h-4 w-4 text-slate-400" />
              <h4 className="ml-2 text-sm font-medium text-slate-700">
                Insurance Details
              </h4>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 rounded-lg border border-slate-100 bg-slate-50/70 p-4">
              <div className="space-y-1.5">
                <div className="flex items-center text-xs text-slate-500">
                  <Award className="mr-1.5 h-3.5 w-3.5 text-slate-400" />
                  Insurance Provider
                </div>
                <p className="text-sm font-medium text-slate-800">
                  {insuranceData.companyName}
                </p>
              </div>

              <div className="space-y-1.5">
                <div className="flex items-center text-xs text-slate-500">
                  <User className="mr-1.5 h-3.5 w-3.5 text-slate-400" />
                  Policy Holder
                </div>
                <p className="text-sm font-medium text-slate-800">
                  {insuranceData.policyHolder}
                </p>
              </div>

              <div className="space-y-1.5">
                <div className="flex items-center text-xs text-slate-500">
                  <Calendar className="mr-1.5 h-3.5 w-3.5 text-slate-400" />
                  Effective Date
                </div>
                <p className="text-sm font-medium text-slate-800">
                  {formattedEffectiveDate}
                </p>
              </div>

              <div className="space-y-1.5">
                <div className="flex items-center text-xs text-slate-500">
                  <Calendar className="mr-1.5 h-3.5 w-3.5 text-slate-400" />
                  Expiration Date
                </div>
                <div className="flex items-center">
                  <p
                    className={cn(
                      "text-sm font-medium",
                      isExpired()
                        ? "text-rose-600"
                        : isExpirationNear()
                        ? "text-amber-600"
                        : "text-slate-800"
                    )}
                  >
                    {formattedExpirationDate}
                  </p>

                  {isExpired() && (
                    <span className="ml-2 inline-flex items-center rounded-sm bg-rose-100 px-1.5 py-0.5 text-xs font-medium text-rose-700">
                      Expired
                    </span>
                  )}

                  {isExpirationNear() && !isExpired() && (
                    <span className="ml-2 inline-flex items-center rounded-sm bg-amber-100 px-1.5 py-0.5 text-xs font-medium text-amber-700">
                      Expiring Soon
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Vehicle Information Section */}
          <div className="space-y-4">
            <div className="flex items-center">
              <Car className="h-4 w-4 text-slate-400" />
              <h4 className="ml-2 text-sm font-medium text-slate-700">
                Vehicle Information
              </h4>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 rounded-lg border border-slate-100 bg-slate-50/70 p-4">
              <div className="space-y-1.5">
                <p className="text-xs text-slate-500">Make</p>
                <p className="text-sm font-medium text-slate-800">
                  {insuranceData.vehicleMake}
                </p>
              </div>

              <div className="space-y-1.5">
                <p className="text-xs text-slate-500">Model</p>
                <p className="text-sm font-medium text-slate-800">
                  {insuranceData.vehicleModel}
                </p>
              </div>

              <div className="space-y-1.5">
                <p className="text-xs text-slate-500">Year</p>
                <p className="text-sm font-medium text-slate-800">
                  {insuranceData.vehicleYear}
                </p>
              </div>

              <div className="space-y-1.5">
                <div className="flex items-center text-xs text-slate-500">
                  <Hash className="mr-1.5 h-3.5 w-3.5 text-slate-400" />
                  VIN
                </div>
                <div className="flex items-center">
                  <p className="text-sm font-medium font-mono text-slate-800 tracking-tight">
                    {insuranceData.vin}
                  </p>

                  {insuranceData.vin !== "N/A" && (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-6 w-6 ml-1"
                          onClick={handleCopy}
                        >
                          {copied ? (
                            <Check className="h-3.5 w-3.5 text-emerald-500" />
                          ) : (
                            <Copy className="h-3.5 w-3.5 text-slate-400" />
                          )}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="top">
                        <p className="text-xs">
                          {copied ? "Copied!" : "Copy VIN"}
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {detailed && attributes.document_url && (
          <div className="mt-6 pb-5">
            <Button
              variant="outline"
              size="sm"
              className="w-full"
              onClick={() => window.open(attributes.document_url, "_blank")}
            >
              <FileText className="mr-1.5 h-4 w-4" />
              View Insurance Document
            </Button>
          </div>
        )}

        {/* Insurance verification progress indicator */}
        <div className="mt-5 border-t border-slate-100 pt-4 pb-4">
          <div className="flex items-center justify-between text-xs">
            <span className="text-slate-500">Verification Progress</span>
            <span
              className={cn(
                "font-medium",
                isPassed
                  ? "text-emerald-600"
                  : isPending
                  ? "text-blue-600"
                  : "text-rose-600"
              )}
            >
              {isPassed ? "100%" : isPending ? "50%" : "Failed"}
            </span>
          </div>
          <div className="mt-2 h-1.5 w-full overflow-hidden rounded-full bg-slate-100">
            <div
              className={cn(
                "h-full rounded-full",
                isPassed
                  ? "bg-emerald-500"
                  : isPending
                  ? "w-1/2 bg-blue-500"
                  : "w-full bg-rose-500"
              )}
              style={{ width: isPassed ? "100%" : isPending ? "50%" : "100%" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DebugJSON = ({ data, label = "Debug" }) => {
  return (
    <div className="mt-4 p-4 bg-slate-50 border border-slate-200 rounded-md">
      <h4 className="text-sm font-semibold mb-2">{label}</h4>
      <pre className="text-xs overflow-auto max-h-[300px] p-2 bg-slate-100 rounded">
        {JSON.stringify(data, null, 2)}
      </pre>
    </div>
  );
};

const RiskReportsSection = ({
  phoneRiskReports = [],
  emailRiskReports = [],
  businessReports = [],
  businessRegistrations = [],
  insuranceVerifications = [],
}) => {
  const [activeTab, setActiveTab] = useState("phone");
  const [showRawData, setShowRawData] = useState(false);

  // Add debugging logs
  console.log("All Reports Data:", {
    phoneRiskReports,
    emailRiskReports,
    businessReports,
    businessRegistrations,
    insuranceVerifications,
  });

  const latestPhoneReport = useMemo(
    () => getLatestReport(phoneRiskReports),
    [phoneRiskReports]
  );
  const latestEmailReport = useMemo(
    () => getLatestReport(emailRiskReports),
    [emailRiskReports]
  );
  const latestBusinessReport = useMemo(
    () => getLatestReport(businessReports),
    [businessReports]
  );
  const latestBusinessRegistration = useMemo(
    () => getLatestReport(businessRegistrations),
    [businessRegistrations]
  );
  const latestInsuranceVerification = useMemo(
    () => getLatestReport(insuranceVerifications),
    [insuranceVerifications]
  );

  // Add debugging for latest reports
  console.log("Latest Reports:", {
    latestPhoneReport,
    latestEmailReport,
    latestBusinessReport,
    latestBusinessRegistration,
    latestInsuranceVerification,
  });

  // Debug for getLatestReport function on businessRegistrations
  console.log("BusinessRegistrations Debug:", {
    hasData: businessRegistrations && businessRegistrations.length > 0,
    count: businessRegistrations?.length || 0,
    firstItem: businessRegistrations?.[0],
    sortResult: businessRegistrations?.length
      ? [...businessRegistrations].sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )[0]
      : null,
  });

  const hasAnyData =
    latestPhoneReport ||
    latestEmailReport ||
    latestBusinessReport ||
    latestBusinessRegistration ||
    latestInsuranceVerification;

  // Removed "summary" from availableTabs and made phone the first choice
  const availableTabs = [
    latestPhoneReport && { value: "phone", label: "Phone" },
    latestEmailReport && { value: "email", label: "Email" },
    latestBusinessReport && { value: "business", label: "Business" },
    latestBusinessRegistration && {
      value: "business-registration",
      label: "Registration",
    },
    latestInsuranceVerification && {
      value: "insurance",
      label: "Insurance",
    },
  ].filter(Boolean);

  // React Hook to update the active tab if needed - MUST be before any conditionals
  useEffect(() => {
    if (
      availableTabs.length > 0 &&
      !availableTabs.some((tab) => tab.value === activeTab)
    ) {
      setActiveTab(availableTabs[0].value);
    }
  }, [availableTabs, activeTab]);

  if (!hasAnyData || availableTabs.length === 0) {
    return (
      <Card className="w-full">
        <CardHeader>
          <CardTitle>Risk Reports</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-center py-8 px-4 border border-dashed rounded-lg">
            <Info className="mx-auto h-8 w-8 text-gray-400" />
            <p className="mt-3 text-sm font-medium text-muted-foreground">
              No risk reports found yet.
            </p>
            <p className="mt-1 text-xs text-muted-foreground">
              As verifications complete, reports will appear here.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full mt-6 mb-6">
      <CardHeader>
        <CardTitle>Verification Reports</CardTitle>
        <CardDescription>
          Summary and details of automated checks.
        </CardDescription>
      </CardHeader>

      <CardContent className="space-y-4">
        <Tabs
          defaultValue={availableTabs[0]?.value || "phone"}
          value={activeTab}
          onValueChange={setActiveTab}
          className="w-full"
        >
          <TabsList
            className="grid w-full"
            style={{
              gridTemplateColumns: `repeat(${availableTabs.length}, minmax(0, 1fr))`,
            }}
          >
            {availableTabs.map((tab) => (
              <TabsTrigger key={tab.value} value={tab.value}>
                {tab.label}
              </TabsTrigger>
            ))}
          </TabsList>

          {latestPhoneReport && (
            <TabsContent value="phone" className="mt-6">
              <PhoneRiskReport
                phoneRiskReport={latestPhoneReport}
                summaryView={false}
              />
            </TabsContent>
          )}
          {latestEmailReport && (
            <TabsContent value="email" className="mt-6">
              <EmailRiskCard emailRiskReport={latestEmailReport} detailed />
            </TabsContent>
          )}
          {latestBusinessReport && (
            <TabsContent value="business" className="mt-6">
              <BusinessVerificationCard
                businessReport={latestBusinessReport}
                detailed
              />
            </TabsContent>
          )}
          {latestBusinessRegistration && (
            <TabsContent value="business-registration" className="mt-6">
              <BusinessRegistrationCard
                businessRegistration={latestBusinessRegistration}
                detailed
              />
            </TabsContent>
          )}
          {latestInsuranceVerification && (
            <TabsContent value="insurance" className="mt-6">
              <InsuranceVerificationCard
                insuranceVerification={latestInsuranceVerification}
                detailed
              />
            </TabsContent>
          )}
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default RiskReportsSection;
