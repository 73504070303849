// @ts-nocheck
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronDown, ChevronUp, Eye, MoreHorizontal } from "lucide-react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

/**
 * DriversDataTable - Displays a table of drivers with expandable rows and actions
 *
 * @param {Object} props - Component props
 * @param {Array} props.data - Data to display in the table
 * @param {number} props.currentPage - Current page number
 * @param {number} props.totalPages - Total number of pages
 * @param {Function} props.onPageChange - Callback for page changes
 * @param {number} props.limit - Number of items per page
 */
const DriversDataTable = ({
  data,
  currentPage,
  totalPages,
  onPageChange,
  limit,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const navigate = useNavigate();

  // Helper function to truncate text
  const truncateText = (text, maxLength = 20) => {
    if (!text) return "N/A";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  // Function to handle expanding/collapsing rows
  const toggleRowExpansion = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  // Function to get badge styles based on employment status
  const getStatusBadge = (status) => {
    switch (status) {
      case "Active":
        return (
          <Badge className="bg-green-100 text-green-800 hover:bg-green-100">
            Active
          </Badge>
        );
      case "Inactive":
        return (
          <Badge
            variant="outline"
            className="bg-gray-100 text-gray-800 hover:bg-gray-100"
          >
            Inactive
          </Badge>
        );
      case "Blocked":
      case "Suspended":
        return (
          <Badge variant="destructive">
            {status === "Blocked" ? "Blocked" : "Suspended"}
          </Badge>
        );
      default:
        return <Badge variant="outline">{status}</Badge>;
    }
  };

  // Calculate pagination limits
  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedData = data.slice(startIndex, endIndex);

  // Set min-height to prevent layout shifts during pagination
  const tableMinHeight = Math.max(paginatedData.length, 5) * 45; // Reduced from 53px to 45px per row

  return (
    <div className="rounded-md border">
      <div
        style={{ minHeight: `${tableMinHeight}px` }}
        className="relative overflow-x-auto"
      >
        <Table>
          <TableHeader>
            <TableRow className="border-b">
              <TableHead className="w-16 py-2">ID</TableHead>
              <TableHead className="w-32 py-2">First Name</TableHead>
              <TableHead className="w-32 py-2">Last Name</TableHead>
              <TableHead className="w-24 py-2">License</TableHead>
              <TableHead className="w-24 py-2">State</TableHead>
              <TableHead className="w-32 py-2">Status</TableHead>
              <TableHead className="w-32 py-2">Contact</TableHead>
              <TableHead className="w-16 py-2">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedData.map((driver, index) => (
              <React.Fragment key={driver.id || index}>
                <TableRow
                  className={cn(
                    "hover:bg-muted/50",
                    "border-b border-gray-100"
                  )}
                >
                  <TableCell className="font-mono text-xs py-2">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          {truncateText(driver.id, 8)}
                        </TooltipTrigger>
                        <TooltipContent side="right">
                          <p>{driver.id}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </TableCell>
                  <TableCell className="py-2">{driver.first_name}</TableCell>
                  <TableCell className="py-2">{driver.last_name}</TableCell>
                  <TableCell className="font-mono text-xs py-2">
                    {truncateText(driver.driver_license_number, 10)}
                  </TableCell>
                  <TableCell className="py-2">
                    {driver.driver_license_state}
                  </TableCell>
                  <TableCell className="py-2">
                    {getStatusBadge(driver.employment_status)}
                  </TableCell>
                  <TableCell className="py-2">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="truncate max-w-[120px]">
                            {truncateText(driver.email, 15)}
                          </div>
                        </TooltipTrigger>
                        <TooltipContent side="right" className="max-w-sm">
                          <div className="space-y-1 text-sm">
                            <p>
                              <strong>Email:</strong> {driver.email}
                            </p>
                            <p>
                              <strong>Phone:</strong> {driver.phone_number}
                            </p>
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </TableCell>
                  <TableCell className="py-2">
                    <div className="flex items-center gap-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => toggleRowExpansion(index)}
                        aria-label={
                          expandedRow === index
                            ? "Hide details"
                            : "Show details"
                        }
                      >
                        {expandedRow === index ? (
                          <ChevronUp className="h-4 w-4" />
                        ) : (
                          <ChevronDown className="h-4 w-4" />
                        )}
                      </Button>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" size="icon">
                            <MoreHorizontal className="h-4 w-4" />
                            <span className="sr-only">Open menu</span>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem
                            onClick={() => navigate(`/dashboard/vet-driver`)}
                            className="cursor-pointer"
                          >
                            <Eye className="mr-2 h-4 w-4" />
                            Verify Driver
                          </DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem
                            onClick={() =>
                              navigate(`/dashboard/edit-driver/${driver.id}`)
                            }
                            className="cursor-pointer"
                          >
                            Edit Driver
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </TableCell>
                </TableRow>
                {expandedRow === index && (
                  <TableRow>
                    <TableCell colSpan={8} className="p-0">
                      <div className="bg-muted/30 p-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                          {/* Personal Information Card */}
                          <Card className="shadow-sm">
                            <CardHeader className="pb-2">
                              <CardTitle className="text-sm font-medium">
                                Personal Information
                              </CardTitle>
                            </CardHeader>
                            <CardContent>
                              <dl className="grid grid-cols-[1fr_2fr] gap-x-3 gap-y-2 text-sm">
                                <dt className="text-xs font-medium text-muted-foreground">
                                  First Name:
                                </dt>
                                <dd className="text-sm">
                                  {driver.first_name || "N/A"}
                                </dd>

                                <dt className="text-xs font-medium text-muted-foreground">
                                  Last Name:
                                </dt>
                                <dd className="text-sm">
                                  {driver.last_name || "N/A"}
                                </dd>

                                <dt className="text-xs font-medium text-muted-foreground">
                                  Email:
                                </dt>
                                <dd
                                  className="text-sm truncate"
                                  title={driver.email}
                                >
                                  {driver.email || "N/A"}
                                </dd>

                                <dt className="text-xs font-medium text-muted-foreground">
                                  Phone:
                                </dt>
                                <dd className="text-sm">
                                  {driver.phone_number || "N/A"}
                                </dd>
                              </dl>
                            </CardContent>
                          </Card>

                          {/* Employment Status Card */}
                          <Card className="shadow-sm">
                            <CardHeader className="pb-2">
                              <CardTitle className="text-sm font-medium">
                                Employment Status
                              </CardTitle>
                            </CardHeader>
                            <CardContent>
                              <dl className="grid grid-cols-[1fr_2fr] gap-x-3 gap-y-2 text-sm">
                                <dt className="text-xs font-medium text-muted-foreground">
                                  Status:
                                </dt>
                                <dd className="text-sm">
                                  {getStatusBadge(driver.employment_status)}
                                </dd>

                                <dt className="text-xs font-medium text-muted-foreground">
                                  Company ID:
                                </dt>
                                <dd className="text-sm">
                                  {driver.company_id || "N/A"}
                                </dd>

                                <dt className="text-xs font-medium text-muted-foreground">
                                  Start Date:
                                </dt>
                                <dd className="text-sm">
                                  {driver.employment_start_date
                                    ? new Date(
                                        driver.employment_start_date
                                      ).toLocaleDateString()
                                    : "N/A"}
                                </dd>

                                <dt className="text-xs font-medium text-muted-foreground">
                                  Verified:
                                </dt>
                                <dd className="text-sm">
                                  {driver.is_verified ? (
                                    <Badge
                                      variant="outline"
                                      className="bg-green-50 text-green-700 hover:bg-green-50 border-green-200"
                                    >
                                      Yes
                                    </Badge>
                                  ) : (
                                    <Badge
                                      variant="outline"
                                      className="bg-amber-50 text-amber-700 hover:bg-amber-50 border-amber-200"
                                    >
                                      No
                                    </Badge>
                                  )}
                                </dd>
                              </dl>
                            </CardContent>
                          </Card>

                          {/* License Card */}
                          <Card className="shadow-sm">
                            <CardHeader className="pb-2">
                              <CardTitle className="text-sm font-medium">
                                License Information
                              </CardTitle>
                            </CardHeader>
                            <CardContent>
                              <dl className="grid grid-cols-[1fr_2fr] gap-x-3 gap-y-2 text-sm">
                                <dt className="text-xs font-medium text-muted-foreground">
                                  Number:
                                </dt>
                                <dd className="font-mono text-xs">
                                  {driver.driver_license_number || "N/A"}
                                </dd>

                                <dt className="text-xs font-medium text-muted-foreground">
                                  State:
                                </dt>
                                <dd className="text-sm">
                                  {driver.driver_license_state || "N/A"}
                                </dd>

                                <dt className="text-xs font-medium text-muted-foreground">
                                  Class:
                                </dt>
                                <dd className="text-sm">
                                  {driver.driver_license_class || "N/A"}
                                </dd>

                                <dt className="text-xs font-medium text-muted-foreground">
                                  Expires:
                                </dt>
                                <dd className="text-sm">
                                  {driver.driver_license_expiration_date
                                    ? new Date(
                                        driver.driver_license_expiration_date
                                      ).toLocaleDateString()
                                    : "N/A"}
                                </dd>
                              </dl>
                            </CardContent>
                          </Card>

                          {/* Safety Record Card */}
                          <Card className="shadow-sm md:col-span-2 lg:col-span-2">
                            <CardHeader className="pb-2">
                              <CardTitle className="text-sm font-medium">
                                Safety Record
                              </CardTitle>
                            </CardHeader>
                            <CardContent>
                              <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-2 text-sm">
                                <div className="grid grid-cols-[1fr_2fr] gap-x-3">
                                  <dt className="text-xs font-medium text-muted-foreground">
                                    Safety Score:
                                  </dt>
                                  <dd className="flex items-center text-sm">
                                    {driver.safety_score ? (
                                      <>
                                        <span
                                          className={cn(
                                            "mr-2 inline-block h-3 w-3 rounded-full",
                                            parseInt(driver.safety_score) > 80
                                              ? "bg-green-500"
                                              : parseInt(driver.safety_score) >
                                                60
                                              ? "bg-yellow-500"
                                              : "bg-red-500"
                                          )}
                                        ></span>
                                        <span className="font-medium">
                                          {driver.safety_score}
                                        </span>
                                      </>
                                    ) : (
                                      "N/A"
                                    )}
                                  </dd>
                                </div>

                                <div className="grid grid-cols-[1fr_2fr] gap-x-3">
                                  <dt className="text-xs font-medium text-muted-foreground">
                                    Violations:
                                  </dt>
                                  <dd className="text-sm text-wrap">
                                    {driver.safety_violations || "None"}
                                  </dd>
                                </div>
                              </dl>
                            </CardContent>
                          </Card>

                          {/* System IDs Card */}
                          <Card className="shadow-sm md:col-span-2 lg:col-span-1">
                            <CardHeader className="pb-2">
                              <CardTitle className="text-sm font-medium">
                                System Information
                              </CardTitle>
                            </CardHeader>
                            <CardContent>
                              <dl className="grid grid-cols-[1fr_2fr] gap-x-3 gap-y-2 text-sm">
                                <dt className="text-xs font-medium text-muted-foreground">
                                  Driver ID:
                                </dt>
                                <dd
                                  className="font-mono text-xs truncate"
                                  title={driver.id}
                                >
                                  {driver.id || "N/A"}
                                </dd>

                                <dt className="text-xs font-medium text-muted-foreground">
                                  User ID:
                                </dt>
                                <dd
                                  className="font-mono text-xs truncate"
                                  title={driver.user_id}
                                >
                                  {driver.user_id || "N/A"}
                                </dd>
                              </dl>
                            </CardContent>
                          </Card>
                        </div>

                        <div className="mt-5 flex justify-start">
                          <Button
                            size="sm"
                            variant="default"
                            onClick={() => navigate(`/dashboard/vet-driver`)}
                          >
                            Issue Verification
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-between border-t px-4 py-2">
        <div className="text-sm text-muted-foreground">
          Showing page <span className="font-medium">{currentPage}</span> of{" "}
          <span className="font-medium">{totalPages}</span>
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DriversDataTable;
