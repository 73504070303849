// @ts-nocheck
import { useState, useEffect, useCallback } from "react";
import { supabase } from "../lib/supabase"; // Adjust path as needed
import { PersonaService } from "../api/services/verification/persona/PersonaService"; // Adjust path
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

// --- Helper Functions ---

// Check if a name likely represents a registered agent or service company
const isLikelyAgentOrService = (name) => {
  if (!name) return false;
  return /corporation service|lawyer|registered agent|incorporate|csc|agent|llc|inc\.|inc$|company|corp\.|corp$|service company|lawyers incorporating/i.test(
    name
  );
};

// Extract owners directly from the structure you provided
const extractOwnersFromBusinessRegistration = (verificationResult) => {
  const extractedOwners = [];
  console.log(
    "DEBUG (Hook): Starting extraction from Business Reg structure:",
    verificationResult
  );

  // Check if the path results.registry_records exists and is an array
  const registryRecords = verificationResult?.results?.registry_records;
  if (!registryRecords || !Array.isArray(registryRecords)) {
    console.log(
      "DEBUG (Hook): results.registry_records not found or not an array."
    );
    return [];
  }

  registryRecords.forEach((record, recordIndex) => {
    console.log(
      `DEBUG (Hook): Inspecting registry record index ${recordIndex}:`,
      record
    );
    // Check if associated_persons exists and is an array - USE BRACKET NOTATION for hyphenated key
    const associatedPersons = record?.["associated-persons"];
    if (!associatedPersons || !Array.isArray(associatedPersons)) {
      console.log(
        `DEBUG (Hook): associated-persons not found in record index ${recordIndex}.`
      );
      return; // Skip this record
    }

    associatedPersons.forEach((person, personIndex) => {
      // Extract name (assuming first name in array is primary)
      const nameValue = person?.names?.[0]?.value;
      if (!nameValue) {
        console.log(
          `DEBUG (Hook): Name not found for person index ${personIndex} in record ${recordIndex}.`
        );
        return; // Skip this person if no name
      }

      // Apply filtering BEFORE adding
      if (isLikelyAgentOrService(nameValue)) {
        console.log(`DEBUG (Hook): Filtering out agent/service: ${nameValue}`);
        return; // Skip likely agents/service companies
      }

      // Extract role (assuming first position is primary, default to 'owner')
      const roleValue = person?.positions?.[0]?.value || "owner";

      console.log(
        `DEBUG (Hook): Extracted Person - Name: ${nameValue}, Role: ${roleValue}`
      );
      extractedOwners.push({ name: nameValue, role: roleValue });
    });
  });

  // Deduplicate owners by name (case-insensitive check might be better but keeping simple for now)
  const uniqueOwnersMap = new Map();
  extractedOwners.forEach((owner) => {
    if (owner.name && !uniqueOwnersMap.has(owner.name.toUpperCase())) {
      uniqueOwnersMap.set(owner.name.toUpperCase(), owner); // Store original object
    }
  });

  const uniqueOwners = Array.from(uniqueOwnersMap.values());
  console.log(
    `DEBUG (Hook): Found ${uniqueOwners.length} unique potential owners after filtering.`
  );
  return uniqueOwners;
};

// Function to fetch owners - moved outside or made stable for useQuery
const fetchBusinessOwnersFromDB = async (carrierId) => {
  if (!carrierId) return [];
  console.log(`DEBUG (Hook - Fetch): Fetching owners for carrier ${carrierId}`);
  const { data, error } = await supabase
    .from("carrier_business_owners")
    .select("*")
    .eq("carrier", carrierId);

  if (error) {
    console.error("DEBUG (Hook - Fetch): Error fetching owners:", error);
    throw new Error(`Failed to fetch owners: ${error.message}`);
  }
  console.log(
    `DEBUG (Hook - Fetch): Fetched ${data?.length || 0} owners successfully.`
  );
  return data || [];
};

// Function to update a single owner - for mutation
const updateOwnerInDB = async ({ ownerId, dataToUpdate }) => {
  console.log(
    `DEBUG (Hook - Update): Updating owner ${ownerId} with:`,
    dataToUpdate
  );
  const { data, error } = await supabase
    .from("carrier_business_owners")
    .update(dataToUpdate)
    .eq("id", ownerId)
    .select()
    .single();

  if (error) {
    console.error("DEBUG (Hook - Update): Error updating owner:", error);
    throw new Error(`Failed to update owner: ${error.message}`);
  }
  console.log("DEBUG (Hook - Update): Updated owner successfully:", data);
  return data;
};

// Function to upsert a single owner - for mutation
const upsertOwnerInDB = async (ownerData) => {
  console.log("DEBUG (Hook - Upsert): Upserting owner:", ownerData);
  // Ensure timestamps are current for upsert
  const dataToUpsert = {
    ...ownerData,
    updated_at: new Date().toISOString(),
    created_at: ownerData.created_at || new Date().toISOString(), // Keep original created_at if exists
  };

  const { data, error } = await supabase
    .from("carrier_business_owners")
    .upsert(dataToUpsert, {
      onConflict: "email,carrier", // Ensure this constraint exists in your DB
      returning: "representation",
    })
    .select()
    .single(); // Assuming upsert affects/returns one row

  if (error) {
    console.error("DEBUG (Hook - Upsert): Error upserting owner:", error);
    throw new Error(`Failed to upsert owner: ${error.message}`);
  }
  console.log("DEBUG (Hook - Upsert): Upserted owner successfully:", data);
  return data;
};

export const useBusinessOwners = (carrierId, businessRegVerification) => {
  const queryClient = useQueryClient();

  // --- Query to Fetch Existing Owners ---
  const {
    data: businessOwners, // Renamed data for clarity
    isLoading: isLoadingOwners,
    error: errorLoadingOwners,
    refetch: refetchOwners, // Expose refetch if needed
  } = useQuery({
    queryKey: ["businessOwners", carrierId], // Unique query key per carrier
    queryFn: () => fetchBusinessOwnersFromDB(carrierId),
    enabled: !!carrierId, // Only run query if carrierId is truthy
    staleTime: 5 * 60 * 1000, // Optional: 5 minutes stale time
    placeholderData: [], // Optional: Start with empty array
  });

  // --- Mutation to Update an Existing Owner ---
  const updateBusinessOwnerMutation = useMutation({
    mutationFn: updateOwnerInDB, // Function performing the update
    onSuccess: (updatedOwner) => {
      console.log("DEBUG (Hook - Update Mutation): Success", updatedOwner);
      // Invalidate and refetch the owners list to show the update
      queryClient.invalidateQueries({
        queryKey: ["businessOwners", carrierId],
      });
      // Optionally update cache directly for faster UI update
      // queryClient.setQueryData(['businessOwners', carrierId], (oldData) =>
      //   oldData?.map(owner => owner.id === updatedOwner.id ? updatedOwner : owner) || []
      // );
    },
    onError: (error) => {
      console.error("DEBUG (Hook - Update Mutation): Error", error);
      // Handle error (e.g., show notification)
    },
  });

  // --- Mutation to Upsert (Create/Update based on conflict) an Owner ---
  const upsertBusinessOwnerMutation = useMutation({
    mutationFn: upsertOwnerInDB, // Function performing the upsert
    onSuccess: (upsertedOwner) => {
      console.log("DEBUG (Hook - Upsert Mutation): Success", upsertedOwner);
      // Invalidate and refetch to include the new/updated owner
      queryClient.invalidateQueries({
        queryKey: ["businessOwners", carrierId],
      });
      // Optionally update cache directly
      // queryClient.setQueryData(['businessOwners', carrierId], (oldData) =>
      //    // Complex logic: remove old if exists, add new
      // );
    },
    onError: (error) => {
      console.error("DEBUG (Hook - Upsert Mutation): Error", error);
    },
  });

  // ----- Existing Extractor/Processor Logic (keep if still needed elsewhere) ----
  // Note: createOwnersFromVerification might need refactoring into its own mutation
  // if it's intended as a user-triggered action rather than automatic extraction.
  const [verificationResults, setVerificationResults] = useState({}); // Keep if needed
  // const [triggerRefetch, setTriggerRefetch] = useState(0); // Manual refetch replaced by queryClient.invalidateQueries

  const createOwnersFromVerification = useCallback(
    async (currentCarrierId, verificationData) => {
      // ... [Implementation remains, but consider if it should be a mutation]
      // It currently inserts and then potentially re-fetches using fetchBusinessOwnersFromDB
      // which might conflict with useQuery's automatic fetching. Refactor needed if used.
      if (!currentCarrierId || !verificationData) return [];

      console.log(
        "DEBUG (Hook): Attempting to extract owners from business registration verification:",
        verificationData
      );

      // Use the NEW specific extractor function
      let extractedOwners =
        extractOwnersFromBusinessRegistration(verificationData);

      if (extractedOwners.length === 0) {
        console.log(
          "DEBUG (Hook): No unique owners extracted from verification data."
        );
        return []; // Exit if no owners were extracted
      }

      console.log(
        `DEBUG (Hook): Extracted ${extractedOwners.length} unique potential owners for insertion.`
      );

      // Prepare owners for insertion, including the extracted role
      const ownersToInsert = extractedOwners.map((owner) => {
        const nameParts = owner.name.trim().split(/\s+/); // Split by whitespace
        return {
          first_name: nameParts[0] || "",
          last_name: nameParts.slice(1).join(" ") || "", // Join remaining parts
          carrier: currentCarrierId,
          role: owner.role || "owner", // Use extracted role, default to 'owner'
          // is_verified: false, // Default verification status
        };
      });

      console.log(
        "DEBUG (Hook): Owners prepared for insertion:",
        ownersToInsert
      );

      try {
        const { data: insertedData, error: insertError } = await supabase
          .from("carrier_business_owners")
          .insert(ownersToInsert)
          .select(); // Important to select to get the inserted data with IDs

        if (insertError) {
          // Handle potential duplicate entries or other insertion errors gracefully
          if (insertError.code === "23505") {
            // Postgres unique violation
            console.warn(
              "DEBUG (Hook): Duplicate owner insertion skipped. Fetching existing owners."
              // insertError.details
            );
            // Invalidate query instead of manual fetch
            queryClient.invalidateQueries({
              queryKey: ["businessOwners", currentCarrierId],
            });
            return []; // Or return fetched data if needed immediately?
          } else {
            throw insertError;
          }
        }
        console.log("DEBUG (Hook): Inserted business owners:", insertedData);
        // Invalidate query after successful insert
        queryClient.invalidateQueries({
          queryKey: ["businessOwners", currentCarrierId],
        });
        return insertedData || [];
      } catch (createError) {
        console.error(
          "DEBUG (Hook): Error inserting business owners:",
          createError
        );
        //setError(`Failed to create owners: ${createError.message}`); // setError is gone
        throw createError; // Rethrow for mutation handling if called via mutation
        // return []; // Return empty on error
      }
    },
    [queryClient] // Dependency on queryClient
  );

  const processOwnerVerification = useCallback(
    async (owner, currentCarrierId) => {
      // ... [Implementation remains for now, consider making it a mutation too]
      if (!owner || !owner.id) {
        console.error(
          "DEBUG (Hook - Process Verify): Invalid owner data provided.",
          owner
        );
        return; // Or throw an error
      }
      if (!currentCarrierId) {
        console.error("DEBUG (Hook - Process Verify): Carrier ID is required.");
        return;
      }

      console.log(
        `DEBUG (Hook - Process Verify): Processing verification for owner ${owner.id}, carrier ${currentCarrierId}`
      );

      // Example: If you need to call an API to start verification
      try {
        // Assume PersonaService.initiateIndividualVerification is an async function
        // that takes owner details and carrier context
        const verificationArgs = {
          entityId: currentCarrierId,
          entityType: "carrier", // Or adjust as needed
          fields: {
            nameFirst: owner.first_name,
            nameLast: owner.last_name,
            // Add other fields like address, dob if available and needed
            phoneNumber: owner.phone_number, // Ensure this field exists and is correct
            emailAddress: owner.email,
          },
          verificationMethod: "individual_verification", // Adjust if needed
        };

        console.log(
          "DEBUG (Hook - Process Verify): Calling PersonaService with args:",
          verificationArgs
        );

        const { inquiryId, referenceId } = await PersonaService.createInquiry(
          verificationArgs
        );

        console.log(
          `DEBUG (Hook - Process Verify): Persona Inquiry created - Inquiry ID: ${inquiryId}, Reference ID: ${referenceId}`
        );

        // Update the owner record in the DB with the verification IDs
        const updateData = {
          verification_result: {
            // Store structured data
            inquiry_id: inquiryId,
            reference_id: referenceId,
            started_at: new Date().toISOString(),
            status: "pending", // Initial status
          },
          // You might want to update a specific status field too
          // e.g., verification_status: 'pending'
        };

        await updateOwnerInDB({ ownerId: owner.id, dataToUpdate: updateData });

        console.log(
          `DEBUG (Hook - Process Verify): Updated owner ${owner.id} record with verification details.`
        );

        // Optionally: Trigger SMS notification here or return IDs to the caller
        // await NotificationService.sendBusinessOwnerVerificationSMS({ ... });

        return { inquiryId, referenceId }; // Return the IDs if needed by the caller
      } catch (verificationError) {
        console.error(
          `DEBUG (Hook - Process Verify): Error during verification process for owner ${owner.id}:`,
          verificationError
        );
        //setError(`Verification failed: ${verificationError.message}`); // setError is gone
        throw verificationError; // Rethrow to be caught by caller or mutation
      }
    },
    [queryClient] // Dependency needed if it triggers invalidation indirectly
  );

  // --- Return Values ---
  return {
    // Query results
    businessOwners,
    isLoadingOwners,
    errorLoadingOwners,
    refetchOwners,

    // Mutations
    updateBusinessOwnerMutation,
    upsertBusinessOwnerMutation,

    // Existing functions (keep if used elsewhere, consider refactoring)
    createOwnersFromVerification,
    processOwnerVerification,
    verificationResults, // Still here if needed
    setVerificationResults, // Still here if needed
  };
};
