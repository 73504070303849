import * as React from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MoreHorizontal, Eye, Edit, Trash, Info } from "lucide-react";

// Format date helper
const formatDate = (dateString, includeTime = false) => {
  if (!dateString) return "Not set";
  try {
    const date = new Date(dateString);
    return includeTime ? date.toLocaleString() : date.toLocaleDateString();
  } catch (error) {
    return "Invalid Date";
  }
};

// Status style mapping
const statusStyles = {
  unverified: {
    label: "Unverified",
    className: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100",
  },
  scheduled: {
    label: "Scheduled",
    className: "bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100",
  },
  arrived: {
    label: "Arrived",
    className:
      "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100",
  },
  "check in": {
    label: "Check In",
    className:
      "bg-indigo-100 text-indigo-800 dark:bg-indigo-800 dark:text-indigo-100",
  },
  loading: {
    label: "Loading",
    className:
      "bg-orange-100 text-orange-800 dark:bg-orange-800 dark:text-orange-100",
  },
  unloading: {
    label: "Unloading",
    className:
      "bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-100",
  },
  "check out": {
    label: "Check Out",
    className: "bg-cyan-100 text-cyan-800 dark:bg-cyan-800 dark:text-cyan-100",
  },
  departed: {
    label: "Departed",
    className:
      "bg-violet-100 text-violet-800 dark:bg-violet-800 dark:text-violet-100",
  },
  completed: {
    label: "Completed",
    className:
      "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
  },
  cancelled: {
    label: "Cancelled",
    className: "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100",
  },
};

// Function to generate columns, accepting action handlers
export const getColumns = ({ onView, onEdit, onDelete }) => [
  {
    accessorKey: "status",
    header: "Status",
    enableSorting: true,
    cell: ({ row }) => {
      const status = (row.original?.status || "Unverified").toLowerCase();
      const { label, className } =
        statusStyles[status] || statusStyles.unverified;

      return (
        <Badge className={className} variant="outline">
          {label}
        </Badge>
      );
    },
  },
  {
    accessorKey: "load_id",
    header: "Load ID",
    enableSorting: true,
    cell: ({ row }) => {
      const loadId = row.original?.load_id;

      // If no load ID, return empty string
      if (!loadId) return <div className="font-medium">-</div>;

      // Truncate long IDs
      const truncatedId =
        loadId.length > 10
          ? `${loadId.substring(0, 8)}...${loadId.substring(loadId.length - 4)}`
          : loadId;

      return (
        <div className="font-medium font-mono relative group">
          <span>{truncatedId}</span>

          {/* Tooltip with full ID */}
          {loadId.length > 10 && (
            <div className="absolute z-10 hidden group-hover:block mt-1 p-2 bg-slate-800 text-white text-xs rounded shadow-lg whitespace-nowrap">
              {loadId}
              <button
                className="ml-2 text-blue-300 hover:text-blue-100"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(loadId);
                }}
              >
                Copy
              </button>
            </div>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "origin_address",
    header: "Origin",
    enableSorting: true,
    cell: ({ row }) => {
      const address = row.original?.origin_address;
      return (
        <div className="truncate max-w-[200px]" title={address}>
          {address}
        </div>
      );
    },
  },
  {
    accessorKey: "destination_address",
    header: "Destination",
    enableSorting: true,
    cell: ({ row }) => {
      const address = row.original?.destination_address;
      return (
        <div className="truncate max-w-[200px]" title={address}>
          {address}
        </div>
      );
    },
  },
  {
    accessorKey: "cargo_owners",
    header: "Cargo Owner",
    enableSorting: true,
    cell: ({ row }) => {
      return (
        <div className="truncate max-w-[150px]">
          {row.original?.cargo_owners?.company_name}
        </div>
      );
    },
  },
  {
    id: "details",
    header: "Details",
    enableSorting: false,
    cell: ({ row }) => {
      const shipment = row.original;

      // Combine vehicle and dock info for tooltip
      const vehicleInfo = shipment?.vehicle_number || "Not Set";
      const dockInfo = shipment?.dock_assignment || "Not Assigned";
      const direction = shipment?.direction || "Not Set";

      return (
        <div className="relative group">
          <Button variant="ghost" className="h-8 p-1">
            <Info className="h-4 w-4 text-gray-500" />
          </Button>

          <div className="absolute z-10 hidden group-hover:block left-0 mt-1 p-2 bg-slate-800 text-white text-xs rounded shadow-lg whitespace-nowrap">
            <div>
              <strong>Direction:</strong> {direction}
            </div>
            <div>
              <strong>Vehicle:</strong> {vehicleInfo}
            </div>
            <div>
              <strong>Dock:</strong> {dockInfo}
            </div>
            {shipment?.arrival_time && (
              <div>
                <strong>Arrival:</strong>{" "}
                {formatDate(shipment.arrival_time, true)}
              </div>
            )}
            {shipment?.departure_time && (
              <div>
                <strong>Departure:</strong>{" "}
                {formatDate(shipment.departure_time, true)}
              </div>
            )}
          </div>
        </div>
      );
    },
  },
  {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    cell: ({ row }) => {
      const shipment = row.original;

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {/* Pass shipment.load_id for viewing */}
            <DropdownMenuItem onClick={() => onView(shipment?.load_id)}>
              <Eye className="mr-2 h-4 w-4" />
              <span>View</span>
            </DropdownMenuItem>
            {/* Keep passing shipment.id for editing/deleting */}
            <DropdownMenuItem onClick={() => onEdit(shipment?.id)}>
              <Edit className="mr-2 h-4 w-4" />
              <span>Edit</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => onDelete(shipment?.id)}>
              <Trash className="mr-2 h-4 w-4" />
              <span>Delete</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];

// Removed placeholder functions: viewShipment, editShipment, deleteShipment
