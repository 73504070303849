// @ts-nocheck
import { z } from "zod";

// Define the shipment validation schema
export const shipmentSchema = z.object({
  load_id: z.string().optional(),
  origin_address: z.string().min(1, { message: "Origin address is required" }),
  destination_address: z
    .string()
    .min(1, { message: "Destination address is required" }),
  // Use union + coerce to handle both string and number inputs
  cargo_owner_id: z.union([
    z
      .string()
      .min(1, { message: "Cargo owner is required" })
      .transform((val) => Number(val)),
    z.number({ message: "Cargo owner is required" }).int().positive(),
  ]),
  carrier_number: z.string().optional(),
  // Use union + coerce to handle both string and number inputs
  company_id: z
    .union([
      z.string().transform((val) => (val ? Number(val) : undefined)),
      z.number().int().positive(),
    ])
    .optional(),
  // Add dock_id with proper validation
  dock_id: z
    .union([
      z.string().transform((val) => (val ? Number(val) : undefined)),
      z.number().int().positive(),
    ])
    .optional(),
  container_number: z.string().optional(),
  pallet_number: z.string().optional(),
  tags: z.string().optional(),
  notes: z.string().optional(),
  status: z.string().optional().default("Unverified"),
  direction: z.enum(["Inbound", "Outbound"]).optional(),
  pickup_time: z.string().optional(),
});
