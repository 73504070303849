// @ts-nocheck
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FiEdit2,
  FiSave,
  FiX,
  FiDownload,
  FiTag,
  FiAlertCircle,
  FiMail,
} from "react-icons/fi";
import { Loader2, Share2, Download, Copy, MapPin, Map } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Badge } from "@/components/ui/badge";
import { Switch } from "@/components/ui/switch";
import Toast from "../../Components/Toast";
import { v4 as uuidv4 } from "uuid";
import PhotoGallery from "../../Components/PhotoGallery";
import EditableField from "../../Components/EditableField";
import { sendEmail as sendEmailNotification } from "../../../api/services/core/NotificationService";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import MapboxMapComponent from "../../Components/MapComponent";
import ShipmentRouteMap from "../../Components/ShipmentRouteMap";
import { useShipment, useShipmentMutations } from "@/hooks/shipments";
import { supabase } from "../../../lib/supabase";

// Get Mapbox token from environment variables
const MAPBOX_TOKEN =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
  process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;

const GenerateReportButton = ({ onClick, isGenerating, isDisabled }) => (
  <Button
    onClick={onClick}
    disabled={isGenerating || isDisabled}
    className="flex items-center space-x-2"
  >
    {isGenerating ? (
      <Loader2 className="w-4 h-4 animate-spin" />
    ) : (
      <Download className="w-4 h-4" />
    )}
    <span>{isGenerating ? "Generating..." : "Generate Report"}</span>
  </Button>
);

const ShipmentHeader = ({ title, children }) => (
  <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6 gap-4">
    <h1 className="text-2xl font-bold tracking-tight text-slate-900 dark:text-slate-100">
      {title}
    </h1>
    <div className="flex items-center space-x-2">{children}</div>
  </div>
);

const LoadingIndicator = () => (
  <div className="flex items-center justify-center min-h-[50vh]">
    <div className="text-center">
      <Loader2 className="w-10 h-10 animate-spin mx-auto text-slate-600" />
      <p className="mt-4 text-slate-600">Loading shipment details...</p>
    </div>
  </div>
);

const ErrorState = ({ message, onRetry }) => (
  <div className="flex flex-col items-center justify-center min-h-[50vh] text-center">
    <FiAlertCircle className="w-12 h-12 text-red-500 mb-4" />
    <h2 className="text-xl font-semibold text-slate-800 mb-2">
      Unable to load shipment
    </h2>
    <p className="text-slate-600 mb-6">
      {message || "An error occurred while loading the shipment details."}
    </p>
    <Button onClick={onRetry}>Try Again</Button>
  </div>
);

const PhotosSection = ({
  photos,
  isLoading,
  groupedPhotos,
  handleUpdatePhotoTags,
}) => (
  <Card className="mb-6">
    <CardHeader>
      <CardTitle className="text-xl font-semibold">Photos</CardTitle>
    </CardHeader>
    <CardContent className="p-6">
      {isLoading ? (
        <div className="text-center py-8 text-slate-500">
          <Loader2 className="w-8 h-8 animate-spin mx-auto mb-2" />
          <p>Loading photos...</p>
        </div>
      ) : photos.length === 0 ? (
        <div className="text-center py-8 text-slate-500">
          <p>No photos available for this shipment</p>
          <p className="text-sm mt-2">
            Photos will appear here once they are uploaded
          </p>
        </div>
      ) : (
        <div className="space-y-8">
          {Object.entries(groupedPhotos).map(([stageName, stagePhotos]) => (
            <div key={stageName} className="space-y-4">
              <h3 className="text-lg font-medium text-slate-800">
                {stageName}
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {stagePhotos.map((photo) => (
                  <div
                    key={photo.id}
                    className="border rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-200"
                  >
                    <div className="relative">
                      {photo.url ? (
                        <img
                          src={photo.url}
                          alt={photo.caption || "Shipment photo"}
                          className="w-full h-48 object-cover"
                        />
                      ) : (
                        <div className="w-full h-48 bg-gray-200 animate-pulse" />
                      )}
                    </div>
                    <div className="p-4 space-y-2">
                      <EditableField
                        label="Tags"
                        type="tags"
                        value={photo.tags || []}
                        onSave={(newTags) =>
                          handleUpdatePhotoTags(photo.id, newTags)
                        }
                      />

                      {photo.metadata && (
                        <div className="text-xs text-slate-500 mt-2 border-t pt-2">
                          <p>
                            Taken:{" "}
                            {new Date(
                              photo.metadata.timing.takenAt
                            ).toLocaleString()}
                          </p>
                          <p>Device: {photo.metadata.deviceInfo.model}</p>
                          <p>
                            Quality Score: {photo.metadata.quality.blurScore}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </CardContent>
  </Card>
);

const ShipmentInfoSection = ({ shipment, handleFieldUpdate }) => {
  const getStatusBadge = (status) => {
    const statusLower = status?.toLowerCase() || "";
    if (
      statusLower.includes("delivered") ||
      statusLower.includes("completed")
    ) {
      return (
        <Badge variant="success" className="ml-2 capitalize">
          Delivered
        </Badge>
      );
    } else if (
      statusLower.includes("transit") ||
      statusLower.includes("shipping")
    ) {
      return (
        <Badge variant="info" className="ml-2 capitalize">
          In Transit
        </Badge>
      );
    } else if (
      statusLower.includes("pending") ||
      statusLower.includes("processing")
    ) {
      return (
        <Badge variant="warning" className="ml-2 capitalize">
          Pending
        </Badge>
      );
    } else if (
      statusLower.includes("cancel") ||
      statusLower.includes("failed")
    ) {
      return (
        <Badge variant="danger" className="ml-2 capitalize">
          Cancelled
        </Badge>
      );
    }
    return (
      <Badge variant="secondary" className="ml-2 capitalize">
        {status || "Unknown"}
      </Badge>
    );
  };

  // Calculate if we have map markers
  const mapMarkers = [];
  if (shipment.origin_lat && shipment.origin_lng) {
    mapMarkers.push({
      id: "origin",
      lng: shipment.origin_lng,
      lat: shipment.origin_lat,
      name: "Origin",
      tooltip: shipment.origin_address,
    });
  }
  if (shipment.destination_lat && shipment.destination_lng) {
    mapMarkers.push({
      id: "destination",
      lng: shipment.destination_lng,
      lat: shipment.destination_lat,
      name: "Destination",
      tooltip: shipment.destination_address,
    });
  }

  return (
    <Card className="mb-6 overflow-hidden border-0 shadow-md hover:shadow-lg transition-shadow duration-300">
      <CardHeader className="bg-gradient-to-r from-slate-50 to-slate-100 dark:from-slate-900 dark:to-slate-800 border-b">
        <div className="flex items-center justify-between">
          <div>
            <CardTitle className="text-xl font-semibold flex items-center">
              <MapPin className="w-5 h-5 text-primary mr-2" />
              Shipment Information
            </CardTitle>
            <CardDescription className="mt-1.5">
              Track and manage shipment details
            </CardDescription>
          </div>
          <div className="flex items-center">
            {shipment.load_id && (
              <div className="flex items-center bg-slate-100 dark:bg-slate-800 rounded-md px-2.5 py-1.5 mr-2">
                <span className="text-xs font-medium text-slate-500 dark:text-slate-400 mr-1.5">
                  ID:
                </span>
                <span className="text-sm font-bold font-mono">
                  {shipment.load_id}
                </span>
              </div>
            )}
            {getStatusBadge(shipment.status)}
          </div>
        </div>
      </CardHeader>
      <CardContent className="p-6 bg-white dark:bg-slate-950">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="bg-slate-50 dark:bg-slate-900 rounded-lg p-5">
              <h3 className="text-sm font-medium text-slate-500 dark:text-slate-400 mb-4 flex items-center">
                <MapPin className="w-4 h-4 mr-2" /> Origin Information
              </h3>
              <EditableField
                label="Origin Address"
                value={shipment.origin_address}
                onSave={(value) => handleFieldUpdate("origin_address", value)}
              />
            </div>

            <div className="bg-slate-50 dark:bg-slate-900 rounded-lg p-5">
              <h3 className="text-sm font-medium text-slate-500 dark:text-slate-400 mb-4 flex items-center">
                <FiTag className="w-4 h-4 mr-2" /> Carrier Details
              </h3>
              <div className="grid grid-cols-1 gap-5">
                <EditableField
                  label="Carrier Number"
                  value={shipment.carrier_number}
                  onSave={(value) => handleFieldUpdate("carrier_number", value)}
                />
                <EditableField
                  label="Container Number"
                  value={shipment.container_number}
                  onSave={(value) =>
                    handleFieldUpdate("container_number", value)
                  }
                />
              </div>
            </div>
          </div>

          <div className="space-y-6">
            <div className="bg-slate-50 dark:bg-slate-900 rounded-lg p-5">
              <h3 className="text-sm font-medium text-slate-500 dark:text-slate-400 mb-4 flex items-center">
                <MapPin className="w-4 h-4 mr-2" /> Destination Information
              </h3>
              <EditableField
                label="Destination Address"
                value={shipment.destination_address}
                onSave={(value) =>
                  handleFieldUpdate("destination_address", value)
                }
              />
            </div>

            <div className="bg-slate-50 dark:bg-slate-900 rounded-lg p-5">
              <h3 className="text-sm font-medium text-slate-500 dark:text-slate-400 mb-4 flex items-center">
                <FiEdit2 className="w-4 h-4 mr-2" /> Shipment Status
              </h3>
              <EditableField
                label="Status"
                value={shipment.status}
                onSave={(value) => handleFieldUpdate("status", value)}
              />
              {shipment.estimated_delivery && (
                <div className="mt-4 text-sm">
                  <span className="font-medium text-slate-500 dark:text-slate-400">
                    Estimated Delivery:
                  </span>
                  <span className="ml-2 text-slate-700 dark:text-slate-300">
                    {new Date(shipment.estimated_delivery).toLocaleDateString()}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        {mapMarkers.length > 0 && (
          <div className="mt-8">
            <div className="bg-slate-50 dark:bg-slate-900 rounded-lg p-5">
              <h3 className="text-sm font-medium text-slate-500 dark:text-slate-400 mb-4 flex items-center">
                <Map className="w-4 h-4 mr-2" /> Shipment Route
              </h3>
              <div className="rounded-lg overflow-hidden shadow-sm border border-slate-200 dark:border-slate-700">
                <div className="h-[300px]">
                  <MapboxMapComponent markers={mapMarkers} />
                </div>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const AdditionalInfoSection = ({ shipment, handleFieldUpdate }) => (
  <Card className="mb-6">
    <CardHeader>
      <CardTitle className="text-xl font-semibold">
        Additional Information
      </CardTitle>
    </CardHeader>
    <CardContent className="p-6">
      <div className="space-y-6">
        <EditableField
          label="Notes"
          value={shipment.notes}
          onSave={(value) => handleFieldUpdate("notes", value)}
          type="textarea"
        />
        <EditableField
          label="Tags"
          value={shipment.tags || []}
          onSave={(value) => handleFieldUpdate("tags", value)}
          type="tags"
        />
        <EditableField
          label="Condition"
          value={shipment.condition}
          onSave={(value) => handleFieldUpdate("condition", value)}
        />
        <div className="flex items-center space-x-2">
          <label className="text-sm font-medium text-slate-500">
            Is Damaged
          </label>
          <Button
            variant="ghost"
            size="sm"
            onClick={() =>
              handleFieldUpdate("is_damaged", !shipment.is_damaged)
            }
            className={`${
              shipment.is_damaged
                ? "text-red-500 hover:text-red-600"
                : "text-green-500 hover:text-green-600"
            }`}
          >
            {shipment.is_damaged ? "Yes" : "No"}
          </Button>
        </div>
      </div>
    </CardContent>
  </Card>
);

const EmailReportModal = ({
  isOpen,
  onClose,
  email,
  setEmail,
  onSend,
  isSending,
}) => {
  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Email Report</DialogTitle>
          <DialogDescription>
            Send the shipment report to any email address.
          </DialogDescription>
        </DialogHeader>
        <div className="py-4">
          <label htmlFor="email" className="block text-sm font-medium mb-1">
            Recipient's Email
          </label>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            className="w-full"
          />
        </div>
        <DialogFooter className="sm:justify-end">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSend} disabled={isSending || !email}>
            {isSending ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Sending...
              </>
            ) : (
              <>
                <FiMail className="w-4 h-4 mr-2" />
                Send Email
              </>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const ShipmentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  console.log("[ShipmentDetails] ID from params:", id);

  const {
    data: shipment,
    isLoading: isLoadingShipment,
    error: shipmentError,
    refetch: refetchShipment,
  } = useShipment(id);

  useEffect(() => {
    console.log("[ShipmentDetails] useShipment state:", {
      id,
      shipment,
      isLoadingShipment,
      shipmentError,
    });
  }, [id, shipment, isLoadingShipment, shipmentError]);

  const { updateShipment: updateShipmentMutation } = useShipmentMutations();

  const [photos, setPhotos] = useState([]);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [publicReportUrl, setPublicReportUrl] = useState(null);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  // Add state for geocoding
  const [isGeocodingAddresses, setIsGeocodingAddresses] = useState(false);
  const [geocodedMarkers, setGeocodedMarkers] = useState([]);
  const [geocodingStatus, setGeocodingStatus] = useState("completed");
  const [isUpdatingDistance, setIsUpdatingDistance] = useState(false);

  useEffect(() => {
    if (shipment?.public_token) {
      setPublicReportUrl(
        `${window.location.origin}/public-report/${shipment.public_token}`
      );
    } else {
      setPublicReportUrl(null);
    }
  }, [shipment?.public_token]);

  const sendEmailToDriver = async () => {
    if (!email) {
      Toast.error("Please enter a valid email address");
      return;
    }

    setIsSendingEmail(true);
    try {
      const emailBody = `Please view the shipment report at: ${publicReportUrl}`;
      const fromEmail = "support@indemni.com";
      const toEmail = email;

      await sendEmailNotification(emailBody, toEmail, fromEmail);

      Toast.success("Email sent successfully");
      setShowEmailModal(false);
      setEmail("");
    } catch (error) {
      console.error("Error sending email:", error);
      Toast.error(
        "Failed to send email: " + (error.message || "Unknown error")
      );
    } finally {
      setIsSendingEmail(false);
    }
  };

  const generatePublicReport = async () => {
    if (!shipment) {
      Toast.error("Shipment data not loaded yet.");
      return;
    }
    setIsGeneratingReport(true);
    try {
      if (shipment.public_token) {
        const publicUrl = `${window.location.origin}/public-report/${shipment.public_token}`;
        window.open(publicUrl, "_blank");
        setPublicReportUrl(publicUrl);
        Toast.success("Existing public report URL retrieved");
        return;
      }

      const token = uuidv4();

      const { error } = await updateShipmentMutation.mutateAsync({
        id: shipment.id,
        shipmentData: { public_token: token },
      });

      if (error) throw error;

      const { error: photosError } = await updateShipmentMutation.mutateAsync({
        id: shipment.id,
        shipmentData: {
          photos: shipment.photos.map((photo) => ({
            ...photo,
            public_token: token,
          })),
        },
      });

      if (photosError) throw photosError;

      const publicUrl = `${window.location.origin}/public-report/${token}`;

      window.open(publicUrl, "_blank");
      setPublicReportUrl(publicUrl);

      Toast.success("Public report generated successfully");
    } catch (error) {
      console.error("Error generating public report:", error);
      Toast.error(
        "Failed to generate public report: " +
          (error.message || "Please try again")
      );
    } finally {
      setIsGeneratingReport(false);
    }
  };

  const handlePhotoUpdate = async (side, metadata) => {
    setIsSaving(true);
    try {
      const { error } = await updateShipmentMutation.mutateAsync({
        id: shipment.id,
        shipmentData: {
          photo: {
            ...shipment.photo,
            metadata: {
              ...shipment.photo.metadata,
              [side]: metadata,
            },
          },
        },
      });

      if (error) throw error;

      Toast.success("Photo details updated successfully");
    } catch (error) {
      console.error("Error updating photo details:", error);
      Toast.error("Failed to update photo details");
    } finally {
      setIsSaving(false);
    }
  };

  const handleFieldUpdate = useCallback(
    async (field, value) => {
      if (!shipment) return;

      if (shipment[field] === value) {
        return;
      }

      const shipmentData = { [field]: value };

      updateShipmentMutation.mutate(
        { id: shipment.id, shipmentData },
        {
          onSuccess: () => {
            Toast.success(`${field} updated successfully`);
          },
          onError: (error) => {
            console.error(`Error updating ${field}:`, error);
            Toast.error(
              `Failed to update ${field}: ` + (error.message || "Unknown error")
            );
          },
        }
      );
    },
    [shipment, updateShipmentMutation]
  );

  useEffect(() => {
    const fetchPhotos = async () => {
      if (!shipment?.load_id) return;
      setIsLoadingPhotos(true);
      try {
        const { data, error } = await supabase
          .from("photos")
          .select("*")
          .eq("load_id", shipment.load_id);

        if (error) throw error;

        const photosWithUrls = await Promise.all(
          (data || []).map(async (photo) => {
            if (!photo.photo_url) {
              return { ...photo, url: null, tags: photo.tags || [] };
            }

            try {
              const { data: urlData, error: urlError } = await supabase.storage
                .from("loadPhotos")
                .createSignedUrl(photo.photo_url, 3600);

              if (urlError) {
                console.error(
                  `Error getting signed URL for ${photo.photo_url}:`,
                  urlError
                );
                return { ...photo, url: null, tags: photo.tags || [] };
              }

              return {
                ...photo,
                url: urlData.signedUrl,
                tags: photo.tags || [],
              };
            } catch (e) {
              console.error(`Error processing photo ${photo.id}:`, e);
              return { ...photo, url: null, tags: photo.tags || [] };
            }
          })
        );

        setPhotos(photosWithUrls);
      } catch (error) {
        console.error("Error fetching photos:", error);
      } finally {
        setIsLoadingPhotos(false);
      }
    };

    fetchPhotos();
  }, [shipment?.load_id]);

  const handleUpdatePhotoTags = async (photoId, newTags) => {
    setIsSaving(true);
    try {
      const { error } = await supabase
        .from("photos")
        .update({ tags: newTags })
        .eq("id", photoId);

      if (error) throw error;

      setPhotos((currentPhotos) =>
        currentPhotos.map((photo) =>
          photo.id === photoId ? { ...photo, tags: newTags } : photo
        )
      );

      Toast.success("Tags updated successfully");
    } catch (error) {
      console.error("Error updating tags:", error);
      Toast.error("Failed to update tags");
    } finally {
      setIsSaving(false);
    }
  };

  const groupedPhotos = useMemo(() => {
    if (photos?.length > 0) {
      return photos.reduce((acc, photo) => {
        const stage = photo.stage_name || "Uncategorized";
        if (!acc[stage]) acc[stage] = [];
        acc[stage].push(photo);
        return acc;
      }, {});
    }
    return {};
  }, [photos]);

  const shareReport = async () => {
    if (!publicReportUrl) {
      Toast.error("No public report available to share");
      return;
    }

    try {
      if (navigator.share) {
        await navigator.share({
          title: `Shipment Report: ${shipment.load_id}`,
          text: "View this shipment report from Indemni",
          url: publicReportUrl,
        });
      } else {
        await navigator.clipboard.writeText(publicReportUrl);
        Toast.success("Report URL copied to clipboard");
      }
    } catch (error) {
      console.error("Error sharing report:", error);
      Toast.error("Failed to share report");
    }
  };

  // Ensure handleDistanceCalculated is memoized
  const handleDistanceCalculated = useCallback(
    (distance) => {
      if (!shipment.distance && distance > 0 && !isUpdatingDistance) {
        setIsUpdatingDistance(true);

        // Update the shipment with the calculated distance
        const updatedShipment = { ...shipment, distance };
        updateShipmentMutation.mutate(
          { id: shipment.id, shipmentData: { distance } },
          {
            onSuccess: () => {
              console.log("Shipment distance updated successfully");
            },
            onError: (error) => {
              console.error("Error updating shipment distance:", error);
            },
          }
        );
      }
    },
    [shipment, isUpdatingDistance, updateShipmentMutation]
  );

  // Geocode addresses with additional checks to prevent loops
  const geocodeAddresses = useCallback(() => {
    // Check if we already have location data in the shipment
    if (
      shipment &&
      ((shipment.origin_lat &&
        shipment.origin_lng &&
        shipment.destination_lat &&
        shipment.destination_lng) ||
        (geocodedMarkers.length >= 2 && geocodingStatus === "completed"))
    ) {
      // We already have coordinates, no need to geocode
      return;
    }

    if (
      shipment &&
      shipment.origin_address &&
      shipment.destination_address &&
      !isGeocodingAddresses &&
      geocodingStatus !== "pending"
    ) {
      console.log(
        "Starting geocoding for addresses:",
        shipment.origin_address,
        shipment.destination_address
      );

      setIsGeocodingAddresses(true);
      setGeocodingStatus("pending");

      Promise.all([
        geocodeAddress(shipment.origin_address),
        geocodeAddress(shipment.destination_address),
      ])
        .then(([originResult, destinationResult]) => {
          console.log("Geocode results:", originResult, destinationResult);

          if (originResult && destinationResult) {
            const markers = [
              {
                id: "origin",
                lat: originResult.lat,
                lng: originResult.lng,
                name: "Origin",
                tooltip: shipment.origin_address,
              },
              {
                id: "destination",
                lat: destinationResult.lat,
                lng: destinationResult.lng,
                name: "Destination",
                tooltip: shipment.destination_address,
              },
            ];

            setGeocodedMarkers(markers);
            setGeocodingStatus("completed");

            // Optionally save the geocoded coordinates back to the shipment
            updateShipmentMutation.mutate({
              id: shipment.id,
              shipmentData: {
                origin_lat: originResult.lat,
                origin_lng: originResult.lng,
                destination_lat: destinationResult.lat,
                destination_lng: destinationResult.lng,
              },
            });
          } else {
            console.error("Failed to geocode one or both addresses");
            setGeocodingStatus("failed");
          }
        })
        .catch((error) => {
          console.error("Geocoding error:", error);
          setGeocodingStatus("failed");
        })
        .finally(() => {
          setIsGeocodingAddresses(false);
        });
    }
  }, [
    shipment,
    isGeocodingAddresses,
    geocodingStatus,
    geocodedMarkers,
    updateShipmentMutation,
  ]);

  // Use markers either from geocoding or create them from shipment if available
  const getMapMarkers = useCallback(() => {
    // First check if we have geocoded markers from the geocoding process
    if (geocodedMarkers.length >= 2) {
      return geocodedMarkers;
    }

    // Then check if we have coordinates stored in the shipment
    if (
      shipment &&
      shipment.origin_lat &&
      shipment.origin_lng &&
      shipment.destination_lat &&
      shipment.destination_lng
    ) {
      return [
        {
          id: "origin",
          lat: parseFloat(shipment.origin_lat),
          lng: parseFloat(shipment.origin_lng),
          name: "Origin",
          tooltip: shipment.origin_address || "Origin Location",
        },
        {
          id: "destination",
          lat: parseFloat(shipment.destination_lat),
          lng: parseFloat(shipment.destination_lng),
          name: "Destination",
          tooltip: shipment.destination_address || "Destination Location",
        },
      ];
    }

    return [];
  }, [geocodedMarkers, shipment]);

  // Function to geocode an address using Mapbox API
  const geocodeAddress = async (address) => {
    if (!address) return null;

    const encodedAddress = encodeURIComponent(address);
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedAddress}.json?access_token=${MAPBOX_TOKEN}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.features && data.features.length > 0) {
        const feature = data.features[0];
        return {
          lng: feature.center[0],
          lat: feature.center[1],
        };
      }

      return null;
    } catch (error) {
      console.error("Error geocoding address:", error);
      return null;
    }
  };

  // Trigger geocoding when shipment data changes
  useEffect(() => {
    if (shipment && shipment.origin_address && shipment.destination_address) {
      geocodeAddresses();
    }
  }, [shipment, geocodeAddresses]);

  const isLoading = isLoadingShipment;
  const isEffectivelySaving = isSaving || updateShipmentMutation.isLoading;
  const error = shipmentError;
  const onRetry = refetchShipment;

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || !shipment) {
    return <ErrorState message={error?.message} onRetry={onRetry} />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      <ShipmentHeader title={`Shipment: ${shipment.load_id}`}>
        <GenerateReportButton
          onClick={generatePublicReport}
          isGenerating={isGeneratingReport}
          isDisabled={updateShipmentMutation.isLoading}
        />
      </ShipmentHeader>

      <Tabs defaultValue="details" className="w-full">
        <TabsList className="grid w-full grid-cols-3 mb-6">
          <TabsTrigger value="details">Details</TabsTrigger>
          <TabsTrigger value="photos">Photos</TabsTrigger>
          <TabsTrigger value="notes">Notes & Additional Info</TabsTrigger>
        </TabsList>

        <TabsContent value="details">
          {/* Route Map Card at the top of details tab */}
          {shipment.origin_address && shipment.destination_address && (
            <Card className="mb-6 overflow-hidden border-0 shadow-md hover:shadow-lg transition-shadow duration-300">
              <CardHeader className="bg-gradient-to-r from-slate-50 to-slate-100 dark:from-slate-900 dark:to-slate-800 border-b pb-4">
                <div className="flex items-center justify-between">
                  <div>
                    <CardTitle className="text-xl font-semibold flex items-center">
                      <Map className="w-5 h-5 text-primary mr-2" />
                      Shipment Route
                    </CardTitle>
                    <CardDescription className="mt-1">
                      Visual representation of shipment journey
                    </CardDescription>
                  </div>
                  <div className="flex items-center">
                    {shipment.status && (
                      <Badge className="mr-3 capitalize">
                        {shipment.status}
                      </Badge>
                    )}
                  </div>
                </div>
              </CardHeader>

              {/* Improved map rendering logic */}
              <CardContent className="p-0">
                {(() => {
                  // First, check if we have already geocoded markers
                  if (geocodedMarkers.length >= 2) {
                    return (
                      <div className="h-[400px]">
                        <ShipmentRouteMap
                          markers={geocodedMarkers}
                          onDistanceCalculated={handleDistanceCalculated}
                        />
                      </div>
                    );
                  }

                  // Then check if we have coordinates stored in the shipment object
                  if (
                    shipment.origin_lat &&
                    shipment.origin_lng &&
                    shipment.destination_lat &&
                    shipment.destination_lng
                  ) {
                    const shipmentMarkers = [
                      {
                        id: "origin",
                        lat: parseFloat(shipment.origin_lat),
                        lng: parseFloat(shipment.origin_lng),
                        name: "Origin",
                        tooltip: shipment.origin_address || "Origin Location",
                      },
                      {
                        id: "destination",
                        lat: parseFloat(shipment.destination_lat),
                        lng: parseFloat(shipment.destination_lng),
                        name: "Destination",
                        tooltip:
                          shipment.destination_address ||
                          "Destination Location",
                      },
                    ];

                    return (
                      <div className="h-[400px]">
                        <ShipmentRouteMap
                          markers={shipmentMarkers}
                          onDistanceCalculated={handleDistanceCalculated}
                        />
                      </div>
                    );
                  }

                  // Show geocoding in progress indicator
                  if (isGeocodingAddresses) {
                    return (
                      <div className="h-[400px] flex items-center justify-center">
                        <div className="flex flex-col items-center">
                          <Loader2 className="w-8 h-8 animate-spin text-primary mb-2" />
                          <p className="text-sm text-gray-500">
                            Converting addresses to coordinates...
                          </p>
                        </div>
                      </div>
                    );
                  }

                  // Show geocoding failure with retry button
                  if (geocodingStatus === "failed") {
                    return (
                      <div className="h-[400px] flex items-center justify-center">
                        <div className="text-center max-w-md p-6">
                          <FiAlertCircle className="w-10 h-10 text-red-300 mx-auto mb-4" />
                          <h3 className="text-lg font-medium text-gray-900 mb-2">
                            Unable to Map Location
                          </h3>
                          <p className="text-sm text-gray-500 mb-4">
                            We couldn't convert the addresses to map
                            coordinates.
                          </p>
                          <Button
                            variant="outline"
                            onClick={() => {
                              setGeocodingStatus("idle");
                              geocodeAddresses();
                            }}
                          >
                            Try Again
                          </Button>
                        </div>
                      </div>
                    );
                  }

                  // Trigger geocoding if we have addresses but haven't geocoded yet
                  if (
                    shipment?.origin_address &&
                    shipment?.destination_address &&
                    geocodingStatus !== "pending"
                  ) {
                    // Start geocoding on component render
                    setTimeout(geocodeAddresses, 0);
                  }

                  // Default state - waiting for geocoding to start
                  return (
                    <div className="h-[400px] flex items-center justify-center">
                      <div className="text-center max-w-md p-6">
                        <MapPin className="w-10 h-10 text-gray-300 mx-auto mb-4" />
                        <h3 className="text-lg font-medium text-gray-900 mb-2">
                          Processing Location Data
                        </h3>
                        <p className="text-sm text-gray-500">
                          {!shipment?.origin_address ||
                          !shipment?.destination_address
                            ? "Missing origin or destination address information."
                            : "We're preparing the map for this shipment. This may take a moment."}
                        </p>
                      </div>
                    </div>
                  );
                })()}
              </CardContent>
            </Card>
          )}

          <ShipmentInfoSection
            shipment={shipment}
            handleFieldUpdate={handleFieldUpdate}
          />
        </TabsContent>

        <TabsContent value="photos">
          <PhotosSection
            photos={photos}
            isLoading={isLoadingPhotos}
            groupedPhotos={groupedPhotos}
            handleUpdatePhotoTags={handleUpdatePhotoTags}
          />
        </TabsContent>

        <TabsContent value="notes">
          <AdditionalInfoSection
            shipment={shipment}
            handleFieldUpdate={handleFieldUpdate}
          />
        </TabsContent>
      </Tabs>

      {publicReportUrl && (
        <Card>
          <CardHeader>
            <CardTitle>Public Report</CardTitle>
            <CardDescription>Share this link publicly.</CardDescription>
          </CardHeader>
          <CardContent className="p-6">
            <div className="flex flex-col sm:flex-row gap-4">
              <Input value={publicReportUrl} readOnly className="flex-grow" />
              <div className="flex gap-2 flex-wrap">
                <Button
                  variant="outline"
                  size="icon"
                  onClick={async () => {
                    await navigator.clipboard.writeText(publicReportUrl);
                    Toast.success("URL copied!");
                  }}
                >
                  <Copy className="w-4 h-4" />
                </Button>
                <Button
                  variant="outline"
                  onClick={() => setShowEmailModal(true)}
                >
                  <FiMail className="w-4 h-4 mr-2" />
                  Email
                </Button>
                <Button variant="outline" onClick={shareReport}>
                  <Share2 className="w-4 h-4 mr-2" />
                  Share
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      <EmailReportModal
        isOpen={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        email={email}
        setEmail={setEmail}
        onSend={sendEmailToDriver}
        isSending={isSendingEmail}
      />
    </div>
  );
};

export default ShipmentDetails;
