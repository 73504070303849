// @ts-nocheck
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";
import {
  ShieldAlert,
  BarChart4,
  LineChart,
  ClipboardCheck,
  AlertTriangle,
  CheckCircle2,
  XCircle,
  Info,
} from "lucide-react";
import { motion } from "framer-motion";

/**
 * FMCSA Safety Ratings emphasize an overall evaluation of a carrier's
 * safety management controls (see FMCSA Safety Ratings, Appendix B:
 * [FMCSA Safety Ratings](https://csa.fmcsa.dot.gov/safetyplanner/MyFiles/Sections.aspx?ch=20&sec=58)).
 *
 * Rather than using discrete numerical thresholds for individual safety measures
 * (e.g. Percentile, Measure Value, Total Inspections, Total Violations), which is not supported by FMCSA guidelines,
 * we apply dynamic color coding only for "Threshold Exceeded".
 *
 * If "Threshold Exceeded" is true (or "Yes"), the value is flagged red to indicate unsatisfactory performance.
 * All other measures are displayed with a neutral color.
 */

const getRiskColor = (label, value) => {
  // Return a neutral color when value is missing.
  if (value === undefined || value === null || value === "N/A") {
    return "text-gray-400";
  }

  // For the "Threshold Exceeded" measure, apply risk colors.
  if (label === "Threshold Exceeded") {
    return value === true || value === "Y" ? "text-red-500" : "text-green-500";
  }

  // According to FMCSA guidelines, individual measures should not determine risk on their own.
  // Therefore, all other fields are rendered in a neutral color.
  return "text-gray-600"; // Darker neutral color for better readability
};

const getColumnIcon = (columnName) => {
  const iconProps = { size: 16, className: "text-gray-500" };

  switch (columnName) {
    case "Safety Measure":
      return <ShieldAlert {...iconProps} />;
    case "Percentile":
      return <BarChart4 {...iconProps} />;
    case "Measure Value":
      return <LineChart {...iconProps} />;
    case "Total Inspections":
      return <ClipboardCheck {...iconProps} />;
    case "Total Violations":
      return <AlertTriangle {...iconProps} />;
    case "Threshold Exceeded":
      return <Info {...iconProps} />;
    default:
      return null;
  }
};

const getThresholdExceededBadge = (value) => {
  if (value === undefined || value === null || value === "N/A") {
    return <span className="text-gray-400">N/A</span>;
  }

  const isExceeded = value === true || value === "Y";

  return (
    <span
      className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium ${
        isExceeded ? "bg-red-100 text-red-800" : "bg-green-100 text-green-800"
      }`}
    >
      {isExceeded ? (
        <>
          <XCircle size={14} className="mr-1" />
          Yes
        </>
      ) : (
        <>
          <CheckCircle2 size={14} className="mr-1" />
          No
        </>
      )}
    </span>
  );
};

const SafetyBasicsTable = ({ basicsContent = [] }) => {
  if (!Array.isArray(basicsContent) || basicsContent.length === 0) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="p-6 text-center bg-gray-50 rounded-lg border border-gray-200"
      >
        <ShieldAlert className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          No Data Available
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          No safety basics information is available for this carrier.
        </p>
      </motion.div>
    );
  }

  const columns = [
    "Safety Measure",
    "Percentile",
    "Measure Value",
    "Total Inspections",
    "Total Violations",
    "Threshold Exceeded",
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="rounded-lg border border-gray-200 shadow-sm overflow-hidden"
    >
      <Table className="w-full">
        <TableHeader>
          <TableRow className="bg-gray-50">
            {columns.map((column, idx) => (
              <TableHead
                key={idx}
                className="py-3 px-4 text-sm font-semibold text-gray-700"
              >
                <div className="flex items-center space-x-2">
                  {getColumnIcon(column)}
                  <span>{column}</span>
                </div>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {basicsContent.map((item, idx) => {
            const basic = item?.basic || {};
            const isThresholdExceeded =
              basic?.exceededFMCSAInterventionThreshold === true ||
              basic?.exceededFMCSAInterventionThreshold === "Y";

            return (
              <TableRow
                key={idx}
                className={`hover:bg-gray-50 transition-colors ${
                  isThresholdExceeded ? "bg-red-50" : ""
                }`}
              >
                <TableCell className="py-3 px-4 font-medium text-gray-700">
                  {basic?.basicsType?.basicsShortDesc || "N/A"}
                </TableCell>
                <TableCell
                  className={`py-3 px-4 ${getRiskColor(
                    "Percentile",
                    basic?.basicsPercentile
                  )} font-medium`}
                >
                  {basic?.basicsPercentile || "N/A"}
                </TableCell>
                <TableCell
                  className={`py-3 px-4 ${getRiskColor(
                    "Measure Value",
                    basic?.measureValue
                  )} font-medium`}
                >
                  {basic?.measureValue || "N/A"}
                </TableCell>
                <TableCell
                  className={`py-3 px-4 ${getRiskColor(
                    "Total Inspections",
                    basic?.totalInspectionWithViolation
                  )} font-medium`}
                >
                  {basic?.totalInspectionWithViolation || "N/A"}
                </TableCell>
                <TableCell
                  className={`py-3 px-4 ${getRiskColor(
                    "Total Violations",
                    basic?.totalViolation
                  )} font-medium`}
                >
                  {basic?.totalViolation || "N/A"}
                </TableCell>
                <TableCell className="py-3 px-4">
                  {getThresholdExceededBadge(
                    basic?.exceededFMCSAInterventionThreshold
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </motion.div>
  );
};

export default SafetyBasicsTable;
