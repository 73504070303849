import { VerificationStrategy } from "./VerificationStrategy";
import { VerificationRepository } from "../repositories/VerificationRepository";
import { PersonaProvider } from "../providers/PersonaProvider";
import { OCRProvider } from "../providers/OCRProvider";
import { providerFactory } from "../factories/ProviderFactory";
import PersonaIDRepository from "../repositories/PersonaIDRespository";
import FMCSARepository from "../repositories/FMSCARepository";

/**
 * Strategy for handling driver verification processes
 * Combines multiple verification providers to validate driver information
 */
export class DriverVerificationStrategy extends VerificationStrategy {
  /**
   * Create a new DriverVerificationStrategy
   * @param {Object} dependencies - Dependencies to inject
   * @param {PersonaIDRepository} dependencies.personaIDRepository - Repository for Persona ID verifications
   * @param {FMCSARepository} dependencies.fmscaRepository - Repository for FMCSA verifications
   * @param {VerificationRepository} dependencies.verificationRepository - Repository for verification results
   * @param {PersonaProvider} dependencies.personaProvider - Provider for Persona ID verification
   * @param {Object} dependencies.fmscaProvider - Provider for FMCSA verification
   * @param {OCRProvider} dependencies.ocrProvider - Provider for OCR processing
   * @param {Object} dependencies.verificationProvider - General verification provider
   */
  constructor(dependencies = {}) {
    super(dependencies);
    this._providers = {};
    this._repositories = {};
  }

  // Lazy-loaded repositories
  get personaIDRepository() {
    if (!this._repositories.personaID) {
      this._repositories.personaID =
        this._dependencies.personaIDRepository || new PersonaIDRepository();
    }
    return this._repositories.personaID;
  }

  get fmscaRepository() {
    if (!this._repositories.fmsca) {
      this._repositories.fmsca =
        this._dependencies.fmscaRepository || new FMCSARepository();
    }
    return this._repositories.fmsca;
  }

  get verificationRepository() {
    if (!this._repositories.verification) {
      this._repositories.verification =
        this._dependencies.verificationRepository ||
        new VerificationRepository();
    }
    return this._repositories.verification;
  }

  // Lazy-loaded providers using factory pattern
  get personaProvider() {
    if (!this._providers.persona) {
      this._providers.persona =
        this._dependencies.personaProvider || new PersonaProvider();
    }
    return this._providers.persona;
  }

  get fmscaProvider() {
    if (!this._providers.fmsca) {
      this._providers.fmsca =
        this._dependencies.fmscaProvider ||
        providerFactory.getFMSCAProvider({
          fmscaRepository: this.fmscaRepository,
        });
    }
    return this._providers.fmsca;
  }

  get ocrProvider() {
    if (!this._providers.ocr) {
      this._providers.ocr = this._dependencies.ocrProvider || new OCRProvider();
    }
    return this._providers.ocr;
  }

  get verificationProvider() {
    if (!this._providers.verification) {
      this._providers.verification =
        this._dependencies.verificationProvider ||
        providerFactory.getVerificationProvider();
    }
    return this._providers.verification;
  }

  /**
   * Verify driver's identity using Persona
   * @param {Object} driverData - Driver information for verification
   * @param {string} driverData.firstName - Driver's first name
   * @param {string} driverData.lastName - Driver's last name
   * @param {string} driverData.dob - Driver's date of birth
   * @param {string} driverData.licenseNumber - Driver's license number
   * @param {string} driverData.idFrontImage - Base64 image of ID front
   * @param {string} driverData.idBackImage - Base64 image of ID back (optional)
   * @returns {Promise<Object>} Result of the verification
   */
  async verifyDriverIdentity(driverData) {
    try {
      // Verify ID documents via Persona
      const personaVerification = await this.personaProvider.verifyIdentity({
        firstName: driverData.firstName,
        lastName: driverData.lastName,
        dob: driverData.dob,
        idNumber: driverData.licenseNumber,
        idFrontImage: driverData.idFrontImage,
        idBackImage: driverData.idBackImage,
      });

      if (!personaVerification.success) {
        return {
          success: false,
          message: "Driver identity verification failed",
          details: personaVerification.details,
        };
      }

      // Store verification results
      await this.personaIDRepository.saveVerificationResult(
        driverData.userId || driverData.driverId,
        personaVerification
      );

      return {
        success: true,
        verificationId: personaVerification.id,
        status: personaVerification.status,
        details: personaVerification.details,
      };
    } catch (error) {
      return this._handleVerificationError(
        error,
        "Driver identity verification failed"
      );
    }
  }

  /**
   * Verify driver's CDL information with FMCSA
   * @param {Object} driverData - Driver information for verification
   * @param {string} driverData.licenseNumber - Driver's license number
   * @param {string} driverData.licenseState - State that issued the license
   * @returns {Promise<Object>} Result of the verification
   */
  async verifyDriverCDL(driverData) {
    try {
      // Verify CDL with FMCSA
      const fmscaVerification = await this.fmscaProvider.verifyDriverLicense({
        licenseNumber: driverData.licenseNumber,
        licenseState: driverData.licenseState,
      });

      if (!fmscaVerification.success) {
        return {
          success: false,
          message: "Commercial driver license verification failed",
          details: fmscaVerification.details,
        };
      }

      // Store FMCSA verification results
      await this.fmscaRepository.saveVerificationResult(
        driverData.userId || driverData.driverId,
        fmscaVerification
      );

      return {
        success: true,
        verificationId: fmscaVerification.id,
        status: fmscaVerification.status,
        details: fmscaVerification.details,
      };
    } catch (error) {
      return this._handleVerificationError(error, "CDL verification failed");
    }
  }

  /**
   * Extract and verify information from driver license using OCR
   * @param {Object} driverData - Driver license image data
   * @param {string} driverData.idFrontImage - Base64 image of ID front
   * @returns {Promise<Object>} Extracted and verified information
   */
  async extractDriverLicenseInfo(driverData) {
    try {
      const ocrResult = await this.ocrProvider.extractDriverLicenseInfo(
        driverData.idFrontImage
      );

      if (!ocrResult.success) {
        return {
          success: false,
          message: "Failed to extract driver license information",
          details: ocrResult.details,
        };
      }

      return {
        success: true,
        data: ocrResult.data,
        confidence: ocrResult.confidence,
      };
    } catch (error) {
      return this._handleVerificationError(
        error,
        "Driver license information extraction failed"
      );
    }
  }

  /**
   * Complete driver verification with all required checks
   * @param {Object} driverData - Complete driver information
   * @returns {Promise<Object>} Combined verification results
   */
  async completeDriverVerification(driverData) {
    try {
      // First extract data from license if needed
      let extractedData = {};
      if (
        driverData.idFrontImage &&
        (!driverData.licenseNumber || !driverData.dob)
      ) {
        const ocrResult = await this.extractDriverLicenseInfo(driverData);
        if (ocrResult.success) {
          extractedData = ocrResult.data;
        } else {
          return {
            success: false,
            message: "Could not extract required driver information",
            details: ocrResult.details,
          };
        }
      }

      // Merge extracted data with provided data
      const verificationData = {
        ...driverData,
        licenseNumber: driverData.licenseNumber || extractedData.licenseNumber,
        dob: driverData.dob || extractedData.dob,
        firstName: driverData.firstName || extractedData.firstName,
        lastName: driverData.lastName || extractedData.lastName,
        licenseState: driverData.licenseState || extractedData.licenseState,
      };

      // Verify identity
      const identityResult = await this.verifyDriverIdentity(verificationData);
      if (!identityResult.success) {
        return identityResult;
      }

      // Verify CDL if applicable
      const cdlResult = await this.verifyDriverCDL(verificationData);

      // Store complete verification in the verification repository
      await this.verificationRepository.saveDriverVerification(
        driverData.userId || driverData.driverId,
        {
          identity: identityResult,
          cdl: cdlResult,
          timestamp: new Date().toISOString(),
        }
      );

      return {
        success: true,
        identity: identityResult,
        cdl: cdlResult,
        verificationComplete: identityResult.success && cdlResult.success,
        verificationDate: new Date().toISOString(),
      };
    } catch (error) {
      return this._handleVerificationError(
        error,
        "Complete driver verification failed"
      );
    }
  }

  /**
   * Get all driver verifications
   * @returns {Promise<Array>} Array of verification records
   */
  async getAllVerifications() {
    console.log("Fetching all verifications from repository...");
    const verifications = await this.verificationRepository.getAll();
    console.log("All verifications:", verifications);

    const driverVerifications = verifications.filter(
      (verification) => verification.verification_type === "driver_shipment"
    );
    console.log("Filtered driver verifications:", driverVerifications);

    return driverVerifications;
  }

  async getVerificationById(id) {
    return this.verificationRepository.findById(id);
  }

  async createVerification(data) {
    return this.verificationRepository.create(data);
  }

  async updateVerification(id, data) {
    return this.verificationRepository.update(id, data);
  }

  async deleteVerification(id) {
    return this.verificationRepository.delete(id);
  }

  async checkStatus(verificationId) {
    const verification = await this.getVerificationById(verificationId);
    if (!verification) {
      throw new Error("Verification not found");
    }

    return {
      status: verification.status,
      details: verification.details,
    };
  }

  async processDocument(verificationId, document, documentType) {
    const verification = await this.getVerificationById(verificationId);
    if (!verification) {
      throw new Error("Verification not found");
    }

    return this._attachDocumentToVerification(
      verification,
      document,
      documentType
    );
  }

  /**
   * Get verification by Persona inquiry ID
   * @param {string} inquiryId - Persona inquiry ID
   * @returns {Promise<Object>} Verification record
   */
  async getVerificationByInquiryId(inquiryId) {
    console.log("Getting verification by inquiry ID:", inquiryId);
    return this.verificationRepository.findByPersonaInquiryId(inquiryId);
  }

  /**
   * Update verification by Persona inquiry ID
   * @param {string} inquiryId - Persona inquiry ID
   * @param {Object} data - Data to update
   * @returns {Promise<Object>} Updated verification record
   */
  async updateVerificationByInquiryId(inquiryId, data) {
    console.log("Updating verification by inquiry ID:", inquiryId, data);
    const verification = await this.getVerificationByInquiryId(inquiryId);

    if (!verification) {
      throw new Error(`Verification not found for inquiry ID: ${inquiryId}`);
    }

    return this.verificationRepository.update(verification.id, data);
  }
}
