import React from "react";
import {
  formatAttributeName,
  formatAttributeValue,
  renderBooleanValue,
  formatDate,
  renderRiskReportAttributeValue,
} from "./utils";

/**
 * Specialized attribute group renderer for risk reports
 * @param {Object} props
 * @param {Object} props.attributes - The attributes object to render
 * @param {Array} props.keys - The keys from the attributes object to render
 */
const RiskReportAttributeGroup = ({ attributes, keys }) => {
  if (!attributes) return null;

  return (
    <>
      {keys.map((key) => {
        if (attributes[key] !== undefined) {
          return (
            <div
              key={key}
              className="group grid grid-cols-[200px,1fr] gap-4 py-3 px-4 hover:bg-slate-50 transition-colors"
            >
              <div className="text-sm text-slate-600 font-medium">
                {formatAttributeName(key)}
              </div>
              <div className="text-sm text-slate-700">
                {/* Explicitly handle special fields by key name */}
                {key === "status" ? (
                  <span>{formatAttributeValue(attributes[key])}</span>
                ) : key === "has-match" ? (
                  renderBooleanValue(attributes[key])
                ) : key === "created-at" ||
                  key === "completed-at" ||
                  key === "redacted-at" ||
                  key === "submitted-at" ? (
                  attributes[key] ? (
                    formatDate(attributes[key])
                  ) : (
                    <span className="text-slate-400 italic">Not Available</span>
                  )
                ) : (
                  renderRiskReportAttributeValue(attributes[key], key)
                )}
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default RiskReportAttributeGroup;
