// @ts-check
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { CargoOwnerService } from "../api/services/business/CargoOwnerService";

/**
 * Hook to fetch a cargo owner by ID
 * @param {string} id - The cargo owner ID to fetch
 * @returns {Object} The query result containing cargo owner details
 */
export const useCargoOwnerById = (id) => {
  return useQuery({
    queryKey: ["cargoOwner", id],
    queryFn: () => CargoOwnerService.getCargoOwnerById(id),
    enabled: !!id,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to fetch a cargo owner by shipment ID
 * @param {string} loadId - The shipment ID to fetch cargo owner for
 * @returns {Object} The query result containing cargo owner details
 */
export const useCargoOwnerByShipment = (loadId) => {
  return useQuery({
    queryKey: ["cargoOwnerByShipment", loadId],
    queryFn: () => CargoOwnerService.getCargoOwnerByShipment(loadId),
    enabled: !!loadId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to fetch a cargo owner by company ID
 * @param {string} companyId - The company ID to fetch cargo owner for
 * @returns {Object} The query result containing cargo owner details
 */
export const useCargoOwnerByCompany = (companyId) => {
  return useQuery({
    queryKey: ["cargoOwner", "company", companyId],
    queryFn: () => CargoOwnerService.getCargoOwnerByCompany(companyId),
    enabled: !!companyId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to fetch multiple cargo owners for a list of shipments
 * @param {Array} shipments - List of shipments to fetch cargo owners for
 * @returns {Object} The query result containing cargo owners
 */
export const useCargoOwners = (shipments) => {
  const cargoOwnerIds = shipments
    ? [...new Set(shipments.map((s) => s.cargo_owner_id).filter(Boolean))]
    : [];

  return useQuery({
    queryKey: ["cargoOwners", cargoOwnerIds],
    queryFn: () => CargoOwnerService.getCargoOwners(cargoOwnerIds),
    enabled: cargoOwnerIds.length > 0,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to fetch all cargo owners
 * @returns {Object} The query result containing all cargo owners
 */
export const useCargoOwnerList = () => {
  return useQuery({
    queryKey: ["cargoOwnerList"],
    queryFn: async () => {
      console.log("Starting cargo owners fetch");
      try {
        const response = await CargoOwnerService.getAllCargoOwners();
        return response;
      } catch (error) {
        console.error("Error in useCargoOwnerList:", error);
        throw error;
      }
    },
    retry: false,
    onError: (error) => {
      console.error("Query error in useCargoOwnerList:", error);
    },
    onSuccess: (data) => {
      console.log("Successfully fetched cargo owners:", data.length);
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to create a new cargo owner
 * @returns {Object} The mutation object for creating a cargo owner
 */
export const useCreateCargoOwner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      console.log("Creating cargo owner in hook", data);
      return await CargoOwnerService.createCargoOwner(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cargoOwnerList"] });
    },
    onError: (error) => {
      console.error("Create cargo owner failed:", error);
    },
  });
};
