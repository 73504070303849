import { supabase } from "../../../lib/supabase";
import { CommonVerificationService } from "./CommonVerificationService";
import { VerificationRepository } from "../../repositories/VerificationRepository";
import { personaProvider } from "../../providers/PersonaProvider";
import CarrierRepository from "../../repositories/CarrierRepository";
import VerificationData from "../../models/VerificationData";
import {
  sendBusinessVerificationSMS,
  sendBusinessOwnerVerificationSMS,
} from "../core/NotificationService";

// Create singleton instances
const verificationRepository = new VerificationRepository(supabase);
const carrierRepository = new CarrierRepository({ supabase });

/**
 * Service for business verification-related operations
 */
export const BusinessVerificationService = {
  /**
   * Creates a business KYB verification record
   *
   * @param {Object} data - Business verification data
   * @param {string} data.companyId - Company ID (Used by Persona)
   * @param {string} data.email - Business email
   * @param {string} data.businessName - Business name
   * @param {string} data.carrierId - Carrier ID
   * @param {string} data.phoneNumber - Business phone number
   * @returns {Promise<Object>} Created verification record and referenceId
   */
  async createBusinessKYBVerification(data) {
    try {
      console.log("🔍 [createBusinessKYBVerification] Starting with data:", {
        companyId: data.companyId,
        email: data.email,
        businessName: data.businessName,
        carrierId: data.carrierId,
        phoneNumber: data.phoneNumber,
      });

      // 1. Create Persona inquiry
      const { referenceId, inquiryId } = await personaProvider.createInquiry(
        {
          businessName: data.businessName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          companyId: data.companyId,
          verificationMethod: "business_verification",
          entityType: "carrier",
          entityId: data.carrierId,
        },
        "business-verification",
        "kyb-business"
      );

      console.log(
        "✅ [createBusinessKYBVerification] Persona inquiry created:",
        {
          inquiryId,
          referenceId,
        }
      );

      // 2. Create verification record
      const verificationDataObject =
        VerificationData.forBusinessRegistrationVerification({
          carrierId: data.carrierId,
          phoneNumber: data.phoneNumber,
          email: data.email,
          personaInquiryId: inquiryId,
          results: { business_name: data.businessName },
          state: "created",
        });

      const verification = await verificationRepository.create(
        verificationDataObject.toObject()
      );

      console.log(
        "✅ [createBusinessKYBVerification] Verification record created:",
        {
          id: verification.id,
        }
      );

      // 3. Send the verification SMS notification - USING EXPLICIT DATA (Workaround)
      try {
        // Construct data explicitly using input 'data' and inquiry 'id'
        const notificationData = {
          phone_number: data.phoneNumber, // Use phone number from input 'data'
          persona_inquiry_id: inquiryId, // Use inquiryId from Persona response
          // Add any other fields required by sendBusinessVerificationSMS if needed
        };
        console.log(
          "Sending Business Verification SMS with explicit data:",
          notificationData
        );
        await sendBusinessVerificationSMS(notificationData); // Pass the constructed data
        console.log(
          `✅ [createBusinessKYBVerification] Business verification SMS sent to ${notificationData.phone_number}`
        );
      } catch (smsError) {
        console.error(
          "❌ [createBusinessKYBVerification] Error sending verification SMS:",
          smsError
        );
        // Log error and continue
      }

      // Return the verification record and reference ID
      return { ...verification, referenceId };
    } catch (error) {
      console.error("❌ [createBusinessKYBVerification] Error:", error);
      throw error;
    }
  },

  /**
   * Creates an individual KYB verification record for business owners
   *
   * @param {Object} data - Individual verification data
   * @param {string} data.companyId - Company ID
   * @param {string} data.email - Owner's email
   * @param {string} data.firstName - Owner's first name
   * @param {string} data.lastName - Owner's last name
   * @param {string} data.carrierId - Carrier ID
   * @param {string} data.phoneNumber - Owner's phone number
   * @returns {Promise<Object>} Created verification record and referenceId
   */
  async createIndividualKYBVerification(data) {
    let businessName = "Your Company"; // Default business name
    try {
      console.log("🔍 [createIndividualKYBVerification] Starting with data:", {
        companyId: data.companyId,
        email: data.email,
        name: `${data.firstName} ${data.lastName}`,
        carrierId: data.carrierId,
      });

      // Attempt to fetch carrier details to get the business name
      try {
        const carrierDetails = await carrierRepository.findById(data.carrierId);
        // Adjust property access based on actual carrier object structure
        if (
          carrierDetails &&
          (carrierDetails.legal_name || carrierDetails.name)
        ) {
          businessName = carrierDetails.legal_name || carrierDetails.name;
        } else {
          console.warn(
            `Could not retrieve business name for carrier ID: ${data.carrierId}`
          );
        }
      } catch (fetchError) {
        console.error(
          `Error fetching carrier details for name: ${fetchError.message}`
        );
      }

      // 1. Create Persona inquiry
      const { referenceId, inquiryId } = await personaProvider.createInquiry(
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          companyId: data.companyId,
          entityType: "carrier",
          entityId: data.carrierId,
        },
        "individual_verification",
        "government-id-selfie"
      );

      console.log(
        "✅ [createIndividualKYBVerification] Persona inquiry created:",
        {
          inquiryId,
          referenceId,
        }
      );

      // 2. Create verification record
      const verificationDataObject = new VerificationData({
        verification_type: "business_owner_verification",
        carrier_id: data.carrierId,
        phone_number: data.phoneNumber,
        email: data.email,
        persona_inquiry_id: inquiryId,
        state: "created",
        persona_verification_complete: false,
        results: { owner_name: `${data.firstName} ${data.lastName}` },
      });

      const verification = await verificationRepository.create(
        verificationDataObject.toObject()
      );

      console.log(
        "✅ [createIndividualKYBVerification] Verification record created:",
        {
          id: verification.id,
        }
      );

      // 3. Send Business Owner SMS - USING EXPLICIT DATA (Workaround)
      try {
        // Construct data explicitly using input 'data' and inquiry 'id'
        const notificationData = {
          phone_number: data.phoneNumber, // Use phone number from input 'data'
          persona_inquiry_id: inquiryId, // Use inquiryId from Persona response
          businessOwner: `${data.firstName} ${data.lastName}`,
          businessName: businessName,
          // Add any other fields required by sendBusinessOwnerVerificationSMS if needed
        };
        console.log(
          "Sending Business Owner Verification SMS with explicit data:",
          notificationData
        );
        await sendBusinessOwnerVerificationSMS(notificationData); // Pass the constructed data
        console.log(
          `✅ [createIndividualKYBVerification] Business owner verification SMS sent to ${notificationData.phone_number}`
        );
      } catch (smsError) {
        console.error(
          "❌ [createIndividualKYBVerification] Error sending owner verification SMS:",
          smsError
        );
        // Log error and continue
      }

      // RETURN BOTH the verification record AND the referenceId
      return { verification, referenceId };
    } catch (error) {
      console.error("❌ [createIndividualKYBVerification] Error:", error);
      throw error;
    }
  },

  /**
   * Saves business owner verification result
   *
   * @param {string} inquiryId - Persona inquiry ID
   * @param {Object} verificationResult - Verification result data
   * @param {string} carrierId - Carrier ID
   * @returns {Promise<Object>} Updated verification record
   */
  async saveBusinessOwnerVerification(
    inquiryId,
    verificationResult,
    carrierId // Keep carrierId parameter if needed for logic within this function
  ) {
    try {
      console.log(
        `Saving business owner verification for inquiry: ${inquiryId}`
      );

      // Find verification by persona inquiry ID
      const verification = await verificationRepository.findByPersonaInquiryId(
        inquiryId
      );

      if (!verification) {
        console.error(
          `No verification found for persona inquiry ID: ${inquiryId}`
        );
        throw new Error(`Verification not found for inquiry ID: ${inquiryId}`);
      }

      console.log(`Found verification record: ${verification.id}`);

      // Parse and prepare the verification status
      const isPassed = verificationResult.status === "passed";
      const statusMap = {
        passed: "Approved",
        failed: "Declined",
        expired: "Expired",
        "waiting-for-approval": "Started",
        reviewing: "Started",
        "waiting-for-data": "Started",
        default: "Started",
      };

      const state = statusMap[verificationResult.status] || statusMap.default;

      // Update the verification record
      const updatedVerification = await verificationRepository.update(
        verification.id,
        {
          state: state,
          persona_verification_complete: isPassed,
          results: {
            ...(verification.results || {}), // Preserve existing results if any
            status: state,
            passed: isPassed,
            personaData: verificationResult, // Store raw Persona data if needed
            lastUpdated: new Date().toISOString(),
          },
        }
      );

      console.log(
        `Updated verification: ${updatedVerification.id} to ${state}`
      );

      if (isPassed && carrierId) {
        try {
          console.log(
            `Updating carrier ${carrierId} verification status potentially (based on rules)`
          );
          await carrierRepository.update(carrierId, {
            verification_status: "Verified",
            is_verified: true,
          });
          console.log(`Carrier ${carrierId} status potentially updated.`);
        } catch (carrierUpdateError) {
          console.error(
            `Error potentially updating carrier verification status: ${carrierUpdateError.message}`
          );
        }
      }

      return updatedVerification;
    } catch (error) {
      console.error(
        `Error saving business owner verification: ${error.message}`
      );
      throw error;
    }
  },

  /**
   * Checks business verification status
   *
   * @param {string} persona_inquiry_id - Persona inquiry ID
   * @returns {Promise<Object>} Status information
   */
  async checkBusinessVerificationStatus(persona_inquiry_id) {
    return CommonVerificationService.checkVerificationStatus(
      persona_inquiry_id
    );
  },
};
