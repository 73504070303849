/**
 * VerificationService.js
 *
 * This file serves as a facade that delegates to specialized verification services.
 * It exports all functions from the specialized services to maintain backward compatibility.
 */

import { verificationFactory } from "../../strategies/VerificationFactory";
import { DriverVerificationService } from "./DriverVerificationService";
import { BusinessVerificationService } from "./BusinessVerificationService";
import { CommonVerificationService } from "./CommonVerificationService";
import { PersonaService } from "./persona/PersonaService";
import { isReportReady as checkReportReady } from "./constants/ReportTypes";
import OCRService from "../../services/core/OCRService";

class VerificationService {
  constructor() {
    if (!verificationFactory) {
      throw new Error("VerificationFactory is not initialized");
    }
    this.factory = verificationFactory;

    // Bind all methods to preserve this context
    this.getAllVerifications = this.getAllVerifications.bind(this);
    this.getVerificationById = this.getVerificationById.bind(this);
    this.createVerification = this.createVerification.bind(this);
    this.updateVerification = this.updateVerification.bind(this);
    this.deleteVerification = this.deleteVerification.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
    this.processDocument = this.processDocument.bind(this);
    this.getVerificationStrategy = this.getVerificationStrategy.bind(this);
    this.createDriverVerification = this.createDriverVerification.bind(this);
    this.createBusinessKYBVerification =
      this.createBusinessKYBVerification.bind(this);
    this.createIndividualKYBVerification =
      this.createIndividualKYBVerification.bind(this);
    this.saveBusinessOwnerVerification =
      this.saveBusinessOwnerVerification.bind(this);
    this.createInsuranceDocumentationVerification =
      this.createInsuranceDocumentationVerification.bind(this);
    this.checkVerificationStatus = this.checkVerificationStatus.bind(this);
    this.runCarrierReports = this.runCarrierReports.bind(this);
    this.getCarrierVerificationStatus =
      this.getCarrierVerificationStatus.bind(this);
    this.runSingleCarrierReport = this.runSingleCarrierReport.bind(this);
    this.runSinglePersonaReport = this.runSinglePersonaReport.bind(this);
    this.runMultiplePersonaReports = this.runMultiplePersonaReports.bind(this);
    this.getPersonaReportByReference =
      this.getPersonaReportByReference.bind(this);
    this.isReportReady = this.isReportReady.bind(this);
    this.getVerificationStatus = this.getVerificationStatus.bind(this);
    this.updateVerificationRecord = this.updateVerificationRecord.bind(this);
    this.performVerificationOCR = this.performVerificationOCR.bind(this);
  }

  // New methods using the factory pattern
  async getAllVerifications(verificationType = "all") {
    const strategy = this.factory.getStrategy(verificationType);
    return strategy.getAllVerifications();
  }

  async getVerificationById(id, verificationType = "all") {
    const strategy = this.factory.getStrategy(verificationType);
    return strategy.getVerificationById(id);
  }

  async createVerification(data, verificationType = "all") {
    const strategy = this.factory.getStrategy(verificationType);
    return strategy.createVerification(data);
  }

  async updateVerification(id, data, verificationType = "all") {
    const strategy = this.factory.getStrategy(verificationType);
    return strategy.updateVerification(id, data);
  }

  async deleteVerification(id, verificationType = "all") {
    const strategy = this.factory.getStrategy(verificationType);
    return strategy.deleteVerification(id);
  }

  async checkStatus(verificationId, verificationType = "all") {
    const strategy = this.factory.getStrategy(verificationType);
    return strategy.checkStatus(verificationId);
  }

  async processDocument(
    verificationId,
    document,
    documentType,
    verificationType = "all"
  ) {
    const strategy = this.factory.getStrategy(verificationType);
    return strategy.processDocument(verificationId, document, documentType);
  }

  /**
   * Get verification status for a shipment
   * @param {string} shipmentId - ID of the shipment
   * @returns {Promise<Object|null>} Verification data or null if not found
   */
  async getVerificationStatus(shipmentId) {
    console.log(
      `[VerificationService] Getting verification status for shipment: ${shipmentId}`
    );
    return CommonVerificationService.getVerificationStatus(shipmentId);
  }

  /**
   * Update verification record for a shipment
   * @param {string} shipmentId - ID of the shipment
   * @param {Object} data - Data to update
   * @returns {Promise<Object>} Updated verification record
   */
  async updateVerificationRecord(shipmentId, data) {
    console.log(
      `[VerificationService] Updating verification for shipment: ${shipmentId}`,
      data
    );
    return CommonVerificationService.updateVerificationStatus(shipmentId, data);
  }

  /**
   * Perform OCR verification on an uploaded document
   * @param {string} shipmentId - ID of the shipment
   * @param {File} file - The file object
   * @param {string} imageUrl - URL of the uploaded image (optional)
   * @returns {Promise<Object>} OCR verification result
   */
  async performVerificationOCR(shipmentId, file, imageUrl) {
    console.log(
      `[VerificationService] *** UPDATED VERSION WITH VALIDATION *** Performing OCR for shipment: ${shipmentId}`
    );
    console.log(
      `[VerificationService] File type:`,
      file ? file.type : "No file"
    );
    console.log(
      `[VerificationService] File size:`,
      file ? `${Math.round(file.size / 1024)}KB` : "No file"
    );
    console.log(
      `[VerificationService] ImageUrl:`,
      imageUrl ? "Provided" : "Not provided"
    );

    try {
      // Get the verification record
      const verification = await this.getVerificationStatus(shipmentId);
      console.log(`[VerificationService] Verification record:`, verification);

      if (!verification) {
        throw new Error(`No verification found for shipment ID: ${shipmentId}`);
      }

      // Step 1: Upload the image if not already uploaded
      let uploadResult;
      if (!imageUrl) {
        console.log(
          `[VerificationService] Uploading image for shipment: ${shipmentId}`
        );
        try {
          uploadResult = await OCRService.uploadImage(file, shipmentId);
          console.log(
            `[VerificationService] Image uploaded successfully, URL:`,
            uploadResult.signedUrl ? "Valid URL" : "Invalid URL"
          );
        } catch (uploadError) {
          console.error(
            `[VerificationService] Error uploading image:`,
            uploadError.message
          );
          throw new Error(`Failed to upload image: ${uploadError.message}`);
        }
      } else {
        console.log(`[VerificationService] Using provided imageUrl`);
        uploadResult = { signedUrl: imageUrl };
      }

      if (!uploadResult || !uploadResult.signedUrl) {
        console.error(`[VerificationService] No valid URL for OCR processing`);
        throw new Error("No valid image URL for OCR processing");
      }

      // Step 2: Process the image with OCR
      console.log(
        `[VerificationService] Processing image with OCR for shipment: ${shipmentId}`
      );

      try {
        // Pass the direct URL string to processImage, not an object
        const ocrResult = await OCRService.processImage(
          uploadResult.signedUrl,
          shipmentId
        );
        console.log(
          `[VerificationService] OCR processing completed, result structure:`,
          Object.keys(ocrResult).join(", ")
        );

        if (!ocrResult) {
          console.error(`[VerificationService] OCR returned empty result`);
          throw new Error("OCR service returned empty result");
        }

        // Extract the OCR text based on the actual response structure
        let ocrText = "";
        if (ocrResult.results?.microsoft?.pages) {
          // New structure with pages
          ocrText = ocrResult.results.microsoft.pages
            .flatMap((page) => page.lines.map((line) => line.text))
            .join("\n");
          console.log(
            `[VerificationService] Text extracted from pages structure`
          );
        } else if (ocrResult.microsoft?.text) {
          // Direct text field in microsoft object
          ocrText = ocrResult.microsoft.text;
          console.log(
            `[VerificationService] Text extracted from microsoft.text`
          );
        } else if (typeof ocrResult === "string") {
          // Handle case where the result might be a string
          console.log(
            `[VerificationService] OCR result is a string, attempting to parse`
          );
          try {
            const parsedResult = JSON.parse(ocrResult);
            if (parsedResult.microsoft?.text) {
              ocrText = parsedResult.microsoft.text;
              console.log(
                `[VerificationService] Text extracted from parsed string`
              );
            }
          } catch (e) {
            // If it's not valid JSON, use the string directly
            ocrText = ocrResult;
            console.log(`[VerificationService] Using string result directly`);
          }
        } else {
          console.warn(
            `[VerificationService] Unexpected OCR result structure:`,
            JSON.stringify(ocrResult).substring(0, 200) + "..."
          );
        }

        console.log(
          `[VerificationService] Extracted OCR text length: ${
            ocrText?.length || 0
          } characters`
        );
        if (ocrText && ocrText.length > 0) {
          console.log(
            `[VerificationService] Text sample: "${ocrText.substring(
              0,
              100
            )}..."`
          );
        }

        // Validate OCR result - Check if shipmentId is present in the OCR text
        let isOcrSuccessful = false;
        const confidence = ocrResult.microsoft?.confidence || 0;
        console.log(`[VerificationService] OCR confidence: ${confidence}`);

        // Format the shipmentId in different ways to increase chances of matching
        const shipmentIdVariations = [
          shipmentId,
          shipmentId.toLowerCase(),
          shipmentId.toUpperCase(),
          shipmentId.replace(/-/g, ""), // Remove hyphens
          shipmentId.replace(/\s/g, ""), // Remove spaces
        ];

        // Define OCR success criteria - check if any variation of shipmentId is in the extracted text
        if (ocrText && ocrText.trim().length > 0) {
          console.log(
            `[VerificationService] Searching for shipment ID in text`
          );

          // Try each variation of the shipment ID
          for (const variation of shipmentIdVariations) {
            if (ocrText.includes(variation)) {
              isOcrSuccessful = true;
              console.log(
                `[VerificationService] OCR validation successful - ShipmentID variation "${variation}" found in the document text`
              );
              break;
            }
          }

          // If exact match failed, try a relaxed search (might be useful if OCR slightly misreads characters)
          if (!isOcrSuccessful && shipmentId.length > 5) {
            // For longer IDs, see if a substantial portion matches (e.g., 80% of the characters)
            console.log(
              `[VerificationService] Trying relaxed matching for shipment ID`
            );
            const minCharsToMatch = Math.floor(shipmentId.length * 0.8);

            for (let i = 0; i <= shipmentId.length - minCharsToMatch; i++) {
              const partialId = shipmentId.substring(i, i + minCharsToMatch);
              if (ocrText.includes(partialId)) {
                isOcrSuccessful = true;
                console.log(
                  `[VerificationService] OCR validation successful with partial match - "${partialId}" found in the document text`
                );
                break;
              }
            }
          }

          if (!isOcrSuccessful) {
            console.warn(
              `[VerificationService] OCR validation failed - ShipmentID "${shipmentId}" not found in the document text`
            );
            console.log(
              `[VerificationService] First 50 chars of text: "${ocrText.substring(
                0,
                50
              )}..."`
            );
          }
        } else {
          console.warn(
            `[VerificationService] OCR validation failed - No text extracted from image`
          );
        }

        // Update verification record based on validation result
        try {
          if (isOcrSuccessful) {
            await this.updateVerificationRecord(shipmentId, {
              ocr_verification_complete: true,
              updated_at: new Date().toISOString(),
            });
            console.log(
              `[VerificationService] Verification marked as OCR complete`
            );
          } else {
            await this.updateVerificationRecord(shipmentId, {
              ocr_verification_complete: false,
              updated_at: new Date().toISOString(),
            });
            console.log(
              `[VerificationService] Verification marked as OCR failed (ocr_verification_complete = false)`
            );
          }
        } catch (updateError) {
          console.error(
            `[VerificationService] Error updating verification record: ${updateError.message}`
          );
          // Continue with the process despite the error
        }

        // Save OCR results to the OCR table
        try {
          const ocrRepository =
            new (require("../../repositories/OCRRepository").OCRRepository)();
          await ocrRepository.saveOCRResult({
            verification_id: verification.id,
            shipment_id: shipmentId,
            document_type: "driver_license",
            ocr_data: ocrResult,
            confidence_score: confidence,
            status: isOcrSuccessful ? "completed" : "failed_validation",
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          });
          console.log(
            `[VerificationService] OCR results saved to database for shipment: ${shipmentId}`
          );
        } catch (ocrError) {
          console.error(
            `[VerificationService] Error saving OCR results: ${ocrError.message}`
          );
          // Don't throw the error here, as we still want to return the OCR results
          // even if saving to the database fails
        }

        const formattedResult = {
          ocrResult: {
            ocrText: ocrText || "No text extracted",
          },
          verificationResult: {
            expected: shipmentId,
            match: true,
            confidence: confidence,
            validated: isOcrSuccessful,
          },
        };

        console.log(
          `[VerificationService] Formatted OCR result:`,
          formattedResult
        );

        // Step 6: Return result or throw error based on validation
        if (!isOcrSuccessful) {
          throw new Error(
            "⚠️ OCR VALIDATION FAILED: The document could not be properly analyzed. Please try again with a clearer image."
          );
        }

        return formattedResult;
      } catch (ocrError) {
        console.error(`[VerificationService] Error processing OCR:`, ocrError);
        throw ocrError;
      }
    } catch (error) {
      console.error(
        `[VerificationService] Error performing OCR: ${error.message}`
      );
      throw error;
    }
  }

  /**
   * Create an insurance documentation verification using the appropriate strategy.
   * This now delegates to the InsuranceVerificationStrategy via the factory.
   * @param {Object} inputData - Data from the frontend { email, phoneNumber, carrier_id, policy_number, companyId, userId (optional) }
   * @returns {Promise<Object>} Result from the strategy's createVerification method (e.g., { verification })
   */
  async createInsuranceDocumentationVerification(inputData) {
    console.log(
      "[VerificationService] Creating insurance documentation verification:",
      inputData
    );
    try {
      // 1. Get the insurance strategy from the factory
      //    Use the consistent key 'insurance_verification'
      const insuranceStrategy = this.factory.getStrategy(
        "insurance_verification"
      );

      // 2. Map frontend inputData to the structure expected by the strategy's createVerification
      const strategyInput = {
        entityId: inputData.carrier_id, // Map carrier_id to entityId
        entityType: "carrier", // Define the entity type
        email: inputData.email,
        phoneNumber: inputData.phoneNumber, // Pass phone number (ensure formatting if strategy requires it)
        companyId: inputData.companyId,
        policy_number: inputData.policy_number || null, // Pass optional policy number
        initiatedBy: inputData.userId || null, // Optional: track who initiated
        verificationSource: "persona_inquiry", // Source is now Persona
        // Add any other fields the strategy might require from inputData
      };

      // 3. Call the strategy's createVerification method
      const result = await insuranceStrategy.createVerification(strategyInput);

      console.log(
        "[VerificationService] Insurance documentation verification created successfully via strategy:",
        result
      );
      // 4. Return the result from the strategy
      return result;
    } catch (error) {
      console.error(
        `❌ [VerificationService] Error creating insurance documentation verification: ${error.message}`,
        error
      );
      // Re-throw the error to be handled by the caller (e.g., the API route handler or frontend)
      throw error;
    }
  }

  // Legacy methods for backward compatibility
  getVerificationStrategy(verificationType) {
    return this.factory.getStrategy(verificationType);
  }

  async createDriverVerification(data) {
    return DriverVerificationService.createDriverVerification(data);
  }

  async createBusinessKYBVerification(data) {
    return BusinessVerificationService.createBusinessKYBVerification(data);
  }

  async createIndividualKYBVerification(data) {
    return BusinessVerificationService.createIndividualKYBVerification(data);
  }

  async saveBusinessOwnerVerification(data) {
    return BusinessVerificationService.saveBusinessOwnerVerification(data);
  }

  async checkVerificationStatus(verificationId) {
    return CommonVerificationService.checkVerificationStatus(verificationId);
  }

  async runCarrierReports(reportInputData, carrierId) {
    return CommonVerificationService.runCarrierReports(
      reportInputData,
      carrierId
    );
  }

  async getCarrierVerificationStatus(carrierId) {
    return CommonVerificationService.getCarrierVerificationStatus(carrierId);
  }

  async runSingleCarrierReport(reportType, reportInputData, carrierId) {
    return CommonVerificationService.runSingleCarrierReport(
      reportType,
      reportInputData,
      carrierId
    );
  }

  async runSinglePersonaReport(options) {
    return CommonVerificationService.runSinglePersonaReport(options);
  }

  async runMultiplePersonaReports(options) {
    return CommonVerificationService.runMultiplePersonaReports(options);
  }

  async getPersonaReportByReference(referenceId) {
    return PersonaService.getPersonaReportByReferenceId(referenceId);
  }

  isReportReady(report) {
    return checkReportReady(report);
  }
}

// Create and export a singleton instance
const verificationService = new VerificationService();

// Export the singleton as default
export default verificationService;

// Re-export legacy functions for backward compatibility
export const {
  createVerification,
  updateVerification,
  runCarrierReports,
  getCarrierVerificationStatus,
  runSingleCarrierReport,
  runSinglePersonaReport,
  runMultiplePersonaReports,
  isReportReady,
  getVerificationStrategy,
  checkVerificationStatus,
  createDriverVerification,
  createBusinessKYBVerification,
  createIndividualKYBVerification,
  saveBusinessOwnerVerification,
  createInsuranceDocumentationVerification,
  getPersonaReportByReference,
  getVerificationStatus,
  updateVerificationRecord,
  performVerificationOCR,
  getVerificationById,
  getAllVerifications,
  deleteVerification,
  processDocument,
} = verificationService;

export const createBusinessOwnerKYBVerification = async (data) => {
  // For clarity, we keep this exported name, but delegate to the individual method
  return BusinessVerificationService.createIndividualKYBVerification(data);
};
