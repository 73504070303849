import React from "react";
import { BaseNode } from "./BaseNode";
import { CameraIcon } from "@heroicons/react/20/solid";

export const PhotoDocNode = ({ data, isConnectable }) => {
  return (
    <BaseNode
      data={data}
      isConnectable={isConnectable}
      type="photoDoc"
      deletable={true}
      id={data.id}
      disableInputHandle={false}
      disableOutputHandle={false}
    >
      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <select
                className="text-sm font-medium text-gray-900 bg-transparent border-0 border-b border-gray-300 focus:border-indigo-500 focus:ring-0 p-0"
                value={data.documentationType}
                onChange={(e) =>
                  data.onChange?.("documentationType", e.target.value)
                }
              >
                <option value="condition">Condition Documentation</option>
                <option value="damage">Damage Documentation</option>
                <option value="delivery">Delivery Documentation</option>
              </select>
              <span className="inline-flex shrink-0 items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                Photo Doc
              </span>
            </div>

            <div className="mt-3 grid grid-cols-2 gap-2">
              <label className="flex items-center space-x-2 rounded-md border border-gray-200 p-2">
                <input
                  type="checkbox"
                  checked={data.requiredPhotos?.front}
                  onChange={(e) =>
                    data.onChange?.("requiredPhotos", {
                      ...data.requiredPhotos,
                      front: e.target.checked,
                    })
                  }
                  className="text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700">Front Photo</span>
              </label>
              <label className="flex items-center space-x-2 rounded-md border border-gray-200 p-2">
                <input
                  type="checkbox"
                  checked={data.requiredPhotos?.back}
                  onChange={(e) =>
                    data.onChange?.("requiredPhotos", {
                      ...data.requiredPhotos,
                      back: e.target.checked,
                    })
                  }
                  className="text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700">Back Photo</span>
              </label>
              <label className="flex items-center space-x-2 rounded-md border border-gray-200 p-2">
                <input
                  type="checkbox"
                  checked={data.requiredPhotos?.left}
                  onChange={(e) =>
                    data.onChange?.("requiredPhotos", {
                      ...data.requiredPhotos,
                      left: e.target.checked,
                    })
                  }
                  className="text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700">Left Side</span>
              </label>
              <label className="flex items-center space-x-2 rounded-md border border-gray-200 p-2">
                <input
                  type="checkbox"
                  checked={data.requiredPhotos?.right}
                  onChange={(e) =>
                    data.onChange?.("requiredPhotos", {
                      ...data.requiredPhotos,
                      right: e.target.checked,
                    })
                  }
                  className="text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700">Right Side</span>
              </label>
            </div>
          </div>
          <div className="shrink-0 rounded-full bg-gray-100 p-2">
            <CameraIcon className="size-5 text-indigo-500" />
          </div>
        </div>
      </div>
    </BaseNode>
  );
};
