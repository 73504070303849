import * as React from "react";
import { Card } from "@/components/ui/card";
import {
  CheckCircle,
  TruckIcon,
  ShieldAlert,
  AlertCircle,
  UserCheck,
} from "lucide-react";

/**
 * StatCard - A compact card displaying a single statistic with an icon
 */
function StatCard({ title, value, icon: Icon, description, className = "" }) {
  return (
    <Card className={`p-4 relative ${className}`}>
      <div className="mb-2 flex justify-between items-center">
        <div className="text-sm font-medium">{title}</div>
        {Icon && <Icon className="h-5 w-5 text-gray-500" aria-hidden="true" />}
      </div>
      <div className="text-3xl font-bold mb-1">{value}</div>
      {description && <p className="text-xs text-gray-500">{description}</p>}
    </Card>
  );
}

/**
 * CarrierStats - Displays summary statistics for carriers
 *
 * @param {Object} props - Component props
 * @param {Array} props.carriers - Array of carrier data
 */
export function CarrierStats({ carriers = [] }) {
  // Calculate statistics based on carrier data
  const stats = React.useMemo(() => {
    const total = carriers.length;
    const vetted = carriers.filter((c) => c.is_vetted).length;
    const unvetted = carriers.filter((c) => !c.is_vetted).length;
    const active = carriers.filter((c) => !c.is_inactive).length;
    const inactive = carriers.filter((c) => c.is_inactive).length;

    return {
      total,
      vetted,
      unvetted,
      active,
      inactive,
    };
  }, [carriers]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
      <StatCard
        title="Total Carriers"
        value={stats.total}
        icon={TruckIcon}
        description="All carriers"
        className="border-l-2 border-l-slate-400"
      />
      <StatCard
        title="Vetted"
        value={stats.vetted}
        icon={CheckCircle}
        description="Verified carriers"
        className="border-l-2 border-l-emerald-200"
      />
      <StatCard
        title="Unvetted"
        value={stats.unvetted}
        icon={AlertCircle}
        description="Carriers needing verification"
        className="border-l-2 border-l-amber-200"
      />
      <StatCard
        title="Active"
        value={stats.active}
        icon={UserCheck}
        description="Currently active carriers"
        className="border-l-2 border-l-[#119DA4]"
      />
      <StatCard
        title="Inactive"
        value={stats.inactive}
        icon={ShieldAlert}
        description="Temporarily inactive"
        className="border-l-2 border-l-rose-200"
      />
    </div>
  );
}
