import { VerificationRepository } from "../../repositories/VerificationRepository";
import { personaProvider } from "../../providers/PersonaProvider";
import { supabase } from "../../../lib/supabase";
import { PersonaService } from "./persona/PersonaService";

// Create singleton instances
const verificationRepository = new VerificationRepository(supabase);

/**
 * Common verification service with shared functionality
 * Used by specialized verification services
 */
export const CommonVerificationService = {
  /**
   * Get verification status for a shipment
   *
   * @param {string} shipmentId - ID of the shipment
   * @returns {Promise<Object|null>} Verification data or null if not found
   */
  async getVerificationStatus(shipmentId) {
    try {
      console.log(`Getting verification status for shipment: ${shipmentId}`);
      console.log(`Shipment ID type: ${typeof shipmentId}`);
      console.log(`Shipment ID length: ${shipmentId?.length}`);

      // Log the exact query we're about to run
      console.log(
        `Running query: SELECT * FROM verifications WHERE shipment_id = '${shipmentId}'`
      );

      const data = await verificationRepository.findByShipmentId(shipmentId);

      console.log(`Query result:`, data);

      if (!data) {
        console.warn(`No verification found for shipment: ${shipmentId}`);
        return null;
      }

      return data;
    } catch (error) {
      console.error(`Error getting verification status: ${error.message}`);
      throw error;
    }
  },

  /**
   * Update verification status for a shipment
   *
   * @param {string} shipmentId - ID of the shipment
   * @param {Object} updates - Fields to update
   * @returns {Promise<Object>} Updated verification record
   */
  async updateVerificationStatus(shipmentId, updates) {
    try {
      console.log(`Updating verification status for shipment: ${shipmentId}`);
      const verification = await verificationRepository.findByShipmentId(
        shipmentId
      );

      if (!verification) {
        console.error(`No verification found for shipment: ${shipmentId}`);
        throw new Error(
          `Verification not found for shipment ID: ${shipmentId}`
        );
      }

      return await verificationRepository.update(verification.id, updates);
    } catch (error) {
      console.error(`Error updating verification status: ${error.message}`);
      throw error;
    }
  },

  /**
   * Update persona inquiry status
   *
   * @param {string} persona_inquiry_id - Persona inquiry ID
   * @param {string} status - New status
   * @returns {Promise<Object>} Updated verification record
   */
  async updatePersonaInquiry(persona_inquiry_id, status) {
    try {
      console.log(
        `Updating persona inquiry status: ${persona_inquiry_id} to ${status}`
      );

      const verification = await verificationRepository.findByPersonaInquiryId(
        persona_inquiry_id
      );

      if (!verification) {
        console.error(
          `No verification found for persona inquiry ID: ${persona_inquiry_id}`
        );
        throw new Error(
          `Verification not found for persona inquiry ID: ${persona_inquiry_id}`
        );
      }

      return await verificationRepository.update(verification.id, {
        persona_verification_complete: status === "completed",
        state: status === "completed" ? "persona_complete" : "created",
      });
    } catch (error) {
      console.error(`Error updating persona inquiry: ${error.message}`);
      throw error;
    }
  },

  /**
   * Check verification status by Persona inquiry ID
   * This is a general-purpose checker that works for all verification types
   *
   * @param {string} persona_inquiry_id - Persona inquiry ID
   * @returns {Promise<Object>} Status information
   */
  async checkVerificationStatus(persona_inquiry_id) {
    try {
      if (!persona_inquiry_id) {
        console.error(
          "No Persona inquiry ID provided to checkVerificationStatus"
        );
        return {
          status: "error",
          message: "Missing Persona inquiry ID for verification",
          success: false,
        };
      }

      console.log(
        `Checking verification status for Persona inquiry ID: ${persona_inquiry_id}`
      );

      // 1. Find the verification record
      const verification = await verificationRepository.findByPersonaInquiryId(
        persona_inquiry_id
      );

      if (!verification) {
        console.error(
          "Verification not found for Persona inquiry ID:",
          persona_inquiry_id
        );
        return {
          status: "error",
          message: "Verification record not found",
          success: false,
        };
      }

      console.log("Found verification record:", {
        id: verification.id,
        state: verification.state,
        inquiryId: verification.persona_inquiry_id,
        complete: verification.persona_verification_complete,
      });

      // 2. Early exit if no Persona inquiry ID
      if (!verification.persona_inquiry_id) {
        console.warn("No Persona inquiry ID found in verification record");

        if (verification.state) {
          return {
            status: verification.state,
            message: `Current status: ${verification.state}. No inquiry ID available.`,
            success: verification.persona_verification_complete === true,
          };
        }

        return {
          status: "pending",
          message: "Verification initiated but no inquiry ID available yet",
          success: false,
        };
      }

      // 3. Query Persona for the latest status
      try {
        console.log(
          `Querying Persona API for inquiry: ${verification.persona_inquiry_id}`
        );

        const statusResult = await personaProvider.checkPersonaInquiryStatus(
          verification.persona_inquiry_id
        );

        const inquiryStatus = statusResult.status || "pending";
        const personaResponse = statusResult.rawResponse || {};

        console.log(`Received status from Persona: ${inquiryStatus}`);

        // 4. Determine the verification state based on inquiry status
        let verificationState;
        let verificationComplete;

        // Check verifications in the included array
        const verifications =
          personaResponse?.included?.filter((item) =>
            item.type.startsWith("verification/")
          ) || [];

        console.log(
          `Found ${verifications.length} verifications in the response`
        );

        // Log verification details if available
        if (verifications.length > 0) {
          verifications.forEach((v, i) => {
            console.log(
              `Verification ${i + 1}: type=${v.type}, status=${
                v.attributes?.status
              }`
            );
          });
        }

        // Check verification statuses
        const allVerificationsPassed =
          verifications.length > 0 &&
          verifications.every((v) => v.attributes?.status === "passed");

        const anyVerificationsFailed = verifications.some(
          (v) =>
            v.attributes?.status === "failed" ||
            v.attributes?.status === "declined"
        );

        console.log(
          `Verification summary: allPassed=${allVerificationsPassed}, anyFailed=${anyVerificationsFailed}`
        );

        // 5. Map Persona status to our internal status
        switch (inquiryStatus.toLowerCase()) {
          case "approved":
            verificationState = "Approved";
            verificationComplete = true;
            break;
          case "declined":
            verificationState = "Declined";
            verificationComplete = false;
            break;
          case "completed":
            // For completed inquiries, check the verification results
            if (allVerificationsPassed) {
              verificationState = "Approved";
              verificationComplete = true;
            } else if (anyVerificationsFailed) {
              verificationState = "Declined";
              verificationComplete = false;
            } else {
              // Default to Complete but not verified if we can't determine
              verificationState = "Complete";
              verificationComplete = false;
            }
            break;
          case "pending":
          case "reviewing":
            verificationState = "Started";
            verificationComplete = false;
            break;
          case "expired":
            verificationState = "Expired";
            verificationComplete = false;
            break;
          default:
            verificationState = "Started";
            verificationComplete = false;
        }

        console.log(
          `Mapped Persona status to internal status: ${verificationState}`
        );

        // 6. Update the verification record with the new status
        await this.updateVerificationRecord(verification.id, {
          status: verificationState,
          passed: verificationComplete,
          personaResponse: personaResponse,
        });

        // 7. Return the result
        return {
          status: verificationState,
          message: `Verification status: ${verificationState}`,
          success: verificationComplete,
          data: personaResponse?.data,
          included: personaResponse?.included,
        };
      } catch (personaError) {
        console.error("Error querying Persona API:", personaError);

        // Return the latest known status from our database if Persona API fails
        return {
          status: verification.state || "error",
          message: `Failed to check status with Persona. Last known status: ${
            verification.state || "Unknown"
          }`,
          success: verification.persona_verification_complete === true,
        };
      }
    } catch (error) {
      console.error("Error checking verification status:", error);
      return {
        status: "error",
        message: error.message || "Unknown error checking verification status",
        success: false,
      };
    }
  },

  /**
   * Update a verification record with latest data
   *
   * @param {string} verificationId - Verification ID
   * @param {Object} data - Data to update
   * @returns {Promise<Object>} Updated verification record
   */
  async updateVerificationRecord(verificationId, data) {
    try {
      console.log(
        "🔍 Starting verification record update for ID:",
        verificationId
      );
      console.log("🔍 Incoming data:", data);

      // Create a sanitized version of the status value (prevent non-allowed values)
      const validStates = [
        "created",
        "Started",
        "pending",
        "Complete",
        "Approved",
        "Declined",
        "Expired",
        "persona_complete",
        "ocr_complete",
        "error",
      ];

      const sanitizedStatus = validStates.includes(data.status)
        ? data.status
        : "pending";

      // Get the original Persona response data
      const personaResponse = data.personaResponse || data;

      // Basic metadata to add
      const metadata = {
        status: sanitizedStatus,
        passed: Boolean(data.passed),
        lastUpdated: new Date().toISOString(),
      };

      // Create a simple results object that includes original response
      // along with our processed status information
      const resultsData = {
        // Add our processed metadata
        ...metadata,

        // Store the entire Persona API response for maximum flexibility
        personaData: personaResponse,
      };

      console.log("🔍 Prepared verification data for update", {
        status: sanitizedStatus,
        passed: Boolean(data.passed),
        hasPersonaData: !!personaResponse,
      });

      // Create the update payload with only the essential fields
      const updateData = {
        state: sanitizedStatus, // NOTE: Using `state` not `status` as per schema
        persona_verification_complete: Boolean(data.passed),
        updated_at: new Date().toISOString(),
        // Include the results data which now contains the full response
        results: resultsData,
      };

      // Include pin_code if it exists in the data
      if (data.pin_code !== undefined) {
        console.log("🔍 Including pin_code in update:", data.pin_code);
        updateData.pin_code = data.pin_code;
      }

      // Include any other custom fields that should be passed through
      // This ensures we don't lose any fields that aren't explicitly handled
      const customFields = [
        "ocr_verification_complete",
        "persona_verification_url",
      ];
      customFields.forEach((field) => {
        if (data[field] !== undefined) {
          console.log(
            `🔍 Including custom field ${field} in update:`,
            data[field]
          );
          updateData[field] = data[field];
        }
      });

      console.log("🔍 Final update data structure:", {
        state: updateData.state,
        persona_verification_complete: updateData.persona_verification_complete,
        updated_at: updateData.updated_at,
        pin_code: updateData.pin_code,
        hasResults: !!updateData.results,
      });

      // Use VerificationRepository to update the record
      const updatedRecord = await verificationRepository.update(
        verificationId,
        updateData
      );

      if (updatedRecord) {
        console.log(
          "✅ Successfully updated verification record:",
          verificationId
        );
        console.log("✅ New record state:", updatedRecord.state);
        console.log(
          "✅ Verification complete:",
          updatedRecord.persona_verification_complete
        );
        if (updatedRecord.pin_code) {
          console.log("✅ PIN code updated:", updatedRecord.pin_code);
        }
      }

      return updatedRecord;
    } catch (error) {
      console.error("❌ Unexpected error in updateVerificationRecord:", error);
      throw error;
    }
  },

  /**
   * Get verifications for a specific entity
   *
   * @param {string} entityType - Type of entity (e.g., "carrier", "driver")
   * @param {string} entityId - ID of the entity
   * @returns {Promise<Array>} List of verification records
   */
  async getVerificationsByEntityId(entityType, entityId) {
    try {
      console.log(`Getting verifications for ${entityType} ${entityId}`);
      const data = await verificationRepository.findByEntityTypeAndId(
        entityType,
        entityId
      );

      return data || [];
    } catch (error) {
      console.error(`Error getting verifications: ${error.message}`);
      throw error;
    }
  },

  /**
   * Create a new verification record
   *
   * @param {Object} verificationData - Verification data
   * @returns {Promise<Object>} Created verification record
   */
  async createVerification(verificationData) {
    try {
      console.log(`Creating verification record`, verificationData);
      const data = await verificationRepository.create(verificationData);

      return data;
    } catch (error) {
      console.error(`Error creating verification: ${error.message}`);
      throw error;
    }
  },

  /**
   * Run carrier reports through Persona
   * @param {Object} reportInputData - Input data for the reports
   * @param {string} carrierId - Carrier ID
   * @returns {Promise<Object>} Report results
   */
  async runCarrierReports(reportInputData, carrierId) {
    try {
      console.log(
        "🔍 [CommonVerificationService] Running carrier reports for:",
        carrierId
      );

      // Get available report types
      const reportTypes =
        PersonaService.getAvailableReportTypes(reportInputData);

      if (!reportTypes || reportTypes.length === 0) {
        throw new Error("No report types available for this carrier");
      }

      // Run reports through Persona
      const reportResults = await PersonaService.runMultiplePersonaReports({
        inputData: reportInputData,
        entityId: carrierId,
        entityType: "carrier",
        reportTypes,
        company_id: reportInputData.company_id, // Pass company_id from reportInputData
      });

      // Add metadata to results
      const results = {
        ...reportResults,
        _meta: {
          success: true,
          partialSuccess: false,
          readyCount: Object.keys(reportResults).length,
          totalCount: reportTypes.length,
        },
      };

      return results;
    } catch (error) {
      console.error(
        "❌ [CommonVerificationService] Error running carrier reports:",
        error
      );
      throw error;
    }
  },
};
