import React from "react";
import { format } from "date-fns";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { Separator } from "@/components/ui/separator";

import { Badge } from "@/components/ui/badge";

/**
 * DriverVerificationDetails - Renders details specific to driver verifications.
 * TODO: Implement driver-specific details (e.g., ID info, photo).
 *
 * @param {Object} props - Component props
 * @param {Object} props.verification - The verification data object
 */
export default function DriverVerificationDetails({ verification }) {
  // Format dates if they exist
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return format(new Date(dateString), "PPP p");
  };

  return (
    <Card className="bg-blue-50 border-blue-200">
      <CardHeader className="pb-2">
        <CardTitle className="text-blue-700 text-lg">
          Driver Verification Details
        </CardTitle>
      </CardHeader>

      <CardContent className="space-y-4 pt-0">
        {/* Basic Information */}
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="font-medium text-slate-600 w-1/2">
                Verification ID
              </TableCell>
              <TableCell>{verification.id || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium text-slate-600">
                Driver ID
              </TableCell>
              <TableCell>{verification.driver_id || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium text-slate-600">
                Verification Type
              </TableCell>
              <TableCell>{verification.verification_type || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium text-slate-600">
                State
              </TableCell>
              <TableCell>
                <Badge
                  variant={
                    verification.state === "pending" ? "outline" : "default"
                  }
                  className="capitalize"
                >
                  {verification.state || "N/A"}
                </Badge>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium text-slate-600">
                Reference ID
              </TableCell>
              <TableCell className="break-all">
                {verification.reference_id || "N/A"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Separator className="my-2" />

        {/* Persona Information */}
        <div>
          <h3 className="font-medium text-blue-600 mb-2 text-sm">
            Persona Verification
          </h3>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="font-medium text-slate-600 w-1/2">
                  Inquiry ID
                </TableCell>
                <TableCell className="break-all">
                  {verification.persona_inquiry_id || "N/A"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium text-slate-600">
                  Verification Complete
                </TableCell>
                <TableCell>
                  <Badge
                    variant={
                      verification.persona_verification_complete
                        ? "success"
                        : "secondary"
                    }
                  >
                    {verification.persona_verification_complete ? "Yes" : "No"}
                  </Badge>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium text-slate-600">
                  OCR Verification Complete
                </TableCell>
                <TableCell>
                  <Badge
                    variant={
                      verification.ocr_verification_complete
                        ? "success"
                        : "secondary"
                    }
                  >
                    {verification.ocr_verification_complete ? "Yes" : "No"}
                  </Badge>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <Separator className="my-2" />

        {/* Results Information */}
        {verification.results && (
          <div>
            <h3 className="font-medium text-blue-600 mb-2 text-sm">Results</h3>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="font-medium text-slate-600 w-1/2">
                    Status
                  </TableCell>
                  <TableCell>
                    {verification.results.status || "Pending"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium text-slate-600">
                    Completed
                  </TableCell>
                  <TableCell>
                    <Badge
                      variant={
                        verification.results.completed ? "success" : "secondary"
                      }
                    >
                      {verification.results.completed ? "Yes" : "No"}
                    </Badge>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium text-slate-600">
                    Template Name
                  </TableCell>
                  <TableCell>
                    {verification.results.templateData?.attributes?.name ||
                      "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-medium text-slate-600">
                    Processed At
                  </TableCell>
                  <TableCell>
                    {formatDate(verification.results.processedAt)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}

        <Separator className="my-2" />

        {/* Timestamps */}
        <div>
          <h3 className="font-medium text-blue-600 mb-2 text-sm">Timestamps</h3>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="font-medium text-slate-600 w-1/2">
                  Created
                </TableCell>
                <TableCell>{formatDate(verification.created_at)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium text-slate-600">
                  Updated
                </TableCell>
                <TableCell>{formatDate(verification.updated_at)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
}
