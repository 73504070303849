/**
 * PersonaInquiryService.js
 *
 * This service handles Persona inquiry-related operations
 */

import PersonaProvider, {
  personaProvider,
} from "../../../providers/PersonaProvider";
import { VerificationRepository } from "../../../repositories/VerificationRepository";
import { supabase } from "../../../../lib/supabase";
import {
  mapPersonaInquiryStatus,
  isVerificationSuccessful,
  mapStatusToVerificationState,
  getVerificationResultFromStatus,
} from "../../../utils/PersonaStatusUtils";

// UUID generator function to replace crypto dependency
function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

class PersonaInquiryService {
  constructor(dependencies = {}) {
    this.personaProvider = dependencies.personaProvider || personaProvider;
    this.verificationRepository =
      dependencies.verificationRepository ||
      new VerificationRepository(supabase);
  }

  /**
   * Creates a new Persona verification inquiry and initializes related database records
   *
   * @param {Object} userData - User data for verification
   * @param {string} [userData.phoneNumber] - User's phone number
   * @param {string} [userData.template_id] - Template key from Persona
   * @param {string} verificationMethod - Type of verification being performed
   * @param {string} entity_type - Type of entity being verified ("carrier", "shipment", or "driver")
   * @param {string|number} entity_id - ID of the entity being verified
   * @returns {Promise<Object>} Verification session details
   */
  async createPersonaInquiry(
    userData,
    verificationMethod,
    entity_type,
    entity_id
  ) {
    try {
      console.log("🔍 [PersonaInquiryService] Starting inquiry creation:", {
        userData,
        verificationMethod,
        entity_type,
        entity_id,
      });

      // Validate entity type
      if (!["carrier", "shipment", "driver"].includes(entity_type)) {
        throw new Error(
          `Invalid entity_type: ${entity_type}. Must be one of: carrier, shipment, driver`
        );
      }

      // Format the data for the PersonaProvider
      const data = {
        entityType: entity_type,
        entityId: entity_id,
        phoneNumber: userData.phoneNumber,
        email: userData.email,
        companyId: userData.companyId || userData.company_id,
        templateId: userData.template_id || "document-verification",
      };

      // Use PersonaProvider to create the inquiry
      const inquiryResult = await this.personaProvider.createInquiry(
        data,
        verificationMethod,
        userData.template_id || "document-verification"
      );

      console.log("✅ [PersonaInquiryService] Inquiry created:", inquiryResult);

      // Save the inquiry data to database
      await this.savePersonaInquiry(
        {
          inquiryId: inquiryResult.inquiryId,
          referenceId: inquiryResult.referenceId,
          templateId: userData.template_id || "document-verification",
        },
        verificationMethod,
        entity_type,
        entity_id
      );

      console.log("✅ [PersonaInquiryService] Inquiry data saved successfully");

      // Return the necessary data with clear naming
      return {
        inquiryId: inquiryResult.inquiryId,
        referenceId: inquiryResult.referenceId,
        entityType: entity_type,
        entityId: entity_id,
      };
    } catch (error) {
      console.error(
        "❌ [PersonaInquiryService] Error creating inquiry:",
        error
      );
      throw error;
    }
  }

  /**
   * Saves Persona inquiry data to the database
   *
   * @param {Object} inquiryData - Inquiry data to save
   * @param {string} inquiryData.inquiryId - Persona inquiry ID
   * @param {string} inquiryData.referenceId - Reference ID for tracking
   * @param {string} inquiryData.templateId - Persona template ID used
   * @param {string} verificationMethod - Type of verification being performed
   * @param {string} entityType - Type of entity being verified
   * @param {string|number} entityId - ID of the entity being verified
   * @returns {Promise<Object>} Result of the save operation
   */
  async savePersonaInquiry(
    inquiryData,
    verificationMethod,
    entityType,
    entityId
  ) {
    console.log("🔍 [PersonaInquiryService] Saving inquiry data:", {
      inquiryData,
      verificationMethod,
      entityType,
      entityId,
    });

    try {
      // Validate required parameters
      if (!inquiryData.referenceId) {
        throw new Error("referenceId is required");
      }

      if (!verificationMethod) {
        throw new Error("verificationMethod is required");
      }

      if (
        !entityType ||
        !["carrier", "shipment", "driver"].includes(entityType)
      ) {
        throw new Error(
          "Valid entityType is required (carrier, shipment, or driver)"
        );
      }

      if (!entityId) {
        throw new Error("entityId is required");
      }

      // Map verification methods to valid verification contexts
      const contextMap = {
        document_verification: "document_verification",
        facial_recognition: "facial_recognition",
        id_number_check: "id_number_check",
        address_verification: "address_verification",
        sanctions_screening: "sanctions_screening",
        fraud_check: "fraud_check",
        business_verification: "business_verification",
      };

      // Use the mapped context or the original if no mapping exists
      const validContext = contextMap[verificationMethod] || verificationMethod;

      // 1. First, create entry in persona_resources table
      const resourceId = generateUUID();

      // Prepare the base resource data
      const resourceData = {
        id: resourceId,
        resource_id: inquiryData.inquiryId, // For inquiry type, use the inquiryId as resource_id
        resource_type: "inquiry", // This is an inquiry, not a report
        verification_context: validContext,
        status: "pending",
        template_id: inquiryData.templateId,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        metadata: {
          inquiry_id: inquiryData.inquiryId,
          reference_id: inquiryData.referenceId,
        },
        entity_type: entityType,
      };

      // Add the appropriate ID field based on entity type
      switch (entityType) {
        case "carrier":
          resourceData.carrier_id = entityId;
          break;
        case "shipment":
          resourceData.shipment_id = entityId;
          break;
        case "driver":
          resourceData.driver_id = entityId;
          break;
        default:
          console.error(
            "❌ [PersonaInquiryService] Invalid entity type:",
            entityType
          );
          throw new Error(`Unsupported entity type: ${entityType}`);
      }

      // Create the persona_resources record
      console.log(
        "💾 [PersonaInquiryService] Creating persona_resources record"
      );
      const { data: savedResource, error: resourceError } = await supabase
        .from("persona_resources")
        .insert(resourceData)
        .select()
        .single();

      if (resourceError) {
        console.error(
          "❌ [PersonaInquiryService] Error creating resource:",
          resourceError
        );
        throw new Error(`Error saving resource: ${resourceError.message}`);
      }

      console.log(
        "✅ [PersonaInquiryService] Created persona_resources record:",
        savedResource.id
      );

      // 2. Now, create entry in persona_verification_details using the resource ID from above
      const verificationDetailsData = {
        resource_id: savedResource.id, // Use the id from persona_resources as foreign key
        verification_method: verificationMethod,
        status: "pending",
        attributes: {
          document_type: "id_document",
          document_number: inquiryData.referenceId,
          inquiry_id: inquiryData.inquiryId, // Add inquiry_id to attributes for easier lookup
        },
        result: {
          success: false, // Initial state
          score: 0,
          inquiryId: inquiryData.inquiryId,
        },
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      // Save to persona_verification_details
      console.log(
        "💾 [PersonaInquiryService] Creating persona_verification_details record"
      );
      const { data: savedDetails, error: detailsError } = await supabase
        .from("persona_verification_details")
        .insert(verificationDetailsData)
        .select()
        .single();

      if (detailsError) {
        console.error(
          "❌ [PersonaInquiryService] Error creating verification details:",
          detailsError
        );
        throw new Error(
          `Error saving verification details: ${detailsError.message}`
        );
      }

      console.log(
        "✅ [PersonaInquiryService] Created verification details record:",
        savedDetails.id
      );

      // 3. Update the existing verification record in the legacy table if it exists
      if (inquiryData.inquiryId) {
        console.log(
          "📝 [PersonaInquiryService] Updating legacy verification record"
        );

        // Use verificationRepository to check if record exists
        const existingVerification =
          await this.verificationRepository.findByPersonaInquiryId(
            inquiryData.inquiryId
          );

        if (existingVerification) {
          // Update the existing record
          await this.verificationRepository.update(existingVerification.id, {
            persona_inquiry_id: inquiryData.inquiryId,
            updated_at: new Date().toISOString(),
          });

          console.log(
            "✅ [PersonaInquiryService] Updated legacy verification record"
          );
        } else {
          console.log(
            "ℹ️ [PersonaInquiryService] No existing verification record to update"
          );
        }
      }

      return {
        success: true,
        resourceId: savedResource.id,
        referenceId: inquiryData.referenceId,
        inquiryId: inquiryData.inquiryId,
      };
    } catch (error) {
      console.error("❌ [PersonaInquiryService] Error saving inquiry:", error);
      throw error;
    }
  }

  /**
   * Gets details about a Persona inquiry by reference ID
   *
   * @param {string} referenceId - The reference ID for the inquiry
   * @returns {Promise<Object>} Combined data from verifications table
   */
  async getPersonaInquiry(referenceId) {
    try {
      console.log(
        "🔍 [PersonaInquiryService] Getting inquiry using referenceId:",
        referenceId
      );

      if (!referenceId) {
        throw new Error("Reference ID is required");
      }

      // Fetch directly from verifications using referenceId as the ID
      // Assumes referenceId IS the primary key (id) of the verification record.
      // If referenceId maps to a different column (e.g., 'reference_id'), adjust the query.
      const verificationRecord = await this.verificationRepository.findById(
        referenceId
      );

      if (!verificationRecord) {
        console.warn(
          "⚠️ [PersonaInquiryService] No verification record found for referenceId:",
          referenceId
        );
        // Return null or a specific structure indicating not found
        return null;
      }

      // Return the fetched verification record directly
      // Adapt the structure as needed by the calling component (VetBusinessOwner.js)
      return {
        ...verificationRecord, // Spread the fields from the verification record
        // Map old fields if VetBusinessOwner.js expects them (adjust as needed)
        isComplete:
          verificationRecord.state === "completed" ||
          verificationRecord.state === "failed", // Example mapping
        status: verificationRecord.state, // Assuming 'state' holds the status
        result: verificationRecord.results, // Assuming 'results' holds the result data
        attributes: verificationRecord.results?.attributes || {}, // Example mapping
        lastUpdated: verificationRecord.updated_at,
      };
    } catch (error) {
      console.error("❌ [PersonaInquiryService] Error getting inquiry:", error);
      // Consider re-throwing or returning a specific error structure
      throw error;
    }
  }

  /**
   * Updates a Persona inquiry status and related records
   *
   * @param {Object} params - Update parameters
   * @param {string} params.referenceId - Resource ID for persona_verification_details
   * @param {string} [params.status] - New status value
   * @param {string} [params.inquiryId] - Persona inquiry ID
   * @param {boolean} [params.success=false] - Whether the verification was successful
   * @param {number} [params.score=0] - Verification score
   * @param {Object} [params.result={}] - Additional result data
   * @param {string} [params.phoneNumber] - Phone number associated with verification
   * @returns {Promise<Object>} Update result
   */
  async updatePersonaInquiry({
    referenceId,
    status,
    inquiryId,
    success = false,
    score = 0,
    result = {},
    phoneNumber,
  }) {
    try {
      console.log("🔍 [PersonaInquiryService] Updating inquiry:", {
        referenceId,
        status,
        inquiryId,
        success,
      });

      if (!referenceId) {
        throw new Error("referenceId is required");
      }

      // Determine standardized status or use explicit success flag
      const standardStatus = status
        ? mapPersonaInquiryStatus(status)
        : success
        ? "completed"
        : "pending";
      const isComplete = success || isVerificationSuccessful(status);
      const verificationState = mapStatusToVerificationState(standardStatus);

      // 1. Update verification record if inquiryId is provided
      if (inquiryId) {
        console.log("📝 [PersonaInquiryService] Updating verification record");

        // Create update data for verifications table
        const verificationUpdateData = {
          state: verificationState,
          persona_verification_complete: isComplete,
          results: getVerificationResultFromStatus(status, {
            score,
            ...result,
          }),
          updated_at: new Date().toISOString(),
        };

        // Add optional fields if provided
        if (phoneNumber) verificationUpdateData.phone_number = phoneNumber;

        // Use verificationRepository to update the record
        try {
          const existingVerification =
            await this.verificationRepository.findByPersonaInquiryId(inquiryId);

          if (existingVerification) {
            await this.verificationRepository.update(
              existingVerification.id,
              verificationUpdateData
            );

            console.log(
              "✅ [PersonaInquiryService] Updated verification record"
            );
          } else {
            console.log(
              "ℹ️ [PersonaInquiryService] No existing verification record found to update"
            );
          }
        } catch (legacyError) {
          console.error(
            "❌ [PersonaInquiryService] Error updating verification:",
            legacyError
          );
          // Don't throw here - since we're trying a best effort update
        }
      }

      return { success: true };
    } catch (error) {
      console.error(
        "❌ [PersonaInquiryService] Error updating inquiry:",
        error
      );
      return Promise.reject(error);
    }
  }

  /**
   * Gets the verification status for a Persona inquiry
   *
   * @param {string} personaInquiryId - The Persona inquiry ID
   * @returns {Promise<Object>} Verification status information
   */
  async getVerificationStatus(personaInquiryId) {
    try {
      console.log(
        "🔍 [PersonaInquiryService] Getting verification status:",
        personaInquiryId
      );

      if (!personaInquiryId) {
        throw new Error("Persona inquiry ID is required");
      }

      // Get verification record
      const verificationRecord =
        await this.verificationRepository.findByPersonaInquiryId(
          personaInquiryId
        );

      // If verification record doesn't exist, handle gracefully
      if (!verificationRecord) {
        console.warn(
          "⚠️ [PersonaInquiryService] No verification record found for inquiry:",
          personaInquiryId
        );
        return {
          success: false,
          message: "No verification record found",
          isComplete: false,
          status: "not_found",
        };
      }

      // Use status utility functions to standardize response
      const statusFromResults = verificationRecord.results?.status;
      const standardStatus = statusFromResults
        ? mapPersonaInquiryStatus(statusFromResults)
        : mapStatusToVerificationState(verificationRecord.state) ===
          "persona_complete"
        ? "completed"
        : "pending";

      // Return combined data
      return {
        success: true,
        verification: verificationRecord,
        isComplete: verificationRecord.persona_verification_complete || false,
        status: standardStatus,
      };
    } catch (error) {
      console.error(
        "❌ [PersonaInquiryService] Error getting verification status:",
        error
      );
      throw error;
    }
  }
}

// Export as singleton instance
export default new PersonaInquiryService();
