import VetBusinessOwner from "./VetBusinessOwner";

const VetBusinessOwnerForm = () => {
  return (
    <div className="min-h-screen bg-white flex flex-row">
      <VetBusinessOwner />
    </div>
  );
};

export default VetBusinessOwnerForm;
