import { supabase } from "../../lib/supabase";

export type OCRVerificationDetail = {
  id: string,
  verification_id: string,
  provider: "persona" | "internal" | string,
  document_type: "driver_license" | "insurance_card" | string,
  ocr_data: any,
  confidence_score: number | null,
  extracted_data: any,
  raw_provider_data: any,
  created_at: string,
  updated_at: string,
};

export type CreateOCRVerificationDetailParams = Omit<
  Partial<OCRVerificationDetail>,
  "id" | "created_at" | "updated_at"
> & {
  verification_id: string,
  provider: string,
  document_type: string,
};

export default class OCRRepository {
  constructor() {
    this.supabase = supabase;
  }

  // Create a new OCR verification detail record
  async create(
    params: CreateOCRVerificationDetailParams
  ): Promise<OCRVerificationDetail | null> {
    const { data, error } = await this.supabase
      .from("ocr_verification_details")
      .insert(params)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  // Get an OCR verification detail by id
  async findById(id: string): Promise<OCRVerificationDetail | null> {
    const { data, error } = await this.supabase
      .from("ocr_verification_details")
      .select("*")
      .eq("id", id)
      .single();

    if (error) throw error;
    return data;
  }

  // Find OCR verification details by verification id
  async findByVerificationId(
    verificationId: string
  ): Promise<OCRVerificationDetail | null> {
    const { data, error } = await this.supabase
      .from("ocr_verification_details")
      .select("*")
      .eq("verification_id", verificationId)
      .single();

    if (error && error.code !== "PGRST116") throw error;
    return data;
  }

  // Find OCR verification details by provider and document type
  async findByProviderAndDocumentType(
    provider: string,
    documentType: string
  ): Promise<OCRVerificationDetail[]> {
    const { data, error } = await this.supabase
      .from("ocr_verification_details")
      .select("*")
      .eq("provider", provider)
      .eq("document_type", documentType)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Update an OCR verification detail
  async update(
    id: string,
    params: Partial<OCRVerificationDetail>
  ): Promise<OCRVerificationDetail | null> {
    const { data, error } = await this.supabase
      .from("ocr_verification_details")
      .update(params)
      .eq("id", id)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  // Delete an OCR verification detail
  async delete(id: string): Promise<void> {
    const { error } = await this.supabase
      .from("ocr_verification_details")
      .delete()
      .eq("id", id);

    if (error) throw error;
  }

  // Get all OCR verification details
  async getAll(): Promise<OCRVerificationDetail[]> {
    const { data, error } = await this.supabase
      .from("ocr_verification_details")
      .select("*")
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data || [];
  }

  // Find the latest OCR verification detail for a verification
  async findLatestByVerificationId(
    verificationId: string
  ): Promise<OCRVerificationDetail | null> {
    const { data, error } = await this.supabase
      .from("ocr_verification_details")
      .select("*")
      .eq("verification_id", verificationId)
      .order("created_at", { ascending: false })
      .limit(1)
      .single();

    if (error && error.code !== "PGRST116") throw error;
    return data;
  }
}
