// Provide shipments hooks with proper data structure handling
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ShipmentService } from "../../api/services/business/ShipmentService";
import { 
  useShipment, 
  useShipmentByCargoOwnerId, 
  useShipmentMutations 
} from "../useShipments";

/**
 * Hook to fetch paginated shipments
 * @param {number} page - The page number to fetch
 * @param {number} limit - Number of items per page
 * @param {Object} filters - Filters to apply to the query
 * @returns {Object} The query result containing shipments
 */
export const useShipments = (page = 1, limit = 1000, filters = {}) => {
  return useQuery({
    queryKey: ["shipments", page, limit, filters],
    queryFn: async () => {
      console.log("Fetching shipments with params:", { page, limit, filters });
      try {
        const result = await ShipmentService.getShipments(page, limit, filters);
        console.log("Shipments data received:", result);
        return result;
      } catch (error) {
        console.error("Error fetching shipments:", error);
        throw error;
      }
    },
    keepPreviousData: true,
    staleTime: 30000, // 30 seconds
    cacheTime: 300000, // 5 minutes
  });
};

// Export other shipment-related hooks
export {
  useShipment,
  useShipmentByCargoOwnerId,
  useShipmentMutations
};
