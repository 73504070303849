// const { ... } = require('...');
const VerificationData = require("../models/VerificationData");

class VerificationProvider {
  constructor(config = {}) {
    // Core dependencies
    this.verificationRepository = config.verificationRepository || null;

    // External providers as dependencies
    this.personaProvider = config.personaProvider || null;
    this.documentProvider = config.documentProvider || null;
    this.businessRegistrationRepository =
      config.businessRegistrationRepository || null;
    this.businessReportRepository = config.businessReportRepository || null;
    this.ocrRepository = config.ocrRepository || null;
    this.ocrService = config.ocrService || null;
    this.insuranceVerificationRepository =
      config.insuranceVerificationRepository || null;
    this.personaIDRepository = config.personaIDRepository || null;
    this.businessVerificationRepository =
      config.businessVerificationRepository || null;

    // Validation
    if (!this.verificationRepository) {
      console.warn(
        "VerificationProvider initialized without verificationRepository"
      );
    }
  }

  async createDriverVerification(data) {
    try {
      if (!data.driverId) {
        throw new Error("Driver ID is required");
      }

      const verificationData = VerificationData.forDriverVerification(data);

      const { isValid, errors } = verificationData.validate();
      if (!isValid) {
        throw new Error(`Invalid verification data: ${errors.join(", ")}`);
      }

      const verification = await this.verificationRepository.create(
        verificationData.toObject()
      );

      return verification;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error creating driver verification:",
        error
      );
      throw error;
    }
  }

  async createBusinessRegistrationVerification(data) {
    try {
      if (!data.carrierId) {
        throw new Error("Carrier ID is required");
      }

      // Create the main verification record
      const verificationData =
        VerificationData.forBusinessRegistrationVerification(data);
      const { isValid, errors } = verificationData.validate();

      if (!isValid) {
        throw new Error(`Invalid verification data: ${errors.join(", ")}`);
      }

      const verification = await this.verificationRepository.create(
        verificationData.toObject()
      );

      // Create the business registration details record if repository is available
      if (this.businessRegistrationRepository && data.businessDetails) {
        await this.businessRegistrationRepository.create({
          verification_id: verification.id,
          business_name: data.businessDetails.business_name,
          ein: data.businessDetails.ein,
          registered_address: data.businessDetails.registered_address,
          owner_count: data.businessDetails.owner_count,
          business_type: data.businessDetails.business_type,
          year_established: data.businessDetails.year_established,
          verification_status: "pending",
          raw_provider_data: data.businessDetails.raw_provider_data,
        });
      }

      return verification;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error creating business registration verification:",
        error
      );
      throw error;
    }
  }

  async createBusinessReportVerification(data) {
    try {
      if (!data.carrierId) {
        throw new Error("Carrier ID is required");
      }

      // Create the main verification record
      const verificationData =
        VerificationData.forBusinessReportVerification(data);
      const { isValid, errors } = verificationData.validate();

      if (!isValid) {
        throw new Error(`Invalid verification data: ${errors.join(", ")}`);
      }

      const verification = await this.verificationRepository.create(
        verificationData.toObject()
      );

      // Create the business report details record if repository is available
      if (this.businessReportRepository && data.reportDetails) {
        await this.businessReportRepository.create({
          verification_id: verification.id,
          report_id: data.reportDetails.report_id,
          report_type: data.reportDetails.report_type,
          report_status: data.reportDetails.report_status || "pending",
          risk_signals: data.reportDetails.risk_signals,
          match_results: data.reportDetails.match_results,
          raw_provider_data: data.reportDetails.raw_provider_data,
        });
      }

      return verification;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error creating business report verification:",
        error
      );
      throw error;
    }
  }

  async createInsuranceVerification(data) {
    try {
      if (!data.carrierId) {
        throw new Error("Carrier ID is required");
      }

      const verificationData = VerificationData.forInsuranceVerification(data);

      const { isValid, errors } = verificationData.validate();

      if (!isValid) {
        throw new Error(`Invalid verification data: ${errors.join(", ")}`);
      }

      const verification = await this.verificationRepository.create(
        verificationData.toObject()
      );

      return verification;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error creating insurance verification:",
        error
      );
      throw error;
    }
  }

  async createOCRVerification(data) {
    try {
      if (!data.driverId) {
        throw new Error("Driver ID is required");
      }

      const verificationData = VerificationData.forOCRVerification(data);
      const { isValid, errors } = verificationData.validate();

      if (!isValid) {
        throw new Error(`Invalid verification data: ${errors.join(", ")}`);
      }

      // Create the main verification record
      const verification = await this.verificationRepository.create(
        verificationData.toObject()
      );

      // Create the OCR details record if repository is available
      if (this.ocrRepository && data.ocrDetails) {
        await this.ocrRepository.create({
          verification_id: verification.id,
          provider: data.ocrDetails.provider || "internal",
          document_type: data.ocrDetails.document_type || "driver_license",
          ocr_data: data.ocrDetails.ocr_data,
          confidence_score: data.ocrDetails.confidence_score,
          extracted_data: data.ocrDetails.extracted_data,
          raw_provider_data: data.ocrDetails.raw_provider_data,
        });
      }

      return verification;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error creating OCR verification:",
        error
      );
      throw error;
    }
  }

  /**
   * Create a verification for a document that needs verification
   *
   * @param {Object} data - Document verification data
   * @returns {Promise<Object>} The created verification record
   */
  async createDocumentVerification(data) {
    try {
      // Check for required entity ID
      if (!data.driverId && !data.carrierId) {
        throw new Error("Either driver ID or carrier ID is required");
      }

      // Create verification data using the factory method
      const verificationData = VerificationData.forDocumentVerification(data);
      const { isValid, errors } = verificationData.validate();

      if (!isValid) {
        throw new Error(`Invalid verification data: ${errors.join(", ")}`);
      }

      // Create the main verification record
      const verification = await this.verificationRepository.create(
        verificationData.toObject()
      );

      return verification;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error creating document verification:",
        error
      );
      throw error;
    }
  }

  /**
   * Process a document for verification and link it to an existing verification
   *
   * @param {string} verificationId - ID of the existing verification
   * @param {Object} documentData - Information about the document
   * @param {string} documentData.documentType - Type of document
   * @returns {Promise<Object>} Updated verification record
   */
  async processDocumentVerification(verificationId, documentData) {
    try {
      if (!verificationId) {
        throw new Error("Verification ID is required");
      }

      // Fetch the verification record to ensure it exists
      const verification = await this.verificationRepository.findById(
        verificationId
      );
      if (!verification) {
        throw new Error(`Verification with ID ${verificationId} not found`);
      }

      // Update the verification with document processing status
      const updatedVerification = await this.verificationRepository.update(
        verificationId,
        {
          state: "document_verification_in_progress",
          updated_at: new Date().toISOString(),
        }
      );

      return updatedVerification;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error processing document verification:",
        error
      );
      throw error;
    }
  }

  /**
   * Complete a document verification process
   *
   * @param {string} verificationId - ID of the verification
   * @param {Object} result - Verification result
   * @param {boolean} result.success - Whether verification was successful
   * @param {string} [result.failureReason] - Reason for failure if unsuccessful
   * @returns {Promise<Object>} Updated verification record
   */
  async completeDocumentVerification(verificationId, result) {
    try {
      if (!verificationId) {
        throw new Error("Verification ID is required");
      }

      // Update the verification status based on the result
      const updatedVerification = await this.verificationRepository.update(
        verificationId,
        {
          state: result.success ? "verified" : "rejected",
          results: result.failureReason
            ? { failureReason: result.failureReason }
            : null,
          updated_at: new Date().toISOString(),
        }
      );

      return updatedVerification;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error completing document verification:",
        error
      );
      throw error;
    }
  }

  async getVerification(id) {
    try {
      const verification = await this.verificationRepository.findById(id);
      return verification;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error getting verification:",
        error
      );
      throw error;
    }
  }

  async getVerificationsByDriverId(driverId) {
    try {
      const verifications = await this.verificationRepository.findByDriverId(
        driverId
      );
      return verifications;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error getting verifications by driver ID:",
        error
      );
      throw error;
    }
  }

  async getVerificationsByCarrierId(carrierId) {
    try {
      const verifications = await this.verificationRepository.findByCarrierId(
        carrierId
      );
      return verifications;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error getting verifications by carrier ID:",
        error
      );
      throw error;
    }
  }

  async getLatestVerificationByDriverId(driverId) {
    try {
      const verifications =
        await this.verificationRepository.findLatestDriverVerificationByType(
          driverId,
          "driver_id"
        );
      return verifications[0] || null;
    } catch (error) {
      console.error(
        "🚨 [VerificationProvider] Error getting latest verification by driver ID:",
        error
      );
      throw error;
    }
  }

  /**
   * Check and update the status of a verification
   *
   * @param {string} verificationId - ID of the verification to check
   * @returns {Promise<Object>} Updated verification with current status
   */
  async checkVerificationStatus(verificationId) {
    try {
      const verification = await this.verificationRepository.findById(
        verificationId
      );

      if (!verification) {
        throw new Error(`Verification with ID ${verificationId} not found`);
      }

      // Dispatch to appropriate status check based on verification type
      let statusResult;
      switch (verification.verification_type) {
        case "driver_id":
          if (verification.persona_inquiry_id && this.personaProvider) {
            statusResult = await this.personaProvider.checkPersonaInquiryStatus(
              verification.persona_inquiry_id
            );
            // Map external status to internal status
            verification.state = this._mapPersonaStatusToState(
              statusResult.status
            );
          }
          break;

        case "business_registration":
        case "business_report":
          // Handle business verification status check
          break;

        // Other verification types...

        default:
          console.log(
            `No specific status check for verification type: ${verification.verification_type}`
          );
          break;
      }

      // Update the verification record with new status
      return await this.verificationRepository.update(verificationId, {
        state: verification.state,
        updated_at: new Date().toISOString(),
      });
    } catch (error) {
      console.error(
        `🚨 [VerificationProvider] Error checking verification status: ${error}`
      );
      throw error;
    }
  }

  /**
   * Update a verification record
   *
   * @param {string} verificationId - ID of the verification to update
   * @param {Object} data - Data to update
   * @returns {Promise<Object>} Updated verification
   */
  async updateVerification(verificationId, data) {
    try {
      if (!verificationId) {
        throw new Error("Verification ID is required");
      }

      return await this.verificationRepository.update(verificationId, {
        ...data,
        updated_at: new Date().toISOString(),
      });
    } catch (error) {
      console.error(
        `🚨 [VerificationProvider] Error updating verification: ${error}`
      );
      throw error;
    }
  }
}

// Create singleton instance
const verificationProvider = new VerificationProvider();

// Export the class and singleton instance
module.exports = VerificationProvider;
module.exports.verificationProvider = verificationProvider;
