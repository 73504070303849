// @ts-nocheck
import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Loader2 } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Alert, AlertDescription } from "@/components/ui/alert";

// Define the validation schema using Zod
const driverFormSchema = z.object({
  first_name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
  date_of_birth: z
    .string()
    .min(1, "Date of birth is required")
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Date must be in YYYY-MM-DD format"),
  phone_number: z
    .string()
    .min(1, "Phone number is required")
    .regex(
      /^\d{3}-\d{3}-\d{4}$/,
      "Phone number must be in 123-456-7890 format"
    ),
  email: z.string().min(1, "Email is required").email("Invalid email address"),
  driver_license_number: z.string().min(1, "License number is required"),
  driver_license_state: z.string().min(1, "License state is required"),
  driver_license_expiration_date: z
    .string()
    .min(1, "License expiration date is required")
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Date must be in YYYY-MM-DD format"),
  driver_license_class: z.string().min(1, "License class is required"),
  employment_start_date: z
    .string()
    .min(1, "Employment start date is required")
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Date must be in YYYY-MM-DD format"),
  employment_status: z.string().min(1, "Employment status is required"),
});

/**
 * AddDriverForm - Form component for adding a new driver
 *
 * @param {Object} props - Component props
 * @param {Function} props.onSubmit - Function to handle form submission
 * @param {Object} props.defaultValues - Default values for the form
 * @param {boolean} props.isSubmitting - Whether the form is currently submitting
 * @param {string} props.error - Error message to display
 */
const AddDriverForm = ({
  onSubmit,
  defaultValues,
  isSubmitting = false,
  error = "",
}) => {
  // Initialize form with validation schema and default values
  const form = useForm({
    resolver: zodResolver(driverFormSchema),
    defaultValues: defaultValues || {
      first_name: "",
      last_name: "",
      date_of_birth: "",
      phone_number: "",
      email: "",
      driver_license_number: "",
      driver_license_state: "",
      driver_license_expiration_date: "",
      driver_license_class: "",
      employment_start_date: "",
      employment_status: "Active",
    },
  });

  // Handle form submission
  const handleSubmit = form.handleSubmit((data) => {
    onSubmit(data);
  });

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Display form error if present */}
        {error && (
          <Alert variant="destructive" className="mb-6">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {/* Form fields arranged in a grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Personal Information */}
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input placeholder="John" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input placeholder="Doe" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="date_of_birth"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Date of Birth</FormLabel>
                <FormControl>
                  <Input placeholder="YYYY-MM-DD" {...field} />
                </FormControl>
                <FormDescription>Format: YYYY-MM-DD</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="phone_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone Number</FormLabel>
                <FormControl>
                  <Input placeholder="123-456-7890" {...field} />
                </FormControl>
                <FormDescription>Format: 123-456-7890</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="john.doe@example.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* License Information */}
          <FormField
            control={form.control}
            name="driver_license_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Driver License Number</FormLabel>
                <FormControl>
                  <Input placeholder="D1234567" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="driver_license_state"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Driver License State</FormLabel>
                <FormControl>
                  <Input placeholder="CA" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="driver_license_expiration_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>License Expiration Date</FormLabel>
                <FormControl>
                  <Input placeholder="YYYY-MM-DD" {...field} />
                </FormControl>
                <FormDescription>Format: YYYY-MM-DD</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="driver_license_class"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Driver License Class</FormLabel>
                <FormControl>
                  <Input placeholder="Class A" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Employment Information */}
          <FormField
            control={form.control}
            name="employment_start_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Employment Start Date</FormLabel>
                <FormControl>
                  <Input placeholder="YYYY-MM-DD" {...field} />
                </FormControl>
                <FormDescription>Format: YYYY-MM-DD</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="employment_status"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Employment Status</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select status" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="Active">Active</SelectItem>
                    <SelectItem value="Inactive">Inactive</SelectItem>
                    <SelectItem value="Blocked">Blocked</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Submit button */}
        <Button type="submit" disabled={isSubmitting} className="w-full">
          {isSubmitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Submitting...
            </>
          ) : (
            "Submit Driver Information"
          )}
        </Button>
      </form>
    </Form>
  );
};

export default AddDriverForm;
