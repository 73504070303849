import { useState } from "react";

/**
 * Custom hook for managing multi-step forms
 *
 * @param {Object} options - Configuration options
 * @param {Array} options.steps - Array of step objects with labels and optional field arrays
 * @param {number} [options.initialStep=0] - Initial step index
 * @returns {Object} Step management functions and state
 */
export function useMultiStepForm({ steps, initialStep = 0 }) {
  const [currentStep, setCurrentStep] = useState(initialStep);

  /**
   * Move to the next step, with optional validation
   *
   * @param {Function} [validateFn] - Optional validation function that returns boolean
   * @returns {boolean} Whether the step was changed
   */
  const handleNextStep = (validateFn) => {
    if (validateFn) {
      // Run validation function if provided
      const isValid = validateFn();
      if (!isValid) return false;
    }

    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
      return true;
    }
    return false;
  };

  /**
   * Move to the previous step
   *
   * @returns {boolean} Whether the step was changed
   */
  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
      return true;
    }
    return false;
  };

  /**
   * Go to a specific step
   *
   * @param {number} step - Target step index
   * @returns {boolean} Whether the step was changed
   */
  const goToStep = (step) => {
    if (step >= 0 && step < steps.length) {
      setCurrentStep(step);
      return true;
    }
    return false;
  };

  return {
    currentStep,
    steps,
    totalSteps: steps.length,
    isFirstStep: currentStep === 0,
    isLastStep: currentStep === steps.length - 1,
    handleNextStep,
    handlePreviousStep,
    goToStep,
  };
}
