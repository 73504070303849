// @ts-nocheck
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useInquiryVerificationMutations,
  useVerificationByInquiryId,
} from "../../hooks/verifications";
import { getVerificationStrategy } from "../../api/services/verification/VerificationService";
import FlowComponent from "../VerificationFlow/FlowComponent";
import SuccessPage from "../Components/SuccessPage";
import FailurePage from "../Components/FailurePage";
import createPersonaClient from "../../lib/persona";

// Import shadcn components
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Progress } from "@/components/ui/progress";
import { Badge } from "@/components/ui/badge";
import { Loader2, CheckCircle, AlertCircle, Info, Clock } from "lucide-react";

const BusinessVerificationKYB = () => {
  const { id: inquiryId } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [statusMessage, setStatusMessage] = useState("");
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const [isPersonaLoading, setIsPersonaLoading] = useState(false);
  const steps = ["Business Verification", "Complete"];

  // Use the verification hooks
  const {
    data: verification,
    isLoading: isVerificationLoading,
    refetch: refetchVerification,
  } = useVerificationByInquiryId(inquiryId);
  const { updateVerification, checkStatus } = useInquiryVerificationMutations();

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else {
      handleFlowComplete();
    }
  };

  const handleFlowComplete = async () => {
    try {
      // Update verification state to complete
      await updateVerification.mutateAsync({
        inquiryId,
        data: { state: "Complete" },
      });
    } catch (error) {
      console.error("Error completing verification:", error);
      setStatusMessage("An error occurred while completing the verification.");
    }
  };

  const handlePersonaVerification = async () => {
    if (!verification?.persona_inquiry_id) {
      console.error("Missing inquiry ID for Persona verification");
      setStatusMessage("Unable to start verification: missing inquiry ID");
      return;
    }

    const template_id = process.env.REACT_APP_PERSONA_TEMPLATE_ID_KYB_BUSINESS;
    if (!template_id && !verification?.persona_inquiry_id) {
      console.error("Persona Template ID is required for new verifications");
      setStatusMessage("Configuration error: missing template ID");
      return;
    }

    setIsPersonaLoading(true);
    setStatusMessage("Initializing verification...");

    try {
      if (verification?.persona_inquiry_id) {
        console.log("Checking verification status:", verification);
        // Rest of the existing code
      }

      // First, ensure the verification record is properly initialized
      try {
        await updateVerification.mutateAsync({
          inquiryId: verification.persona_inquiry_id,
          data: {
            state: "Started",
            updated_at: new Date().toISOString(),
          },
        });
        console.log("Verification record initialized before starting Persona");
      } catch (updateError) {
        console.error(
          "Warning: Could not initialize verification record:",
          updateError
        );
        // Continue anyway as this is not a critical error
      }

      // Create the Persona client with callback handling
      const personaClient = createPersonaClient(
        verification.persona_inquiry_id,
        template_id,
        async (data) => {
          try {
            console.log("Persona verification completed with data:", data);
            // Store the inquiry ID returned from Persona to ensure we use it for future operations
            const inquiryIdToUse =
              data?.inquiryId || verification.persona_inquiry_id;

            if (!inquiryIdToUse) {
              console.error("No inquiry ID available after Persona completion");
              setStatusMessage(
                "Verification completed but no ID was returned. Please check status."
              );
              setIsPersonaLoading(false);
              return;
            }

            console.log(
              `Updating verification with inquiry ID: ${inquiryIdToUse}`
            );

            await updateVerification.mutateAsync({
              inquiryId: inquiryIdToUse,
              data: {
                persona_verification_complete: true,
                state: "pending",
                // Update persona_inquiry_id if we got a new one from Persona
                ...(data?.inquiryId &&
                  data.inquiryId !== verification?.persona_inquiry_id && {
                    persona_inquiry_id: data.inquiryId,
                  }),
              },
            });

            console.log("Verification record updated successfully");
            setStatusMessage("Verification completed. Checking status...");

            // Fetch the latest verification data
            await refetchVerification();

            // Automatically check status after completion
            await handleCheckStatus();
          } catch (error) {
            console.error(
              "Error updating verification after completion:",
              error
            );
            setStatusMessage(
              `Verification may be complete, but we couldn't update our records: ${error.message}`
            );
          } finally {
            setIsPersonaLoading(false);
          }
        },
        verification.persona_inquiry_id
      );

      if (!personaClient) {
        throw new Error(
          "Failed to create Persona client - check console for details"
        );
      }

      console.log("Persona client created successfully");
    } catch (error) {
      console.error("Error creating Persona client:", error);
      // Provide more specific guidance based on the error message
      if (
        error.message?.includes("Only one of templateId") ||
        error.message?.includes("should be specified to start a flow")
      ) {
        setStatusMessage(
          "Configuration error: There's a conflict between your existing verification and starting a new one. Please try refreshing the page."
        );
      } else {
        setStatusMessage(
          `Failed to start verification process: ${error.message}`
        );
      }
      setIsPersonaLoading(false);
    }
  };

  const handleCheckStatus = async () => {
    setIsCheckingStatus(true);
    setStatusMessage("Checking verification status...");

    try {
      // First ensure we have the current verification data
      if (!verification) {
        await refetchVerification();
      }

      // Safe-check for inquiryId - use the one from params or from verification record
      const inquiryIdToUse = inquiryId || verification?.persona_inquiry_id;

      if (!inquiryIdToUse) {
        setStatusMessage("Missing inquiry ID. Cannot check status.");
        setIsCheckingStatus(false);
        return;
      }

      console.log(`Checking status for inquiry ID: ${inquiryIdToUse}`);

      // Call the API to check status
      try {
        const result = await checkStatus.mutateAsync({
          inquiryId: inquiryIdToUse,
        });

        // Set appropriate status message based on the result
        if (result.success) {
          setStatusMessage("Verification approved successfully!");
        } else if (result.status === "Declined") {
          setStatusMessage(
            "Verification was declined. Please contact support."
          );
        } else if (result.status === "Started" || result.status === "pending") {
          setStatusMessage(
            "Verification is still in progress. Please check back later."
          );
        } else {
          setStatusMessage(
            result.message || `Status: ${result.status || "Unknown"}`
          );
        }
      } catch (statusError) {
        console.error("Error from checkStatus mutation:", statusError);

        // Fall back to direct verification status check if mutation fails
        try {
          if (verification?.persona_inquiry_id) {
            console.log(
              "Falling back to direct status check with persona_inquiry_id"
            );
            const directResult = await getVerificationStrategy(
              verification.persona_inquiry_id
            );
            setStatusMessage(`Status: ${directResult.status || "Unknown"}`);
          } else {
            throw new Error(
              "No persona_inquiry_id available for fallback status check"
            );
          }
        } catch (fallbackError) {
          console.error("Fallback status check also failed:", fallbackError);
          throw statusError; // Re-throw the original error
        }
      }

      // Always refresh verification data
      await refetchVerification();
    } catch (error) {
      console.error("Error checking status:", error);
      setStatusMessage(
        "Error checking status: " + (error.message || "Unknown error")
      );
    } finally {
      setIsCheckingStatus(false);
    }
  };

  const getStatusBadge = () => {
    if (!verification) return null;

    if (verification.persona_verification_complete) {
      return (
        <Badge className="bg-green-500">
          <CheckCircle className="w-3 h-3 mr-1" />
          <span>Verified</span>
        </Badge>
      );
    } else if (
      verification.state === "Started" ||
      verification.state === "pending"
    ) {
      return (
        <Badge
          variant="outline"
          className="bg-amber-100 text-amber-800 border-amber-300"
        >
          <Clock className="w-3 h-3 mr-1" />
          <span>In Progress</span>
        </Badge>
      );
    } else if (verification.state === "Declined") {
      return (
        <Badge variant="destructive">
          <AlertCircle className="w-3 h-3 mr-1" />
          <span>Declined</span>
        </Badge>
      );
    } else {
      return (
        <Badge variant="outline" className="bg-slate-100">
          <Info className="w-3 h-3 mr-1" />
          <span>{verification.state || "Not Started"}</span>
        </Badge>
      );
    }
  };

  // Loading component
  const LoadingSpinner = () => (
    <div className="flex flex-col items-center justify-center py-12">
      <Loader2 className="w-8 h-8 text-slate-700 animate-spin mb-4" />
      <p className="text-slate-600">Loading verification data...</p>
    </div>
  );

  const renderContent = () => {
    if (isVerificationLoading) {
      return <LoadingSpinner />;
    }

    if (isPersonaLoading) {
      return <LoadingSpinner />;
    }

    if (verification?.isComplete) {
      return verification.personaSuccess ? (
        <SuccessPage />
      ) : (
        <FailurePage personaFailed={!verification.personaSuccess} />
      );
    }

    if (!verification?.persona_inquiry_id) {
      return (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Verification Error</AlertTitle>
          <AlertDescription>
            Verification data is incomplete. Please go back and try again.
          </AlertDescription>
        </Alert>
      );
    }

    switch (currentStep) {
      case 1:
        return (
          <Card className="mb-6">
            <CardHeader>
              <div className="flex justify-between items-center">
                <CardTitle>Business Verification</CardTitle>
                {getStatusBadge()}
              </div>
              <CardDescription>
                Complete the verification process to continue
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex items-center justify-between mb-4">
                <span className="text-sm font-medium text-slate-700">
                  Verification Progress
                </span>
                <span className="text-sm text-slate-500">
                  Step {currentStep} of {steps.length}
                </span>
              </div>

              <Progress
                value={(currentStep / steps.length) * 100}
                className="h-2"
              />

              {statusMessage && (
                <Alert className="mt-4 bg-blue-50 border-blue-200">
                  <Info className="h-4 w-4 text-blue-500" />
                  <AlertDescription className="text-blue-700 ml-2">
                    {statusMessage}
                  </AlertDescription>
                </Alert>
              )}

              <div className="pt-4 space-y-2">
                <Button
                  className="w-full"
                  variant="outline"
                  onClick={handleCheckStatus}
                  disabled={
                    isCheckingStatus ||
                    verification?.persona_verification_complete
                  }
                >
                  {isCheckingStatus ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Checking Status...
                    </>
                  ) : verification?.persona_verification_complete ? (
                    <>
                      <CheckCircle className="mr-2 h-4 w-4" />
                      Verification Complete
                    </>
                  ) : (
                    <>
                      <Clock className="mr-2 h-4 w-4" />
                      Check Verification Status
                    </>
                  )}
                </Button>

                <Button
                  className="w-full"
                  onClick={handlePersonaVerification}
                  disabled={
                    verification?.persona_verification_complete ||
                    !verification?.persona_inquiry_id
                  }
                >
                  {verification?.persona_verification_complete ? (
                    <>
                      <CheckCircle className="mr-2 h-4 w-4" />
                      Verification Complete
                    </>
                  ) : (
                    "Start Business Verification"
                  )}
                </Button>
              </div>
            </CardContent>
          </Card>
        );
      case 2:
        return (
          <Card className="mb-6">
            <CardHeader>
              <div className="flex justify-between items-center">
                <CardTitle>Verification Complete</CardTitle>
                {getStatusBadge()}
              </div>
              <CardDescription>
                Your business verification status
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex justify-between items-center p-4 bg-slate-50 rounded-md border border-slate-200">
                <span className="text-sm font-medium">
                  Business Verification Status:
                </span>
                <span
                  className={`text-sm font-bold ${
                    verification?.persona_verification_complete
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {verification?.persona_verification_complete
                    ? "Verified"
                    : "Not Verified"}
                </span>
              </div>

              {verification?.persona_verification_complete && (
                <Alert className="bg-green-50 border-green-200">
                  <CheckCircle className="h-4 w-4 text-green-500" />
                  <AlertTitle className="text-green-700 ml-2">
                    Verification Successful
                  </AlertTitle>
                  <AlertDescription className="text-green-600 ml-6">
                    Your business has been successfully verified.
                  </AlertDescription>
                </Alert>
              )}
            </CardContent>
          </Card>
        );
      default:
        return (
          <Alert variant="destructive" className="mb-6">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Invalid Step</AlertTitle>
            <AlertDescription>
              Unknown verification step. Please go back and try again.
            </AlertDescription>
          </Alert>
        );
    }
  };

  return (
    <FlowComponent
      title="Business Verification"
      subtitle="Please complete the following steps to verify your business identity."
      steps={steps}
      currentStep={currentStep}
      onSubmit={handleNext}
      buttonText={currentStep === steps.length ? "Complete" : "Next"}
      showBackButton={false}
      verification={verification}
    >
      {renderContent()}
    </FlowComponent>
  );
};

export default BusinessVerificationKYB;
