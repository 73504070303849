// @ts-check
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import UserService from "../api/services/core/UserService";

/**
 * Hook to create a new user
 * @returns {Object} The mutation object for creating a user
 */
export const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: UserService.createUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: (error) => {
      console.error("Error creating user:", error);
    },
  });
};

/**
 * Hook to fetch all users
 * @returns {Object} The query result containing all users
 */
export const useGetAllUsers = () => {
  return useQuery({
    queryKey: ["users"],
    queryFn: UserService.getAllUsers,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to fetch a user by ID
 * @param {string} userId - The ID of the user to fetch
 * @returns {Object} The query result containing the user
 */
export const useGetUserById = (userId) => {
  return useQuery({
    queryKey: ["user", userId],
    queryFn: () => UserService.getUserById(userId),
    enabled: !!userId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to update a user
 * @param {string} userId - The ID of the user to update
 * @returns {Object} The mutation object for updating a user
 */
export const useUpdateUser = (userId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userData) => UserService.updateUser(userId, userData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      queryClient.invalidateQueries({ queryKey: ["user", userId] });
    },
    onError: (error) => {
      console.error("Error updating user:", error);
    },
  });
};

/**
 * Hook to delete a user
 * @param {string} userId - The ID of the user to delete
 * @returns {Object} The mutation object for deleting a user
 */
export const useDeleteUser = (userId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => UserService.deleteUser(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: (error) => {
      console.error("Error deleting user:", error);
    },
  });
};

/**
 * Hook to get and manage the current user session
 * @returns {Object} Object containing session and loading state
 */
export const useGetUserSession = () => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        console.log("Fetching session...");
        const session = await UserService.getSession();
        console.log("Session response:", session);
        setSession(session);
        setLoading(false);
      } catch (error) {
        console.error("Error in fetchSession:", error);
        setLoading(false);
      }
    };

    fetchSession();

    const {
      data: { subscription },
    } = UserService.onAuthStateChange((session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return { session, loading };
};
