// @ts-check
import { useState, useRef, useCallback, useMemo } from "react";
import {
  useCarrierByDOT,
  useCarrierBasics,
  useCarrierCargoCarried,
  useCarrierOperationClassification,
  useCarrierOOS,
  useCarrierDocketNumbers,
  useCarrierAuthority,
} from "./useFMSCA";
import { useCarrierMutations } from "./useCarriers";
import { CarrierService } from "../api/services/carrier/CarrierService";
import { CarrierDataService } from "../api/services/carrier/CarrierDataService";

/**
 * Custom hook for fetching and managing carrier data
 * @param {string|number} dotNumber - DOT number of the carrier
 * @param {Object} options - Additional options for the query
 * @param {boolean} [options.enabled=false] - Whether the query should automatically run
 * @returns {Object} Carrier data and related functions
 */
export const useCarrierData = (dotNumber, options = { enabled: false }) => {
  // Add state to track current DOT number
  const [currentDotNumber, setCurrentDotNumber] = useState(dotNumber);

  // Remove the empty string fallback
  const formattedDotNumber = currentDotNumber?.toString().trim();

  // Create instance of CarrierDataService
  const carrierDataService = useMemo(() => new CarrierDataService(), []);

  const queryOptions = {
    ...options,
    enabled: options?.enabled && Boolean(formattedDotNumber),
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
    retry: 2, // Retry failed requests twice
    retryDelay: 1000, // Wait 1 second between retries
  };

  const { insertCarrier: insertCarrierMutation } = useCarrierMutations();

  // State to track if we're currently fetching data
  const [isFetching, setIsFetching] = useState(false);

  // Cache for storing previously fetched data
  const dataCache = useRef({});

  // Add new loading states at the top of the hook
  const [loadingState, setLoadingState] = useState({
    fmcsaLoading: false,
    carrierDbLoading: false,
    error: null,
    progress: 0,
    currentOperation: null,
  });

  // Each hook is initialized with enabled: false (lazy fetching)
  const { refetch: refetchCarrierByDOT, isLoading: isLoadingCarrierByDOT } =
    useCarrierByDOT(dotNumber, { enabled: false });
  const { refetch: refetchCarrierBasics, isLoading: isLoadingCarrierBasics } =
    useCarrierBasics(dotNumber, { enabled: false });
  const { refetch: refetchCarrierCargo, isLoading: isLoadingCarrierCargo } =
    useCarrierCargoCarried(dotNumber, { enabled: false });
  const {
    refetch: refetchCarrierOperation,
    isLoading: isLoadingCarrierOperation,
  } = useCarrierOperationClassification(dotNumber, { enabled: false });
  const { refetch: refetchCarrierOOS, isLoading: isLoadingCarrierOOS } =
    useCarrierOOS(dotNumber, { enabled: false });
  const {
    refetch: refetchCarrierDocketNumbers,
    isLoading: isLoadingCarrierDocketNumbers,
  } = useCarrierDocketNumbers(dotNumber, { enabled: false });
  const {
    refetch: refetchCarrierAuthority,
    isLoading: isLoadingCarrierAuthority,
  } = useCarrierAuthority(dotNumber, { enabled: false });

  // Combined loading state (if needed for UI)
  const isAnyLoading =
    isLoadingCarrierByDOT ||
    isLoadingCarrierBasics ||
    isLoadingCarrierCargo ||
    isLoadingCarrierOperation ||
    isLoadingCarrierOOS ||
    isLoadingCarrierDocketNumbers ||
    isLoadingCarrierAuthority;

  /**
   * Helper to update loading state
   * @param {Object} updates - The updates to apply to the loading state
   */
  const updateLoadingState = useCallback((updates) => {
    setLoadingState((current) => ({
      ...current,
      ...updates,
    }));
  }, []);

  /**
   * Fetch all carrier data for a given DOT number
   * @param {string|number} dot_number - The DOT number to fetch data for
   * @returns {Promise<Object>} The fetched carrier data
   */
  const refetchAll = useCallback(
    async (dot_number) => {
      console.log("[refetchAll] Starting with DOT:", dot_number);

      if (!dot_number) {
        console.error("[refetchAll] No DOT number provided");
        return;
      }

      setCurrentDotNumber(dot_number);

      try {
        // Use the CarrierDataService to fetch the carrier data
        // Pass the updateLoadingState callback to receive progress updates
        const result = await carrierDataService.fetchCarrierData(
          dot_number,
          updateLoadingState
        );

        // Cache the result
        dataCache.current[dot_number] = result.fmcsaData;

        return result;
      } catch (error) {
        console.error("[refetchAll] Error:", error);
        updateLoadingState({
          fmcsaLoading: false,
          carrierDbLoading: false,
          error: error.message,
          currentOperation: "Error",
        });
        throw error;
      }
    },
    [carrierDataService, updateLoadingState]
  );

  // Update isDataCached check
  const isDataCached = Boolean(currentDotNumber?.toString().trim())
    ? Boolean(dataCache.current[currentDotNumber])
    : false;

  // Return loading state along with other data
  return {
    data: dataCache.current[currentDotNumber] || null, // Simplified data access
    refetchAll,
    loadingState,
    isDataCached,
    currentDotNumber,
    isLoading: loadingState.fmcsaLoading || loadingState.carrierDbLoading,
  };
};
