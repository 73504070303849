import { supabase } from "../../../lib/supabase";

export const CarrierService = {
  async getCarrierList(activeOnly = true) {
    const { data, error } = await supabase
      .from("carriers")
      .select("*")
      .eq("is_active", activeOnly)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data;
  },

  async getCarrierByDOT(dotNumber) {
    console.log("Triggered getCarrier By DOT", dotNumber);
    const { data, error } = await supabase
      .from("carriers")
      .select("*")
      .eq("dot_number", dotNumber)
      .single();

    console.log("The data from getCarrierByDOT is", data);

    if (error) throw error;
    return data;
  },

  async getCarrierDetails(id) {
    const { data, error } = await supabase
      .from("carriers")
      .select("*")
      .eq("id", id)
      .single();

    if (error) {
      console.error(`Error fetching carrier details for ID ${id}:`, error);
      throw error;
    }
    return data;
  },

  async getAllCarrierDetails() {
    const { data, error } = await supabase.from("carriers").select("*");
    if (error) throw error;
    return data;
  },

  async deleteCarrier(id) {
    const { error } = await supabase.from("carriers").delete().eq("id", id);
    if (error) throw error;
  },

  async toggleCarrierActiveStatus({ id, isActive }) {
    const { data, error } = await supabase
      .from("carriers")
      .update({ is_active: !isActive })
      .eq("id", id)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  async insertCarrier(data) {
    console.log("Inserting carrier with data:", JSON.stringify(data, null, 2));

    // Clean up null or empty string fields
    const cleanData = { ...data };
    Object.keys(cleanData).forEach((key) => {
      if (
        cleanData[key] === "" ||
        cleanData[key] === null ||
        cleanData[key] === undefined
      ) {
        // Convert empty strings to null for database consistency
        cleanData[key] = null;
      }
    });

    console.log(
      "Cleaned data for insertion:",
      JSON.stringify(cleanData, null, 2)
    );

    try {
      // Note: In PostgreSQL, NULL values are not considered equal, so two NULL values
      // should not violate a unique constraint. However, if the column has a unique index
      // with a WHERE clause that converts NULL to a specific value, then conflicts can occur.
      // If we're seeing unique constraint violations with NULL values, we should check:
      // 1. Does the table have a trigger that modifies NULL values before insertion?
      // 2. Is there a partial unique index with a WHERE clause?
      // 3. Is there a custom constraint that treats NULLs specially?

      const { data: newCarrier, error } = await supabase
        .from("carriers")
        .insert(cleanData)
        .select()
        .single();

      if (error) {
        console.error("Supabase error during carrier insertion:", error);
        console.error("Error details:", JSON.stringify(error, null, 2));

        // Log more details for unique constraint violations
        if (error.code === "23505") {
          // PostgreSQL unique violation
          console.error("Unique constraint violation detected:");
          console.error("Constraint:", error.constraint);
          console.error("Detail:", error.detail);

          // Try to extract the field that caused the violation
          const fieldMatch = error.detail?.match(/Key \(([^)]+)\)/);
          if (fieldMatch && fieldMatch[1]) {
            const field = fieldMatch[1].split(",")[0].trim();
            console.error("Field that caused violation:", field);
            console.error("Value in submitted data:", cleanData[field]);
          }
        }

        throw error;
      }

      console.log("Successfully inserted carrier:", newCarrier);
      return newCarrier;
    } catch (error) {
      console.error("Error in CarrierService.insertCarrier:", error);
      console.error("Error message:", error.message);
      console.error("Error code:", error.code);
      if (error.details) {
        console.error("Error details:", error.details);
      }

      // Add any other constraint error handling logic here

      throw error;
    }
  },

  async updateCarrier({ id, updatedFields }) {
    const { data: updatedCarrier, error } = await supabase
      .from("carriers")
      .update(updatedFields)
      .eq("id", id)
      .select()
      .single();

    if (error) throw error;
    return updatedCarrier;
  },

  async searchCarriers(searchTerm) {
    const { data, error } = await supabase
      .from("carriers")
      .select("*")
      .or(`name.ilike.%${searchTerm}%,email.ilike.%${searchTerm}%`)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data;
  },

  async bulkInsertCarriers(carriers) {
    const { data: insertedCarriers, error } = await supabase
      .from("carriers")
      .insert(carriers)
      .select();

    if (error) throw error;
    return insertedCarriers;
  },

  async getCarrierVerifications(carrierId) {
    if (!carrierId) {
      console.warn("[getCarrierVerifications] No carrierId provided.");
      return [];
    }
    console.log(
      `[getCarrierVerifications] Fetching verifications for carrier ID: ${carrierId}`
    );
    const { data, error } = await supabase
      .from("verifications")
      .select("*")
      .eq("carrier_id", carrierId)
      .order("created_at", { ascending: false });

    if (error) {
      console.error(
        `[getCarrierVerifications] Error fetching verifications for carrier ${carrierId}:`,
        error
      );
      throw error;
    }
    console.log(
      `[getCarrierVerifications] Found ${data.length} verifications for carrier ${carrierId}`
    );
    return data;
  },

  /**
   * Extract DOT number from carrier data by checking various possible paths
   * @param {Object} carrierData - Carrier data object
   * @returns {string|null} DOT number or null if not found
   */
  extractDotNumber(carrierData) {
    if (!carrierData) return null;

    // Try all possible paths
    return (
      carrierData.carrierBasics?.content?.[0]?.basic?.id?.dotNumber ||
      carrierData.carrierCargo?.content?.[0]?.id?.dotNumber ||
      carrierData.carrierOperation?.content?.[0]?.id?.dotNumber ||
      carrierData.carrierAuthority?.content?.[0]?.carrierAuthority?.dotNumber ||
      carrierData.dot_number || // Try direct property access too
      null
    );
  },

  /**
   * Update verification status for a carrier
   * @param {string} carrierId - The ID of the carrier
   * @param {Object} data - Verification data to update
   * @returns {Promise<Object>} Updated carrier
   */
  async updateCarrierVerification(carrierId, data) {
    const { data: carrier, error } = await supabase
      .from("carriers")
      .update(data)
      .eq("id", carrierId)
      .select()
      .single();

    if (error) throw error;
    return carrier;
  },

  // --- Fetch Carrier Risk Data ---
  async fetchCarrierRiskData(carrierId) {
    if (!carrierId) {
      console.warn("[fetchCarrierRiskData] No carrierId provided.");
      // Returning null or throwing an error depends on how useQuery handles it (enabled flag is better)
      return null;
    }

    console.log(
      " RISK - Service: Fetching risk assessment for carrier:",
      carrierId
    );

    // --- Session Check ---
    const { data: sessionData, error: sessionError } =
      await supabase.auth.getSession();
    if (sessionError || !sessionData?.session) {
      console.error(
        " RISK ERROR - Service: Authentication required or failed:",
        sessionError?.message || "No session"
      );
      throw new Error("Authentication required or failed.");
    }

    // --- Invoke Supabase Function ---
    try {
      const { data, error } = await supabase.functions.invoke(
        "calculate-carrier-risk",
        { body: { carrierId: carrierId.toString() } }
      );

      if (error) {
        const errorDetails = error.context?.details || error.message;
        console.error(
          " RISK ERROR - Service: Failed to invoke edge function:",
          errorDetails
        );
        throw new Error(`Risk calculation failed: ${errorDetails}`);
      }

      // --- Validate Response ---
      if (
        typeof data?.riskScore !== "number" ||
        !Array.isArray(data?.riskSignals)
      ) {
        console.error(
          " RISK ERROR - Service: Invalid response format from function:",
          data
        );
        throw new Error(
          "Invalid response format from risk calculation service."
        );
      }

      console.log(
        " RISK SUCCESS - Service: Risk score data from function:",
        data
      );

      // --- Return Processed Data ---
      return {
        riskScore: data.riskScore,
        riskSignals:
          data.riskSignals.length > 0
            ? data.riskSignals
            : ["No specific risk signals identified."],
      };
    } catch (invokeError) {
      console.error(
        " RISK ERROR - Service: Error invoking or processing edge function:",
        invokeError
      );
      // Re-throw the error so useQuery can catch it
      throw invokeError;
    }
  },
};
