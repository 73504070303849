import React, { useState } from "react";
import ResultCard from "./ResultCard";

const DisplayPersonaResults = ({ results, verificationResults }) => {
  const [expandedResults, setExpandedResults] = useState({});

  // 1. Filter out non-completed results
  const completedResults = (results || []).filter(
    (r) => r.status === "completed"
  );

  const normalizedResults = {};

  completedResults.forEach((resultItem) => {
    if (!resultItem || !resultItem.result) return;

    const { id, result } = resultItem;

    // 1) Safely parse a string if needed
    let parsedResult;
    if (typeof result === "string") {
      try {
        parsedResult = JSON.parse(result);
      } catch (err) {
        console.error("Error parsing JSON string:", err);
        return;
      }
    } else {
      // If it's not a string, the data might already be an object
      parsedResult = result;
    }

    // 2) Retrieve the possible paths for "report_type" or "type"
    const topLevelReportType = parsedResult?.report_type;
    const nestedType = parsedResult?.data?.data?.data?.type;
    const topLevelType = parsedResult?.type;
    const isBusinessCheck =
      resultItem.verification_method === "business_verification";

    // 3) Handle business verification with business registrations lookup
    if (
      isBusinessCheck &&
      (topLevelReportType === "business_registration" ||
        nestedType === "report/business-registrations-lookup" ||
        topLevelType === "report/business-registrations-lookup")
    ) {
      // Extract registry records from the appropriate location
      const registryRecords =
        parsedResult.registryRecords ||
        parsedResult.data?.data?.data?.attributes?.["registry-records"] ||
        [];

      normalizedResults[id] = {
        type: "report/business-registrations-lookup",
        id: parsedResult.reportId || id,
        attributes: {
          status: parsedResult.status || "ready",
          "created-at": resultItem.created_at,
          "completed-at": resultItem.created_at,
          "has-match": parsedResult.hasMatch,
          "registry-records": registryRecords,
        },
        created_at: resultItem.created_at,
        raw: parsedResult,
        data: { ...resultItem, parsedResult },
      };
    }
    // 4) Handle business verification with transaction
    else if (
      isBusinessCheck &&
      parsedResult?.verification_type === "business_verification_transaction"
    ) {
      normalizedResults[id] = {
        type: "transaction",
        id: parsedResult.transactionId || id,
        attributes: {
          status:
            parsedResult.transaction_status || parsedResult.status || "unknown",
          "created-at": resultItem.created_at,
          fields: {
            business_name: parsedResult.businessName,
            ein: parsedResult.ein,
            ...(parsedResult.transactionData?.fields || {}),
          },
        },
        created_at: resultItem.created_at,
        raw: parsedResult,
        data: { ...resultItem, parsedResult },
      };
    }
    // 5) Handle business verification if present
    else if (
      parsedResult.businessVerification &&
      parsedResult.businessVerification.data &&
      parsedResult.businessVerification.data.attributes
    ) {
      const verificationData = parsedResult.businessVerification.data;
      normalizedResults[id] = {
        type: verificationData.type,
        id: verificationData.id,
        attributes: verificationData.attributes,
        created_at:
          verificationData.attributes["created-at"] || resultItem.created_at,
        raw: verificationData,
        data: parsedResult.businessVerification,
      };
    }
    // 6) Handle business registrations lookup via personaReport
    else if (parsedResult.personaReport && parsedResult.personaReport.data) {
      const reportData = parsedResult.personaReport.data;
      normalizedResults[id] = {
        type: reportData.type,
        id: reportData.id,
        attributes: reportData.attributes,
        created_at:
          reportData.attributes["created-at"] || resultItem.created_at,
        raw: reportData,
        data: parsedResult.personaReport,
      };
    }
    // 7) Handle email and phone risk reports
    else if (
      parsedResult.type &&
      (parsedResult.type === "report/email-address" ||
        parsedResult.type === "report/phone-number")
    ) {
      normalizedResults[id] = {
        type: parsedResult.type,
        id: parsedResult.id,
        attributes: parsedResult.attributes,
        created_at:
          parsedResult.attributes?.["created-at"] || resultItem.created_at,
        raw: parsedResult,
        data: parsedResult,
      };
    }
    // 8) Default case for other business verifications
    else if (isBusinessCheck) {
      normalizedResults[id] = {
        type: "verification/database-business",
        id: parsedResult.reportId || parsedResult.transactionId || id,
        attributes: {
          status: parsedResult.status || "ready",
          "created-at": resultItem.created_at,
          "business-name": parsedResult.businessName || "Unknown Business",
          ein: parsedResult.ein || "Not provided",
          success: parsedResult.success,
          summary: parsedResult.summary || "",
          timestamp: parsedResult.timestamp,
        },
        created_at: resultItem.created_at,
        raw: parsedResult,
        data: { ...resultItem, parsedResult },
      };
    }
  });

  const hasResults = Object.keys(normalizedResults).length > 0;
  if (!hasResults) {
    return (
      <div className="p-4 bg-slate-50 rounded-lg text-slate-500 border border-slate-200">
        No verification data available.
      </div>
    );
  }

  // Sort results by creation date, descending
  const sortedResultEntries = Object.entries(normalizedResults).sort((a, b) => {
    const dateA = new Date(a[1].created_at || 0).getTime();
    const dateB = new Date(b[1].created_at || 0).getTime();
    return dateB - dateA;
  });
  const [latestKey] = sortedResultEntries[0] || [];

  // Extract business owner verification data if available
  const hasBusinessOwnerVerification =
    verificationResults && Object.keys(verificationResults).length > 0;
  const businessOwners = [];
  if (hasBusinessOwnerVerification) {
    Object.values(verificationResults).forEach((result) => {
      if (result.inquiryData) {
        businessOwners.push({
          name: result.inquiryData.name || "Unknown",
          role: "owner",
          email: result.inquiryData.email || "Not provided",
          phone: result.inquiryData.phone || "Not provided",
          status: result.passed ? "Verified" : "Not Verified",
        });
      } else if (result.matchDetails) {
        businessOwners.push({
          name:
            result.matchDetails.actualName ||
            result.matchDetails.expectedName ||
            "Unknown",
          role: "owner",
          email: "Not provided",
          phone: "Not provided",
          status: result.passed ? "Verified" : "Not Verified",
        });
      }
    });
  }

  // Toggle expanding/collapsing a result
  const toggleDetails = (resultId) => {
    setExpandedResults((prev) => ({
      ...prev,
      [resultId]: !prev[resultId],
    }));
  };

  return (
    <div className="space-y-4">
      {sortedResultEntries.map(([key, normalized]) => (
        <ResultCard
          key={key}
          result={normalized}
          isExpanded={expandedResults[key]}
          onToggle={() => toggleDetails(key)}
          isLatest={key === latestKey}
          showRawJson={false}
          verificationResult={null}
        />
      ))}
    </div>
  );
};

export default DisplayPersonaResults;
