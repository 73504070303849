// @ts-nocheck

// Create a new file called Toast.js
const Toast = {
  debug: false,

  init() {
    // Create toast container if it doesn't exist
    if (!document.getElementById("toast-container")) {
      if (this.debug) console.log("Creating toast container");
      const container = document.createElement("div");
      container.id = "toast-container";
      container.className = "fixed top-4 right-4 z-[9999] flex flex-col gap-2";
      document.body.appendChild(container);
    }
  },

  show({ message, type = "success", duration = 5000 }) {
    this.init();
    if (this.debug) console.log(`Showing toast: ${message} (${type})`);
    const container = document.getElementById("toast-container");

    // Create toast element
    const toast = document.createElement("div");
    toast.className = `
        transform translate-y-[-10px] opacity-0 transition-all duration-300 ease-out
        flex items-center p-4 rounded-lg shadow-xl min-w-[280px] max-w-[400px]
        ${type === "success" ? "bg-green-600 text-white" : ""}
        ${type === "error" ? "bg-red-600 text-white" : ""}
        ${type === "info" ? "bg-blue-600 text-white" : ""}
        ${type === "warning" ? "bg-yellow-600 text-white" : ""}
      `;

    // Add message
    toast.textContent = message;

    // Add to container
    container.appendChild(toast);

    // Trigger animation
    setTimeout(() => {
      if (this.debug) console.log("Animating toast in");
      toast.classList.remove("translate-y-[-10px]", "opacity-0");
      toast.classList.add("translate-y-0", "opacity-100");
    }, 50);

    // Remove after duration
    setTimeout(() => {
      if (this.debug) console.log("Animating toast out");
      toast.classList.add("translate-y-[-10px]", "opacity-0");
      setTimeout(() => {
        if (container.contains(toast)) {
          container.removeChild(toast);
        }
      }, 300);
    }, duration);

    // Return the toast element in case the caller wants to manipulate it
    return toast;
  },

  success(message, duration = 5000) {
    return this.show({ message, type: "success", duration });
  },

  error(message, duration = 5000) {
    return this.show({ message, type: "error", duration });
  },

  info(message, duration = 5000) {
    return this.show({ message, type: "info", duration });
  },

  warning(message, duration = 5000) {
    return this.show({ message, type: "warning", duration });
  },

  // Enable debug mode for troubleshooting
  enableDebug() {
    this.debug = true;
    console.log("Toast debug mode enabled");
  },
};

export default Toast;
