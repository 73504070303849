// @ts-nocheck
import React, { useState } from "react";
import InputField from "../../Components/InputField";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const RiskLookup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    phone_number: "",
  });
  const [pageError, setPageError] = useState("");
  const [message, setMessage] = useState("");
  const [carrierData, setCarrierData] = useState(null);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    console.log("carrierData updated:", carrierData);
  }, [carrierData]);

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg border border-slate-300 shadow-md">
      <div className="flex flex-col md:flex-row gap-6 pb-2">
        <div className="md:w-5/6 flex flex-col gap-6">
          <div className="space-y-2">
            <h2 className="text-slate-900 text-xl font-semibold">
              Risk Signals
            </h2>
            <p className="text-slate-500 text-sm">
              Please use the form below to search for risk signals by email,
              phone number or website.
            </p>
          </div>
          <form className="space-y-6">
            <div className="grid grid-cols-1  gap-x-4 gap-y-2">
              <InputField
                label="Email"
                placeholder="carrier@example.com"
                name="email"
              />
              <InputField
                label="Website"
                placeholder="https://example.com"
                name="website"
              />
              <InputField
                label="Phone Number"
                placeholder="123-456-7890"
                name="phone_number"
              />
            </div>

            <div className="space-y-4">
              <button
                type="submit"
                className="w-full px-4 py-2 bg-slate-900 text-white text-sm font-medium rounded-md hover:bg-slate-800 transition-colors"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RiskLookup;
