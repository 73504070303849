// @ts-nocheck
import React, { useState } from "react";
import { useShipmentMutations } from "../../hooks/shipments";
import { Card, CardContent, CardHeader, CardTitle } from "../Components/Card";
import { Button } from "../Components/Button";
import { Input } from "../Components/Input";
import { Progress } from "../Components/Progress";
import { Alert, AlertTitle, AlertDescription } from "../Components/Alert";
import { SimpleDateTimePicker } from "@/components/ui/date-time-picker";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase";
import { useCargoOwnerById } from "../../hooks/cargoOwner";
import Toast from "../Components/Toast";

// Simplified steps - reduced from 5 to 3
const STEPS = [
  { id: 1, title: "Schedule & Location" },
  { id: 2, title: "Cargo Details" },
  { id: 3, title: "Review & Confirm" },
];

const ScheduleDelivery = () => {
  const { id: cargoOwnerId } = useParams();
  const { data: cargoOwner } = useCargoOwnerById(cargoOwnerId);
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const { createShipment } = useShipmentMutations();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  // Simplified form state
  const [formData, setFormData] = useState({
    cargo_owner_id: parseInt(cargoOwnerId) || null,
    status: "Unverified",
    pickup_time: null,
    origin_address: "",
    destination_address: "",
    company_id: null,
    carrier_number: "",
    container_number: "",
    pallet_number: "",
    tags: "",
    notes: "",
  });

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleNext = () => {
    // Simple validation by step
    let isValid = true;
    let errorMessage = "";

    if (currentStep === 1) {
      if (!formData.pickup_time) {
        isValid = false;
        errorMessage = "Please select a pickup date and time";
      } else if (!formData.origin_address || !formData.destination_address) {
        isValid = false;
        errorMessage = "Origin and destination addresses are required";
      }
    }

    if (isValid) {
      setError("");
      setCurrentStep((prev) => Math.min(prev + 1, STEPS.length));
    } else {
      setError(errorMessage);
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    try {
      // Ensure cargo_owner_id is a number
      const submissionData = {
        ...formData,
        cargo_owner_id: parseInt(formData.cargo_owner_id) || null,
      };

      const result = await createShipment.mutateAsync(submissionData);

      Toast.success("Delivery scheduled successfully!");

      // Redirect to confirmation page
      setTimeout(() => {
        navigate(`/dashboard/shipment/${result.id}`);
      }, 1500);
    } catch (error) {
      console.error("Error scheduling delivery:", error);
      setError(`Failed to schedule delivery: ${error.message}`);
      Toast.error(`Failed to schedule delivery: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // 0 is Sunday, 6 is Saturday
  };

  const isFutureDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date >= today;
  };

  // Filter for business hours (9 AM - 5 PM)
  const isBusinessHours = (time) => {
    const hours = time.getHours();
    return hours >= 9 && hours <= 17;
  };

  // Render progress indicator
  const renderProgress = () => (
    <div className="mb-6">
      <div className="flex justify-between mb-2">
        {STEPS.map((step) => (
          <div
            key={step.id}
            className={`text-sm font-medium ${
              currentStep >= step.id ? "text-primary" : "text-muted-foreground"
            }`}
          >
            {step.title}
          </div>
        ))}
      </div>
      <Progress value={(currentStep / STEPS.length) * 100} className="h-2" />
    </div>
  );

  // Render the current step content
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-6">
            <div className="space-y-4">
              <h3 className="text-lg font-medium">Pickup Schedule</h3>
              <SimpleDateTimePicker
                value={formData.pickup_time}
                onChange={(date) => handleChange("pickup_time", date)}
                filterDate={(date) => isWeekday(date) && isFutureDate(date)}
                filterTime={isBusinessHours}
                timeIntervals={30}
                placeholderText="Select pickup date and time"
                className="w-full"
              />
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-medium">Location Details</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Origin Address
                  </label>
                  <Input
                    value={formData.origin_address}
                    onChange={(e) =>
                      handleChange("origin_address", e.target.value)
                    }
                    placeholder="Enter pickup location"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Destination Address
                  </label>
                  <Input
                    value={formData.destination_address}
                    onChange={(e) =>
                      handleChange("destination_address", e.target.value)
                    }
                    placeholder="Enter delivery location"
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <h3 className="text-lg font-medium">Cargo Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">
                  Carrier Number
                </label>
                <Input
                  value={formData.carrier_number}
                  onChange={(e) =>
                    handleChange("carrier_number", e.target.value)
                  }
                  placeholder="Enter carrier number"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Container Number
                </label>
                <Input
                  value={formData.container_number}
                  onChange={(e) =>
                    handleChange("container_number", e.target.value)
                  }
                  placeholder="Enter container number"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Pallet Number
                </label>
                <Input
                  value={formData.pallet_number}
                  onChange={(e) =>
                    handleChange("pallet_number", e.target.value)
                  }
                  placeholder="Enter pallet number"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Tags</label>
                <Input
                  value={formData.tags}
                  onChange={(e) => handleChange("tags", e.target.value)}
                  placeholder="Enter tags"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Notes</label>
              <Input
                value={formData.notes}
                onChange={(e) => handleChange("notes", e.target.value)}
                placeholder="Add any special requirements or notes"
              />
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            <h3 className="text-lg font-medium">
              Review Your Delivery Schedule
            </h3>

            <Card className="bg-white">
              <CardContent className="p-4">
                <h4 className="font-medium mb-2">Schedule</h4>
                <p className="text-gray-700">
                  {formData.pickup_time
                    ? new Intl.DateTimeFormat("en-US", {
                        dateStyle: "full",
                        timeStyle: "short",
                      }).format(formData.pickup_time)
                    : "Not specified"}
                </p>
              </CardContent>
            </Card>

            <Card className="bg-white">
              <CardContent className="p-4">
                <h4 className="font-medium mb-2">Location</h4>
                <div className="space-y-2">
                  <div>
                    <span className="text-sm text-gray-500">Origin:</span>
                    <p className="text-gray-700">
                      {formData.origin_address || "Not specified"}
                    </p>
                  </div>
                  <div>
                    <span className="text-sm text-gray-500">Destination:</span>
                    <p className="text-gray-700">
                      {formData.destination_address || "Not specified"}
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card className="bg-white">
              <CardContent className="p-4">
                <h4 className="font-medium mb-2">Cargo Details</h4>
                <div className="grid grid-cols-2 gap-2 text-sm">
                  <div>
                    <span className="text-gray-500">Carrier Number:</span>
                    <p className="text-gray-700">
                      {formData.carrier_number || "Not specified"}
                    </p>
                  </div>
                  <div>
                    <span className="text-gray-500">Container Number:</span>
                    <p className="text-gray-700">
                      {formData.container_number || "Not specified"}
                    </p>
                  </div>
                  <div>
                    <span className="text-gray-500">Pallet Number:</span>
                    <p className="text-gray-700">
                      {formData.pallet_number || "Not specified"}
                    </p>
                  </div>
                  <div>
                    <span className="text-gray-500">Tags:</span>
                    <p className="text-gray-700">{formData.tags || "None"}</p>
                  </div>
                </div>
                {formData.notes && (
                  <div className="mt-2">
                    <span className="text-gray-500">Notes:</span>
                    <p className="text-gray-700">{formData.notes}</p>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle>Schedule Delivery</CardTitle>
        </CardHeader>
        <CardContent>
          {/* Error alert */}
          {error && (
            <Alert className="mb-6 bg-red-50 border-red-200 text-red-800">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {/* Progress indicator */}
          {renderProgress()}

          {/* Step content */}
          <form onSubmit={handleSubmit}>
            {renderStepContent()}

            {/* Navigation buttons */}
            <div className="flex justify-between mt-8">
              {currentStep > 1 ? (
                <Button
                  type="button"
                  onClick={handlePrevious}
                  disabled={isSubmitting}
                >
                  Back
                </Button>
              ) : (
                <div></div> // Empty div for spacing
              )}

              {currentStep < STEPS.length ? (
                <Button
                  type="button"
                  onClick={handleNext}
                  disabled={isSubmitting}
                >
                  Continue
                </Button>
              ) : (
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Scheduling..." : "Schedule Delivery"}
                </Button>
              )}
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ScheduleDelivery;
