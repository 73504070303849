import ErrorHandler from "../providers/ErrorHandler";
import { VerificationRepository } from "../repositories/VerificationRepository";

// Base class that defines the common interface for all verification strategies
export class VerificationStrategy {
  constructor(dependencies = {}) {
    // Store dependencies in a protected property instead of directly setting properties
    // This avoids conflicts with getters defined in subclasses
    this._dependencies = dependencies;
    this._providers = {};
    this._repositories = {};
  }

  // Standard verification statuses
  static STATUSES = {
    PENDING: "pending",
    VERIFIED: "verified",
    REJECTED: "rejected",
    FAILED: "failed",
    EXPIRED: "expired",
  };

  get verificationRepository() {
    if (!this._repositories.verification) {
      this._repositories.verification =
        this._dependencies.verificationRepository ||
        new VerificationRepository();
    }
    return this._repositories.verification;
  }

  // Interface methods that all strategies should implement
  async getAllVerifications() {
    throw new Error("Method getAllVerifications() must be implemented");
  }

  async getVerificationById(id) {
    throw new Error("Method getVerificationById() must be implemented");
  }

  async createVerification(data) {
    throw new Error("Method createVerification() must be implemented");
  }

  async updateVerification(id, data) {
    throw new Error("Method updateVerification() must be implemented");
  }

  async deleteVerification(id) {
    throw new Error("Method deleteVerification() must be implemented");
  }

  async getVerificationByInquiryId(inquiryId) {
    throw new Error("Method getVerificationByInquiryId() must be implemented");
  }

  async updateVerificationByInquiryId(inquiryId, data) {
    throw new Error("Method updateVerificationByInquiryId() must be implemented");
  }

  async checkStatus(verificationId) {
    throw new Error("Method not implemented");
  }

  async processDocument(verificationId, document, documentType) {
    throw new Error("Method not implemented");
  }

  // Common utility methods that can be shared across strategies
  async _updateVerificationStatus(verificationId, status) {
    const verificationRepo =
      this._dependencies.verificationRepository ||
      (this.verificationRepository ? this.verificationRepository : null);

    if (!verificationRepo) {
      throw new Error("Verification repository is required to update status");
    }

    return verificationRepo.update(verificationId, { status });
  }

  // Common document handling method
  async _attachDocumentToVerification(verification, document, documentType) {
    try {
      const docProvider =
        this._dependencies.documentProvider ||
        (this.documentProvider ? this.documentProvider : null);

      const verificationRepo =
        this._dependencies.verificationRepository ||
        (this.verificationRepository ? this.verificationRepository : null);

      if (!docProvider) {
        throw new Error("Document provider is required to upload documents");
      }

      if (!verificationRepo) {
        throw new Error(
          "Verification repository is required to update verification"
        );
      }

      const uploadedDocument = await docProvider.uploadDocument({
        file: document,
        verificationId: verification.id,
        fileType: documentType,
        metadata: {
          entity_type: verification.entity_type,
          document_type: documentType,
        },
      });

      // Update verification with document reference
      await verificationRepo.update(verification.id, {
        documents: [
          ...(verification.documents || []),
          { id: uploadedDocument.id, type: documentType },
        ],
      });

      return uploadedDocument;
    } catch (error) {
      throw new Error(`Document processing failed: ${error.message}`);
    }
  }

  /**
   * Handle verification errors consistently
   * @param {Error} error - The error that occurred
   * @param {string} message - A user-friendly error message
   * @param {Object} metadata - Additional metadata about the error context
   * @returns {Object} Standardized error response
   */
  _handleVerificationError(error, message, metadata = {}) {
    // Use the ErrorHandler but ensure we return a standardized format
    const errorResponse = ErrorHandler.handleError(error, message);

    // Preserve any metadata passed in
    return {
      ...errorResponse,
      metadata: {
        ...metadata,
        ...(errorResponse.metadata || {}),
      },
    };
  }

  /**
   * Validate that required dependencies are available
   * @param {string[]} requiredDependencies - List of dependency keys that must be present
   * @throws {Error} If any required dependency is missing
   */
  _validateDependencies(requiredDependencies) {
    const missing = requiredDependencies.filter(
      (dep) => !this._dependencies || !this._dependencies[dep]
    );

    if (missing.length > 0) {
      throw new Error(`Missing required dependencies: ${missing.join(", ")}`);
    }
  }

  // Helper to check if a date is valid and not expired
  _isDateValidAndNotExpired(dateString) {
    if (!dateString) return false;

    try {
      const date = new Date(dateString);
      const now = new Date();
      return date instanceof Date && !isNaN(date) && date >= now;
    } catch (error) {
      return false;
    }
  }
}
