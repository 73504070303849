import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import driverInformation from "../../../public/static/screenshots/DriverVerification.png";
import cargoPhoto from "../../../public/static/screenshots/ApplicationAndMobile.png";
import driverVerification from "../../../public/static/screenshots/DriverVerificationPage.png";
import shipmentInformation from "../../../public/static/screenshots/VerifyCompliance.png";

export default function VettingFeatures() {
  // Create refs for each grid item
  const topLeftRef = useRef(null);
  const topRightRef = useRef(null);
  const bottomLeftRef = useRef(null);
  const bottomRightRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  // Check if device is mobile on component mount and window resize
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Initial check
    checkIsMobile();
    
    // Add listener for window resize
    window.addEventListener('resize', checkIsMobile);
    
    // Clean up
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  // Create spring animations with slower movement
  const springConfig = {
    mass: 2, // Increased for more weight
    tension: 180, // Reduced for slower movement
    friction: 60, // Kept same for smoothness
  };

  const [topLeftSpring, topLeftApi] = useSpring(() => ({
    from: { x: 0, opacity: isMobile ? 1 : 0 },
    config: springConfig,
  }));

  const [topRightSpring, topRightApi] = useSpring(() => ({
    from: { x: 0, opacity: isMobile ? 1 : 0 },
    config: springConfig,
  }));

  const [bottomLeftSpring, bottomLeftApi] = useSpring(() => ({
    from: { x: 0, opacity: isMobile ? 1 : 0 },
    config: springConfig,
  }));

  const [bottomRightSpring, bottomRightApi] = useSpring(() => ({
    from: { x: 0, opacity: isMobile ? 1 : 0 },
    config: springConfig,
  }));

  useEffect(() => {
    // Skip animation setup for mobile devices
    if (isMobile) return;
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add sequential delays to each animation
            if (entry.target === topLeftRef.current) {
              topLeftApi.start({ x: 0, opacity: 1, delay: 0 });
            } else if (entry.target === topRightRef.current) {
              topRightApi.start({ x: 0, opacity: 1, delay: 400 });
            } else if (entry.target === bottomLeftRef.current) {
              bottomLeftApi.start({ x: 0, opacity: 1, delay: 800 });
            } else if (entry.target === bottomRightRef.current) {
              bottomRightApi.start({ x: 0, opacity: 1, delay: 1200 });
            }
          }
        });
      },
      {
        threshold: 0.3, // Increased threshold so animation starts a bit later
        rootMargin: "-50px", // Adds a small buffer before triggering
      }
    );

    // Observe all grid items
    [topLeftRef, topRightRef, bottomLeftRef, bottomRightRef].forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => observer.disconnect();
  }, [isMobile]);

  // Update spring configurations when isMobile changes
  useEffect(() => {
    if (isMobile) {
      // Reset all animations for mobile
      topLeftApi.set({ x: 0, opacity: 1 });
      topRightApi.set({ x: 0, opacity: 1 });
      bottomLeftApi.set({ x: 0, opacity: 1 });
      bottomRightApi.set({ x: 0, opacity: 1 });
    } else {
      // Reset to initial state for desktop if needed
      topLeftApi.set({ x: -200, opacity: 0 });
      topRightApi.set({ x: 200, opacity: 0 });
      bottomLeftApi.set({ x: -200, opacity: 0 });
      bottomRightApi.set({ x: 200, opacity: 0 });
    }
  }, [isMobile]);

  return (
    <div className="bg-gray-900 py-16 sm:py-24 relative overflow-hidden">
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 relative z-10">
        <h2 className="text-base font-semibold text-[#119DA5]">
          Catch Double Brokering and Identity Theft
        </h2>
        <p className="mt-2 text-3xl sm:text-4xl md:text-5xl font-semibold tracking-tight text-white">
          Automatically Vet Carriers and Catch Identity Theft.
        </p>
        <div className="mt-8 sm:mt-12 grid grid-cols-1 gap-6 sm:gap-8 lg:grid-cols-6">
          {/* Top Left */}
          <animated.div
            ref={topLeftRef}
            style={!isMobile ? topLeftSpring : {}}
            className="flex lg:col-span-4 rounded-lg overflow-hidden"
          >
            <div className="w-full bg-gray-800 ring-1 ring-white/15 rounded-lg lg:rounded-tl-[2rem]">
              <div className="aspect-[16/9] lg:aspect-[16/8] w-full overflow-hidden">
                <img
                  alt="Driver verification interface for carrier validation"
                  src={driverInformation}
                  className="w-full h-full object-cover object-left"
                />
              </div>
              <div className="p-6 sm:p-8">
                <h3 className="text-sm uppercase tracking-wider font-semibold text-gray-400">
                  Catch Fraudulent Carriers
                </h3>
                <p className="mt-2 text-xl sm:text-2xl font-medium tracking-tight text-white">
                  Verify Carrier Authority & Insurance
                </p>
                <p className="mt-3 text-base sm:text-lg text-gray-300">
                  Automatically verify DOT authority, insurance coverage, and
                  safety ratings in real-time before every load assignment
                </p>
              </div>
            </div>
          </animated.div>

          {/* Top Right */}
          <animated.div
            ref={topRightRef}
            style={!isMobile ? topRightSpring : {}}
            className="flex lg:col-span-2 rounded-lg overflow-hidden"
          >
            <div className="w-full bg-gray-800 ring-1 ring-white/15 rounded-lg lg:rounded-tr-[2rem]">
              <div className="aspect-[16/9] lg:aspect-[16/8] w-full overflow-hidden">
                <img
                  alt="Mobile application for fraud detection"
                  src={cargoPhoto}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-6 sm:p-8">
                <h3 className="text-sm uppercase tracking-wider font-semibold text-gray-400">
                  Fraud Signal Detection
                </h3>
                <p className="mt-2 text-xl sm:text-2xl font-medium tracking-tight text-white">
                  Detect Fraudulent Applications
                </p>
                <p className="mt-3 text-base sm:text-lg text-gray-300">
                  Capture signals of fraudulent applications, including
                  suspicious documents, devices, phone numbers, and more.
                </p>
              </div>
            </div>
          </animated.div>

          {/* Bottom Left */}
          <animated.div
            ref={bottomLeftRef}
            style={!isMobile ? bottomLeftSpring : {}}
            className="flex lg:col-span-2 rounded-lg overflow-hidden"
          >
            <div className="w-full bg-gray-800 ring-1 ring-white/15 rounded-lg lg:rounded-bl-[2rem]">
              <div className="aspect-[16/9] lg:aspect-[16/8] w-full overflow-hidden">
                <img
                  alt="Business verification interface for carrier vetting"
                  src={driverVerification}
                  className="w-full h-full object-cover object-[25%_2%]"
                />
              </div>
              <div className="p-6 sm:p-8">
                <h3 className="text-sm uppercase tracking-wider font-semibold text-gray-400">
                  Verify the Business
                </h3>
                <p className="mt-2 text-xl sm:text-2xl font-medium tracking-tight text-white">
                  Verify who is handling your cargo
                </p>
                <p className="mt-3 text-base sm:text-lg text-gray-300">
                  Use information from trusted sources to verify who is behind
                  the application.
                </p>
              </div>
            </div>
          </animated.div>

          {/* Bottom Right */}
          <animated.div
            ref={bottomRightRef}
            style={!isMobile ? bottomRightSpring : {}}
            className="flex lg:col-span-4 rounded-lg overflow-hidden"
          >
            <div className="w-full bg-gray-800 ring-1 ring-white/15 rounded-lg lg:rounded-br-[2rem]">
              <div className="flex flex-col">
                {/* Top section with compliance metrics table */}
                <div className="w-full bg-white rounded-t-lg p-4 overflow-hidden">
                  <img
                    alt="Compliance verification dashboard for carrier safety metrics"
                    src={shipmentInformation}
                    className="w-full object-contain"
                  />
                </div>
                
                {/* Bottom section with text */}
                <div className="p-6 sm:p-8 bg-gray-800">
                  <h3 className="text-sm uppercase tracking-wider font-semibold text-gray-400">
                    ENSURE COMPLIANCE
                  </h3>
                  <p className="mt-2 text-xl sm:text-2xl font-medium tracking-tight text-white">
                    Verify Compliance & Safety
                  </p>
                  <p className="mt-3 text-base sm:text-lg text-gray-300">
                    Verify compliance and safety metrics with all publicly
                    available relevant regulations and standards.
                  </p>
                </div>
              </div>
            </div>
          </animated.div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-0 -z-0 transform-gpu overflow-hidden blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-0 -z-0 transform-gpu overflow-hidden blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[-10rem] top-[calc(100%-40rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-30 sm:left-[-30rem] sm:w-[72.1875rem]"
        />
      </div>
    </div>
  );
}
