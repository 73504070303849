// @ts-check
import { supabase } from "../../../lib/supabase";

/**
 * Service for handling user-related operations
 */
const UserService = {
  /**
   * Create a new user
   * @param {Object} userData - The user data to create
   * @returns {Promise<Object>} The created user data
   */
  createUser: async (userData) => {
    const { data, error } = await supabase
      .from("users")
      .insert(userData)
      .select()
      .single();
    if (error) throw error;
    return data;
  },

  /**
   * Get all users
   * @returns {Promise<Array>} Array of all users
   */
  getAllUsers: async () => {
    const { data, error } = await supabase
      .from("users")
      .select("*")
      .order("created_at", { ascending: false });
    if (error) throw error;
    return data;
  },

  /**
   * Get user by ID
   * @param {string} userId - The user ID to fetch
   * @returns {Promise<Object>} The user data
   */
  getUserById: async (userId) => {
    const { data, error } = await supabase
      .from("users")
      .select("*")
      .eq("id", userId)
      .single();
    if (error) throw error;
    return data;
  },

  /**
   * Update user data
   * @param {string} userId - The ID of the user to update
   * @param {Object} userData - The updated user data
   * @returns {Promise<Object>} The updated user data
   */
  updateUser: async (userId, userData) => {
    const { data, error } = await supabase
      .from("users")
      .update(userData)
      .eq("id", userId)
      .select()
      .single();
    if (error) throw error;
    return data;
  },

  /**
   * Delete a user
   * @param {string} userId - The ID of the user to delete
   * @returns {Promise<void>}
   */
  deleteUser: async (userId) => {
    const { error } = await supabase.from("users").delete().eq("id", userId);
    if (error) throw error;
  },

  /**
   * Get the current user session
   * @returns {Promise<Object>} The current session
   */
  getSession: async () => {
    const { data, error } = await supabase.auth.getSession();
    if (error) throw error;
    return data.session;
  },

  /**
   * Subscribe to auth state changes
   * @param {Function} callback - Function to call when auth state changes
   * @returns {Object} Subscription that can be unsubscribed
   */
  onAuthStateChange: (callback) => {
    return supabase.auth.onAuthStateChange((_event, session) => {
      callback(session);
    });
  },

  /**
   * Get external user details for the specified user ID
   * @param {string} userId - The ID of the user to fetch external details for
   * @returns {Promise<Object>} The external user details including cargo_owner_id
   */
  getExternalUserDetails: async (userId) => {
    const { data, error } = await supabase
      .from("external_users")
      .select("*")
      .eq("user_id", userId);

    if (error) throw error;
    return data && data.length > 0 ? data[0] : null;
  },
};

export default UserService;
