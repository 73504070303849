import React from "react";
// Consider importing components/styles from RiskReportsSection if reusable
// import { BusinessRegistrationCard } from '@/components/RiskReports/RiskReportsSection'; // Note: This is the whole card

/**
 * BusinessRegistrationDetails - Renders details specific to business registration checks.
 * TODO: Implement business registration snapshot view, potentially reusing logic/UI
 * from src/components/RiskReports/RiskReportsSection.jsx (BusinessRegistrationCard)
 *
 * @param {Object} props - Component props
 * @param {Object} props.verification - The verification data object (contains registration data)
 */
export default function BusinessRegistrationDetails({ verification }) {
  // Extract relevant data, similar to BusinessRegistrationCard
  const reportData = verification?.results || {};

  return (
    <div className="p-4 bg-lime-50 space-y-2 text-sm border border-lime-200 rounded">
      <h4 className="font-semibold text-lime-700">
        Business Registration Details
      </h4>
      <p className="text-slate-500">Verification ID: {verification.id}</p>
      <p className="text-slate-500">State: {verification.state || "N/A"}</p>
      <p className="text-orange-600 italic">
        -- Business registration snapshot placeholder --
      </p>
      {/* Build specific view based on reportData here */}
      <pre className="text-xs bg-slate-100 p-2 rounded overflow-auto max-h-60">
        {JSON.stringify(reportData, null, 2)}
      </pre>
    </div>
  );
}
