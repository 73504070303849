/**
 * PersonaRepositoryService.js
 *
 * This service handles repository operations for Persona verifications
 */

import { VerificationRepository } from "../../../repositories/VerificationRepository";
import { supabase } from "../../../../lib/supabase";

// UUID generator function to replace crypto dependency
function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

class PersonaRepositoryService {
  constructor(dependencies = {}) {
    this.verificationRepository =
      dependencies.verificationRepository ||
      new VerificationRepository(supabase);
  }

  /**
   * Saves verification result to the database
   *
   * @param {Object} params - Parameters for saving the verification result
   * @param {string} params.resourceId - ID of the resource being verified
   * @param {string} params.method - Verification method used
   * @param {Object} params.result - Result of the verification
   * @param {string} params.resourceType - Type of resource being verified
   * @param {string} params.entityId - ID of the entity being verified
   * @param {string} params.entityType - Type of entity being verified
   * @returns {Promise<Object>} Created verification record
   */
  async saveVerificationResult({
    resourceId,
    method,
    result,
    resourceType,
    entityId,
    entityType,
  }) {
    try {
      console.log("🔍 [PersonaRepositoryService] Saving verification result:", {
        resourceId,
        method,
        entityType,
        entityId,
      });

      // Validate required parameters
      if (!resourceId) {
        throw new Error("resourceId is required");
      }

      if (!method) {
        throw new Error("Verification method is required");
      }

      if (!entityType) {
        throw new Error("Entity type is required");
      }

      if (!entityId) {
        throw new Error("Entity ID is required");
      }

      // Prepare verification data
      const verificationData = {
        type: resourceType || method,
        state: result?.success ? "persona_complete" : "error",
        persona_verification_complete: result?.success || false,
        reference_id: resourceId,
        results: result || {},
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        metadata: {
          resource_id: resourceId,
          method: method,
          resource_type: resourceType,
        },
      };

      // Add the appropriate ID field based on entity type
      switch (entityType) {
        case "carrier":
          verificationData.carrier_id = entityId;
          break;
        case "driver":
          verificationData.driver_id = entityId;
          break;
        case "shipment":
          verificationData.shipment_id = entityId;
          break;
        default:
          console.warn(
            "⚠️ [PersonaRepositoryService] Unknown entity type:",
            entityType
          );
          // Use a generic field for unknown entity types
          verificationData.metadata.entity_id = entityId;
          verificationData.metadata.entity_type = entityType;
      }

      // Save to verifications table
      const savedRecord = await this.verificationRepository.create(
        verificationData
      );

      console.log(
        "✅ [PersonaRepositoryService] Saved verification result:",
        savedRecord.id
      );

      return savedRecord;
    } catch (error) {
      console.error(
        "❌ [PersonaRepositoryService] Error saving verification result:",
        error
      );
      throw error;
    }
  }

  /**
   * Saves data to the verifications table
   *
   * @param {Object} params - Parameters for the verification record
   * @returns {Promise<Object>} Created verification record
   */
  async saveToVerificationsTable(params) {
    try {
      console.log(
        "🔍 [PersonaRepositoryService] Saving to verifications table:",
        params
      );

      // Ensure we have the minimum required data
      if (!params) {
        throw new Error("Verification parameters are required");
      }

      // Set default values for required fields if not provided
      const verificationData = {
        type: params.type || "persona_verification",
        state: params.state || "pending",
        persona_verification_complete:
          params.persona_verification_complete || false,
        reference_id: params.reference_id || generateUUID(),
        created_at: params.created_at || new Date().toISOString(),
        updated_at: params.updated_at || new Date().toISOString(),
        ...params,
      };

      // Save to verifications table
      const savedRecord = await this.verificationRepository.create(
        verificationData
      );

      console.log(
        "✅ [PersonaRepositoryService] Saved to verifications table:",
        savedRecord.id
      );

      return savedRecord;
    } catch (error) {
      console.error(
        "❌ [PersonaRepositoryService] Error saving to verifications table:",
        error
      );
      throw error;
    }
  }
}

// Export as singleton instance
export default new PersonaRepositoryService();
