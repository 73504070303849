// hooks/useCarrierVetting.js
//@ts-nocheck
import { useState, useRef, useEffect } from "react";
import { CarrierVettingService } from "../../api/services/carrier/CarrierVettingService";

/**
 * @typedef {Object} VettingState
 * @property {boolean} isLoading - Whether any vetting process is running
 * @property {string} message - Success/info message
 * @property {string} error - Error message if any
 * @property {Object} carrierData - Carrier data from DOT lookup
 * @property {Object} personaResults - Results from persona verification
 */

/**
 * Hook to manage the carrier vetting process
 * @returns {Object} Vetting methods and state
 */
export const useCarrierVetting = () => {
  const [state, setState] = useState({
    isLoading: false,
    message: "",
    error: "",
    carrierData: null,
    personaResults: null,
    loadingSteps: {
      submitData: false,
      fmcsaVerification: false,
      businessVerification: false,
      fraudChecks: false,
    },
    currentOperation: "",
  });

  const isSubmitting = useRef(false);

  useEffect(() => {
    console.log("[useCarrierVetting] Hook initialized");
    return () => console.log("[useCarrierVetting] Hook cleanup");
  }, []);

  /**
   * Main vetting process
   * @param {Object} formData The form data to process
   */
  const vetCarrier = async (formData) => {
    console.log("[useCarrierVetting] vetCarrier started", {
      formData,
      isSubmitting,
    });

    // Initialize loading state
    setState((prev) => ({
      ...prev,
      isLoading: true,
      loadingSteps: {
        submitData: true,
        fmcsaVerification: false,
        businessVerification: false,
        fraudChecks: false,
      },
    }));

    try {
      // Use the CarrierVettingService to process the vetting
      const result = await CarrierVettingService.vetCarrier(formData, {
        // Callback to update loading status
        onLoadingStatusChange: (loadingStatus) => {
          // Map the service loading status to our loading steps
          if (loadingStatus.currentOperation === "Fetching FMCSA data") {
            setState((prev) => ({
              ...prev,
              loadingSteps: {
                ...prev.loadingSteps,
                fmcsaVerification: true,
              },
            }));
          } else if (
            loadingStatus.currentOperation === "Processing carrier data" ||
            loadingStatus.currentOperation === "Creating new carrier record" ||
            loadingStatus.currentOperation === "Creating carrier from cached data" ||
            loadingStatus.currentOperation === "Creating FMCSA verification record" ||
            loadingStatus.currentOperation === "Preparing fraud check data"
          ) {
            setState((prev) => ({
              ...prev,
              loadingSteps: {
                ...prev.loadingSteps,
                businessVerification: true,
              },
            }));
          } else if (
            loadingStatus.currentOperation === "Running fraud checks" ||
            loadingStatus.currentOperation === "Saving verification records" ||
            loadingStatus.currentOperation === "Analyzing verification results" ||
            loadingStatus.currentOperation === "Updating carrier verification status"
          ) {
            setState((prev) => ({
              ...prev,
              loadingSteps: {
                ...prev.loadingSteps,
                fraudChecks: true,
              },
            }));
          }

          // Handle errors in the loading process
          if (loadingStatus.error) {
            setState((prev) => ({
              ...prev,
              error: loadingStatus.error,
            }));
          }

          // Update current operation
          setState((prev) => ({
            ...prev,
            currentOperation:
              loadingStatus.currentOperation || prev.currentOperation,
          }));
        },
      });

      // Mark the fraud checks as complete
      setState((prev) => ({
        ...prev,
        loadingSteps: {
          ...prev.loadingSteps,
          fraudChecks: true,
        },
        currentOperation: "Verification complete",
        carrierData: result.carrierData,
        personaResults: result.personaResults,
        isLoading: false,
      }));

      return result;
    } catch (error) {
      // Handle error in vetting process
      console.error("[useCarrierVetting] Error in vetting process:", error);
      setState((prev) => ({
        ...prev,
        error: error.message || "Unknown error in vetting process",
        isLoading: false,
        currentOperation: "",
      }));
      return { success: false, error: error.message };
    }
  };

  /**
   * Get the verification status for a specific carrier
   * @param {string} carrierId The ID of the carrier to check
   * @returns {Promise<Object>} The verification status
   */
  const getCarrierVerificationStatus = async (carrierId) => {
    if (!carrierId) {
      return { success: false, error: "Carrier ID is required" };
    }

    try {
      setState((prev) => ({
        ...prev,
        isLoading: true,
        currentOperation: "Checking verification status",
      }));

      // Use CarrierVettingService to get verification status
      const result = await CarrierVettingService.getCarrierVerificationStatus(
        carrierId
      );

      // Update state with results
      setState((prev) => ({
        ...prev,
        isLoading: false,
        currentOperation: "",
      }));

      return result;
    } catch (error) {
      console.error(
        "[useCarrierVetting] Error getting verification status:",
        error
      );
      setState((prev) => ({
        ...prev,
        error: error.message || "Failed to get verification status",
        isLoading: false,
        currentOperation: "",
      }));

      return { success: false, error: error.message };
    }
  };

  return {
    vetCarrier,
    getCarrierVerificationStatus,
    state,
  };
};
