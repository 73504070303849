import React from "react";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

/**
 * The first step of the shipment form for entering basic shipment details
 */
export function ShipmentDetailsStep({ form, cargoOwnerOptions }) {
  const navigate = useNavigate();

  return (
    <>
      <FormField
        control={form.control}
        name="load_id"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Load ID</FormLabel>
            <FormControl>
              <Input placeholder="1234567890" {...field} />
            </FormControl>
            <FormDescription>
              Optional. A unique identifier will be generated if left blank.
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="cargo_owner_id"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Cargo Owner *</FormLabel>
              <FormControl>
                <select
                  className="flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                  value={field.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    console.log("Selected cargo owner:", value);
                    field.onChange(value);

                    // Explicitly set value in form
                    form.setValue("cargo_owner_id", value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });

                    // Log current form state after change
                    console.log(
                      "Form values after cargo owner selection:",
                      form.getValues()
                    );
                  }}
                >
                  <option value="" disabled>
                    Select Cargo Owner
                  </option>
                  {cargoOwnerOptions.map((owner) => (
                    <option key={owner.value} value={owner.value}>
                      {owner.label}
                    </option>
                  ))}
                </select>
              </FormControl>
              <FormDescription>
                Select the owner of the cargo being shipped
              </FormDescription>
              <FormMessage />
              <Button
                variant="link"
                className="p-0 h-auto text-sm"
                onClick={() => navigate("/dashboard/add-cargo-owner")}
              >
                Cargo owner not listed? Add a new cargo owner
              </Button>
            </FormItem>
          );
        }}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormField
          control={form.control}
          name="carrier_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Carrier Number</FormLabel>
              <FormControl>
                <Input placeholder="Enter carrier number" {...field} />
              </FormControl>
              <FormDescription>
                Optional. The identification number of the carrier handling this
                shipment.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="container_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Container Number</FormLabel>
              <FormControl>
                <Input placeholder="Enter container number" {...field} />
              </FormControl>
              <FormDescription>
                Optional. The container number for this shipment.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="pallet_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Pallet Number</FormLabel>
              <FormControl>
                <Input placeholder="Enter pallet number" {...field} />
              </FormControl>
              <FormDescription>
                Optional. The pallet number for this shipment.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="tags"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Tags</FormLabel>
              <FormControl>
                <Input
                  placeholder="e.g. fragile,expedited,refrigerated"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                Optional. Comma-separated tags to categorize this shipment.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="pickup_time"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Pickup Time</FormLabel>
              <FormControl>
                <div className="flex items-center w-full">
                  <DatePicker
                    selected={field.value ? new Date(field.value) : null}
                    onChange={(date) => {
                      console.log("Selected date:", date);
                      field.onChange(date ? date.toISOString() : null);
                      form.setValue(
                        "pickup_time",
                        date ? date.toISOString() : null,
                        {
                          shouldValidate: true,
                          shouldDirty: true,
                          shouldTouch: true,
                        }
                      );
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText="Pickup Date and Time"
                    className="file:text-foreground placeholder:text-muted-foreground selection:bg-primary selection:text-primary-foreground dark:bg-input/30 border-input flex h-9 w-full min-w-0 rounded-md border bg-transparent px-3 py-1 text-base shadow-xs transition-[color,box-shadow] outline-none disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm focus-visible:border-ring focus-visible:ring-ring/50 focus-visible:ring-[3px]"
                  />
                </div>
              </FormControl>
              <FormDescription>
                Optional. Schedule when the shipment should be picked up.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <FormField
        control={form.control}
        name="direction"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Direction</FormLabel>
            <FormControl>
              <select
                className="flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                value={field.value || ""}
                onChange={(e) => field.onChange(e.target.value)}
              >
                <option value="" disabled>
                  Select Direction
                </option>
                <option value="Inbound">Inbound</option>
                <option value="Outbound">Outbound</option>
              </select>
            </FormControl>
            <FormDescription>
              Optional. Specify whether this is an inbound or outbound shipment.
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="notes"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Notes</FormLabel>
            <FormControl>
              <textarea
                className="flex min-h-20 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                placeholder="Enter any additional notes about this shipment"
                {...field}
              />
            </FormControl>
            <FormDescription>
              Optional. Any additional information about this shipment.
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}
