import React from "react";

/**
 * A component that displays progress through multi-step forms
 *
 * @param {Object} props - Component props
 * @param {Array} props.steps - Array of step objects with labels
 * @param {number} props.currentStep - Current active step index
 * @param {string} [props.className] - Optional additional CSS classes
 * @returns {JSX.Element} The step indicator UI
 */
export function FormStepIndicator({ steps, currentStep, className = "" }) {
  return (
    <div className={`mb-6 ${className}`}>
      <div className="w-full">
        <div className="relative">
          {/* Progress bar line */}
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-200"></div>
          </div>

          {/* Step circles */}
          <div className="relative flex justify-between">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`flex items-center justify-center w-10 h-10 rounded-full 
                  ${
                    index <= currentStep
                      ? "bg-primary text-white"
                      : "bg-gray-200"
                  }
                  text-sm font-medium`}
              >
                {index + 1}
              </div>
            ))}
          </div>
        </div>

        {/* Step labels */}
        <div className="flex justify-between mt-2">
          {steps.map((step, index) => (
            <div key={index} className="text-center text-sm font-medium">
              {step.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
