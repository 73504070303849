// @ts-nocheck
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useVerificationByInquiryId,
  useInquiryVerificationMutations,
  useCheckStatus,
} from "../../hooks/verifications";
import SuccessPage from "../Components/SuccessPage";
import FailurePage from "../Components/FailurePage";
import OCRService from "../../api/services/core/OCRService";
import { performInsuranceVerificationOCR } from "../../api/services/verification/VerificationService";
import createPersonaClient from "../../lib/persona";
import { QRCodeSVG } from "qrcode.react";
import Toast from "../Components/Toast";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

// Import shadcn components
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Progress } from "@/components/ui/progress";
import { Badge } from "@/components/ui/badge";
import { Loader2, CheckCircle, AlertCircle, Info, Clock, RefreshCw, FileText } from "lucide-react";

const InsuranceVerification = () => {
  const { id: inquiry_id } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [statusMessage, setStatusMessage] = useState("");
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const [completionMessage, setCompletionMessage] = useState("");
  const [isPersonaLoading, setIsPersonaLoading] = useState(false);
  const steps = ["Insurance Verification", "Complete"];

  // Use the existing hooks
  const {
    data: verification,
    isLoading: isVerificationLoading,
    refetch: refetchVerification,
  } = useVerificationByInquiryId(inquiry_id);
  const { updateVerification, checkStatus } = useInquiryVerificationMutations();

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else {
      handleFlowComplete();
    }
  };

  const handleFlowComplete = async () => {
    try {
      // Update verification state to complete
      await updateVerification.mutateAsync({
        inquiry_id,
        data: { state: "Complete" },
      });

      setCompletionMessage("Insurance verification complete!");
    } catch (error) {
      console.error("Error completing verification:", error);
      setStatusMessage("An error occurred while completing the verification.");
    }
  };

  const handlePersonaVerification = async () => {
    if (!verification?.persona_inquiry_id) {
      console.error("Missing inquiry ID for Persona verification");
      setStatusMessage("Unable to start verification: missing inquiry ID");
      return;
    }

    const template_id = process.env.REACT_APP_PERSONA_TEMPLATE_ID_KYB_INSURANCE;
    if (!template_id) {
      console.error("Persona Template ID is required");
      setStatusMessage("Configuration error: missing template ID");
      return;
    }

    setIsPersonaLoading(true);
    setStatusMessage("Initializing verification...");

    try {
      createPersonaClient(
        verification.persona_inquiry_id,
        template_id,
        async () => {
          try {
            await updateVerification.mutateAsync({
              inquiry_id,
              data: { persona_verification_complete: true },
            });
            refetchVerification();
            setStatusMessage("Verification completed successfully!");
          } catch (error) {
            console.error("Error updating verification:", error);
            setStatusMessage("Failed to update verification status");
          } finally {
            setIsPersonaLoading(false);
          }
        }
      );
    } catch (error) {
      console.error("Error creating Persona client:", error);
      setStatusMessage("Failed to start verification process");
      setIsPersonaLoading(false);
    }
  };

  const handleCheckStatus = async () => {
    setIsCheckingStatus(true);
    setStatusMessage("Checking verification status...");
    try {
      const result = await checkStatus.mutateAsync({ inquiry_id });
      setStatusMessage(result.message || "Status check complete");
      if (result.success) {
        refetchVerification();
      }
    } catch (error) {
      console.error("Error checking status:", error);
      setStatusMessage("Error checking status: " + (error.message || "Unknown error"));
    } finally {
      setIsCheckingStatus(false);
    }
  };

  // Add this new function to handle going back
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const getStatusBadge = () => {
    if (!verification) return null;
    
    if (verification.persona_verification_complete) {
      return (
        <Badge className="bg-green-500">
          <CheckCircle className="w-3 h-3 mr-1" />
          <span>Verified</span>
        </Badge>
      );
    } else if (verification.state === "Started" || verification.state === "pending") {
      return (
        <Badge variant="outline" className="bg-amber-100 text-amber-800 border-amber-300">
          <Clock className="w-3 h-3 mr-1" />
          <span>In Progress</span>
        </Badge>
      );
    } else if (verification.state === "Declined") {
      return (
        <Badge variant="destructive">
          <AlertCircle className="w-3 h-3 mr-1" />
          <span>Declined</span>
        </Badge>
      );
    } else {
      return (
        <Badge variant="outline" className="bg-slate-100">
          <Info className="w-3 h-3 mr-1" />
          <span>{verification.state || "Not Started"}</span>
        </Badge>
      );
    }
  };
  
  // Loading component
  const LoadingSpinner = () => (
    <div className="flex flex-col items-center justify-center py-12">
      <Loader2 className="w-8 h-8 text-slate-700 animate-spin mb-4" />
      <p className="text-slate-600">Loading verification data...</p>
    </div>
  );

  const renderContent = () => {
    if (isVerificationLoading) {
      return <LoadingSpinner />;
    }

    if (isPersonaLoading) {
      return <LoadingSpinner />;
    }

    if (completionMessage) {
      return (
        <Alert className="bg-green-50 border-green-200 mb-6">
          <CheckCircle className="w-4 h-4 text-green-500" />
          <AlertTitle className="text-green-700 font-medium ml-2">
            {completionMessage}
          </AlertTitle>
        </Alert>
      );
    }

    if (verification?.isComplete) {
      return verification.personaSuccess ? (
        <SuccessPage />
      ) : (
        <FailurePage personaFailed={!verification.personaSuccess} />
      );
    }

    if (!verification?.persona_inquiry_id) {
      return (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Verification Error</AlertTitle>
          <AlertDescription>
            Verification data is incomplete. Please go back and try again.
            <Button 
              variant="outline" 
              className="mt-4"
              onClick={() => window.history.back()}
            >
              Go Back
            </Button>
          </AlertDescription>
        </Alert>
      );
    }

    switch (currentStep) {
      case 1:
        return (
          <Card className="mb-6">
            <CardHeader>
              <div className="flex justify-between items-center">
                <CardTitle>Insurance Verification</CardTitle>
                {getStatusBadge()}
              </div>
              <CardDescription>
                Verify your insurance documentation
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex items-center justify-between mb-4">
                <span className="text-sm font-medium text-slate-700">Verification Progress</span>
                <span className="text-sm text-slate-500">Step {currentStep} of {steps.length}</span>
              </div>
              
              <Progress value={(currentStep / steps.length) * 100} className="h-2" />
              
              {statusMessage && (
                <Alert className="mt-4 bg-blue-50 border-blue-200">
                  <Info className="h-4 w-4 text-blue-500" />
                  <AlertDescription className="text-blue-700 ml-2">{statusMessage}</AlertDescription>
                </Alert>
              )}
              
              <div className="pt-4 space-y-2">
                <Button
                  className="w-full"
                  variant="outline"
                  onClick={handleCheckStatus}
                  disabled={isCheckingStatus || verification?.persona_verification_complete}
                >
                  {isCheckingStatus ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Checking Status...
                    </>
                  ) : verification?.persona_verification_complete ? (
                    <>
                      <CheckCircle className="mr-2 h-4 w-4" />
                      Verification Complete
                    </>
                  ) : (
                    <>
                      <RefreshCw className="mr-2 h-4 w-4" />
                      Check Verification Status
                    </>
                  )}
                </Button>
                
                <Button
                  className="w-full"
                  onClick={handlePersonaVerification}
                  disabled={verification?.persona_verification_complete}
                >
                  {verification?.persona_verification_complete ? (
                    <>
                      <CheckCircle className="mr-2 h-4 w-4" />
                      Verification Complete
                    </>
                  ) : (
                    <>
                      <FileText className="mr-2 h-4 w-4" />
                      Start Insurance Verification
                    </>
                  )}
                </Button>
              </div>
            </CardContent>
            {verification?.persona_verification_complete && (
              <CardFooter className="bg-green-50 border-t border-green-200 py-3 px-6">
                <div className="flex items-center text-green-700">
                  <CheckCircle className="w-4 h-4 mr-2" />
                  <span className="text-sm">Your insurance has been successfully verified</span>
                </div>
              </CardFooter>
            )}
          </Card>
        );
      case 2:
        return (
          <Card className="mb-6">
            <CardHeader>
              <div className="flex justify-between items-center">
                <CardTitle>Verification Complete</CardTitle>
                {getStatusBadge()}
              </div>
              <CardDescription>Your insurance verification status</CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex justify-between items-center p-4 bg-slate-50 rounded-md border border-slate-200">
                <span className="text-sm font-medium">Insurance Verification Status:</span>
                <span 
                  className={`text-sm font-bold ${
                    verification?.persona_verification_complete
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {verification?.persona_verification_complete ? "Verified" : "Not Verified"}
                </span>
              </div>
              
              {verification?.persona_verification_complete && verification?.qr_code && (
                <div className="flex flex-col items-center bg-white p-4 rounded-md border border-slate-200">
                  <span className="text-sm font-medium mb-2">Verification QR Code</span>
                  <div className="bg-white p-2 rounded shadow-sm">
                    <QRCodeSVG value={verification.qr_code} size={120} />
                  </div>
                </div>
              )}
              
              {verification?.persona_verification_complete && (
                <Alert className="bg-green-50 border-green-200">
                  <CheckCircle className="h-4 w-4 text-green-500" />
                  <AlertTitle className="text-green-700 ml-2">Verification Successful</AlertTitle>
                  <AlertDescription className="text-green-600 ml-6">
                    Your insurance documentation has been successfully verified.
                  </AlertDescription>
                </Alert>
              )}
            </CardContent>
          </Card>
        );
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-4 sm:p-6 md:p-8">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg font-bold">Insurance Verification</h1>
        <Button
          className="w-full sm:w-auto"
          onClick={handleBack}
          disabled={currentStep === 1}
        >
          Back
        </Button>
      </div>
      {renderContent()}
      <div className="flex justify-end mt-4">
        <Button
          className="w-full sm:w-auto"
          onClick={handleNext}
          disabled={currentStep === steps.length}
        >
          {currentStep === steps.length ? "Complete" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default InsuranceVerification;
