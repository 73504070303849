import React from "react";

/**
 * DefaultVerificationDetails - Renders the default details view for an expanded verification row.
 *
 * @param {Object} props - Component props
 * @param {Object} props.verification - The verification data object
 */
export default function DefaultVerificationDetails({ verification }) {
  return (
    <div className="p-4 bg-gray-50 space-y-2 text-sm">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h4 className="font-semibold text-slate-600">Verification Details</h4>
          <p className="text-slate-500">
            Phone Number: {verification.phone_number || "N/A"}
          </p>
          <p className="text-slate-500">
            Shipment ID: {verification.shipment_id || "N/A"}
          </p>
          <p className="text-slate-500">
            Driver ID: {verification.driver_id || "N/A"}
          </p>
          <p className="text-slate-500">State: {verification.state || "N/A"}</p>
        </div>
        <div>
          <h4 className="font-semibold text-slate-600">
            Additional Information
          </h4>
          <p className="text-slate-500">
            Created At:{" "}
            {verification.created_at
              ? new Date(verification.created_at).toLocaleString()
              : "N/A"}
          </p>
          <p className="text-slate-500">
            Updated At:{" "}
            {verification.updated_at
              ? new Date(verification.updated_at).toLocaleString()
              : "N/A"}
          </p>
          <p className="text-slate-500">
            PIN Code: {verification.pin_code || "N/A"}
          </p>
        </div>
      </div>
    </div>
  );
}
