import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  lazy,
  Suspense,
} from "react";
import { useNavigate } from "react-router-dom";
import { format, addDays, subDays, parseISO } from "date-fns";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  BellIcon,
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  ChatBubbleLeftRightIcon,
  ArrowPathIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  DocumentTextIcon,
  ShieldCheckIcon,
  CurrencyDollarIcon,
  ClockIcon,
  ChartBarIcon,
  MapIcon,
} from "@heroicons/react/20/solid";
import {
  BuildingOfficeIcon,
  TruckIcon,
  UserGroupIcon,
  CubeIcon,
  CheckBadgeIcon,
  MapPinIcon,
  ExclamationTriangleIcon,
  BoltIcon,
  FireIcon,
  ShieldExclamationIcon,
  PhoneIcon,
  EnvelopeIcon,
  DocumentPlusIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/outline";
import { useGetUserSession } from "../../hooks/useUsers";
import { useCargoOwnerList } from "../../hooks/useCargoOwner";
import { useCarrierList } from "../../hooks/useCarriers";
import { useDrivers } from "../../hooks/useDrivers";
import { useShipments } from "../../hooks/useShipments";
import { useGetAllVerifications } from "../../hooks/useVerifications";
import LoadingSpinner from "../Components/LoadingSpinner";
import MapComponent from "../Components/MapComponent";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { Progress } from "@/components/ui/progress";
import { Slider } from "@/components/ui/slider";
import { Switch } from "@/components/ui/switch";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { motion } from "framer-motion";
import mockAlerts from "../../data/mockAlerts";

// --- Lazy Loaded Components ---
const CommandTabContent = lazy(() => import("./CommandTabContent"));
const AlertsTabContent = lazy(() => import("./AlertsTabContent"));
const MetricsTabContent = lazy(() => import("./MetricsTabContent"));
const MapTabContent = lazy(() => import("./MapTabContent"));
const ActionDialogContent = lazy(() => import("./ActionDialogContent"));
// -----------------------------

// Status styles for different states
const statusStyles = {
  Verified: "bg-green-100 text-green-800",
  Completed: "bg-blue-100 text-blue-800",
  Pending: "bg-yellow-100 text-yellow-800",
  Failed: "bg-red-100 text-red-800",
  Processing: "bg-purple-100 text-purple-800",
};

// Helper function for class names
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Command center components
const CommandBar = ({ onAction, urgentCount }) => {
  return (
    <div className="bg-gray-900 text-white p-4 rounded-lg flex items-center justify-between mb-4">
      <div className="flex items-center space-x-4">
        <div className="flex items-center space-x-2">
          <div className="h-3 w-3 bg-green-500 rounded-full animate-pulse"></div>
          <span className="font-medium">LIVE</span>
        </div>
        <Separator orientation="vertical" className="h-8 bg-gray-700" />
        <span className="text-sm text-gray-400">
          Last update: {format(new Date(), "h:mm:ss a")}
        </span>
      </div>
      <div className="flex items-center space-x-4">
        <Button
          variant="ghost"
          className="text-white"
          onClick={() => onAction("refresh")}
        >
          <ArrowPathIcon className="h-5 w-5 mr-1" /> Refresh
        </Button>
        <Button
          variant="outline"
          className="bg-red-600 hover:bg-red-700 text-white border-none"
          onClick={() => onAction("alert")}
        >
          <BoltIcon className="h-5 w-5 mr-1" />{" "}
          {urgentCount > 0 ? `${urgentCount} Urgent` : "All Clear"}
        </Button>
      </div>
    </div>
  );
};

export default function DashboardContent() {
  const navigate = useNavigate();
  const [timeRange, setTimeRange] = useState("30d");
  const [searchQuery, setSearchQuery] = useState("");
  const [showNotifications, setShowNotifications] = useState(false);
  const [activeTab, setActiveTab] = useState("command");

  // New state variables for enhanced features
  const [mapViewMode, setMapViewMode] = useState("standard");
  const [mapLayers, setMapLayers] = useState({
    hotspots: true,
    traffic: false,
    weather: true,
  });
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [showActionDialog, setShowActionDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [notifications, setNotifications] = useState([]);

  // Get user session
  const { session, isLoading: sessionLoading } = useGetUserSession();

  // Fetch data
  const { data: cargoOwnerData, isLoading: cargoOwnersLoading } =
    useCargoOwnerList();
  const { data: carrierData, isLoading: carriersLoading } = useCarrierList();
  const { data: driverData, isLoading: driversLoading } = useDrivers();
  const { data: shipmentsData, isLoading: shipmentsLoading } = useShipments();
  const { data: verificationData, isLoading: verificationsLoading } =
    useGetAllVerifications();

  // Extract shipments from the data
  const shipments = useMemo(() => {
    return shipmentsData?.shipments || [];
  }, [shipmentsData]);

  // Check if any data is loading
  const isLoading =
    sessionLoading ||
    cargoOwnersLoading ||
    carriersLoading ||
    driversLoading ||
    shipmentsLoading ||
    verificationsLoading;

  // Get current time of day for greeting
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning";
    if (hour < 18) return "Good afternoon";
    return "Good evening";
  };

  // Get user's first name
  const getUserFirstName = () => {
    if (!session?.user?.user_metadata?.full_name) return "";
    return session.user.user_metadata.full_name.split(" ")[0];
  };

  // Enhanced shipment data with risk scores and enriched properties
  const enhancedShipments = useMemo(() => {
    if (!shipments.length) return [];

    return shipments.map((shipment) => {
      // Generate a risk score between 1-100
      const riskScore = Math.floor(Math.random() * 100) + 1;

      // Determine if there are any alerts for this shipment
      const hasAlerts = riskScore > 70;

      // Calculate estimated financial value
      const estimatedValue = Math.floor(Math.random() * 50000) + 10000;

      // Calculate theft probability as a percentage
      const theftProbability = riskScore > 75 ? riskScore - 30 : riskScore / 3;

      // Create enriched shipment object
      return {
        ...shipment,
        riskScore,
        hasAlerts,
        estimatedValue,
        theftProbability,
        potentialLoss: Math.floor(estimatedValue * (theftProbability / 100)),
        insuranceSavings: Math.floor(estimatedValue * 0.02),
        geofenceStatus: Math.random() > 0.7 ? "outside" : "inside",
        weatherConditions: ["Clear", "Rainy", "Foggy", "Snowy"][
          Math.floor(Math.random() * 4)
        ],
        trafficConditions: ["Light", "Moderate", "Heavy", "Severe"][
          Math.floor(Math.random() * 4)
        ],
        predictedDelay:
          Math.random() > 0.7 ? Math.floor(Math.random() * 120) : 0,
        // Add current position with slight randomization for visual effect
        current_latitude: shipment.current_latitude
          ? parseFloat(shipment.current_latitude) +
            (Math.random() * 0.01 - 0.005)
          : null,
        current_longitude: shipment.current_longitude
          ? parseFloat(shipment.current_longitude) +
            (Math.random() * 0.01 - 0.005)
          : null,
        // Add theft history for the current route corridor
        routeTheftHistory: Math.floor(Math.random() * 5),
      };
    });
  }, [shipments]);

  // Replace the activeAlerts useMemo with:
  const activeAlerts = useMemo(() => {
    // Filter out the regular activities, keeping only actual alerts
    return mockAlerts.filter(
      (alert) =>
        alert.type === "alert" ||
        alert.type === "verification" ||
        alert.type === "document"
    );
  }, []);

  // Replace the activityFeed useMemo with:
  const activityFeed = useMemo(() => {
    // Return all entries from mockAlerts, which includes both alerts and activities
    return mockAlerts;
  }, []);

  // Calculate business value metrics
  const businessMetrics = useMemo(() => {
    if (!enhancedShipments.length)
      return {
        totalValue: 0,
        potentialLoss: 0,
        avoidedLoss: 0,
        insuranceSavings: 0,
        completionRate: 0,
        averageRisk: 0,
        highRiskCount: 0,
      };

    const totalValue = enhancedShipments.reduce(
      (sum, s) => sum + s.estimatedValue,
      0
    );
    const potentialLoss = enhancedShipments.reduce(
      (sum, s) => sum + s.potentialLoss,
      0
    );
    // Simulated avoided loss - this would come from real historical data
    const avoidedLoss = Math.floor(potentialLoss * 0.85);
    const insuranceSavings = enhancedShipments.reduce(
      (sum, s) => sum + s.insuranceSavings,
      0
    );

    // Calculate completion rate
    const completedShipments = enhancedShipments.filter(
      (s) => s.status === "Delivered" || s.status === "Completed"
    ).length;
    const completionRate =
      (completedShipments / enhancedShipments.length) * 100;

    // Calculate average risk score
    const totalRisk = enhancedShipments.reduce(
      (sum, s) => sum + s.riskScore,
      0
    );
    const averageRisk = totalRisk / enhancedShipments.length;

    // Count high risk shipments
    const highRiskCount = enhancedShipments.filter(
      (s) => s.riskScore > 70
    ).length;

    return {
      totalValue,
      potentialLoss,
      avoidedLoss,
      insuranceSavings,
      completionRate,
      averageRisk,
      highRiskCount,
    };
  }, [enhancedShipments]);

  // Get active shipments with location data for map with enhanced properties
  const activeShipments = useMemo(() => {
    return enhancedShipments
      .filter(
        (shipment) =>
          (shipment.status === "In Progress" ||
            shipment.status === "In Transit") &&
          shipment.current_latitude &&
          shipment.current_longitude
      )
      .map((shipment) => ({
        id: shipment.id,
        name: shipment.reference_number || `Shipment #${shipment.id}`,
        lat: parseFloat(shipment.current_latitude),
        lng: parseFloat(shipment.current_longitude),
        status: shipment.status,
        riskScore: shipment.riskScore,
        hasAlerts: shipment.hasAlerts,
        origin: shipment.origin_city,
        destination: shipment.destination_city,
        geofenceStatus: shipment.geofenceStatus,
        weatherConditions: shipment.weatherConditions,
        trafficConditions: shipment.trafficConditions,
      }));
  }, [enhancedShipments]);

  // Generate theft hotspots
  const theftHotspots = useMemo(() => {
    // This would normally come from real data analysis
    // For demo, generate some random hotspots near active shipments
    return activeShipments.slice(0, 3).map((shipment) => ({
      id: `hotspot-${shipment.id}`,
      lat: shipment.lat + (Math.random() * 0.2 - 0.1),
      lng: shipment.lng + (Math.random() * 0.2 - 0.1),
      intensity: Math.floor(Math.random() * 100) + 1,
      radius: Math.floor(Math.random() * 20) + 5,
      description: `${
        Math.floor(Math.random() * 10) + 1
      } reported thefts in last 30 days`,
    }));
  }, [activeShipments]);

  // Handle command bar actions
  const handleCommandAction = useCallback(
    (action) => {
      if (action === "refresh") {
        // Simulate refresh by showing a notification
        setNotifications([
          ...notifications,
          {
            id: Date.now(),
            title: "Dashboard Refreshed",
            message: "All data updated to latest values",
            type: "info",
          },
        ]);
      } else if (action === "alert") {
        // Toggle alert mode
        setActiveTab("alerts");
      }
    },
    [notifications]
  );

  // Handle executing an action
  const handleExecuteAction = useCallback((action) => {
    setCurrentAction(action);
    setShowActionDialog(true);
  }, []);

  // Handle dismissing an action
  const handleDismissAction = useCallback(
    (action) => {
      // Remove the action from the list
      setNotifications([
        ...notifications,
        {
          id: Date.now(),
          title: "Action Dismissed",
          message: `${action.title} has been dismissed`,
          type: "info",
        },
      ]);
    },
    [notifications]
  );

  // Handle map layer toggle
  const handleToggleMapLayer = useCallback((layer) => {
    setMapLayers((prev) => ({
      ...prev,
      [layer]: !prev[layer],
    }));
  }, []);

  // Format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  // Create value metrics cards
  const valueMetricsCards = useMemo(
    () => [
      {
        title: "Cargo Value Protected",
        value: formatCurrency(businessMetrics.totalValue),
        change: "+12% vs. last month",
        trend: "up",
        icon: ShieldCheckIcon,
        color: "bg-blue-100",
      },
      {
        title: "Potential Loss Exposure",
        value: formatCurrency(businessMetrics.potentialLoss),
        change: "-5% vs. last month",
        trend: "down",
        icon: ExclamationTriangleIcon,
        color: "bg-red-100",
      },
      {
        title: "Loss Avoidance",
        value: formatCurrency(businessMetrics.avoidedLoss),
        change: "+8% vs. last month",
        trend: "up",
        icon: CheckCircleIcon,
        color: "bg-green-100",
      },
      {
        title: "Insurance Savings",
        value: formatCurrency(businessMetrics.insuranceSavings),
        change: "+3% vs. last month",
        trend: "up",
        icon: CurrencyDollarIcon,
        color: "bg-green-100",
      },
    ],
    [businessMetrics, formatCurrency]
  );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner size="large" />
      </div>
    );
  }

  // --- Props to pass down to lazy components ---
  const commandTabProps = {
    valueMetricsCards,
    activeShipments,
    mapLayers,
    theftHotspots,
    handleToggleMapLayer,
    mapViewMode,
    setMapViewMode,
    activityFeed,
    activeAlerts,
    handleExecuteAction,
    handleDismissAction,
    setActiveTab,
  };
  const alertsTabProps = {
    activeAlerts,
    handleExecuteAction,
    handleDismissAction,
  };
  const metricsTabProps = {
    valueMetricsCards,
    enhancedShipments,
    businessMetrics,
  };
  const mapTabProps = {
    activeShipments,
    mapLayers,
    theftHotspots,
    handleToggleMapLayer,
    mapViewMode,
    setMapViewMode,
  };
  const actionDialogProps = {
    currentAction,
    setShowActionDialog,
    setNotifications,
    notifications,
  };
  // -------------------------------------------

  return (
    <div className="p-4 space-y-4">
      {/* Command Bar */}
      <CommandBar
        onAction={handleCommandAction}
        urgentCount={
          activeAlerts.filter((alert) => alert.severity === "high").length
        }
      />

      {/* Welcome header */}
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold tracking-tight">
          <span>
            {getGreeting()}, {getUserFirstName()}
          </span>
        </h1>
        {/* Tab Buttons */}
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            onClick={() => setActiveTab("command")}
            className={activeTab === "command" ? "bg-blue-50" : ""}
          >
            <MapPinIcon className="h-4 w-4 mr-2" /> Command Center
          </Button>
          <Button
            variant="outline"
            onClick={() => setActiveTab("alerts")}
            className={activeTab === "alerts" ? "bg-red-50" : ""}
          >
            <BellIcon className="h-4 w-4 mr-2" />
            {activeAlerts.length > 0 && (
              <span className="bg-red-500 text-white text-xs rounded-full px-1.5 py-0.5 mr-1">
                {activeAlerts.length}
              </span>
            )}
            Alerts
          </Button>
          <Button
            variant="outline"
            onClick={() => setActiveTab("metrics")}
            className={activeTab === "metrics" ? "bg-green-50" : ""}
          >
            <ChartBarIcon className="h-4 w-4 mr-2" /> Metrics
          </Button>
        </div>
      </div>

      {/* Dashboard Content - Using Suspense for Lazy Loaded Tabs */}
      <div className="space-y-4">
        <Suspense
          fallback={
            <div className="flex justify-center items-center p-10">
              <LoadingSpinner />
            </div>
          }
        >
          {activeTab === "command" && (
            <CommandTabContent {...commandTabProps} />
          )}
          {activeTab === "alerts" && <AlertsTabContent {...alertsTabProps} />}
          {activeTab === "metrics" && (
            <MetricsTabContent {...metricsTabProps} />
          )}
          {activeTab === "map" && <MapTabContent {...mapTabProps} />}
        </Suspense>
      </div>

      {/* Action Dialog - Using Suspense */}
      <Dialog open={showActionDialog} onOpenChange={setShowActionDialog}>
        {/* DialogTrigger might be part of ActionCenter/Alerts, ensure it works */}
        {/* The content itself is lazy loaded */}
        <Suspense
          fallback={
            <div className="flex justify-center items-center p-10">
              <LoadingSpinner />
            </div>
          }
        >
          {showActionDialog && <ActionDialogContent {...actionDialogProps} />}
        </Suspense>
      </Dialog>

      {/* Basic Notification Display Area (Example) */}
      <div className="fixed bottom-4 right-4 space-y-2 z-50">
        {notifications.map((notif) => (
          <div
            key={notif.id}
            className={`p-4 rounded-md shadow-lg text-sm ${
              notif.type === "success"
                ? "bg-green-100 text-green-800"
                : "bg-blue-100 text-blue-800"
            }`}
          >
            <p className="font-bold">{notif.title}</p>
            <p>{notif.message}</p>
            {/* Add a close button maybe */}
          </div>
        ))}
      </div>
    </div>
  );
}
