import VetCarrier from "./VetCarrier";

const VetCarrierForm = () => {
  return (
    <div className="min-h-screen bg-white flex flex-row">
      <VetCarrier />
    </div>
  );
};

export default VetCarrierForm;
