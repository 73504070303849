/**
 * Utility functions for working with Persona status values
 */

/**
 * Maps Persona inquiry statuses to standardized internal statuses
 *
 * @param {string} personaStatus - The status from Persona API
 * @returns {string} - Standardized internal status
 */
export function mapPersonaInquiryStatus(personaStatus) {
  if (!personaStatus) return "pending";

  const status = personaStatus.toLowerCase().replace(/_/g, " ");

  const statusMap = {
    approved: "completed",
    completed: "completed",
    declined: "failed",
    pending: "pending",
    reviewing: "pending",
    expired: "failed",
    errored: "failed",
  };

  return statusMap[status] || "pending";
}

/**
 * Maps Persona report statuses to standardized internal statuses
 *
 * @param {string} reportStatus - The status from Persona API
 * @returns {string} - Standardized internal status
 */
export function mapPersonaReportStatus(reportStatus) {
  if (!reportStatus) return "pending";

  const status = reportStatus.toLowerCase().replace(/_/g, " ");

  const statusMap = {
    passed: "completed",
    completed: "completed",
    generated: "completed",
    failed: "failed",
    pending: "pending",
    canceled: "failed",
    errored: "failed",
  };

  return statusMap[status] || "pending";
}

/**
 * Determines if a verification is successful based on status
 *
 * @param {string} status - Status from Persona API
 * @returns {boolean} Whether verification was successful
 */
export function isVerificationSuccessful(status) {
  if (!status) return false;

  const successStatuses = ["approved", "completed", "passed", "generated"];

  return successStatuses.includes(status.toLowerCase().replace(/_/g, " "));
}

/**
 * Maps standardized status to verification state
 *
 * @param {string} standardStatus - The standardized status
 * @returns {string} - The verification state
 */
export function mapStatusToVerificationState(standardStatus) {
  const stateMap = {
    completed: "verified",
    pending: "pending",
    failed: "rejected",
  };

  return stateMap[standardStatus] || "pending";
}

/**
 * Gets a formatted verification result from status
 *
 * @param {string} status - Status from Persona API
 * @param {Object} additionalData - Additional data to include in result
 * @returns {Object} - Formatted verification result
 */
export function getVerificationResultFromStatus(status, additionalData = {}) {
  const standardStatus = mapPersonaInquiryStatus(status);
  const success = isVerificationSuccessful(status);

  return {
    status: standardStatus,
    passed: success,
    personaStatus: status,
    lastUpdated: new Date().toISOString(),
    ...additionalData,
  };
}

// Export default for convenience
export default {
  mapPersonaInquiryStatus,
  mapPersonaReportStatus,
  isVerificationSuccessful,
  mapStatusToVerificationState,
  getVerificationResultFromStatus,
};
