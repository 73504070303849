// @ts-nocheck
import React, { useState } from "react";
import { Helmet } from "react-helmet"; // SEO helper
import { useNavigate } from "react-router-dom";
import { Button } from "../Components/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../Components/Card";
import { Input } from "../Components/Input";
import { Label } from "../Components/Label";
import { supabase } from "../../lib/supabase";
import { toast } from "react-hot-toast";

export default function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (
        companyId !==
        "LYNX1234290-5u90-2856-29086-234860-32489560-34-6038-40683-046-034960-3495"
      ) {
        toast.error(
          "Invalid company ID - If you do not have a company ID, please contact us at support@indemni.com",
          {
            duration: 4000,
            position: "top-center",
            style: {
              background: "linear-gradient(135deg, #FFB74D 0%, #FF9800 100%)",
              color: "#fff",
              borderRadius: "8px",
              padding: "12px 24px",
              height: "120px",
              width: "500px",
              boxShadow:
                "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
              fontSize: "14px",
              fontWeight: "500",
            },
          }
        );
        throw new Error("Invalid company ID");
      }
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: {
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            company_access_id: companyId,
          },
        },
      });

      toast.success(
        "Sign up successful! Please check your email to verify your account.",
        {
          duration: 4000,
          position: "top-center",
          style: {
            background: "linear-gradient(135deg, #4CAF50 0%, #45a049 100%)",
            color: "#fff",
            borderRadius: "8px",
            padding: "12px 24px",
            height: "120px",
            width: "500px",
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            fontSize: "14px",
            fontWeight: "500",
          },
        }
      );
      navigate("/signin");
    } catch (error) {
      if (error.message !== "Invalid company ID") {
        toast.error(error.message, {
          duration: 4000,
          position: "top-center",
          style: {
            background: "linear-gradient(135deg, #FFB74D 0%, #FF9800 100%)",
            color: "#fff",
            borderRadius: "8px",
            padding: "12px 24px",
            height: "120px",
            width: "500px",
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
            fontSize: "14px",
            fontWeight: "500",
          },
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative h-screen w-full bg-custom-photo bg-no-repeat bg-cover flex justify-center items-center">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Sign Up - Indemni</title>
        <meta
          name="description"
          content="Create your account on Indemni's secure cargo risk management platform and unlock our full risk management tool."
        />
        <link rel="canonical" href="https://indemni.com/signup" />
        <meta property="og:title" content="Sign Up - Indemni" />
        <meta
          property="og:description"
          content="Create your account on Indemni's secure cargo risk management platform and unlock our full risk management tool."
        />
        <meta property="og:url" content="https://indemni.com/signup" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Card className="w-full max-w-md shadow-lg rounded-lg overflow-hidden z-10">
        <form onSubmit={handleSubmit}>
          <CardHeader className="text-left">
            <CardTitle className="text-2xl font-bold">
              Create your account
            </CardTitle>
            <CardDescription className="text-sm text-gray-600 mt-2">
              Sign up to unlock our full risk management tool
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4 px-6">
            <div className="space-y-1">
              <Label htmlFor="first_name">First Name</Label>
              <Input
                id="first_name"
                type="text"
                placeholder="First Name"
                className="w-full bg-white"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="last_name">Last Name</Label>
              <Input
                id="last_name"
                type="text"
                placeholder="Last Name"
                className="w-full bg-white"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="you@example.com"
                className="w-full bg-white"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="company_id">Company ID</Label>
              <Input
                id="company_id"
                type="text"
                placeholder="Company ID"
                className="w-full bg-white"
                value={companyId}
                onChange={(e) => setCompanyId(e.target.value)}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="phone_number">Phone Number</Label>
              <Input
                id="phone_number"
                type="tel"
                placeholder="123-456-7890"
                className="w-full bg-white"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                placeholder="Enter your password"
                className="w-full bg-white"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </CardContent>
          <CardFooter className="px-6 py-4">
            <Button
              type="submit"
              className="w-full bg-blue-900 text-white py-2 rounded-md hover:bg-indigo-500"
              disabled={loading}
            >
              {loading ? "Signing up..." : "Sign Up"}
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
}
