import React from "react";
import { motion } from "framer-motion";
import StatusBadge from "./StatusBadge";
import EmailRiskReport from "./ReportTypes/EmailRiskReport.jsx";
import PhoneRiskReport from "./ReportTypes/PhoneRiskReport.jsx";
import BusinessVerificationTransaction from "./ReportTypes/BusinessVerificationTransaction.jsx";
import BusinessRegistrationsLookup from "./ReportTypes/BusinessRegistrationsLookup.jsx";
import DatabaseBusinessVerification from "./ReportTypes/DatabaseBusinessVerification.jsx";
import DefaultReport from "./ReportTypes/DefaultReport.jsx";

const ResultCard = ({
  result,
  isExpanded,
  onToggle,
  isLatest,
  showRawJson,
  verificationResult,
}) => {
  if (!result || !result.type) {
    return null;
  }

  // Map known types to friendly report labels
  const REPORT_TYPE_NAMES = {
    "verification/database-business": "Database Business Verification",
    "report/email-address": "Email Risk Report",
    "report/phone-number": "Phone Risk Report",
    "report/business-registrations-lookup": "Business Registrations",
    transaction: "Business Verification Transaction",
    unknown: "Unknown Report",
  };

  let reportTypeFriendly =
    REPORT_TYPE_NAMES[result.type] || REPORT_TYPE_NAMES.unknown;

  // Determine final status
  let status = "completed"; // we've already filtered out incomplete in the parent
  if (result.attributes) {
    if (
      result.attributes.status === "errored" ||
      result.attributes.status === "failed"
    ) {
      status = "error";
    } else if (
      result.attributes.status === "passed" ||
      result.attributes.status === "ready"
    ) {
      status = "completed";
    }
  }

  // Render the correct component based on the type
  const renderReportContent = () => {
    if (!result.attributes) return null;

    switch (result.type) {
      case "verification/database-business":
        return <DatabaseBusinessVerification attributes={result} />;
      case "report/email-address":
        return <EmailRiskReport attributes={result.attributes} />;
      case "report/phone-number":
        return <PhoneRiskReport attributes={result.attributes} />;
      case "transaction":
        return;
      case "report/business-registrations-lookup":
        return <BusinessRegistrationsLookup attributes={result.attributes} />;
      default:
        return (
          <DefaultReport
            attributes={result.attributes}
            reportType={result.type}
          />
        );
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`mb-4 border rounded-lg overflow-hidden shadow-sm ${
        isLatest ? "bg-white border-slate-200" : "bg-slate-50 border-slate-100"
      }`}
    >
      <div
        className="p-4 flex items-center justify-between cursor-pointer"
        onClick={onToggle}
      >
        <div className="flex flex-col">
          <h3
            className={`font-medium ${
              isLatest ? "text-slate-800" : "text-slate-700"
            }`}
          >
            {reportTypeFriendly}
          </h3>
          <span className="text-xs text-slate-500">
            {result.id ? `ID: ${result.id}` : "Processing..."}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <button className="text-slate-400 hover:text-slate-600">
            <StatusBadge status={status} />
            <svg
              className={`w-5 h-5 transition-transform ${
                isExpanded ? "rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
        </div>
      </div>

      {isExpanded && (
        <div className="border-t border-slate-100 p-4 bg-white">
          {showRawJson && result.raw && (
            <div className="mb-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
              <h3 className="font-medium mb-2 text-gray-700">
                Raw Response Data:
              </h3>
              <pre className="text-xs text-black bg-white p-3 rounded border overflow-auto max-h-96">
                {JSON.stringify(result.raw, null, 2)}
              </pre>
            </div>
          )}
          {renderReportContent()}
        </div>
      )}
    </motion.div>
  );
};

export default ResultCard;
