import React from "react";
import RiskReportAttributeGroup from "../RiskReportAttributeGroup";

/**
 * Renders a phone risk report
 * @param {Object} props
 * @param {Object} props.attributes - The report attributes
 */
const PhoneRiskReport = ({ attributes }) => {
  // Group phone report fields based on the actual data structure
  const basicInfo = [
    "phone-number",
    "status",
    "has-match",
    "created-at",
    "completed-at",
    "redacted-at",
    "is-recurring",
    "is-continuous",
  ];

  const carrierDetails = ["phone-type", "phone-carrier"];

  const riskAssessment = [
    "phone-risk-level",
    "phone-risk-score",
    "phone-risk-recommendation",
    "phone-risk-sim-swap",
  ];

  return (
    <div className="space-y-6">
      <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
        <h3 className="text-blue-800 font-medium mb-3 text-sm flex items-center">
          <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            ></path>
          </svg>
          Basic Information
        </h3>
        <div className="bg-white rounded-lg divide-y divide-slate-100 shadow-sm">
          <RiskReportAttributeGroup attributes={attributes} keys={basicInfo} />
        </div>
      </div>

      <div className="bg-indigo-50 p-4 rounded-lg border border-indigo-100">
        <h3 className="text-indigo-800 font-medium mb-3 text-sm flex items-center">
          <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2z"
            ></path>
          </svg>
          Carrier Information
        </h3>
        <div className="bg-white rounded-lg divide-y divide-slate-100 shadow-sm">
          <RiskReportAttributeGroup
            attributes={attributes}
            keys={carrierDetails}
          />
        </div>
      </div>

      <div className="bg-rose-50 p-4 rounded-lg border border-rose-100">
        <h3 className="text-rose-800 font-medium mb-3 text-sm flex items-center">
          <svg
            className="w-4 h-4 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            ></path>
          </svg>
          Risk Assessment
        </h3>
        <div className="bg-white rounded-lg divide-y divide-slate-100 shadow-sm">
          <RiskReportAttributeGroup
            attributes={attributes}
            keys={riskAssessment}
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneRiskReport;
