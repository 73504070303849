// @ts-nocheck
import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { Button } from "../../Components/Button";
import { Card, CardContent } from "../../Components/Card";
import {
  Shield,
  FileCheck,
  Brain,
  TruckIcon,
  ClipboardCheck,
  Clock,
  BarChart4,
} from "lucide-react";

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 },
};

const Warehouses = () => {
  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Warehouses | Secure White Glove Delivery Operations</title>
        <meta
          name="description"
          content="Join leading warehouses in streamlining white glove delivery operations without compromising security. Optimized for compliance and risk management."
        />
        <link rel="canonical" href="https://indemni.com/warehouses" />
        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Warehouses | Secure White Glove Delivery Operations"
        />
        <meta
          property="og:description"
          content="Join leading warehouses in streamlining white glove delivery operations without compromising security."
        />
        <meta property="og:url" content="https://indemni.com/warehouses" />
        <meta property="og:type" content="website" />
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="bg-gradient-to-b from-slate-900 to-slate-800 min-h-screen">
        {/* Hero Section */}
        <section className="relative py-12 md:py-20 overflow-hidden">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              className="text-center"
              initial={fadeIn.initial}
              animate={fadeIn.animate}
              transition={fadeIn.transition}
            >
              <div className="inline-block px-3 py-1 md:px-4 md:py-1 bg-[#119DA5]/10 rounded-full mb-4">
                <span className="text-[#119DA5] text-sm md:text-base font-semibold">
                  Trusted by Leading Warehouses
                </span>
              </div>
              <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold text-white mb-4 md:mb-6">
                Streamline White Glove
                <br />
                <span className="text-[#119DA5]">Delivery Operations</span>
                <br />
                Without Compromising Security
              </h1>
              <p className="text-lg md:text-xl text-gray-300 mb-6 md:mb-8 max-w-2xl mx-auto px-4">
                Join warehouses using our end-to-end delivery management
                platform to optimize operations while maintaining compliance and
                preventing cargo loss.
              </p>
              <div className="flex flex-col items-center justify-center space-y-4 px-4">
                <Button
                  className="w-96 bg-[#119DA5] text-white px-8 py-4 md:px-10 md:py-6 text-lg md:text-xl rounded-lg hover:bg-[#0E8A91] transition-colors"
                  onClick={() =>
                    (window.location.href =
                      "https://calendly.com/meet-indemni/30min")
                  }
                >
                  Secure your Cargo Handoffs Today
                </Button>
                <Button
                  className="w-96 bg-transparent border-2 border-[#119DA5] text-white px-8 py-4 md:px-10 md:py-6 text-lg md:text-xl rounded-lg hover:bg-[#119DA5]/10 transition-colors"
                  onClick={() => (window.location.href = "/vetting")}
                >
                  Try our free carrier lookup tool →
                </Button>
                <span className="text-sm text-gray-400 block mt-2">
                  No commitment required
                </span>
              </div>
            </motion.div>
          </div>

          {/* Decorative Elements */}
          <div className="absolute top-0 right-0 -z-10 opacity-20">
            <svg width="400" height="400" viewBox="0 0 100 100" fill="none">
              <circle
                cx="50"
                cy="50"
                r="40"
                stroke="#119DA5"
                strokeWidth="0.5"
              />
            </svg>
          </div>
        </section>

        {/* Problem Statement Section */}
        <section className="py-12 md:py-20 bg-slate-800/50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-start"
              initial={fadeIn.initial}
              animate={fadeIn.animate}
              transition={fadeIn.transition}
            >
              <div>
                <h2 className="text-3xl font-bold text-white mb-6 text-center md:text-left">
                  Why Leading Warehouses Choose Indemni
                </h2>
                <div className="space-y-6">
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-6 h-6 rounded-full bg-[#119DA5] flex items-center justify-center mt-1">
                      <span className="text-white text-sm">1</span>
                    </div>
                    <div>
                      <p className="text-gray-300">
                        <strong className="text-white">
                          Growing Delivery Complexity:
                        </strong>{" "}
                        $142B+ white glove delivery market demands premium
                        service with perfect execution
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-6 h-6 rounded-full bg-[#119DA5] flex items-center justify-center mt-1">
                      <span className="text-white text-sm">2</span>
                    </div>
                    <div>
                      <p className="text-gray-300">
                        <strong className="text-white">
                          Multiple Systems Create Risk:
                        </strong>{" "}
                        Managing separate platforms for inventory, delivery, and
                        security creates gaps
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0 w-6 h-6 rounded-full bg-[#119DA5] flex items-center justify-center mt-1">
                      <span className="text-white text-sm">3</span>
                    </div>
                    <div>
                      <p className="text-gray-300">
                        <strong className="text-white">
                          Single Platform Solution:
                        </strong>{" "}
                        Manage pickups, scheduling, compliance and security in
                        one integrated system
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Updated stats cards */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 md:mt-0">
                <Card className="bg-slate-700 border-none h-full">
                  <CardContent className="p-6 flex flex-col h-full">
                    <h3 className="text-4xl font-bold text-[#119DA5] mb-2 text-center sm:text-left">
                      Compliance
                    </h3>
                    <p className="text-gray-300 font-medium mb-2 text-center sm:text-left">
                      Meet Compliance Needs
                    </p>
                    <span className="text-sm text-gray-400 text-center sm:text-left">
                      Automated chain of custody tracking for all modes of
                      transport
                    </span>
                  </CardContent>
                </Card>
                <Card className="bg-slate-700 border-none h-full">
                  <CardContent className="p-6 flex flex-col h-full">
                    <h3 className="text-4xl font-bold text-[#119DA5] mb-2 text-center sm:text-left">
                      60%
                    </h3>
                    <p className="text-gray-300 font-medium mb-2 text-center sm:text-left">
                      Faster Processing
                    </p>
                    <span className="text-sm text-gray-400 text-center sm:text-left">
                      Streamlined workflows from inventory to final delivery
                    </span>
                  </CardContent>
                </Card>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-12 md:py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              className="text-center mb-12 md:mb-16"
              initial={fadeIn.initial}
              animate={fadeIn.animate}
              transition={fadeIn.transition}
            >
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
                Proactive Security Features
              </h2>
              <p className="text-lg md:text-xl text-gray-300 px-4">
                Catch fraudulent documentation and identity before it leads to
                theft with our comprehensive verification system
              </p>
            </motion.div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:gap-8">
              {features.map((feature, index) => (
                <Card
                  key={index}
                  className="bg-slate-700 border-none hover:bg-slate-600 transition-colors"
                >
                  <CardContent className="p-6">
                    <feature.icon className="w-12 h-12 text-[#119DA5] mb-4" />
                    <h3 className="text-xl font-bold text-white mb-2">
                      {feature.title}
                    </h3>
                    <p className="text-gray-300">{feature.description}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section className="py-12 md:py-20 bg-slate-800/30">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              className="text-center mb-12"
              initial={fadeIn.initial}
              animate={fadeIn.animate}
              transition={fadeIn.transition}
            >
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
                How Our Platform Works
              </h2>
              <p className="text-lg text-gray-300 max-w-3xl mx-auto">
                End-to-end delivery management built specifically for warehouses
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <Card className="bg-slate-700 border-none">
                <CardContent className="p-6 text-center">
                  <div className="bg-[#119DA5]/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                    <TruckIcon className="w-8 h-8 text-[#119DA5]" />
                  </div>
                  <h3 className="text-xl font-bold text-white mb-2">
                    1. Inventory Management
                  </h3>
                  <p className="text-gray-300">
                    Streamlined inventory tracking with real-time updates and
                    chain of custody verification
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-slate-700 border-none">
                <CardContent className="p-6 text-center">
                  <div className="bg-[#119DA5]/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                    <Shield className="w-8 h-8 text-[#119DA5]" />
                  </div>
                  <h3 className="text-xl font-bold text-white mb-2">
                    2. Security Verification
                  </h3>
                  <p className="text-gray-300">
                    Multi-factor authentication and identity verification for
                    delivery personnel
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-slate-700 border-none">
                <CardContent className="p-6 text-center">
                  <div className="bg-[#119DA5]/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                    <ClipboardCheck className="w-8 h-8 text-[#119DA5]" />
                  </div>
                  <h3 className="text-xl font-bold text-white mb-2">
                    3. Delivery Documentation
                  </h3>
                  <p className="text-gray-300">
                    Complete digital record of delivery with timestamp
                    verification and proof of delivery
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-12 md:py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              className="text-center mb-12"
              initial={fadeIn.initial}
              animate={fadeIn.animate}
              transition={fadeIn.transition}
            >
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
                Key Benefits for Warehouses
              </h2>
              <p className="text-lg text-gray-300 max-w-3xl mx-auto">
                How our platform transforms your delivery operations
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <Card className="bg-slate-700 border-none">
                <CardContent className="p-6">
                  <Shield className="w-10 h-10 text-[#119DA5] mb-4" />
                  <h3 className="text-xl font-bold text-white mb-2">
                    Enhanced Security
                  </h3>
                  <p className="text-gray-300">
                    Prevent unauthorized access and verify all personnel
                    involved in the delivery process
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-slate-700 border-none">
                <CardContent className="p-6">
                  <BarChart4 className="w-10 h-10 text-[#119DA5] mb-4" />
                  <h3 className="text-xl font-bold text-white mb-2">
                    Operational Efficiency
                  </h3>
                  <p className="text-gray-300">
                    Reduce processing time by up to 60% with streamlined
                    workflows and automated verification
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-slate-700 border-none">
                <CardContent className="p-6">
                  <Brain className="w-10 h-10 text-[#119DA5] mb-4" />
                  <h3 className="text-xl font-bold text-white mb-2">
                    Intelligent Scheduling
                  </h3>
                  <p className="text-gray-300">
                    AI-powered scheduling optimization for delivery routes and
                    warehouse resources
                  </p>
                </CardContent>
              </Card>

              <Card className="bg-slate-700 border-none">
                <CardContent className="p-6">
                  <Clock className="w-10 h-10 text-[#119DA5] mb-4" />
                  <h3 className="text-xl font-bold text-white mb-2">
                    Real-time Visibility
                  </h3>
                  <p className="text-gray-300">
                    Complete visibility into delivery status, chain of custody,
                    and documentation
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-12 md:py-20 bg-[#119DA5]/10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <motion.div
              initial={fadeIn.initial}
              animate={fadeIn.animate}
              transition={fadeIn.transition}
            >
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 md:mb-6">
                Ready to Secure Your Warehouse Operations?
              </h2>
              <p className="text-lg md:text-xl text-gray-300 mb-6 md:mb-8 max-w-2xl mx-auto px-4">
                Join forward-thinking warehouses who have transformed their
                delivery operations with our comprehensive platform.
              </p>
              <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 justify-center">
                <Button
                  className="bg-white text-slate-900 px-6 py-3 md:px-8 md:py-4 text-base md:text-lg rounded-lg hover:bg-gray-100 transition-colors"
                  onClick={() =>
                    (window.location.href =
                      "https://calendly.com/meet-indemni/30min")
                  }
                >
                  Schedule a Demo
                </Button>
                <Button
                  className="bg-transparent border-2 border-white text-white px-6 py-3 md:px-8 md:py-4 text-base md:text-lg rounded-lg hover:bg-white/10 transition-colors"
                  onClick={() => (window.location.href = "/vetting")}
                >
                  Try Free Lookup Tool
                </Button>
              </div>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

const features = [
  {
    title: "End-to-End Management",
    description:
      "Single platform for verification, delivery scheduling, and security compliance",
    icon: Shield,
  },
  {
    title: "White Glove Support",
    description:
      "Specialized workflows for technical deliveries, installation, and setup verification",
    icon: FileCheck,
  },
  {
    title: "Smart Operations",
    description:
      "AI-powered scheduling optimization and real-time tracking of every delivery touchpoint",
    icon: Brain,
  },
];

export default Warehouses;
