import React, { useState } from "react";
import Header from "../Portal/Header";

// Stats Component
const RuleStats = ({ data }) => (
  <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
    {[
      {
        title: "Active Rules",
        value: data.filter((rule) => rule.status === "active").length,
        color: "bg-green-100 text-green-800",
      },
      {
        title: "Total Triggers",
        value: data.reduce((acc, rule) => acc + (rule.triggerCount || 0), 0),
        color: "bg-blue-100 text-blue-800",
      },
      {
        title: "Accuracy Rate",
        value: `${Math.round(
          (data.reduce((acc, rule) => acc + (rule.accuracyRate || 0), 0) /
            data.length) *
            100
        )}%`,
        color: "bg-purple-100 text-purple-800",
      },
      {
        title: "False Positives",
        value: data.reduce((acc, rule) => acc + (rule.falsePositives || 0), 0),
        color: "bg-red-100 text-red-800",
      },
    ].map((stat, index) => (
      <div key={index} className={`${stat.color} rounded-lg p-4 shadow-sm`}>
        <h3 className="text-sm font-medium">{stat.title}</h3>
        <p className="text-2xl font-semibold mt-2">{stat.value}</p>
      </div>
    ))}
  </div>
);

// Filter Component
const RuleFilter = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({});

  const handleFilterChange = (newFilters) => {
    const updatedFilters = { ...filters, ...newFilters };
    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <select
          className="form-select rounded-md border-gray-300 bg-gray-500 text-white font-medium"
          onChange={(e) => handleFilterChange({ status: e.target.value })}
        >
          <option value="">All Statuses</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
        <select
          className="form-select rounded-md border-gray-300 bg-gray-500 text-white font-medium"
          onChange={(e) => handleFilterChange({ type: e.target.value })}
        >
          <option value="">All Types</option>
          <option value="identity">Identity</option>
          <option value="behavior">Behavior</option>
          <option value="transaction">Transaction</option>
        </select>
        {/* Add more filters as needed */}
      </div>
    </div>
  );
};

// Table Component
const RuleTable = ({ data, currentPage, totalPages, onPageChange }) => (
  <div className="bg-white rounded-lg shadow-sm mt-4">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Rule Name
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Status
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Type
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Triggers
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Accuracy
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((rule) => (
          <tr key={rule.id}>
            <td className="px-6 py-4 whitespace-nowrap text-blue-500 font-medium">
              {rule.name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-blue-500 font-medium">
              <span
                className={`px-2 py-1 rounded-full text-xs ${
                  rule.status === "active"
                    ? "bg-green-100 text-green-800"
                    : "bg-gray-100 text-gray-800"
                }`}
              >
                {rule.status}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-blue-500 font-medium">
              {rule.type}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-blue-500 font-medium">
              {rule.triggerCount}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-blue-500 font-medium">
              {`${Math.round(rule.accuracyRate * 100)}%`}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-blue-500 font-medium">
              <button className="text-indigo-600 hover:text-indigo-900">
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {/* Add pagination controls here */}
  </div>
);

// Main Dashboard Component
const FraudRuleDashboard = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const rulesData = {
    total: 100,
    rules: [
      {
        id: 1,
        name: "Rule 1",
        status: "active",
        type: "identity",
      },
    ],
  };
  const isLoading = false;
  //   const error = {
  //     message: "Error loading rules",
  //   };

  //   if (error)
  //     return (
  //       <div className="text-center text-red-500 mt-20">
  //         Error: {error.message}
  //       </div>
  //     );
  if (isLoading)
    return <div className="text-center text-gray-500 mt-20">Loading...</div>;

  const totalPages = Math.ceil((rulesData?.total || 0) / limit);

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header
        companyName="Fraud Rules"
        url="/dashboard/fraud-rules/create"
        text="Fraud Rules"
      />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-full">
          <RuleFilter onFilterChange={setFilters} />
        </div>
        <RuleStats data={rulesData?.rules || []} />
        <div className="mt-8">
          <RuleTable
            data={rulesData?.rules || []}
            currentPage={page}
            totalPages={totalPages}
            onPageChange={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default FraudRuleDashboard;
