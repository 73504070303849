import React from "react";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";

/**
 * Submit button component with loading state and success indicator
 */
export function SubmitButton({ isSubmitting, onClick, disabled = false }) {
  return (
    <Button
      type="button"
      disabled={isSubmitting || disabled}
      className="relative bg-primary text-primary-foreground hover:bg-primary/90"
      onClick={onClick}
    >
      {isSubmitting ? (
        <div className="flex items-center">
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          <span>Creating Shipment...</span>
        </div>
      ) : (
        <>
          <span>Create Shipment</span>
          {/* Visual indicator that pulses when form is valid */}
          <span className="absolute top-0 right-0 mr-1 mt-1 flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
          </span>
        </>
      )}
    </Button>
  );
}
