/**
 * PersonaService.js
 *
 * This file serves as a facade that delegates to specialized Persona services.
 * It exports all functions from the specialized services to maintain backward compatibility.
 */

// Import all specialized services
import PersonaInquiryService from "./PersonaInquiryService";
import { PersonaProvider } from "../../../providers/PersonaProvider";
import { ProviderFactory } from "../../../factories/ProviderFactory";
import PersonaReportService from "./PersonaReportService";
import PersonaWebhookService from "./PersonaWebhookService";
import BusinessVerificationPersonaService from "./BusinessVerificationPersonaService";
import PersonaRepositoryService from "./PersonaRepositoryService";

// Export inquiry-related functions
export const createPersonaInquiry =
  PersonaInquiryService.createPersonaInquiry.bind(PersonaInquiryService);
export const savePersonaInquiry = PersonaInquiryService.savePersonaInquiry.bind(
  PersonaInquiryService
);
export const getPersonaInquiry = PersonaInquiryService.getPersonaInquiry.bind(
  PersonaInquiryService
);
export const updatePersonaInquiry =
  PersonaInquiryService.updatePersonaInquiry.bind(PersonaInquiryService);
export const getVerificationStatus =
  PersonaInquiryService.getVerificationStatus.bind(PersonaInquiryService);

// Export report-related functions
export const createPersonaReport =
  PersonaReportService.createPersonaReport.bind(PersonaReportService);
export const getAllPersonaReports =
  PersonaReportService.getAllPersonaReports.bind(PersonaReportService);
export const getPersonaReportByReferenceId =
  PersonaReportService.getPersonaReportByReferenceId.bind(PersonaReportService);
export const getVerificationsByCarrierId =
  PersonaReportService.getVerificationsByCarrierId.bind(PersonaReportService);
export const updatePersonaReport =
  PersonaReportService.updatePersonaReport.bind(PersonaReportService);

// Export business verification functions
export const verifyBusinessOwnerWithInquiry =
  BusinessVerificationPersonaService.verifyBusinessOwnerWithInquiry.bind(
    BusinessVerificationPersonaService
  );
export const getPersonaVerificationStatusDirect =
  BusinessVerificationPersonaService.getPersonaVerificationStatusDirect.bind(
    BusinessVerificationPersonaService
  );

// Export repository functions
export const saveVerificationResult =
  PersonaRepositoryService.saveVerificationResult.bind(
    PersonaRepositoryService
  );
export const saveToVerificationsTable =
  PersonaRepositoryService.saveToVerificationsTable.bind(
    PersonaRepositoryService
  );

// Export webhook handler functions
export const handlePersonaWebhook =
  PersonaWebhookService.handlePersonaWebhook.bind(PersonaWebhookService);
export const handleBusinessRegistrationsLookupEvent =
  PersonaWebhookService.handleBusinessRegistrationsLookupEvent.bind(
    PersonaWebhookService
  );
export const handleBusinessVerificationTransactionEvent =
  PersonaWebhookService.handleBusinessVerificationTransactionEvent.bind(
    PersonaWebhookService
  );

// Export report data formatting functions
export const formatReportResult =
  PersonaReportService.formatReportResult.bind(PersonaReportService);
export const formatTransactionResult =
  PersonaReportService.formatTransactionResult.bind(PersonaReportService);
export const getAvailableReportTypes =
  PersonaReportService.getAvailableReportTypes.bind(PersonaReportService);

// Export the class for direct use
export const PersonaService = {
  createPersonaInquiry,
  savePersonaInquiry,
  getPersonaInquiry,
  updatePersonaInquiry,
  getVerificationStatus,
  createPersonaReport,
  getAllPersonaReports,
  getPersonaReportByReferenceId,
  getVerificationsByCarrierId,
  updatePersonaReport,
  verifyBusinessOwnerWithInquiry,
  getPersonaVerificationStatusDirect,
  formatReportResult,
  formatTransactionResult,
  getAvailableReportTypes,

  /**
   * Run multiple Persona reports
   * @param {Object} options - Options for running reports
   * @param {Object} options.inputData - Input data for the reports
   * @param {string} options.entityId - Entity ID
   * @param {string} options.entityType - Entity type
   * @param {string[]} options.reportTypes - Types of reports to run
   * @param {string} options.company_id - Company ID
   * @returns {Promise<Object>} Report results
   */
  async runMultiplePersonaReports(options) {
    try {
      const { inputData, entityId, entityType, reportTypes, company_id } = options;

      if (!reportTypes || reportTypes.length === 0) {
        throw new Error("No report types provided");
      }

      const personaProvider = new ProviderFactory().getPersonaProvider();

      // Run each report type in parallel
      const reportPromises = reportTypes.map(async (reportType) => {
        try {
          const reportData = {
            reportType,
            inputData,
            entityId,
            entityType,
            company_id, // Pass company_id to createReport
          };

          const result = await personaProvider.createReport(reportData);
          return {
            type: reportType,
            result,
          };
        } catch (error) {
          console.error(`Error running report type ${reportType}:`, error);
          return {
            type: reportType,
            error: error.message,
          };
        }
      });

      const results = await Promise.all(reportPromises);

      // Transform results into expected format
      const transformedResults = {};
      results.forEach(({ type, result, error }) => {
        if (error) {
          transformedResults[type] = {
            success: false,
            error,
          };
        } else {
          transformedResults[type] = {
            success: true,
            ...result,
          };
        }
      });

      return transformedResults;
    } catch (error) {
      console.error("Error running multiple Persona reports:", error);
      throw error;
    }
  },
};

export default PersonaService;
