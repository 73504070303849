// @ts-nocheck
const tableStyles = {
  table:
    "width: 100%; border-collapse: collapse; margin-bottom: 20px; background-color: #ffffff;",
  th: "text-align: left; padding: 12px; background-color: #f3f4f6; color: #374151; border: 1px solid #e5e7eb;",
  td: "padding: 12px; border: 1px solid #e5e7eb; color: #4b5563;",
  section: "margin-bottom: 30px;",
  sectionTitle:
    "color: #1a56db; font-size: 20px; margin-bottom: 15px; font-weight: bold;",
  alert: "padding: 12px; border-radius: 4px; margin-bottom: 20px;",
  alertWarning:
    "background-color: #fff3cd; border: 1px solid #ffeeba; color: #856404;",
  alertSuccess:
    "background-color: #d4edda; border: 1px solid #c3e6cb; color: #155724;",
};

const generateBasicInfoTable = (carrier) => `
  <div style="${tableStyles.section}">
    <h2 style="${tableStyles.sectionTitle}">Carrier Basic Information</h2>
    <table style="${tableStyles.table}">
      <tr>
        <th style="${tableStyles.th}">DOT Number</th>
        <td style="${tableStyles.td}">${carrier.dotNumber || "N/A"}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Legal Name</th>
        <td style="${tableStyles.td}">${carrier.legalName || "N/A"}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">DBA Name</th>
        <td style="${tableStyles.td}">${carrier.dbaName || "N/A"}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Physical Address</th>
        <td style="${tableStyles.td}">${carrier.phyStreet || ""} ${
  carrier.phyCity || ""
}, ${carrier.phyState || ""} ${carrier.phyZipcode || ""}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Operating Status</th>
        <td style="${tableStyles.td}">${
  carrier.allowedToOperate === "Y" ? "Authorized to Operate" : "Not Authorized"
}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Operation Type</th>
        <td style="${tableStyles.td}">${
  carrier.carrierOperation?.carrierOperationDesc || "N/A"
}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Entity Type</th>
        <td style="${tableStyles.td}">${
  carrier.censusTypeId?.censusTypeDesc || "N/A"
}</td>
      </tr>
    </table>
  </div>
`;

const generateSafetyRatingTable = (carrier) => `
  <div style="${tableStyles.section}">
    <h2 style="${tableStyles.sectionTitle}">Safety Rating Information</h2>
    <table style="${tableStyles.table}">
      <tr>
        <th style="${tableStyles.th}">Safety Rating</th>
        <td style="${tableStyles.td}">${carrier.safetyRating || "N/A"}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Rating Date</th>
        <td style="${tableStyles.td}">${carrier.safetyRatingDate || "N/A"}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Review Date</th>
        <td style="${tableStyles.td}">${carrier.reviewDate || "N/A"}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Review Type</th>
        <td style="${tableStyles.td}">${carrier.reviewType || "N/A"}</td>
      </tr>
    </table>
  </div>
`;

const generateInsuranceTable = (carrier) => `
  <div style="${tableStyles.section}">
    <h2 style="${tableStyles.sectionTitle}">Insurance Information</h2>
    <table style="${tableStyles.table}">
      <tr>
        <th style="${tableStyles.th}">BIPD Insurance Required</th>
        <td style="${tableStyles.td}">${
  carrier.bipdInsuranceRequired === "Y" ? "Yes" : "No"
}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">BIPD Insurance On File</th>
        <td style="${tableStyles.td}">$${Number(
  carrier.bipdInsuranceOnFile || 0
).toLocaleString()}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Required BIPD Amount</th>
        <td style="${tableStyles.td}">$${Number(
  carrier.bipdRequiredAmount || 0
).toLocaleString()}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Bond Insurance</th>
        <td style="${tableStyles.td}">$${Number(
  carrier.bondInsuranceOnFile || 0
).toLocaleString()}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Cargo Insurance</th>
        <td style="${tableStyles.td}">$${Number(
  carrier.cargoInsuranceOnFile || 0
).toLocaleString()}</td>
      </tr>
    </table>
  </div>
`;

const generateFleetStatsTable = (carrier) => `
  <div style="${tableStyles.section}">
    <h2 style="${tableStyles.sectionTitle}">Fleet Statistics</h2>
    <table style="${tableStyles.table}">
      <tr>
        <th style="${tableStyles.th}">Total Drivers</th>
        <td style="${tableStyles.td}">${Number(
  carrier.totalDrivers || 0
).toLocaleString()}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Total Power Units</th>
        <td style="${tableStyles.td}">${Number(
  carrier.totalPowerUnits || 0
).toLocaleString()}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Driver Inspections</th>
        <td style="${tableStyles.td}">${Number(
  carrier.driverInsp || 0
).toLocaleString()}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Vehicle Inspections</th>
        <td style="${tableStyles.td}">${Number(
  carrier.vehicleInsp || 0
).toLocaleString()}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Driver OOS Rate</th>
        <td style="${tableStyles.td}">${(carrier.driverOosRate || 0).toFixed(
  2
)}% (National Avg: ${carrier.driverOosRateNationalAverage || 0}%)</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Vehicle OOS Rate</th>
        <td style="${tableStyles.td}">${(carrier.vehicleOosRate || 0).toFixed(
  2
)}% (National Avg: ${carrier.vehicleOosRateNationalAverage || 0}%)</td>
      </tr>
    </table>
  </div>
`;

const generateCrashStatsTable = (carrier) => `
  <div style="${tableStyles.section}">
    <h2 style="${tableStyles.sectionTitle}">Crash Statistics</h2>
    <table style="${tableStyles.table}">
      <tr>
        <th style="${tableStyles.th}">Total Crashes</th>
        <td style="${tableStyles.td}">${Number(
  carrier.crashTotal || 0
).toLocaleString()}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Fatal Crashes</th>
        <td style="${tableStyles.td}">${Number(
  carrier.fatalCrash || 0
).toLocaleString()}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Injury Crashes</th>
        <td style="${tableStyles.td}">${Number(
  carrier.injCrash || 0
).toLocaleString()}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Towaway Crashes</th>
        <td style="${tableStyles.td}">${Number(
  carrier.towawayCrash || 0
).toLocaleString()}</td>
      </tr>
    </table>
  </div>
`;

const generateSafetyBasicsTable = (basics) => `
  <div style="${tableStyles.section}">
    <h2 style="${tableStyles.sectionTitle}">Safety BASIC Scores</h2>
    <table style="${tableStyles.table}">
      <thead>
        <tr>
          <th style="${tableStyles.th}">Category</th>
          <th style="${tableStyles.th}">Percentile</th>
          <th style="${tableStyles.th}">Measure</th>
        </tr>
      </thead>
      <tbody>
        ${basics
          .map(
            (item) => `
          <tr>
            <td style="${tableStyles.td}">${
              item.basic.basicsType.basicsShortDesc || "N/A"
            }</td>
            <td style="${tableStyles.td}">${
              item.basic.basicsPercentile || "N/A"
            }</td>
            <td style="${tableStyles.td}">${
              item.basic.measureValue || "N/A"
            }</td>
          </tr>
        `
          )
          .join("")}
      </tbody>
    </table>
  </div>
`;

const generateCargoTable = (cargo) => `
  <div style="${tableStyles.section}">
    <h2 style="${tableStyles.sectionTitle}">Cargo Carried</h2>
    <table style="${tableStyles.table}">
      <thead>
        <tr>
          <th style="${tableStyles.th}">Cargo Classification</th>
        </tr>
      </thead>
      <tbody>
        ${cargo
          .map(
            (item) => `
          <tr>
            <td style="${tableStyles.td}">${item.cargoClassDesc || "N/A"}</td>
          </tr>
        `
          )
          .join("")}
      </tbody>
    </table>
  </div>
`;

const generateOperationTable = (operations) => `
  <div style="${tableStyles.section}">
    <h2 style="${tableStyles.sectionTitle}">Operation Classification</h2>
    <table style="${tableStyles.table}">
      <thead>
        <tr>
          <th style="${tableStyles.th}">Operation Type</th>
        </tr>
      </thead>
      <tbody>
        ${operations
          .map(
            (op) => `
          <tr>
            <td style="${tableStyles.td}">${op.operationClassDesc || "N/A"}</td>
          </tr>
        `
          )
          .join("")}
      </tbody>
    </table>
  </div>
`;

const generateAuthorityTable = (authorities) => `
  <div style="${tableStyles.section}">
    <h2 style="${tableStyles.sectionTitle}">Authority Information</h2>
    <table style="${tableStyles.table}">
      <tr>
        <th style="${tableStyles.th}">Common Authority Status</th>
        <td style="${tableStyles.td}">${
  authorities[0]?.carrierAuthority?.commonAuthorityStatus || "N/A"
}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Contract Authority Status</th>
        <td style="${tableStyles.td}">${
  authorities[0]?.carrierAuthority?.contractAuthorityStatus || "N/A"
}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Broker Authority Status</th>
        <td style="${tableStyles.td}">${
  authorities[0]?.carrierAuthority?.brokerAuthorityStatus || "N/A"
}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Authorized For Property</th>
        <td style="${tableStyles.td}">${
  authorities[0]?.carrierAuthority?.authorizedForProperty === "Y" ? "Yes" : "No"
}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Authorized For Passenger</th>
        <td style="${tableStyles.td}">${
  authorities[0]?.carrierAuthority?.authorizedForPassenger === "Y"
    ? "Yes"
    : "No"
}</td>
      </tr>
      <tr>
        <th style="${tableStyles.th}">Authorized For Household Goods</th>
        <td style="${tableStyles.td}">${
  authorities[0]?.carrierAuthority?.authorizedForHouseholdGoods === "Y"
    ? "Yes"
    : "No"
}</td>
      </tr>
    </table>
  </div>
`;

const generateCallToAction = () => `
  <div style="
    margin: 40px 0;
    padding: 20px;
    background-color: #143560;
    border-radius: 12px;
    text-align: left;
  ">
    <div style="max-width: 600px; margin: 0 auto;">
      <table cellpadding="0" cellspacing="0" border="0" width="100%" style="width: 100%;">
        <tr>
          <td align="center" style="padding-bottom: 20px;">
            <h3 style="
              color: #ffffff;
              margin: 0;
              font-size: 24px;
              font-weight: 600;
            ">
              Upgrade Your Cargo Security Today
            </h3>
          </td>
        </tr>
        
        <tr>
          <td align="left" style="padding-bottom: 10px;">
            <div style="
              color: #ffffff;
              font-size: 18px;
              font-weight: 600;
              margin-bottom: 15px;
            ">
              Secure your cargo and improve your profits with Indemni
            </div>
            
            <div style="color: #ffffff; font-size: 16px; line-height: 1.8;">
              → Real-time driver verification<br>
              → Automated compliance and fraud monitoring<br>
              → Cargo Evidence Photo and Documentation Tracking<br>
              → Detailed shipments and supply chain analytics<br>
              → and more!
            </div>
            
            <div style="
              color: #ffffff;
              font-size: 16px;
              font-weight: 500;
              margin-top: 15px;
              text-align: center;
              font-weight: bold;
            ">
              Start your 30-day free trial today!
            </div>
          </td>
        </tr>
        
        <tr>
          <td align="center" style="padding: 25px 0;">
            <!--[if mso]>
            <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://calendly.com/your-scheduling-link" style="height:50px;v-text-anchor:middle;width:250px;" arcsize="10%" stroke="f" fillcolor="#ffffff">
              <w:anchorlock/>
              <center>
            <![endif]-->
            <a href="https://calendly.com/meet-indemni/30min?background_color=000000&text_color=FFFFFF&primary_color=119DA4" 
               style="
                 background-color: #ffffff;
                 border-radius: 8px;
                 color: #1a56db;
                 display: inline-block;
                 font-family: sans-serif;
                 font-size: 16px;
                 font-weight: bold;
                 line-height: 50px;
                 text-align: center;
                 text-decoration: none;
                 width: 250px;
                 -webkit-text-size-adjust: none;
               "
            >
              Schedule Your Demo →
            </a>
            <!--[if mso]>
              </center>
            </v:roundrect>
            <![endif]-->
          </td>
        </tr>
        
        <tr>
          <td align="center">
            <p style="
              color: #e2e8f0;
              margin: 0;
              font-size: 14px;
              font-style: italic;
            ">
              Reply to this email for immediate assistance
            </p>
          </td>
        </tr>
      </table>
    </div>
  </div>
`;

export const generateCarrierReport = (carrierData) => {
  const carrier = carrierData.carrierByDOT?.content?.carrier;

  return `
    <div style="font-family: Arial, sans-serif; max-width: 800px; margin: 0 auto; padding: 20px; background-color: #f8f9fa;">
      <div style="background-color: #ffffff; padding: 30px; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
        <div style="margin-bottom: 30px;">
          <p style="color: #4b5563; margin: 0 0 10px 0; font-size: 14px; text-align: center;">
            This report contains publicly available data from the Federal Motor Carrier Safety Administration (FMCSA).
          </p>
        </div>

        <h1 style="color: #1a56db; margin-bottom: 30px; text-align: center; font-size: 24px;">
        FMCSA Carrier Report
        </h1>

        ${
          carrier
            ? generateBasicInfoTable(carrier)
            : '<p style="color: #6b7280;">No carrier basic information available.</p>'
        }
        ${carrier ? generateSafetyRatingTable(carrier) : ""}
        ${carrier ? generateInsuranceTable(carrier) : ""}
        ${carrier ? generateFleetStatsTable(carrier) : ""}
        ${carrier ? generateCrashStatsTable(carrier) : ""}
        
        ${
          carrierData.carrierBasics?.content
            ? generateSafetyBasicsTable(carrierData.carrierBasics.content)
            : '<p style="color: #6b7280;">No safety basics available.</p>'
        }
        
        ${
          carrierData.carrierCargo?.content
            ? generateCargoTable(carrierData.carrierCargo.content)
            : '<p style="color: #6b7280;">No cargo information available.</p>'
        }
        
        ${
          carrierData.carrierOperation?.content
            ? generateOperationTable(carrierData.carrierOperation.content)
            : '<p style="color: #6b7280;">No operation classification available.</p>'
        }
        
        ${
          carrierData.carrierAuthority?.content
            ? generateAuthorityTable(carrierData.carrierAuthority.content)
            : '<p style="color: #6b7280;">No authority information available.</p>'
        }

        ${generateCallToAction()}

        <div style="margin-top: 30px; padding-top: 20px; border-top: 1px solid #e5e7eb; text-align: center;">
          <p style="color: #6b7280; font-size: 14px;">
            Report generated on ${new Date().toLocaleDateString()}. Data provided by FMCSA.
          </p>
        </div>
      </div>
    </div>
  `;
};
