// @ts-nocheck
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VetCarrierReportPresenter from "./VetCarrierReportPresenter";
import { useCarrierData } from "../../hooks/useCarrierData";
import {
  useCarrierDetails,
  useCarrierVerifications,
} from "../../hooks/useCarriers";
import { useBusinessOwners } from "../../hooks/useBusinessOwners";
import LoadingSpinner from "../Components/LoadingSpinner";
import Toast from "../Components/Toast";
import { supabase } from "../../lib/supabase";
import {
  getCarrierVerificationStatus,
  runCarrierReports,
  verificationServiceLegacyNotice,
  verifyCarrier,
} from "../../api/services/verification/VerificationService";
import { useQuery } from "@tanstack/react-query";
import { CarrierService } from "../../api/services/carrier/CarrierService";

const VetCarrierReportContainer = () => {
  const { id } = useParams();
  const { data: carrierDetails, isLoading: isCarrierLoading } =
    useCarrierDetails(id);
  const { data: verifications, isLoading: isVerificationsLoading } =
    useCarrierVerifications(id);
  const { refetchAll: refetchFmcsa } = useCarrierData(
    carrierDetails?.dot_number,
    {
      enabled: Boolean(carrierDetails?.dot_number),
    }
  );

  const navigate = useNavigate();

  // --- State for this container ---
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [carrierData, setCarrierData] = useState(null);
  const [carrierFmcsaData, setCarrierFmcsaData] = useState(null);
  const [carrierName, setCarrierName] = useState("");
  const [verificationDisplayData, setVerificationDisplayData] = useState({
    phoneRiskReports: [],
    emailRiskReports: [],
    businessReports: [],
    businessRegistrations: [],
    personaVerifications: [],
    insuranceVerifications: [],
    businessOwnerVerifications: [], // Added this array for business owner verifications
  });
  const [isLoadingContainer, setIsLoadingContainer] = useState(true);
  const [pageError, setPageError] = useState("");
  const dataFetchedRef = React.useRef(false);
  const lastCarrierIdRef = React.useRef(null);

  // --- Call the Business Owners Hook ---
  const {
    businessOwners: owners,
    verificationResults,
    isLoading: isLoadingOwners,
    error: errorOwners,
    refetchBusinessOwners,
  } = useBusinessOwners(
    id,
    verifications?.find((v) => v.verification_type === "business_registration")
  );

  const onVerifyBusiness = async () => {
    navigate(`/dashboard/vet-business-owner?carrier_id=${id}`);
  };

  const onVerifyBusinessDocumentation = async () => {
    navigate(`/dashboard/vet-business-documentation?carrier_id=${id}`);
  };

  const onVerifyInsuranceDocumentation = async () => {
    navigate(`/dashboard/vet-documents?carrier_id=${id}`);
  };

  // --- NEW: Risk Score Fetching with useQuery ---
  const {
    data: riskData, // Contains { riskScore, riskSignals }
    isLoading: isLoadingRisk, // Specific loading state for risk
    isError: isRiskError, // Specific error state for risk
    error: riskError, // Specific error object for risk
    refetch: refetchRiskScore, // Function to refetch risk score
  } = useQuery({
    queryKey: ["carrierRiskScore", id], // Unique key including carrier ID
    queryFn: () => CarrierService.fetchCarrierRiskData(id), // Use the imported service function
    enabled: !!id, // Only run query if ID exists
    staleTime: 5 * 60 * 1000, // Data fresh for 5 minutes
    gcTime: 10 * 60 * 1000, // Cache kept for 10 minutes
    retry: 1, // Retry once on failure
  });

  // --- Derive Risk Score and Signals from useQuery result ---
  const riskScore = riskData?.riskScore ?? 0; // Default to 0 if loading/error/no data
  const riskSignals = riskData?.riskSignals ?? []; // Default to empty array
  const riskErrorMessage = isRiskError
    ? riskError?.message || "Failed to calculate risk score."
    : null;

  // --- Main Data Fetching Logic for the Container ---
  const loadContainerData = useCallback(async () => {
    if (!id || !carrierDetails || isCarrierLoading) {
      setIsLoadingContainer(isCarrierLoading); // Reflect initial loading
      return;
    }

    setIsLoadingContainer(true);
    setPageError(""); // Clear previous errors

    try {
      // 1. Fetch FMCSA Context if available
      let fmcsaDataResult = null;
      if (carrierDetails.dot_number) {
        try {
          // console.log("DEBUG (Container): Fetching FMSCA data..."); // Removed
          fmcsaDataResult = await refetchFmcsa(carrierDetails.dot_number);
          setCarrierName(
            fmcsaDataResult?.carrier?.name || carrierDetails.name || "Unknown"
          );
          setCarrierFmcsaData(fmcsaDataResult?.fmcsaData);
        } catch (fmcsaError) {
          setCarrierName(carrierDetails.name || "Unknown"); // Fallback
          setCarrierFmcsaData(null);
        }
      } else {
        setCarrierName(carrierDetails.name || "Unknown");
        setCarrierFmcsaData(null);
      }

      // 2. Fetch verification records (including the 'results' field)
      const { data: verifications, error: verificationsError } = await supabase
        .from("verifications")
        .select("*")
        .eq("carrier_id", id)
        .order("created_at", { ascending: false });

      if (verificationsError) throw verificationsError;

      if (!verifications || verifications.length === 0) {
        setLoading(false);
        return; // No verifications to process
      }

      // 3. Process verifications into separate arrays
      let phoneReports = [];
      let emailReports = [];
      let businessReports = [];
      let businessRegistrations = [];
      let personaIdVerifications = [];
      let insuranceVerifications = [];
      let businessOwnerVerifications = []; // Added this array for business owner verifications

      verifications.forEach((verification) => {
        // The verification object itself now contains the necessary details in its 'results' field
        switch (verification.verification_type) {
          case "phone_risk_report":
            phoneReports.push(verification);
            break;
          case "email_risk_report":
            emailReports.push(verification);
            break;
          case "business_report": // Check exact type name if different
            businessReports.push(verification);
            break;
          case "business_registration": // Fixed: changed from business_registrations_lookup to match actual type
            businessRegistrations.push(verification);
            break;
          case "id_verification":
            personaIdVerifications.push(verification);
            break;
          case "insurance_verification":
            insuranceVerifications.push(verification);
            break;
          default:
            break;
        }

        // Add to businessOwnerVerifications if relevant IDs are present and complete
        if (verification.business_owner_id || verification.persona_inquiry_id) {
          if (verification.persona_verification_complete) {
            // Avoid duplicates
            if (
              !businessOwnerVerifications.some(
                (bov) => bov.id === verification.id
              )
            ) {
              // Keep this log: Shows which verifications are successfully linked
              console.log(
                `DEBUG (Container): Adding verification to businessOwnerVerifications: ID=${verification.id}, OwnerID=${verification.business_owner_id}, InquiryID=${verification.persona_inquiry_id}`
              );
              businessOwnerVerifications.push(verification);
            }
          } else {
            // Keep this log: Shows potential links that are incomplete
            console.log(
              `DEBUG (Container): Found incomplete potential business owner verification: ${verification.id} (Type: ${verification.verification_type})`
            );
          }
        }
      });

      // ---> DEBUG: Log the final categorized businessOwnerVerifications array // Keep this log
      console.log(
        "DEBUG (Container): Final businessOwnerVerifications array BEFORE state update:",
        businessOwnerVerifications
      );

      // ---> DEBUG: Log categorized phone reports // Removed
      // console.log( // Removed
      //   "DEBUG (Container): Categorized phone reports:", // Removed
      //   phoneReports // Removed
      // ); // Removed

      // 4. Update the state with the arrays of full verification objects
      // console.log("Fetched & Processed phone reports:", phoneReports); // Removed
      // console.log("Fetched & Processed email reports:", emailReports); // Removed
      // console.log("Fetched & Processed business reports:", businessReports); // Removed
      // console.log( // Removed
      //   "Fetched & Processed business registrations:", // Removed
      //   businessRegistrations // Removed
      // ); // Removed
      // console.log( // Removed
      //   "Fetched & Processed ID verifications:", // Removed
      //   personaIdVerifications // Removed
      // ); // Removed
      // console.log( // Removed
      //   "Fetched & Processed insurance verifications:", // Removed
      //   insuranceVerifications // Removed
      // ); // Removed

      // Debug insurance verification data // Removed
      // if (insuranceVerifications.length > 0) { // Removed
      //   console.log("DEBUG (Container): Found insurance verifications:"); // Removed
      //   insuranceVerifications.forEach((iv, index) => { // Removed
      //     console.log( // Removed
      //       `DEBUG (Container): Insurance verification #${index + 1}:`, // Removed
      //       { // Removed
      //         id: iv.id, // Removed
      //         verification_type: iv.verification_type, // Removed
      //         state: iv.state, // Removed
      //         created_at: iv.created_at, // Removed
      //         results: iv.results ? "Has results data" : "No results data", // Removed
      //       } // Removed
      //     ); // Removed
      //   }); // Removed
      // } else { // Removed
      //   console.log("DEBUG (Container): No insurance verifications found"); // Removed
      // } // Removed

      setVerificationDisplayData({
        phoneRiskReports: phoneReports,
        emailRiskReports: emailReports,
        businessReports: businessReports,
        businessRegistrations: businessRegistrations,
        personaVerifications: personaIdVerifications, // Keep separate for now
        insuranceVerifications: insuranceVerifications, // Changed from insuranceReports to insuranceVerifications
        businessOwnerVerifications: businessOwnerVerifications, // Added this array for business owner verifications
      });

      // Log verification display data after update // Removed
      // console.log("DEBUG (Container): verificationDisplayData state UPDATED:", { // Removed
      //   phoneRiskCount: phoneReports.length, // Removed
      //   emailRiskCount: emailReports.length, // Removed
      //   businessReportCount: businessReports.length, // Removed
      //   businessRegistrationCount: businessRegistrations.length, // Removed
      //   personaVerificationCount: personaIdVerifications.length, // Removed
      //   insuranceVerificationCount: insuranceVerifications.length, // Removed
      //   businessOwnerVerificationCount: businessOwnerVerifications.length, // Removed
      // }); // Removed

      // console.log("DEBUG (Container): Core data loading finished."); // Removed

      // Mark this carrier as fetched
      lastCarrierIdRef.current = id;
      dataFetchedRef.current = true;
    } catch (error) {
      // console.error("DEBUG (Container): Error loading container data:", error); // Removed
      setPageError(`Failed to load report data: ${error.message}`);
    } finally {
      setIsLoadingContainer(false);
    }
  }, [id, carrierDetails, isCarrierLoading, refetchFmcsa, verifications]);

  // --- Effects ---

  // Trigger main data load
  useEffect(() => {
    // Check if we need to fetch again
    const needToFetch =
      !dataFetchedRef.current || lastCarrierIdRef.current !== id;

    if (needToFetch) {
      // console.log("DEBUG (Container): Initiating data load for carrier:", id); // Removed
      loadContainerData();
    } else {
      // console.log("DEBUG (Container): Using cached data for carrier:", id); // Removed
    }
  }, [id, loadContainerData]);

  // ---> DEBUG: Log verificationDisplayData state whenever it changes // Removed
  // useEffect(() => { // Removed
  //   console.log( // Removed
  //     "DEBUG (Container): verificationDisplayData state UPDATED:", // Removed
  //     verificationDisplayData // Removed
  //   ); // Removed
  // }, [verificationDisplayData]); // Removed

  // Combine loading states
  const combinedLoading =
    isLoadingContainer || isCarrierLoading || isLoadingOwners || isLoadingRisk;

  // Combine error states
  const combinedError = pageError || errorOwners || riskErrorMessage;

  // Function to refresh everything
  const refreshAllData = useCallback(() => {
    // console.log("DEBUG (Container): Refreshing all data..."); // Removed
    // Reset fetch tracking
    dataFetchedRef.current = false;
    loadContainerData(); // Reload container data (which includes parsing details)
    refetchBusinessOwners(); // Explicitly refetch owners via the hook
    refetchRiskScore(); // Refetch risk score
  }, [loadContainerData, refetchBusinessOwners, refetchRiskScore]);

  // --- Render Presenter ---
  return (
    <VetCarrierReportPresenter
      loading={combinedLoading}
      error={combinedError}
      carrierData={carrierFmcsaData}
      businessOwners={owners}
      carrierName={carrierName}
      onVerifyBusiness={onVerifyBusiness}
      onVerifyBusinessDocumentation={onVerifyBusinessDocumentation}
      onVerifyInsuranceDocumentation={onVerifyInsuranceDocumentation}
      riskScore={riskScore}
      riskSignals={riskSignals}
      businessOwnerVerifications={
        verificationDisplayData.businessOwnerVerifications
      }
      phoneRiskReports={verificationDisplayData.phoneRiskReports}
      emailRiskReports={verificationDisplayData.emailRiskReports}
      businessReports={verificationDisplayData.businessReports}
      businessRegistrations={verificationDisplayData.businessRegistrations}
      personaVerifications={verificationDisplayData.personaVerifications}
      insuranceVerifications={verificationDisplayData.insuranceVerifications}
      refreshData={refreshAllData}
    />
  );
};

export default VetCarrierReportContainer;
