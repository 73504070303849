// @ts-nocheck
import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { FiEdit2, FiSave, FiX, FiPlus, FiCheck } from "react-icons/fi";
import { cn } from "@/lib/utils";

// Fallback for cn function in case the import fails
const safeCn = (...inputs) => {
  try {
    return cn(...inputs);
  } catch (error) {
    // Simple fallback that joins classnames (doesn't handle conflicts)
    return inputs.filter(Boolean).join(" ");
  }
};

const EditableField = ({ label, value, onSave, type = "text", className }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value || []);
  const [newTag, setNewTag] = useState("");

  useEffect(() => {
    setTempValue(value || []);
  }, [value]);

  const handleSave = () => {
    onSave(tempValue);
    setIsEditing(false);
  };

  const handleAddTag = () => {
    if (!newTag.trim()) return;
    const updatedTags = [...tempValue, { name: newTag.trim() }];
    setTempValue(updatedTags);
    setNewTag("");
  };

  const handleRemoveTag = (indexToRemove) => {
    setTempValue(tempValue.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className={safeCn("space-y-1.5", className)}>
      <div className="flex items-center justify-between mb-1">
        <label className="text-sm font-medium text-muted-foreground">
          {label}
        </label>
      </div>

      {isEditing ? (
        <div className="space-y-2">
          {type === "tags" ? (
            <div className="space-y-3">
              <div className="flex flex-wrap gap-1.5">
                {tempValue.map((tag, index) => (
                  <Badge
                    key={index}
                    variant="secondary"
                    className="px-2 py-1 flex items-center gap-1 text-xs"
                  >
                    {tag.name}
                    <button
                      onClick={() => handleRemoveTag(index)}
                      className="ml-1 hover:text-destructive focus:outline-none"
                    >
                      <FiX className="w-3 h-3" />
                    </button>
                  </Badge>
                ))}
              </div>
              <div className="flex gap-2">
                <Input
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  placeholder="Add new tag"
                  className="flex-1"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddTag();
                    }
                  }}
                />
                <Button size="sm" variant="outline" onClick={handleAddTag}>
                  <FiPlus className="w-4 h-4" />
                </Button>
              </div>
            </div>
          ) : type === "textarea" ? (
            <Textarea
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
              className="min-h-[80px] w-full"
            />
          ) : (
            <Input
              type={type}
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
              className="w-full"
            />
          )}
          <div className="flex gap-2 mt-3">
            <Button
              size="sm"
              variant="default"
              onClick={handleSave}
              className="h-8"
            >
              <FiCheck className="w-4 h-4 mr-1" /> Save
            </Button>
            <Button
              size="sm"
              variant="outline"
              onClick={() => setIsEditing(false)}
              className="h-8"
            >
              <FiX className="w-4 h-4 mr-1" /> Cancel
            </Button>
          </div>
        </div>
      ) : type === "tags" ? (
        <div className="group flex items-center">
          <div className="flex-grow py-1.5">
            <div className="flex flex-wrap gap-1.5">
              {Array.isArray(value) && value.length > 0 ? (
                value.map((tag, index) => (
                  <Badge
                    key={index}
                    variant="secondary"
                    className="px-2 py-0.5 text-xs"
                  >
                    {tag.name}
                  </Badge>
                ))
              ) : (
                <span className="text-sm text-muted-foreground italic">
                  No tags available
                </span>
              )}
            </div>
          </div>
          <Button
            size="sm"
            variant="ghost"
            onClick={() => setIsEditing(true)}
            className="h-8 w-8 p-0 ml-2 opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <FiEdit2 className="w-4 h-4" />
          </Button>
        </div>
      ) : (
        <div className="group flex items-center">
          <div className="flex-grow py-1.5 px-3 border border-transparent rounded-md -ml-3 hover:bg-muted/30 transition-colors duration-200">
            <span className="text-sm text-foreground">
              {value || (
                <span className="text-muted-foreground italic">
                  Not specified
                </span>
              )}
            </span>
          </div>
          <Button
            size="sm"
            variant="ghost"
            onClick={() => setIsEditing(true)}
            className="h-8 w-8 p-0 ml-2 opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <FiEdit2 className="w-4 h-4" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default EditableField;
