import { supabase } from "../../../lib/supabase";
import { CommonVerificationService } from "./CommonVerificationService";
import { VerificationRepository } from "../../repositories/VerificationRepository";
import { personaProvider } from "../../providers/PersonaProvider";
import CarrierRepository from "../../repositories/CarrierRepository";
import { sendInsuranceVerificationSMS } from "../core/NotificationService";

// Create singleton instances
const verificationRepository = new VerificationRepository(supabase);
const carrierRepository = new CarrierRepository({ supabase });

/**
 * Service for insurance verification-related operations
 */
export const InsuranceVerificationService = {
  /**
   * Creates an insurance documentation verification record
   *
   * @param {Object} data - Insurance verification data
   * @param {string} data.carrier_id - Carrier ID
   * @param {string} data.email - Contact email
   * @param {string} data.phoneNumber - Contact phone number
   * @param {string} data.companyId - Company ID
   * @param {string} data.insuranceType - Type of insurance being verified
   * @returns {Promise<Object>} Created verification record
   */
  async createInsuranceDocumentationVerification(data) {
    try {
      console.log(
        "🔍 [createInsuranceDocumentationVerification] Starting with data:",
        {
          carrier_id: data.carrier_id,
          email: data.email,
          insuranceType: data.insuranceType,
        }
      );

      console.log(
        "🔍 [createInsuranceDocumentationVerification] Calling PersonaProvider.createInquiry with:",
        {
          dataArg: {
            entityType: "carrier",
            entityId: data.carrier_id,
            email: data.email,
            phoneNumber: data.phoneNumber,
            companyId: data.companyId,
            carrier_id: data.carrier_id,
          },
          verificationMethodArg: "document_verification",
          templateIdArg: "insurance-document",
        }
      );

      // 1. Create Persona inquiry - Pass arguments correctly
      const { referenceId, inquiryId } = await personaProvider.createInquiry(
        // 1st Arg: data object
        {
          entityType: "carrier", // Pass entityType
          entityId: data.carrier_id, // Pass entityId (maps to carrier_id internally in provider)
          email: data.email,
          phoneNumber: data.phoneNumber,
          companyId: data.companyId,
          carrier_id: data.carrier_id, // Include carrier_id if provider logic relies on it directly
        },
        // 2nd Arg: verificationMethod string
        "document_verification",
        // 3rd Arg: templateId string
        "insurance-document"
      );

      console.log(
        "✅ [createInsuranceDocumentationVerification] Persona inquiry created:",
        {
          inquiryId,
          referenceId,
        }
      );

      // 2. Create verification record using VerificationRepository
      const verificationData = {
        phone_number: data.phoneNumber,
        carrier_id: data.carrier_id,
        persona_inquiry_id: inquiryId,
        persona_verification_complete: false,
        state: "created",
        verification_type: "insurance_verification",
        results: {
          insurance_type: data.insuranceType,
        },
      };

      const verification = await verificationRepository.create(
        verificationData
      );

      console.log(
        "✅ [createInsuranceDocumentationVerification] Verification record created:",
        {
          id: verification.id,
        }
      );

      // Send SMS notification
      try {
        console.log(
          "✉️ [createInsuranceDocumentationVerification] Sending insurance verification SMS to:",
          verification.phone_number
        );
        await sendInsuranceVerificationSMS(verification);
        console.log(
          "✅ [createInsuranceDocumentationVerification] Insurance verification SMS sent successfully."
        );
      } catch (notificationError) {
        console.error(
          "❌ [createInsuranceDocumentationVerification] Failed to send insurance verification SMS:",
          notificationError
        );
        // Decide if failure to notify should be a critical error or just logged
      }

      return verification;
    } catch (error) {
      console.error(
        "❌ [createInsuranceDocumentationVerification] Error:",
        error
      );
      throw error;
    }
  },

  /**
   * Update insurance verification record based on document verification results
   *
   * @param {string} inquiryId - Persona inquiry ID
   * @param {Object} verificationResult - Result from Persona
   * @param {string} carrierId - ID of the carrier
   * @returns {Promise<Object>} Updated verification record
   */
  async updateInsuranceVerification(inquiryId, verificationResult, carrierId) {
    try {
      console.log(`Updating insurance verification for inquiry: ${inquiryId}`);

      // Find verification by persona inquiry ID
      const verification = await verificationRepository.findByPersonaInquiryId(
        inquiryId
      );

      if (!verification) {
        console.error(
          `No verification found for persona inquiry ID: ${inquiryId}`
        );
        throw new Error(`Verification not found for inquiry ID: ${inquiryId}`);
      }

      console.log(`Found verification record: ${verification.id}`);

      // Parse and prepare the verification status
      const isPassed = verificationResult.status === "passed";
      const statusMap = {
        passed: "Approved",
        failed: "Declined",
        expired: "Expired",
        "waiting-for-approval": "Started",
        reviewing: "Started",
        "waiting-for-data": "Started",
        default: "Started",
      };

      const state = statusMap[verificationResult.status] || statusMap.default;

      // Update the verification record
      const updatedVerification = await verificationRepository.update(
        verification.id,
        {
          state: state,
          persona_verification_complete: isPassed,
          results: {
            status: state,
            passed: isPassed,
            personaData: verificationResult,
            lastUpdated: new Date().toISOString(),
          },
        }
      );

      console.log(
        `Updated verification: ${updatedVerification.id} to ${state}`
      );

      // If verification is passed and carrier ID is provided, update the carrier's insurance status
      if (isPassed && carrierId) {
        try {
          console.log(
            `Updating carrier ${carrierId} insurance verification status to Verified`
          );
          await carrierRepository.update(carrierId, {
            insurance_verification_status: "Verified",
            has_verified_insurance: true,
          });
          console.log(
            `Carrier ${carrierId} insurance status updated successfully`
          );
        } catch (carrierUpdateError) {
          console.error(
            `Error updating carrier insurance verification status: ${carrierUpdateError.message}`
          );
          // Continue with the current function, but log the error
        }
      }

      return updatedVerification;
    } catch (error) {
      console.error(`Error updating insurance verification: ${error.message}`);
      throw error;
    }
  },

  /**
   * Checks insurance verification status
   *
   * @param {string} persona_inquiry_id - Persona inquiry ID
   * @returns {Promise<Object>} Status information
   */
  async checkInsuranceVerificationStatus(persona_inquiry_id) {
    return CommonVerificationService.checkVerificationStatus(
      persona_inquiry_id
    );
  },
};
