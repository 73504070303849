// @ts-check
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CarrierService } from "../api/services/carrier/CarrierService";

/**
 * Hook to fetch the list of carriers
 * @returns {Object} The query result containing carrier list
 */
export const useCarrierList = () => {
  return useQuery({
    queryKey: ["carriers"],
    queryFn: () => CarrierService.getAllCarrierDetails(),
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to fetch carrier details by ID
 * @param {string} id - The carrier ID to fetch
 * @returns {Object} The query result containing carrier details
 */
export const useCarrierDetails = (id) => {
  return useQuery({
    queryKey: ["carriers", id],
    queryFn: () => CarrierService.getCarrierDetails(id),
    enabled: !!id,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to fetch verifications for a specific carrier
 * @param {string} carrierId - The carrier ID to fetch verifications for
 * @param {object} options - Optional TanStack Query options
 * @returns {Object} The query result containing verifications
 */
export const useCarrierVerifications = (carrierId, options = {}) => {
  return useQuery({
    queryKey: ["verifications", "carrier", carrierId],
    queryFn: () => CarrierService.getCarrierVerifications(carrierId),
    enabled: !!carrierId, // Only run query if carrierId is provided
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000, // 30 minutes
    ...options,
  });
};

/**
 * Hook to create a new carrier
 * @returns {Object} The mutation object for creating a carrier
 */
export const useCreateCarrier = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data) => {
      console.log("Creating carrier in hook with data:", data);
      // Ensure data is an object before proceeding
      const carrierData = typeof data === 'object' && data !== null ? data : {};
      try {
        // Normalize the data - convert empty strings to null for db consistency
        const normalizedData = { ...carrierData }; // Use spread on carrierData
        Object.keys(normalizedData).forEach((key) => {
          if (normalizedData[key] === "") {
            normalizedData[key] = null;
          }
        });

        console.log(
          "Normalized data for insertion:",
          JSON.stringify(normalizedData, null, 2)
        );

        const result = await CarrierService.insertCarrier(normalizedData);
        console.log("Carrier created successfully:", result);
        return result;
      } catch (error) {
        console.error("Error in useCreateCarrier:", error);
        console.error("Error details:", JSON.stringify(error, null, 2));

        // Enhance error with additional information
        if (error?.code === "23505") {
          // PostgreSQL unique violation code
          const detail = error?.detail || "";
          const message = error?.message || "";

          console.log("Constraint violation detected:", detail);

          // Extract field name from constraint info if possible
          let field = "";

          // Check if constraint name is available in the error
          const constraintMatch = error?.constraint
            ? error.constraint.match(/_(.*?)_/)
            : null;

          if (constraintMatch && constraintMatch[1]) {
            field = constraintMatch[1];
            console.log("Extracted field from constraint name:", field);
          }
          // If not found in constraint name, try detail message
          else {
            const keyMatch = detail.match(/Key \(([^)]+)\)/i);
            if (keyMatch && keyMatch[1]) {
              field = keyMatch[1].split(",")[0].trim();
              console.log("Extracted field from error detail:", field);
            }
            // Fallback to simple string matching
            else if (detail.includes("email")) field = "email";
            else if (detail.includes("phone_number")) field = "phone_number";
            else if (detail.includes("dot_number")) field = "dot_number";
            else if (detail.includes("mc_number")) field = "mc_number";
            else if (detail.includes("tax_id")) field = "tax_id";
          }

          // For tax_id specifically, check if the user provided one
          if (field === "tax_id" && !carrierData.tax_id) { // Check carrierData here
            // This is likely a null tax_id conflict with another null tax_id
            // So let's change the field to another identifier
            field = "carrier_identity"; // Changed from tax_id to avoid confusion
            console.log(
              "Tax ID is null but got tax_id conflict, changing field to:",
              field
            );
          }

          console.log("Final identified conflicting field:", field);

          // Attach additional context to the error
          error.status = 409; // Conflict
          error.field = field;
        }

        throw error;
      }
    },
    onSuccess: (data) => {
      console.log("Mutation successful, invalidating queries", data);
      queryClient.invalidateQueries({ queryKey: ["carriers"] });
    },
    onError: (error) => {
      console.error("Mutation error in onError handler:", error);
    },
  });

  // Return the mutation object with both mutate and mutateAsync available
  return {
    mutate: mutation.mutate,
    mutateAsync: mutation.mutateAsync,
    ...mutation,
  };
};

/**
 * Hook to fetch carrier data by DOT number
 * @param {string|number} dotNumber - The DOT number to search for
 * @returns {Object} The query result containing carrier data
 */
export const useGetCarrierByDOT = (dotNumber) => {
  return useQuery({
    queryKey: ["carriers", "dot", dotNumber],
    queryFn: () => {
      if (!dotNumber) return null;
      return CarrierService.getCarrierByDOT(dotNumber);
    },
    enabled: !!dotNumber,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook providing all carrier-related mutations
 * @returns {Object} Mutation functions for carrier operations
 */
export const useCarrierMutations = () => {
  const queryClient = useQueryClient();

  /**
   * Helper to invalidate carrier queries
   * @param {string} [id] - Optional carrier ID to invalidate specific queries
   */
  const invalidateCarriers = async (id) => {
    // Use Promise.all for concurrent invalidation
    const promises = [queryClient.invalidateQueries({ queryKey: ["carriers"] })];
    if (id) {
      promises.push(queryClient.invalidateQueries({ queryKey: ["carriers", id] }));
      // Also invalidate the verifications query for this carrier
      promises.push(queryClient.invalidateQueries({ queryKey: ["verifications", "carrier", id] }));
    }
    await Promise.all(promises);
  };

  const deleteCarrier = useMutation({
    mutationFn: CarrierService.deleteCarrier,
    onSuccess: (_, id) => invalidateCarriers(id), // Pass id correctly
    onError: (error) => {
      console.error("Error deleting carrier:", error);
    },
  });

  const toggleCarrierActiveStatus = useMutation({
    mutationFn: CarrierService.toggleCarrierActiveStatus,
    onSuccess: (_, { id }) => invalidateCarriers(id),
    onError: (error) => {
      console.error("Error toggling carrier status:", error);
    },
  });

  const insertCarrier = useMutation({
    mutationFn: CarrierService.insertCarrier,
    onSuccess: (newCarrier) => invalidateCarriers(newCarrier?.id), // Invalidate specific carrier if possible
    onError: (error) => {
      console.error("Error inserting carrier:", error);
    },
  });

  const updateCarrier = useMutation({
    mutationFn: CarrierService.updateCarrier,
    onSuccess: (_, { id }) => invalidateCarriers(id),
    onError: (error) => {
      console.error("Error updating carrier:", error);
    },
  });

  return {
    deleteCarrier: deleteCarrier.mutateAsync,
    toggleCarrierActiveStatus: toggleCarrierActiveStatus.mutateAsync,
    insertCarrier: insertCarrier.mutateAsync,
    updateCarrier: updateCarrier.mutateAsync,
  };
};

/**
 * Hook to search carriers by name or email
 * @param {string} searchTerm - The search term to use
 * @returns {Object} The query result containing matching carriers
 */
export const useCarrierSearch = (searchTerm) => {
  return useQuery({
    queryKey: ["carriers", "search", searchTerm],
    queryFn: () => CarrierService.searchCarriers(searchTerm),
    enabled: !!searchTerm && searchTerm.length > 2, // Example: enable only if searchTerm is long enough
    staleTime: 1 * 60 * 1000, // 1 minute
    gcTime: 15 * 60 * 1000, // 15 minutes
  });
};

/**
 * Hook to bulk insert carrier records
 * @returns {Object} The mutation object for bulk insert
 */
export const useBulkInsertCarriers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: CarrierService.bulkInsertCarriers,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["carriers"] });
    },
    onError: (error) => {
      console.error("Error bulk inserting carriers:", error);
    },
  });
};
