// @ts-nocheck
import React, { useMemo } from "react";
import { useShipments } from "../../hooks/shipments";
import { Card, CardContent } from "../Components/Card";
import { Loader2Icon } from "lucide-react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Toast from "../Components/Toast";
import { useParams } from "react-router-dom";

// Update color palette to match Indemni brand
const COLORS = ["#143560", "#119DA4", "#143560", "#119DA4"];

// Enhanced chart customization constants
const CHART_CONFIG = {
  fontSize: 13,
  fontFamily: "Inter, sans-serif",
  padding: { top: 20, right: 30, bottom: 20, left: 30 },
  text: {
    fill: "#374151", // More readable text color
    fontWeight: 500,
  },
};

const Analytics = () => {
  const { id: cargoOwnerId } = useParams();
  const {
    data: shipments,
    isLoading,
    error,
  } = useShipments(1, 1000, {
    orderBy: "created_at",
    orderDirection: "desc",
    cargoOwnerId,
  });

  // Prepare data for charts
  const analytics = useMemo(() => {
    if (!shipments?.shipments) return null;

    // Status distribution
    const statusCount = shipments.shipments.reduce((acc, shipment) => {
      acc[shipment.status] = (acc[shipment.status] || 0) + 1;
      return acc;
    }, {});

    const statusData = Object.entries(statusCount).map(([name, value]) => ({
      name,
      value,
    }));

    // Monthly trends
    const monthlyData = shipments.shipments.reduce((acc, shipment) => {
      const month = new Date(shipment.created_at).toLocaleString("default", {
        month: "short",
      });
      if (!acc[month]) {
        acc[month] = { month, total: 0, completed: 0, in_transit: 0 };
      }
      acc[month].total += 1;
      acc[month][shipment.status] = (acc[month][shipment.status] || 0) + 1;
      return acc;
    }, {});

    return {
      statusDistribution: statusData,
      monthlyTrends: Object.values(monthlyData),
    };
  }, [shipments]);

  if (error) {
    return <Toast type="error" message={error.message} />;
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Status Distribution */}
        <Card className="bg-white dark:bg-slate-800 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-200 border-none">
          <CardContent className="p-6">
            <h2 className="text-lg font-semibold mb-4 text-slate-800 dark:text-white">
              Shipment Status Distribution
            </h2>
            {isLoading ? (
              <div className="flex justify-center py-8">
                <Loader2Icon className="w-8 h-8 animate-spin text-blue-500" />
              </div>
            ) : (
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={analytics?.statusDistribution}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, percent }) =>
                        `${name} (${(percent * 100).toFixed(0)}%)`
                      }
                      outerRadius={100}
                      innerRadius={60}
                      fill="#8884d8"
                      dataKey="value"
                      paddingAngle={3}
                    >
                      {analytics?.statusDistribution.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px solid #E5E7EB",
                        boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)",
                        color: "#374151",
                      }}
                    />
                    <Legend
                      verticalAlign="bottom"
                      height={36}
                      formatter={(value) => (
                        <span
                          style={{
                            color: "#374151",
                            fontSize: "13px",
                            fontWeight: 500,
                          }}
                        >
                          {value}
                        </span>
                      )}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            )}
          </CardContent>
        </Card>

        {/* Monthly Trends */}
        <Card className="bg-white dark:bg-slate-800 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-200 border-none">
          <CardContent className="p-6">
            <h2 className="text-lg font-semibold mb-4 text-slate-800 dark:text-white">
              Monthly Shipment Trends
            </h2>
            {isLoading ? (
              <div className="flex justify-center py-8">
                <Loader2Icon className="w-8 h-8 animate-spin text-blue-500" />
              </div>
            ) : (
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={[...analytics?.monthlyTrends].reverse()}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                    <XAxis
                      dataKey="month"
                      tick={{
                        ...CHART_CONFIG.text,
                        fontSize: CHART_CONFIG.fontSize,
                      }}
                      axisLine={{ stroke: "#E5E7EB" }}
                    />
                    <YAxis
                      tick={{
                        ...CHART_CONFIG.text,
                        fontSize: CHART_CONFIG.fontSize,
                      }}
                      axisLine={{ stroke: "#E5E7EB" }}
                      allowDecimals={false}
                    />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px solid #E5E7EB",
                        boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)",
                        color: "#374151",
                      }}
                    />
                    <Bar
                      dataKey="total"
                      fill={COLORS[0]}
                      radius={[4, 4, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </CardContent>
        </Card>

        {/* Key Metrics Summary */}
        <Card className="bg-white dark:bg-slate-800 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-200 border-none w-full lg:col-span-2">
          <CardContent className="p-6">
            <h2 className="text-lg font-semibold mb-4 text-slate-800 dark:text-white">
              Key Metrics
            </h2>
            {isLoading ? (
              <div className="flex justify-center py-8">
                <Loader2Icon className="w-8 h-8 animate-spin text-blue-500" />
              </div>
            ) : (
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-white dark:bg-slate-700 p-6 rounded-xl border border-gray-100 dark:border-slate-600 shadow-md hover:shadow-lg transition-all duration-200">
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                      Completion Rate
                    </p>
                    <p className="text-3xl font-semibold text-gray-900 dark:text-white">
                      {analytics?.statusDistribution.find(
                        (s) => s.name === "completed"
                      )?.value || 0}
                      %
                    </p>
                  </div>
                  <div className="bg-white dark:bg-slate-700 p-6 rounded-xl border border-gray-100 dark:border-slate-600 shadow-md hover:shadow-lg transition-all duration-200">
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                      Average Transit Time
                    </p>
                    <p className="text-3xl font-semibold text-gray-900 dark:text-white">
                      3.2 days
                    </p>
                  </div>
                  <div className="bg-white dark:bg-slate-700 p-6 rounded-xl border border-gray-100 dark:border-slate-600 shadow-md hover:shadow-lg transition-all duration-200">
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                      On-Time Delivery
                    </p>
                    <p className="text-3xl font-semibold text-gray-900 dark:text-white">
                      94%
                    </p>
                  </div>
                  <div className="bg-white dark:bg-slate-700 p-6 rounded-xl border border-gray-100 dark:border-slate-600 shadow-md hover:shadow-lg transition-all duration-200">
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
                      Active Shipments
                    </p>
                    <p className="text-3xl font-semibold text-gray-900 dark:text-white">
                      {analytics?.statusDistribution.find(
                        (s) => s.name === "in_transit"
                      )?.value || 0}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Analytics;
