// src/api/Repositories/FMSCARepository.js
import { supabase } from "../../lib/supabase"; // Ensure you have this import

class FMCSARepository {
  constructor(config = {}) {
    this.supabase = config.supabase || supabase;
    this.tableName = "carrier_verification_details";
  }

  async create(data) {
    try {
      const { data: record, error } = await this.supabase
        .from(this.tableName)
        .insert(data)
        .select()
        .single();

      if (error) throw error;
      return record;
    } catch (error) {
      console.error("Error creating FMCSA verification record:", error);
      throw error;
    }
  }

  async findById(id) {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select("*")
        .eq("id", id)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error finding FMCSA verification record:", error);
      throw error;
    }
  }

  async findByVerificationId(verificationId) {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select("*")
        .eq("verification_id", verificationId)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error(
        "Error finding FMCSA verification by verification ID:",
        error
      );
      throw error;
    }
  }

  async findByDOTNumber(dotNumber) {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select("*")
        .eq("dot_number", dotNumber)
        .order("created_at", { ascending: false })
        .limit(1)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error finding FMCSA verification by DOT number:", error);
      throw error;
    }
  }

  async update(id, data) {
    try {
      // Add updated_at timestamp
      const updateData = {
        ...data,
        updated_at: new Date(),
      };

      const { data: record, error } = await this.supabase
        .from(this.tableName)
        .update(updateData)
        .eq("id", id)
        .select()
        .single();

      if (error) throw error;
      return record;
    } catch (error) {
      console.error("Error updating FMCSA verification record:", error);
      throw error;
    }
  }
}

export default FMCSARepository;
