import React, { useState, useEffect } from "react";
import { formatDate } from "./utils";
import PersonaVerificationData from "./PersonaVerificationData";
import VerificationBadge from "../VerificationBadge";

/**
 * Component for displaying business owner verification status
 * @param {Object} props
 * @param {Object} props.verificationResult - The verification result data
 * @param {Object} props.verificationDetails - Structured verification details extracted from Persona API
 */
const BusinessOwnerVerificationStatus = ({
  verificationResult,
  verificationDetails,
}) => {
  const [selectedVerification, setSelectedVerification] = useState(null); // For single verification view
  const [showVerificationsList, setShowVerificationsList] = useState(false); // Show list of verifications

  useEffect(() => {
    // Debug logs to trace data flow
    console.log(
      "BusinessOwnerVerificationStatus - verificationResult:",
      verificationResult
    );

    // Log the new structured verification details
    if (verificationDetails) {
      console.log(
        "BusinessOwnerVerificationStatus - extracted verification details:",
        verificationDetails
      );
    }
  }, [verificationResult, verificationDetails]);

  if (!verificationResult) return null;

  const {
    passed,
    nameMatch,
    inquiryPassed,
    inquiryId,
    businessOwnerId,
    inquiryData,
    matchDetails,
    includedResources = {},
    rawRelationships,
    referenceId,
    fields,
    status,
    selfiePhotos = [],
    documentPhotos = [],

    // New fields from Persona API
    nameFirst,
    nameLast,
    nameMiddle,
    emailAddress,
    phoneNumber,
    addressStreet1,
    addressStreet2,
    addressCity,
    addressSubdivision,
    addressSubdivisionAbbr,
    addressPostalCode,
    addressPostalCodeAbbr,
    addressCountryCode,
    identificationNumber,
    identificationClass,
    selectedIdClass,
    identificationNumbers,
    businessName,
    association,
    kybCaseId,
    countryCode,
    selectedCountryCode,
    createdAt,
    updatedAt,
    startedAt,
    completedAt,
    expiresAt,
    expiredAt,
    failedAt,
    decisionedAt,
    markedForReviewAt,
    nextStepName,
    previousStepName,
    templateId,
    templateVersion,
    behaviors,
    birthdate,
  } = verificationResult;

  // Use let for inquiryStatus since we might need to update it
  let { inquiryStatus } = verificationResult;

  // Utility function to recursively search for objects with a specific type in a nested structure
  const findObjectsByType = (obj, type) => {
    if (!obj) return [];

    // If it's an array, look through each item
    if (Array.isArray(obj)) {
      return obj.flatMap((item) => findObjectsByType(item, type));
    }

    // If it's an object, check if it matches our criteria
    if (typeof obj === "object") {
      // Check if this object is what we're looking for
      if (obj.type && obj.type.includes(type)) {
        return [obj];
      }

      // Look through all properties of this object
      return Object.values(obj).flatMap((value) =>
        findObjectsByType(value, type)
      );
    }

    // Not what we're looking for
    return [];
  };

  // Extract verification resources from all possible locations
  const rawData = verificationResult.rawData || {};
  const rawIncludedResources = rawData.included || [];
  const dataIncluded = rawData.data?.included || [];

  // Use the utility function to find all verifications
  const allVerifications = [
    ...findObjectsByType(verificationResult, "verification"),
    ...(includedResources.verifications || []),
    ...(rawIncludedResources.filter(
      (resource) => resource.type && resource.type.includes("verification")
    ) || []),
    ...(dataIncluded.filter(
      (resource) => resource.type && resource.type.includes("verification")
    ) || []),
  ];

  // Remove duplicates using Map
  const uniqueVerificationsMap = new Map();
  allVerifications.forEach((v) => {
    if (v && v.id) {
      uniqueVerificationsMap.set(v.id, v);
    }
  });
  const uniqueVerifications = Array.from(uniqueVerificationsMap.values());

  // Categorize verifications
  const governmentIdVerifications = uniqueVerifications.filter(
    (v) => v.type && v.type.includes("government-id")
  );

  const selfieVerifications = uniqueVerifications.filter(
    (v) => v.type && v.type.includes("selfie")
  );

  // Get all sessions
  const allSessions = [
    ...findObjectsByType(verificationResult, "session"),
    ...(includedResources.sessions || []),
  ];

  // Remove duplicates
  const uniqueSessionsMap = new Map();
  allSessions.forEach((s) => {
    if (s && s.id) {
      uniqueSessionsMap.set(s.id, s);
    }
  });
  const uniqueSessions = Array.from(uniqueSessionsMap.values());

  // Check if any verification is complete - this will override the status
  const hasCompleteVerification = uniqueVerifications.some(
    (v) => v.attributes && v.attributes.status === "completed"
  );

  // Update status logic - if any verification is complete, show as complete
  if (hasCompleteVerification) {
    inquiryStatus = "completed";
  }

  // Determine status color and text
  let statusColor = "gray";
  let statusText = "Unknown";

  // First check our new verification structure
  if (
    verificationResult.state === "persona_complete" &&
    verificationResult.persona_verification_complete
  ) {
    statusColor = "green";
    statusText = "Verified";
  } else if (verificationResult.state === "persona_pending") {
    statusColor = "orange";
    statusText = "Pending";
  } else if (verificationResult.state === "persona_failed") {
    statusColor = "red";
    statusText = "Failed";
  }
  // Fall back to the original logic
  else if (passed && inquiryPassed) {
    statusColor = "green";
    statusText = "Verified";
  } else if (inquiryStatus === "completed" && !passed) {
    statusColor = "red";
    statusText = "Failed";
  } else if (inquiryStatus === "pending" || inquiryStatus === "processing") {
    statusColor = "orange";
    statusText = "Pending";
  } else if (inquiryStatus === "created") {
    statusColor = "blue";
    statusText = "Created";
  } else if (inquiryStatus === "expired" || inquiryStatus === "abandoned") {
    statusColor = "red";
    statusText = "Expired";
  }

  // NEW: If any verification is complete, update the status
  if (hasCompleteVerification) {
    statusColor = "green";
    statusText = "Complete";
  }

  // Use the status from the verification result if inquiryStatus is not available
  if (!inquiryStatus && status) {
    inquiryStatus = status;
    if (status === "created") {
      statusColor = "blue";
      statusText = "Created";
    }
  }

  // Extract name information
  const firstName =
    verificationResult.nameFirst ||
    includedResources.account?.fields?.name?.value?.first?.value ||
    fields?.["name-first"]?.value;
  const lastName =
    verificationResult.nameLast ||
    includedResources.account?.fields?.name?.value?.last?.value ||
    fields?.["name-last"]?.value;
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : "ID Verification";

  // Handler functions
  const viewVerificationsList = () => {
    setShowVerificationsList(true);
    setSelectedVerification(null);
  };

  const viewVerificationDetails = (verification) => {
    setSelectedVerification(verification);
    setShowVerificationsList(false);
  };

  const backToVerificationsList = () => {
    setSelectedVerification(null);
    setShowVerificationsList(true);
  };

  const backToOwnerDetails = () => {
    setShowVerificationsList(false);
    setSelectedVerification(null);
  };

  // Function to render the processed verification details
  const renderProcessedVerificationDetails = () => {
    if (!verificationDetails) return null;

    const { verifications, passedCount, failedCount } = verificationDetails;

    return (
      <div className="mt-4 bg-gray-50 p-4 rounded-lg">
        <h3 className="font-medium text-lg mb-2">Verification Summary</h3>

        <div className="flex space-x-4 mb-3">
          <div className={`px-3 py-1 rounded-full bg-green-100 text-green-800`}>
            {passedCount} passed
          </div>
          {failedCount > 0 && (
            <div className={`px-3 py-1 rounded-full bg-red-100 text-red-800`}>
              {failedCount} failed
            </div>
          )}
        </div>

        {verifications && verifications.length > 0 && (
          <div className="mt-3">
            <h4 className="font-medium text-md mb-2">Verification Details</h4>
            <div className="space-y-2">
              {verifications.map((v, i) => (
                <div key={i} className="border-b pb-2">
                  <div className="flex justify-between">
                    <span className="font-medium">{v.type}</span>
                    <span
                      className={`px-2 py-1 rounded-full ${
                        v.status === "passed"
                          ? "bg-green-100 text-green-800"
                          : v.status === "failed"
                          ? "bg-red-100 text-red-800"
                          : "bg-gray-100 text-gray-800"
                      }`}
                    >
                      {v.status}
                    </span>
                  </div>

                  {v.failedChecks && v.failedChecks.length > 0 && (
                    <div className="mt-1">
                      <span className="text-sm text-red-600">
                        Failed checks:
                      </span>
                      <ul className="text-sm list-disc list-inside pl-2">
                        {v.failedChecks.map((check, j) => (
                          <li key={j}>
                            {check.replace(/_/g, " ").toUpperCase()}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  // Build a map of all verification checks and their statuses
  const buildChecksMap = (verification) => {
    if (
      !verification ||
      !verification.attributes ||
      !verification.attributes.checks
    ) {
      return {};
    }

    const checksMap = {};
    verification.attributes.checks.forEach((check) => {
      checksMap[check.name] = check.status;
    });
    return checksMap;
  };

  // Function to render status badge
  const renderStatusBadge = (status) => {
    if (!status)
      return (
        <span className="px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-800">
          N/A
        </span>
      );

    // Convert not_applicable to N/A
    if (status === "not_applicable") {
      return (
        <span className="px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-800">
          N/A
        </span>
      );
    }

    let color = "gray";
    if (status === "passed" || status === "completed") color = "green";
    else if (status === "failed") color = "red";
    else if (status === "pending" || status === "processing") color = "yellow";

    // Capitalize first letter of status
    const displayStatus = status.charAt(0).toUpperCase() + status.slice(1);

    return (
      <span
        className={`px-2 py-1 text-xs font-medium rounded-full bg-${color}-100 text-${color}-800`}
      >
        {displayStatus}
      </span>
    );
  };

  // Render verification type badge
  const renderVerificationTypeBadge = (type) => {
    let color = "gray";
    let text = "Unknown";

    if (type && type.includes("government-id")) {
      color = "blue";
      text = "ID Verification";
    } else if (type && type.includes("selfie")) {
      color = "purple";
      text = "Selfie Verification";
    }

    return (
      <span
        className={`px-2 py-1 text-xs font-medium rounded-full bg-${color}-100 text-${color}-800`}
      >
        {text}
      </span>
    );
  };

  // Function to render location info
  const renderLocation = (latitude, longitude) => {
    if (!latitude || !longitude) return null;

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

    return (
      <div className="mt-2">
        <div className="bg-gray-50 p-3 rounded-lg">
          <div className="grid grid-cols-2 gap-2">
            <div className="text-xs text-gray-600">Latitude:</div>
            <div className="text-xs font-medium">{latitude}</div>
            <div className="text-xs text-gray-600">Longitude:</div>
            <div className="text-xs font-medium">{longitude}</div>
          </div>
          <div className="mt-2 text-center">
            <a
              href={googleMapsUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
            >
              <svg
                className="w-4 h-4 mr-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
              </svg>
              View on Google Maps
            </a>
          </div>
        </div>
      </div>
    );
  };

  // Render a single verification details
  const renderVerificationDetails = (verification) => {
    if (!verification) return null;

    const attr = verification.attributes || {};
    const checksMap = buildChecksMap(verification);
    const isGovernmentId =
      verification.type && verification.type.includes("government-id");
    const isSelfie = verification.type && verification.type.includes("selfie");

    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <button
            onClick={backToVerificationsList}
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <svg
              className="w-4 h-4 mr-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            Back to Verifications
          </button>
          <div>{renderVerificationTypeBadge(verification.type)}</div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-4">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Verification Details
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <h4 className="text-sm font-medium text-gray-700 mb-2">
                Basic Information
              </h4>
              <div className="bg-gray-50 p-3 rounded-lg">
                <div className="grid grid-cols-2 gap-2">
                  <div className="text-xs text-gray-600">ID:</div>
                  <div className="text-xs font-medium text-gray-900">
                    {verification.id}
                  </div>
                  <div className="text-xs text-gray-600">Status:</div>
                  <div>{renderStatusBadge(attr.status)}</div>
                  <div className="text-xs text-gray-600">Created:</div>
                  <div className="text-xs text-gray-900">
                    {attr["created-at"]
                      ? formatDate(attr["created-at"])
                      : "N/A"}
                  </div>
                  <div className="text-xs text-gray-600">Updated:</div>
                  <div className="text-xs text-gray-900">
                    {attr["updated-at"]
                      ? formatDate(attr["updated-at"])
                      : "N/A"}
                  </div>
                </div>
              </div>
            </div>

            {isGovernmentId && (
              <div>
                <h4 className="text-sm font-medium text-gray-700 mb-2">
                  ID Information
                </h4>
                <div className="bg-gray-50 p-3 rounded-lg">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="text-xs text-gray-600">ID Type:</div>
                    <div className="text-xs font-medium text-gray-900">
                      {attr["id-class"] || "Unknown"}
                    </div>
                    <div className="text-xs text-gray-600">ID Number:</div>
                    <div className="text-xs font-medium text-gray-900">
                      {attr["identification-number"] || "N/A"}
                    </div>
                    <div className="text-xs text-gray-600">Country:</div>
                    <div className="text-xs font-medium text-gray-900">
                      {attr["issuing-country"] || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isSelfie && (
              <div>
                <h4 className="text-sm font-medium text-gray-700 mb-2">
                  Selfie Information
                </h4>
                <div className="bg-gray-50 p-3 rounded-lg">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="text-xs text-gray-600">
                      Left Similarity Score:
                    </div>
                    <div className="text-xs font-medium text-gray-900">
                      {attr["selfie-similarity-score-left"] || "N/A"}
                    </div>
                    <div className="text-xs text-gray-600">
                      Right Similarity Score:
                    </div>
                    <div className="text-xs font-medium text-gray-900">
                      {attr["selfie-similarity-score-right"] || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Verification Checks */}
          <div className="mb-6">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Verification Checks
            </h4>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Check
                  </th>
                  <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Object.entries(checksMap).length > 0 ? (
                  Object.entries(checksMap).map(([check, status], index) => (
                    <tr key={index}>
                      <td className="px-3 py-2 whitespace-nowrap text-xs text-gray-600">
                        {check.replace(/_/g, " ").toUpperCase()}
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        {renderStatusBadge(status)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={2}
                      className="px-3 py-2 text-center text-xs text-gray-500"
                    >
                      No checks available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Location Map */}
          {attr.latitude && attr.longitude && (
            <div className="mb-6">
              <h4 className="text-sm font-medium text-gray-700 mb-2">
                Location Map
              </h4>
              {renderLocation(attr.latitude, attr.longitude)}
            </div>
          )}
        </div>
      </div>
    );
  };

  // Render list of all verifications
  const renderVerificationsList = () => {
    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <button
            onClick={backToOwnerDetails}
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <svg
              className="w-4 h-4 mr-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            Back to Owner Details
          </button>
          <h3 className="text-lg font-medium text-gray-900">
            All Verifications for {fullName}
          </h3>
        </div>

        {/* ID Verifications */}
        {governmentIdVerifications.length > 0 && (
          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="bg-blue-50 px-4 py-2 border-b border-blue-100">
              <h3 className="text-blue-800 font-medium">ID Verifications</h3>
            </div>
            <div className="divide-y divide-gray-200">
              {governmentIdVerifications.map((verification, index) => {
                const attr = verification.attributes || {};
                return (
                  <div
                    key={index}
                    className="px-4 py-3 hover:bg-gray-50 cursor-pointer"
                    onClick={() => viewVerificationDetails(verification)}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {attr["id-class"] || "Government ID"}
                          {attr["identification-number"] &&
                            ` - ${attr["identification-number"]}`}
                        </div>
                        <div className="text-xs text-gray-500">
                          Created:{" "}
                          {attr["created-at"]
                            ? formatDate(attr["created-at"])
                            : "N/A"}
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        {renderStatusBadge(attr.status)}
                        <svg
                          className="w-4 h-4 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* Selfie Verifications */}
        {selfieVerifications.length > 0 && (
          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="bg-purple-50 px-4 py-2 border-b border-purple-100">
              <h3 className="text-purple-800 font-medium">
                Selfie Verifications
              </h3>
            </div>
            <div className="divide-y divide-gray-200">
              {selfieVerifications.map((verification, index) => {
                const attr = verification.attributes || {};
                return (
                  <div
                    key={index}
                    className="px-4 py-3 hover:bg-gray-50 cursor-pointer"
                    onClick={() => viewVerificationDetails(verification)}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          Selfie Verification
                        </div>
                        <div className="text-xs text-gray-500">
                          Created:{" "}
                          {attr["created-at"]
                            ? formatDate(attr["created-at"])
                            : "N/A"}
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        {renderStatusBadge(attr.status)}
                        <svg
                          className="w-4 h-4 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* Sessions */}
        {uniqueSessions.length > 0 && (
          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="bg-green-50 px-4 py-2 border-b border-green-100">
              <h3 className="text-green-800 font-medium">Sessions</h3>
            </div>
            <div className="divide-y divide-gray-200">
              {uniqueSessions.map((session, index) => {
                const attr = session.attributes || {};
                return (
                  <div
                    key={index}
                    className="px-4 py-3 hover:bg-gray-50 cursor-pointer"
                    onClick={() => viewVerificationDetails(session)}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          Session {index + 1}
                        </div>
                        <div className="text-xs text-gray-500">
                          IP: {attr["ip-address"] || "N/A"}
                          {attr["device-type"] &&
                            ` • Device: ${attr["device-type"]}`}
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        {renderStatusBadge(attr.status)}
                        <svg
                          className="w-4 h-4 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {governmentIdVerifications.length === 0 &&
          selfieVerifications.length === 0 &&
          uniqueSessions.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              No verifications found.
            </div>
          )}
      </div>
    );
  };

  if (selectedVerification) {
    return renderVerificationDetails(selectedVerification);
  }

  if (showVerificationsList) {
    return renderVerificationsList();
  }

  return (
    <div className="space-y-6">
      {/* Status Header */}
      <div className={`rounded-lg shadow-md overflow-hidden`}>
        {/* Status Bar */}
        <div
          className={`h-2 ${
            statusColor === "green"
              ? "bg-green-500"
              : statusColor === "red"
              ? "bg-red-500"
              : statusColor === "yellow"
              ? "bg-orange-500"
              : statusColor === "blue"
              ? "bg-blue-500"
              : "bg-gray-500"
          }`}
        ></div>

        {/* Content */}
        <div className="bg-white p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              {/* Status Icon */}
              <div
                className={`flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center ${
                  statusColor === "green"
                    ? "bg-green-100 text-green-600"
                    : statusColor === "red"
                    ? "bg-red-100 text-red-600"
                    : statusColor === "yellow"
                    ? "bg-orange-100 text-orange-600"
                    : statusColor === "blue"
                    ? "bg-blue-100 text-blue-600"
                    : "bg-gray-100 text-gray-600"
                }`}
              >
                {statusColor === "green" && (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                )}
                {statusColor === "red" && (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
                {statusColor === "yellow" && (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
                {statusColor === "blue" && (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
                {(statusColor === "gray" ||
                  !["green", "red", "yellow", "blue"].includes(
                    statusColor
                  )) && (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
              </div>

              {/* Name and Status */}
              <div>
                <h3 className="text-lg font-semibold text-gray-800">
                  {fullName}
                </h3>
                <div className="flex items-center mt-1">
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      statusColor === "green"
                        ? "bg-green-100 text-green-800"
                        : statusColor === "red"
                        ? "bg-red-100 text-red-800"
                        : statusColor === "yellow"
                        ? "bg-orange-100 text-orange-800"
                        : statusColor === "blue"
                        ? "bg-blue-100 text-blue-800"
                        : "bg-gray-100 text-gray-800"
                    }`}
                  >
                    {statusText}
                  </span>

                  {referenceId && (
                    <>
                      <span className="mx-2 text-gray-300">•</span>
                      <span className="text-xs text-gray-500">
                        Ref: {referenceId}
                      </span>
                    </>
                  )}

                  {inquiryId && (
                    <>
                      <span className="mx-2 text-gray-300">•</span>
                      <span className="text-xs text-gray-500">
                        ID: {inquiryId.substring(0, 8)}...
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex items-center space-x-3">
              <button
                onClick={viewVerificationsList}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition"
              >
                <svg
                  className="w-4 h-4 mr-1.5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                  />
                </svg>
                View verifications
              </button>
            </div>
          </div>

          {/* Show the new processed verification details if available */}
          {verificationDetails && renderProcessedVerificationDetails()}

          {/* Additional Info Section */}
          {(emailAddress || phoneNumber || createdAt) && (
            <div className="mt-4 pt-4 border-t border-gray-200 grid grid-cols-1 md:grid-cols-3 gap-3">
              {emailAddress && (
                <div className="flex items-center text-sm text-gray-500">
                  <svg
                    className="w-4 h-4 mr-1.5 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  {emailAddress}
                </div>
              )}
              {phoneNumber && (
                <div className="flex items-center text-sm text-gray-500">
                  <svg
                    className="w-4 h-4 mr-1.5 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  {phoneNumber}
                </div>
              )}
              {createdAt && (
                <div className="flex items-center text-sm text-gray-500">
                  <svg
                    className="w-4 h-4 mr-1.5 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                  Verification created: {formatDate(createdAt)}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessOwnerVerificationStatus;
