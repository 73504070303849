import * as React from "react";
import { Card } from "@/components/ui/card";
import { Building2, CheckCircle, XCircle, Factory } from "lucide-react";

/**
 * StatCard - A compact card displaying a single statistic with an icon
 */
function StatCard({ title, value, icon: Icon, description, className = "" }) {
  return (
    <Card className={`p-4 relative ${className}`}>
      <div className="mb-2 flex justify-between items-center">
        <div className="text-sm font-medium">{title}</div>
        {Icon && <Icon className="h-5 w-5 text-gray-500" aria-hidden="true" />}
      </div>
      <div className="text-3xl font-bold mb-1">{value}</div>
      {description && <p className="text-xs text-gray-500">{description}</p>}
    </Card>
  );
}

/**
 * CargoOwnerStats - Displays summary statistics for cargo owners
 *
 * @param {Object} props - Component props
 * @param {Array} props.cargoOwners - Array of cargo owner data
 */
export function CargoOwnerStats({ cargoOwners = [] }) {
  // Calculate statistics based on cargo owner data
  const stats = React.useMemo(() => {
    const total = cargoOwners.length;
    const active = cargoOwners.filter((c) => c.is_active).length;
    const inactive = cargoOwners.filter((c) => !c.is_active).length;

    // Count unique industries
    const uniqueIndustries = new Set();
    cargoOwners.forEach((owner) => {
      if (owner.industry) {
        uniqueIndustries.add(owner.industry);
      }
    });

    return {
      total,
      active,
      inactive,
      industries: uniqueIndustries.size,
    };
  }, [cargoOwners]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      <StatCard
        title="Total Cargo Owners"
        value={stats.total}
        icon={Building2}
        description="All cargo owners"
        className="border-l-2 border-l-slate-400"
      />
      <StatCard
        title="Active"
        value={stats.active}
        icon={CheckCircle}
        description="Currently active"
        className="border-l-2 border-l-emerald-200"
      />
      <StatCard
        title="Inactive"
        value={stats.inactive}
        icon={XCircle}
        description="Currently inactive"
        className="border-l-2 border-l-amber-200"
      />
      <StatCard
        title="Industries"
        value={stats.industries}
        icon={Factory}
        description="Unique industries"
        className="border-l-2 border-l-[#119DA4]"
      />
    </div>
  );
}
