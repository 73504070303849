// src/api/Providers/FMCSAProvider.js
const FMSCAService = require("../services/carrier/FMSCA");
const { ProviderError } = require("./ErrorHandler");

class FMCSAProvider {
  constructor(config = {}) {
    this.fmcsaRepository = config.fmscaRepository || null;
    this.fmcsaService = config.fmscaService || FMSCAService;

    if (!this.fmscaRepository) {
      console.warn("FMSCAProvider initialized without fmscaRepository");
    }
  }

  async verifyCarrierByDOT(verificationId, dotNumber) {
    try {
      if (!this.fmcsaRepository) {
        throw new ProviderError(
          "FMCSARepository is required for verifyCarrierByDOT",
          "fmcsa_repository_missing"
        );
      }

      // Step 1: Get basic carrier information
      const carrierData = await this.fmcsaService.getCarrierByDOT(dotNumber);

      if (!carrierData || !carrierData.content) {
        throw new ProviderError(
          `No carrier found with DOT number ${dotNumber}`,
          "fmcsa_carrier_not_found"
        );
      }

      // Step 2: Get additional carrier details in parallel
      const [basics, cargoCarried, opClass, oosInfo, authority] =
        await Promise.all([
          this.fmcsaService.getCarrierBasics(dotNumber),
          this.fmcsaService.getCarrierCargoCarried(dotNumber),
          this.fmcsaService.getCarrierOperationClassification(dotNumber),
          this.fmcsaService.getCarrierOOS(dotNumber),
          this.fmcsaService.getCarrierAuthority(dotNumber),
        ]);

      // Step 3: Create FMCSA verification record
      const verificationDetail = await this.fmcsaRepository.create({
        verification_id: verificationId,
        dot_number: dotNumber,
        legal_name: carrierData.content.legalName,
        dba_name: carrierData.content.dbaName,
        carrier_operation: carrierData.content.carrierOperation,
        hm_flag: carrierData.content.hasHazmatFlag === "Y",
        pc_flag: carrierData.content.hasPassengerFlag === "Y",
        physical_address: this.formatAddress(
          carrierData.content.phyStreet,
          carrierData.content.phyCity,
          carrierData.content.phyState,
          carrierData.content.phyZipcode
        ),
        mailing_address: this.formatAddress(
          carrierData.content.mailingStreet,
          carrierData.content.mailingCity,
          carrierData.content.mailingState,
          carrierData.content.mailingZipcode
        ),
        phone: carrierData.content.phoneNumber,
        authority_status:
          authority?.content?.commonAuthority?.activeStatus || null,
        authority_date:
          authority?.content?.commonAuthority?.applicationDate || null,
        insurance_required: this.formatInsuranceRequirements(authority),
        insurance_on_file: this.formatInsuranceOnFile(authority),
        ooc_status: oosInfo?.content || null,
        safety_rating: carrierData.content.safetyRating,
        safety_review_date: carrierData.content.reviewDate,
        basic_scores: basics?.content || null,
        cargo_carried: cargoCarried?.content || null,
        operation_classification: opClass?.content || null,
        raw_carrier_data: {
          basic: carrierData,
          basics,
          cargoCarried,
          opClass,
          oosInfo,
          authority,
        },
      });

      return {
        success: true,
        verificationDetail,
        carrierInfo: {
          dotNumber,
          legalName: carrierData.content.legalName,
          dbaName: carrierData.content.dbaName,
          authorityStatus:
            authority?.content?.commonAuthority?.activeStatus || null,
          safetyRating: carrierData.content.safetyRating,
        },
      };
    } catch (error) {
      console.error("FMCSA verification error:", error);
      throw new ProviderError(
        `Failed to verify carrier: ${error.message}`,
        "fmcsa_verification_failed",
        { originalError: error }
      );
    }
  }

  async verifyCarrierByName(verificationId, carrierName) {
    try {
      if (!this.fmcsaRepository) {
        throw new ProviderError(
          "FMCSARepository is required for verifyCarrierByName",
          "fmcsa_repository_missing"
        );
      }

      // Step 1: Search for carriers by name
      const searchResults = await this.fmcsaService.getCarrierByName(
        carrierName
      );

      if (
        !searchResults ||
        !searchResults.content ||
        searchResults.content.length === 0
      ) {
        throw new ProviderError(
          `No carriers found matching name "${carrierName}"`,
          "fmcsa_carrier_not_found"
        );
      }

      // Return the search results without creating a verification record yet
      // The strategy will handle choosing the correct carrier and then call verifyCarrierByDOT
      return {
        success: true,
        carriers: searchResults.content.map((carrier) => ({
          dotNumber: carrier.dotNumber,
          legalName: carrier.legalName,
          dbaName: carrier.dbaName,
          state: carrier.state,
          mailingCity: carrier.mailingCity,
        })),
      };
    } catch (error) {
      console.error("FMCSA carrier search error:", error);
      throw new ProviderError(
        `Failed to search for carrier: ${error.message}`,
        "fmcsa_search_failed",
        { originalError: error }
      );
    }
  }

  // Helper methods
  formatAddress(street, city, state, zipcode) {
    return { street, city, state, zipcode };
  }

  formatInsuranceRequirements(authorityData) {
    if (!authorityData?.content?.commonAuthority?.insuranceRequirements) {
      return null;
    }

    return authorityData.content.commonAuthority.insuranceRequirements;
  }

  formatInsuranceOnFile(authorityData) {
    if (!authorityData?.content?.commonAuthority?.insuranceOnFile) {
      return null;
    }

    return authorityData.content.commonAuthority.insuranceOnFile;
  }
}

module.exports = FMCSAProvider;
