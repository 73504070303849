import { CarrierService } from "../services/carrier/CarrierService";

/**
 * Repository for handling carrier data operations
 * Follows the repository pattern while utilizing the existing CarrierService
 */
class CarrierRepository {
  constructor(dependencies = {}) {
    this.carrierService = dependencies.carrierService || CarrierService;
  }

  /**
   * Find a carrier by ID
   *
   * @param {number|string} id - Carrier ID to find
   * @returns {Promise<Object>} Carrier data
   */
  async findById(id) {
    try {
      return await this.carrierService.getCarrierDetails(id);
    } catch (error) {
      console.error(
        `[CarrierRepository] Error finding carrier by ID ${id}:`,
        error
      );
      throw error;
    }
  }

  /**
   * Find a carrier by DOT number
   *
   * @param {string} dotNumber - DOT number to search for
   * @returns {Promise<Object>} Carrier data
   */
  async findByDOT(dotNumber) {
    try {
      return await this.carrierService.getCarrierByDOT(dotNumber);
    } catch (error) {
      console.error(
        `[CarrierRepository] Error finding carrier by DOT ${dotNumber}:`,
        error
      );
      throw error;
    }
  }

  /**
   * Get all carriers (optionally filtered by active status)
   *
   * @param {boolean} activeOnly - Whether to return only active carriers
   * @returns {Promise<Array<Object>>} List of carriers
   */
  async findAll(activeOnly = true) {
    try {
      return await this.carrierService.getCarrierList(activeOnly);
    } catch (error) {
      console.error(`[CarrierRepository] Error getting carrier list:`, error);
      throw error;
    }
  }

  /**
   * Create a new carrier
   *
   * @param {Object} carrierData - Carrier data to insert
   * @returns {Promise<Object>} Created carrier
   */
  async create(carrierData) {
    try {
      return await this.carrierService.insertCarrier(carrierData);
    } catch (error) {
      console.error(`[CarrierRepository] Error creating carrier:`, error);
      throw error;
    }
  }

  /**
   * Update a carrier
   *
   * @param {number|string} id - Carrier ID
   * @param {Object} updateData - Fields to update
   * @returns {Promise<Object>} Updated carrier record
   */
  async update(id, updateData) {
    try {
      const updatedCarrier = await this.carrierService.updateCarrier({
        id,
        updatedFields: updateData,
      });
      return { data: updatedCarrier, error: null };
    } catch (error) {
      console.error(`[CarrierRepository] Error updating carrier ${id}:`, error);
      return { data: null, error };
    }
  }

  /**
   * Delete a carrier
   *
   * @param {number|string} id - Carrier ID to delete
   * @returns {Promise<void>}
   */
  async delete(id) {
    try {
      await this.carrierService.deleteCarrier(id);
    } catch (error) {
      console.error(`[CarrierRepository] Error deleting carrier ${id}:`, error);
      throw error;
    }
  }

  /**
   * Search for carriers by name or email
   *
   * @param {string} searchTerm - Search term
   * @returns {Promise<Array<Object>>} Matching carriers
   */
  async search(searchTerm) {
    try {
      return await this.carrierService.searchCarriers(searchTerm);
    } catch (error) {
      console.error(`[CarrierRepository] Error searching carriers:`, error);
      throw error;
    }
  }

  /**
   * Toggle the active status of a carrier
   *
   * @param {Object} params - Parameters
   * @param {number|string} params.id - Carrier ID
   * @param {boolean} params.isActive - Current active status
   * @returns {Promise<Object>} Updated carrier
   */
  async toggleActiveStatus({ id, isActive }) {
    try {
      return await this.carrierService.toggleCarrierActiveStatus({
        id,
        isActive,
      });
    } catch (error) {
      console.error(
        `[CarrierRepository] Error toggling carrier status:`,
        error
      );
      throw error;
    }
  }

  /**
   * Update verification information for a carrier
   *
   * @param {number|string} id - Carrier ID
   * @param {Object} verificationData - Verification data to update
   * @returns {Promise<Object>} Result with data and error properties
   */
  async updateVerificationInfo(id, verificationData) {
    try {
      const updatedCarrier = await this.carrierService.updateCarrier({
        id,
        updatedFields: {
          verification_status:
            verificationData.verification_status || "pending",
          verification_reference: verificationData.verification_reference,
          verification_result: verificationData.verification_result || {},
          last_verification_attempt: new Date().toISOString(),
        },
      });
      return { data: updatedCarrier, error: null };
    } catch (error) {
      console.error(
        `[CarrierRepository] Error updating verification info:`,
        error
      );
      return { data: null, error };
    }
  }
}

export default CarrierRepository;
