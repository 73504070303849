import * as React from "react";
import { CargoOwnersDataTable } from "./CargoOwnersDataTable";
import { CargoOwnerStats } from "./CargoOwnerStats";
import { Button } from "@/components/ui/button";
import { Download, Plus, Users } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useCargoOwnerList } from "../../hooks/useCargoOwner";
import { Link } from "react-router-dom";

/**
 * CargoOwnersDashboard - Main container for the cargo owners page
 */
export function CargoOwnersDashboard() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeView, setActiveView] = React.useState("all");
  const [page, setPage] = React.useState(1);
  const [limit] = React.useState(10); // Fixed limit of 10 items per page

  // Fetch cargo owners using the useCargoOwnerList hook
  const {
    data: cargoOwnersData,
    isLoading: cargoOwnersLoading,
    error,
  } = useCargoOwnerList();

  // Extract the cargo owners from the data
  const cargoOwners = React.useMemo(() => {
    return cargoOwnersData || [];
  }, [cargoOwnersData]);

  // Filter cargo owners based on active view
  const filteredCargoOwners = React.useMemo(() => {
    if (activeView === "all") return cargoOwners;
    return cargoOwners.filter((owner) => {
      if (activeView === "active") return owner.is_active;
      if (activeView === "inactive") return !owner.is_active;
      return true;
    });
  }, [cargoOwners, activeView]);

  // Reset pagination when tab changes
  React.useEffect(() => {
    setPage(1);
  }, [activeView]);

  // Handler for exporting cargo owners
  const handleExport = () => {
    setIsLoading(true);

    // Simulate export process
    setTimeout(() => {
      setIsLoading(false);
      alert("Cargo Owner data has been exported to CSV");
    }, 1500);
  };

  // If there's an error loading cargo owners
  if (error) {
    return (
      <div className="flex-1 overflow-auto">
        <div className="p-4 sm:p-6 lg:p-8">
          <div className="text-center text-red-500 p-4">
            Error loading cargo owners: {error.message}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-auto">
      <div className="p-4 sm:p-6 lg:p-8 space-y-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0">
          <div>
            <h2 className="text-2xl font-bold tracking-tight">Cargo Owners</h2>
            <p className="text-muted-foreground">
              Manage and track your cargo owners
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="sm"
              className="h-9"
              onClick={handleExport}
              disabled={isLoading}
            >
              <Download className="mr-2 h-4 w-4" />
              Export
            </Button>
            <Link to="/dashboard/add-cargo-owner">
              <Button variant="default" size="sm" className="h-9">
                <Plus className="mr-2 h-4 w-4" />
                Add Cargo Owner
              </Button>
            </Link>
          </div>
        </div>

        <CargoOwnerStats cargoOwners={cargoOwners} />

        <Tabs
          defaultValue="all"
          onValueChange={setActiveView}
          className="space-y-4"
        >
          <TabsList>
            <TabsTrigger value="all">All Owners</TabsTrigger>
            <TabsTrigger value="active">Active</TabsTrigger>
            <TabsTrigger value="inactive">Inactive</TabsTrigger>
          </TabsList>
          <TabsContent value={activeView}>
            <Card>
              <CardHeader className="pb-0">
                <CardTitle>Cargo Owner Management</CardTitle>
                <CardDescription>
                  View, filter and manage your cargo owners
                </CardDescription>
              </CardHeader>
              <CardContent className="pt-6">
                {cargoOwnersLoading ? (
                  <div className="text-center py-8 text-muted-foreground">
                    Loading cargo owners...
                  </div>
                ) : filteredCargoOwners.length > 0 ? (
                  <CargoOwnersDataTable
                    data={filteredCargoOwners}
                    page={page}
                    setPage={setPage}
                    limit={limit}
                  />
                ) : (
                  <div className="text-center py-8 text-muted-foreground">
                    No cargo owners found
                  </div>
                )}
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
