import {
  MapPinIcon,
  ShieldExclamationIcon,
  ExclamationTriangleIcon,
  TruckIcon,
  CheckBadgeIcon,
  DocumentTextIcon,
  DocumentPlusIcon,
} from "@heroicons/react/24/outline";
import { format } from "date-fns";

// Helper to create timestamps for alerts
const getTimestamp = (minutesAgo) => {
  return format(new Date(Date.now() - minutesAgo * 60000), "h:mm a");
};

/**
 * Hardcoded alerts for the dashboard
 *
 * Alert structure:
 * {
 *   id: string,               // Unique identifier for the alert
 *   shipmentId?: string,      // Optional reference to a shipment
 *   verificationId?: string,  // Optional reference to a verification
 *   title: string,            // Alert title
 *   description: string,      // Alert description
 *   severity: "high" | "medium" | "low",  // Alert severity level
 *   type: "alert" | "verification" | "document" | "shipment" | "carrier", // Alert type
 *   time: string,             // Formatted time string
 *   icon: IconComponent,      // Reference to an icon component
 *   actionable: boolean,      // Whether this alert has actions
 *   actions?: Array<{         // Optional actions that can be taken
 *     type: string,           // Action type (verify, contact, document, message)
 *     label: string           // Action button label
 *   }>
 * }
 */
const mockAlerts = [
  // High severity alerts
  {
    id: "alert-1-blizzard",
    title: "Blizzard Conditions in Midwest",
    description:
      "Severe blizzard conditions have led to highway closures in Kansas and Nebraska, impacting multiple shipments.",
    severity: "high",
    type: "alert",
    time: getTimestamp(120),
    icon: ExclamationTriangleIcon,
    actionable: true,
    actions: [
      { type: "message", label: "Notify Affected Drivers" },
      { type: "reroute", label: "Suggest Alternative Routes" },
    ],
  },
  {
    id: "alert-2-tornado",
    title: "Tornado Damage in Indiana",
    description:
      "Tornadoes have caused significant damage in Indiana, leading to road blockages and detours.",
    severity: "high",
    type: "alert",
    time: getTimestamp(180),
    icon: ShieldExclamationIcon,
    actionable: true,
    actions: [
      { type: "contact", label: "Alert Local Authorities" },
      { type: "reroute", label: "Advise Drivers on Detours" },
    ],
  },
  {
    id: "alert-3-wildfires",
    title: "Wildfires in Arkansas",
    description:
      "Multiple wildfires have led to road closures and hazardous conditions in Arkansas.",
    severity: "high",
    type: "alert",
    time: getTimestamp(240),
    icon: ExclamationTriangleIcon,
    actionable: true,
    actions: [
      { type: "message", label: "Warn Drivers in Affected Areas" },
      { type: "reroute", label: "Plan Alternate Routes" },
    ],
  },
  {
    id: "alert-4-duststorm",
    title: "Dust Storms in Kansas",
    description:
      "Severe dust storms have caused low visibility and road closures in parts of Kansas.",
    severity: "high",
    type: "alert",
    time: getTimestamp(300),
    icon: ExclamationTriangleIcon,
    actionable: true,
    actions: [
      { type: "message", label: "Advise Drivers to Halt Operations" },
      { type: "reroute", label: "Identify Safe Detours" },
    ],
  },
  {
    id: "alert-5-flooding",
    title: "Flooding in Louisiana",
    description:
      "Heavy rains have resulted in flooding, leading to impassable roads in Louisiana.",
    severity: "high",
    type: "alert",
    time: getTimestamp(360),
    icon: ExclamationTriangleIcon,
    actionable: true,
    actions: [
      { type: "message", label: "Inform Drivers of Flooded Areas" },
      { type: "reroute", label: "Suggest Alternative Routes" },
    ],
  },
  {
    id: "alert-6-poweroutage",
    title: "Power Outages in Midwest",
    description:
      "Over 250,000 people are without power due to severe weather, affecting traffic signals and road safety.",
    severity: "high",
    type: "alert",
    time: getTimestamp(420),
    icon: ExclamationTriangleIcon,
    actionable: true,
    actions: [
      {
        type: "message",
        label: "Caution Drivers About Non-Functional Traffic Signals",
      },
      { type: "reroute", label: "Plan Routes Avoiding Affected Areas" },
    ],
  },
  {
    id: "alert-7-bridgeclosure",
    title: "Bridge Closure in San Antonio",
    description:
      "Kelly Bridge at Joint Base San Antonio closed due to safety hazards from thunderstorms.",
    severity: "high",
    type: "alert",
    time: getTimestamp(480),
    icon: ExclamationTriangleIcon,
    actionable: true,
    actions: [
      { type: "reroute", label: "Plan Alternate Routes" },
      { type: "message", label: "Inform Drivers of Closure" },
    ],
  },
  {
    id: "alert-8-laneclosure",
    title: "I-10 Lane Closure in Texas",
    description:
      "Long-term closure of the left lane on I-10 westbound frontage road in Seguin for bridge work.",
    severity: "high",
    type: "alert",
    time: getTimestamp(540),
    icon: ExclamationTriangleIcon,
    actionable: true,
    actions: [
      { type: "reroute", label: "Suggest Alternative Routes" },
      { type: "message", label: "Notify Drivers of Lane Closure" },
    ],
  },
  {
    id: "alert-9-interchangeclosure",
    title: "Loop 1604 and I-10 Interchange Closures",
    description:
      "Weekend closures at the I-10 and Loop 1604 interchange in San Antonio for construction.",
    severity: "high",
    type: "alert",
    time: getTimestamp(600),
    icon: ExclamationTriangleIcon,
    actionable: true,
    actions: [
      { type: "reroute", label: "Advise Drivers on Detours" },
      { type: "message", label: "Inform Drivers of Closure Schedule" },
    ],
  },
  {
    id: "alert-10-rampclosure",
    title: "I-295 Ramp Closures in Rhode Island",
    description:
      "Nighttime closures of I-295 North and South Exits 18 A and B off-ramps in Lincoln for bridge construction.",
    severity: "high",
    type: "alert",
    time: getTimestamp(660),
    icon: ExclamationTriangleIcon,
    actionable: true,
    actions: [
      { type: "reroute", label: "Suggest Alternative Routes" },
      { type: "message", label: "Notify Drivers of Ramp Closure" },
    ],
  },
];

export default mockAlerts;
