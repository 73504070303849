// @ts-nocheck
import React, { useState, useEffect } from "react";
import Header from "../../Portal/Header";
import Filter from "./RiskFilter";
import SummaryStats from "./RiskStats";
import DataTable from "./RiskTable";
import { useShipments } from "../../../hooks/shipments";
import { supabase } from "../../../lib/supabase"; // Make sure this import exists

const RiskDashboardContent = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const {
    data: shipmentsData,
    isLoading,
    error,
  } = useShipments(page, limit, filters);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1);
  };

  const totalPages = Math.ceil((shipmentsData?.total || 0) / limit);

  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  if (isLoading)
    return <div className="text-center text-gray-500 mt-20">Loading...</div>;

  if (
    !isLoading &&
    (!shipmentsData || Object.keys(shipmentsData).length === 0)
  ) {
    return (
      <div className="text-center text-gray-500 mt-20">
        No shipments available
      </div>
    );
  }

  return (
    <div className="bg-white text-gray-300 min-h-screen">
      <Header companyName="Risk Signals" url="/dashboard/add-shipment" />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={handleFilterChange} />
        </div>
        <SummaryStats data={shipmentsData?.shipments || []} />
        <div className="mt-8">
          <DataTable
            data={[]}
            currentPage={page}
            totalPages={totalPages}
            onPageChange={setPage}
            limit={limit}
          />
        </div>
      </div>
    </div>
  );
};

const RiskDashboard = () => <RiskDashboardContent />;

export default RiskDashboard;
