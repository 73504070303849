class ProviderError extends Error {
  constructor(message, code, details = {}) {
    super(message);
    this.name = "ProviderError";
    this.code = code;
    this.details = details;
  }
}

const ErrorHandler = {
  handleError(error, defaultMessage = "An error occurred") {
    console.error("Error handled:", error);

    // If it's already a structured error response, return it
    if (error && typeof error === "object" && "success" in error) {
      return error;
    }

    return {
      success: false,
      message: error.message || defaultMessage,
      code: error.code || "unknown_error",
      details: error.details || {},
    };
  },
};

module.exports = { ProviderError, ErrorHandler };
