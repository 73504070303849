import * as React from "react";
import { CarriersDataTable } from "./CarriersDataTable";
import { CarrierStats } from "./CarrierStats";
import { Button } from "@/components/ui/button";
import { Download, Plus } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useCarrierList } from "@/hooks/carriers";
import { Link } from "react-router-dom";

/**
 * CarriersDashboard - Main container for the carriers page
 */
export function CarriersDashboard() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeView, setActiveView] = React.useState("all");
  const [page, setPage] = React.useState(1);
  const [limit] = React.useState(10); // Fixed limit of 10 items per page
  const [filters, setFilters] = React.useState({});

  // Fetch carriers using the useCarrierList hook
  const {
    data: carriersData,
    isLoading: carriersLoading,
    error,
  } = useCarrierList();

  // Extract the carriers from the data
  const carriers = React.useMemo(() => {
    return carriersData || [];
  }, [carriersData]);

  // Filter carriers based on active view
  const filteredCarriers = React.useMemo(() => {
    if (activeView === "all") return carriers;
    return carriers.filter((carrier) => {
      if (activeView === "vetted") return carrier.is_vetted;
      if (activeView === "unvetted") return !carrier.is_vetted;
      return true;
    });
  }, [carriers, activeView]);

  // Reset pagination when tab changes
  React.useEffect(() => {
    setPage(1);
  }, [activeView]);

  // Handler for exporting carriers
  const handleExport = () => {
    setIsLoading(true);

    // Simulate export process
    setTimeout(() => {
      setIsLoading(false);
      alert("Carrier data has been exported to CSV");
    }, 1500);
  };

  // If there's an error loading carriers
  if (error) {
    return (
      <div className="flex-1 overflow-auto">
        <div className="p-4 sm:p-6 lg:p-8">
          <div className="text-center text-red-500 p-4">
            Error loading carriers: {error.message}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-auto">
      <div className="p-4 sm:p-6 lg:p-8 space-y-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0">
          <div>
            <h2 className="text-2xl font-bold tracking-tight">Carriers</h2>
            <p className="text-muted-foreground">
              Manage and vet your carriers
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="sm"
              className="h-9"
              onClick={handleExport}
              disabled={isLoading}
            >
              <Download className="mr-2 h-4 w-4" />
              Export
            </Button>
            <Link to="/dashboard/add-carrier">
              <Button variant="default" size="sm" className="h-9">
                <Plus className="mr-2 h-4 w-4" />
                Add Carrier
              </Button>
            </Link>
            <Link to="/dashboard/vet-carrier">
              <Button variant="default" size="sm" className="h-9">
                Vet Carrier
              </Button>
            </Link>
          </div>
        </div>

        <CarrierStats carriers={carriers} />

        <Tabs
          defaultValue="all"
          onValueChange={setActiveView}
          className="space-y-4"
        >
          <TabsList>
            <TabsTrigger value="all">All Carriers</TabsTrigger>
            <TabsTrigger value="vetted">Vetted</TabsTrigger>
            <TabsTrigger value="unvetted">Unvetted</TabsTrigger>
          </TabsList>
          <TabsContent value={activeView}>
            <Card>
              <CardHeader className="pb-0">
                <CardTitle>Carrier Management</CardTitle>
                <CardDescription>
                  View, filter and manage your carriers
                </CardDescription>
              </CardHeader>
              <CardContent className="pt-6">
                {carriersLoading ? (
                  <div className="text-center py-8 text-muted-foreground">
                    Loading carriers...
                  </div>
                ) : filteredCarriers.length > 0 ? (
                  <CarriersDataTable
                    data={filteredCarriers}
                    page={page}
                    setPage={setPage}
                    limit={limit}
                  />
                ) : (
                  <div className="text-center py-8 text-muted-foreground">
                    No carriers found
                  </div>
                )}
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
