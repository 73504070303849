import React, { useState, useEffect } from "react";
import BusinessOwnerVerificationStatus from "./BusinessOwnerVerificationStatus";
import BusinessOwnerVerificationBadge from "./BusinessOwnerVerificationBadge";

/**
 * Component for displaying all business owners with their verification status
 * @param {Object} props
 * @param {Array} props.businessOwners - List of business owners
 * @param {Object} props.verificationResults - Verification results for business owners
 * @param {Array} props.verifications - Verification data for each business owner
 */
const BusinessOwnersSection = ({
  businessOwners,
  verificationResults = {},
  verifications = [], // Add verifications array as a prop
  isLoading = false,
}) => {
  console.log("BusinessOwnersSection props:", {
    businessOwnersCount: businessOwners?.length,
    verificationResultsKeys: Object.keys(verificationResults || {}),
    verificationsCount: verifications?.length,
  });

  useEffect(() => {
    // Log verification data for debugging
    console.log("Business owners:", businessOwners);
    console.log("Verifications:", verifications);
  }, [businessOwners, verifications]);

  const [expandedOwner, setExpandedOwner] = useState(null);

  // Enhanced debugging: log each business owner and their associated verifications
  useEffect(() => {
    if (businessOwners && verifications) {
      console.log("Business Owner Verifications Mapping:");
      businessOwners.forEach((owner) => {
        const matchingVerifications = verifications.filter(
          (v) =>
            // First try matching by inquiry_id from verification_result (new logic)
            (owner.verification_result?.inquiry_id &&
              v.persona_inquiry_id === owner.verification_result.inquiry_id) ||
            // Fall back to ID matching (old logic)
            v.business_owner_id === owner.id ||
            v.owner_id === owner.id
        );
        console.log(
          `Owner ${owner.first_name} ${owner.last_name} (ID: ${owner.id}):`,
          matchingVerifications.length > 0
            ? matchingVerifications
            : "No verifications found"
        );
      });
    }
  }, [businessOwners, verifications]);

  // Check if verificationResults is undefined or empty
  const hasVerificationResults =
    verificationResults && Object.keys(verificationResults).length > 0;
  console.log("Has verification results:", hasVerificationResults);

  if (!businessOwners || businessOwners.length === 0) {
    return (
      <div className="bg-white rounded-xl shadow-sm overflow-hidden mb-6">
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-4">Business Owners</h2>
          <p className="text-gray-500">
            No business owners found for this carrier.
          </p>
        </div>
      </div>
    );
  }

  const toggleOwner = (ownerId) => {
    setExpandedOwner(expandedOwner === ownerId ? null : ownerId);
  };

  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden mb-6">
      <div className="p-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-900">
          Business Owners
        </h2>

        <div className="space-y-4">
          {businessOwners.map((owner) => {
            // ---> DEBUG: Log owner details and all available verifications before attempting match
            console.log(
              `DEBUG (Section): Processing Owner ID: ${owner.id}, Name: ${owner.first_name} ${owner.last_name}`
            );
            console.log(
              `DEBUG (Section): Owner's verification_result:`,
              owner.verification_result
            );
            console.log(
              `DEBUG (Section): All verifications available:`,
              verifications.map((v) => ({
                id: v.id,
                owner_id: v.business_owner_id,
                inquiry_id: v.persona_inquiry_id,
              }))
            );

            // Find the matching verification directly instead of using verificationResults
            const matchingVerification = verifications.find(
              (v) =>
                // First try matching by inquiry_id from verification_result
                (owner.verification_result?.inquiry_id &&
                  v.persona_inquiry_id ===
                    owner.verification_result.inquiry_id) ||
                // Fall back to ID matching
                v.business_owner_id === owner.id ||
                v.owner_id === owner.id ||
                v.results?.business_owner_id === owner.id ||
                v.metadata?.business_owner_id === owner.id
            );

            console.log(
              `DEBUG (Section): Found matching verification for ${owner.first_name} ${owner.last_name}:`,
              matchingVerification ? "Yes" : "No"
            );

            const isExpanded = expandedOwner === owner.id;

            // Determine verification status based on the matching verification
            let statusColor = "gray";
            let statusText = "Unknown";

            if (matchingVerification) {
              if (
                matchingVerification.state === "persona_complete" &&
                matchingVerification.persona_verification_complete
              ) {
                statusColor = "green";
                statusText = "Verified";
              } else if (matchingVerification.state === "persona_pending") {
                statusColor = "orange";
                statusText = "Pending";
              } else if (matchingVerification.state === "persona_failed") {
                statusColor = "red";
                statusText = "Failed";
              }
            }

            // Check if verification is completely missing
            if (!matchingVerification) {
              console.log("No verification found for owner:", owner.id);

              // Return UI for missing verification
              return (
                <div
                  key={owner.id}
                  className="border rounded-lg overflow-hidden"
                >
                  {/* Owner Summary Card - Always visible */}
                  <div
                    className={`bg-gray-50 p-4 flex justify-between items-center cursor-pointer`}
                    onClick={() => toggleOwner(owner.id)}
                  >
                    <div className="flex items-center">
                      <div className="bg-gray-100 p-2 rounded-full mr-3">
                        <svg
                          className="w-5 h-5 text-gray-600"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                      </div>
                      <div>
                        <h3 className="font-medium text-gray-900">
                          {owner.first_name} {owner.last_name}
                        </h3>
                        <div className="text-sm text-gray-600">
                          {owner.email || "No email"} •{" "}
                          {owner.phone_number || "No phone"}
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <span className="px-2 py-1 text-sm rounded-full bg-gray-100 text-gray-800 mr-2">
                        Not Verified
                      </span>
                      <svg
                        className={`w-5 h-5 transform transition-transform ${
                          isExpanded ? "rotate-180" : ""
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>

                  {/* Expanded Details */}
                  {isExpanded && (
                    <div className="p-4">
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <p className="text-gray-800 font-medium">
                          No verification data available
                        </p>
                        <p className="mt-2 text-sm text-gray-700">
                          This business owner has not been verified yet. Click
                          the "Verify Business Owner" button above to start the
                          verification process.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              );
            }

            return (
              <div key={owner.id} className="border rounded-lg overflow-hidden">
                {/* Owner Summary Card - Always visible */}
                <div
                  className={`bg-${statusColor}-50 p-4 flex justify-between items-center cursor-pointer`}
                  onClick={() => toggleOwner(owner.id)}
                >
                  <div className="flex items-center">
                    <div
                      className={`bg-${statusColor}-100 p-2 rounded-full mr-3`}
                    >
                      <svg
                        className={`w-5 h-5 text-${statusColor}-600`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                        />
                      </svg>
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">
                        {owner.first_name} {owner.last_name}
                      </h3>
                      <div className="text-sm text-gray-600">
                        {owner.email || "No email"} •{" "}
                        {owner.phone_number || "No phone"}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center space-x-4">
                    {/* Show verification badge with debug info */}
                    <div className="flex flex-col">
                      <BusinessOwnerVerificationBadge
                        owner={owner}
                        verification={matchingVerification}
                        verifications={verifications}
                      />
                      <div className="text-xs text-gray-400 mt-1">
                        ID: {owner.id}
                        {owner.verification_result?.inquiry_id && (
                          <div className="text-xs text-green-500">
                            Inquiry ID:{" "}
                            {owner.verification_result?.inquiry_id.substring(
                              0,
                              12
                            )}
                            ...
                          </div>
                        )}
                      </div>
                    </div>

                    <svg
                      className={`w-5 h-5 transform transition-transform ${
                        isExpanded ? "rotate-180" : ""
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>

                {/* Expanded Details */}
                {isExpanded && (
                  <div className="p-4">
                    {matchingVerification ? (
                      <BusinessOwnerVerificationStatus
                        verificationResult={matchingVerification}
                      />
                    ) : (
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <p className="text-gray-600">
                          No verification data available for this business
                          owner.
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BusinessOwnersSection;
