import { personaProvider } from "../providers/PersonaProvider";
import { VerificationRepository } from "../repositories/VerificationRepository";
import VerificationData from "../models/VerificationData";
import { sendInsuranceVerificationSMS } from "../services/core/NotificationService";

class InsuranceVerificationStrategy {
  constructor(dependencies = {}) {
    this.verificationRepository =
      dependencies.verificationRepository || new VerificationRepository();
    this.insuranceRepository = dependencies.insuranceRepository;
    this.documentProvider = dependencies.documentProvider;
    this.documentStorageService = dependencies.documentStorageService;
  }

  async createVerification(data) {
    try {
      console.log("🔍 [createInsuranceVerification] Starting with data:", data);

      const { inquiryId, referenceId } = await personaProvider.createInquiry(
        {
          email: data.email,
          phoneNumber: data.phoneNumber,
          referenceId: data.entityId,
          companyId: data.companyId,
          entityType: "carrier",
          entityId: data.entityId,
          verificationMethod: "insurance_verification",
        },
        "insurance_verification"
      );

      const verificationResults = {
        initiated_by: data.initiatedBy,
        verification_source: "persona_inquiry",
        email: data.email,
        company_id: data.companyId,
      };

      const verificationDataObject = VerificationData.forInsuranceVerification({
        carrierId: data.entityId,
        policyNumber: data.policy_number,
        state: "created",
        persona_inquiry_id: inquiryId,
        reference_id: referenceId,
        phone_number: data.phoneNumber,
        results: verificationResults,
        persona_verification_complete: false,
        ocr_verification_complete: false,
        ...data,
      });

      const validation = verificationDataObject.validate();
      if (!validation.isValid) {
        console.error(
          "❌ [createInsuranceVerification] Invalid verification data:",
          validation.errors
        );
        throw new Error(
          `Invalid verification data: ${validation.errors.join(", ")}`
        );
      }

      const verificationDataForDb = verificationDataObject.toObject();

      const verification = await this.verificationRepository.create(
        verificationDataForDb
      );

      if (!verification) {
        console.error(
          "❌ [createInsuranceVerification] Failed to create verification record"
        );
        throw new Error("Failed to create verification record");
      }
      console.log(
        "✅ [createInsuranceVerification] Verification record created:",
        { id: verification.id }
      );

      // Send SMS notification for insurance verification
      await sendInsuranceVerificationSMS({
        persona_inquiry_id: inquiryId,
        phone_number: data.phoneNumber,
      });

      return { verification };
    } catch (error) {
      console.error("❌ [createInsuranceVerification] Error:", error);
      throw error;
    }
  }

  async checkStatus(verificationId) {
    // Get the verification record
    const verification = await this.verificationRepository.findById(
      verificationId
    );
    if (!verification) {
      throw new Error(`Verification with ID ${verificationId} not found`);
    }

    // Get the insurance details
    const insuranceDetails =
      await this.insuranceRepository.findByVerificationId(verificationId);

    // Determine the status based on verification and details
    const status = this._determineVerificationStatus(
      verification,
      insuranceDetails
    );

    // If status has changed, update the verification
    if (status !== verification.status) {
      await this.verificationRepository.update(verificationId, { status });
    }

    return {
      verification,
      insuranceDetails,
      status,
    };
  }

  async processDocument(verificationId, document, documentType) {
    try {
      // 1. Get verification record
      const verification = await this.verificationRepository.findById(
        verificationId
      );
      if (!verification) {
        throw new Error(`Verification with ID ${verificationId} not found`);
      }

      // 2. Upload document
      const uploadedDocument = await this.documentProvider.uploadDocument({
        file: document,
        verificationId,
        fileType: documentType,
        metadata: {
          entity_type: verification.entity_type,
          document_type: documentType,
        },
      });

      // 3. Extract insurance information from the document
      const extractedData = await this.extractInsuranceData(document);

      // 4. Get insurance details
      let insuranceDetails =
        await this.insuranceRepository.findByVerificationId(verificationId);

      // 5. Update insurance details with extracted data
      const updatedDetails = await this.insuranceRepository.update(
        insuranceDetails.id,
        {
          insurance_company_name:
            extractedData.companyName ||
            insuranceDetails.insurance_company_name,
          policy_holder:
            extractedData.policyHolder || insuranceDetails.policy_holder,
          insurance_expiration_date:
            extractedData.expirationDate ||
            insuranceDetails.insurance_expiration_date,
          insurance_effective_date:
            extractedData.effectiveDate ||
            insuranceDetails.insurance_effective_date,
          vehicle_identification_number:
            extractedData.vin || insuranceDetails.vehicle_identification_number,
          vehicle_make:
            extractedData.vehicleMake || insuranceDetails.vehicle_make,
          vehicle_model:
            extractedData.vehicleModel || insuranceDetails.vehicle_model,
          policy_number:
            extractedData.policyNumber || insuranceDetails.policy_number,
          coverage_amount:
            extractedData.coverageAmount || insuranceDetails.coverage_amount,
          confidence_score: extractedData.confidenceScore,
          extracted_data: extractedData,
          verification_result:
            this._evaluateInsuranceVerification(extractedData),
        }
      );

      // 6. Update verification with document reference and new status
      const newStatus = this._determineVerificationStatus(
        verification,
        updatedDetails
      );
      await this.verificationRepository.update(verificationId, {
        status: newStatus,
        documents: [
          ...(verification.documents || []),
          { id: uploadedDocument.id, type: documentType },
        ],
      });

      return {
        success: true,
        verification: await this.verificationRepository.findById(
          verificationId
        ),
        insuranceDetails: updatedDetails,
        document: uploadedDocument,
      };
    } catch (error) {
      // Get existing verification to preserve metadata
      const existingVerification = await this.verificationRepository.findById(
        verificationId
      );

      // Update with error details while preserving other metadata
      await this.verificationRepository.update(verificationId, {
        status: "failed",
        metadata: {
          ...existingVerification.metadata,
          error_message: error.message,
        },
      });

      throw new Error(`Insurance document processing failed: ${error.message}`);
    }
  }

  async extractInsuranceData(document) {
    try {
      // If document is a file, we might need to get a signed URL or pass directly
      // to the extraction service depending on implementation

      // Simulating an extraction process
      // In a real implementation, this would call an OCR service or API
      return {
        companyName: null,
        policyHolder: null,
        expirationDate: null,
        effectiveDate: null,
        vin: null,
        vehicleMake: null,
        vehicleModel: null,
        policyNumber: null,
        coverageAmount: null,
        confidenceScore: 0.75,
        // Other extracted fields
      };
    } catch (error) {
      console.error(`Error extracting insurance data: ${error.message}`);
      throw new Error(`Failed to extract insurance data: ${error.message}`);
    }
  }

  async verifyInsuranceCoverage(verificationId, minimumCoverage) {
    const insuranceDetails =
      await this.insuranceRepository.findByVerificationId(verificationId);

    if (!insuranceDetails) {
      throw new Error(
        `Insurance details for verification ID ${verificationId} not found`
      );
    }

    // Check if the coverage meets the minimum requirement
    const hasSufficientCoverage =
      insuranceDetails.coverage_amount >= minimumCoverage;

    // Check if the policy is active
    const currentDate = new Date();
    const expirationDate = new Date(insuranceDetails.insurance_expiration_date);
    const effectiveDate = new Date(insuranceDetails.insurance_effective_date);
    const isPolicyActive =
      effectiveDate <= currentDate && expirationDate >= currentDate;

    // Update the verification result
    await this.insuranceRepository.update(insuranceDetails.id, {
      verification_result:
        isPolicyActive && hasSufficientCoverage ? "verified" : "rejected",
    });

    // Update the verification status
    const verification = await this.verificationRepository.findById(
      verificationId
    );
    const newStatus =
      isPolicyActive && hasSufficientCoverage ? "verified" : "rejected";

    if (verification.status !== newStatus) {
      await this.verificationRepository.update(verificationId, {
        status: newStatus,
      });
    }

    return {
      isVerified: isPolicyActive && hasSufficientCoverage,
      isPolicyActive,
      hasSufficientCoverage,
      coverageAmount: insuranceDetails.coverage_amount,
      minimumRequired: minimumCoverage,
      expirationDate: insuranceDetails.insurance_expiration_date,
    };
  }

  // Helper method to determine verification status
  _determineVerificationStatus(verification, insuranceDetails) {
    if (!insuranceDetails) {
      return "pending";
    }

    // Check if we have a verification result
    if (insuranceDetails.verification_result) {
      return insuranceDetails.verification_result;
    }

    // Check if the policy is active
    const currentDate = new Date();
    const expirationDate = insuranceDetails.insurance_expiration_date
      ? new Date(insuranceDetails.insurance_expiration_date)
      : null;
    const effectiveDate = insuranceDetails.insurance_effective_date
      ? new Date(insuranceDetails.insurance_effective_date)
      : null;

    // If we don't have dates or complete info, it's still pending
    if (!expirationDate || !effectiveDate || !insuranceDetails.policy_number) {
      return "pending";
    }

    // Check if the policy is expired
    if (expirationDate < currentDate) {
      return "rejected";
    }

    // Check if the policy is not yet effective
    if (effectiveDate > currentDate) {
      return "pending";
    }

    // If we have all the required info and policy is active, it's verified
    if (
      insuranceDetails.policy_number &&
      insuranceDetails.insurance_company_name &&
      insuranceDetails.policy_holder
    ) {
      return "verified";
    }

    return verification.status;
  }

  // Helper method to evaluate verification based on extracted data
  _evaluateInsuranceVerification(extractedData) {
    // If confidence score is too low, reject
    if (extractedData.confidenceScore < 0.6) {
      return "rejected";
    }

    // If missing critical fields, mark as pending for manual review
    if (
      !extractedData.policyNumber ||
      !extractedData.companyName ||
      !extractedData.expirationDate
    ) {
      return "pending";
    }

    // If policy is expired, reject
    const currentDate = new Date();
    const expirationDate = extractedData.expirationDate
      ? new Date(extractedData.expirationDate)
      : null;

    if (expirationDate && expirationDate < currentDate) {
      return "rejected";
    }

    // If everything looks good, verify
    return "verified";
  }

  /**
   * Get verification by Persona inquiry ID
   * @param {string} inquiryId - Persona inquiry ID
   * @returns {Promise<Object>} Verification record
   */
  async getVerificationByInquiryId(inquiryId) {
    console.log("Getting insurance verification by inquiry ID:", inquiryId);
    return this.verificationRepository.findByPersonaInquiryId(inquiryId);
  }

  /**
   * Update verification by Persona inquiry ID
   * @param {string} inquiryId - Persona inquiry ID
   * @param {Object} data - Data to update
   * @returns {Promise<Object>} Updated verification record
   */
  async updateVerificationByInquiryId(inquiryId, data) {
    console.log(
      "Updating insurance verification by inquiry ID:",
      inquiryId,
      data
    );
    const verification = await this.getVerificationByInquiryId(inquiryId);

    if (!verification) {
      throw new Error(`Verification not found for inquiry ID: ${inquiryId}`);
    }

    return this.verificationRepository.update(verification.id, data);
  }
}

export default InsuranceVerificationStrategy;
