// @ts-nocheck
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CalendarIcon, Clock, Loader2, CheckCircle } from "lucide-react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SimpleDateTimePicker } from "@/components/ui/date-time-picker";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";

import { createDriverVerification } from "../../../api/services/verification/VerificationService";
import { useCustomerCompanyId } from "../../../hooks/useCustomers";
import { useGetUserSession } from "../../../hooks/users";
import { useDrivers } from "../../../hooks/drivers";
import { useShipments } from "../../../hooks/shipments";

// Simplified form validation schema
const formSchema = z.object({
  driver_id: z.string({ required_error: "Please select a driver" }),
  shipment_id: z.string({ required_error: "Please select a shipment" }),
  phoneNumber: z
    .string()
    .min(10, "Phone number must be at least 10 digits")
    .regex(
      /^[\d\s()-]+$/,
      "Phone number can only contain digits, spaces, and ()- characters"
    ),
  deliveryDateTime: z.date({
    required_error: "Please select delivery date and time",
  }),
});

export default function DriverVerificationForm() {
  const navigate = useNavigate();
  const { session } = useGetUserSession();
  const { data: companyId } = useCustomerCompanyId(session?.user?.id);
  const { data: drivers, isLoading: isLoadingDrivers } = useDrivers();
  const { data: shipments, isLoading: isLoadingShipments } = useShipments();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isShipmentOpen, setIsShipmentOpen] = useState(false);
  const [isDriverOpen, setIsDriverOpen] = useState(false);

  // Add click outside handler
  React.useEffect(() => {
    function handleClickOutside(event) {
      const commandMenu = document.querySelector("[data-command-menu]");
      const shipmentButton = document.querySelector("[data-shipment-button]");
      const driverButton = document.querySelector("[data-driver-button]");
      if (
        commandMenu &&
        !commandMenu.contains(event.target) &&
        !shipmentButton?.contains(event.target) &&
        !driverButton?.contains(event.target)
      ) {
        setIsShipmentOpen(false);
        setIsDriverOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Add escape key handler
  React.useEffect(() => {
    function handleEscapeKey(event) {
      if (event.key === "Escape") {
        setIsShipmentOpen(false);
        setIsDriverOpen(false);
      }
    }

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, []);

  // Set up form with zod validation
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      driver_id: "",
      shipment_id: "",
      phoneNumber: "",
      deliveryDateTime: new Date(),
    },
  });

  // Add form state debugging
  React.useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      console.log("Form field changed:", { name, type, value });
    });
    return () => subscription.unsubscribe();
  }, [form]);

  // Form submission handler
  async function onSubmit(data) {
    console.log("Form submission started", data);
    setIsSubmitting(true);
    setError("");

    try {
      console.log("Attempting to create driver verification...");

      // Get the selected shipment details to check/set dock_id
      const selectedShipment = shipments?.shipments?.find(
        (s) => s.load_id === data.shipment_id
      );

      console.log("Selected shipment:", selectedShipment);

      // Create verification with all required data
      const response = await createDriverVerification({
        phoneNumber: data.phoneNumber,
        shipment_id: data.shipment_id,
        driver_id: data.driver_id,
        selectedDateTime: data.deliveryDateTime,
        companyId,
        // Include dock_id if the shipment is already assigned to a dock
        dock_id: selectedShipment?.dock_id || null,
      });

      console.log("Verification response:", response);

      if (response.error) throw new Error(response.error);

      setSuccess(true);
      setTimeout(() => {
        navigate("/dashboard/verifications");
      }, 2000);
    } catch (err) {
      console.error("Error during form submission:", err);
      setError(
        err.message || "Failed to schedule verification. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  }

  // Add form validation debugging
  const handleFormSubmit = (e) => {
    console.log("Form submit event triggered");
    console.log("Current form values:", form.getValues());
    console.log("Form errors:", form.formState.errors);

    form.handleSubmit((data) => {
      console.log("Form validation passed, calling onSubmit");
      onSubmit(data);
    })(e);
  };

  // Loading state
  if (isLoadingDrivers || isLoadingShipments) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  const driverOptions = drivers?.drivers || [];
  const shipmentOptions = shipments?.shipments || [];

  return (
    <div className="space-y-6">
      <div className="grid md:grid-cols-3 gap-6">
        <Card className="md:col-span-2">
          <CardHeader>
            <div className="flex items-center gap-4">
              <div className="bg-primary/10 p-3 rounded-full">
                <CheckCircle className="h-6 w-6 text-primary" />
              </div>
              <CardTitle className="text-xl">
                24/7 Driver Verification
              </CardTitle>
            </div>
            <p className="text-muted-foreground mt-2">
              Enter driver information and expected delivery time.
            </p>
          </CardHeader>
          <CardContent>
            <Form {...form}>
              <form onSubmit={handleFormSubmit} className="space-y-6">
                <div className="grid gap-6 sm:grid-cols-2">
                  {/* Driver Select */}
                  <FormField
                    control={form.control}
                    name="driver_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Select Driver *</FormLabel>
                        <FormControl>
                          <div className="relative">
                            <Button
                              variant="outline"
                              role="combobox"
                              data-driver-button
                              className={cn(
                                "w-full justify-between",
                                !field.value && "text-muted-foreground"
                              )}
                              onClick={() => setIsDriverOpen(!isDriverOpen)}
                            >
                              {field.value && driverOptions.length > 0
                                ? (() => {
                                    const selectedDriver = driverOptions.find(
                                      (d) =>
                                        String(d.id) === String(field.value)
                                    );
                                    return selectedDriver
                                      ? `${selectedDriver.first_name} ${selectedDriver.last_name}`
                                      : "Select Driver";
                                  })()
                                : "Select Driver"}
                              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                            {isDriverOpen && (
                              <div
                                className="absolute top-full left-0 right-0 mt-1 bg-white border rounded-md shadow-lg z-50"
                                data-command-menu
                              >
                                <Command>
                                  <CommandInput
                                    placeholder="Search driver..."
                                    data-command-input
                                  />
                                  <CommandEmpty>No driver found.</CommandEmpty>
                                  <CommandGroup className="max-h-[300px] overflow-auto">
                                    {driverOptions.map((driver) => (
                                      <CommandItem
                                        key={driver.id}
                                        value={String(driver.id)}
                                        onSelect={() => {
                                          field.onChange(String(driver.id));
                                          setIsDriverOpen(false);
                                        }}
                                      >
                                        <Check
                                          className={cn(
                                            "mr-2 h-4 w-4",
                                            String(driver.id) ===
                                              String(field.value)
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                        {driver.first_name} {driver.last_name}
                                      </CommandItem>
                                    ))}
                                  </CommandGroup>
                                </Command>
                              </div>
                            )}
                          </div>
                        </FormControl>
                        <FormDescription>
                          Choose the driver who will be making the delivery.
                        </FormDescription>
                        <FormMessage />
                        <div className="mt-1">
                          <Button
                            type="button"
                            variant="link"
                            className="p-0 h-auto text-xs"
                            onClick={() => navigate("/dashboard/add-driver")}
                          >
                            Driver not listed? Add a new driver
                          </Button>
                        </div>
                      </FormItem>
                    )}
                  />

                  {/* Shipment Select */}
                  <FormField
                    control={form.control}
                    name="shipment_id"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Select Shipment *</FormLabel>
                        <FormControl>
                          <div className="relative">
                            <Button
                              variant="outline"
                              role="combobox"
                              data-shipment-button
                              className={cn(
                                "w-full justify-between",
                                !field.value && "text-muted-foreground"
                              )}
                              onClick={() => setIsShipmentOpen(!isShipmentOpen)}
                            >
                              {field.value
                                ? shipmentOptions
                                    .find(
                                      (shipment) =>
                                        shipment.load_id === field.value
                                    )
                                    ?.load_id.substring(0, 12) + "..."
                                : "Select shipment..."}
                              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                            {isShipmentOpen && (
                              <div
                                className="absolute top-full left-0 right-0 mt-1 bg-white border rounded-md shadow-lg z-50"
                                data-command-menu
                              >
                                <Command>
                                  <CommandInput
                                    placeholder="Search shipment..."
                                    data-command-input
                                  />
                                  <CommandEmpty>
                                    No shipment found.
                                  </CommandEmpty>
                                  <CommandGroup className="max-h-[300px] overflow-auto">
                                    {shipmentOptions.map((shipment) => (
                                      <CommandItem
                                        key={shipment.load_id}
                                        value={String(shipment.load_id)}
                                        onSelect={() => {
                                          field.onChange(
                                            String(shipment.load_id)
                                          );
                                          setIsShipmentOpen(false);
                                        }}
                                      >
                                        <Check
                                          className={cn(
                                            "mr-2 h-4 w-4",
                                            String(shipment.load_id) ===
                                              String(field.value)
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                        {shipment.load_id.substring(0, 12)}...
                                      </CommandItem>
                                    ))}
                                  </CommandGroup>
                                </Command>
                              </div>
                            )}
                          </div>
                        </FormControl>
                        <FormDescription>
                          Select the shipment associated with this verification.
                        </FormDescription>
                        <FormMessage />
                        <div className="mt-1">
                          <Button
                            type="button"
                            variant="link"
                            className="p-0 h-auto text-xs"
                            onClick={() => navigate("/dashboard/add-shipment")}
                          >
                            Shipment not listed? Add a new shipment
                          </Button>
                        </div>
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid gap-6 sm:grid-cols-2">
                  {/* Phone Number */}
                  <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Phone Number *</FormLabel>
                        <FormControl>
                          <Input placeholder="(555) 555-5555" {...field} />
                        </FormControl>
                        <FormDescription>
                          The driver's phone number for receiving verification
                          text messages.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Date and Time (combined) */}
                  <FormField
                    control={form.control}
                    name="deliveryDateTime"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Date & Time of Delivery *</FormLabel>
                        <FormControl>
                          <SimpleDateTimePicker
                            value={field.value}
                            onChange={field.onChange}
                            minDate={new Date()}
                            placeholderText="Select date and time"
                          />
                        </FormControl>
                        <FormDescription>
                          Expected delivery date and time. Must be in the
                          future.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                {/* Error message */}
                {error && (
                  <Alert variant="destructive">
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}

                {/* Success message */}
                {success && (
                  <Alert className="bg-green-50 border-green-200 text-green-800">
                    <AlertTitle>Success</AlertTitle>
                    <AlertDescription>
                      Verification link sent via SMS. Redirecting to
                      verifications page...
                    </AlertDescription>
                  </Alert>
                )}

                <Button
                  type="submit"
                  className="w-full"
                  disabled={isSubmitting || success}
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                      Processing...
                    </>
                  ) : success ? (
                    <>
                      <CheckCircle className="h-4 w-4 mr-2" />
                      Verification Scheduled
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>

        {/* Right panel with scheduled drivers */}
        <div className="md:col-span-1">
          {/* Reusing the ScheduledDrivers component */}
          {React.createElement(require("./ScheduledDrivers").default)}
        </div>
      </div>
    </div>
  );
}
