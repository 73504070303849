// @ts-nocheck
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";
import {
  Building2,
  FileDigit,
  CheckCircle,
  Truck,
  ShieldAlert,
  Users,
  Gauge,
  ClipboardCheck,
  ClipboardList,
} from "lucide-react";
import { motion } from "framer-motion";

/**
 * FMCSA Cargo Theft Guidelines Heuristic:
 *
 * We now focus solely on the Safety Rating. The Safety Rating field is colored red if:
 *   - Total Drivers is 0 or null.
 *   - Total Power Units is 0, null, or "none".
 *   - Driver Inspections is 0 or null.
 *   - Vehicle Inspections is 0 or null.
 *
 * Otherwise, the Safety Rating is determined by its value:
 *   - Red if Safety Rating < 3.
 *   - Green if Safety Rating >= 3.
 *
 * All other fields display a neutral gray text.
 *
 * Reference:
 *   [FreightWaves - Lawmakers look at expanding FMCSA's power to rein in cargo theft](https://www.freightwaves.com/news/lawmakers-look-at-expanding-fmcsas-power-to-rein-in-cargo-theft)
 */

const getSafetyRatingColor = (carrier) => {
  const {
    totalDrivers,
    totalPowerUnits,
    driverInsp,
    vehicleInsp,
    safetyRating,
  } = carrier;

  // Check critical fields; if any are missing/zero, color the Safety Rating red.
  if (
    !totalDrivers ||
    Number(totalDrivers) === 0 ||
    !totalPowerUnits ||
    String(totalPowerUnits).toLowerCase() === "none" ||
    Number(totalPowerUnits) === 0 ||
    !driverInsp ||
    Number(driverInsp) === 0 ||
    !vehicleInsp ||
    Number(vehicleInsp) === 0
  ) {
    return "text-red-500";
  }

  // Otherwise, base color on Safety Rating value.
  return Number(safetyRating) < 3 ? "text-red-500" : "text-green-500";
};

const getRiskColor = (label, value, carrier) => {
  // Apply dynamic color logic only to the Safety Rating row.
  if (label === "Safety Rating") {
    return getSafetyRatingColor(carrier);
  }
  return "text-gray-600"; // Darker neutral color for better readability
};

const getBackgroundColor = (label, carrier) => {
  if (label === "Safety Rating") {
    return getSafetyRatingColor(carrier) === "text-red-500"
      ? "bg-red-50"
      : "bg-green-50";
  }
  return "";
};

const getIcon = (label) => {
  const iconProps = { size: 18, className: "text-gray-500" };

  switch (label) {
    case "Legal Name":
      return <Building2 {...iconProps} />;
    case "DOT Number":
      return <FileDigit {...iconProps} />;
    case "Allowed to Operate":
      return <CheckCircle {...iconProps} />;
    case "Operation":
      return <Truck {...iconProps} />;
    case "Safety Rating":
      return <ShieldAlert {...iconProps} />;
    case "Total Drivers":
      return <Users {...iconProps} />;
    case "Total Power Units":
      return <Gauge {...iconProps} />;
    case "Driver Inspections":
      return <ClipboardCheck {...iconProps} />;
    case "Vehicle Inspections":
      return <ClipboardList {...iconProps} />;
    default:
      return null;
  }
};

const getSafetyRatingBadge = (rating, colorClass) => {
  if (!rating) return null;

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${colorClass} ${
        colorClass === "text-red-500" ? "bg-red-100" : "bg-green-100"
      }`}
    >
      {rating}
    </span>
  );
};

const CarrierBasicInfoTable = ({ carrier }) => {
  if (!carrier) {
    return (
      <p className="text-gray-500 italic">No carrier information available.</p>
    );
  }

  const rows = [
    { label: "Legal Name", value: carrier.legalName },
    { label: "DOT Number", value: carrier.dotNumber },
    { label: "Allowed to Operate", value: carrier.allowedToOperate },
    {
      label: "Operation",
      value: carrier.carrierOperation?.carrierOperationDesc,
    },
    { label: "Safety Rating", value: carrier.safetyRating },
    { label: "Total Drivers", value: carrier.totalDrivers },
    { label: "Total Power Units", value: carrier.totalPowerUnits },
    { label: "Driver Inspections", value: carrier.driverInsp },
    { label: "Vehicle Inspections", value: carrier.vehicleInsp },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="rounded-lg border border-gray-200 shadow-sm overflow-hidden"
    >
      <Table className="w-full">
        <TableHeader>
          <TableRow className="bg-gray-50">
            <TableHead className="py-3 px-4 text-gray-700">Field</TableHead>
            <TableHead className="py-3 px-4 text-gray-700">Value</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow
              key={idx}
              className={`hover:bg-gray-50 transition-colors ${getBackgroundColor(
                row.label,
                carrier
              )}`}
            >
              <TableCell className="py-3 px-4 font-medium text-gray-700 flex items-center space-x-2">
                {getIcon(row.label)}
                <span>{row.label}</span>
              </TableCell>
              <TableCell
                className={`py-3 px-4 ${getRiskColor(
                  row.label,
                  row.value,
                  carrier
                )} font-medium`}
              >
                {row.label === "Safety Rating"
                  ? getSafetyRatingBadge(
                      row.value,
                      getRiskColor(row.label, row.value, carrier)
                    ) || "N/A"
                  : row.value || "N/A"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </motion.div>
  );
};

export default CarrierBasicInfoTable;
