import React from "react";
import { Copy, Eye } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useNavigate } from "react-router-dom";

// Helper function to copy text to clipboard
const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

// Helper function to truncate text
const truncateText = (text, maxLength = 10) => {
  if (!text) return "";
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

// Separate component for the Actions cell to properly use the useNavigate hook
const ActionCell = ({ verification }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    // Navigate to verification details page
    if (verification.shipment_id) {
      navigate(`/verification/${verification.shipment_id}`);
    } else if (verification.id) {
      navigate(`/verification/${verification.id}`);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8"
              onClick={handleViewDetails}
            >
              <Eye className="h-4 w-4" />
              <span className="sr-only">View details</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>View details</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

// --- Reusable Column Definitions ---

const verificationIdColumn = {
  id: "verification_id",
  header: "Verification ID",
  accessorKey: "id", // Use 'id' as it seems to be the common identifier
  cell: ({ row }) => {
    const id = row.original.id || ""; // Access directly from original data
    return (
      <div className="flex items-center space-x-2">
        <span className="font-mono text-sm">{truncateText(id, 8)}</span>
        {id && ( // Only show copy button if ID exists
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-6 w-6"
                  onClick={() => copyToClipboard(id)}
                >
                  <Copy className="h-3.5 w-3.5" />
                  <span className="sr-only">Copy ID</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Copy ID: {id}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    );
  },
};

const phoneNumberColumn = {
  id: "phone_number",
  header: "Phone Number",
  accessorKey: "phone_number",
  cell: ({ row }) => {
    const phoneNumber = row.getValue("phone_number") || "";
    return <span className="text-sm">{phoneNumber || "—"}</span>; // Display dash if empty
  },
};

const shipmentIdColumn = {
  id: "shipment_id",
  header: "Shipment ID",
  accessorKey: "shipment_id",
  cell: ({ row }) => {
    const shipmentId = row.getValue("shipment_id") || "";
    if (!shipmentId) return <span className="text-muted-foreground"></span>;

    return (
      <div className="flex items-center space-x-2">
        <span className="font-mono text-sm">{truncateText(shipmentId, 8)}</span>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="h-6 w-6"
                onClick={() => copyToClipboard(shipmentId)}
              >
                <Copy className="h-3.5 w-3.5" />
                <span className="sr-only">Copy Shipment ID</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Copy Shipment ID: {shipmentId}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    );
  },
};

const carrierIdColumn = {
  id: "carrier_id",
  header: "Carrier ID",
  accessorKey: "carrier_id",
  cell: ({ row }) => {
    const carrierId = row.getValue("carrier_id")?.toString() || ""; // Ensure string conversion
    if (!carrierId) return <span className="text-muted-foreground"></span>;

    return (
      <div className="flex items-center space-x-2">
        <span className="font-mono text-sm">{truncateText(carrierId, 8)}</span>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="h-6 w-6"
                onClick={() => copyToClipboard(carrierId)}
              >
                <Copy className="h-3.5 w-3.5" />
                <span className="sr-only">Copy Carrier ID</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Copy Carrier ID: {carrierId}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    );
  },
};

// Base column for actions that all verification types will have
const actionsColumn = {
  id: "actions",
  header: "Actions",
  cell: ({ row }) => {
    return <ActionCell verification={row.original} />;
  },
};

// --- Dynamic Column Generation based on Group ---

// Function now accepts activeGroup instead of verificationType
export const getVerificationColumns = (activeGroup) => {
  // Always include Verification ID and Actions
  const baseColumns = [verificationIdColumn, phoneNumberColumn];
  const endColumns = [actionsColumn];

  switch (activeGroup) {
    case "driver_verification":
      return [...baseColumns, shipmentIdColumn, ...endColumns];
    case "business_verification":
    case "risk_report":
      return [...baseColumns, carrierIdColumn, ...endColumns];
    default:
      console.warn(
        `Unknown verification group: ${activeGroup}. Using default columns.`
      );
      return [...baseColumns, shipmentIdColumn, carrierIdColumn, ...endColumns]; // Or adjust as needed
  }
};
