import React, { useEffect, useState, useRef } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import ycombinator from "../../../public/static/logo/ycombinator.png";
import lynx from "../../../public/static/logo/lynx.png";
import lloydslab from "../../../public/static/logo/lloydslab.png";
import globalcfs from "../../../public/static/logo/globalcfs.png";
import logosdistribution from "../../../public/static/logo/logosdistribution.png";

const logos = [
  { id: 1, src: ycombinator, alt: "Y Combinator" },
  { id: 2, src: lynx, alt: "Lynx" },
  { id: 3, src: lloydslab, alt: "Lloyd's Lab" },
  { id: 4, src: globalcfs, alt: "Global CFS" },
  { id: 5, src: logosdistribution, alt: "Logos" },
];

export default function SocialProof() {
  const [containerWidth, setContainerWidth] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  // Calculate the widths needed for the infinite scroll
  useEffect(() => {
    if (containerRef.current && contentRef.current) {
      const updateWidths = () => {
        setContainerWidth(containerRef.current.offsetWidth);
        setContentWidth(contentRef.current.offsetWidth / 3); // Divided by 3 because we have 3 sets of logos
      };
      
      updateWidths();
      window.addEventListener('resize', updateWidths);
      
      return () => window.removeEventListener('resize', updateWidths);
    }
  }, []);

  // Animation for the continuous scroll with reset
  const { x } = useSpring({
    from: { x: 0 },
    to: { x: -contentWidth },
    reset: true,
    reverse: false,
    config: {
      duration: 30000, // Adjust speed as needed
      easing: t => t,
    },
    loop: true,
    immediate: contentWidth === 0,
  });

  // Duplicate logos for seamless infinite scroll (3x for better safety)
  const duplicatedLogos = [...logos, ...logos, ...logos];

  // Animation for the heading
  const headingAnimation = useSpring({
    from: { opacity: 0, y: 20 },
    to: { opacity: 1, y: 0 },
    config: config.gentle,
  });

  return (
    <div
      className="w-full overflow-hidden relative -mt-1"
      style={{
        background: "rgb(17, 24, 39)",
      }}
    >
      {/* Top gradient blob decoration - exactly like Hero */}
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80 pointer-events-none"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        />
      </div>

      {/* Right side gradient blob decoration - exactly like Hero */}
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -right-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-right-80 pointer-events-none"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative right-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] translate-x-1/2 rotate-[140deg] bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-20 sm:right-[calc(50%-30rem)] sm:w-[72.1875rem]"
        />
      </div>

      <div className="relative z-10 max-w-7xl mx-auto px-6 lg:px-8">
        {/* Heading */}
        <animated.div style={headingAnimation} className="mb-16">
          <h2 className="text-white text-center text-4xl font-semibold">
            Trusted by Industry Leaders
          </h2>
          <p className="text-gray-400 text-center mt-4 max-w-2xl mx-auto">
            Partnering with top organizations to deliver secure and reliable
            solutions
          </p>
        </animated.div>

        {/* Logo marquee container */}
        <div className="relative overflow-hidden mx-auto" ref={containerRef}>
          {/* Logo container */}
          <div className="relative h-32 overflow-hidden">
            <animated.div
              ref={contentRef}
              className="flex items-center h-full"
              style={{
                transform: x.to(value => `translateX(${value}px)`),
                width: "max-content",
              }}
            >
              {duplicatedLogos.map((logo, index) => (
                <div
                  key={`${logo.id}-${index}`}
                  className="flex flex-col items-center mx-12 transition-all duration-300"
                >
                  <div className="relative">
                    {/* Glow effect on hover */}
                    <div
                      className="absolute inset-0 rounded-full blur-xl opacity-0 hover:opacity-70 transition-opacity duration-300"
                      style={{
                        background:
                          "linear-gradient(to right, rgba(20, 53, 96, 0.3), rgba(17, 157, 165, 0.3))",
                      }}
                    />
                    <img
                      src={logo.src}
                      alt={logo.alt}
                      className="h-16 w-auto object-contain grayscale hover:grayscale-0 hover:scale-110 transition-all duration-500"
                      loading="lazy"
                    />
                  </div>
                </div>
              ))}
            </animated.div>
          </div>
        </div>
      </div>

      {/* Bottom gradient blob decoration - exactly like Hero */}
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
      </div>

      {/* Additional Left side gradient blob decoration */}
      <div
        aria-hidden="true"
        className="absolute -left-20 top-1/3 -z-10 transform-gpu overflow-hidden blur-3xl pointer-events-none"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="absolute h-56 w-56 -translate-x-1/2 rotate-[15deg] bg-gradient-to-tr from-[#143560] to-[#119DA5] opacity-10"
        />
      </div>

      {/* Smooth transition gradient at the bottom */}
      <div
        className="absolute bottom-0 left-0 right-0 h-24 pointer-events-none"
        style={{
          background:
            "linear-gradient(to bottom, rgba(17, 24, 39, 0) 0%, rgba(15, 23, 42, 1) 100%)",
          zIndex: 5,
        }}
      />
    </div>
  );
}
