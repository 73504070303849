// @ts-nocheck
import React, { useState } from "react";
import InputField from "../../Components/InputField";
import { useGetUserSession } from "../../../hooks/useUsers";
import { useCreateCargoOwner } from "../../../hooks/useCargoOwner";
import { useNavigate } from "react-router-dom";

const AddCargoOwner = () => {
  const { session, loading } = useGetUserSession();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    company_name: "",
    phone_number: "",
    email: "",
    is_active: true,
    industry: "",
  });

  const navigate = useNavigate();
  const { mutate: createCargoOwner } = useCreateCargoOwner();

  if (!session) {
    navigate("/signin");
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form", formData);

    try {
      createCargoOwner(formData, {
        onSuccess: (result) => {
          console.log("Success Result:", result);
          setMessage(`Cargo owner added successfully.`);
          setError("");
        },
        onError: (error) => {
          const errorMessage =
            error?.response?.data?.message ||
            error?.message ||
            "An unexpected error occurred. Please try again.";
          setError(errorMessage);
          console.error("Error Details:", {
            status: error?.response?.status,
            data: error?.response?.data,
            message: error?.message,
            fullError: error,
          });
        },
      });
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "An unexpected error occurred. Please try again.";
      setError(errorMessage);
      console.error("Error Details:", {
        status: error?.response?.status,
        data: error?.response?.data,
        message: error?.message,
        fullError: error,
      });
    }
  };

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg border border-slate-300 shadow-md">
      <div className="flex flex-col md:flex-row gap-6 pb-2">
        <div className="md:w-1/6 flex justify-center items-start">
          <img
            src="/static/warranty.png"
            alt="Warranty"
            className="w-16 h-16 rounded-full"
          />
        </div>
        <div className="md:w-5/6 flex flex-col gap-6">
          <div className="space-y-2">
            <h2 className="text-slate-900 text-xl font-semibold">
              Add Cargo Owner
            </h2>
            <p className="text-slate-500 text-sm">
              Enter cargo owner information.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
              <InputField
                label="Company Name"
                htmlFor="company_name"
                placeholder="Company Name"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
              />
              <InputField
                label="Phone Number"
                htmlFor="phone_number"
                placeholder="123-456-7890"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
              />
              <InputField
                label="Email"
                htmlFor="email"
                placeholder="carrier@example.com"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <InputField
                label="Industry"
                htmlFor="industry"
                placeholder="Industry"
                name="industry"
                value={formData.industry}
                onChange={handleInputChange}
              />
            </div>

            {error && <p className="text-red-500 mt-6">{error}</p>}
            {message && <p className="text-green-500 mt-6">{message}</p>}

            <div className="space-y-4">
              <button
                type="submit"
                className="w-full px-4 py-2 bg-slate-900 text-white text-sm font-medium rounded-md hover:bg-slate-800 transition-colors"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCargoOwner;
