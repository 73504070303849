// @ts-check
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ReportService } from "../api/services/core/ReportService";

/**
 * Hook to fetch paginated reports
 * @param {number} page - The page number to fetch
 * @param {number} limit - Number of items per page
 * @returns {Object} The query result containing reports
 */
export const useReports = (page = 1, limit = 10) => {
  return useQuery({
    queryKey: ["reports", page, limit],
    queryFn: () => ReportService.getReports(page, limit),
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to fetch all reports (unpaginated)
 * @returns {Object} The query result containing all reports
 */
export const useGetAllReports = () => {
  return useQuery({
    queryKey: ["reports", "all"],
    queryFn: () => ReportService.getAllReports(),
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook to fetch a specific report by ID
 * @param {string} id - The report ID to fetch
 * @returns {Object} The query result containing report details
 */
export const useReport = (id) => {
  return useQuery({
    queryKey: ["report", id],
    queryFn: () => ReportService.getReport(id),
    enabled: !!id,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
  });
};

/**
 * Hook providing all report-related mutations
 * @returns {Object} Mutation functions for report operations
 */
export const useReportMutations = () => {
  const queryClient = useQueryClient();

  /**
   * Helper to invalidate report queries
   * @param {string} id - Optional report ID to invalidate specific queries
   */
  const invalidateReports = async (id) => {
    await queryClient.invalidateQueries({ queryKey: ["reports"] });
    if (id) await queryClient.invalidateQueries({ queryKey: ["report", id] });
  };

  const createReport = useMutation({
    mutationFn: ReportService.createReport,
    onSuccess: () => invalidateReports(),
    onError: (error) => {
      console.error("Error creating report:", error);
    },
  });

  const updateReport = useMutation({
    mutationFn: ({ id, reportData }) =>
      ReportService.updateReport(id, reportData),
    onSuccess: (_, { id }) => invalidateReports(id),
    onError: (error) => {
      console.error("Error updating report:", error);
    },
  });

  const deleteReport = useMutation({
    mutationFn: ReportService.deleteReport,
    onSuccess: (_, id) => invalidateReports(id),
    onError: (error) => {
      console.error("Error deleting report:", error);
    },
  });

  const updateReportStatus = useMutation({
    mutationFn: ({ id, status }) => {
      console.log("Triggering updateReportStatus hook", id, status);
      return ReportService.updateReportStatus(id, status);
    },
    onSuccess: (_, { id }) => invalidateReports(id),
    onError: (error) => {
      console.error("Error updating report status:", error);
    },
  });

  return {
    createReport,
    updateReport,
    deleteReport,
    updateReportStatus,
  };
};
