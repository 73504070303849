import React from "react";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
} from "@/components/ui/form";
import { MapboxAddressInput } from "@/components/ui/mapbox-address-input";
import { MapPin } from "lucide-react";
import { Separator } from "@/components/ui/separator";

/**
 * The second step of the shipment form for entering origin and destination addresses
 */
export function RouteInformationStep({ form }) {
  return (
    <div className="grid gap-6">
      <div className="space-y-2">
        <div className="flex items-center">
          <MapPin className="h-5 w-5 text-green-600 mr-2" />
          <h3 className="text-lg font-medium">Origin</h3>
        </div>
        <Separator />
      </div>

      <FormField
        control={form.control}
        name="origin_address"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Origin Address *</FormLabel>
            <FormControl>
              <MapboxAddressInput
                placeholder="1 Apple Way, Cupertino, CA 95014"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                onAddressSelected={(address) => {
                  field.onChange(address.formatted_address);
                  form.setValue("origin_address", address.formatted_address, {
                    shouldValidate: true,
                  });
                }}
              />
            </FormControl>
            <FormDescription>
              Enter the full address where the shipment will be picked up from
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="space-y-2 mt-6">
        <div className="flex items-center">
          <MapPin className="h-5 w-5 text-red-600 mr-2" />
          <h3 className="text-lg font-medium">Destination</h3>
        </div>
        <Separator />
      </div>

      <FormField
        control={form.control}
        name="destination_address"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Destination Address *</FormLabel>
            <FormControl>
              <MapboxAddressInput
                placeholder="1 Google Way, Mountain View, CA 94043"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                onAddressSelected={(address) => {
                  field.onChange(address.formatted_address);
                  form.setValue(
                    "destination_address",
                    address.formatted_address,
                    {
                      shouldValidate: true,
                    }
                  );
                }}
              />
            </FormControl>
            <FormDescription>
              Enter the full address where the shipment will be delivered to
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}
