// @ts-nocheck
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";
import { FileText, Hash, FileDigit, AlignLeft, FileSearch } from "lucide-react";
import { motion } from "framer-motion";

const DocketNumbersTable = ({ docketNumbersContent = [] }) => {
  if (
    !Array.isArray(docketNumbersContent) ||
    docketNumbersContent.length === 0
  ) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="p-6 text-center bg-gray-50 rounded-lg border border-gray-200"
      >
        <FileSearch className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          No Docket Numbers
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          No docket numbers are available for this carrier.
        </p>
      </motion.div>
    );
  }

  const columns = [
    {
      name: "Docket Number",
      icon: <FileText size={16} className="text-gray-500" />,
    },
    {
      name: "Docket Number ID",
      icon: <Hash size={16} className="text-gray-500" />,
    },
    {
      name: "DOT Number",
      icon: <FileDigit size={16} className="text-gray-500" />,
    },
    { name: "Prefix", icon: <AlignLeft size={16} className="text-gray-500" /> },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="rounded-lg border border-gray-200 shadow-sm overflow-hidden"
    >
      <Table className="w-full">
        <TableHeader>
          <TableRow className="bg-gray-50">
            {columns.map((column, idx) => (
              <TableHead
                key={idx}
                className="py-3 px-4 text-sm font-semibold text-gray-700"
              >
                <div className="flex items-center space-x-2">
                  {column.icon}
                  <span>{column.name}</span>
                </div>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {docketNumbersContent.map((item, idx) => (
            <TableRow key={idx} className="hover:bg-gray-50 transition-colors">
              <TableCell className="py-3 px-4 text-gray-600 font-medium">
                {item.docketNumber || "N/A"}
              </TableCell>
              <TableCell className="py-3 px-4 text-gray-600 font-medium">
                {item.docketNumberId || "N/A"}
              </TableCell>
              <TableCell className="py-3 px-4 text-gray-600 font-medium">
                {item.dotNumber || "N/A"}
              </TableCell>
              <TableCell className="py-3 px-4 text-gray-600 font-medium">
                {item.prefix || "N/A"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </motion.div>
  );
};

export default DocketNumbersTable;
