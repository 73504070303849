// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useShipments } from "../../hooks/shipments";
import { Loader2Icon } from "lucide-react";
import { Card, CardContent } from "../Components/Card";
import { Button } from "../Components/Button";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../Components/Tabs";
import {
  FiDownload,
  FiRefreshCw,
  FiPackage,
  FiTruck,
  FiCheckCircle,
  FiPieChart,
  FiList,
  FiLoader,
  FiLogOut,
  FiSettings,
  FiCalendar,
  FiUser,
  FiGrid,
} from "react-icons/fi";
import Analytics from "./Analytics";
import ShipmentList from "./ShipmentList";
import Toast from "../Components/Toast";
import { useAuth } from "../../providers/AuthProvider";
import { supabase } from "../../lib/supabase";
import ScheduleDelivery from "./ScheduleDelivery";
import Settings from "./Settings";

const ExternalDashboard = () => {
  const { id: cargoOwnerId } = useParams();
  const { loading, externalUser, session } = useAuth();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("shipments");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [filters] = useState({
    limit: 100,
    orderBy: "created_at",
    orderDirection: "desc",
    cargoOwnerId: cargoOwnerId,
  });
  console.log(session);

  const {
    data: { shipments: shipmentsData, total } = { shipments: [], total: 0 },
    isLoading,
    error,
    refetch,
  } = useShipments(1, 100, filters);

  // Updated authorization check
  useEffect(() => {
    if (loading) return; // Wait until loading is complete

    if (!externalUser || !externalUser.cargo_owner_id) {
      navigate("/unauthorized");
      return;
    }

    const hasAccess = externalUser.cargo_owner_id === Number(cargoOwnerId);
    if (!hasAccess) {
      navigate("/unauthorized");
    }
  }, [externalUser, cargoOwnerId, loading, navigate]);

  // Update stats to use the correct data structure
  const stats = {
    totalShipments: total || 0,
    inTransit:
      shipmentsData?.filter((s) => s.status === "Unverified").length || 0,
    completed:
      shipmentsData?.filter((s) => s.status === "Verified").length || 0,
    recentUpdates: shipmentsData?.slice(0, 5) || [],
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await refetch();
    setTimeout(() => setIsRefreshing(false), 800); // Add a slight delay for better UX
  };

  const handleLogout = () => {
    supabase.auth.signOut();
    navigate("/external/login");
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-slate-900 to-slate-800 flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-4">
          <Loader2Icon className="w-12 h-12 animate-spin text-blue-500" />
          <p className="text-white text-lg animate-pulse">
            Loading your dashboard...
          </p>
        </div>
      </div>
    );
  }

  if (!externalUser) {
    Toast.error("Unauthorized access");
    return null;
  }

  if (error) {
    Toast.error(error.message);
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-slate-100 dark:from-slate-900 dark:to-slate-800 transition-colors duration-300">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-10 bg-white dark:bg-slate-800 shadow-md transition-all duration-300">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <div className="bg-blue-600 w-10 h-10 rounded-lg flex items-center justify-center shadow-lg">
                <FiGrid className="w-5 h-5 text-white" />
              </div>
              <h1 className="text-2xl font-bold text-slate-800 dark:text-white">
                Procept Biorobotics
              </h1>
            </div>
            <div className="flex items-center space-x-4">
              <div className="hidden md:flex items-center p-2 rounded-full bg-slate-100 dark:bg-slate-700 text-slate-600 dark:text-slate-200">
                <FiUser className="w-4 h-4 mr-2" />
                <span className="text-sm font-medium">Omar</span>
              </div>
              <Button
                onClick={handleRefresh}
                variant="ghost"
                className="rounded-full p-2 h-10 w-10 flex items-center justify-center hover:bg-slate-100 dark:hover:bg-slate-700 transition-colors"
                disabled={isRefreshing}
              >
                <FiRefreshCw
                  className={`w-5 h-5 text-slate-600 dark:text-slate-200 ${
                    isRefreshing ? "animate-spin" : ""
                  }`}
                />
              </Button>
              <Button
                onClick={handleLogout}
                variant="outline"
                className="rounded-full bg-white dark:bg-transparent border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-700 text-slate-700 dark:text-slate-200 transition-all duration-200 flex items-center gap-2"
              >
                <FiLogOut className="w-4 h-4" />
                <span className="hidden sm:inline">Logout</span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 pt-24">
        <div className="space-y-8">
          {/* Welcome message */}
          <div className="bg-gradient-to-r from-blue-600 to-blue-400 rounded-2xl shadow-xl p-6 md:p-8 mb-8">
            <div className="flex flex-col md:flex-row md:items-center justify-between">
              <div className="mb-4 md:mb-0">
                <h2 className="text-lg md:text-2xl font-bold text-white mb-2">
                  Welcome back, Omar
                </h2>
                <p className="text-blue-100 text-sm md:text-base">
                  Get an overview of your shipments and logistics operations.
                </p>
              </div>
              <Button
                onClick={handleRefresh}
                className="bg-white/20 hover:bg-white/30 text-white rounded-xl transition-all duration-200 backdrop-blur-sm self-start"
              >
                <FiRefreshCw
                  className={`w-4 h-4 mr-2 ${
                    isRefreshing ? "animate-spin" : ""
                  }`}
                />
                Refresh Data
              </Button>
            </div>
          </div>

          {/* Stats Overview */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <Card className="bg-white dark:bg-slate-800 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-200 border-none">
              <CardContent className="p-0">
                <div className="bg-gradient-to-r from-blue-600 to-blue-500 h-2"></div>
                <div className="flex items-center p-6">
                  <div className="p-4 rounded-xl bg-blue-100 dark:bg-blue-900/30 mr-5 shadow-sm">
                    <FiPackage className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm font-medium text-slate-500 dark:text-slate-400">
                      Total Shipments Booked
                    </p>
                    <div className="flex items-center">
                      <p className="text-2xl font-bold text-slate-800 dark:text-white">
                        {isLoading ? (
                          <FiLoader className="w-6 h-6 animate-spin" />
                        ) : (
                          stats.totalShipments
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card className="bg-white dark:bg-slate-800 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-200 border-none">
              <CardContent className="p-0">
                <div className="bg-gradient-to-r from-amber-500 to-amber-400 h-2"></div>
                <div className="flex items-center p-6">
                  <div className="p-4 rounded-xl bg-amber-100 dark:bg-amber-900/30 mr-5 shadow-sm">
                    <FiTruck className="w-6 h-6 text-amber-600 dark:text-amber-400" />
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm font-medium text-slate-500 dark:text-slate-400">
                      Unverified
                    </p>
                    <div className="flex items-center">
                      <p className="text-2xl font-bold text-slate-800 dark:text-white">
                        {isLoading ? (
                          <FiLoader className="w-6 h-6 animate-spin" />
                        ) : (
                          stats.inTransit
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>

            <Card className="bg-white dark:bg-slate-800 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-200 border-none">
              <CardContent className="p-0">
                <div className="bg-gradient-to-r from-emerald-600 to-emerald-500 h-2"></div>
                <div className="flex items-center p-6">
                  <div className="p-4 rounded-xl bg-emerald-100 dark:bg-emerald-900/30 mr-5 shadow-sm">
                    <FiCheckCircle className="w-6 h-6 text-emerald-600 dark:text-emerald-400" />
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm font-medium text-slate-500 dark:text-slate-400">
                      Completed
                    </p>
                    <div className="flex items-center">
                      <p className="text-2xl font-bold text-slate-800 dark:text-white">
                        {isLoading ? (
                          <FiLoader className="w-6 h-6 animate-spin" />
                        ) : (
                          stats.completed
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Main Content Tabs */}
          <Card className="bg-white dark:bg-slate-800 rounded-xl shadow-lg border-none overflow-hidden">
            <CardContent className="p-6">
              <Tabs
                value={activeTab}
                onValueChange={setActiveTab}
                className="w-full"
              >
                <TabsList className="mb-6 bg-slate-100 dark:bg-slate-700/50 p-1 rounded-lg w-full flex justify-between items-stretch">
                  <TabsTrigger
                    value="shipments"
                    className="flex-1 flex items-center justify-center px-4 py-3 rounded-lg text-sm font-medium text-slate-600 dark:text-slate-300 data-[state=active]:bg-white dark:data-[state=active]:bg-slate-800 data-[state=active]:text-blue-600 dark:data-[state=active]:text-blue-400 data-[state=active]:shadow-sm hover:text-blue-500 dark:hover:text-blue-300 transition-all duration-200"
                  >
                    <FiList className="w-4 h-4 mr-2" />
                    <span>Shipment List</span>
                  </TabsTrigger>
                  <TabsTrigger
                    value="overview"
                    className="flex-1 flex items-center justify-center px-4 py-3 rounded-lg text-sm font-medium text-slate-600 dark:text-slate-300 data-[state=active]:bg-white dark:data-[state=active]:bg-slate-800 data-[state=active]:text-blue-600 dark:data-[state=active]:text-blue-400 data-[state=active]:shadow-sm hover:text-blue-500 dark:hover:text-blue-300 transition-all duration-200"
                  >
                    <FiPieChart className="w-4 h-4 mr-2" />
                    <span>Analytics Overview</span>
                  </TabsTrigger>
                  <TabsTrigger
                    value="schedule"
                    className="flex-1 flex items-center justify-center px-4 py-3 rounded-lg text-sm font-medium text-slate-600 dark:text-slate-300 data-[state=active]:bg-white dark:data-[state=active]:bg-slate-800 data-[state=active]:text-blue-600 dark:data-[state=active]:text-blue-400 data-[state=active]:shadow-sm hover:text-blue-500 dark:hover:text-blue-300 transition-all duration-200"
                  >
                    <FiCalendar className="w-4 h-4 mr-2" />
                    <span>Schedule a Delivery</span>
                  </TabsTrigger>
                </TabsList>

                <div className="mt-6">
                  <TabsContent
                    value="overview"
                    className="focus-visible:outline-none focus-visible:ring-0"
                  >
                    <Analytics />
                  </TabsContent>

                  <TabsContent
                    value="shipments"
                    className="focus-visible:outline-none focus-visible:ring-0"
                  >
                    <ShipmentList />
                  </TabsContent>

                  <TabsContent
                    value="schedule"
                    className="focus-visible:outline-none focus-visible:ring-0"
                  >
                    <ScheduleDelivery />
                  </TabsContent>
                </div>
              </Tabs>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ExternalDashboard;
