import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Portal/Header";
import { supabase } from "../../../lib/supabase";

const CONDITION_TYPES = {
  DRIVER: [
    { label: "Driver Rating", field: "rating", type: "number" },
    { label: "Number of Deliveries", field: "delivery_count", type: "number" },
    { label: "Account Age (days)", field: "account_age", type: "number" },
  ],
  SHIPMENT: [
    { label: "Shipment Value", field: "value", type: "number" },
    { label: "Distance (miles)", field: "distance", type: "number" },
    { label: "Pickup Time", field: "pickup_time", type: "time" },
  ],
  LOCATION: [
    { label: "High Risk Area", field: "risk_area", type: "boolean" },
    { label: "Cross Border", field: "cross_border", type: "boolean" },
  ],
};

const OPERATORS = {
  number: [
    { label: "is less than", value: "<" },
    { label: "is greater than", value: ">" },
    { label: "equals", value: "=" },
  ],
  boolean: [{ label: "is", value: "=" }],
  time: [
    { label: "is before", value: "<" },
    { label: "is after", value: ">" },
  ],
};

const ACTIONS = [
  { label: "Block Shipment", value: "block", color: "bg-red-100 text-red-800" },
  {
    label: "Flag for Review",
    value: "flag",
    color: "bg-yellow-100 text-yellow-800",
  },
  {
    label: "Require Additional Verification",
    value: "verify",
    color: "bg-blue-100 text-blue-800",
  },
];

const LOGICAL_OPERATORS = [
  { label: "AND", value: "and" },
  { label: "OR", value: "or" },
];

const StepIndicator = ({ currentStep, totalSteps }) => (
  <div className="flex items-center justify-center mb-8">
    {[...Array(totalSteps)].map((_, index) => (
      <React.Fragment key={index}>
        <div
          className={`w-8 h-8 rounded-full flex items-center justify-center
          ${
            index + 1 === currentStep
              ? "bg-[#119da5] text-white"
              : index + 1 < currentStep
              ? "bg-green-100 text-green-800"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {index + 1}
        </div>
        {index < totalSteps - 1 && (
          <div
            className={`w-16 h-1 ${
              index + 1 < currentStep ? "bg-green-100" : "bg-gray-200"
            }`}
          />
        )}
      </React.Fragment>
    ))}
  </div>
);

const CreateFraudRule = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [rule, setRule] = useState({
    name: "",
    description: "",
    conditions: [
      {
        category: "",
        field: "",
        operator: "",
        value: "",
        type: "",
      },
    ],
    logicalOperator: "and",
    action: "",
  });

  const handleSubmit = async () => {
    try {
      const { error } = await supabase.from("fraud_rules").insert([
        {
          ...rule,
          status: "active",
          created_at: new Date(),
        },
      ]);

      if (error) throw error;
      navigate("/dashboard/fraud-rules");
    } catch (error) {
      console.error("Error creating rule:", error);
    }
  };

  const addCondition = () => {
    setRule({
      ...rule,
      conditions: [
        ...rule.conditions,
        {
          category: "",
          field: "",
          operator: "",
          value: "",
          type: "",
        },
      ],
    });
  };

  const removeCondition = (index) => {
    setRule({
      ...rule,
      conditions: rule.conditions.filter((_, i) => i !== index),
    });
  };

  const updateCondition = (index, updates) => {
    setRule({
      ...rule,
      conditions: rule.conditions.map((condition, i) =>
        i === index ? { ...condition, ...updates } : condition
      ),
    });
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-4 text-gray-800">
            <h3 className="text-lg font-medium text-gray-900">
              Basic Information
            </h3>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Rule Name
              </label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#119da5] focus:ring-[#119da5] text-gray-900"
                value={rule.name}
                onChange={(e) => setRule({ ...rule, name: e.target.value })}
                placeholder="e.g., High Value Shipment Check"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#119da5] focus:ring-[#119da5] text-gray-900"
                value={rule.description}
                onChange={(e) =>
                  setRule({ ...rule, description: e.target.value })
                }
                placeholder="Describe what this rule does..."
                rows={3}
              />
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6 text-gray-800">
            <h3 className="text-lg font-medium text-gray-900">
              Define Conditions
            </h3>

            {/* Logical Operator Selection */}
            {rule.conditions.length > 1 && (
              <div className="bg-gray-50 p-4 rounded-lg">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  How should these conditions be combined?
                </label>
                <div className="flex gap-4">
                  {LOGICAL_OPERATORS.map((op) => (
                    <button
                      key={op.value}
                      className={`px-4 py-2 rounded-md text-sm font-medium ${
                        rule.logicalOperator === op.value
                          ? "bg-[#119da5] text-white"
                          : "bg-white text-gray-700 border border-gray-300"
                      }`}
                      onClick={() =>
                        setRule({ ...rule, logicalOperator: op.value })
                      }
                    >
                      {op.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {/* Conditions */}
            <div className="space-y-6">
              {rule.conditions.map((condition, index) => (
                <div
                  key={index}
                  className="bg-white p-4 rounded-lg border border-gray-200"
                >
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="text-sm font-medium text-gray-900">
                      Condition {index + 1}
                    </h4>
                    {rule.conditions.length > 1 && (
                      <button
                        onClick={() => removeCondition(index)}
                        className="text-red-600 hover:text-red-800 text-sm"
                      >
                        Remove
                      </button>
                    )}
                  </div>

                  <div className="grid grid-cols-1 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Category
                      </label>
                      <select
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#119da5] focus:ring-[#119da5] text-gray-900"
                        value={condition.category}
                        onChange={(e) => {
                          updateCondition(index, {
                            category: e.target.value,
                            field: "",
                            operator: "",
                            value: "",
                          });
                        }}
                      >
                        <option value="" className="text-gray-900">
                          Select a category
                        </option>
                        {Object.keys(CONDITION_TYPES).map((category) => (
                          <option
                            key={category}
                            value={category}
                            className="text-gray-900"
                          >
                            {category}
                          </option>
                        ))}
                      </select>
                    </div>

                    {condition.category && (
                      <>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Field
                          </label>
                          <select
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#119da5] focus:ring-[#119da5] text-gray-900"
                            value={condition.field}
                            onChange={(e) => {
                              const selectedField = CONDITION_TYPES[
                                condition.category
                              ].find((f) => f.field === e.target.value);
                              updateCondition(index, {
                                field: e.target.value,
                                type: selectedField?.type,
                                operator: "",
                                value: "",
                              });
                            }}
                          >
                            <option value="" className="text-gray-900">
                              Select a field
                            </option>
                            {CONDITION_TYPES[condition.category].map(
                              (field) => (
                                <option
                                  key={field.field}
                                  value={field.field}
                                  className="text-gray-900"
                                >
                                  {field.label}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        {condition.field && (
                          <>
                            <div>
                              <label className="block text-sm font-medium text-gray-700">
                                Operator
                              </label>
                              <select
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#119da5] focus:ring-[#119da5] text-gray-900"
                                value={condition.operator}
                                onChange={(e) =>
                                  updateCondition(index, {
                                    operator: e.target.value,
                                  })
                                }
                              >
                                <option value="" className="text-gray-900">
                                  Select an operator
                                </option>
                                {OPERATORS[condition.type].map((op) => (
                                  <option
                                    key={op.value}
                                    value={op.value}
                                    className="text-gray-900"
                                  >
                                    {op.label}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div>
                              <label className="block text-sm font-medium text-gray-700">
                                Value
                              </label>
                              {condition.type === "boolean" ? (
                                <select
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#119da5] focus:ring-[#119da5] text-gray-900"
                                  value={condition.value}
                                  onChange={(e) =>
                                    updateCondition(index, {
                                      value: e.target.value,
                                    })
                                  }
                                >
                                  <option value="" className="text-gray-900">
                                    Select a value
                                  </option>
                                  <option
                                    value="true"
                                    className="text-gray-900"
                                  >
                                    True
                                  </option>
                                  <option
                                    value="false"
                                    className="text-gray-900"
                                  >
                                    False
                                  </option>
                                </select>
                              ) : (
                                <input
                                  type={
                                    condition.type === "time"
                                      ? "time"
                                      : "number"
                                  }
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#119da5] focus:ring-[#119da5] text-gray-900"
                                  value={condition.value}
                                  onChange={(e) =>
                                    updateCondition(index, {
                                      value: e.target.value,
                                    })
                                  }
                                />
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={addCondition}
                className="w-full py-2 px-4 border-2 border-dashed border-gray-300 rounded-lg text-gray-600 hover:border-[#119da5] hover:text-[#119da5] transition-colors duration-200"
              >
                + Add another condition
              </button>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-4 text-gray-800">
            <h3 className="text-lg font-medium text-gray-900">Select Action</h3>
            <div className="grid grid-cols-1 gap-4">
              {ACTIONS.map((action) => (
                <button
                  key={action.value}
                  className={`p-4 rounded-lg border-2 text-left ${
                    rule.action === action.value
                      ? "border-[#119da5] " + action.color
                      : "border-gray-200 hover:border-gray-300"
                  }`}
                  onClick={() => setRule({ ...rule, action: action.value })}
                >
                  <div className="font-medium">{action.label}</div>
                </button>
              ))}
            </div>
          </div>
        );

      case 4:
        return (
          <div className="space-y-4 text-gray-800">
            <h3 className="text-lg font-medium text-gray-900">Review Rule</h3>
            <div className="bg-gray-50 p-4 rounded-lg">
              <dl className="space-y-4">
                <div>
                  <dt className="text-sm font-medium text-gray-700">
                    Rule Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{rule.name}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-700">
                    Description
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {rule.description}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-700">
                    Conditions
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {rule.conditions.map((condition, index) => (
                      <div key={index} className="mb-2">
                        {index > 0 && (
                          <div className="my-2 text-gray-500 font-medium">
                            {rule.logicalOperator.toUpperCase()}
                          </div>
                        )}
                        {`If ${condition.category} ${condition.field} ${condition.operator} ${condition.value}`}
                      </div>
                    ))}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-700">Action</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {ACTIONS.find((a) => a.value === rule.action)?.label}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const canProceed = () => {
    switch (currentStep) {
      case 1:
        return rule.name.trim() && rule.description.trim();
      case 2:
        return rule.conditions.every(
          (condition) =>
            condition.category &&
            condition.field &&
            condition.operator &&
            condition.value
        );
      case 3:
        return rule.action;
      case 4:
        return true;
      default:
        return false;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Header
        companyName="Create Fraud Rule"
        url="/dashboard/fraud-rules"
        text="Back"
      />
      <div className="max-w-3xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg p-6">
          <StepIndicator currentStep={currentStep} totalSteps={4} />

          {renderStep()}

          <div className="mt-8 flex justify-between">
            {currentStep > 1 && (
              <button
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                Back
              </button>
            )}
            <button
              className={`px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white
                ${
                  canProceed()
                    ? "bg-[#119da5] hover:bg-[#0d8c94]"
                    : "bg-gray-300 cursor-not-allowed"
                }`}
              onClick={() => {
                if (currentStep === 4) {
                  handleSubmit();
                } else {
                  setCurrentStep(currentStep + 1);
                }
              }}
              disabled={!canProceed()}
            >
              {currentStep === 4 ? "Create Rule" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFraudRule;
