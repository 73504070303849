// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useGetUserSession } from "../../hooks/users";
import { createBusinessOwnerKYBVerification } from "../../api/services/verification/VerificationService";
import {
  getPersonaInquiry,
  getVerificationsByCarrierId,
  PersonaService,
} from "../../api/services/verification/persona/PersonaService";
import { supabase } from "../../lib/supabase";
import { sendBusinessOwnerVerificationSMS } from "../../api/services/core/NotificationService";
import { createIndividualKYBVerification } from "../../api/services/verification/VerificationService";
import { useCustomerCompanyId } from "../../hooks/useCustomers";
import { useBusinessOwners } from "../../hooks/useBusinessOwners";

// Import shadcn components
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Alert, AlertTitle, AlertDescription } from "../../components/ui/alert";
import { Form, FormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage } from "../../components/ui/form";
import { Separator } from "../../components/ui/separator";
import { Loader2, User, Check, AlertCircle, Building } from "lucide-react";

// Validation schema using zod
const businessOwnerSchema = z.object({
  business_name: z.string().min(1, { message: "Business name is required" }),
  business_owner: z.string().optional(),
  business_owner_id: z.string().min(1, { message: "Business owner is required" }),
  email: z.string().email({ message: "Please enter a valid email address" }),
  phoneNumber: z.string().min(10, { message: "Phone number should be at least 10 digits" }),
  carrier_id: z.string().min(1, { message: "Carrier ID is required" }),
  custom_business_owner: z.string().optional(),
});

const VetBusinessOwnerForm = () => {
  const { session, loading: sessionLoading } = useGetUserSession();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const carrierIdFromUrl = queryParams.get("carrier_id");

  const { data: companyId, isLoading: companyLoading } = useCustomerCompanyId(
    session?.user?.id
  );

  const [submitting, setSubmitting] = useState(false);
  const [businessOwners, setBusinessOwners] = useState([]);
  const [selectedOwnerId, setSelectedOwnerId] = useState(null);
  const [businessOwnerData, setBusinessOwnerData] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  // Get mutation functions from the hook
  const { updateBusinessOwnerMutation, upsertBusinessOwnerMutation } =
    useBusinessOwners(carrierIdFromUrl);

  // Initialize react-hook-form with zod validation
  const form = useForm({
    resolver: zodResolver(businessOwnerSchema),
    defaultValues: {
      business_name: "",
      business_owner: "",
      business_owner_id: "",
      email: session?.user?.email || "",
      phoneNumber: "",
      carrier_id: carrierIdFromUrl || "",
      custom_business_owner: "",
    },
  });

  // Fetch business owners when component mounts
  useEffect(() => {
    if (carrierIdFromUrl) {
      form.setValue("carrier_id", carrierIdFromUrl);
      fetchBusinessOwners(carrierIdFromUrl);
    }

    // Auto-fill email with the user's email if available
    if (session?.user?.email) {
      form.setValue("email", session.user.email);
    }
  }, [carrierIdFromUrl, session, form]);

  // Fetch business owners from the database
  const fetchBusinessOwners = async (carrierId) => {
    try {
      const { data, error } = await supabase
        .from("carrier_business_owners")
        .select("*")
        .eq("carrier", carrierId);

      if (error) throw error;

      setBusinessOwnerData(data || []);

      // Create a list of business owner names for the dropdown
      const ownerNames = data.map((owner) => ({
        id: owner.id,
        name: `${owner.first_name} ${owner.last_name}`,
      }));

      setBusinessOwners(ownerNames);

      // Set the first business owner as default if available
      if (ownerNames.length > 0) {
        setSelectedOwnerId(ownerNames[0].id);
        form.setValue("business_owner", ownerNames[0].name);
        form.setValue("business_owner_id", ownerNames[0].id);
      }
    } catch (error) {
      console.error("Error fetching business owners:", error);
      form.setError("business_owner_id", {
        type: "manual",
        message: "Failed to load business owners",
      });
    }
  };

  // Loading state
  if (sessionLoading || companyLoading) {
    return (
      <div className="flex items-center justify-center min-h-[300px]">
        <Loader2 className="w-8 h-8 animate-spin text-slate-700" />
      </div>
    );
  }

  const handleOwnerChange = (value) => {
    setSelectedOwnerId(value);
    form.setValue("business_owner_id", value);

    // Find the selected owner
    const selectedOwner = businessOwners.find((owner) => owner.id === value);

    if (selectedOwner) {
      form.setValue("business_owner", selectedOwner.name);

      // If it's a database owner, pre-fill email and phone
      const dbOwner = businessOwnerData.find((owner) => owner.id === value);
      if (dbOwner) {
        // Format phone number to just digits if it exists
        const phoneNumber = dbOwner.phone_number
          ? dbOwner.phone_number.replace(/\D/g, "")
          : form.getValues("phoneNumber");

        form.setValue("email", dbOwner.email || form.getValues("email"));
        form.setValue("phoneNumber", phoneNumber);
      }
    } else if (value === "custom") {
      form.setValue("business_owner", "");
    }
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    setStatusMessage("");
    setIsSuccess(false);

    try {
      // Find selected owner details from businessOwnerData
      const selectedOwner = businessOwnerData.find(
        (owner) => owner.id === selectedOwnerId
      );

      if (selectedOwnerId !== "custom" && !selectedOwner) {
        throw new Error("Selected business owner not found.");
      }

      if (!companyId) {
        throw new Error("Company ID not loaded. Cannot submit verification.");
      }

      // Prepare data for the API call based on whether it's a custom owner or existing one
      const verificationData = selectedOwnerId === "custom" 
        ? {
            carrierId: data.carrier_id,
            companyId: companyId,
            // Split custom name into first and last name
            firstName: data.custom_business_owner.split(" ")[0] || "",
            lastName: data.custom_business_owner.split(" ").slice(1).join(" ") || "",
            email: data.email,
            phoneNumber: data.phoneNumber.replace(/\D/g, ""),
          }
        : {
            carrierId: data.carrier_id,
            companyId: companyId,
            ownerId: selectedOwnerId,
            firstName: selectedOwner.first_name,
            lastName: selectedOwner.last_name,
            email: data.email,
            phoneNumber: data.phoneNumber.replace(/\D/g, ""),
          };

      // Call the service function
      const { verification, referenceId } =
        await createBusinessOwnerKYBVerification(verificationData);

      // Prepare verification result object for the business owner record
      const verificationResultData = {
        inquiry_id: verification.persona_inquiry_id,
        reference_id: referenceId,
        started_at: new Date().toISOString(),
      };

      // Update or Create Business Owner using hook mutations
      let businessOwnerRecord = null;
      
      if (selectedOwnerId !== "custom") {
        // Update existing owner using mutation
        businessOwnerRecord = await updateBusinessOwnerMutation.mutateAsync({
          ownerId: selectedOwnerId,
          dataToUpdate: { verification_result: verificationResultData },
        });
      } else {
        // Upsert new/custom owner using mutation
        const nameParts = data.custom_business_owner.split(" ") || ["", ""];
        const firstName = nameParts[0] || "";
        const lastName = nameParts.slice(1).join(" ") || "";

        const ownerDataToUpsert = {
          first_name: firstName,
          last_name: lastName,
          email: data.email,
          phone_number: data.phoneNumber.replace(/\D/g, ""),
          carrier: data.carrier_id,
          verification_result: verificationResultData,
          role: "owner",
        };
        
        businessOwnerRecord = await upsertBusinessOwnerMutation.mutateAsync(
          ownerDataToUpsert
        );
      }

      // Check if the mutation was successful and we have a record
      if (businessOwnerRecord) {
        setIsSuccess(true);
        setStatusMessage(
          `Business verification initiated. Reference ID: ${referenceId}`
        );

        // Send SMS notification
        const ownerDetailsForSMS = {
          persona_inquiry_id: verification?.persona_inquiry_id,
          phone_number: data.phoneNumber.replace(/\D/g, ""),
          businessOwner: selectedOwnerId === "custom" ? data.custom_business_owner : data.business_owner,
          businessName: data.business_name,
        };

        try {
          await sendBusinessOwnerVerificationSMS(ownerDetailsForSMS);
        } catch (smsError) {
          console.error("Error sending verification SMS:", smsError);
          setStatusMessage(
            `Verification initiated (Ref: ${referenceId}), but failed to send SMS: ${smsError.message}`
          );
        }
      }
    } catch (error) {
      console.error("Error initiating business owner verification:", error);
      setStatusMessage(
        `Error: ${error.message || "Failed to initiate verification"}`
      );
      form.setError("root", { 
        type: "manual", 
        message: error.message || "Failed to initiate verification" 
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto p-4 sm:p-6 lg:p-8">
      <Card className="w-full">
        <CardHeader className="space-y-1">
          <div className="flex items-center gap-4">
            <div className="bg-slate-100 p-2 rounded-full">
              <Building className="h-6 w-6 text-slate-700" />
            </div>
            <div>
              <CardTitle>Business Owner Verification</CardTitle>
              <CardDescription>
                Enter business information for KYB verification.
              </CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <div className="space-y-4">
                <h3 className="text-lg font-medium">Business Information</h3>
                <Separator />
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="business_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Business Name *</FormLabel>
                        <FormControl>
                          <Input placeholder="Acme Corporation" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="business_owner_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Business Owner *</FormLabel>
                        <Select
                          onValueChange={handleOwnerChange}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a business owner" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {businessOwners.map((owner) => (
                              <SelectItem key={owner.id} value={owner.id}>
                                {owner.name}
                              </SelectItem>
                            ))}
                            <SelectItem value="custom">Enter custom name</SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  {selectedOwnerId === "custom" && (
                    <FormField
                      control={form.control}
                      name="custom_business_owner"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Custom Business Owner Name *</FormLabel>
                          <FormControl>
                            <Input placeholder="John Doe" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                </div>
              </div>
              
              <div className="space-y-4">
                <h3 className="text-lg font-medium">Contact Information</h3>
                <Separator />
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email *</FormLabel>
                        <FormControl>
                          <Input 
                            type="email"
                            placeholder="john.doe@example.com" 
                            {...field} 
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Phone Number *</FormLabel>
                        <FormControl>
                          <Input 
                            placeholder="(123) 456-7890" 
                            {...field} 
                            onChange={(e) => {
                              // Allow formatted or unformatted numbers
                              field.onChange(e.target.value);
                            }}
                          />
                        </FormControl>
                        <FormDescription>
                          Phone number will be used to send the verification link.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="carrier_id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Carrier ID *</FormLabel>
                        <FormControl>
                          <Input 
                            placeholder="Carrier ID" 
                            {...field} 
                            disabled={!!carrierIdFromUrl}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              
              {statusMessage && (
                <Alert className={isSuccess ? "bg-green-50 border-green-200" : "bg-amber-50 border-amber-200"}>
                  {isSuccess ? (
                    <Check className="h-4 w-4 text-green-500" />
                  ) : (
                    <AlertCircle className="h-4 w-4 text-amber-500" />
                  )}
                  <AlertTitle className={isSuccess ? "text-green-700" : "text-amber-700"}>
                    {isSuccess ? "Success" : "Notification"}
                  </AlertTitle>
                  <AlertDescription className={isSuccess ? "text-green-600" : "text-amber-600"}>
                    {statusMessage}
                  </AlertDescription>
                </Alert>
              )}
              
              {form.formState.errors.root && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{form.formState.errors.root.message}</AlertDescription>
                </Alert>
              )}
              
              <Button 
                type="submit" 
                className="w-full" 
                disabled={submitting}
              >
                {submitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Processing...
                  </>
                ) : (
                  "Start Business Owner Verification"
                )}
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

export default VetBusinessOwnerForm;
