import React from "react";
// Consider importing components/styles from RiskReportsSection if reusable
// import { BusinessVerificationCard } from '@/components/RiskReports/RiskReportsSection'; // Note: This is the whole card

/**
 * BusinessReportDetails - Renders details specific to business reports (database verification).
 * TODO: Implement business report snapshot view, potentially reusing logic/UI
 * from src/components/RiskReports/RiskReportsSection.jsx (BusinessVerificationCard)
 *
 * @param {Object} props - Component props
 * @param {Object} props.verification - The verification data object (contains business report data)
 */
export default function BusinessReportDetails({ verification }) {
  // Extract relevant data, similar to BusinessVerificationCard
  const resultsData = verification.results || {};
  const verificationAttributes = resultsData.rawData?.data?.attributes || {};

  return (
    <div className="p-4 bg-indigo-50 space-y-2 text-sm border border-indigo-200 rounded">
      <h4 className="font-semibold text-indigo-700">Business Report Details</h4>
      <p className="text-slate-500">Verification ID: {verification.id}</p>
      <p className="text-slate-500">State: {verification.state || "N/A"}</p>
      <p className="text-orange-600 italic">
        -- Business report snapshot placeholder --
      </p>
      {/* Build specific view based on verificationAttributes here */}
      <pre className="text-xs bg-slate-100 p-2 rounded overflow-auto max-h-60">
        {JSON.stringify(verificationAttributes, null, 2)}
      </pre>
    </div>
  );
}
