// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

import AddDriverForm from "./AddDriverForm";
import { useCustomerCompanyId } from "../../../hooks/useCustomers";
import { useGetUserSession } from "../../../hooks/users";
import { useDriverMutations } from "../../../hooks/drivers";

/**
 * AddDriver - Page component for adding a new driver
 */
const AddDriver = () => {
  const navigate = useNavigate();
  const { session, loading: sessionLoading } = useGetUserSession();
  const { data: companyId, isLoading: companyLoading } = useCustomerCompanyId(
    session?.user?.id
  );
  const { createDriver } = useDriverMutations();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formDefaultValues, setFormDefaultValues] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    phone_number: "",
    email: "",
    driver_license_number: "",
    driver_license_state: "",
    driver_license_expiration_date: "",
    driver_license_class: "",
    company_id: null,
    employment_start_date: "",
    employment_status: "Active",
    is_verified: false,
  });

  // Update company ID in form values when available
  useEffect(() => {
    if (companyId) {
      setFormDefaultValues((prevData) => ({
        ...prevData,
        company_id: companyId,
      }));
    }
  }, [companyId]);

  // Handle form submission
  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    setError("");

    try {
      // Submit data to API
      const result = await createDriver.mutateAsync({
        ...data,
        company_id: companyId,
        is_verified: false,
      });

      // Handle success
      setSuccess(true);
      setIsSubmitting(false);

      // Navigate back to drivers list after short delay
      setTimeout(() => {
        navigate("/dashboard/drivers");
      }, 1500);
    } catch (err) {
      // Handle error
      setError(
        err.message ||
          "An error occurred while adding the driver. Please try again."
      );
      setIsSubmitting(false);
    }
  };

  // Show loading state while waiting for session and company data
  if (sessionLoading || companyLoading) {
    return (
      <div className="p-6">
        <div className="flex items-center justify-center h-40">
          <p className="text-muted-foreground">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      {/* Header with breadcrumbs */}
      <div>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/dashboard/drivers">Drivers</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/dashboard/add-driver">
                Add Driver
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <div className="flex items-center justify-between mt-2">
          <h1 className="text-2xl font-semibold tracking-tight">Add Driver</h1>
          <Button
            variant="outline"
            size="sm"
            onClick={() => navigate("/dashboard/drivers")}
          >
            <ChevronLeft className="mr-2 h-4 w-4" />
            Back to Drivers
          </Button>
        </div>
      </div>

      {/* Form card */}
      <Card>
        <CardHeader>
          <CardTitle>Driver Information</CardTitle>
          <CardDescription>
            Enter the details of the driver you want to add to your company.
          </CardDescription>
        </CardHeader>
        <CardContent>
          {success ? (
            <div className="text-center py-6">
              <h3 className="text-lg font-medium text-green-600 mb-2">
                Driver Added Successfully!
              </h3>
              <p className="text-muted-foreground mb-4">
                The driver has been added to your company.
              </p>
              <Button onClick={() => navigate("/dashboard/drivers")}>
                Return to Drivers List
              </Button>
            </div>
          ) : (
            <AddDriverForm
              onSubmit={handleSubmit}
              defaultValues={formDefaultValues}
              isSubmitting={isSubmitting}
              error={error}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default AddDriver;
