// @ts-nocheck
import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Truck,
  UserCheck,
  FileCheck,
  ClipboardCheck,
  ChevronRight,
  AlertCircle,
  Loader2,
  ShieldAlert,
  Phone,
  Mail,
} from "lucide-react";
import DisplayCarrierData from "../Components/DisplayCarrierData";
import DisplayPersonaResults from "../Components/DisplayPersonaResults";
import BusinessOwnersSection from "../Components/PersonaResults/BusinesOwnerSection";
import RiskReportsSection from "../../components/RiskReports/RiskReportsSection";

// Risk Level Indicator Component
const RiskLevelIndicator = ({ riskScore = 0, riskSignals = [] }) => {
  // Determine risk level text and colors based on score
  const getRiskLevel = (score) => {
    if (score < 30)
      return {
        text: "Low Risk",
        color: "bg-gradient-to-r from-green-500 to-green-300",
        textColor: "text-green-700",
        bgColor: "bg-green-50",
        borderColor: "border-green-200",
        action: "Low risk profile. Standard onboarding process recommended.",
      };
    if (score < 70)
      return {
        text: "Medium Risk",
        color: "bg-gradient-to-r from-yellow-500 to-yellow-300",
        textColor: "text-yellow-700",
        bgColor: "bg-yellow-50",
        borderColor: "border-yellow-200",
        action:
          "Basic verification required. Review documentation and business history.",
      };
    return {
      text: "High Risk",
      color: "bg-gradient-to-r from-red-500 to-red-300",
      textColor: "text-red-700",
      bgColor: "bg-red-50",
      borderColor: "border-red-200",
      action:
        "Caution required. Extensive verification and additional documentation needed.",
    };
  };

  const { text, color, textColor, bgColor, borderColor, action } =
    getRiskLevel(riskScore);

  return (
    <motion.div
      className={`${bgColor} rounded-lg shadow-md border ${borderColor} p-6 w-full`}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      style={{
        backgroundColor:
          bgColor === "bg-green-50"
            ? "#f0fdf4"
            : bgColor === "bg-yellow-50"
            ? "#fefce8"
            : "#fef2f2",
      }}
    >
      <div className="flex items-center mb-4">
        <ShieldAlert
          size={24}
          className="mr-3"
          style={{
            color:
              textColor === "text-green-700"
                ? "#15803d"
                : textColor === "text-yellow-700"
                ? "#a16207"
                : "#b91c1c",
          }}
        />
        <h3 className="font-bold text-xl text-gray-800">
          Carrier Risk Assessment
        </h3>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <span
          className="font-semibold text-lg"
          style={{
            color:
              textColor === "text-green-700"
                ? "#15803d"
                : textColor === "text-yellow-700"
                ? "#a16207"
                : "#b91c1c",
          }}
        >
          {text}
        </span>
        <span
          className="text-md font-bold px-3 py-1.5 rounded-full shadow-sm border"
          style={{
            backgroundColor:
              textColor === "text-green-700"
                ? "#dcfce7" // Light green
                : textColor === "text-yellow-700"
                ? "#fef9c3" // Light yellow
                : "#fee2e2", // Light red
            color:
              textColor === "text-green-700"
                ? "#15803d"
                : textColor === "text-yellow-700"
                ? "#a16207"
                : "#b91c1c",
            borderColor:
              borderColor === "border-green-200"
                ? "#bbf7d0"
                : borderColor === "border-yellow-200"
                ? "#fef08a"
                : "#fecaca",
          }}
        >
          {riskScore}/100
        </span>
      </div>

      <div className="h-4 w-full bg-gray-200 rounded-full overflow-hidden mb-2 shadow-inner">
        <motion.div
          className="h-full"
          style={{
            background:
              riskScore < 30
                ? "linear-gradient(to right, #22c55e, #86efac)"
                : riskScore < 70
                ? "linear-gradient(to right, #eab308, #fde047)"
                : "linear-gradient(to right, #ef4444, #fca5a5)",
          }}
          initial={{ width: 0 }}
          animate={{ width: `${riskScore}%` }}
          transition={{ duration: 1, ease: "easeOut" }}
        />
      </div>

      <div className="flex justify-between mt-1 text-xs text-gray-600 font-medium mb-4">
        <span>Low</span>
        <span>Medium</span>
        <span>High</span>
      </div>

      {riskSignals.length > 0 && (
        <div className="mt-4 mb-4 p-3 rounded-lg bg-white border border-gray-200">
          <h4 className="font-semibold mb-2 text-gray-800">
            Risk Signals Detected:
          </h4>
          <ol className="list-decimal pl-8 space-y-1">
            {riskSignals.map((signal, index) => (
              <li key={index} className="text-gray-700">
                {signal}
              </li>
            ))}
          </ol>
        </div>
      )}

      <div
        className="mt-4 p-3 rounded-lg border"
        style={{
          backgroundColor:
            bgColor === "bg-green-50"
              ? "#f0fdf4"
              : bgColor === "bg-yellow-50"
              ? "#fefce8"
              : "#fef2f2",
          borderColor:
            borderColor === "border-green-200"
              ? "#bbf7d0"
              : borderColor === "border-yellow-200"
              ? "#fef08a"
              : "#fecaca",
          color:
            textColor === "text-green-700"
              ? "#15803d"
              : textColor === "text-yellow-700"
              ? "#a16207"
              : "#b91c1c",
        }}
      >
        <h4 className="font-semibold mb-1">Recommended Action:</h4>
        <p>{action}</p>
      </div>
    </motion.div>
  );
};

// Verification Button Component
const VerificationButton = ({ icon, label, onClick }) => (
  <motion.button
    onClick={onClick}
    className="flex items-center justify-center bg-white border border-gray-300 hover:bg-gray-50 text-gray-700 font-medium px-4 py-2 rounded-lg shadow-sm transition-colors"
    whileHover={{ scale: 1.02, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.05)" }}
    whileTap={{ scale: 0.98 }}
  >
    {icon}
    <span className="ml-2">{label}</span>
  </motion.button>
);

// Page Header Component
const PageHeader = ({
  carrierId,
  carrierName,
  onVerifyBusiness,
  onVerifyBusinessDocumentation,
  onVerifyInsuranceDocumentation,
}) => (
  <motion.div
    className="mb-8"
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex items-center text-sm text-gray-500 mb-2">
      <span>Carriers</span>
      <ChevronRight size={16} className="mx-1" />
      <span className="font-medium text-gray-700">Carrier Report</span>
    </div>

    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-6">
      <div className="flex items-center">
        <Truck className="text-blue-600 mr-3" size={32} />
        <h1 className="text-3xl font-bold text-gray-900">
          {carrierName || "Carrier Report"}
        </h1>
      </div>

      <div className="flex flex-col sm:flex-row gap-3">
        <VerificationButton
          icon={<UserCheck size={18} className="text-blue-600" />}
          label="Verify Business Owner"
          onClick={() => onVerifyBusiness(carrierId)}
        />
        <VerificationButton
          icon={<FileCheck size={18} className="text-blue-600" />}
          label="Verify Business Documentation"
          onClick={() => onVerifyBusinessDocumentation(carrierId)}
        />
        <VerificationButton
          icon={<ClipboardCheck size={18} className="text-blue-600" />}
          label="Verify Insurance Documentation"
          onClick={() => onVerifyInsuranceDocumentation(carrierId)}
        />
      </div>
    </div>
  </motion.div>
);

// Error Message Component
const ErrorMessage = ({ message }) => (
  <motion.div
    className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg flex items-start"
    initial={{ opacity: 0, height: 0 }}
    animate={{ opacity: 1, height: "auto" }}
    exit={{ opacity: 0, height: 0 }}
  >
    <AlertCircle className="text-red-500 mr-3 mt-0.5 flex-shrink-0" size={20} />
    <div>
      <h3 className="font-medium text-red-800 mb-1">Error</h3>
      <p className="text-red-700">{message}</p>
    </div>
  </motion.div>
);

// Loading Spinner Component
const LoadingSpinner = () => (
  <motion.div
    className="flex flex-col items-center justify-center py-16"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <Loader2 className="h-12 w-12 text-blue-600 animate-spin mb-4" />
    <p className="text-gray-600 font-medium">Loading carrier data...</p>
  </motion.div>
);

// Main Component
const VetCarrierReportPresenter = ({
  loading,
  error,
  carrierData,
  phoneRiskReports,
  emailRiskReports,
  businessReports,
  businessRegistrations,
  personaVerifications,
  insuranceVerifications,
  businessOwnerVerifications,
  businessOwners,
  carrierName,
  carrierId,
  onVerifyBusiness,
  onVerifyBusinessDocumentation,
  onVerifyInsuranceDocumentation,
  riskScore,
  riskSignals,
  refreshData,
}) => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <PageHeader
          carrierName={carrierName}
          carrierId={carrierId}
          onVerifyBusiness={onVerifyBusiness}
          onVerifyBusinessDocumentation={onVerifyBusinessDocumentation}
          onVerifyInsuranceDocumentation={onVerifyInsuranceDocumentation}
        />

        <AnimatePresence>
          {error && <ErrorMessage message={error} />}
        </AnimatePresence>

        {loading ? (
          <LoadingSpinner />
        ) : (
          <motion.div
            className="space-y-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {/* Risk Level Indicator - Now full width */}
            <div className="w-full">
              <RiskLevelIndicator
                riskScore={riskScore}
                riskSignals={riskSignals}
              />
            </div>

            {/* Business Owners Section */}
            {!loading && !error && (
              <>
                <BusinessOwnersSection
                  isLoading={loading}
                  businessOwners={businessOwners}
                  verifications={businessOwnerVerifications}
                />
              </>
            )}

            <motion.div
              className="bg-white rounded-xl shadow-sm overflow-hidden"
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {carrierData ? (
                <DisplayCarrierData carrierData={carrierData} />
              ) : (
                <div className="p-8 text-center">
                  <p className="text-gray-600">No carrier data available.</p>
                </div>
              )}
            </motion.div>

            {/* Risk Reports Section */}
            {!loading && !error && (
              <RiskReportsSection
                isLoading={loading}
                phoneRiskReports={phoneRiskReports}
                emailRiskReports={emailRiskReports}
                businessReports={businessReports}
                businessRegistrations={businessRegistrations}
                personaVerifications={personaVerifications}
                insuranceVerifications={insuranceVerifications}
              />
            )}

            {/* Persona Verifications */}
            {personaVerifications && personaVerifications.length > 0 && (
              <DisplayPersonaResults
                isLoading={loading}
                results={personaVerifications}
              />
            )}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default VetCarrierReportPresenter;
