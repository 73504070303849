import { VerificationStrategy } from "./VerificationStrategy";
import { VerificationRepository } from "../repositories/VerificationRepository";

export class AllVerificationStrategy extends VerificationStrategy {
  constructor(dependencies = {}) {
    super(dependencies);
    this._providers = {};
    this._repositories = {};
  }

  get verificationRepository() {
    if (!this._repositories.verification) {
      this._repositories.verification =
        this._dependencies.verificationRepository ||
        new VerificationRepository();
    }
    return this._repositories.verification;
  }

  async getAllVerifications() {
    console.log("Fetching all verifications from repository...");
    const verifications = await this.verificationRepository.getAll();
    console.log("All verifications:", verifications);
    return verifications;
  }

  async getVerificationById(id) {
    return this.verificationRepository.findById(id);
  }

  async createVerification(data) {
    return this.verificationRepository.create(data);
  }

  async updateVerification(id, data) {
    return this.verificationRepository.update(id, data);
  }

  async deleteVerification(id) {
    return this.verificationRepository.delete(id);
  }

  /**
   * Get verification by Persona inquiry ID
   * @param {string} inquiryId - Persona inquiry ID
   * @returns {Promise<Object>} Verification record
   */
  async getVerificationByInquiryId(inquiryId) {
    console.log("Getting verification by inquiry ID:", inquiryId);
    return this.verificationRepository.findByPersonaInquiryId(inquiryId);
  }

  /**
   * Update verification by Persona inquiry ID
   * @param {string} inquiryId - Persona inquiry ID
   * @param {Object} data - Data to update
   * @returns {Promise<Object>} Updated verification record
   */
  async updateVerificationByInquiryId(inquiryId, data) {
    console.log("Updating verification by inquiry ID:", inquiryId, data);
    const verification = await this.getVerificationByInquiryId(inquiryId);
    
    if (!verification) {
      throw new Error(`Verification not found for inquiry ID: ${inquiryId}`);
    }
    
    return this.verificationRepository.update(verification.id, data);
  }
}
