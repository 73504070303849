import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Loader2 } from "lucide-react";
import { useCustomerCompanyId } from "@/hooks/useCustomers";
import { useGetUserSession } from "@/hooks/users";
import { useShipmentMutations } from "@/hooks/shipments";
import { useCargoOwnerList } from "@/hooks/cargoOwner";
import Toast from "@/pages/Components/Toast";
import "react-datepicker/dist/react-datepicker.css";

// Import MultiStepForms components
import {
  useMultiStepForm,
  FormStepIndicator,
  FormStep,
} from "@/components/forms/MultiStepForms";

// Import our form components
import {
  ShipmentDetailsStep,
  RouteInformationStep,
  ShipmentReviewStep,
  FormHeader,
  SubmitButton,
} from "./components";

// Import shadcn components
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";

// Define the shipment validation schema
const shipmentSchema = z.object({
  load_id: z.string().optional(),
  origin_address: z.string().min(1, { message: "Origin address is required" }),
  destination_address: z
    .string()
    .min(1, { message: "Destination address is required" }),
  cargo_owner_id: z.string().min(1, { message: "Cargo owner is required" }),
  carrier_number: z.string().optional(),
  company_id: z.string().optional(),
  container_number: z.string().optional(),
  pallet_number: z.string().optional(),
  tags: z.string().optional(),
  notes: z.string().optional(),
  status: z.string().optional().default("Unverified"),
  direction: z.enum(["Inbound", "Outbound"]).optional(),
  pickup_time: z.string().nullable().optional(),
});

// Utility function for haptic feedback
const provideHapticFeedback = (pattern = 100) => {
  // Use vibration API if available
  if (navigator.vibrate) {
    navigator.vibrate(pattern);
  }

  // For iOS Safari where vibration isn't supported, we can use other feedback:
  // 1. Play a short sound
  try {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    if (audioContext) {
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();

      oscillator.type = "sine";
      oscillator.frequency.value = 300;
      gainNode.gain.value = 0.1;

      oscillator.connect(gainNode);
      gainNode.connect(audioContext.destination);

      oscillator.start();
      setTimeout(() => oscillator.stop(), 50);
    }
  } catch (e) {
    console.log("Audio feedback not available", e);
  }
};

export function AddShipmentForm() {
  const navigate = useNavigate();
  const { session, loading: sessionLoading } = useGetUserSession();
  const { data: companyId } = useCustomerCompanyId(session?.user?.id);
  const { createShipment } = useShipmentMutations();
  const {
    data: cargoOwners,
    isLoading: isLoadingCargoOwners,
    error: cargoOwnersError,
    isError,
  } = useCargoOwnerList();

  // Define form steps
  const steps = [
    { label: "Shipment Details", fields: ["load_id", "cargo_owner_id"] },
    {
      label: "Origin & Destination",
      fields: ["origin_address", "destination_address"],
    },
    { label: "Review & Submit", fields: [] },
  ];

  // Use our new MultiStepForm hook instead of manual step management
  const { currentStep, handleNextStep, handlePreviousStep } = useMultiStepForm({
    steps,
    initialStep: 0,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Add a ref to track toast initialization
  const toastInitialized = React.useRef(false);

  // Initialize toast container on mount and enable debug mode for troubleshooting
  useEffect(() => {
    // Only run this once
    if (toastInitialized.current) return;

    // Mark as initialized
    toastInitialized.current = true;

    // Enable debug mode in development
    if (process.env.NODE_ENV === "development") {
      Toast.enableDebug();
    }

    // Initialize the toast container
    Toast.init();

    // Show a test toast after a short delay - only in development
    if (process.env.NODE_ENV === "development") {
      // Use setTimeout to give the UI time to render first
      const timer = setTimeout(() => {
        Toast.info("Form ready for submission", 3000);
      }, 500);

      return () => clearTimeout(timer); // Clean up the timer if component unmounts
    }
  }, []);

  // Set up react-hook-form with zod validation
  const form = useForm({
    resolver: zodResolver(shipmentSchema),
    defaultValues: {
      load_id: "",
      origin_address: "",
      destination_address: "",
      cargo_owner_id: "",
      carrier_number: "",
      company_id: companyId || "",
      container_number: "",
      pallet_number: "",
      tags: "",
      notes: "",
      status: "Scheduled",
      direction: undefined,
      pickup_time: null,
    },
  });

  // Update company_id when it's available
  React.useEffect(() => {
    if (companyId) {
      form.setValue("company_id", companyId);
    }
  }, [companyId, form]);

  // Transform cargo owners for Select component
  const cargoOwnerOptions =
    cargoOwners?.map((owner) => ({
      value: owner.id,
      label: owner.company_name,
    })) || [];

  // Form submission handler
  const onSubmit = async (data) => {
    console.log("Form submission started", data);

    setIsSubmitting(true);
    setFormError("");
    setFormSubmitted(true);

    // Provide immediate feedback to the user that the form is being submitted
    provideHapticFeedback();

    // Add a direct toast here to verify Toast component is working
    Toast.info("Processing shipment submission...", 3000);

    try {
      if (!createShipment || typeof createShipment.mutateAsync !== "function") {
        throw new Error(
          "Mutation function not available. Check React Query setup."
        );
      }

      // Process the form data and ensure IDs are properly typed
      const processedData = {
        ...data,
        load_id: data.load_id?.trim() || uuidv4(),
        cargo_owner_id: data.cargo_owner_id
          ? Number(data.cargo_owner_id)
          : undefined,
        company_id: data.company_id ? Number(data.company_id) : undefined,
        pickup_time:
          data.pickup_time &&
          typeof data.pickup_time === "string" &&
          data.pickup_time.trim() !== ""
            ? data.pickup_time
            : null,
      };

      console.log("Submitting data to API:", processedData);

      // Define result variable at this scope level
      let result;

      // Add a try/catch specifically around the mutation call
      try {
        console.log("Starting createShipment mutation call");
        // Use the createShipment mutation with the processed data
        result = await createShipment.mutateAsync(processedData);
        console.log("API response:", result);
      } catch (mutationError) {
        console.error("Mutation error details:", mutationError);
        // Re-throw to be caught by the outer catch
        throw mutationError;
      }

      // Success feedback
      provideHapticFeedback([100, 50, 100]); // Success pattern
      Toast.success("Shipment created successfully!", 5000);

      // Reset form
      form.reset();

      // Redirect to the newly created shipment after a short delay
      if (result?.id) {
        console.log("Redirecting to shipment page:", result.id);
        // Allow time for toast to be seen before redirect
        setTimeout(() => {
          navigate(`/dashboard/shipment/${result.id}`);
        }, 1500);
      } else {
        console.warn("No shipment ID returned from API, can't redirect");
      }
    } catch (error) {
      console.error("Error details:", error);
      setFormError(`Failed to create shipment: ${error.message}`);
      Toast.error("Failed to create shipment: " + error.message, 5000);
      // Error feedback
      provideHapticFeedback([100, 50, 100, 50, 100]); // Error pattern
    } finally {
      setIsSubmitting(false);
      console.log("Form submission completed");
    }
  };

  // Validate current step fields
  const validateCurrentStep = () => {
    const fieldsToValidate = steps[currentStep].fields;
    if (fieldsToValidate.length === 0) return true;

    return form.trigger(fieldsToValidate).then((isValid) => {
      if (!isValid) {
        // Trigger validation errors to display
        fieldsToValidate.forEach((field) => form.trigger(field));
      }
      return isValid;
    });
  };

  // Handler for submitting the form on the last step
  const handleSubmitForm = () => {
    const formValues = form.getValues();
    console.log("Form values for direct submission:", formValues);

    // Ensure cargo_owner_id is a number
    if (
      formValues.cargo_owner_id &&
      typeof formValues.cargo_owner_id === "string"
    ) {
      formValues.cargo_owner_id = Number(formValues.cargo_owner_id);
    }

    // Ensure company_id is a number if present
    if (formValues.company_id && typeof formValues.company_id === "string") {
      formValues.company_id = Number(formValues.company_id);
    }

    // Fix timestamp handling
    if (
      !formValues.pickup_time ||
      (typeof formValues.pickup_time === "string" &&
        formValues.pickup_time.trim() === "")
    ) {
      formValues.pickup_time = null;
    } else if (formValues.pickup_time instanceof Date) {
      // If it's a Date object, convert to ISO string
      formValues.pickup_time = formValues.pickup_time.toISOString();
    }

    // Call onSubmit with the processed form values
    if (!isSubmitting) {
      onSubmit(formValues);
    }
  };

  // Loading state
  if (sessionLoading || isLoadingCargoOwners) {
    return (
      <div className="w-full mx-auto p-6 flex justify-center items-center min-h-[300px]">
        <Loader2 className="w-8 h-8 animate-spin text-slate-700" />
      </div>
    );
  }

  // Error state
  if (isError) {
    return (
      <div className="w-full mx-auto p-6">
        <Alert variant="destructive">
          <AlertTitle>Error loading cargo owners</AlertTitle>
          <AlertDescription>
            {cargoOwnersError?.message || "Please try again later"}
            <Button
              variant="outline"
              className="mt-4"
              onClick={() => window.location.reload()}
            >
              Retry
            </Button>
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <div className="p-4 sm:p-6 lg:p-8 space-y-6">
      {/* Form header with breadcrumbs and title */}
      {/* <FormHeader /> */}

      {/* Progress indicator using our custom component */}
      <FormStepIndicator steps={steps} currentStep={currentStep} />

      {/* Error message display */}
      {formError && (
        <Alert variant="destructive" className="mb-6">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{formError}</AlertDescription>
        </Alert>
      )}

      {/* Main form */}
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-6"
          onSubmitCapture={() => console.log("Form submit event captured")}
        >
          {/* Step 1: Shipment Details */}
          <FormStep
            step={0}
            title="Shipment Details"
            description="Enter basic information about this shipment"
            isActive={currentStep === 0}
            footer={
              <div className="flex justify-end">
                <Button
                  type="button"
                  onClick={() => handleNextStep(validateCurrentStep)}
                >
                  Continue to Address Information
                </Button>
              </div>
            }
          >
            <ShipmentDetailsStep
              form={form}
              cargoOwnerOptions={cargoOwnerOptions}
            />
          </FormStep>

          {/* Step 2: Origin & Destination */}
          <FormStep
            step={1}
            title="Origin & Destination"
            description="Enter the pickup and delivery locations"
            isActive={currentStep === 1}
            footer={
              <div className="flex justify-between space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={handlePreviousStep}
                >
                  Back
                </Button>
                <Button
                  type="button"
                  onClick={() => handleNextStep(validateCurrentStep)}
                >
                  Continue to Review
                </Button>
              </div>
            }
          >
            <RouteInformationStep form={form} />
          </FormStep>

          {/* Step 3: Review & Submit */}
          <FormStep
            step={2}
            title="Review & Submit"
            description="Review the shipment information before submitting"
            isActive={currentStep === 2}
            footer={
              <div className="flex justify-between space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={handlePreviousStep}
                >
                  Back
                </Button>
                <SubmitButton
                  isSubmitting={isSubmitting}
                  onClick={handleSubmitForm}
                />
              </div>
            }
          >
            <ShipmentReviewStep
              form={form}
              cargoOwnerOptions={cargoOwnerOptions}
            />
          </FormStep>
        </form>
      </Form>
    </div>
  );
}
