import React from "react";
import {
  formatAttributeName,
  renderAttributeValue,
  formatAttributeValue,
  renderBooleanValue,
  formatDate,
  dateFields,
} from "./utils";

/**
 * Renders a group of attributes in a consistent format
 * @param {Object} props
 * @param {Object} props.attributes - The attributes object to render
 * @param {Array} props.keys - The keys from the attributes object to render
 */
const AttributeGroup = ({ attributes, keys }) => {
  if (!attributes) return null;

  return (
    <>
      {keys.map((key) => {
        if (attributes[key] !== undefined) {
          return (
            <div
              key={key}
              className="group grid grid-cols-[200px,1fr] gap-4 py-3 px-4 hover:bg-slate-50 transition-colors"
            >
              <div className="text-sm text-slate-600 font-medium">
                {formatAttributeName(key)}
              </div>
              <div className="text-sm text-slate-700">
                {/* Explicit handling for each type */}
                {key === "status" ? (
                  <span>{formatAttributeValue(attributes[key])}</span>
                ) : key === "has-match" ? (
                  renderBooleanValue(attributes[key])
                ) : key === "is-recurring" || key === "is-continuous" ? (
                  renderBooleanValue(attributes[key])
                ) : dateFields.includes(key) ? (
                  attributes[key] ? (
                    formatDate(attributes[key])
                  ) : (
                    <span className="text-slate-400 italic">Not Available</span>
                  )
                ) : (
                  <span>{formatAttributeValue(attributes[key])}</span>
                )}
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default AttributeGroup;
