// @ts-nocheck
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Plus } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

import DriversFilter from "./DriversFilter";
import DriversStats from "./DriversStats";
import DriversDataTable from "./DriversDataTable";
import { useDrivers } from "../../hooks/drivers";

/**
 * DriversDashboard - Main dashboard component for the Drivers page
 */
const DriversDashboard = () => {
  // State
  const [filters, setFilters] = useState({
    searchTerm: "",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;

  // Fetch drivers data
  const { data: driversData, isLoading, error } = useDrivers();

  // Filter data based on search term
  const getFilteredData = useCallback(() => {
    if (!driversData?.drivers) return [];

    return driversData.drivers.filter((driver) => {
      if (!filters.searchTerm) return true;

      const searchTerm = filters.searchTerm.toLowerCase();

      // Search in multiple fields
      return (
        driver.id?.toString().toLowerCase().includes(searchTerm) ||
        driver.first_name?.toLowerCase().includes(searchTerm) ||
        driver.last_name?.toLowerCase().includes(searchTerm) ||
        driver.email?.toLowerCase().includes(searchTerm) ||
        driver.driver_license_number?.toLowerCase().includes(searchTerm) ||
        driver.phone_number?.toLowerCase().includes(searchTerm)
      );
    });
  }, [driversData, filters]);

  // Calculate total pages
  useEffect(() => {
    const filteredData = getFilteredData();
    setTotalPages(Math.ceil(filteredData.length / limit));

    // Reset to page 1 when filters change
    if (filters.searchTerm) {
      setPage(1);
    }
  }, [getFilteredData, filters, limit]);

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
    // Scroll to top when changing pages
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Handle loading and error states
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-muted-foreground">Loading drivers data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-destructive">
          Error loading drivers: {error.message}
        </p>
      </div>
    );
  }

  if (!driversData?.drivers || driversData.drivers.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-screen gap-4">
        <p className="text-muted-foreground">No drivers available</p>
        <Button asChild>
          <Link to="/dashboard/add-driver">Add Driver</Link>
        </Button>
      </div>
    );
  }

  // Get filtered data
  const filteredData = getFilteredData();

  return (
    <div className="flex-1 overflow-auto">
      <div className="p-4 sm:p-6 lg:p-8 space-y-8">
        {/* Header with breadcrumbs */}
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0">
          <div>
            <h2 className="text-2xl font-bold tracking-tight">Drivers</h2>
            <p className="text-muted-foreground">Manage and vet your drivers</p>
          </div>
          <Button asChild>
            <Link to="/dashboard/add-driver">
              <Plus className="mr-2 h-4 w-4" />
              Add Driver
            </Link>
          </Button>
        </div>

        {/* Stats cards */}
        <DriversStats data={driversData.drivers} />

        {/* Filters */}
        <div className="w-full md:w-1/2">
          <DriversFilter onFilterChange={setFilters} />
        </div>

        {/* Drivers table */}
        <DriversDataTable
          data={filteredData}
          currentPage={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          limit={limit}
        />
      </div>
    </div>
  );
};

export default DriversDashboard;
