import React from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";

/**
 * A container component for an individual form step
 *
 * @param {Object} props - Component props
 * @param {number} props.step - Step index
 * @param {string} props.title - Step title
 * @param {string} [props.description] - Optional step description
 * @param {React.ReactNode} props.children - Step content
 * @param {React.ReactNode} [props.footer] - Optional footer with navigation buttons
 * @param {boolean} props.isActive - Whether this step is currently active
 * @returns {JSX.Element|null} The step container or null if not active
 */
export function FormStep({
  step,
  title,
  description,
  children,
  footer,
  isActive,
}) {
  // Only render the step if it's active
  if (!isActive) return null;

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        {description && <CardDescription>{description}</CardDescription>}
      </CardHeader>
      <CardContent className="space-y-6">{children}</CardContent>
      {footer && <CardFooter className="flex justify-end">{footer}</CardFooter>}
    </Card>
  );
}
