import * as React from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  MoreHorizontal,
  Eye,
  Edit,
  Trash,
  Info,
  CheckCircle,
  XCircle,
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

// Status style mapping for vetting status
const vettingStatusStyles = {
  vetted: {
    label: "Vetted",
    className:
      "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
    icon: CheckCircle,
  },
  unvetted: {
    label: "Unvetted",
    className:
      "bg-amber-100 text-amber-800 dark:bg-amber-800 dark:text-amber-100",
    icon: XCircle,
  },
};

// Format phone number helper
const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return "Not provided";
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};

// Actions component that can use hooks
function CarrierActions({ carrier }) {
  const navigate = useNavigate();

  // Navigate to carrier view page
  const viewCarrier = (id) => {
    navigate(`/dashboard/carrier/${id}`);
  };

  const editCarrier = (id) => {
    navigate(`/dashboard/edit-carrier/${id}`);
  };

  const deleteCarrier = (id) => {
    console.log(`Delete carrier ${id}`);
  };

  const vetCarrier = (carrier) => {
    navigate(`/dashboard/vet-carrier?carrier_id=${carrier.id}`);
  };

  // Navigate to vet carrier report for vetted carriers
  const viewVetReport = (id) => {
    navigate(`/dashboard/vet-carrier/${id}`);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Open menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => viewCarrier(carrier?.id)}>
          <Eye className="mr-2 h-4 w-4" />
          <span>View</span>
        </DropdownMenuItem>
        {carrier?.is_vetted && (
          <DropdownMenuItem onClick={() => viewVetReport(carrier?.id)}>
            <Info className="mr-2 h-4 w-4" />
            <span>View Vet Report</span>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onClick={() => editCarrier(carrier?.id)}>
          <Edit className="mr-2 h-4 w-4" />
          <span>Edit</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => vetCarrier(carrier)}>
          <CheckCircle className="mr-2 h-4 w-4" />
          <span>Vet Carrier</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => deleteCarrier(carrier?.id)}
          className="text-red-600 focus:bg-red-100 focus:text-red-700"
        >
          <Trash className="mr-2 h-4 w-4" />
          <span>Delete</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

// Core/essential columns that are always displayed
export const columns = [
  {
    accessorKey: "id",
    header: "ID",
    enableSorting: true,
    cell: ({ row }) => {
      const id = row.original?.id;

      // If no ID, return empty string
      if (!id) return <div className="font-medium">-</div>;

      // Truncate long IDs
      const truncatedId =
        id.length > 10
          ? `${id.substring(0, 8)}...${id.substring(id.length - 4)}`
          : id;

      return (
        <div className="font-medium font-mono relative group">
          <span>{truncatedId}</span>

          {/* Tooltip with full ID */}
          {id.length > 10 && (
            <div className="absolute z-10 hidden group-hover:block mt-1 p-2 bg-slate-800 text-white text-xs rounded shadow-lg whitespace-nowrap">
              {id}
              <button
                className="ml-2 text-blue-300 hover:text-blue-100"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(id);
                }}
              >
                Copy
              </button>
            </div>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "name",
    header: "Name",
    enableSorting: true,
    cell: ({ row }) => {
      const name = row.original?.name;
      return (
        <div className="truncate max-w-[150px]" title={name}>
          {name || "Unnamed Carrier"}
        </div>
      );
    },
  },
  {
    accessorKey: "is_vetted",
    header: "Status",
    enableSorting: true,
    cell: ({ row }) => {
      const isVetted = row.original?.is_vetted;
      const status = isVetted ? "vetted" : "unvetted";
      const { label, className } =
        vettingStatusStyles[status] || vettingStatusStyles.unvetted;

      return (
        <Badge className={className} variant="outline">
          {label}
        </Badge>
      );
    },
  },
  {
    accessorKey: "city",
    header: "City",
    enableSorting: true,
    cell: ({ row }) => {
      const city = row.original?.city;
      return (
        <div className="truncate max-w-[100px]" title={city}>
          {city || "N/A"}
        </div>
      );
    },
  },
  {
    accessorKey: "state",
    header: "State",
    enableSorting: true,
    cell: ({ row }) => {
      const state = row.original?.state;
      return (
        <div className="truncate max-w-[50px]" title={state}>
          {state || "N/A"}
        </div>
      );
    },
  },
  {
    accessorKey: "dot_number",
    header: "DOT #",
    enableSorting: true,
    cell: ({ row }) => {
      const dotNumber = row.original?.dot_number;
      return <div className="font-medium font-mono">{dotNumber || "N/A"}</div>;
    },
  },
  {
    id: "details",
    header: "Details",
    enableSorting: false,
    cell: ({ row }) => {
      const carrier = row.original;

      // Combine additional info for tooltip
      const phoneInfo = formatPhoneNumber(carrier?.phone_number);
      const emailInfo = carrier?.email || "Not provided";
      const addressInfo = carrier?.address || "Not provided";
      const zipInfo = carrier?.zip_code || "Not provided";

      return (
        <div className="relative group">
          <Button variant="ghost" className="h-8 p-1">
            <Info className="h-4 w-4 text-gray-500" />
          </Button>

          <div className="absolute z-10 hidden group-hover:block left-0 mt-1 p-2 bg-slate-800 text-white text-xs rounded shadow-lg whitespace-nowrap">
            <div>
              <strong>Address:</strong> {addressInfo}
            </div>
            <div>
              <strong>ZIP:</strong> {zipInfo}
            </div>
            <div>
              <strong>Phone:</strong> {phoneInfo}
            </div>
            <div>
              <strong>Email:</strong> {emailInfo}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    cell: ({ row }) => {
      const carrier = row.original;
      return <CarrierActions carrier={carrier} />;
    },
  },
];
