// @ts-nocheck
import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import NavBar from "./pages/Components/NavBar";
import { AppSidebar } from "./pages/Components/AppSidebar";
import { SidebarProvider } from "@/components/ui/sidebar";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import DashboardContent from "./pages/Dashboard/DashboardHomeContent";
import VerificationForm from "./pages/Verifications/AddVerifcation/VerificationPage";
import LoadPhotoProof from "./pages/PhotoProof/PhotoProof";
import AddCarrierForm from "./pages/Carriers/AddCarrier/AddCarrier";
import LandingPage from "./pages/LandingPage/LandingPage";
import AddCargoOwnerForm from "./pages/CargoOwners/AddCargoOwner/AddCargoOwnerForm";
import CargoOwnerUsers from "./pages/CargoOwners/CargoOwnerUsers";
import ShipmentDetails from "./pages/Shipments/ViewShipment/ViewShipment";
import ReportDashboard from "./pages/Reports/ReportDashboard";
import AddReport from "./pages/Reports/AddReport/AddReport";
import ReportDetails from "./pages/Reports/ViewForm/ViewReport";
import PublicReport from "./pages/Reports/PublicReport/PublicReport";
import IdentityVerification from "./pages/VerificationForm/IdentityVerification";
import Workflow from "./pages/Workflow/AddWorkflow/Workflow";
import WorkflowDashboard from "./pages/Workflow/WorkflowDashboard";
import ExternalDashboard from "./pages/ExternalDashboard/ExternalDashboard";
import CustomerLogin from "./pages/ExternalDashboard/CustomerLogin";
import ProtectedRoute from "./hooks/useProtectedRoute";
import NotFound from "./pages/Unauthorized/404";
import VetCarrierForm from "./pages/Carriers/VetCarrier/VetCarrierForm";
import PublicVetting from "./pages/PublicVettingPage/PublicVetting";
import FreightBrokers from "./pages/LandingPage/Shippers/Shippers";
import Warehouses from "./pages/LandingPage/Warehouses/Warehouses";
import VetDocumentsForm from "./pages/Documents/VetDocumentsForm";
import RiskDashboard from "./pages/RiskSignals/RiskSignalsHistorical/RiskDashboard";
import RiskLookupForm from "./pages/RiskSignals/RiskSignalsLookup/RiskLookupForm";
import FraudRuleDashboard from "./pages/Rules/FraudRuleDashboard";
import CreateFraudRules from "./pages/Rules/CreateFraudRules/CreateFraudRules";
import VetCarrierReportContainer from "./pages/VetCarrierReport/VetCarrierReportContainer";
import VetBusinessOwnerForm from "./pages/VetBusinessOwner/VetBusinessOwnerForm";
import VetBusinessDocumentationForm from "./pages/VetBusinessDocumentation/VetBusinessDocumentationForm";
import VetInsurancedocumentationForm from "./pages/VetInsuranceDocumentation/VetInsuranceDocumentationForm";
import BusinessOwnerVerificationKYB from "./pages/VerificationForm/BusinessOwnerVerificationKYB";
import BusinessVerificationKYB from "./pages/VerificationForm/BusinessVerificationKYB";
import InsuranceVerification from "./pages/VerificationForm/InsuranceVerification";
import { DockView } from "./pages/Dashboard/DockView";

// Import redesigned components
import { ShipmentDashboard, AddShipmentForm } from "./pages/Shipments-Redesign";
import { CarriersDashboard } from "./pages/Carriers-Redesign";
import { CargoOwnersDashboard } from "./pages/CargoOwners-Redesign";
import { VerificationsDashboard } from "./pages/Verifications-Redesign";
import { DriversDashboard, AddDriver } from "./pages/Drivers-Redesign";

const App = () => {
  if (process.env.NODE_ENV === "production") {
    Object.keys(console).forEach((method) => {
      if (typeof console[method] === "function") {
        console[method] = () => {};
      }
    });
  }

  return (
    <Routes>
      {/* Public routes without NavBar */}
      <Route path="/public-report/:token" element={<PublicReport />} />
      <Route path="/verify/:shipmentId" element={<IdentityVerification />} />
      <Route
        path="/external"
        element={<Navigate to="/external/login" replace />}
      />
      <Route path="/external/login" element={<CustomerLogin />} />
      <Route path="/external/:id" element={<ExternalDashboard />} />
      <Route path="/unauthorized" element={<NotFound />} />
      <Route path="/verification/:shipmentId" element={<ShipmentDetails />} />
      <Route
        path="/verify-business/:id"
        element={<BusinessVerificationKYB />}
      />
      <Route
        path="/verify-business-owner/:id"
        element={<BusinessOwnerVerificationKYB />}
      />
      <Route path="/verify-insurance/:id" element={<InsuranceVerification />} />

      {/* All other routes with NavBar */}
      <Route
        element={
          <div className="flex flex-col min-h-screen">
            <header className="sticky top-0 z-50 w-full">
              <NavBar />
            </header>
            <main className="flex-1">
              <Outlet />
            </main>
          </div>
        }
      >
        {/* Public routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/freight-brokers" element={<FreightBrokers />} />
        <Route path="/warehouses" element={<Warehouses />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/vetting" element={<PublicVetting />} />
        <Route path="/photo-proof/:shipmentId" element={<LoadPhotoProof />} />

        {/* Protected Dashboard Routes */}
        <Route element={<ProtectedRoute />}>
          <Route
            element={
              <div className="flex h-full overflow-hidden">
                <SidebarProvider defaultOpen={true}>
                  <div className="h-full">
                    <AppSidebar />
                  </div>
                  <div className="flex-1 ml-4 mt-4 overflow-auto">
                    <Outlet />
                  </div>
                </SidebarProvider>
              </div>
            }
          >
            <Route path="/dashboard">
              <Route index element={<DashboardContent />} />
              {/* Shipment Routes */}
              <Route path="shipments" element={<ShipmentDashboard />} />
              <Route path="add-shipment" element={<AddShipmentForm />} />
              <Route path="shipment/:id" element={<ShipmentDetails />} />

              {/* Driver Routes */}
              <Route path="drivers" element={<DriversDashboard />} />
              <Route path="add-driver" element={<AddDriver />} />

              {/* Carrier Routes */}
              <Route path="carriers" element={<CarriersDashboard />} />
              <Route path="add-carrier" element={<AddCarrierForm />} />
              <Route path="vet-carrier" element={<VetCarrierForm />} />
              <Route
                path="vet-business-owner"
                element={<VetBusinessOwnerForm />}
              />
              <Route
                path="vet-business-documentation"
                element={<VetBusinessDocumentationForm />}
              />
              <Route
                path="vet-insurance-documentation"
                element={<VetInsurancedocumentationForm />}
              />
              <Route
                path="vet-carrier/:id"
                element={<VetCarrierReportContainer />}
              />
              <Route
                path="vet-documents"
                element={<VetInsurancedocumentationForm />}
              />

              {/* Cargo Owner Routes */}
              <Route path="cargo-owners" element={<CargoOwnersDashboard />} />
              <Route path="add-cargo-owner" element={<AddCargoOwnerForm />} />
              <Route
                path="cargo-owners/:id/users"
                element={<CargoOwnerUsers />}
              />

              {/* Verification Routes */}
              <Route
                path="verifications"
                element={<VerificationsDashboard />}
              />
              <Route path="vet-driver" element={<VerificationForm />} />

              {/* Report Routes */}
              <Route path="reports" element={<ReportDashboard />} />
              <Route path="add-report" element={<AddReport />} />
              <Route path="report/:id" element={<ReportDetails />} />

              {/* Risk Signals Routes */}
              <Route path="risk-signals" element={<RiskDashboard />} />
              <Route path="risk-signals/lookup" element={<RiskLookupForm />} />

              {/* Fraud Rules Routes */}
              <Route path="fraud-rules" element={<FraudRuleDashboard />} />
              <Route path="fraud-rules/create" element={<CreateFraudRules />} />

              {/* Workflow Routes */}
              <Route path="workflows" element={<WorkflowDashboard />} />
              <Route path="workflows/add-workflow" element={<Workflow />} />

              <Route path="dock-view" element={<DockView />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
