// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase";
import { Button } from "../Components/Button";
import Toast from "../Components/Toast";
import { Input } from "../Components/Input";
import { Label } from "../Components/Label";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../Components/Card";

const CustomerLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession();

        console.log("Checking if there is a session:", session ? "Yes" : "No");
        if (session) {
          // Fetch the cargo_owner_id for the logged-in user
          const { data: externalUser } = await supabase
            .from("external_users")
            .select("cargo_owner_id")
            .eq("user_id", session.user.id)
            .single();

          console.log("External user data:", externalUser);
          if (externalUser?.cargo_owner_id) {
            console.log(
              "Navigating to:",
              `/external/${externalUser.cargo_owner_id}`
            );
            navigate(`/external/${externalUser.cargo_owner_id}`);
          } else {
            console.log("No cargo_owner_id found for user");
          }
        }
      } catch (error) {
        console.error("Error in checkSession:", error);
      }
    };
    checkSession();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      console.log("Attempting login with email:", email);
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        console.error("Login error:", error);
        throw error;
      }

      console.log("Login successful, user:", data.user.id);

      // Fetch the cargo_owner_id immediately after successful login
      console.log("Fetching cargo_owner_id...");
      const { data: externalUser, error: userError } = await supabase
        .from("external_users")
        .select("cargo_owner_id")
        .eq("user_id", data.user.id)
        .single();

      if (userError) {
        console.error("Error fetching user details:", userError);
        throw userError;
      }

      console.log("User details fetched:", externalUser);

      if (externalUser?.cargo_owner_id) {
        // Simplify the navigation approach - use window.location.href for a clean redirect
        const destination = `/external/${externalUser.cargo_owner_id}`;
        console.log("Navigating to:", destination);
        window.location.href = destination;
      } else {
        console.error("User has no cargo_owner_id associated");
        throw new Error("User not associated with a cargo owner");
      }
    } catch (err) {
      Toast.error(err.message || "Failed to sign in. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative h-screen w-full bg-custom-photo bg-no-repeat bg-cover flex justify-center items-center">
      <Card className="w-full max-w-md shadow-lg rounded-lg overflow-hidden z-10">
        <form onSubmit={handleLogin}>
          <CardHeader className="text-left">
            <div className="flex justify-center mb-6">
              <img
                src="/static/favicon.png"
                alt="Indemni Logo"
                className="h-16 w-auto"
              />
            </div>
            <CardTitle className="text-2xl font-bold">Customer Login</CardTitle>
            <CardDescription className="text-sm text-gray-600 mt-2">
              Sign in to access your customer dashboard. Please contact your
              administrator to get access.
            </CardDescription>
          </CardHeader>

          <CardContent className="space-y-4 px-6">
            <div className="space-y-1">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="you@example.com"
                className="w-full bg-white"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="space-y-1">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                placeholder="Enter your password"
                className="w-full bg-white"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </CardContent>

          <CardFooter className="px-6 py-4">
            <Button
              type="submit"
              className="w-full bg-primary text-white py-2 rounded-md"
              disabled={isLoading}
            >
              {isLoading ? "Signing in..." : "Sign in"}
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
};

export default CustomerLogin;
