// @ts-check
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import UserService from "../api/services/core/UserService";
import Toast from "../pages/Components/Toast";

/**
 * A component that protects routes by checking authentication status
 * Redirects to login if user is not authenticated
 * @returns {JSX.Element} The protected route or redirect
 */
const ProtectedRoute = () => {
  const [cargoOwnerId, setCargoOwnerId] = useState(null);
  const { session, loading } = useAuth();

  useEffect(() => {
    const checkExternalUser = async () => {
      // Only proceed with the check if we have a session and it's an external user
      if (session?.user?.user_metadata?.role === "external_user") {
        try {
          const externalUser = await UserService.getExternalUserDetails(
            session.user.id
          );

          if (externalUser) {
            setCargoOwnerId(externalUser.cargo_owner_id);
          }
        } catch (error) {
          Toast.error("Failed to check external user status");
          console.error("Error checking external user:", error);
        }
      }
    };

    checkExternalUser();
  }, [session]);

  if (loading || session === undefined) {
    return <div>Loading...</div>;
  }

  if (!session?.access_token) {
    return <Navigate to="/signin" replace />;
  }

  const currentTime = Math.floor(Date.now() / 1000);
  if (session.expires_at && currentTime >= session.expires_at) {
    return <Navigate to="/signin" replace />;
  }

  // Handle external user navigation after all other checks
  if (cargoOwnerId) {
    return <Navigate to={`/external-user/${cargoOwnerId}`} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
