const PersonaProvider = require("../providers/PersonaProvider");
const FMSCAProvider = require("../providers/FMSCAProvider");
const OCRProvider = require("../providers/OCRProvider");
const VerificationProvider = require("../providers/VerificationProvider");

/**
 * Factory for creating and caching provider instances
 * Centralizes provider initialization and ensures providers are only created once
 */
class ProviderFactory {
  constructor() {
    this.providers = new Map();
  }

  /**
   * Get a provider instance of the specified type
   * @param {string} type - Provider type
   * @param {Object} config - Provider configuration
   * @returns {Object} Provider instance
   */
  getProvider(type, config = {}) {
    const key = `${type}-${JSON.stringify(config)}`;

    if (!this.providers.has(key)) {
      this.providers.set(key, this.createProvider(type, config));
    }

    return this.providers.get(key);
  }

  /**
   * Create a new provider instance
   * @param {string} type - Provider type
   * @param {Object} config - Provider configuration
   * @returns {Object} Provider instance
   * @throws {Error} If provider type is not supported
   */
  createProvider(type, config) {
    switch (type.toLowerCase()) {
      case "persona":
        return new PersonaProvider(config);

      case "fmsca":
        return new FMSCAProvider(config);

      case "ocr":
        return new OCRProvider(config);

      case "verification":
        return new VerificationProvider(config);

      default:
        throw new Error(`Unsupported provider type: ${type}`);
    }
  }

  /**
   * Get a Persona provider instance
   * @param {Object} config - Provider configuration
   * @returns {PersonaProvider} Persona provider instance
   */
  getPersonaProvider(config = {}) {
    return this.getProvider("persona", config);
  }

  /**
   * Get a FMSCA provider instance
   * @param {Object} config - Provider configuration
   * @returns {FMSCAProvider} FMSCA provider instance
   */
  getFMSCAProvider(config = {}) {
    return this.getProvider("fmsca", config);
  }

  /**
   * Get an OCR provider instance
   * @param {Object} config - Provider configuration
   * @returns {OCRProvider} OCR provider instance
   */
  getOCRProvider(config = {}) {
    return this.getProvider("ocr", config);
  }

  /**
   * Get a verification provider instance
   * @param {Object} config - Provider configuration
   * @returns {VerificationProvider} Verification provider instance
   */
  getVerificationProvider(config = {}) {
    return this.getProvider("verification", config);
  }
}

// Export a singleton instance
const providerFactory = new ProviderFactory();
module.exports = { providerFactory, ProviderFactory };
