import React, { useState, useEffect, useRef, useCallback } from "react";
import { Input } from "./input";
import { Loader2 } from "lucide-react";

// Mapbox access token - use environment variable in production
const MAPBOX_ACCESS_TOKEN =
  process.env.REACT_APP_MAPBOX_TOKEN ||
  "pk.eyJ1Ijoib2RyYXoiLCJhIjoiY204bmdmYThjMDBnbjJzb2w0YWQ3cHdqNyJ9.sCz1na5WkoWB6oqQXVrQRA";

export function MapboxAddressInput({
  value,
  onChange,
  onAddressSelected,
  placeholder = "Enter address",
  required,
  className,
  ...props
}) {
  const [inputValue, setInputValue] = useState(value || "");
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const debounceTimer = useRef(null);
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  // Update input value when prop changes
  useEffect(() => {
    if (value !== undefined && value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  // Add click outside listener to close suggestions
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Debounced fetch suggestions to prevent too many API calls
  const fetchSuggestions = useCallback(async (query) => {
    if (query.length < 3) return;

    try {
      setIsLoading(true);
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        query
      )}.json?access_token=${MAPBOX_ACCESS_TOKEN}&types=address&country=us&limit=5`;
      const response = await fetch(url);
      const data = await response.json();

      if (data && data.features) {
        setSuggestions(data.features);
        setShowSuggestions(true);
      }
    } catch (error) {
      console.error("Error fetching address suggestions:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Debounced input change handler
  const handleInputChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setInputValue(newValue);

      if (onChange) {
        onChange(e);
      }

      // Clear previous debounce timer
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }

      // Only search if we have at least 3 characters
      if (newValue.length < 3) {
        setSuggestions([]);
        setShowSuggestions(false);
        return;
      }

      // Set new debounce timer
      debounceTimer.current = setTimeout(() => {
        fetchSuggestions(newValue);
      }, 300);
    },
    [onChange, fetchSuggestions]
  );

  // Handle suggestion selection
  const handleSelectSuggestion = useCallback(
    (suggestion) => {
      const formattedAddress = suggestion.place_name;
      setInputValue(formattedAddress);
      setSuggestions([]);
      setShowSuggestions(false);

      if (onAddressSelected) {
        onAddressSelected({
          feature_id: suggestion.id,
          formatted_address: formattedAddress,
          address: suggestion.text,
          coordinates: suggestion.center,
          properties: suggestion.properties || {},
        });
      }

      if (onChange) {
        const syntheticEvent = {
          target: { value: formattedAddress },
        };
        onChange(syntheticEvent);
      }

      // Restore focus after selection
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [onAddressSelected, onChange]
  );

  return (
    <div className="relative w-full" ref={containerRef}>
      <Input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onClick={() => inputValue.length >= 3 && setShowSuggestions(true)}
        placeholder={placeholder}
        required={required}
        className={className}
        autoComplete="off" // Prevent browser autocomplete from interfering
        {...props}
      />

      {isLoading && (
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
          <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
        </div>
      )}

      {showSuggestions && suggestions.length > 0 && (
        <ul className="absolute z-50 w-full mt-1 max-h-60 overflow-auto rounded-md bg-popover py-1 shadow-md">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.id}
              className="relative cursor-pointer select-none py-2 px-3 hover:bg-accent hover:text-accent-foreground"
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              {suggestion.place_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
