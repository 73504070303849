/**
 * PersonaWebhookService.js
 *
 * This service handles Persona webhook events
 */

import { VerificationRepository } from "../../../repositories/VerificationRepository";
import { supabase } from "../../../../lib/supabase";
import { updateVerificationRecord } from "../VerificationService";
import {
  mapPersonaInquiryStatus,
  mapPersonaReportStatus,
  isVerificationSuccessful,
  mapStatusToVerificationState,
  getVerificationResultFromStatus,
} from "../../../utils/PersonaStatusUtils";

class PersonaWebhookService {
  constructor(dependencies = {}) {
    this.verificationRepository =
      dependencies.verificationRepository ||
      new VerificationRepository(supabase);
  }

  /**
   * Main webhook handler to process Persona events
   *
   * @param {Object} webhookData - Data from Persona webhook
   * @returns {Promise<Object>} Processing result
   */
  async handlePersonaWebhook(webhookData) {
    try {
      console.log("🔍 [PersonaWebhookService] Processing webhook:", {
        event: webhookData.event,
        payload_id: webhookData.payload?.id,
        payload_type: webhookData.payload?.object,
      });

      if (!webhookData || !webhookData.event) {
        throw new Error("Invalid webhook data: missing event");
      }

      // Route to the appropriate handler based on event type and object
      const eventType = webhookData.event;
      const objectType = webhookData.payload?.object;

      // Handle different event types
      switch (objectType) {
        case "inquiry":
          return this._handleInquiryEvent(eventType, webhookData.payload);
        case "verification":
          return this._handleVerificationEvent(eventType, webhookData.payload);
        case "report":
          return this._handleReportEvent(eventType, webhookData.payload);
        case "business-lookup":
          return this.handleBusinessRegistrationsLookupEvent(
            webhookData.payload
          );
        case "business-verification-transaction":
          return this.handleBusinessVerificationTransactionEvent(
            webhookData.payload
          );
        default:
          console.warn(
            "⚠️ [PersonaWebhookService] Unhandled object type:",
            objectType
          );
          return {
            success: false,
            message: `Unhandled object type: ${objectType}`,
          };
      }
    } catch (error) {
      console.error(
        "❌ [PersonaWebhookService] Error processing webhook:",
        error
      );
      throw error;
    }
  }

  /**
   * Handle business registrations lookup event
   *
   * @param {Object} eventData - Event data from Persona
   * @returns {Promise<Object>} Processing result
   */
  async handleBusinessRegistrationsLookupEvent(eventData) {
    try {
      console.log(
        "🔍 [PersonaWebhookService] Processing business lookup event:",
        {
          id: eventData.id,
          status: eventData.status,
        }
      );

      if (!eventData) {
        throw new Error("Invalid business lookup event data");
      }

      // Find the associated verification record
      const verificationRecords =
        await this.verificationRepository.findByMetadataValue(
          "business_lookup_id",
          eventData.id
        );

      if (!verificationRecords || verificationRecords.length === 0) {
        console.warn(
          "⚠️ [PersonaWebhookService] No verification record found for business lookup:",
          eventData.id
        );
        return { success: false, message: "No verification record found" };
      }

      // Map status using utility functions
      const standardStatus = mapPersonaReportStatus(eventData.status);
      const isComplete = isVerificationSuccessful(eventData.status);
      const verificationState = mapStatusToVerificationState(standardStatus);

      // Update the verification record with the lookup result
      const verification = verificationRecords[0];
      const updateData = {
        state: verificationState,
        persona_verification_complete: isComplete,
        results: getVerificationResultFromStatus(eventData.status, {
          business_lookup: eventData,
        }),
        updated_at: new Date().toISOString(),
      };

      // Update the verification record
      await this.verificationRepository.update(verification.id, updateData);

      console.log(
        "✅ [PersonaWebhookService] Updated verification record for business lookup:",
        verification.id
      );
      return { success: true, verification_id: verification.id };
    } catch (error) {
      console.error(
        "❌ [PersonaWebhookService] Error processing business lookup event:",
        error
      );
      throw error;
    }
  }

  /**
   * Handle business verification transaction event
   *
   * @param {Object} eventData - Event data from Persona
   * @returns {Promise<Object>} Processing result
   */
  async handleBusinessVerificationTransactionEvent(eventData) {
    try {
      console.log(
        "🔍 [PersonaWebhookService] Processing business verification transaction:",
        {
          id: eventData.id,
          status: eventData.status,
          reference_id: eventData.reference_id,
        }
      );

      if (!eventData) {
        throw new Error("Invalid business verification transaction data");
      }

      // Find the associated verification records using reference_id
      const verificationRecords = eventData.reference_id
        ? await this.verificationRepository.findByReferenceId(
            eventData.reference_id
          )
        : await this.verificationRepository.findByMetadataValue(
            "transaction_id",
            eventData.id
          );

      if (!verificationRecords || verificationRecords.length === 0) {
        console.warn(
          "⚠️ [PersonaWebhookService] No verification record found for transaction:",
          eventData.reference_id || eventData.id
        );
        return { success: false, message: "No verification record found" };
      }

      // Map status using utility functions
      const standardStatus = mapPersonaReportStatus(eventData.status);
      const isComplete = isVerificationSuccessful(eventData.status);
      const verificationState = mapStatusToVerificationState(standardStatus);

      // Update the verification record with the transaction result
      const verification = verificationRecords[0];
      const updateData = {
        state: verificationState,
        persona_verification_complete: isComplete,
        results: getVerificationResultFromStatus(eventData.status, {
          business_verification: eventData,
        }),
        updated_at: new Date().toISOString(),
      };

      // Update the verification record
      await this.verificationRepository.update(verification.id, updateData);

      console.log(
        "✅ [PersonaWebhookService] Updated verification record for business transaction:",
        verification.id
      );
      return { success: true, verification_id: verification.id };
    } catch (error) {
      console.error(
        "❌ [PersonaWebhookService] Error processing business verification transaction:",
        error
      );
      throw error;
    }
  }

  /**
   * Handle inquiry events
   *
   * @param {string} eventType - Type of event
   * @param {Object} payload - Event payload
   * @returns {Promise<Object>} Processing result
   * @private
   */
  async _handleInquiryEvent(eventType, payload) {
    try {
      console.log("🔍 [PersonaWebhookService] Processing inquiry event:", {
        event: eventType,
        inquiry_id: payload.id,
        status: payload.status,
      });

      // Find the associated verification record
      const verificationRecords =
        await this.verificationRepository.findByPersonaInquiryId(payload.id);

      if (!verificationRecords || !verificationRecords.id) {
        console.warn(
          "⚠️ [PersonaWebhookService] No verification record found for inquiry:",
          payload.id
        );
        return { success: false, message: "No verification record found" };
      }

      // Map status using utility functions
      const standardStatus = mapPersonaInquiryStatus(payload.status);
      const isComplete = isVerificationSuccessful(payload.status);
      const verificationState = mapStatusToVerificationState(standardStatus);

      // Update based on the event type
      const updateData = {
        state: verificationState,
        persona_verification_complete: isComplete,
        results: getVerificationResultFromStatus(payload.status, {
          inquiry: payload,
        }),
        updated_at: new Date().toISOString(),
      };

      // Update the verification record
      await this.verificationRepository.update(
        verificationRecords.id,
        updateData
      );

      console.log(
        "✅ [PersonaWebhookService] Updated verification record for inquiry:",
        verificationRecords.id
      );
      return { success: true, verification_id: verificationRecords.id };
    } catch (error) {
      console.error(
        "❌ [PersonaWebhookService] Error processing inquiry event:",
        error
      );
      throw error;
    }
  }

  /**
   * Handle verification events
   *
   * @param {string} eventType - Type of event
   * @param {Object} payload - Event payload
   * @returns {Promise<Object>} Processing result
   * @private
   */
  async _handleVerificationEvent(eventType, payload) {
    try {
      console.log("🔍 [PersonaWebhookService] Processing verification event:", {
        event: eventType,
        verification_id: payload.id,
        status: payload.status,
        inquiry_id: payload.inquiry_id,
      });

      // If we have an inquiry_id, find records associated with it
      if (payload.inquiry_id) {
        const verificationRecords =
          await this.verificationRepository.findByPersonaInquiryId(
            payload.inquiry_id
          );

        if (!verificationRecords || !verificationRecords.id) {
          console.warn(
            "⚠️ [PersonaWebhookService] No verification record found for inquiry:",
            payload.inquiry_id
          );
          return { success: false, message: "No verification record found" };
        }

        // Map status using utility functions
        const standardStatus = mapPersonaInquiryStatus(payload.status);
        const isComplete = isVerificationSuccessful(payload.status);
        const verificationState = mapStatusToVerificationState(standardStatus);

        // Update the verification record with the verification result
        const updateData = {
          state: verificationState,
          persona_verification_complete: isComplete,
          results: getVerificationResultFromStatus(payload.status, {
            verification: payload,
          }),
          updated_at: new Date().toISOString(),
        };

        // Update the verification record
        await this.verificationRepository.update(
          verificationRecords.id,
          updateData
        );

        console.log(
          "✅ [PersonaWebhookService] Updated verification record:",
          verificationRecords.id
        );
        return { success: true, verification_id: verificationRecords.id };
      } else {
        console.warn(
          "⚠️ [PersonaWebhookService] Verification event missing inquiry_id"
        );
        return {
          success: false,
          message: "Verification event missing inquiry_id",
        };
      }
    } catch (error) {
      console.error(
        "❌ [PersonaWebhookService] Error processing verification event:",
        error
      );
      throw error;
    }
  }

  /**
   * Handle report events
   *
   * @param {string} eventType - Type of event
   * @param {Object} payload - Event payload
   * @returns {Promise<Object>} Processing result
   * @private
   */
  async _handleReportEvent(eventType, payload) {
    try {
      console.log("🔍 [PersonaWebhookService] Processing report event:", {
        event: eventType,
        report_id: payload.id,
        status: payload.status,
        reference_id: payload.reference_id,
      });

      // Find verification records by report_id or reference_id
      const verificationRecords = payload.reference_id
        ? await this.verificationRepository.findByReferenceId(
            payload.reference_id
          )
        : await this.verificationRepository.findByMetadataValue(
            "report_id",
            payload.id
          );

      if (!verificationRecords || verificationRecords.length === 0) {
        console.warn(
          "⚠️ [PersonaWebhookService] No verification record found for report:",
          payload.reference_id || payload.id
        );
        return { success: false, message: "No verification record found" };
      }

      // Map status using utility functions
      const standardStatus = mapPersonaReportStatus(payload.status);
      const isComplete = isVerificationSuccessful(payload.status);
      const verificationState = mapStatusToVerificationState(standardStatus);

      // Update the first matching verification record
      const verification = verificationRecords[0];
      const updateData = {
        state: verificationState,
        persona_verification_complete: isComplete,
        results: getVerificationResultFromStatus(payload.status, {
          report: payload,
        }),
        updated_at: new Date().toISOString(),
      };

      // Update the verification record
      await this.verificationRepository.update(verification.id, updateData);

      console.log(
        "✅ [PersonaWebhookService] Updated verification record for report:",
        verification.id
      );
      return { success: true, verification_id: verification.id };
    } catch (error) {
      console.error(
        "❌ [PersonaWebhookService] Error processing report event:",
        error
      );
      throw error;
    }
  }
}

// Export as singleton instance
export default new PersonaWebhookService();
