// @ts-nocheck
import { supabase } from "../../../lib/supabase";

// Utility function for error handling
const handleSupabaseError = (error) => {
  console.error("Supabase error:", error);
  throw new Error("An error occurred while fetching data");
};

export const CargoOwnerService = {
  async getCustomerByUserId(userId) {
    if (!userId) return null;
    const { data, error } = await supabase
      .from("customers")
      .select("*")
      .eq("user_id", userId)
      .single();
    if (error) handleSupabaseError(error);
    return data;
  },

  async getCargoOwner(companyId, cargoOwnerId) {
    if (!companyId && !cargoOwnerId) return null;
    const { data, error } = await supabase
      .from("cargo_owners")
      .select("*")
      .eq(cargoOwnerId ? "id" : "company_id", cargoOwnerId || companyId)
      .single();
    if (error) handleSupabaseError(error);
    return data;
  },

  async getCargoOwnerById(id) {
    if (!id) return null;
    const { data, error } = await supabase
      .from("cargo_owners")
      .select("*")
      .eq("id", id)
      .single();
    if (error) handleSupabaseError(error);
    return data;
  },

  async getCargoOwnerByShipment(loadId) {
    if (!loadId) return null;
    const { data: shipment, error: shipmentError } = await supabase
      .from("shipments")
      .select("cargo_owner_id")
      .eq("load_id", loadId)
      .single();

    if (shipmentError) handleSupabaseError(shipmentError);
    if (!shipment?.cargo_owner_id) return null;

    return this.getCargoOwnerById(shipment.cargo_owner_id);
  },

  async getCargoOwnerByCompany(companyId) {
    if (!companyId) return null;
    const { data: cargoOwner, error: cargoOwnerError } = await supabase
      .from("cargo_owners")
      .select("*")
      .eq("company_id", companyId)
      .single();

    if (cargoOwnerError) handleSupabaseError(cargoOwnerError);
    return cargoOwner;
  },

  async getCargoOwners(cargoOwnerIds) {
    if (cargoOwnerIds.length === 0) return {};

    const { data, error } = await supabase
      .from("cargo_owners")
      .select("id, company_name")
      .in("id", cargoOwnerIds);

    if (error) handleSupabaseError(error);

    return data.reduce((acc, owner) => {
      acc[owner.id] = owner;
      return acc;
    }, {});
  },

  async getAllCargoOwners() {
    const { data, error } = await supabase.from("cargo_owners").select("*");
    if (error) handleSupabaseError(error);
    return data;
  },

  async createCargoOwner(cargoOwnerData) {
    if (!cargoOwnerData) throw new Error("Cargo owner data is required");

    const { data, error } = await supabase
      .from("cargo_owners")
      .insert(cargoOwnerData)
      .single();

    if (error) handleSupabaseError(error);
    return data;
  },
};
